import React from "react";
import PropTypes from "prop-types";

import useLanguage from "utils/hooks/useLanguage";
import SelectableChip from "components/guest/selectable-chip/SelectableChip";

import styles from "./FilterGroup.module.scss";

const FilterGroup = ({ name, selectedData, setFilter, data, groupName }) => {
  const { displayDataByLanguage } = useLanguage();

  const isItemSelected = (id) => {
    return selectedData?.includes(id);
  };
  const toggleCheckCategory = (id) => {
    if (isItemSelected(id)) {
      setFilter((prev) => {
        return { ...prev, [name]: prev[name].filter((i) => i !== id) };
      });
    } else {
      setFilter((prev) => {
        return { ...prev, [name]: [...prev[name], id] };
      });
    }
  };

  return (
    <div className={styles.FilterGroup}>
      <div className={styles.Categories}>
        <h2 className="SemiBold">{groupName}</h2>
        <div className={styles.CategoriesWrapper}>
          {data.map((category, index) => (
            <SelectableChip
              key={category.id}
              onClick={() => toggleCheckCategory(category.id)}
              name={displayDataByLanguage(category.name)}
              checked={isItemSelected(category.id)}
              delay={index * (2 / data.length)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterGroup;

FilterGroup.propTypes = {
  name: PropTypes.string.isRequired,
  selectedData: PropTypes.array,
  setFilter: PropTypes.func,
  data: PropTypes.array,
  groupName: PropTypes.string,
};
