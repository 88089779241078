import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateBasket } from "redux/slices/basketStore";
import { STORE_NAMES } from "utils/constants/redux";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";

const useCheckAndDeleteItemsFromBasket = () => {
  const dispatch = useDispatch();
  const { isValidMenuItem } = useMenuHelper();
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const guests = basketOrders ? basketOrders.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const basketItems = guest?.orderItems || [];

  useEffect(() => {
    basketItems.forEach(({ item }) => {
      if (!isValidMenuItem(item.id)) {
        dispatch(
          updateBasket({
            menuItem: {
              ...item,
            },
            count: null,
            userId: guestId,
          })
        );
      }
    });
  }, [basketItems, isValidMenuItem, dispatch]);
};

export default useCheckAndDeleteItemsFromBasket;
