import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { ReactComponent as Clock } from "assets/icons/clock/Clock.svg";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import AnimatedAddButton, {
  ENUMS as ENUMS_ANIMATION,
} from "components/buttons/animated-add-button/AnimatedAddButton";
import FavoritesIconButton, {
  ENUMS as ENUMS_FAVORITES_ICON_BUTTON,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";
import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import useTags from "utils/hooks/useTags";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import LazyImage from "components/lazy-image/LazyImage";
import If from "components/if/If";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import Cube from "components/cube/Cube";

import styles from "./MenuCard.module.scss";

export const ENUMS = {
  name: "MenuCard",
  types: {
    SMALL: "Small",
    MEDIUM: "Medium",
    LARGE: "Large",
    WITHOUT_IMAGE: "WithoutImage",
    GRID: "Grid",
  },
};

const MenuCard = ({
  menuItem,
  onFavorite,
  onAddToBasket,
  type = ENUMS.types.SMALL,
  isFavorite = false,
  onClick,
  lazyImage = false,
  handleOpenAr,
  className,
}) => {
  const {
    id,
    extraFields: { displayedName },
    timeToMake,
  } = menuItem;
  const { allTags } = useTags(menuItem);
  const { t } = useTranslation();

  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);

  const isActiveArView = Boolean(
    menuItem?.model3D && menuItem.model3D?.ios && menuItem.model3D?.general
  );
  return (
    <div
      onClick={onClick}
      className={cx(className, styles.MenuCard, styles[type])}
    >
      <div className={styles.ImageContainer}>
        <If
          state={
            (type === ENUMS.types.MEDIUM ||
              type === ENUMS.types.LARGE ||
              type === ENUMS.types.GRID) &&
            isActiveArView
          }
        >
          <div
            onClick={(e) => {
              if (type !== ENUMS.types.GRID) {
                e.stopPropagation();
                handleOpenAr();
              }
            }}
            className={styles.ArButtonWrapper}
          >
            <Cube />
            <p className="h7 SemiBold">AR</p>
          </div>
        </If>

        <If state={!lazyImage}>
          <ImageWithPlaceholder
            imageSource={menuItem.coverImageSrc}
            placeholder={IMAGE_ITEM_PLACEHOLDER}
            alt={displayedName}
            className={styles.Image}
          />
        </If>
        <If state={lazyImage}>
          <LazyImage
            src={menuItem.coverImageSrc}
            placeholder={IMAGE_ITEM_PLACEHOLDER}
            alt={displayedName}
            className={styles.Image}
          />
        </If>
        <If state={type !== ENUMS.types.SMALL}>
          <div className={styles.FavoriteAndTimeToMake}>
            <If state={timeToMake !== 0 && type !== ENUMS.types.GRID}>
              <h6 className={`${styles.TimeToMake} h8 Medium`}>
                <Clock /> {timeToMake} {t("common.time.min")}
              </h6>
            </If>
            <FavoritesIconButton
              id={id}
              type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_F}
              onClick={onFavorite}
              value={isFavorite}
            />
          </div>
        </If>
      </div>
      <div
        className={cx(styles.DetailContainer, {
          [styles.extraGap]:
            discountPrice === null && type === ENUMS.types.GRID,
        })}
      >
        <h4 className={cx(styles.Title, "SemiBold")}>{displayedName}</h4>
        <If state={type === ENUMS.types.WITHOUT_IMAGE}>
          <div className={styles.Favorite}>
            <FavoritesIconButton
              id={id}
              type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_G}
              onClick={onFavorite}
              value={isFavorite}
            />
          </div>
        </If>
        <If state={allTags.length > 0 && type !== ENUMS.types.GRID}>
          <div className={styles.Tags}>
            <Tag
              items={allTags}
              type={ENUMS_TAG.types.TYPE_F}
              showTime={type === ENUMS.types.WITHOUT_IMAGE}
            />
          </div>
        </If>

        <div className={styles.Footer}>
          <div className={styles.Price}>
            <If state={discountPrice !== null}>
              <Price
                type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
                value={menuItemPrice}
              />
            </If>
            <Price
              type={ENUMS_PRICE.types.PRICE_M_SEMI_BOLD}
              value={
                discountPrice !== null
                  ? menuItemPrice + discountPrice
                  : menuItemPrice
              }
            />
          </div>

          <AnimatedAddButton
            id={id}
            onClick={() => onAddToBasket(menuItem)}
            value={0}
            size={
              type === ENUMS.types.GRID
                ? ENUMS_ANIMATION.types.SMALL
                : ENUMS_ANIMATION.types.MEDIUM
            }
          />
        </div>
      </div>
    </div>
  );
};

MenuCard.propTypes = {
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    extraFields: PropTypes.shape({
      displayedName: PropTypes.string.isRequired,
    }),
    coverImageSrc: PropTypes.string,
    priceSell: PropTypes.number.isRequired,
    priceCost: PropTypes.number,
    calories: PropTypes.number,
    timeToMake: PropTypes.number,
    discountPrice: PropTypes.number,
    amount: PropTypes.number,
    unit: PropTypes.shape({
      name: PropTypes.string,
    }),
    model3D: PropTypes.object,
  }).isRequired,
  onFavorite: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool,
  onAddToBasket: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(ENUMS.types)).isRequired,
  lazyImage: PropTypes.bool,
  handleOpenAr: PropTypes.func,
  className: PropTypes.string,
};

export default MenuCard;
