import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTES_OF_BUSINESS_SETTINGS } from "utils/constants/routes";

const useGuestRouteManager = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = useRef(null);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  useEffect(() => {
    const handleRouteChange = () => {
      const currentPath = location.pathname;

      // Handle restricted routes based on business settings
      if (business) {
        const restrictedSettings = ROUTES_OF_BUSINESS_SETTINGS.filter(
          (r) => !business[r?.setting]
        );

        restrictedSettings.forEach((restrictedSetting) => {
          restrictedSetting.restrictedRoutes.forEach((route) => {
            if (currentPath.includes(route)) {
              window.location = `${window.location.origin}${restrictedSetting.redirectRoute}${business.id}`;
            }
          });
        });
      }

      previousLocation.current = currentPath;
    };

    handleRouteChange();
  }, [location, business, navigate]);

  useEffect(() => {
    window.parent.postMessage({ url: window.location.href }, "*");
  }, [window.location.href]);
};

export default useGuestRouteManager;
