import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import MenuItemList from "components/menu-item-list/MenuItemList";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import useLanguage from "utils/hooks/useLanguage";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

const SeeAll = () => {
  const { businessId, categoryId } = useParams();
  const routerNavigate = useNavigate();
  const { navigate } = useInternalNavigation();
  const { allCategories } = useMenuHelper();
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const displayedCategory = useMemo(() => {
    if (allCategories.length === 0) return [];

    const selectedCategory = allCategories.find(
      (category) => String(category.id) === String(categoryId)
    );

    if (!selectedCategory) return [];

    return [selectedCategory];
  }, [allCategories]);

  const categoryTitle = displayedCategory?.[0]?.name
    ? displayDataByLanguage(displayedCategory[0].name)
    : "";

  const handleAddToBasket = (menuItem) => {
    const filteredModifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => ({
            id: modification.id,
            options: modification.options.map((option) => ({
              id: option.id,
              count: option.count ?? 1,
            })),
          })),
        },
        count: 1,
        userId: guestId,
      })
    );
  };

  const handleClickMenuItem = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  const handleFavItem = (menuItem) => {
    dispatch(updateFavorites({ userId: guestId, menuItemID: menuItem.id }));
  };

  const goBackHandler = () => {
    const backUrl = `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`;
    routerNavigate(backUrl);
  };

  return (
    <MenuItemList
      onClick={handleClickMenuItem}
      onFavorite={handleFavItem}
      addToBasket={handleAddToBasket}
      onGoBack={goBackHandler}
      categories={displayedCategory}
      title={categoryTitle}
    />
  );
};

export default SeeAll;
