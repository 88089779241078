import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./SelectButton.module.scss";

const SelectButton = ({ onClick, className, icon, title, isActive }) => {
  return (
    <div
      className={cx(
        styles.Root,
        className,
        { [styles.isClickable]: Boolean(onClick) },
        { [styles.isActive]: isActive }
      )}
      onClick={onClick}
    >
      <div className={styles.Icon}>{icon}</div>
      <h6 className={cx("SemiBold h7", styles.Title)}>{title}</h6>
    </div>
  );
};

SelectButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default SelectButton;
