import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { createDOBucketName } from "utils/DO-Spaces";
import MagicCard from "pages/client/magic-order/magic-card/MagicCard";
import If from "components/if/If";

import "./Recommendations.scss";

export const RECOMMENDATIONS_TYPE = {
  basket: "basket_recommended",
  search: "search_recommended",
};

const Recommendations = ({
  recommendCategories,
  onAdd,
  type = RECOMMENDATIONS_TYPE.basket,
  delay = "",
}) => {
  const { t } = useTranslation();

  const filteredCategories = useMemo(
    () => recommendCategories.filter((cat) => cat.extraVisibility === type),
    [recommendCategories]
  );
  const recommendedItems = useMemo(() => {
    const items = Array.from(
      new Map(
        filteredCategories
          .flatMap(({ menuItems }) => menuItems)
          .map((item) => [item.id, item])
      ).values()
    );

    return items.map((item) => ({
      ...item,
      coverImageSrc: createDOBucketName(item.coverImageSrc),
      otherImagesSrc: item.otherImagesSrc?.map(createDOBucketName),
    }));
  }, [recommendCategories]);
  return (
    <If state={recommendedItems.length > 0}>
      <div
        className={cx("BasketRecommendations", {
          search: type === RECOMMENDATIONS_TYPE.search,
          Delay: delay,
        })}
        style={{ animationDelay: `${delay}` }}
      >
        <h2 className="SemiBold RecommendationTitle">
          {type === RECOMMENDATIONS_TYPE.search
            ? t("menu.category.youMayAlsoLike")
            : t("recommend.recommendations")}
        </h2>
        <div className="BasketRecommendationsCards">
          {recommendedItems?.map((item) => (
            <MagicCard
              key={item.id}
              menuItem={item}
              onAddToBasket={onAdd}
              recommendations={true}
            />
          ))}
        </div>
      </div>
    </If>
  );
};

Recommendations.propTypes = {
  recommendCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isArchived: PropTypes.bool.isRequired,
      linkedMenuItems: PropTypes.arrayOf(PropTypes.number),
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          isArchived: PropTypes.bool.isRequired,
          isPublished: PropTypes.bool.isRequired,
          coverImageSrc: PropTypes.string,
          otherImagesSrc: PropTypes.arrayOf(PropTypes.string),
          rate: PropTypes.shape({
            isEnabled: PropTypes.bool,
            amount: PropTypes.number,
          }),
        })
      ).isRequired,
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  type: PropTypes.string,
  delay: PropTypes.string,
};

export default Recommendations;
