import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as CLEAR_ICON } from "assets/icons/close/AdminClose.svg";

import "./BasketMessageInput.scss";

export const ENUMS = {
  name: "BasketInputControl",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};
const BasketInputControl = ({
  value,
  defaultValue,
  onChange,
  required,
  placeholder,
  className,
  name,
  hasError,
  onBlur,
  onFocus,
  func,
  error,
  readOnly,
  handleClear,
  maxLength,
}) => {
  const textareaRef = useRef(null);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "48px";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        96
      )}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  return (
    <div className="BasketInputControlContainer">
      <div className={cx("BasketInputControl", className)}>
        <textarea
          value={value}
          ref={textareaRef}
          defaultValue={defaultValue}
          onChange={(e) => {
            onChange(e);
            adjustHeight();
          }}
          readOnly={readOnly}
          {...func}
          required={required}
          placeholder={placeholder}
          className={cx("BasketInputControlInput BasketInputControlTextarea", {
            hasError: hasError,
            hasValue: value.length,
          })}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
        />
        {value && (
          <div className="ClearMessageIcon" onClick={handleClear}>
            <CLEAR_ICON />
          </div>
        )}
      </div>
      {error}
      <p>
        {document.documentElement.dir === "rtl"
          ? maxLength + "/" + value.length
          : value.length + "/" + maxLength}
      </p>
    </div>
  );
};

BasketInputControl.propTypes = {
  /**
   * The current value of the input
   */
  value: PropTypes.any,

  /**
   * The default value of the input
   */
  defaultValue: PropTypes.any,

  /**
   * Callback function to handle input change events
   */
  onChange: PropTypes.func,

  /**
   * Indicates whether the input is required
   */
  required: PropTypes.bool,

  /**
   * Placeholder text for the input
   */
  placeholder: PropTypes.string,

  /**
   * The type of input (e.g., text, number)
   */
  type: PropTypes.string,

  /**
   * Additional class name for custom styling
   */
  className: PropTypes.string,
  /**
   * The name attribute for the input element
   */
  name: PropTypes.string,

  /**
   * Indicates whether the input should render as a textarea
   */
  textarea: PropTypes.bool,

  /**
   * Indicates whether the input has an error
   */
  hasError: PropTypes.bool,

  /**
   * Callback function to handle input blur events
   */
  onBlur: PropTypes.func,

  /**
   * Callback function to handle input focus events
   */
  onFocus: PropTypes.func,

  /**
   * Additional function to pass to the input
   * (e.g., onKeyDown, onClick)
   */
  func: PropTypes.object,

  /**
   * Error message to display
   * (e.g., for form validation)
   */
  error: PropTypes.object,

  /**
   * Indicates whether the input is read-only
   */
  readOnly: PropTypes.bool,

  maxLength: PropTypes.number,
  handleClear: PropTypes.func,
};

export default BasketInputControl;
