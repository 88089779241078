import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as CheckIcon } from "assets/icons/form/Check.svg";

import "./SelectableChip.scss";

const SelectableChip = ({ name, className, checked, onClick, delay }) => {
  return (
    <div
      className={cx("SelectableChip", className, {
        isChecked: checked,
      })}
      onClick={onClick}
      style={{
        animationDelay: `${delay}s`,
      }}
    >
      <h4 className="ChipRadioButtonContainer Bold">
        <div className="ChipRadioButton">{checked && <CheckIcon />}</div>
      </h4>
      <h6 className="SemiBold SelectableChipName">{name}</h6>
    </div>
  );
};

export default SelectableChip;

SelectableChip.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  delay: PropTypes.number,
};
