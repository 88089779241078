import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import HintForApple from "assets/images/hint/HintForApple.png";
import HintForSamsung from "assets/images/hint/HintForSamsung.png";
import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";

import "./MicrophoneHintModal.scss";

const checkIfIPhone = () => {
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  return /iPhone|iPad|iPod/i.test(userAgent);
};

const MicrophoneHintModal = ({
  openSlide,
  mainElementRef,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  const [isIPhone, setIsIPhone] = useState(false);

  useEffect(() => {
    setIsIPhone(checkIfIPhone());
  }, []);

  const MicrophoneHintModalHeader = (
    <div className="MicrophoneHintModalHeader">
      <h6 className="MicrophoneHintModalHeaderTitle">
        {t("record.youCanUseMicrophone")}
      </h6>
    </div>
  );
  const MicrophoneHintModalBody = (
    <div className="MicrophoneHintModalBody">
      <img alt="HintForApple" src={isIPhone ? HintForApple : HintForSamsung} />
    </div>
  );
  const MicrophoneHintModalFooter = (
    <div className="MicrophoneHintModalFooter" onClick={handleCloseModal}>
      <h5 className="SemiBold MicrophoneHintModalFooterTitle">
        {t("buttons.close")}
      </h5>
    </div>
  );

  return (
    <GuestModal
      header={MicrophoneHintModalHeader}
      body={MicrophoneHintModalBody}
      footer={MicrophoneHintModalFooter}
      mainElementRef={mainElementRef}
      containerClassname="MicrophoneModalContainer"
      openSlide={openSlide}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.CENTER}
    />
  );
};
MicrophoneHintModal.propTypes = {
  openSlide: PropTypes.bool,
  mainElementRef: PropTypes.object,
  handleCloseModal: PropTypes.func,
};
export default MicrophoneHintModal;
