import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { ReactComponent as GuestIcon } from "assets/icons/reserve/guestIcon.svg";
import { ReactComponent as CallIcon } from "assets/icons/reserve/phone.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock/ClockCircle.svg";
import { ReactComponent as GuestCountIcon } from "assets/icons/reserve/People.svg";
import { ReactComponent as TableIcon } from "assets/icons/reserve/Table.svg";
import { formatTimeForReservationDatePicker } from "utils/helpers";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./ReservationStepperPreview.scss";

const ReservationStepperPreview = ({ formData }) => {
  const {
    business,
    table,
    guestsCount,
    date,
    guestName,
    guestPhone,
    startDateTime,
    endDateTime,
  } = formData;
  const { t } = useTranslation();
  const editedStartDateTime = formatTimeForReservationDatePicker(startDateTime);
  const editedEndDateTime = formatTimeForReservationDatePicker(endDateTime);
  return (
    <div className="ReservationStepperPreview">
      <div className="ReservationStepperPreviewHeader">
        <div className="ReservationStepperPreviewBusiness">
          <div className="ReservationStepperPreviewBusinessImage">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(business?.images.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="logo"
            />
          </div>
          <h3>{business?.name}</h3>
        </div>
      </div>
      <div className="ReservationStepperPreviewDetail">
        <div className="ReservationStepperPreviewDetailDate">
          <div>
            <TableIcon className="ReservationStepperPreviewDetailCalendarIcon" />
            {table?.name ? (
              <div className="ReservationStepperPreviewDetailTableInfo">
                <h6 className="Medium">{table?.name}</h6>
                <div>
                  <h5>{"("}</h5>
                  <h6 className="Medium">{table?.zoneName}</h6>
                  <h5>{")"}</h5>
                </div>
              </div>
            ) : (
              <span>{t("reservation.tableNotFound")}</span>
            )}
          </div>
          <div>
            <CalendarIcon className="ReservationStepperPreviewDetailCalendarIcon" />
            <span>
              {date ? (
                <input
                  className="ReservationDetailInputCalendar"
                  disabled={true}
                  name="date"
                  type="date"
                  value={date}
                />
              ) : (
                "-"
              )}
            </span>
          </div>
        </div>
        <div className="ReservationStepperPreviewDetailGuest">
          <div>
            <GuestCountIcon className="ReservationStepperPreviewDetailCalendarIcon" />
            <span>
              {guestsCount ? `${guestsCount} ${t("guests.guest")}` : "-"}
            </span>
          </div>
          <div>
            <ClockIcon className="ReservationStepperPreviewDetailClockIcon" />
            <span>
              {startDateTime && endDateTime
                ? `${editedStartDateTime} - ${editedEndDateTime}`
                : "-"}
            </span>
          </div>
        </div>
      </div>
      {(guestName || guestPhone) && (
        <div className="ReservationStepperPreviewDetailGuestContact">
          <div>
            <GuestIcon className="ReservationStepperPreviewDetailCalendarIcon" />
            <span>{guestName ? guestName : "-"}</span>
          </div>
          <div>
            <CallIcon className="ReservationStepperPreviewDetailCalendarIcon" />
            {guestPhone ? (
              <PhoneNumberFormatter
                phoneNumber={guestPhone}
                className="h6 Medium textWhite"
              />
            ) : (
              <span>-</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
ReservationStepperPreview.propTypes = {
  formData: PropTypes.shape({
    business: PropTypes.shape({
      name: PropTypes.string,
      images: PropTypes.shape({
        logo: PropTypes.string,
      }),
    }),
    table: PropTypes.shape({
      name: PropTypes.string,
      zoneName: PropTypes.string,
    }),
    guestsCount: PropTypes.number,
    date: PropTypes.string,
    time: PropTypes.string,
    guestName: PropTypes.string,
    guestPhone: PropTypes.string,
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
  }).isRequired,
  activeStep: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};
export default React.memo(ReservationStepperPreview);
