import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import { STORE_NAMES } from "utils/constants/redux";
import {
  filterMenuItemByIsPublishedAndSchedule,
  findItemById,
} from "utils/general";
import useTimeout from "utils/hooks/useTimeout";
import {
  findGuest,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import Loya from "assets/icons/menuv2/Loya.png";
import MenuCard, {
  ENUMS as MENU_CARD_ENUMS,
} from "pages/client/menu-v2/MenuCard/MenuCard";

import "./BestChoiceModal.scss";

const ResultBody = ({
  result,
  handleAddToBasket,
  favoriteItems,
  handleOnClick,
  handleFavItem,
}) => {
  const filteredResultItems = filterMenuItemByIsPublishedAndSchedule(
    result.items
  );

  const [addBasketAnimation, setAddBasketAnimation] = useState(false);

  const handleAddBasketCustom = (item) => {
    handleAddToBasket([item.id]);
  };

  useTimeout({
    callback: () => {
      setAddBasketAnimation(false);
    },
    delay: addBasketAnimation ? 1000 : null,
  });

  return (
    filteredResultItems.length > 0 && (
      <div className="ResultItems">
        <div className="ResultItemTitle">
          <h5 className="SemiBold">{result.title}</h5>
          <h6 className="Medium">{result.reason}</h6>
        </div>
        <div className="ResultItemDetails">
          {filteredResultItems.map((item, index) => (
            <MenuCard
              key={index}
              menuItem={item}
              onFavorite={() => handleFavItem(item)}
              onAddToBasket={handleAddBasketCustom}
              isFavorite={favoriteItems.includes(item.id)}
              onClick={() => handleOnClick(item)}
              type={MENU_CARD_ENUMS.types.GRID}
              favoriteItems={favoriteItems}
              className="ResultItemMenuCard"
            />
          ))}
        </div>
      </div>
    )
  );
};

ResultBody.propTypes = {
  handleAddToBasket: PropTypes.func,
  favoriteItems: PropTypes.array,
  handleOnClick: PropTypes.func,
  result: PropTypes.object,
  handleFavItem: PropTypes.func,
};

const BestChoiceModal = ({ results }) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const dispatch = useDispatch();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const { navigate } = useInternalNavigation();
  const { businessId } = useParams();
  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);
  const favoriteItems = guest?.favoriteItems || [];

  const handleAddToBasket = (selectedItems) => {
    const addedMenuItems = selectedItems.map((itemId) =>
      findItemById(itemId, menu)
    );

    addedMenuItems.forEach((menuItem) => {
      const modifications = getBasketModificationsWithDefaultValues(
        menuItem.modifications
      );
      const filteredModifications = modifications.filter((modification) => {
        return modification.options.length !== 0;
      });
      dispatch(
        updateBasket({
          menuItem: {
            id: menuItem.id,
            modifications: filteredModifications.map((modification) => {
              return {
                id: modification.id,
                options: modification.options.map((option) => {
                  return {
                    id: option.id,
                    count: option.count ?? 1,
                  };
                }),
              };
            }),
          },
          count: 1,
          userId: guestId,
        })
      );
    });
  };

  const handleOnClick = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  const handleFavItem = (menuItem) => {
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  return (
    <div className="BestChoiceModalBody">
      <div className="LoyaIconWrapper">
        <img className="LoyaIcon" src={Loya} alt="Loya" />
      </div>

      <div className="ResultsWrapper">
        {results.map((result, index) => {
          return (
            <ResultBody
              key={index}
              result={result}
              handleAddToBasket={handleAddToBasket}
              handleOnClick={handleOnClick}
              favoriteItems={favoriteItems}
              handleFavItem={handleFavItem}
            />
          );
        })}
      </div>
    </div>
  );
};
BestChoiceModal.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
};
export default BestChoiceModal;
