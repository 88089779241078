import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import GuestIcon from "assets/icons/profile/Users.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import { ReactComponent as HappyBirthday } from "assets/icons/birthday/happybirthday.svg";
import EmptyZoneIcon from "assets/icons/qr/Table.svg";
import NoOrderIcon from "assets/icons/other/NoOrder.svg";

import EmptyState from "components/admin/empty-state/EmptyState";
import AdminOrderGuestInfoModal from "pages/admin/admin-pages/admin-order/admin-order-tables/admin-order-guest-info-modal/AdminOrderGuestInfoModal";
import AdminOrderTable from "pages/admin/admin-pages/admin-order/admin-order-tables/admin-order-table/AdminOrderTable";
import AdminOrderHeader from "pages/admin/admin-pages/admin-order/admin-order-tables/admin-order-header/AdminOrderHeader";
import If from "components/if/If";

import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import { sortActiveGuest } from "utils/helper-functions/dashboard/activeGuestHelper";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ORDER_ACTION_STATE } from "utils/constants/data/menu-model";
import { STORE_NAMES } from "utils/constants/redux";
import {
  checkDateEqualCurrentDate,
  concatFirstNameWithLastName,
  findZoneOfTable,
} from "utils/helpers";

import "./AdminOrderTables.scss";

export const ADMIN_ORDER_VIEW_MODE_WITH_GUESTS = {
  standard: "STANDARD",
  guests: "Guests",
};
const MIN_WIDTH_TABLE = 148;
const TABLES_GAP = 16;
const CONTAINER_PADDING_TOTAL = 60;

const AdminOrderTables = ({ onClickTable }) => {
  const { t } = useTranslation();
  const tablesRef = useRef(null);
  const [selectedAction, setSelectedAction] = useState([]);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  const user = useSelector((state) => state[STORE_NAMES.user].user);
  const [guestId, setGuestId] = useState(null);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const isTableFull = (tableID) => {
    return orders.find((order) => order.table.id === tableID);
  };
  const [orderViewWithGuests, setOrderViewWithGuests] = useState(
    ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard
  );
  let initialZone = {
    name: t("buttons.fullTables"),
    id: 0,
  };
  const [activeZone, setActiveZone] = useState(initialZone);

  const [
    openSlideGuestInfoModal,
    setOpenSlideGuestInfoModal,
    mainElementRefGuestInfoModal,
  ] = useOutsideClick();

  const selectFullTables = () => {
    const allTables = [];
    zones.forEach((zone) => {
      zone.tables.forEach((table) => {
        isTableFull(table.id) && allTables.push(table);
      });
    });
    return {
      ...initialZone,
      tables: allTables,
    };
  };
  const allGuests = orders.flatMap((order) => order.guests);

  const activeZoneTables =
    zones?.find((zone) => {
      return zone.id === activeZone?.id;
    }) || selectFullTables();

  const activeTables = useMemo(() => {
    return activeZoneTables?.tables
      .filter((table) => {
        if (selectedAction.length > 0) {
          return orders.some((order) => {
            return selectedAction.some(
              (action) => order.table.id === table.id && order[action.id]
            );
          });
        }
        return true;
      })
      .map((el) => {
        const selectedOrder = orders.find((o) => o.table.id === el.id);
        const currentTopic = chat?.find(
          (topic) => topic.id === selectedOrder?.id
        );
        const lastMessage =
          currentTopic?.messages[currentTopic.messages.length - 1];
        const lastReadTime = currentTopic?.usersLastReadTime.find(
          (item) => item.userId === user.id
        )?.dateTime;

        let hasNewMessage = false;
        if (lastMessage?.author?.id !== user.id) {
          hasNewMessage = lastMessage?.dateTime
            ? !lastReadTime || lastReadTime < lastMessage.dateTime
            : false;
        }

        return {
          ...el,
          askForBill: selectedOrder?.actionBill,
          callWaiter: selectedOrder?.actionWaiter,
          hasNewMessage,
          hasNewOrder: selectedOrder?.hasNewOrder,
          createDate: selectedOrder?.createDate,
        };
      });
  }, [activeZoneTables, selectedAction, orders, chat, user.id]);

  useEffect(() => {
    if (activeZone?.id === initialZone?.id) {
      setActiveZone({
        ...initialZone,
        name: t("buttons.fullTables"),
        tables: activeZoneTables,
      });
    }
  }, [activeZone?.id, activeZoneTables?.tables?.length, t]);

  const handleOnFilterOrdersByAction = (action) => {
    const actionIsSelected = selectedAction.find((z) => z.id === action.id);
    if (actionIsSelected) {
      const updatedActions = selectedAction.filter((z) => z.id !== action.id);
      setSelectedAction(updatedActions);
    } else {
      setSelectedAction((prev) => [...prev, action]);
    }
  };

  const handleZoneChange = (zone) => {
    zone && setActiveZone(zone);
  };
  const orderFilterOptions = useMemo(() => {
    return [
      {
        id: ORDER_ACTION_STATE.actionBill,
        name: t("waiter.bill"),
        isEnabled: business?.isAskForBillEnabled,
      },
      {
        id: ORDER_ACTION_STATE.actionWaiter,
        name: t("waiter.call"),
        isEnabled: business?.isCallWaiterEnabled,
      },
    ].filter((orderOption) => orderOption.isEnabled);
  }, [business?.isAskForBillEnabled, business?.isCallWaiterEnabled, t]);

  const allTablesLength = zones.flatMap((zone) => zone.tables).length;

  const allTablesOfActiveZone =
    activeZone?.id === initialZone.id
      ? allTablesLength
      : activeZoneTables.tables.length;

  const fullTablesOfActiveZone = activeZoneTables.tables.filter(({ id }) =>
    isTableFull(id)
  );

  const messageCountOfOrdersByActiveZone = activeTables.filter(
    (table) => table.hasNewMessage
  ).length;

  const showOrderWithGuests = () => {
    switch (orderViewWithGuests) {
      case ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard:
        setOrderViewWithGuests(ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests);
        break;
      case ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests:
        setOrderViewWithGuests(ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard);
    }
  };

  const allGuestsWithDetails = allGuests.map((guest) => {
    const order = orders.find((order) =>
      order.guests.some((g) => g.id === guest.id)
    );
    if (order) {
      const table = order.table;
      const zone = zones.find((zone) =>
        zone.tables.some((t) => t.id === table.id)
      );
      return {
        ...guest,
        tableName: table.name,
        zoneName: zone ? zone.name : "",
      };
    }
    return guest;
  });

  const handleCloseModal = () => {
    setOpenSlideGuestInfoModal(false);
  };
  const handleOpenModal = (id) => {
    setOpenSlideGuestInfoModal(true);
    setGuestId(id);
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!tablesRef.current) return;
      const containerWidth = tablesRef.current.clientWidth;
      const repeatCount = Math.floor(
        (containerWidth - CONTAINER_PADDING_TOTAL + TABLES_GAP) /
          (MIN_WIDTH_TABLE + TABLES_GAP)
      );
      tablesRef.current.style.gridTemplateColumns = `repeat(${repeatCount}, 1fr)`;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [orderViewWithGuests, activeTables]);

  return (
    <div className="AdminOrderTablesContainer">
      <AdminOrderHeader
        business={business}
        fullTablesOfActiveZone={fullTablesOfActiveZone}
        showOrderWithGuests={showOrderWithGuests}
        messageCountOfOrdersByActiveZone={messageCountOfOrdersByActiveZone}
        handleOnFilterOrdersByAction={handleOnFilterOrdersByAction}
        handleZoneChange={handleZoneChange}
        orderFilterOptions={orderFilterOptions}
        initialZone={initialZone}
        zones={zones}
        activeZone={activeZone}
        selectedAction={selectedAction}
        orderViewWithGuests={orderViewWithGuests}
      />
      <div className="AdminOrderTablesSections">
        <If state={activeTables.length > 0}>
          <div className="AdminOrderTables" ref={tablesRef}>
            {activeTables.map((table) => {
              const hasNewNotification =
                (table.askForBill && business.isAskForBillEnabled) ||
                (table.callWaiter && business.isCallWaiterEnabled) ||
                (table.hasNewMessage && business.chat) ||
                table.hasNewOrder;
              return (
                <AdminOrderTable
                  key={table.id}
                  onClickTable={() => onClickTable(table)}
                  table={table}
                  zoneName={`${findZoneOfTable(zones, table.id).name}`}
                  newNotification={hasNewNotification}
                  hasOrder={isTableFull(table.id)}
                  business={business}
                />
              );
            })}
          </div>
        </If>
        <If state={allTablesOfActiveZone === 0}>
          <EmptyState
            icon={EmptyZoneIcon}
            description={t("emptyTable.emptyZoneDescription")}
            isAdmin={true}
          />
        </If>
        <If state={allTablesOfActiveZone !== 0 && activeTables.length === 0}>
          <EmptyState
            icon={NoOrderIcon}
            description={t("emptyStates.noOrders")}
            isAdmin={true}
          />
        </If>
        <If
          state={
            orderViewWithGuests === ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests
          }
        >
          <div className="AdminOrderTablesSectionGuests">
            <h4 className="SemiBold">{`${t("orders.guests")} (${
              allGuestsWithDetails.length
            })`}</h4>
            {allGuestsWithDetails.length > 0 ? (
              <div className="AdminOrderTablesSectionGuestsContainer">
                {sortActiveGuest(allGuestsWithDetails)?.map((guest, index) => {
                  const isBirthday = checkDateEqualCurrentDate(
                    guest.dateOfBirth
                  );
                  return (
                    <div className="AdminOrderTablesSectionGuest" key={index}>
                      <div
                        key={index}
                        className="AdminOrderTablesSectionGuestInfo"
                        onClick={() => handleOpenModal(guest.person.id)}
                      >
                        <div className="GuestInfoProfilePicContainer">
                          {isBirthday && (
                            <BirthdayHat className="BirthdayHat" />
                          )}
                          <ImageWithPlaceholder
                            imageSource={createDOBucketName(guest?.profilePic)}
                            placeholder={IMG_GUEST}
                            alt="logo"
                          />
                        </div>
                        <div>
                          <h6 className="Medium">
                            {concatFirstNameWithLastName({
                              firstName: guest?.firstName,
                              lastName: guest?.lastName,
                            }) ||
                              `${t("dashboard.guest.guest")} ${
                                guest?.person.id
                              }`}
                          </h6>
                          <span>{`${guest?.tableName} (${guest?.zoneName})`}</span>
                        </div>
                      </div>
                      {isBirthday && (
                        <div className="HappyBirthdayContainer">
                          <HappyBirthday className="HappyBirthday" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <EmptyState
                icon={GuestIcon}
                description={t("emptyStates.noGuests")}
                isAdmin={true}
              />
            )}
          </div>
        </If>
      </div>
      {guestId && (
        <AdminOrderGuestInfoModal
          openSlide={openSlideGuestInfoModal}
          mainElementRef={mainElementRefGuestInfoModal}
          handleCloseModal={handleCloseModal}
          guestId={guestId}
        />
      )}
    </div>
  );
};
AdminOrderTables.propTypes = {
  /**
   * Function to handle click table
   */
  onClickTable: PropTypes.func,
  /**
   * Array for newMessage
   */
  newMessage: PropTypes.array,
};

export default AdminOrderTables;
