import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getBrandMainLogo } from "utils/branding-helper";
import { ROUTE_NAME } from "utils/constants/routes";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import OrSignUpWith from "pages/client/auth/sign-up/or-sign-up-with/OrSignUpWith";
import useLogoutGuest from "utils/hooks/useGuestLogout";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import AnimatedWord from "components/guest-account-info-modal/animated-word/AnimatedWord";

import "./Auth.scss";

const MainLogo = getBrandMainLogo();

const Auth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessId } = useParams();
  const messages = [
    t("auth.message.first"),
    t("auth.message.second"),
    t("auth.message.third"),
    t("auth.message.fourth"),
    t("auth.message.first"),
  ];
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.auth });
  const { logoutGuest } = useLogoutGuest();
  useEffect(() => {
    logoutGuest();
  }, []);

  const handleSignUp = () => {
    businessId
      ? navigate(
          `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`
        )
      : navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`);
  };
  const handleSignIn = () => {
    businessId
      ? navigate(
          `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`
        )
      : navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`);
  };

  const GuestAuthHeader = (
    <div className="GuestAuthHeader">
      <MainLogo />
    </div>
  );

  const GuestAuthContent = (
    <div className="GuestAuthContent">
      <div className="GuestAuthBody">
        <AnimatedWord messages={messages} firstText={t("auth.signUp")} />
      </div>
      <div className="GuestAuthFooterButtons">
        <PrimaryButton
          onClick={handleSignUp}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_Z}
          text={t("login.signUp.title")}
        />
        <PrimaryButton
          onClick={handleSignIn}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_S}
          text={t("buttons.login")}
        />
      </div>
      <OrSignUpWith />
    </div>
  );

  return (
    <div className="GuestAuth">
      {GuestAuthHeader}
      {GuestAuthContent}
    </div>
  );
};

export default Auth;
