import React, { useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Dropdown from "components/pos/dropdown/Dropdown";
import If from "components/if/If";
import CreateGenericQr from "pages/admin/admin-pages/admin-qr/generate-qr-modal/create-generic-qr/CreateGenericQr";
import CreateCategoryQr from "pages/admin/admin-pages/admin-qr/generate-qr-modal/create-category-qr/CreateCategoryQr";

import styles from "./GenerateQrModal.module.scss";

const GenerateQrModal = () => {
  const { t } = useTranslation();
  const DROPDOWN_OPTIONS = {
    CUSTOM: {
      id: 1,
      key: "CUSTOM",
      name: t("qr.custom"),
    },
    FOR_EACH_CATEGORY: {
      id: 2,
      key: "FOR_EACH_CATEGORY",
      name: t("qr.forEachCategory"),
    },
  };
  const [selectedOption, setSelectedOptions] = useState(
    DROPDOWN_OPTIONS.CUSTOM
  );
  const handleChangeOption = (option) => {
    setSelectedOptions(option);
  };

  return (
    <div className={cx(styles.Root)}>
      <div className={cx(styles.Container)}>
        <div className={styles.Header}>
          <h1 className={cx(styles.Title, "Semibold")}>{t("qr.generateQR")}</h1>
          <Dropdown
            onChange={handleChangeOption}
            value={selectedOption}
            options={Object.values(DROPDOWN_OPTIONS)}
            className={styles.Dropdown}
          />
        </div>
        <div className={cx(styles.Main)}>
          <If state={selectedOption.key === DROPDOWN_OPTIONS.CUSTOM.key}>
            <CreateGenericQr />
          </If>
          <If
            state={
              selectedOption.key === DROPDOWN_OPTIONS.FOR_EACH_CATEGORY.key
            }
          >
            <CreateCategoryQr />
          </If>
        </div>
      </div>
    </div>
  );
};

export default GenerateQrModal;
