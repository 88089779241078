import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/hooks/useLanguage";

import { TableV2 } from "components/table/TableV2";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import useAsync from "utils/hooks/useAsync";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { getStatsMenuStatisticsTableData } from "utils/api/services/dashboard";
import { findMenuItemById } from "utils/helpers";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as SortArrowDefault } from "assets/icons/admin-dashboard/sortArrowDefault.svg";
import { ReactComponent as SortArrowDesc } from "assets/icons/admin-dashboard/sortArrowDesc.svg";
import { ReactComponent as SortArrowAsc } from "assets/icons/admin-dashboard/sortArrowAsc.svg";
import Spinner from "components/elements/spinner/Spinner";
import EmptyTableIcon from "assets/icons/admin-dashboard/EmptyTable.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "./AdminDashAllMenus.scss";

const TABLE_KEYS = [
  {
    value: "id",
    valueForTranslation: "dashboard.table.menuStatisticsTable.menuItem",
    isSortable: false,
  },
  {
    value: "category",
    valueForTranslation: "dashboard.table.menuStatisticsTable.category",
    isSortable: false,
  },
  {
    value: "orderCount",
    valueForTranslation: "dashboard.table.menuStatisticsTable.order",
    isSortable: true,
  },
  {
    value: "price",
    valueForTranslation: "dashboard.table.menuStatisticsTable.price",
    isSortable: true,
  },
  {
    value: "cost",
    valueForTranslation: "dashboard.table.menuStatisticsTable.cost",
    isSortable: true,
  },
  {
    value: "revenue",
    valueForTranslation: "dashboard.table.menuStatisticsTable.revenue",
    isSortable: true,
  },
  {
    value: "totalSale",
    valueForTranslation: "dashboard.table.menuStatisticsTable.total",
    isSortable: true,
  },
];

const meta = {
  breakpoints: [
    {
      BreakPointsRange: {
        max: -1,
        min: 1800,
      },
      cellDisplayAndProportions: [
        20,
        80 / 6,
        80 / 6,
        80 / 6,
        80 / 6,
        80 / 6,
        80 / 6,
      ],
    },
    {
      BreakPointsRange: {
        max: 1799,
        min: 1100,
      },
      cellDisplayAndProportions: [21, 13, 13, 12, 10, 14, 12],
    },
    {
      BreakPointsRange: {
        max: 1099,
        min: 700,
      },
      cellDisplayAndProportions: [30, 20, null, null, 25, null, 25],
    },
    {
      BreakPointsRange: {
        max: 699,
        min: 300,
      },
      cellDisplayAndProportions: [60, null, null, null, 40, null, null],
    },
  ],
};

const AdminDashAllMenus = () => {
  const { setTitle, fromDateToDate } = useOutletContext();
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const [categoryTable, setCategoryTable] = useState("");
  const [sortType, setSortType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [rows, setRows] = useState([]);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const allCategories = useSelector(
    (state) => state[STORE_NAMES.menu].data?.categories
  );

  const { menuCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );

  const getMenuItemName = (itemId) =>
    findMenuItemById(allCategories, itemId)?.name[0].value;

  const getMenuItemPicture = (itemId) =>
    createDOBucketName(findMenuItemById(allCategories, itemId)?.coverImageSrc);

  const getCategoryName = (ctgId) =>
    allCategories.find((ctg) => ctg.id == ctgId)?.name[0]?.value;

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.allItems"));
  }, [t]);

  const handleOnCategoryTableDropdownChange = (option) => {
    setCategoryTable(option);
  };

  const { loading, result: fetchedMenuStatsTableData } = useAsync(
    getStatsMenuStatisticsTableData,
    {
      immediate: true,
      params: [
        businessId,
        fromDateToDate.from,
        fromDateToDate.to,
        500,
        1,
        sortBy,
        sortType,
        categoryTable?.id,
      ],
    }
  );

  const updatedAllCategories = allCategories
    ?.filter((category) => category.type !== menuCategoryType.special_category)
    .map((category) => {
      const updatedCategoryName = displayDataByLanguage(category?.name);
      const updatedMenuItems = category?.menuItems?.map((menuItem) => ({
        ...menuItem,
        name: displayDataByLanguage(menuItem?.name),
      }));

      return {
        ...category,
        name: updatedCategoryName,
        menuItems: updatedMenuItems,
      };
    });

  updatedAllCategories?.unshift({ name: t("dashboard.all"), id: undefined });

  useEffect(() => {
    setRows(createRows(fetchedMenuStatsTableData));
  }, [fetchedMenuStatsTableData]);

  const createRows = (data) => {
    return data?.data?.map((menuitem, itemIndex) => [
      {
        element: (
          <div key={`${itemIndex}-name`} className="TableMainInfoPart">
            <p>{itemIndex + 1}</p>
            <div className="ImageNameContainer">
              <ImageWithPlaceholder
                imageSource={getMenuItemPicture(menuitem.itemId)}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt="menu item picture"
              />
              <p>{getMenuItemName(menuitem.itemId)}</p>
            </div>
          </div>
        ),
      },
      {
        element: (
          <p key={`${itemIndex}-category`}>
            {getCategoryName(menuitem.categoryId)}
          </p>
        ),
      },
      { element: <p key={`${itemIndex}-order`}>{menuitem.orderCount}</p> },
      {
        element: (
          <p key={`${itemIndex}-price`}>
            {menuCurrency.symbol} {menuitem.price}
          </p>
        ),
      },
      {
        element: (
          <p key={`${itemIndex}-cost`}>
            {menuCurrency.symbol} {menuitem.cost}
          </p>
        ),
      },
      {
        element: (
          <p key={`${itemIndex}-revenue`}>
            {menuCurrency.symbol} {menuitem.revenue}
          </p>
        ),
      },
      {
        element: (
          <p key={`${itemIndex}-totalSale`}>
            {menuCurrency.symbol} {menuitem.totalSale}
          </p>
        ),
      },
    ]);
  };

  const handleSort = (header) => {
    if (rows.length <= 1) return;
    if (!header.isSortable) return;
    if (header.value == sortBy) {
      if (sortType === "DESC") {
        setSortBy("");
        setSortType("");
        return;
      }
      setSortType(sortType === "ASC" ? "DESC" : "ASC");
      return;
    }

    setSortBy(header.value);
    setSortType("ASC");
  };

  const headers = TABLE_KEYS.map((header, index) => {
    return {
      element: (
        <div
          key={index}
          className="headerCell"
          onClick={() => handleSort(header)}
        >
          <p>{t(header.valueForTranslation)}</p>
          {header.isSortable && (
            <>
              {sortBy !== header.value ? (
                <SortArrowDefault />
              ) : sortType === "ASC" ? (
                <SortArrowAsc />
              ) : sortType === "DESC" ? (
                <SortArrowDesc />
              ) : null}
            </>
          )}
        </div>
      ),
    };
  });

  const EmptyTable = () => {
    return (
      <div className={`AdminDashHistoryFormAndTableEmpty`}>
        <img src={EmptyTableIcon} alt="Empty Table" />
        <h4 className="Medium AdminDashHistoryFormAndTableEmptyInfo">
          {t("emptyStates.noTableData")}
        </h4>
      </div>
    );
  };

  return (
    <div className="AdminDashAllMenus">
      <div className="Form">
        <Dropdown
          className={"CustomDropDown"}
          value={categoryTable || updatedAllCategories?.[0] || ""}
          onChange={(category) => handleOnCategoryTableDropdownChange(category)}
          placeholder={t("dashboard.placeholder.categories")}
          options={updatedAllCategories}
        />
      </div>

      <TableV2
        meta={meta}
        headers={headers}
        rows={rows}
        loading={loading}
        LoadingComponent={<Spinner className={"TableSpinner"} />}
        EmptyComponent={<EmptyTable />}
      />
    </div>
  );
};

export default AdminDashAllMenus;
