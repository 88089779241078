import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as CheckIcon } from "assets/icons/check/Check.svg";
import { ReactComponent as MinusIcon } from "assets/icons/pos/minus.svg";

import If from "components/if/If";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
  isChecked,
  onChange,
  label,
  isDisabled,
  isIndeterminate = false,
}) => {
  return (
    <div
      className={cx(styles.Checkbox, {
        [styles.isChecked]: isChecked,
        [styles.isDisabled]: isDisabled,
      })}
    >
      <div className={styles.Icon} onClick={onChange}>
        <If state={isChecked && !isIndeterminate}>
          <CheckIcon />
        </If>
        <If state={isChecked && isIndeterminate}>
          <MinusIcon />
        </If>
      </div>
      <If state={Boolean(label)}>
        <h5 className="Medium" onClick={onChange}>
          {label}
        </h5>
      </If>
    </div>
  );
};

Checkbox.propTypes = {
  /**
   * Indicates whether the checkbox is checked or not
   */
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,

  /**
   * Callback function called when the checkbox is clicked
   */
  onChange: PropTypes.func,

  /**
   * The label to display alongside the checkbox
   */
  label: PropTypes.string,
  isIndeterminate: PropTypes.bool,
};

export default Checkbox;
