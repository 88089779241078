import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./DropdownOption.module.scss";

const DropdownOption = ({ option, isActive, handleOptionClick, className }) => {
  const { imgSrc, code, name } = option;

  return (
    <div
      className={cx(styles.DropdownOption, className, {
        [styles.isActive]: isActive,
      })}
      onClick={() => handleOptionClick(option)}
    >
      <div className={styles.OptionWithIcon}>
        <img src={imgSrc} alt={name} />
        <div className={styles.OptionWithIconTitle}>
          <h6 className="Medium OptionName WithIconOptionName">{code}</h6>
        </div>
      </div>
    </div>
  );
};

DropdownOption.propTypes = {
  option: PropTypes.shape({
    code: PropTypes.string,
    codeISO: PropTypes.string,
    id: PropTypes.number,
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    nativeName: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  handleOptionClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default DropdownOption;
