import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import MenuCard, { ENUMS } from "pages/client/menu-v2/MenuCard/MenuCard";
import EmptyState from "components/admin/empty-state/EmptyState";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import useDebounce from "utils/hooks/useDebounce";
import { advanceSearch } from "utils/algorithms";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import SearchIcon from "assets/icons/chat/search.svg";
import TypingEffect from "components/typing-effect/TypingEffect";
import { STORE_NAMES } from "utils/constants/redux";
import { updateBasket } from "redux/slices/basketStore";
import { ROUTE_NAME } from "utils/constants/routes";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import GuestModal, {
  COMPONENT_SIZES,
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import useTimeout from "utils/hooks/useTimeout";
import Recommendations, {
  RECOMMENDATIONS_TYPE,
} from "pages/client/recommendations/Recommendations";
import If from "components/if/If";
import PromotionAndBanner from "pages/client/menu-v2/promotion-and-banner/PromotionAndBanner";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import styles from "./MenuSearchModal.module.scss";

const MenuSearchModal = ({ openSlide, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigate } = useInternalNavigation();

  const inputRef = useRef(null);
  const { businessId } = useParams();
  const { promotion, allCategories } = useMenuHelper();
  const { extraVisibility, menuItemCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const debouncedRequest = useDebounce(() => {
    setSearchValue(debouncedSearchValue);
  }, 100);

  useTimeout({
    callback: () => {
      inputRef.current?.focus();
    },
    delay: openSlide ? 400 : null,
  });

  const displayedMenuItems = useMemo(() => {
    const allMenuItems = allCategories.flatMap(
      (category) => category.menuItems
    );

    if (searchValue.trim().length === 0 || allMenuItems.length === 0) {
      return [];
    }

    const lowerCasedSearchValue = searchValue.trim().toLowerCase();

    const filteredItems = allMenuItems
      .filter((menuItem) => {
        const matchesName = menuItem.name.some((nameObj) => {
          const menuItemNameInAnyLanguage = nameObj.value.toLowerCase();
          return advanceSearch({
            string: menuItemNameInAnyLanguage,
            searchString: lowerCasedSearchValue,
          });
        });

        const matchesDescription = menuItem.description
          ? menuItem.description.some((descObj) => {
              const descriptionInAnyLanguage = descObj.value
                ? descObj.value.toLowerCase()
                : "";
              return advanceSearch({
                string: descriptionInAnyLanguage,
                searchString: lowerCasedSearchValue,
              });
            })
          : false;

        const matchesTags =
          menuItem.tags.length > 0
            ? menuItem.tags.some((tag) =>
                tag.name.some((tagNameObj) => {
                  const tagNameInAnyLanguage = tagNameObj.value.toLowerCase();
                  return advanceSearch({
                    string: tagNameInAnyLanguage,
                    searchString: lowerCasedSearchValue,
                  });
                })
              )
            : false;
        return matchesName || matchesDescription || matchesTags;
      })
      .filter((item) => item.ctaType === menuItemCategoryType.menu_items);

    return Array.from(
      new Map(filteredItems.map((item) => [item.id, item])).values()
    );
  }, [allCategories, searchValue]);

  const handleSearchChange = (value) => {
    const trimmedValue = value.trimStart();
    setDebouncedSearchValue(trimmedValue);
    debouncedRequest();
  };

  const handleAddToBasket = (menuItem) => {
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    const payload = {
      menuItem: {
        id: menuItem.id,
        modifications: modifications.map((modification) => {
          return {
            id: modification.id,
            options: modification.options.map((option) => {
              return {
                id: option.id,
                count: option.count ?? 1,
              };
            }),
          };
        }),
      },
      count: 1,
      userId: guestId,
    };

    dispatch(updateBasket(payload));
  };

  const handleClickMenuItem = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}`,
    });
  };

  const onClearHandler = () => {
    setSearchValue("");
    setDebouncedSearchValue("");
  };

  const hasVisibleRecommendation = allCategories.some(
    (category) =>
      category.extraVisibility === extraVisibility.search_recommended
  );

  const SearchResults = displayedMenuItems.map((menuItem, index) => {
    return (
      <div
        key={menuItem.id}
        className={styles.Delay}
        style={{
          animationDelay: `${(index + 1) / 10}s`,
        }}
      >
        <MenuCard
          menuItem={menuItem}
          onAddToBasket={handleAddToBasket}
          type={ENUMS.types.SMALL}
          onClick={() => handleClickMenuItem(menuItem)}
          onFavorite={() => {}}
        />
      </div>
    );
  });

  const shouldShowSearchResult =
    searchValue.trim().length > 0 && displayedMenuItems.length > 0;

  const shouldShowEmptyState =
    searchValue.trim().length > 0 && displayedMenuItems.length === 0;

  const modalHeader = (
    <div className={styles.Header}>
      <h4 className={`${styles.Title} SemiBold`}>{t("inputs.search")}</h4>
      <CloseButton
        onClick={() => {
          onClearHandler();
          onCancel();
        }}
        type={ENUMS_CLOSE_BUTTON.types.TYPE_S}
        className={styles.SearchCloseButton}
      />
    </div>
  );

  const texts = useMemo(() => [t("menu.search")], [t]);

  const modalBody = (
    <div className={styles.Body}>
      <TypingEffect
        texts={texts}
        deletingSpeed={5}
        textContainer={(displayText) => (
          <Search
            inputRef={inputRef}
            value={debouncedSearchValue}
            onChange={handleSearchChange}
            placeholder={displayText}
            type={SEARCH_ENUMS.types.TYPE_S}
            onClear={onClearHandler}
            classname={styles.Input}
          />
        )}
      />
    </div>
  );

  const modalFooter = (
    <div className={styles.Wrapper}>
      <If state={!shouldShowSearchResult && !shouldShowEmptyState}>
        <div
          className={cx(styles.PromoAndRecommendations, {
            [styles.NoPromotion]: !promotion,
          })}
          style={{
            padding: promotion ? undefined : 0,
          }}
        >
          <If state={Boolean(promotion)}>
            <PromotionAndBanner delay={"0.1s"} category={promotion} />
          </If>
          <If state={hasVisibleRecommendation}>
            <Recommendations
              delay={`${promotion ? "0.2s" : "0.1s"}`}
              recommendCategories={allCategories}
              onAdd={handleAddToBasket}
              type={RECOMMENDATIONS_TYPE.search}
            />
          </If>
        </div>
      </If>
      <If state={shouldShowSearchResult}>{SearchResults}</If>
      <If state={shouldShowEmptyState}>
        <EmptyState
          description={t("emptyStates.noSearchResults")}
          icon={SearchIcon}
          isAdmin={false}
          className="AdminMenuMenuDisplayEmptyState"
        />
      </If>
    </div>
  );

  return (
    <div className={styles.Modal}>
      <GuestModal
        className={styles.Background}
        containerClassname={styles.Container}
        header={modalHeader}
        body={modalBody}
        footer={modalFooter}
        openSlide={openSlide}
        slideDirection={POSITION_TYPES.DOWN}
        modalPosition={MODAL_POSITIONS.BOTTOM}
        componentMaxHeight={COMPONENT_SIZES.FULL_HEIGHT}
      />
    </div>
  );
};

MenuSearchModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MenuSearchModal;
