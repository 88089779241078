import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css";

import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import {
  calculateMenuItemPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import MenuItemHeader, {
  ENUMS as Header_ENUMS,
} from "components/elements/menu-item-header/MenuItemHeader";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import {
  findGuest,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { ROUTE_NAME, QUERY_PARAMS } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { MP_EVENTS, MP_PROP_NAMES } from "utils/constants/mixpanel";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import If from "components/if/If";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import Cube from "components/cube/Cube";
import MenuItemDescription, {
  ENUMS as DESCRIPTION_ENUMS,
} from "components/elements/menu-item-description/MenuItemDescription";
import Tag, { ENUMS as TAG_ENUMS } from "components/elements/tag/Tag";
import TagWithIcon, {
  ENUMS as TAG_ENUMS_WICON,
} from "components/elements/tag-with-icon/TagWithIcon";
import MenuItemPrice from "components/elements/menu-item-price/MenuItemPrice";
import useTags from "utils/hooks/useTags";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";
import useLanguage from "utils/hooks/useLanguage";
import useMouseDragHorizontal from "utils/hooks/useMouseDragHorizontal";

import "./MenuItem.scss";

const MenuItem = () => {
  const { trackMixpanel } = useMixpanel();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businessId, menuItemId } = useParams();
  let [searchParams] = useSearchParams();
  const { shouldDisplayMenuItemImage, findItemById, isValidMenuItem } =
    useMenuHelper();
  const { navigateToPreservedBackUrl, navigate } = useInternalNavigation();
  const { displayDataByLanguage } = useLanguage();

  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(guestId, basketOrders);
  const favoriteItems = guest?.favoriteItems || [];

  const selectedMenuItem = useMemo(() => {
    if (!menuItemId) return null;

    const existMenuItem = findItemById(menuItemId);

    return existMenuItem
      ? { ...existMenuItem, allModifications: existMenuItem.modifications }
      : null;
  }, [menuItemId, findItemById]);

  const {
    sliderRef: sliderRefTags,
    handleMouseDownHandler: handleMouseDownHandlerTags,
  } = useMouseDragHorizontal();

  const allTags = useTags(selectedMenuItem, true)?.allTags || [];
  const allTagsWithIcon =
    useTagsWIcon(selectedMenuItem, displayDataByLanguage, true)
      ?.allTagsWithIcon || [];

  const [count, setCount] = useState(1);
  const [selectedModifications, setSelectedModifications] = useState([]);
  const [focusedModificationOption, setFocusedModificationOption] =
    useState(null);

  const discountPrice = calculateMenuItemPriceBySchedule(selectedMenuItem);
  const menuItemPrice = selectedMenuItem?.priceSell || 0;
  const finalPrice = useMemo(
    () =>
      selectedMenuItem
        ? calculateMenuItemPrice({
            ...selectedMenuItem,
            priceSell: menuItemPrice + discountPrice,
            modifications: selectedModifications,
          })
        : 0,
    [selectedMenuItem, selectedModifications, menuItemPrice, discountPrice]
  );

  useEffect(() => {
    if (!selectedMenuItem) {
      return [];
    }
    const defaultModifications = getBasketModificationsWithDefaultValues(
      selectedMenuItem.modifications
    );
    setSelectedModifications(defaultModifications);
  }, [selectedMenuItem]);

  const handleOnFavorite = useCallback(() => {
    if (!selectedMenuItem) return;

    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: selectedMenuItem.id,
      })
    );
    trackMixpanel(`${MP_EVENTS.menu.liked}`, {
      [MP_PROP_NAMES.itemId]: menuItemId,
      [MP_PROP_NAMES.itemName]: selectedMenuItem.name[0].value,
    });
  }, [dispatch, guestId, selectedMenuItem, trackMixpanel, menuItemId]);

  const handleGoBack = useCallback(() => {
    const memorizedScroll = searchParams.get(QUERY_PARAMS.memorizedScroll);

    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      queries: {
        ...(memorizedScroll && {
          [QUERY_PARAMS.targetedScroll]: memorizedScroll,
        }),
      },
    });
  }, [navigateToPreservedBackUrl, businessId, searchParams]);

  const handleCountChange = useCallback((increment) => {
    setCount((prev) => Math.max(1, prev + increment));
  }, []);

  const handleAddToBasket = () => {
    const filteredModifications = selectedModifications.filter(
      (modification) => modification.options.length > 0
    );
    dispatch(
      updateBasket({
        menuItem: {
          id: selectedMenuItem.id,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count,
        userId: guestId,
      })
    );
    handleGoBack();
  };

  const handleOpenAr = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItemAR}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  if (!isValidMenuItem(selectedMenuItem?.id)) {
    return (
      <Navigate
        to={`${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`}
      />
    );
  }

  const shouldShowSlider =
    selectedMenuItem.otherImagesSrc?.length > 1 && shouldDisplayMenuItemImage;

  const isActiveArView = Boolean(
    selectedMenuItem?.model3D?.ios && selectedMenuItem.model3D?.general
  );

  return (
    <div className="MenuItem">
      <MenuItemHeader
        onFavorite={handleOnFavorite}
        itemId={selectedMenuItem?.id}
        onGoBack={handleGoBack}
        favoriteItems={favoriteItems}
        type={Header_ENUMS.types.TYPE_C}
      />

      <If state={shouldDisplayMenuItemImage}>
        <div className="MenuItemImagesWrapper">
          <If state={isActiveArView}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleOpenAr(selectedMenuItem);
              }}
              className="ArButtonWrapper"
            >
              <Cube />
              <p className="h7 SemiBold">AR</p>
            </div>
          </If>

          <If state={shouldShowSlider}>
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              effect={"fade"}
              modules={[EffectFade, Autoplay]}
              className="MenuItemSlider"
            >
              {selectedMenuItem.otherImagesSrc.map((imageSrc, index) => (
                <SwiperSlide className="SwiperSlide" key={index}>
                  <img
                    className="MenuItemCarouselImage"
                    src={imageSrc}
                    alt={selectedMenuItem.extraFields.displayedName}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </If>
          <If state={!shouldShowSlider && shouldDisplayMenuItemImage}>
            <ImageWithPlaceholder
              imageSource={selectedMenuItem.coverImageSrc}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt={String(selectedMenuItem.id)}
              className="MenuItemCoverImage"
            />
          </If>
        </div>
      </If>
      <div
        className={cx("MenuItemInfoContainer", {
          WithPhoto: shouldDisplayMenuItemImage,
          WithoutPhoto: !shouldDisplayMenuItemImage,
        })}
      >
        <h2 className="SemiBold">
          {selectedMenuItem.extraFields.displayedName}
        </h2>
        <div className="MenuItemInfoWrapper">
          <div className="MenuItemDescriptionContainer">
            <MenuItemDescription
              description={selectedMenuItem.extraFields.displayedDescription}
              type={DESCRIPTION_ENUMS.types.TYPE_A}
            />
          </div>

          <If state={allTags.length > 0}>
            <Tag items={allTags} type={TAG_ENUMS.types.TYPE_F} showTime />
          </If>
          <If state={allTagsWithIcon.length > 0}>
            <div
              className="MenuItemTagsContainer"
              ref={sliderRefTags}
              onMouseDown={handleMouseDownHandlerTags}
            >
              <TagWithIcon
                items={allTagsWithIcon}
                type={TAG_ENUMS_WICON.types.TYPE_B}
              />
            </div>
          </If>
          <div className="MenuItemInfoPrice">
            <MenuItemPrice
              menuItemPrice={menuItemPrice}
              discountPrice={discountPrice}
            />
          </div>
        </div>
      </div>
      <If state={selectedMenuItem?.allModifications.length > 0}>
        <div className="MenuItemModifications">
          {selectedMenuItem.allModifications.map((modification) => (
            <ModificationsMenu
              key={modification.id}
              data={modification}
              selectedOptions={selectedModifications?.find(
                (option) => option.id === modification.id
              )}
              setSelectedModifications={setSelectedModifications}
              focusedModificationOption={focusedModificationOption}
              setFocusedModificationOption={setFocusedModificationOption}
            />
          ))}
        </div>
      </If>
      <div className="MenuItemFooter">
        <AnimatedPlusMinusButton
          onMinusClick={() => handleCountChange(-1)}
          onPlusClick={() => handleCountChange(1)}
          hasBorder
          count={count}
          doAnimate
          disableMinusButtonAtOne
        />
        <CTAButton
          className="AddToBasketBtn"
          onClick={handleAddToBasket}
          name={t("buttons.addBasket")}
          type={CTA_ENUMS.types.TYPE_N}
          price={count * finalPrice}
        />
      </div>
    </div>
  );
};

export default MenuItem;
