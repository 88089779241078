import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGES } from "utils/constants/language";
import TRANSLATION_EN from "./en/en-main";
import TRANSLATION_RU from "./ru/ru-main";
import TRANSLATION_TR from "./tr/tr-main";
import TRANSLATION_DE from "./de/de-main";
import TRANSLATION_AZ from "./az/az-main";
import TRANSLATION_UZ from "./uz/uz-main";
import TRANSLATION_ES from "./es/es-main";
import TRANSLATION_GE from "./ge/ge-main";
import TRANSLATION_AR from "./ar/ar-main";
import { STORE_NAMES } from "../redux";

const reduxStore = localStorage.getItem(STORE_NAMES.redux);
let userLanguage;
if (reduxStore) {
  const userStore = JSON.parse(reduxStore)[STORE_NAMES.user];
  userLanguage = userStore.userLanguage;
}

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: LANGUAGES.en.code,
  lng: userLanguage?.code ?? LANGUAGES.en.code,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    [LANGUAGES.en.code]: {
      translation: TRANSLATION_EN,
    },
    [LANGUAGES.ru.code]: {
      translation: TRANSLATION_RU,
    },
    [LANGUAGES.tr.code]: {
      translation: TRANSLATION_TR,
    },
    [LANGUAGES.deu.code]: {
      translation: TRANSLATION_DE,
    },
    [LANGUAGES.az.code]: {
      translation: TRANSLATION_AZ,
    },
    [LANGUAGES.es.code]: {
      translation: TRANSLATION_ES,
    },
    [LANGUAGES.ge.code]: {
      translation: TRANSLATION_GE,
    },
    [LANGUAGES.uz.code]: {
      translation: TRANSLATION_UZ,
    },
    [LANGUAGES.ar.code]: {
      translation: TRANSLATION_AR,
    },
  },
});

export default i18n;
