import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
// import { concat } from "lodash";
import isEqual from "lodash/isEqual";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { ErrorMessage } from "@hookform/error-message";

import {
  commonAsyncErrorMessage,
  MENU_VIEW_ENUMS,
} from "utils/constants/data/base";
import RadioSelectOption from "components/admin/forms/radio-select-option/RadioSelectOption";
import { STORE_NAMES } from "utils/constants/redux";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
// import { ReactComponent as SelectedIcon } from "assets/icons/check/selected.svg";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import useAsync from "utils/hooks/useAsync";
import { updateMenu } from "utils/api/services/menu";
import { MENU_COLOR_OPTIONS, RADIUS_OPTIONS } from "./data";
// import RadioSelection, {
//   ENUMS as RADIO_SELECTION_ENUMS,
// } from "components/admin/forms/radio-selection/RadioSelection";
import { QUERY_PARAMS } from "utils/constants/routes";
import { addPartialMenuUpdate } from "redux/slices/menuStore";

import "./AdminMenuSettings.scss";

const SETTINGS_OPTIONS = {
  language: "language",
  defaultMenuView: "defaultMenuView",
  color: "primaryColor",
  radius: "borderRadius",
  availableLanguages: "availableLanguages",
  currency: "currency",
};

const defaultColor = MENU_COLOR_OPTIONS[0].flatMap((o) => o.name);
const defaultRadius = RADIUS_OPTIONS[1].type;

const AdminMenuSettings = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const schemas = useValidationSchema(t);
  const menuSettingsLanguageSchema =
    schemas.menuSettingsLanguageSchema(SETTINGS_OPTIONS);

  const allCurrencies = useSelector(
    (state) => state[STORE_NAMES.app].currencies
  );
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    setError,
    watch,
  } = useForm({
    resolver: zodResolver(menuSettingsLanguageSchema),
    criteriaMode: "all",
    mode: "onChange",
  });

  const sortLanguages = (languages) => languages.sort((a, b) => a - b);

  const mapLanguageIdsToObjects = (languageIds) => {
    return languageIds.map((id) => allLanguages.find((lang) => lang.id === id));
  };

  const [formData, setFormData] = useState({
    [SETTINGS_OPTIONS.language]: menu?.language?.id,
    [SETTINGS_OPTIONS.currency]: menu?.currency?.id,
    [SETTINGS_OPTIONS.defaultMenuView]: menu?.defaultMenuView,
    [SETTINGS_OPTIONS.color]: menu?.primaryColor || defaultColor,
    [SETTINGS_OPTIONS.radius]: menu?.borderRadius || defaultRadius,
    [SETTINGS_OPTIONS.availableLanguages]:
      sortLanguages(menu?.availableLanguages.map((lang) => lang.id)) ||
      sortLanguages([menu?.language.id]),
  });

  const previousMenuSettings = useMemo(() => {
    return {
      [SETTINGS_OPTIONS.radius]: menu.borderRadius || defaultRadius,
      [SETTINGS_OPTIONS.defaultMenuView]: menu.defaultMenuView,
      [SETTINGS_OPTIONS.color]:
        menu.primaryColor || MENU_COLOR_OPTIONS[0].flatMap((o) => o.name),
      [SETTINGS_OPTIONS.language]: menu.language.id,
      [SETTINGS_OPTIONS.currency]: menu?.currency?.id,
      [SETTINGS_OPTIONS.availableLanguages]: sortLanguages(
        menu.availableLanguages.map((lang) => lang.id) || [menu.language.id]
      ),
    };
  }, [menu]);

  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(false);
  const watchedLanguage = watch(SETTINGS_OPTIONS.language);
  const watchedCurrency = watch(SETTINGS_OPTIONS.currency);
  const watchedAvailableLanguages = watch(SETTINGS_OPTIONS.availableLanguages);

  useEffect(() => {
    const unsavedChanges = !isEqual(
      {
        ...formData,
        [SETTINGS_OPTIONS.availableLanguages]: sortLanguages([
          ...formData[SETTINGS_OPTIONS.availableLanguages],
        ]),
      },
      previousMenuSettings
    );

    setHasUnSavedChanges(unsavedChanges);
    if (unsavedChanges) {
      setSearchParams({ ...searchParams, [QUERY_PARAMS.unsavedChanges]: true });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);

      Object.keys(errors).forEach((key) => {
        setError(key, {
          type: "manual",
          message: "",
        });
      });
    }
  }, [formData, previousMenuSettings]);

  const { execute: executeUpdateMenu, loading: isLoadingUpdateMenu } = useAsync(
    updateMenu,
    {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) => {
        handleOnAsyncSuccessForMenu(
          t("toastMessages.success.updateMenuSettings"),
          response
        );
      },
    }
  );

  const handleOnAsyncErrorForMenu = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const handleOnAsyncSuccessForMenu = (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {});
    dispatch(addPartialMenuUpdate(response.data));
  };

  const handleSettingChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAvailableLanguagesChange = (option) => {
    const handleOnLanguageRemove = (option) => {
      // if (formData[SETTINGS_OPTIONS.language] === option.id) {
      //   return;
      // }
      setFormData({
        ...formData,
        [SETTINGS_OPTIONS.availableLanguages]: formData[
          SETTINGS_OPTIONS.availableLanguages
        ].filter((id) => id !== option.id),
        [SETTINGS_OPTIONS.language]:
          formData[SETTINGS_OPTIONS.language] === option.id
            ? null
            : formData[SETTINGS_OPTIONS.language],
      });
    };

    const existingLanguage = formData[
      SETTINGS_OPTIONS.availableLanguages
    ].includes(option.id);

    if (existingLanguage) {
      handleOnLanguageRemove(option);
      setValue(
        SETTINGS_OPTIONS.availableLanguages,
        formData[SETTINGS_OPTIONS.availableLanguages].filter(
          (id) => id !== option.id
        )
      );
      setValue(
        SETTINGS_OPTIONS.language,
        formData[SETTINGS_OPTIONS.language] === option.id
          ? null
          : formData[SETTINGS_OPTIONS.language]
      );
    } else {
      setFormData({
        ...formData,
        [SETTINGS_OPTIONS.availableLanguages]: sortLanguages([
          ...formData[SETTINGS_OPTIONS.availableLanguages],
          option.id,
        ]),
        [SETTINGS_OPTIONS.language]:
          formData[SETTINGS_OPTIONS.language] || option.id,
      });
      setValue(
        SETTINGS_OPTIONS.availableLanguages,
        sortLanguages([
          ...formData[SETTINGS_OPTIONS.availableLanguages],
          option.id,
        ])
      );
      setValue(
        SETTINGS_OPTIONS.language,
        formData[SETTINGS_OPTIONS.language] || option.id
      );
    }
  };

  const handleOnLanguageChange = (option) => {
    setFormData({
      ...formData,
      [SETTINGS_OPTIONS.language]: option.id,
      [SETTINGS_OPTIONS.availableLanguages]: formData[
        SETTINGS_OPTIONS.availableLanguages
      ].includes(option.id)
        ? formData[SETTINGS_OPTIONS.availableLanguages]
        : sortLanguages([
            ...formData[SETTINGS_OPTIONS.availableLanguages],
            option.id,
          ]),
    });

    setValue(
      SETTINGS_OPTIONS.availableLanguages,
      sortLanguages([
        ...formData[SETTINGS_OPTIONS.availableLanguages],
        option.id,
      ])
    );
    setValue(SETTINGS_OPTIONS.language, option.id);
  };

  const handleOnCurrencyChange = (option) => {
    setFormData({
      ...formData,
      [SETTINGS_OPTIONS.currency]: option.id,
    });
  };
  const onSave = async () => {
    await executeUpdateMenu(businessId, menu.id, formData);
    // const response = await dispatch(
    //     updateBusinessAsync({ business: updatedFormData, id: businessId })
    // );
    // if (response.error) {
    //   handleOnAsyncError(t(commonAsyncErrorMessage));
    // } else {
    //   handleOnAsyncSuccess(t("toastMessages.success.updateBusinessProfile"));
    //   setFormData(getBusinessObject(response.payload));
    // }
  };

  return (
    <div className="AdminMenuSettings">
      <div className="AdminMenuSettingsHeader">
        <div className="AdminMenuSettingsTitle">
          <h2 className="SemiBold AdminMenuSettingsTitleText">
            {t("navbarRoutes.pageTitles.settings")}
          </h2>
        </div>
        {hasUnSavedChanges && (
          <div className="AdminMenuSettingsSaveButton">
            <PrimaryButton
              onClick={handleSubmit(onSave)}
              text={t("buttons.save")}
              type={PRIMARY_BUTTON_ENUMS.types.TYPE_E}
              isLoading={isLoadingUpdateMenu}
            />
          </div>
        )}
      </div>
      <div className="AdminMenuSettingsList">
        <div className="AdminMenuSettingsLanguagesAndCurrency">
          <div className="CardInsetShadow-v1 AdminMenuSettingsListItem AdminMenuSettingsMenuLanguage">
            <h2 className="SemiBold AdminMenuSettingsListName">
              {t("language.language")}
            </h2>
            <div className="AdminMenuSettingsMenuLanguageDropdowns">
              <div className="AdminMenuSettingsMenuLanguageDropdown">
                <Controller
                  name={SETTINGS_OPTIONS.availableLanguages}
                  control={control}
                  defaultValue={
                    formData[SETTINGS_OPTIONS.availableLanguages] || []
                  }
                  render={() => (
                    <Dropdown
                      isMultiple
                      value={
                        mapLanguageIdsToObjects(
                          formData[SETTINGS_OPTIONS.availableLanguages]
                        ) || []
                      }
                      options={allLanguages}
                      required
                      onChange={handleAvailableLanguagesChange}
                      dropdownWithIcon
                      placeholder={t("buttons.availableLanguages")}
                      error={
                        <ErrorMessage
                          errors={
                            watchedAvailableLanguages &&
                            watchedAvailableLanguages.length === 0
                              ? errors
                              : {}
                          }
                          name={SETTINGS_OPTIONS.availableLanguages}
                          render={({ message }) => (
                            <p className="h7 error-message">{message}</p>
                          )}
                        />
                      }
                      hasError={
                        watchedAvailableLanguages?.length === 0 &&
                        errors[SETTINGS_OPTIONS.availableLanguages]
                      }
                    />
                  )}
                />
              </div>
              <div className="AdminMenuSettingsMenuLanguageDropdown">
                <Controller
                  name={SETTINGS_OPTIONS.language}
                  control={control}
                  defaultValue={formData.language || null}
                  render={() => (
                    <Dropdown
                      value={
                        allLanguages.find(
                          (lang) => lang.id === formData.language
                        ) || {}
                      }
                      options={allLanguages}
                      dropdownWithIcon
                      placeholder={t("buttons.defaultLanguage")}
                      onChange={handleOnLanguageChange}
                      required
                      isOptionRequired
                      error={
                        <ErrorMessage
                          errors={!watchedLanguage ? errors : {}}
                          name={SETTINGS_OPTIONS.language}
                          render={({ message }) => (
                            <p className="h7 error-message">{message}</p>
                          )}
                        />
                      }
                      hasError={
                        !watchedLanguage && errors[SETTINGS_OPTIONS.language]
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="CardInsetShadow-v1 AdminMenuSettingsListItem AdminMenuSettingsMenuCurrency">
            <h2 className="SemiBold AdminMenuSettingsListName">
              {t("menu.settings.currency")}
            </h2>
            <div className="AdminMenuSettingsMenuCurrencyDropdown">
              <Controller
                name={SETTINGS_OPTIONS.currency}
                control={control}
                defaultValue={formData.currency || null}
                render={() => (
                  <Dropdown
                    value={
                      allCurrencies.find(
                        (currency) => currency.id === formData.currency
                      ) || {}
                    }
                    options={allCurrencies}
                    dropdownWithIcon
                    placeholder={t("buttons.defaultCurrency")}
                    onChange={handleOnCurrencyChange}
                    required
                    isOptionRequired
                    showCodeColumnInTitle
                    error={
                      <ErrorMessage
                        errors={!watchedCurrency ? errors : {}}
                        name={SETTINGS_OPTIONS.currency}
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    hasError={
                      !watchedCurrency && errors[SETTINGS_OPTIONS.currency]
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>

        {/*<div className="CardInsetShadow-v1 AdminMenuSettingsListItem AdminMenuSettingsMenuColor">*/}
        {/*  <h2 className="SemiBold AdminMenuSettingsListName">*/}
        {/*    {t("menu.settings.color")}*/}
        {/*  </h2>*/}
        {/*  <form className="AdminMenuSettingsMenuColorOptions">*/}
        {/*    {MENU_COLOR_OPTIONS.filter((el) => el.length === 2).map(*/}
        {/*      (option) => {*/}
        {/*        const active =*/}
        {/*          formData[SETTINGS_OPTIONS.color]?.[0] === option?.[0]?.name &&*/}
        {/*          formData[SETTINGS_OPTIONS.color]?.[1] === option?.[1]?.name &&*/}
        {/*          formData[SETTINGS_OPTIONS.color]?.length === 2;*/}
        {/*        return (*/}
        {/*          <div*/}
        {/*            onClick={() =>*/}
        {/*              handleSettingChange(SETTINGS_OPTIONS.color, [*/}
        {/*                option?.[0]?.name,*/}
        {/*                option?.[1]?.name,*/}
        {/*              ])*/}
        {/*            }*/}
        {/*            className={`AdminMenuSettingsMenuColorOption ${*/}
        {/*              active ? "active dual" : "dual"*/}
        {/*            }`}*/}
        {/*            key={concat(option?.[0].code, option?.[1].code)}*/}
        {/*          >*/}
        {/*            <div*/}
        {/*              className="AdminMenuSettingsMenuColorOptionPrimary"*/}
        {/*              style={{ backgroundColor: option?.[0].code }}*/}
        {/*            ></div>*/}
        {/*            <div*/}
        {/*              className="AdminMenuSettingsMenuColorOptionSecondary"*/}
        {/*              style={{ backgroundColor: option?.[1].code }}*/}
        {/*            ></div>*/}
        {/*            {active && <SelectedIcon />}*/}
        {/*          </div>*/}
        {/*        );*/}
        {/*      }*/}
        {/*    )}*/}
        {/*    {MENU_COLOR_OPTIONS.filter((el) => el.length == 1).map((option) => {*/}
        {/*      const active =*/}
        {/*        formData[SETTINGS_OPTIONS.color]?.[0] == option?.[0]?.name &&*/}
        {/*        formData[SETTINGS_OPTIONS.color]?.length === 1;*/}
        {/*      return (*/}
        {/*        <div*/}
        {/*          onClick={() =>*/}
        {/*            handleSettingChange(SETTINGS_OPTIONS.color, [*/}
        {/*              option?.[0]?.name,*/}
        {/*            ])*/}
        {/*          }*/}
        {/*          className={`AdminMenuSettingsMenuColorOption ${*/}
        {/*            active ? "active" : ""*/}
        {/*          }`}*/}
        {/*          key={option?.[0].code}*/}
        {/*          style={{ backgroundColor: option?.[0].code }}*/}
        {/*        >*/}
        {/*          {active && <SelectedIcon />}*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </form>*/}
        {/*</div>*/}
        {/*<div className="CardInsetShadow-v1 AdminMenuSettingsListItem AdminMenuSettingsMenuBorderRadius">*/}
        {/*  <h2 className="SemiBold AdminMenuSettingsListName">*/}
        {/*    {t("menu.settings.roundness")}*/}
        {/*  </h2>*/}
        {/*  <form className="AdminMenuSettingsMenuRadiusOptions">*/}
        {/*    {RADIUS_OPTIONS.map((option) => {*/}
        {/*      return (*/}
        {/*        <div*/}
        {/*          className={`AdminMenuSettingsMenuRadiusOption ${*/}
        {/*            formData[SETTINGS_OPTIONS.radius] === option.type*/}
        {/*              ? "isSelected"*/}
        {/*              : ""*/}
        {/*          }`}*/}
        {/*          key={option.type}*/}
        {/*          onClick={() =>*/}
        {/*            handleSettingChange(SETTINGS_OPTIONS.radius, option.type)*/}
        {/*          }*/}
        {/*        >*/}
        {/*          <RadioSelection*/}
        {/*            name={option.type}*/}
        {/*            value={option.type}*/}
        {/*            isChecked={*/}
        {/*              formData[SETTINGS_OPTIONS.radius] === option.type*/}
        {/*            }*/}
        {/*            type={RADIO_SELECTION_ENUMS.types.TYPE_YELLOW}*/}
        {/*          />*/}
        {/*          <h6 className="Medium">{t(`optionTypes.${option.type}`)}</h6>*/}
        {/*          <div*/}
        {/*            className="AdminMenuSettingsMenuRadiusOptionPreview"*/}
        {/*            style={{ borderTopRightRadius: `${option.size.A}px` }}*/}
        {/*          ></div>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </form>*/}
        {/*</div>*/}
        <div className="CardInsetShadow-v1 AdminMenuSettingsListItem AdminMenuSettingsMenuViews">
          <h2 className="SemiBold AdminMenuSettingsListName">
            {t("menu.view.selection")}
          </h2>
          <form className="AdminMenuSettingsMenuViewOptions">
            {Object.keys(MENU_VIEW_ENUMS).map((viewType) => (
              <RadioSelectOption
                key={MENU_VIEW_ENUMS[viewType].id}
                onClick={(id) => {
                  handleSettingChange(SETTINGS_OPTIONS.defaultMenuView, id);
                }}
                isSelected={
                  formData.defaultMenuView === MENU_VIEW_ENUMS[viewType].id
                }
                id={MENU_VIEW_ENUMS[viewType].id}
                name={t(MENU_VIEW_ENUMS[viewType].name)}
                imgSrc={MENU_VIEW_ENUMS[viewType].imgSrc}
              />
            ))}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminMenuSettings;
