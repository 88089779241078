import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { STORE_NAMES } from "utils/constants/redux";
import AddNewButton, {
  ENUMS as ADD_NEW_BUTTON_ENUMS,
} from "components/admin/buttons/add-new-button/AddNewButton";
import TextInput, {
  ENUMS as ENUMS_TEXT_INPUT,
} from "components/forms/input/input-text/TextInput";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import useAsync from "utils/hooks/useAsync";
import {
  addIpToBlacklist,
  deleteIpFromBlacklist,
  getAllBlackListedIps,
} from "utils/api/services/blackList";

import "./SuperAdminIpBlacklist.scss";

const SuperAdminIpBlacklist = () => {
  const { t } = useTranslation();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const [ip, setIp] = useState("");

  const { result, execute: fetchBlacklistedIps } = useAsync(
    getAllBlackListedIps,
    {
      immediate: true,
    }
  );
  const allBlacklistIps = result || [];

  const { execute: executeAddIp } = useAsync(addIpToBlacklist, {
    onError: () => toast.error("Failed to add IP"),
    onSuccess: () => {
      setIp("");
      toast.success("IP added successfully");
      fetchBlacklistedIps();
    },
  });

  const { execute: executeDeleteIp } = useAsync(deleteIpFromBlacklist, {
    onError: () => toast.error("Failed to delete IP"),
    onSuccess: () => {
      toast.success("IP removed successfully");
      fetchBlacklistedIps();
    },
  });

  const handleChangeIp = (e) => setIp(e.target.value.trim());

  const handleAddIpToBlacklist = async () => {
    if (!ip) return toast.error("Please enter an IP address");
    if (allBlacklistIps.includes(ip))
      return toast.warn("This IP is already blacklisted");

    await executeAddIp({ businessId, ip });
  };

  const handleDeleteIpFromBlacklist = (ip) =>
    executeDeleteIp({ businessId, ip });

  return (
    <>
      <div className="IpBlacklistHeaderContainer">
        <div className="SuperAdminGuestsInfo">
          <h4 className="SemiBold SuperAdminGuestsInfoTitle">
            {t("navbarRoutes.ipBlacklist")} ({allBlacklistIps.length})
          </h4>
        </div>
      </div>
      <div className="IpBlacklistContainer">
        <div className="IpBlacklistWrapper">
          <div className="AddIpToBlackListWrapper">
            <TextInput
              placeholder="IP"
              labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
              onChange={handleChangeIp}
              onKeyDown={(e) => e.key === "Enter" && handleAddIpToBlacklist()}
              value={ip}
            />
            <AddNewButton
              onClick={handleAddIpToBlacklist}
              label={t("buttons.add")}
              type={ADD_NEW_BUTTON_ENUMS.types.TYPE_A}
            />
          </div>

          <ul className="IpsBlacklist">
            {allBlacklistIps.map((blacklistIp) => (
              <li key={blacklistIp} className="IpBlacklist">
                <h4>{blacklistIp}</h4>
                <DeleteButton
                  onClick={() => handleDeleteIpFromBlacklist(blacklistIp)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
export default SuperAdminIpBlacklist;
