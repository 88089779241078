import React from "react";
import PropTypes from "prop-types";

import "./SectionHeader.scss";

export const ENUMS = {
  name: "SectionHeader",
};

const SectionHeader = ({ className, title, count, isCountFirst, body }) => {
  return (
    <div className={`SectionHeader ${className}`}>
      <h4 className="SemiBold SectionHeaderItem">
        {title}
        {isCountFirst && count > 0 && (
          <span className="SectionHeaderCount">{` (${count}) `}</span>
        )}
        {!isCountFirst && count > 0 && (
          <span className="SectionHeaderCount">{` (${count})`}</span>
        )}
        {body}
      </h4>
    </div>
  );
};

SectionHeader.propTypes = {
  /**
   * Additional class names to apply
   */
  className: PropTypes.string,

  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,

  /**
   * The count to display (optional)
   */
  count: PropTypes.number,

  /**
   * Whether the count should be displayed before the title
   */
  isCountFirst: PropTypes.bool,

  /**
   * The body to display (optional)
   */
  body: PropTypes.element,
};

export default SectionHeader;
