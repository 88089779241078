export const PRINTER_STATUS_CODES = {
  0: "Unknown", // GRAY
  1: "Paused", // Yellow
  2: "Error",
  3: "Ready", // GREEN
  4: "Printing",
  5: "Warming Up",
  6: "Offline", // RED
  7: "Paper Jam",
  8: "Out of Paper",
  9: "Out of Toner",
  10: "Cover Open",
};

export const ELECTRON_CONSTANTS = {
  userAgent: "electron/",
  PRINT_THROTTLE_TIME: 5,
};

export const ELECTRON_CHANNEL = {
  inbound: { printersList: "printers-list" },
  outbound: {
    printRequest: "print-request",
    getPrinters: "get-printers",
    testPrintRequest: "test-print-request",
  },
};

export const COMPACT_VIEW_COLUMNS = 2;

export const PRINTER_ALIGNMENTS = {
  ITEM_NAME: "LEFT",
  ORDER_COUNT: "RIGHT",
  PRICE: "RIGHT",
  TOTAL_PRICE: "RIGHT",
  HEADER: "CENTER",
  MESSAGE: "LEFT",
  FOOTER: "CENTER",
};

export const TEST_PRINT_DATA = {
  id: 12345,
  status: "OPEN",
  createDate: new Date().toISOString(),
  lastUpdateDate: new Date().toISOString(),
  table: {
    name: "Table 1",
    zoneName: "Terrace",
  },
  assignee: {
    name: "Test Waiter",
  },
  users: [
    {
      orderItems: [
        {
          count: 2,
          isConfirmed: true,
          payment: {
            orderItemTotalPrice: 50,
          },
          item: {
            name: [
              { languageCode: "EN", value: "Test Product" },
              { languageCode: "TR", value: "Test Ürün" },
              { languageCode: "AZ", value: "Test Məhsul" },
              { languageCode: "UZ", value: "Test Mahsulot" },
              { languageCode: "RU", value: "Тестовый Продукт" },
              { languageCode: "ES", value: "Producto de Prueba" },
              { languageCode: "GE", value: "სატესტო პროდუქტი" },
              { languageCode: "DE", value: "Testprodukt" },
            ],
            priceSell: 25,
            modifications: [
              {
                name: [
                  { languageCode: "EN", value: "Extra" },
                  { languageCode: "TR", value: "Ekstra" },
                  { languageCode: "AZ", value: "Əlavə" },
                  { languageCode: "UZ", value: "Qo'shimcha" },
                  { languageCode: "RU", value: "Дополнительно" },
                  { languageCode: "ES", value: "Extra" },
                  { languageCode: "GE", value: "დამატებითი" },
                  { languageCode: "DE", value: "Extra" },
                ],
                options: [
                  {
                    name: [
                      { languageCode: "EN", value: "Cheese" },
                      { languageCode: "TR", value: "Peynir" },
                      { languageCode: "AZ", value: "Pendir" },
                      { languageCode: "UZ", value: "Pishloq" },
                      { languageCode: "RU", value: "Сыр" },
                      { languageCode: "ES", value: "Queso" },
                      { languageCode: "GE", value: "ყველი" },
                      { languageCode: "DE", value: "Käse" },
                    ],
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
  guests: [],
};
