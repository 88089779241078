import React, { useState, useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import { useTranslation } from "react-i18next";

import LOGO_QR from "assets/images/qr/Logo-qr.png";
import TextInput, {
  ENUMS as ENUMS_TEXT_INPUT,
} from "components/forms/input/input-text/TextInput";
import If from "components/if/If";
import useToggle from "utils/hooks/useToggle";
import Button, {
  ENUMS_TYPE as BUTTON_ENUMS_TYPE,
} from "components/pos/button/Button";

import "./CreateGenericQr.scss";

const initialURL = "https://";

const CreateGenericQr = () => {
  const [url, setUrl] = useState(initialURL);
  const { t } = useTranslation();
  const {
    value: isVisibleQR,
    setOff: hideQR,
    setOn: showQR,
  } = useToggle({ initialValue: false });

  const [qrCode, setQrCode] = useState(null);
  const canvasRef = useRef(null);

  const width = 300;
  const height = 300;

  useEffect(() => {
    const qr = new QRCodeStyling({
      width: width,
      height: height,
      type: "svg",
      data: url,
      margin: 10,
      qrOptions: {
        typeNumber: Math.ceil(url.length / 8),
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        hideBackgroundDots: false,
        margin: 0,
      },
      dotsOptions: {
        type: "dots",
        color: "#000000",
        gradient: null,
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      image: LOGO_QR,
      cornersSquareOptions: {
        type: "extra-rounded",
        color: "#000000",
      },
      cornersDotOptions: {
        type: "dot",
        color: "#000000",
      },
    });

    setQrCode(qr);
  }, [url.length]);

  useEffect(() => {
    if (isVisibleQR && qrCode && canvasRef.current) {
      qrCode.update({ data: url });
      canvasRef.current.innerHTML = "";
      qrCode.append(canvasRef.current);
    }
  }, [isVisibleQR, url, qrCode]);

  const generateQR = () => {
    if (!url || url.trim() === initialURL) return;
    showQR();
  };

  const downloadQR = () => {
    if (qrCode) {
      qrCode.download({ name: "qr", extension: "png" });
    }
  };

  const handleChangeUrl = (e) => {
    hideQR();
    setUrl(e.target.value.trim());
  };

  return (
    <div className="CreateGenericQrContainer">
      <div className="CreateGenericQrForm">
        <TextInput
          value={url}
          onChange={handleChangeUrl}
          placeholder={"URL"}
          labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
          onKeyDown={(e) => e.key === "Enter" && generateQR()}
        />
      </div>
      <If state={isVisibleQR}>
        <h4 className="SemiBold">{t("common.qr")}</h4>
        <div ref={canvasRef} className="QRCode" />
        <Button
          text={t("qr.downloadQr")}
          onClick={downloadQR}
          className="DownloadQrButton"
          type={BUTTON_ENUMS_TYPE.Primary}
        />
      </If>
      <If state={!isVisibleQR}>
        <Button
          onClick={generateQR}
          text={t("buttons.save")}
          type={BUTTON_ENUMS_TYPE.Primary}
        />
      </If>
    </div>
  );
};

export default CreateGenericQr;
