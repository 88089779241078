import React from "react";
import PropTypes from "prop-types";

import MenuCard, { ENUMS } from "pages/client/menu-v2/MenuCard/MenuCard";

import styles from "./ScrollView.module.scss";

const ScrollView = ({
  category,
  handleAddToBasket,
  favoriteItems,
  lazyImage,
  handleOpenAr,
  handleFavItem,
  handleOnClick,
}) => {
  return (
    <div className={styles.ScrollViewWrapper}>
      {category?.menuItems?.map((menuItem) => {
        return (
          <MenuCard
            key={menuItem.id}
            menuItem={menuItem}
            onAddToBasket={handleAddToBasket}
            type={ENUMS.types.MEDIUM}
            isFavorite={favoriteItems.includes(menuItem.id)}
            onFavorite={() => handleFavItem(menuItem)}
            onClick={() => handleOnClick(menuItem)}
            lazyImage={lazyImage}
            handleOpenAr={() => handleOpenAr(menuItem)}
          />
        );
      })}
    </div>
  );
};

ScrollView.propTypes = {
  handleOpenAr: PropTypes.func,
  category: PropTypes.object,
  handleAddToBasket: PropTypes.func,
  favoriteItems: PropTypes.array,
  lazyImage: PropTypes.bool,
  handleFavItem: PropTypes.func,
  handleOnClick: PropTypes.func,
};

export default ScrollView;
