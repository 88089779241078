import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import Modal from "components/modal/Modal";
import useAsync from "utils/hooks/useAsync";
import useLanguage from "utils/hooks/useLanguage";
import Switch from "components/forms/switch/Switch";
import { STORE_NAMES } from "utils/constants/redux";
import { createDOBucketName } from "utils/DO-Spaces";
import IconButton from "components/buttons/icon-button/IconButton";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import RadioButton from "components/buttons/radio-button/RadioButton";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createLinkItemsToCategory } from "utils/api/services/menuItem";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import PrimaryButton, {
  ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { addPartialMenuUpdate } from "redux/slices/menuStore";

import "./AddSpecialCategoryItemModal.scss";

const AddSpecialCategoryItemModal = ({
  title,
  openSlide,
  setOpenSlide,
  mainElementRef,
  selectedCategory,
  setOutsideClickAction,
}) => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();

  const { menuCategoryType, extraVisibility } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const initialVisibility = [
    {
      id: 1,
      name: t("basket.basket"),
      type: extraVisibility.basket_recommended,
    },
    {
      id: 2,
      name: t("inputs.search"),
      type: extraVisibility.search_recommended,
    },
  ];

  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const initialFormData = {
    menuItemsId: [],
    showDiscountItems: false,
    showMostSoldItems: false,
    showMostLikedItems: false,
    extraVisibility: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formDataInitial, setFormDataInitial] = useState(initialFormData);

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  useEffect(() => {
    setFormData({
      menuItemsId: selectedCategory?.linkedMenuItems || [],
      showDiscountItems: selectedCategory?.showDiscountItems || false,
      showMostSoldItems: selectedCategory?.showMostSoldItems || false,
      showMostLikedItems: selectedCategory?.showMostLikedItems || false,
      extraVisibility: selectedCategory?.extraVisibility || null,
    });
    setFormDataInitial({
      menuItemsId: selectedCategory?.linkedMenuItems || [],
      showDiscountItems: selectedCategory?.showDiscountItems || false,
      showMostSoldItems: selectedCategory?.showMostSoldItems || false,
      showMostLikedItems: selectedCategory?.showMostLikedItems || false,
      extraVisibility: selectedCategory?.extraVisibility || null,
    });
  }, [selectedCategory, openSlide]);

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const filteredCategories = useMemo(
    () =>
      menu?.categories
        ?.filter(
          (category) =>
            !category.isArchived &&
            category.type === menuCategoryType.standard_category &&
            category.menuItems.some((item) => !item.isArchived)
        )
        .sort((a, b) => a.placeInTheList - b.placeInTheList) || [],
    [menu, menuCategoryType]
  );

  const toggleItemSelection = useCallback((id) => {
    setFormData((prev) => ({
      ...prev,
      menuItemsId: prev.menuItemsId.includes(id)
        ? prev.menuItemsId.filter((itemId) => itemId !== id)
        : [...prev.menuItemsId, id],
    }));
  }, []);

  const handleSelectAllItemsFromCategory = useCallback((category) => {
    const categoryItems = category.menuItems
      .filter((item) => !item.isArchived)
      .map((item) => item.id);
    setFormData((prev) => {
      const isAllSelected = categoryItems.every((id) =>
        prev.menuItemsId.includes(id)
      );

      return {
        ...prev,
        menuItemsId: isAllSelected
          ? prev.menuItemsId.filter((id) => !categoryItems.includes(id))
          : [...new Set([...prev.menuItemsId, ...categoryItems])],
      };
    });
  }, []);

  const handleOnModalClose = () => {
    if (!isEqual(formData, formDataInitial)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
  };

  const handleOnSubmit = async () => {
    await executeCreateCategoryLinkItems(
      businessId,
      menu.id,
      selectedCategory.id,
      formData
    );
  };

  const handleOnSuccess = async (successMessage, response) => {
    setFormData((prev) => ({ ...prev, menuItemsId: [] }));
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
    });
    const category = response.data;
    await dispatch(addPartialMenuUpdate({ categories: [category] }));
  };

  const handleVisibilityChange = (value) => {
    setFormData((formData) => ({
      ...formData,
      extraVisibility: value?.type || null,
    }));
  };
  const {
    execute: executeCreateCategoryLinkItems,
    loading: isLoadingCreateCategoryLinkItems,
  } = useAsync(createLinkItemsToCategory, {
    onError: () => handleOnAsyncError(t(commonAsyncErrorMessage)),
    onSuccess: (response) =>
      handleOnSuccess(
        t("toastMessages.success.updateSpecialCategory"),
        response
      ),
  });

  const isDisabledButton = isEqual(formData, formDataInitial);

  const AddSpecialCategoryItemModalHeader = (
    <div className="AddSpecialCategoryItemModalHeader">
      <h3 className="SemiBold AddSpecialCategoryTitle">{title}</h3>
      <IconButton
        className="AddSpecialCategoryButtonWrapper"
        onClick={handleOnModalClose}
        svgComponent={<IconClose />}
      />
    </div>
  );

  const AddSpecialCategoryItemModalBody = (
    <div className="AddSpecialCategoryItemModalBody">
      <div className="ModalBodyFilterContainer">
        <div className="ModalBodyFilter">
          <Switch
            isChecked={formData.showDiscountItems}
            onChange={() =>
              setFormData((prev) => ({
                ...prev,
                showDiscountItems: !prev.showDiscountItems,
              }))
            }
          />
          <h6 className="Medium">{t("menu.category.discountProducts")}</h6>
        </div>
        <div className="ModalBodyFilter">
          <Switch
            isChecked={formData.showMostSoldItems}
            onChange={() =>
              setFormData((prev) => ({
                ...prev,
                showMostSoldItems: !prev.showMostSoldItems,
              }))
            }
          />
          <h6 className="Medium">{t("menu.category.mostSoldProducts")}</h6>
        </div>
        <div className="ModalBodyFilter">
          <Switch
            isChecked={formData.showMostLikedItems}
            onChange={() =>
              setFormData((prev) => ({
                ...prev,
                showMostLikedItems: !prev.showMostLikedItems,
              }))
            }
          />
          <h6 className="Medium">{t("menu.category.mostLikedProducts")}</h6>
        </div>
        <Dropdown
          className="AddSpecialCategoryVisibilityDropdown"
          name="extraVisibility"
          value={
            formData.extraVisibility
              ? initialVisibility.find(
                  (visibility) => visibility.type === formData.extraVisibility
                )
              : null
          }
          options={initialVisibility}
          onChange={(option) => handleVisibilityChange(option)}
          placeholder={t("menu.category.extraVisibility")}
        />{" "}
      </div>
      {filteredCategories.length > 0 && (
        <div className="ModalBodyAllItemsContainer">
          <div className="ModalBodyAllItemsHeader">
            <h6 className="Medium ModalBodyAllItemsHeaderTitle">
              {t("menu.item.all")}
            </h6>
          </div>
          <div className="AllItemsBodyItemsContainer">
            {filteredCategories.map((category) => (
              <div key={category.id} className="AllItemsBodyCategory">
                <div className="AllItemsBodyCategoryHeader">
                  <h6 className="Medium AllItemsBodyCategoryTitle">
                    {displayDataByLanguage(category.name)}
                  </h6>
                  <div
                    className="AllItemsBodyCategoryHeaderButton"
                    onClick={() => handleSelectAllItemsFromCategory(category)}
                  >
                    <h6 className="Medium">
                      {category.menuItems
                        .filter((item) => !item.isArchived)
                        .every((item) => formData.menuItemsId.includes(item.id))
                        ? t("dashboard.unSelect")
                        : t("buttons.selectAll")}
                    </h6>
                  </div>
                </div>
                <div className="AllItemsBodyCategoryBody">
                  {category.menuItems
                    .filter((menuItem) => !menuItem.isArchived)
                    .sort((a, b) => a.placeInTheList - b.placeInTheList)
                    .map((menuItem) => (
                      <div
                        key={menuItem.id}
                        className="AllItemsBodyCategoryBodyItem"
                        onClick={() => toggleItemSelection(menuItem.id)}
                      >
                        <RadioButton
                          checked={formData.menuItemsId.includes(menuItem.id)}
                        />
                        <div className="AllItemsBodyCategoryBodyItemTitle">
                          <ImageWithPlaceholder
                            imageSource={createDOBucketName(
                              menuItem.coverImageSrc
                            )}
                            placeholder={IMAGE_ITEM_PLACEHOLDER}
                            alt={displayDataByLanguage(menuItem.name)}
                            className="CategoryBodyItemImage"
                          />
                          <h6 className="Medium">
                            {displayDataByLanguage(menuItem.name)}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const AddSpecialCategoryItemModalFooter = (
    <PrimaryButton
      onClick={handleOnSubmit}
      text={t("buttons.apply")}
      type={ENUMS.types.TYPE_I}
      isLoading={isLoadingCreateCategoryLinkItems}
      isDisabled={isDisabledButton}
    />
  );
  return (
    <Modal
      header={AddSpecialCategoryItemModalHeader}
      body={AddSpecialCategoryItemModalBody}
      footer={AddSpecialCategoryItemModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AddSpecialCategoryItemModal.propTypes = {
  title: PropTypes.string.isRequired,
  openSlide: PropTypes.bool,
  setOpenSlide: PropTypes.func,
  mainElementRef: PropTypes.object,
  selectedCategory: PropTypes.shape({
    id: PropTypes.number,
    linkedMenuItems: PropTypes.array,
    showDiscountItems: PropTypes.bool,
    showMostSoldItems: PropTypes.bool,
    showMostLikedItems: PropTypes.bool,
    extraVisibility: PropTypes.string,
  }),
  setOutsideClickAction: PropTypes.func,
};

export default AddSpecialCategoryItemModal;
