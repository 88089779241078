import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import useAuthCheck from "utils/hooks/useAuthCheck";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import Spinner from "components/elements/spinner/Spinner";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useAuthCheck();
  const { isAdminDashboardPinLocked } = useSelector(
    (state) => state[STORE_NAMES.app]
  );

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate(ROUTE_NAME.signIn, { replace: true });
    } else if (isAdminDashboardPinLocked) {
      navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`, {
        replace: true,
      });
    }
  }, [isAuthenticated, loading, navigate, isAdminDashboardPinLocked]);

  if (loading) return <Spinner />;
  if (!isAuthenticated) return null;

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
