import React, { useRef, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import { useImageVisibility } from "utils/context-api/ImageVisibilityContext";

const LazyImage = ({
  src,
  placeholder = ITEM_PLACEHOLDER,
  alt = "",
  className,
}) => {
  // console.log({ src, placeholder, alt });
  const ref = useRef(null);
  const [imgSrc, setImgSrc] = useState(placeholder);
  const { visibleImages, setImageVisible } = useImageVisibility();
  const isVisible = visibleImages[src];

  const handleIntersection = useCallback(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setImageVisible(src);
          observer.unobserve(ref.current);
        }
      });
    },
    [setImageVisible, src]
  );

  useEffect(() => {
    if (!isVisible && src && ref.current) {
      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [isVisible, handleIntersection, src]);

  const handleError = () => {
    setImgSrc(placeholder);
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <img
      className={className}
      ref={ref}
      src={isVisible && src ? imgSrc : placeholder}
      alt={alt}
      onError={handleError}
      loading="lazy"
    />
  );
};

LazyImage.propTypes = {
  src: PropTypes.string,
  placeholder: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default LazyImage;
