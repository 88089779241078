import React from "react";
import PropTypes from "prop-types";

import MenuItemPrice from "components/elements/menu-item-price/MenuItemPrice";
// import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import MenuItemDescription, {
  ENUMS as DESCRIPTION_ENUMS,
} from "components/elements/menu-item-description/MenuItemDescription";
import Tag, { ENUMS as TAG_ENUMS } from "components/elements/tag/Tag";
import TagWithIcon, {
  ENUMS as TAG_ENUMS_WICON,
} from "components/elements/tag-with-icon/TagWithIcon";
import useLanguage from "utils/hooks/useLanguage";
import useTags from "utils/hooks/useTags";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";
import useMouseDragHorizontal from "utils/hooks/useMouseDragHorizontal";

import "./MenuItemInfo.scss";

export const ENUMS = {
  name: "MenuItemInfo",
};

const MenuItemInfo = ({ menuItem, discountPrice, menuItemPrice }) => {
  const { displayDataByLanguage } = useLanguage();
  const {
    sliderRef: sliderRefTags,
    handleMouseDownHandler: handleMouseDownHandlerTags,
  } = useMouseDragHorizontal();
  const { allTags } = useTags(menuItem, true);
  const { allTagsWithIcon } = useTagsWIcon(
    menuItem,
    displayDataByLanguage,
    true
  );
  return (
    <div className="MenuItemInfoWrapper">
      <div className="MenuItemDescriptionContainer">
        <MenuItemDescription
          description={displayDataByLanguage(menuItem.description)}
          type={DESCRIPTION_ENUMS.types.TYPE_A}
        />
      </div>

      {allTags && allTags.length > 0 && (
        <Tag items={allTags} type={TAG_ENUMS.types.TYPE_F} showTime />
      )}
      {allTagsWithIcon.length > 0 && (
        <div
          className="MenuItemTagsContainer"
          ref={sliderRefTags}
          onMouseDown={handleMouseDownHandlerTags}
        >
          <TagWithIcon
            items={allTagsWithIcon}
            type={TAG_ENUMS_WICON.types.TYPE_B}
          />
        </div>
      )}
      <div className="MenuItemInfoPrice">
        <MenuItemPrice
          menuItemPrice={menuItemPrice}
          discountPrice={discountPrice}
        />
      </div>
    </div>
  );
};

MenuItemInfo.propTypes = {
  /**
   * The  order
   */
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
    priceSell: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            languageCode: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }),

  /**
   * The function called when minus button is clicked
   */

  onMinus: PropTypes.func.isRequired,

  /**
   * Indicates whether to disable the minus button when the count is one.
   */

  disableMinusButtonAtOne: PropTypes.bool,

  /**
   * The function called when plus button is clicked
   */

  onPlus: PropTypes.func.isRequired,

  /**
   *  The count of menu item
   */
  count: PropTypes.number.isRequired,

  /**
   * The discounted price of the menu item, if available.
   */
  discountPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  menuItemPrice: PropTypes.number,
};

export default MenuItemInfo;
