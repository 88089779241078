import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import useOutsideClick from "utils/hooks/useOutsideClick";
import DropdownOption from "components/admin/forms/dropdown/dropdown-option/DropdownOption";
import ICON_ARROW from "assets/icons/arrows/DropdownArrowDown.svg";
import ICON_ADD from "assets/icons/math-operators/add/PlusAdmin.svg";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import useLanguage from "utils/hooks/useLanguage";

import "./Dropdown.scss";

export const ENUMS = {
  name: "Dropdown",
};

const Dropdown = ({
  options = [],
  onChange,
  value,
  isEnableAdd,
  addButtonText,
  isGrouped,
  isMultiple,
  isDisabled,
  onAdd,
  error,
  hasError,
  dropdownWithIcon,
  isBorderless,
  isOptionRequired,
  placeholder,
  search,
  className,
  menuMaxHeight,
  isMultiLanguage,
  hideDescription,
  required,
  showCodeColumnInTitle = false,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const { t } = useTranslation();
  const subValue = isGrouped
    ? options?.find((option) =>
        option.subOptions.some((subOption) => subOption?.id === value?.id)
      )
    : "";
  const [openSlide, setOpenSlide, mainElementRef, toggleBtnRef] =
    useOutsideClick();
  const [displayedOptions, setDisplayedOptions] = useState(options || []);
  const [searchValue, setSearchValue] = useState("");

  // TODO fix infinite loop. Happens when backend is down.
  useEffect(() => {
    setDisplayedOptions(options);
  }, [options]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    if (value.trim() !== "") {
      const result = options.filter((option) =>
        getDisplayValue(option.name)
          .toLowerCase()
          .split(" ")
          .some((word) => word.startsWith(value.toLowerCase()))
      );
      setDisplayedOptions(result);
    } else {
      setDisplayedOptions(options);
    }
  };

  const handleOptionClick = (option) => {
    onChange(option);
    if (!isMultiple) setOpenSlide(false);
  };
  const getDisplayValue = (property) => {
    return isMultiLanguage ? displayDataByLanguage(property) : property;
  };
  const renderSelectedValue = () => {
    let selectedDisplayValue = "";
    if (isMultiple && value?.length > 0) {
      selectedDisplayValue = value
        .map((option) => getDisplayValue(option.name))
        .join(", ");
    } else if (!isMultiple && value && Object.entries(value).length > 0) {
      selectedDisplayValue = showCodeColumnInTitle
        ? getDisplayValue(value?.code)
        : getDisplayValue(value?.name);
    } else {
      return null;
    }
    return selectedDisplayValue;
  };
  return (
    <div className={cx("Dropdown", className)}>
      <div
        className={cx("DropdownSelectedItem", {
          isDisabled: isDisabled,
          isBorderless: isBorderless,
          hasError: hasError,
        })}
        onClick={() => setOpenSlide(isDisabled ? false : !openSlide)}
        ref={toggleBtnRef}
      >
        <h6
          className={cx("Medium DropdownLabel", {
            isCentered: !renderSelectedValue(),
            hasError: hasError,
          })}
        >
          {placeholder}
          {required && <span className="InputControlRequiredSign">*</span>}
        </h6>

        <div className="DropdownSelectedItemTitle">
          {dropdownWithIcon && value.imgSrc && (
            <img
              className="DropdownSelectedItemTitleImage"
              src={value.imgSrc}
              alt={getDisplayValue(value.name)}
            />
          )}
          {dropdownWithIcon && value.symbol && (
            <h5 className="DropdownSelectedItemTitleSymbol">{value.symbol}</h5>
          )}
          <h6 className="Medium">
            {renderSelectedValue()}
            {isGrouped && value && subValue && (
              <span className="DropdownSelectedItemSubTitle">{` ( ${getDisplayValue(
                subValue.name
              )})`}</span>
            )}
          </h6>
        </div>
        <img
          className={cx("DropdownArrow", { isOpen: openSlide })}
          src={ICON_ARROW}
          alt="arrow"
        />
      </div>
      {openSlide && (
        <div
          className="DropdownOptions"
          ref={mainElementRef}
          style={{ maxHeight: menuMaxHeight ? `${menuMaxHeight}px` : "240px" }}
        >
          {search && (
            <Search
              onChange={(value) => {
                handleSearchChange(value);
              }}
              value={searchValue}
              type={SEARCH_ENUMS.types.TYPE_B}
              classname="DropdownSearch"
              placeholder={t("inputs.search")}
            />
          )}
          {isEnableAdd && (
            <h6
              className="DropdownOptionsAddButton DropdownOption Medium"
              onClick={onAdd}
            >
              <img
                className="DropdownOptionsAddButtonIcon"
                src={ICON_ADD}
                alt="add"
              />
              {addButtonText}
            </h6>
          )}
          {displayedOptions.length === 0 && (
            <h6 className="EmptyDropdown Medium">
              {t("emptyStates.noOption")}
            </h6>
          )}
          {displayedOptions.map((option, index) =>
            isGrouped ? (
              <div key={index}>
                <h6 className="h7 DropdownGroupName">
                  {getDisplayValue(option.name)}
                </h6>
                {option.subOptions.map((subOption, subIndex) => (
                  <DropdownOption
                    key={subIndex}
                    option={subOption}
                    value={value}
                    isOptionRequired={isOptionRequired}
                    handleOptionClick={handleOptionClick}
                    isMultiple={isMultiple}
                    isGrouped={isGrouped}
                    isMultiLanguage={isMultiLanguage}
                  />
                ))}
              </div>
            ) : (
              <DropdownOption
                key={index}
                isOptionRequired={isOptionRequired}
                option={option}
                value={value}
                handleOptionClick={handleOptionClick}
                isMultiple={isMultiple}
                dropdownWithIcon={dropdownWithIcon}
                isMultiLanguage={isMultiLanguage}
                hideDescription={hideDescription}
                showCodeColumnInTitle={showCodeColumnInTitle}
              />
            )
          )}
        </div>
      )}

      {error}
    </div>
  );
};

Dropdown.propTypes = {
  /**
   * The options of dropdown
   */
  options: PropTypes.array,

  /**
   * Callback function to handle dropdown change events
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The current value of the dropdown
   */
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),

  /**
   * Enables or disables the add button
   */
  isEnableAdd: PropTypes.bool,

  /**
   * Text for the add button
   */
  addButtonText: PropTypes.string,

  /**
   * Determines if the options in the dropdown are grouped
   */
  isGrouped: PropTypes.bool,

  /**
   * Determines if multiple selections are allowed
   */
  isMultiple: PropTypes.bool,

  /**
   * Indicates whether the input is disabled
   */
  isDisabled: PropTypes.bool,

  /**
   * Callback function to handle the add button click event in dropdown
   */
  onAdd: PropTypes.func,

  /**
   * Determines if the dropdown has an icon
   */
  dropdownWithIcon: PropTypes.bool,

  /**
   * Indicates whether the input should have no border
   */
  isBorderless: PropTypes.bool,

  /**
   * Placeholder text for the dropdown
   */
  placeholder: PropTypes.string,

  /**
   * Indicates whether the dropdown has search input or not
   */
  search: PropTypes.bool,

  /**
   * Additional class name for custom styling
   */
  className: PropTypes.string,

  /**
   *  maximum height of the options list
   */
  menuMaxHeight: PropTypes.number,

  /**
   * Indicates whether the dropdown supports multiple languages
   */
  isMultiLanguage: PropTypes.bool,

  /**
   * Indicates whether to show the description of the option in the dropdown
   */
  hideDescription: PropTypes.bool,

  /**
   * Indicates whether the input is required
   */
  required: PropTypes.bool,

  /**
   * Determines if the option is required
   */
  isOptionRequired: PropTypes.bool,

  /**
   * The error message to be displayed
   */
  error: PropTypes.node,

  /**
   * Indicates whether the dropdown has an error
   */
  hasError: PropTypes.bool,
  /**
   * Indicates whether the dropdown options represent currencies
   */
  showCodeColumnInTitle: PropTypes.bool,
};

export default Dropdown;
