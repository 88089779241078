import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { createDOBucketName } from "utils/DO-Spaces";
import Confirm from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import ThreeDots from "components/admin/elements/three-dots/ThreeDots";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import ICON_CLOSED_EYE from "assets/icons/eye/EyeCloseWhite.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import AccessDeniedModal from "pages/admin/admin-pages/admin-order/admin-order-actions/access-denied-modal/AccessDeniedModal";
import { STORE_NAMES } from "utils/constants/redux";

import "./Item.scss";

export const ENUMS = {
  name: "Item",
};

const Item = ({
  item,
  onClick,
  onDelete,
  category,
  onDuplicate,
  allowClickDeleteButton,
  allowClickDuplicateButton,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const discountedPrice = calculateMenuItemPriceBySchedule(item);
  const menuItemPrice = calculateItemPriceWithDefaultModificationPrice(item);

  const { menuCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const { t } = useTranslation();
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();

  const [
    openSlideDeniedModal,
    setOpenSlideDeniedModal,
    mainElementRefDeniedModal,
  ] = useOutsideClick();

  const threeDotsOptions = [
    {
      name: t("menu.item.deleteItem"),
      action: () =>
        allowClickDeleteButton
          ? setOpenSlideConfirm(true)
          : setOpenSlideDeniedModal(true),
    },
  ];

  if (category.type !== menuCategoryType.special_category) {
    threeDotsOptions.push({
      name: t("menu.item.duplicateItem"),
      action: allowClickDuplicateButton
        ? onDuplicate
        : () => setOpenSlideDeniedModal(true),
    });
  }

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
  };
  const handleCloseDeniedModal = (e) => {
    e.stopPropagation();
    setOpenSlideDeniedModal(false);
  };

  return (
    <div
      className="Item"
      onClick={(e) => {
        e.stopPropagation();
        if (!openSlideDeniedModal && !openSlideConfirm) {
          onClick();
        }
      }}
    >
      <div className="ItemThreeDots">
        <ThreeDots options={threeDotsOptions} />
      </div>
      {!item.isPublished &&
        category.type !== menuCategoryType.special_category && (
          <>
            <div className="ItemUnPublishedOverlay"></div>
            <img
              className="ItemClosedEye"
              src={ICON_CLOSED_EYE}
              alt="closed eye"
            />
          </>
        )}
      <ImageWithPlaceholder
        imageSource={createDOBucketName(item.coverImageSrc)}
        placeholder={IMAGE_ITEM_PLACEHOLDER}
        alt={displayDataByLanguage(item.name)}
        className={cx("ItemImage")}
      />
      <div className="ItemInfo">
        <h6 className="Medium ItemName">{displayDataByLanguage(item.name)}</h6>
        <div className="ItemInfoPrice">
          {discountedPrice !== null && (
            <Price
              type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
              value={menuItemPrice}
            />
          )}
          <Price
            type={ENUMS_PRICE.types.PRICE_S}
            value={
              discountedPrice !== null
                ? menuItemPrice + discountedPrice
                : menuItemPrice
            }
          />
        </div>
      </div>
      <Confirm
        title={t("modal.deleteModalTitle")}
        description={t("modal.deleteModalDescription")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={(e) => handleOnCancel(e)}
        onConfirm={(e) => handleOnConfirm(e)}
      />
      <AccessDeniedModal
        onClose={(e) => handleCloseDeniedModal(e)}
        openSlide={openSlideDeniedModal}
        mainElementRef={mainElementRefDeniedModal}
      />
    </div>
  );
};

Item.propTypes = {
  /**
   * The item data to be displayed
   */
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array.isRequired,
    name: PropTypes.array.isRequired,
    isPublished: PropTypes.bool.isRequired,
    priceSell: PropTypes.number.isRequired,
    rate: PropTypes.object.isRequired,
    isFixed: PropTypes.bool,
  }).isRequired,

  /**
   * The function called when the component is clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The function called when delete item
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * The function called when duplicate item
   */
  category: PropTypes.object,
  onDuplicate: PropTypes.func.isRequired,
  allowClickDeleteButton: PropTypes.bool,
  allowClickDuplicateButton: PropTypes.bool,
};

export default Item;
