import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./SortChipItem.module.scss";

const sortChipItem = ({ onClick, isSelected, name }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(`${styles.BodySortItem}`, {
        [styles.isActive]: isSelected,
      })}
      onClick={onClick}
    >
      <h6 className="SemiBold">{t(`sort.${name}`)}</h6>
    </div>
  );
};

export default sortChipItem;

sortChipItem.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
};
