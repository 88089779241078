import { CHART_DATA_KEYS } from "pages/admin/admin-pages/admin-dashboard/admin-dash-feedbacks/AdminDashFeedbacks";

export const hasFeedbacks = (
  chartData,
  starCountKey = CHART_DATA_KEYS.starCount
) => {
  return chartData.some((item) => item[starCountKey] > 0);
};

export const getFeedbacksStarsChartData = (
  feedbacksData,
  type,
  keys = CHART_DATA_KEYS
) => {
  return feedbacksData?.[type]
    ? Object.keys(feedbacksData[type]).map((key) => ({
        [keys.xAxisDataKey]: `${key[1]}★`,
        [keys.starCount]: feedbacksData[type][key],
      }))
    : [];
};

export const formatDate = (date) => {
  const dateObj = new Date(date);
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};
