import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import Spinner from "components/elements/spinner/Spinner";
import { getBrandMainLogoDark } from "utils/branding-helper";
import If from "components/if/If";
import useToggle from "utils/hooks/useToggle";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import styles from "./VR.module.scss";

const FooterLogo = getBrandMainLogoDark();

const VR = () => {
  const { t } = useTranslation();
  const { navigateToPreservedBackUrl } = useInternalNavigation();
  const { businessId } = useParams();
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const vrLink = business?.vrLink;
  const vrLinkEnabled = business?.vrLinkEnabled;

  const { value: isLoading, setOff: stopLoading } = useToggle({
    initialValue: true,
  });

  const onClose = () => {
    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.info}`,
    });
  };

  if (!vrLink || !vrLinkEnabled) {
    return (
      <Navigate
        to={`${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.info}`}
      />
    );
  }

  // useEffect(() => {
  //   if (vrLink) {
  //     fetch(vrLink, { method: "HEAD" }).catch(onClose);
  //   }
  // }, [vrLink]);

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <FooterLogo />
        <p className={styles.Title}>{t("reservation.vr.vrView")}</p>
        <CloseButton
          onClick={onClose}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
          className={styles.CloseBtn}
        />
      </div>
      <div className={styles.Content}>
        <If state={isLoading}>
          <Spinner className={styles.Spinner} />
        </If>
        <iframe
          className={styles.Iframe}
          width={"100%"}
          height={"100%"}
          src={vrLink}
          sandbox="allow-scripts allow-same-origin allow-popups"
          onLoad={stopLoading}
        />
      </div>
    </div>
  );
};

export default VR;
