import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import MemoryGame from "assets/images/game/fun-zone/memoryGame.svg";
import TowerGame from "assets/images/game/fun-zone/towerGame.svg";
import TicTacToe from "assets/images/funZone/TicTacToe.png";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import "./FunZoneGames.scss";

const FunZoneGames = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.funZone });
  const { t } = useTranslation();
  const { businessId } = useParams();
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const isOrderEmpty = !order;
  const { shouldDisplayMenuItemImage } = useMenuHelper();
  const { navigate, navigateToPreservedBackUrl } = useInternalNavigation();

  const handleGoBack = () => {
    navigateToPreservedBackUrl({
      fallbackUrl: isOrderEmpty
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}`,
    });
  };

  const handleGoMemoryGame = () => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.memoryCard}`,
      preserveExistingQueries: true,
    });
  };
  const handleGoTowerBlock = () => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.towerBlock}`,
      preserveExistingQueries: true,
    });
  };
  const handleGoTicTacToeGame = () => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.ticTacToe}`,
      preserveExistingQueries: true,
    });
  };

  const FunZoneGamesHeader = (
    <div className="FunZoneGamesHeader">
      <BackButton
        type={BACK_BUTTON_ENUMS.types.TYPE_E}
        onClick={handleGoBack}
      />
      <h2 className="SemiBold">{t("funZone.selectGame")}</h2>
    </div>
  );

  const FunZoneGamesBody = (
    <div className="FunZoneGamesBody">
      <h6 className="Medium">{t("funZone.playGame")}</h6>
      <div className="FunZoneGamesBodyGames">
        {/*<button*/}
        {/*  className="FunZoneGamesBodySpinnerGame FunZoneGamesBodyGame"*/}
        {/*  onClick={handleGoSpinner}*/}
        {/*>*/}
        {/*  <div className="FunZoneGamesBodyGamesText">*/}
        {/*    <h1 className="Bold">{t("funZone.who")}</h1>*/}
        {/*    <h3 className="Medium">{t("funZone.paysTheBill")}</h3>*/}
        {/*  </div>*/}
        {/*  <img src={Spinner} alt="Spinner" />*/}
        {/*</button>*/}
        {shouldDisplayMenuItemImage && (
          <button
            className="FunZoneGamesBodyMindGame FunZoneGamesBodyGame"
            onClick={handleGoMemoryGame}
          >
            <div className="FunZoneGamesBodyGamesText">
              <h1 className="Bold">{t("funZone.mindGame")}</h1>
              <h3 className="Medium">{t("funZone.canYouRemember")}</h3>
            </div>
            <img src={MemoryGame} alt="Memory Game" />
          </button>
        )}

        <button
          className="FunZoneGamesBodyTowerGame FunZoneGamesBodyGame"
          onClick={handleGoTowerBlock}
        >
          <div className="FunZoneGamesBodyGamesText">
            <h1 className="Bold">{t("funZone.tower")}</h1>
            <h3 className="Medium">{t("funZone.buildYourHighestTower")}</h3>
          </div>
          <img src={TowerGame} alt="Tower Game" />
        </button>
        <button
          className="FunZoneGamesBodyTicTacGame FunZoneGamesBodyGame"
          onClick={handleGoTicTacToeGame}
        >
          <div className="FunZoneGamesBodyGamesText">
            <h1 className="Bold">{t("funZone.ticTackToe.title")}</h1>
            <h3 className="Medium">
              {t("funZone.ticTackToe.outsmartYourOpponent")}
            </h3>
          </div>
          <img src={TicTacToe} alt="TicTacToe" />
        </button>
      </div>
    </div>
  );

  return (
    <div className="FunZoneGames">
      {FunZoneGamesHeader}
      {FunZoneGamesBody}
    </div>
  );
};

export default FunZoneGames;
