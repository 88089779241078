import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as Back } from "assets/icons/pos/arrow-narrow-left.svg";

import styles from "./BackButton.module.scss";

const BackButton = ({ onClick, className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.Root, className)} onClick={onClick}>
      <Back />
      <h6 className={cx(`SemiBold h7 ${styles.Title}`)}>{t("buttons.back")}</h6>
    </div>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BackButton;
