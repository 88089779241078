import React from "react";
import { useNavigate } from "react-router-dom";

import NavigationCard, {
  ENUMS as NAVIGATION_CARD_ENUMS,
} from "components/pos/main-page-card/MainPageCard";
import { ROUTE_NAME } from "utils/constants/routes";
import { ROLES } from "utils/constants/global";
import { useAdminRouteController } from "utils/context-api/AdminRouteControllerContext";

import styles from "./Navigation.module.scss";

const Navigation = () => {
  const { routes } = useAdminRouteController();
  const navigate = useNavigate();

  const navigationCardClickHandler = (path) => {
    navigate(`${ROUTE_NAME.admin}${path}`);
  };

  return (
    <div className={styles.Root}>
      {routes.map((route) => {
        const { key, label, icon, subRoutes } = route;
        return (
          <NavigationCard
            className={styles.NavigationCard}
            key={key}
            onClick={() => navigationCardClickHandler(subRoutes[0].path)}
            label={label}
            icon={icon}
            type={
              route.key === ROLES.superAdmin.name
                ? NAVIGATION_CARD_ENUMS.types.PRIMARY
                : NAVIGATION_CARD_ENUMS.types.SECONDARY
            }
          />
        );
      })}
    </div>
  );
};

export default Navigation;
