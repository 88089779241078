import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useAsync from "utils/hooks/useAsync";
import { STORE_NAMES } from "utils/constants/redux";
import { aiSuperSearchMenu } from "utils/api/services/open-ai";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { formatMenuForAI } from "pages/client/magic-order/helper";
import {
  MP_EVENTS,
  MP_PAGE_NAMES,
  MP_PROP_NAMES,
} from "utils/constants/mixpanel";
import ScrollBottom from "components/buttons/scroll-bottom/ScrollBottom";
import MagicOrderHeader from "pages/client/magic-order/magic-order-header/MagicOrderHeader";
import MagicOrderBody from "pages/client/magic-order/magic-order-body/MagicOrderBody";
import MagicOrderFooter from "pages/client/magic-order/magic-order-footer/MagicOrderFooter";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";

import "./MagicOrder.scss";

const SpeechToText = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.magicOrder });
  const { trackMixpanel } = useMixpanel();
  const { businessId } = useParams();
  const { t } = useTranslation();
  const [typedText, setTypedText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { allCategories } = useMenuHelper();
  const lastPromptRef = useRef(null);
  const lastResultRef = useRef(null);

  const [promptResultsHistory, setPromptResultsHistory] = useState(
    JSON.parse(sessionStorage.getItem("promptResultsHistory"))?.[businessId] ||
      []
  );
  const speechToTextBodyRef = useRef(null);
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);

  useEffect(() => {
    const parsedHistory =
      JSON.parse(sessionStorage.getItem("promptResultsHistory")) || {};
    sessionStorage.setItem(
      "promptResultsHistory",
      JSON.stringify({ ...parsedHistory, [businessId]: promptResultsHistory })
    );
  }, [promptResultsHistory]);
  const {
    execute: executeAiSuperSearchMenu,
    loading: loadingAiSuperSearchMenu,
  } = useAsync(aiSuperSearchMenu, {
    onError: () => {
      toast.error(t("toastMessages.error.common"));
      setPromptResultsHistory((prev) =>
        prev.map((item, index) =>
          index === prev.length - 1
            ? {
                ...item,
                response: {
                  success: false,
                  result: t("toastMessages.error.common"),
                },
              }
            : item
        )
      );
    },
    onSuccess: ({ data }) => {
      if (!data.result || data.result.length === 0) {
        setPromptResultsHistory((prev) =>
          prev.map((item, index) =>
            index === prev.length - 1
              ? {
                  ...item,
                  response: {
                    success: false,
                    result: t("record.magicOrderError"),
                  },
                }
              : item
          )
        );
      } else {
        setPromptResultsHistory((prev) =>
          prev.map((item, index) =>
            index === prev.length - 1
              ? {
                  ...item,
                  response: {
                    success: true,
                    result: data?.result,
                  },
                }
              : item
          )
        );
      }
    },
  });

  const handleSaveSpeech = ({ prompt }) => {
    const promptBody = {
      businessId: businessId,
      data: {
        userPrompt: prompt,
        menu: formatMenuForAI(menu, allCategories),
      },
    };
    executeAiSuperSearchMenu(promptBody);
    setTypedText("");
    setPromptResultsHistory((prev) => [
      ...prev,
      { prompt: prompt, response: null },
    ]);
    trackMixpanel(`${MP_EVENTS.magicOrderPrompt}`, {
      [MP_PROP_NAMES.businessId]: businessId,
      [MP_PROP_NAMES.prompt]: prompt,
    });
  };

  useEffect(() => {
    if (promptResultsHistory[promptResultsHistory.length - 1]?.response) {
      const scrollDistance =
        speechToTextBodyRef.current.scrollHeight -
        lastPromptRef.current.clientHeight -
        lastResultRef.current.clientHeight -
        125;
      speechToTextBodyRef?.current?.scrollTo({
        top: scrollDistance,
        behavior: "smooth",
      });
    } else {
      speechToTextBodyRef?.current?.scrollTo({
        top: speechToTextBodyRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [promptResultsHistory]);

  return (
    <div className="SpeechToTextContainer">
      <MagicOrderHeader
        resultsHistory={promptResultsHistory}
        setResultsHistory={setPromptResultsHistory}
      />
      <MagicOrderBody
        resultsHistory={promptResultsHistory}
        loadingAiSuperSearchMenu={loadingAiSuperSearchMenu}
        handleSaveSpeech={handleSaveSpeech}
        speechToTextBodyRef={speechToTextBodyRef}
        lastResultRef={lastResultRef}
        lastPromptRef={lastPromptRef}
      />
      <MagicOrderFooter
        handleSaveSpeech={handleSaveSpeech}
        loadingAiSuperSearchMenu={loadingAiSuperSearchMenu}
        typedText={typedText}
        setTypedText={setTypedText}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
      />
      <ScrollBottom
        isFocused={isFocused}
        containerRef={speechToTextBodyRef}
        promptResultsHistory={promptResultsHistory}
      />
    </div>
  );
};

export default SpeechToText;
