import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import IosSwitch from "components/forms/ios-switch/IosSwitch";

import "./CallButton.scss";

export const ENUMS = {
  name: "CallButton",
};
const CallButton = ({
  isChecked,
  onChange,
  title,
  isDisabled,
  className,
  icon,
}) => {
  return (
    <div
      className={cx("CallButtonContainer", className, {
        isDisabled,
      })}
      onClick={isDisabled ? undefined : onChange}
    >
      <div className="CallButton">
        {icon}
        <h5 className="SemiBold">{title}</h5>
      </div>

      <IosSwitch isChecked={isChecked} isDisabled={isDisabled} darkMode />
    </div>
  );
};

CallButton.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node,
};
export default CallButton;
