import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";

import "./IconAskBill.scss";

const IconAskBill = ({ isActive }) => {
  const menuCurrencySymbol = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency?.symbol
  );
  return (
    <div className={cx("IconAskBill", { isActive })}>
      <h5 className="IconAskBillSymbol">{menuCurrencySymbol}</h5>
    </div>
  );
};
IconAskBill.propTypes = {
  isActive: PropTypes.bool,
};

export default IconAskBill;
