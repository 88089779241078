import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as UndoIcon } from "assets/icons/undo/undo.svg";

import "./UndoButton.scss";

const UndoButton = ({ onClick }) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      type="button"
      onClick={(e) => handleOnClick(e)}
      className={cx("UndoButton")}
    >
      <UndoIcon className="UndoButtonIcon" />
    </button>
  );
};

UndoButton.propTypes = {
  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,
};

export default UndoButton;
