export const LANGUAGES = {
  en: {
    name: "English",
    code: "EN",
  },
  ru: {
    name: "Русский",
    code: "RU",
  },
  az: {
    name: "Azərbaycan",
    code: "AZ",
  },
  deu: {
    name: "Deutsch",
    code: "DE",
  },
  tr: {
    name: "Türkiye",
    code: "TR",
  },
  es: {
    name: "Spanish",
    code: "ES",
  },
  ge: {
    name: "Georgia",
    code: "GE",
  },
  uz: {
    name: "Uzbek",
    code: "UZ",
  },
  ar: {
    name: "العربية",
    code: "AR",
  },
};
