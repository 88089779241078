import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import GuestModal, {
  MODAL_POSITIONS,
} from "components/guest/guest-modal/GuestModal";
import { ReactComponent as WarningIcon } from "assets/icons/basket/Warning.svg";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";

import "./EmptyOrderInfoModal.scss";

const EmptyOrderInfoModal = ({
  openSlide,
  mainElementRef,
  onClose,
  handleGoBackToMenu,
  isBasketEmpty,
}) => {
  const { t } = useTranslation();

  const EmptyOrderInfoModalHeader = (
    <div className="EmptyOrderInfoModalHeader">
      <WarningIcon className="WarningIcon" />
      <CloseButton onClick={onClose} type={ENUMS_CLOSE_BUTTON.types.TYPE_S} />
    </div>
  );

  const EmptyOrderInfoModalBody = (
    <div className="EmptyOrderInfoModalBody">
      <h5 className="Medium">
        {isBasketEmpty
          ? t("basket.emptyBasketDescription")
          : t("modal.emptyOrderInfo")}
      </h5>
    </div>
  );

  const EmptyOrderInfoModalFooter = (
    <div className="EmptyOrderInfoModalFooter">
      <PrimaryButton
        onClick={handleGoBackToMenu}
        className="EmptyOrderInfoModalFooterButton"
        text={t("buttons.backToMenu")}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
      />
    </div>
  );
  return (
    <GuestModal
      containerClassname={"EmptyOrderInfoModalContainerClassname"}
      header={EmptyOrderInfoModalHeader}
      mainElementRef={mainElementRef}
      body={EmptyOrderInfoModalBody}
      footer={EmptyOrderInfoModalFooter}
      openSlide={openSlide}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
EmptyOrderInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  openSlide: PropTypes.bool.isRequired,
  mainElementRef: PropTypes.object.isRequired,
  handleGoBackToMenu: PropTypes.func.isRequired,
  isBasketEmpty: PropTypes.bool.isRequired,
};
export default EmptyOrderInfoModal;
