import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { STORE_NAMES } from "utils/constants/redux";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { createOrderAsync } from "redux/actions/orderActions";
import { handleOnAsyncError } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import AdminOrderTables from "pages/admin/admin-pages/admin-order/admin-order-tables/AdminOrderTables";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import AdminOrderPage from "./admin-order-page/AdminOrderPage";
import { QUERY_PARAMS } from "utils/constants/routes";
import AdminDetailedChat from "pages/admin/admin-pages/admin-order/admin-detailed-chat/AdminDetailedChat";

import "./AdminOrder.scss";

const AdminOrder = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isNewOrderCreated, setIsNewOrderCreated] = useState(false);

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const { user, userWithPin } = useSelector((state) => state[STORE_NAMES.user]);

  const [openSlideOrder, setOpenSlideOrder] = useOutsideClick();
  const [openSlideChat, setOpenSlideChat] = useOutsideClick();

  const { t } = useTranslation();

  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();

  const [selectedTable, setSelectedTable] = useState(null);

  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const selectedZone = zones.find((zone) =>
    zone.tables.some((table) => table.id === selectedTable?.id)
  );

  const handleOnClickTable = async (table) => {
    const order = orders.find((order) => order.table.id === table.id);
    if (order) {
      searchParams.set(QUERY_PARAMS.selectedOrder, order.id);
      setSearchParams(searchParams);
    } else {
      setSelectedTable(table);
      setOpenSlideConfirm(true);
    }
  };

  useEffect(() => {
    setOpenSlideOrder(searchParams.has(QUERY_PARAMS.selectedOrder));
    setOpenSlideChat(searchParams.has(QUERY_PARAMS.showDetailedChat));
  }, [searchParams]);

  const createNewOrder = async () => {
    const newOrder = {
      tableId: selectedTable.id,
      assigneeId: selectedTable.assignee?.id || userWithPin?.id || user.id,
    };
    const response = await dispatch(
      createOrderAsync({
        businessId,
        order: newOrder,
      })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      setOpenSlideOrder(true);
      setOpenSlideConfirm(false);
      searchParams.set(QUERY_PARAMS.selectedOrder, response.payload.id);
      setSearchParams(searchParams);
      setIsNewOrderCreated(true);
    }
  };

  return (
    <div className="AdminOrder">
      <AdminOrderTables onClickTable={handleOnClickTable} />
      {openSlideOrder && (
        <AdminOrderPage
          setOpenSlide={setOpenSlideOrder}
          orders={orders}
          isNewOrderCreated={isNewOrderCreated}
          setIsNewOrderCreated={setIsNewOrderCreated}
        />
      )}
      {openSlideChat && (
        <div className="AdminOrderChat">
          <AdminDetailedChat
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </div>
      )}

      <Confirm
        title={t("modal.warningModalTitleNewOrder")
          .replace("{{table}}", `${selectedTable?.name}`)
          .replace("{{zone}}", `${selectedZone?.name}`)}
        type={ENUMS_CONFIRM.types.TYPE_B}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={() => setOpenSlideConfirm(false)}
        onConfirm={createNewOrder}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
};

export default AdminOrder;
