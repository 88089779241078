import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";
import BasketHeroSection from "pages/client/basket/basket-hero-section/BasketHeroSection";
import {
  findMenuItemByIdAndPublished,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateServiceFee,
  calculateTax,
  findItemByIdForBasket,
} from "utils/general";
import {
  ACTIONS,
  resetItems,
  updateBasket,
  updateFavorites,
} from "redux/slices/basketStore";
import { setLoading } from "redux/slices/ordersStore";
import OrderInfo from "components/cards/order-info/OrderInfo";
import BasketItem from "components/elements/basket-item/BasketItem";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useAsync from "utils/hooks/useAsync";
import { updateOrderGuest } from "utils/api/services/order";
import EmptyState from "components/admin/empty-state/EmptyState";
import ICON_EMPTY_BASKET from "assets/icons/basket/empty-basket.svg";
import WelcomeClient from "components/welcome-client/WelcomeClient";
import useOutsideClick from "utils/hooks/useOutsideClick";
import OrderConfirm from "components/guest/guest-modal/order-confirm/OrderConfirm";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import {
  MP_EVENTS,
  MP_PAGE_NAMES,
  MP_PROP_NAMES,
  MP_SOURCE_NAME,
} from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import { TAB_BAR_HEIGHT } from "pages/client/menu-v2/tab-bar/TabBar";
import Recommendations from "pages/client/recommendations/Recommendations";
import SwitchOptions from "components/elements/switch-options/SwitchOptions";
import MenuCard, { ENUMS } from "pages/client/menu-v2/MenuCard/MenuCard";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import If from "components/if/If";
import EmptyOrderInfoModal from "pages/client/basket/empty-order-info-modal/EmptyOrderInfoModal";
import BasketMessageModal from "pages/client/basket/basket-comment-section/basket-comment-modal/BasketMessageModal";
import BasketCommentSection from "pages/client/basket/basket-comment-section/BasketMessageSection";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import "./Basket.scss";

export const BASKET_METHOD = {
  active: "Active",
  favorite: "Favorite",
};

const SWITCH_OPTIONS_FOR_BASKET = [
  {
    name: "Active",
    label: "common.active",
    value: BASKET_METHOD.active,
  },
  {
    name: "Favorite",
    label: "basket.favorites",
    value: BASKET_METHOD.favorite,
  },
];
const heroHeight = 91;

const Basket = () => {
  const { allCategories } = useMenuHelper();
  const { isTabBarHidden } = useGuestLayout();
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.basket });
  const { trackMixpanel } = useMixpanel();
  const { navigate } = useInternalNavigation();
  const { pathname } = useLocation();
  // const audioRef = useRef(null);
  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data.currency.code
  );
  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const isLoadingOrder = useSelector(
    (state) => state[STORE_NAMES.orders]?.thunkAPIStates?.getAllOrder
  );

  const resultHeight = 76 + heroHeight;
  const { shouldDisplayMenuItemImage } = useMenuHelper();

  const { execute: executeUpdateOrder } = useAsync(updateOrderGuest, {
    onSuccess: () => {
      trackMixpanel(`${MP_EVENTS.basket.checkout}`, {
        [MP_PROP_NAMES.idsOfItems]: idsOfItems,
        [MP_PROP_NAMES.numOfItems]: orderItemsCount,
        [MP_PROP_NAMES.totalPrice]: totalPrice,
        [MP_PROP_NAMES.currency]: currency,
      });
      navigate({
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}`,
        preserveExistingQueries: true,
        queries: {
          [QUERY_PARAMS.showConfetti]: true,
        },
      });
      dispatch(
        resetItems({
          userId: guestId,
          actionType: ACTIONS.resetOrderItems,
        })
      );
    },
  });

  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrder = useSelector((state) => state[STORE_NAMES.basket].order);
  const tableId = useSelector((state) => state[STORE_NAMES.qrScan].table.id);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { extraVisibility } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const { data: menu, isLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick(() => trackMixpanel(`${MP_EVENTS.basket.clickCloseModal}`));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = orders?.find((order) => order.table.id === tableId);
  const guests = basketOrder ? basketOrder.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);

  const initialLocalMessage = guest?.orderMessage || "";
  const [localMessage, setLocalMessage] = useState(initialLocalMessage);

  const handleClickResetInput = () => {
    setLocalMessage(initialLocalMessage);
  };
  const [
    openSlideBasketMessageModal,
    setOpenSlideBasketMessageModal,
    mainElementRefBasketMessageModal,
  ] = useOutsideClick(handleClickResetInput);

  const [
    openSlideEmptyOrderInfo,
    setOpenSlideEmptyOrderInfo,
    mainElementRefEmptyOrderInfo,
  ] = useOutsideClick();

  const [selectedBasketMethod, setSelectedLoginMethod] = useState(
    BASKET_METHOD.active
  );

  const isOrderEmpty = !order;

  const favoriteItems = useMemo(() => {
    if (!menu || !guest) return [];
    return guest.favoriteItems
      .map((id) => findItemByIdForBasket(id, allCategories))
      .filter((item) => item && item.isPublished && !item.isArchived);
  }, [menu, guest]);

  const orderItems = guest?.orderItems || [];
  const filteredOrderItems =
    orderItems.filter((orderItem) => {
      const menuItem =
        orderItem.item &&
        findItemByIdForBasket(orderItem.item.id, allCategories);
      return menuItem?.isPublished && !menuItem?.isArchived;
    }) || [];

  const idsOfItems = filteredOrderItems
    .flatMap(({ item }) => item.id)
    .sort((a, b) => a - b);

  const orderItemsCount = filteredOrderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );

  const isBasketEmpty = orderItemsCount === 0;
  const convertedGuestsInBasket = useMemo(() => {
    return [
      {
        ...guest,
        orderItems: filteredOrderItems
          ? filteredOrderItems.map((orderItem) => {
              return {
                ...orderItem,
                item: findMenuItemByIdAndPublished(
                  allCategories,
                  orderItem.item,
                  dispatch,
                  guest.id
                ),
              };
            })
          : [],
      },
    ];
  }, [filteredOrderItems]);
  const subtotal =
    filteredOrderItems.length > 0
      ? calculateAllOrdersPrice(convertedGuestsInBasket)
      : 0;

  const discountPrice =
    filteredOrderItems.length > 0
      ? calculateAllOrdersDiscountPrice(convertedGuestsInBasket)
      : 0;

  const tax = menu ? calculateTax(subtotal, menu.extraFees.tax) : 0;

  const serviceFeeTotal = menu
    ? calculateServiceFee(subtotal, discountPrice, serviceFee)
    : 0;

  const totalPrice = subtotal
    ? calculateAllOrdersTotalPrice(
        subtotal,
        menu?.extraFees,
        null,
        serviceFeeTotal,
        discountPrice
      )
    : 0;
  const handleRemoveFavItem = (menuItem) => {
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const onBasketItemClick = (menuItemId, basketIndex) => {
    const itemName = allCategories
      .flatMap(({ menuItems }) => menuItems)
      .find((item) => item.id === menuItemId).name[0].value;
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}${ROUTE_NAME.basketItem}/${basketIndex}`,
      preserveExistingQueries: true,
      queries: { [QUERY_PARAMS.from]: pathname },
    });
    trackMixpanel(`${MP_EVENTS.pageView.guest.menuItem}`, {
      [MP_PROP_NAMES.itemId]: menuItemId,
      [MP_PROP_NAMES.itemName]: itemName,
      [MP_PROP_NAMES.source]: MP_SOURCE_NAME.basket,
    });
  };
  const handleRemoveBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: null,
        userId: guestId,
      })
    );
  };
  const handleAddBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: 1,
        userId: guestId,
      })
    );
  };
  const handleAddToBasketRecommendation = (menuItem) => {
    const filteredModifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => ({
            id: modification.id,
            options: modification.options.map((option) => ({
              id: option.id,
              count: option.count ?? 1,
            })),
          })),
        },
        count: 1,
        userId: guestId,
      })
    );
  };
  const handleDecreaseBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: -1,
        userId: guestId,
      })
    );
  };

  const handleOnClick = (e) => {
    if (isBasketEmpty) {
      navigate({
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
        preserveExistingQueries: true,
      });
    } else {
      e.stopPropagation();
      trackMixpanel(`${MP_EVENTS.basket.clickConfirmOrder}`);
      setOpenSlideConfirm(true);
    }
  };

  const handleOnConfirm = async (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
    try {
      await handleConfirmOrder();
    } catch (error) {
      console.error("Order confirmation failed:", error);
    }
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    trackMixpanel(`${MP_EVENTS.basket.clickCloseModal}`);
    setOpenSlideConfirm(false);
  };

  const handleConfirmOrder = async () => {
    dispatch(setLoading(true));
    const allOrderItems = [...filteredOrderItems];
    const updatedBasketOrder = {
      ...basketOrder,
      guests: [
        {
          ...guest,
          orderMessage:
            guest.orderMessage.trim() !== "" ? guest.orderMessage : undefined,
          orderItems: allOrderItems,
        },
      ],
      hasNewOrder: true,
    };

    await executeUpdateOrder({
      businessId,
      order: updatedBasketOrder,
      id: order.id,
    });

    await dispatch(setLoading(false));
  };

  const handleCloseMessageModal = () => {
    setOpenSlideBasketMessageModal(false);
  };

  const handleOpenMessageModal = () => {
    setOpenSlideBasketMessageModal(true);
  };

  const handleOpenEmptyOrderModal = () => {
    setOpenSlideEmptyOrderInfo(true);
  };
  const handleGoBackToMenu = () => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      preserveExistingQueries: true,
    });
  };

  if (isLoading) {
    return <WelcomeClient />;
  }

  const hasVisibleRecommendation = allCategories.some(
    (category) =>
      category.extraVisibility === extraVisibility.basket_recommended
  );
  const handleAddToBasket = (menuItem) => {
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    const payload = {
      menuItem: {
        id: menuItem.id,
        modifications: modifications.map((modification) => {
          return {
            id: modification.id,
            options: modification.options.map((option) => {
              return {
                id: option.id,
                count: option.count ?? 1,
              };
            }),
          };
        }),
      },
      count: 1,
      userId: guestId,
    };

    dispatch(updateBasket(payload));
  };
  const handleOnClickItem = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}`,
      preserveExistingQueries: true,
      queries: { [QUERY_PARAMS.from]: pathname },
    });
  };

  const handleOpenAr = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItemAR}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  const activeMode = selectedBasketMethod === BASKET_METHOD.active;
  const favoriteMode = selectedBasketMethod === BASKET_METHOD.favorite;
  return (
    <div className={cx("Basket", { isBasketEmpty })}>
      <BasketHeroSection />
      <div className="BasketMainSection">
        <SwitchOptions
          className={"BasketAndFavoritesSwitch"}
          options={SWITCH_OPTIONS_FOR_BASKET}
          selectedMethod={selectedBasketMethod}
          setSelectedMethod={setSelectedLoginMethod}
        />

        <If state={activeMode && !isBasketEmpty}>
          <>
            <div className="OrderItemsWrapper">
              <h2 className="BasketAllItems SemiBold">
                {t("navbarRoutes.allItems")}
              </h2>
              {filteredOrderItems.map((orderItem, index) => (
                <BasketItem
                  key={index}
                  menuItem={orderItem.item}
                  basketIndex={index}
                  menuCategories={allCategories}
                  onAdd={handleAddBasketItem}
                  onRemove={handleRemoveBasketItem}
                  onClick={onBasketItemClick}
                  onDecrease={handleDecreaseBasketItem}
                  count={orderItem.count}
                  guestId={guestId}
                />
              ))}
            </div>
            {hasVisibleRecommendation && (
              <Recommendations
                recommendCategories={allCategories}
                onAdd={handleAddToBasketRecommendation}
              />
            )}
            {order && (
              <BasketCommentSection openModal={handleOpenMessageModal} />
            )}
            <OrderInfo
              serviceFee={serviceFee}
              totalPrice={totalPrice}
              subtotal={subtotal}
              tax={tax}
              serviceFeeTotal={serviceFeeTotal}
              discountPrice={discountPrice}
            />
          </>
        </If>
        <If state={activeMode && isBasketEmpty}>
          <div
            className="BasketEmptyStateContainer"
            style={{ height: `calc(100% - ${resultHeight}px)` }}
          >
            <EmptyState
              title={t("basket.emptyBasketTitle")}
              icon={ICON_EMPTY_BASKET}
              isAdmin={false}
            />
          </div>
        </If>
        <If state={favoriteMode && favoriteItems.length > 0}>
          <div className="BasketFavoritesContainer">
            <h2 className="SemiBold FavoritesTitle">{t("basket.favorites")}</h2>
            {favoriteItems.map((item) => {
              return (
                <MenuCard
                  key={item.id}
                  menuItem={item}
                  type={
                    shouldDisplayMenuItemImage
                      ? ENUMS.types.LARGE
                      : ENUMS.types.WITHOUT_IMAGE
                  }
                  isFavorite={favoriteItems.some(
                    (favItem) => favItem.id === item.id
                  )}
                  onClick={() => handleOnClickItem(item)}
                  onFavorite={() => handleRemoveFavItem(item)}
                  onAddToBasket={handleAddToBasket}
                  handleOpenAr={() => handleOpenAr(item)}
                />
              );
            })}
          </div>
        </If>
        <If state={favoriteMode && favoriteItems.length === 0}>
          <div className="EmptyStateFavoritesContainer">
            <h5 className="SemiBold">{t("basket.emptyFavorite")}</h5>
          </div>
        </If>
      </div>

      <CTAButton
        className={cx("ConfirmOrderBtn", { isBasketEmpty })}
        style={{ bottom: isTabBarHidden ? 30 : 10 + TAB_BAR_HEIGHT }}
        onClick={!isOrderEmpty ? handleOnClick : handleOpenEmptyOrderModal}
        element={
          <div className="GuestPayDetail">
            <div className="OrderItemsToBePaidCount">
              <h6 className="SemiBold">{orderItemsCount}</h6>
            </div>
            <h4 className="Medium PayButton">
              {!isOrderEmpty
                ? isBasketEmpty
                  ? t("buttons.backToMenu")
                  : t("buttons.confirmOrder")
                : t("buttons.confirmOrder")}
            </h4>
          </div>
        }
        type={CTA_ENUMS.types.TYPE_Y}
        price={totalPrice}
        isLoading={isLoadingOrder}
      />
      <OrderConfirm
        orderItems={filteredOrderItems}
        menuCategories={allCategories}
        totalPrice={totalPrice}
        subtotal={subtotal}
        serviceFee={serviceFee}
        serviceFeeTotal={serviceFeeTotal}
        discountPrice={Math.abs(discountPrice)}
        guestId={guestId}
        title={t("modal.areYouSureYouWantToPlaceThisOrder")}
        checkboxText={t("modal.yesIConfirmMyOrderAndTakeResponsibilityToPay")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={(e) => handleOnCancel(e)}
        onConfirm={(e) => handleOnConfirm(e)}
      />
      <EmptyOrderInfoModal
        openSlide={openSlideEmptyOrderInfo}
        mainElementRef={mainElementRefEmptyOrderInfo}
        onClose={() => setOpenSlideEmptyOrderInfo(false)}
        handleGoBackToMenu={handleGoBackToMenu}
        isBasketEmpty={isBasketEmpty}
      />
      <BasketMessageModal
        openSlide={openSlideBasketMessageModal}
        mainElementRefModal={mainElementRefBasketMessageModal}
        handleCloseModal={handleCloseMessageModal}
        initialLocalMessage={initialLocalMessage}
        localMessage={localMessage}
        setLocalMessage={setLocalMessage}
      />
    </div>
  );
};

export default Basket;
