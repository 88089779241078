import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { updateOrderMessage } from "redux/slices/basketStore";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import BasketMessageInput from "components/admin/forms/basket-message-input/BasketMessageInput";

import "./BasketMessageModal.scss";

const BasketMessageModal = ({
  mainElementRefModal,
  openSlide,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrder = useSelector((state) => state[STORE_NAMES.basket].order);
  const guests = basketOrder ? basketOrder.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const initialLocalMessage = guest?.orderMessage || "";
  const [localMessage, setLocalMessage] = useState(initialLocalMessage);

  const handleOnClose = (e) => {
    e.stopPropagation();
    handleCloseModal();
    setLocalMessage(initialLocalMessage);
  };

  const handleSave = (e) => {
    dispatch(
      updateOrderMessage({
        userId: guestId,
        message: localMessage,
      })
    );
    e.stopPropagation();
    handleCloseModal();
  };

  const handleClear = () => {
    setLocalMessage("");
  };

  const BasketMessageModalHeader = (
    <div className="BasketMessageModalHeader">
      <CloseButton
        type={ENUMS_CLOSE_BUTTON.types.TYPE_S}
        onClick={(e) => handleOnClose(e)}
      />
    </div>
  );

  const BasketMessageModalBody = (
    <div className="BasketMessageModalBody">
      <div className="BasketMessageModalBodyTitle">
        <h2 className="SemiBold">{t("basket.comment.addMessage")}</h2>
        <h6>{t("basket.comment.addMessageDesc")}</h6>
      </div>
      <BasketMessageInput
        placeholder={t("inputs.example")}
        value={localMessage}
        onChange={(e) => setLocalMessage(e.target.value)}
        handleClear={handleClear}
        maxLength={400}
      />
    </div>
  );
  const BasketMessageModalFooter = (
    <div className="BasketMessageModalFooter">
      <CTAButton
        onClick={(e) => handleSave(e)}
        name={t("buttons.save")}
        type={CTA_BUTTON_ENUMS.types.TYPE_B}
        className="BasketMessageModalFooterButton"
      />
    </div>
  );

  return (
    <GuestModal
      header={BasketMessageModalHeader}
      body={BasketMessageModalBody}
      footer={BasketMessageModalFooter}
      mainElementRef={mainElementRefModal}
      openSlide={openSlide}
      className={"BackgroundClassName"}
      containerClassname={"ContainerClassname"}
      modalClassname={"BasketMessageModal"}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
BasketMessageModal.propTypes = {
  /**
   * Ref for the main element
   */
  mainElementRefModal: PropTypes.object.isRequired,

  /**
   * Boolean to control modal visibility
   */
  openSlide: PropTypes.bool.isRequired,

  /**
   * Function to handle modal close action
   */
  handleCloseModal: PropTypes.func.isRequired,
};
export default BasketMessageModal;
