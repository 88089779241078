import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cx from "classnames";

import AdminOrderGuestInfoModal from "pages/admin/admin-pages/admin-order/admin-order-tables/admin-order-guest-info-modal/AdminOrderGuestInfoModal";
import { TableV2 } from "components/table/TableV2";
import Pagination from "components/pagination/Pagination";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import Spinner from "components/elements/spinner/Spinner";
import NotificationModalContent, {
  ENUMS_TYPE as NOTIFICATION_MODAL_ENUMS_TYPE,
} from "components/pos/notification-modal-content/NotificationModalContent";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import IconButton, {
  ENUMS_TYPE as ICON_BUTTON_ENUMS,
} from "components/pos/icon-button/IconButton";
import Modal, { useModal } from "components/pos/modal/Modal";
import ConfirmModalContent, {
  ENUMS as CONFIRM_MODAL_ENUMS,
} from "components/pos/confirm-modal-content/ConfirmModalContent";
import TextArea from "components/admin/forms/text-area-control/TextArea";
import Checkbox from "components/pos/checkbox/Checkbox";
import Button, { ENUMS_TYPE } from "components/pos/button/Button";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAsync from "utils/hooks/useAsync";
import { getBusinessGuests, sendBulkSms } from "utils/api/services/guest";
import { STORE_NAMES } from "utils/constants/redux";
import {
  checkDateEqualCurrentDate,
  concatFirstNameWithLastName,
  formatDateToShortFormat,
} from "utils/helpers";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import useDebounce from "utils/hooks/useDebounce";
import { createDOBucketName } from "utils/DO-Spaces";
import EmptyTableIcon from "assets/icons/admin-dashboard/EmptyTable.svg";
import { ReactComponent as SortArrowAsc } from "assets/icons/admin-dashboard/sortArrowAsc.svg";
import { ReactComponent as SortArrowDesc } from "assets/icons/admin-dashboard/sortArrowDesc.svg";
import { ReactComponent as SortArrowDefault } from "assets/icons/admin-dashboard/sortArrowDefault.svg";
import { ReactComponent as ThreeDots } from "assets/icons/dot/ThreeDots.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import { ReactComponent as Cancel } from "assets/icons/pos/x-close-white.svg";
import { ReactComponent as IconClose } from "assets/icons/pos/x.svg";
import Phone from "assets/images/pos/phone.png";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";

import "./AdminBusinessGuests.scss";

const guestsMeta = {
  breakpoints: [
    {
      BreakPointsRange: {
        max: -1,
        min: 1800,
      },
      cellDisplayAndProportions: [3, 23.5, 23.5, 23.5, 23.5, 3],
    },
    {
      BreakPointsRange: {
        max: 1799,
        min: 1001,
      },
      cellDisplayAndProportions: [5, 22.25, 22.25, 22.25, 22.25, 6],
    },
    {
      BreakPointsRange: {
        max: 1000,
        min: 601,
      },
      cellDisplayAndProportions: [7, 86 / 3, null, 86 / 3, 86 / 3, 7],
    },
    {
      BreakPointsRange: {
        max: 600,
        min: 0,
      },
      cellDisplayAndProportions: [12, 38, null, null, 38, 12],
    },
  ],
};
const guestCount = [
  { id: 10, name: "10" },
  { id: 25, name: "25" },
  { id: 50, name: "50" },
  { id: 75, name: "75" },
  { id: 100, name: "100" },
];

const AdminBusinessGuests = () => {
  const { t } = useTranslation();
  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const registerFilterOptions = [
    {
      id: 0,
      name: t("dashboard.all"),
    },
    {
      id: 1,
      name: t("guests.registeredGuest"),
      isRegistered: true,
    },
    {
      id: 2,
      name: t("guests.unregisteredGuest"),
      isRegistered: false,
    },
  ];
  const [currentPage, setActivePage] = useState(1);
  const [guestId, setGuestId] = useState();
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(guestCount[1]);
  const [searchValue, setSearchValue] = useState("");
  const [searchDebounceValue, setSearchDebounceValue] = useState(null);
  const [registerData, setRegisterData] = useState(registerFilterOptions[0]);
  const [rows, setRows] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [selectedGuestIds, setSelectedGuestIds] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [isMessageRequired, setIsMessageRequired] = useState(false);
  const [notificationModalType, setNotificationModalType] = useState(null);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    title: null,
    description: null,
    onConfirm: null,
    onCancel: null,
  });

  const selectAllUser = () => {
    const newSelectAllState = !isSelectAll;
    setIsSelectAll(newSelectAllState);

    if (newSelectAllState) {
      const allGuestIds = fetchedBusinessGuests.data
        .filter((guest) => guest.phoneNumber)
        .map((guest) => guest.guestId);

      setSelectedGuestIds(allGuestIds);
    } else {
      setSelectedGuestIds([]);
    }
  };

  const { execute: executeSendSms } = useAsync(sendBulkSms, {
    onSuccess: () => {
      bulkSmsModalClose();
      confirmModalClose();
      notificationModalOpen();
      setNotificationModalType(NOTIFICATION_MODAL_ENUMS_TYPE.Success);
    },
    onError: () => {
      bulkSmsModalClose();
      confirmModalClose();
      notificationModalOpen();
      setNotificationModalType(NOTIFICATION_MODAL_ENUMS_TYPE.Error);
    },
  });

  const selectGuest = (guestId) => {
    const isExistGuestId = selectedGuestIds.includes(guestId);

    if (isExistGuestId) {
      setIsSelectAll(false);
    }

    setSelectedGuestIds((prev) => {
      if (isExistGuestId) {
        return prev.filter((id) => id !== guestId);
      } else {
        return [...prev, guestId];
      }
    });
  };

  const handleOnType = (e) => {
    setMessageText(e.target.value);
    setIsMessageRequired(false);
  };

  const GUESTS_TABLE_KEYS = [
    {
      element: (
        <Checkbox
          isChecked={isSelectAll}
          onChange={selectAllUser}
          isIndeterminate
        />
      ),
    },
    {
      value: "name",
      valueForTranslation: "dashboard.table.businessGuestsTable.name",
      isSortable: false,
    },
    {
      value: "gender",
      valueForTranslation: "dashboard.table.businessGuestsTable.gender",
      isSortable: false,
    },
    {
      value: "dateOfBirth",
      valueForTranslation: "dashboard.table.businessGuestsTable.dateOfBirth",
      isSortable: true,
    },
    {
      value: "visitCount",
      valueForTranslation: "dashboard.table.businessGuestsTable.numberOfVisit",
      isSortable: true,
    },
    {
      value: "threeDots",
      valueForTranslation: "",
      isSortable: false,
    },
  ];

  const [
    openSlideGuestInfoModal,
    setOpenSlideGuestInfoModal,
    mainElementRefGuestInfoModal,
  ] = useOutsideClick();

  const {
    isVisible: bulkSmsModalIsVisible,
    open: bulkSmsModalOpen,
    close: bulkSmsModalClose,
  } = useModal();

  const {
    isVisible: notificationModalIsVisible,
    open: notificationModalOpen,
    close: notificationModalClose,
  } = useModal();

  const {
    isVisible: confirmModalIsVisible,
    open: confirmModalOpen,
    close: confirmModalClose,
  } = useModal();

  const { loading, result: fetchedBusinessGuests } = useAsync(
    getBusinessGuests,
    {
      immediate: true,
      params: [
        {
          pageNumber: currentPage,
          businessId,
          pageSize: pageSize.id,
          sortBy: sortBy || "createDate",
          sortDir: sortType || "DESC",
          hasSignedUp: registerData?.isRegistered,
          searchValue,
        },
      ],
    }
  );

  const handleGuestsSort = (header) => {
    if (rows.length <= 1) return;
    if (!header.isSortable) return;
    setActivePage(1);
    if (header.value === sortBy) {
      if (sortType === "DESC") {
        setSortBy("");
        setSortType("");
        return;
      }
      setSortType(sortType === "ASC" ? "DESC" : "ASC");
      return;
    }

    setSortBy(header.value);
    setSortType("ASC");
  };

  const headers = GUESTS_TABLE_KEYS.map((header, index) => {
    return {
      element: (
        <div
          key={index}
          className="headerCell"
          onClick={() => {
            handleGuestsSort(header);
          }}
        >
          {header.element}
          <p>{t(header.valueForTranslation)}</p>
          {header.isSortable && (
            <>
              {sortBy !== header.value ? (
                <SortArrowDefault />
              ) : sortType === "ASC" ? (
                <SortArrowAsc />
              ) : sortType === "DESC" ? (
                <SortArrowDesc />
              ) : null}
            </>
          )}
        </div>
      ),
    };
  });

  useEffect(() => {
    if (fetchedBusinessGuests && fetchedBusinessGuests.data) {
      setRows(createRows(fetchedBusinessGuests));
      setPageCount(fetchedBusinessGuests.totalPages);
    } else {
      setRows([]);
    }
  }, [fetchedBusinessGuests, isSelectAll, selectedGuestIds, t]);

  useEffect(() => {
    const registerDataMapping = {
      [registerFilterOptions[0]?.id]: {
        ...registerFilterOptions[0],
        name: t("dashboard.all"),
      },
      [registerFilterOptions[1]?.id]: {
        ...registerFilterOptions[1],
        name: t("guests.registeredGuest"),
      },
      [registerFilterOptions[2]?.id]: {
        ...registerFilterOptions[2],
        name: t("guests.unregisteredGuest"),
      },
    };

    setRegisterData(registerDataMapping[registerData?.id]);
  }, [registerData?.id, t]);

  const debouncedRequest = useDebounce(() => {
    setSearchValue(searchDebounceValue);
    setActivePage(1);
  }, 1000);

  const createRows = (guestsData) => {
    return guestsData.data.map((row) => {
      const isBirthday = checkDateEqualCurrentDate(row.dateOfBirth);
      const isChecked = selectedGuestIds.includes(row.guestId) || isSelectAll;
      const hasPhoneNumber = row.phoneNumber;
      return [
        {
          element: hasPhoneNumber && (
            <Checkbox
              isChecked={isChecked}
              onChange={() => selectGuest(row.guestId)}
            />
          ),
        },
        {
          element: (
            <div className="AdminBusinessGuestProfileContainer">
              <div>
                {isBirthday && <BirthdayHat className="BirthdayHat" />}
                <ImageWithPlaceholder
                  className="AdminBusinessGuestProfileImage"
                  imageSource={createDOBucketName(row.profilePic)}
                  placeholder={IMG_GUEST}
                  alt="logo"
                />
              </div>
              <p className="h7 SemiBold">
                {row.firstName
                  ? concatFirstNameWithLastName({
                      firstName: row.firstName,
                      lastName: row.lastName,
                    })
                  : `${t("dashboard.guest.guest")} ${row.guestId}`}
              </p>
            </div>
          ),
        },
        {
          element: (
            <p className="h7 SemiBold">{t(`auth.genders.${row.gender}`)}</p>
          ),
        },
        {
          element: (
            <p className="h7 SemiBold">
              {row.dateOfBirth ? formatDateToShortFormat(row.dateOfBirth) : "-"}
            </p>
          ),
        },
        {
          element: <p className="h7 SemiBold">{row.numberOfVisit}</p>,
        },
        {
          element: (
            <div
              className="ThreeDotsContainer"
              onClick={() => handleOpenModal(row)}
            >
              <ThreeDots />
            </div>
          ),
        },
      ];
    });
  };

  const EmptyTable = () => {
    return (
      <div className={`AdminBusinessGuestTableEmpty`}>
        <img src={EmptyTableIcon} alt="Empty Table" />
        <h4 className="Medium AdminBusinessGuestTableEmptyInfo">
          {t("emptyStates.noTableData")}
        </h4>
      </div>
    );
  };

  const handleGuestCountChange = (guestCount) => {
    setActivePage(1);
    guestCount && setPageSize(guestCount);
  };

  const handleChangeRegisteredFilter = (filter) => {
    filter && setRegisterData(filter);
    setActivePage(1);
    setSearchDebounceValue(null);
    setSearchValue("");
  };

  const handleSearchChange = (value) => {
    setSearchDebounceValue(value.trimStart());
    setActivePage(1);
    debouncedRequest();
  };

  const handleOpenModal = (row) => {
    setGuestId(row.guestId);
    setOpenSlideGuestInfoModal(true);
  };

  const handleCloseModal = () => {
    setOpenSlideGuestInfoModal(false);
  };

  const handleCloseBulkMessageModal = () => {
    setConfirmModalOptions({
      title: t("bulkSms.closeModal"),
      description: t("bulkSms.yourMessageWillBeDeleted"),
      onCancel: confirmModalClose,
      onConfirm: handleCloseBulkMessagesModal,
    });
    if (messageText.trim().length > 0) {
      confirmModalOpen();
    } else {
      bulkSmsModalClose();
      setMessageText("");
    }
  };

  const handleOpenBulkMessageModal = () => {
    bulkSmsModalOpen();
  };

  const handleSendBulkMessage = () => {
    setConfirmModalOptions({
      title: t("bulkSms.sendSmsModal"),
      description: isSelectAll
        ? t("bulkSms.sentToEveryone")
        : t("bulkSms.willBeSent").replace(
            "{{numberOfPeople}}",
            `${selectedGuestIds.length}`
          ),
      onCancel: confirmModalClose,
      onConfirm: handleConfirmSms,
    });

    if (messageText.trim().length > 0) {
      setIsMessageRequired(false);
      confirmModalOpen();
    } else {
      setIsMessageRequired(true);
    }
  };
  const handleCloseBulkMessagesModal = () => {
    bulkSmsModalClose();
    confirmModalClose();
    setMessageText("");
  };
  const handleCancelMessage = () => {
    setMessageText("");
    setSelectedGuestIds([]);
    setIsSelectAll(false);
  };
  const handleConfirmSms = () => {
    const body = {
      businessId,
      data: {
        selectedGuestIds,
        isSelectAll,
        messageText,
      },
    };
    executeSendSms(body);
  };

  const handleReturnPlaceholder = () => {
    const placeholders = {
      1: `${t("inputs.search")} (${t("common.name")})`,
      2: `${t("inputs.search")} (id)`,
    };

    return placeholders[registerData.id] || `${t("inputs.search")}`;
  };

  const notificationInfo = useMemo(() => {
    if (notificationModalType === NOTIFICATION_MODAL_ENUMS_TYPE.Success) {
      return {
        type: notificationModalType,
        title: t("bulkSms.successfullySent"),
        description: isSelectAll
          ? t("bulkSms.sentToEveryoneSuccess")
          : t("bulkSms.successfullySentToPeople", {
              numberOfPeople: selectedGuestIds.length,
            }),
      };
    } else {
      return {
        type: notificationModalType,
        title: t("bulkSms.operationFailed"),
        description: isSelectAll
          ? t("bulkSms.sentToEveryoneFail")
          : t("bulkSms.operationFailedPeople", {
              numberOfPeople: selectedGuestIds.length,
            }),
      };
    }
  }, [notificationModalType, selectedGuestIds]);

  const BulkSmsModal = (
    <div className="AdminBusinessGuestsModal">
      <div className="AdminBusinessGuestsModalHeader">
        <h1 className="Bold">{t("bulkSms.sendSms")}</h1>
        <IconButton
          onClick={handleCloseBulkMessageModal}
          icon={<IconClose />}
          className="AddItemModalHeaderCloseButton"
        />
      </div>
      <div className="AdminBusinessGuestsModalBody">
        <div className="AdminBusinessGuestsMessageComponent">
          <h4 className="Medium">{t("bulkSms.composeYourMessage")}</h4>
          <TextArea
            onChange={handleOnType}
            placeholder={t("bulkSms.typeHere")}
            value={messageText}
            maxLength={100}
            hasError={isMessageRequired}
            errorMessage={t("bulkSms.messageIsRequired")}
          />
        </div>
        <div className="AdminBusinessGuestsPhone">
          <img src={Phone} alt="Phone" className="AdminBusinessGuestPhone" />
          <div className="AdminBusinessGuestsPhoneMessage">
            {messageText.trim().length > 0 && (
              <h6 className="h8 Medium AdminBusinessGuestsPhoneMessageBlock">
                {messageText.trim()}
              </h6>
            )}
          </div>
        </div>
      </div>

      <div className="AdminBusinessGuestsModalFooter">
        <Button
          onClick={handleSendBulkMessage}
          text={t("bulkSms.sendSms")}
          className="AdminBusinessGuestsModalFooterButton"
          type={ENUMS_TYPE.Primary}
        />
      </div>
    </div>
  );

  return (
    <div className="AdminBusinessGuests">
      <div className="AdminBusinessGuestsHeader">
        <div className="AdminBusinessGuestsTitle">
          <h4 className="SemiBold AdminBusinessGuestsTitleText">
            {t("navbarRoutes.pageTitles.guests")}
          </h4>
          <h6 className="Medium AdminBusinessGuestsCount">
            {t("dashboard.guest.guest")} (
            {fetchedBusinessGuests && fetchedBusinessGuests.totalElems})
          </h6>
        </div>
        <div className="AdminBusinessGuestsFilters">
          <Search
            onChange={handleSearchChange}
            value={searchDebounceValue}
            placeholder={handleReturnPlaceholder()}
            type={SEARCH_ENUMS.types.TYPE_B}
            classname={cx("AdminFiltersSearch", {
              isDisabled: registerData?.id === registerFilterOptions[0]?.id,
            })}
          />
          <Dropdown
            onChange={handleChangeRegisteredFilter}
            value={registerData}
            options={registerFilterOptions}
            placeholder={t("orders.guests")}
            className="RegisteredGuestsFilter"
          />
          <Dropdown
            onChange={handleGuestCountChange}
            value={pageSize}
            options={guestCount}
            placeholder={t("dashboard.table.businessGuestsTable.pageSize")}
            className="PageSizeDropdown"
          />
        </div>
      </div>
      <TableV2
        meta={guestsMeta}
        headers={headers}
        loading={loading}
        rows={rows}
        LoadingComponent={<Spinner />}
        EmptyComponent={<EmptyTable />}
        Footer={
          pageCount > 1 && (
            <Pagination
              pageCount={pageCount}
              pageRange={1}
              setActivePage={setActivePage}
              currentPage={currentPage}
            />
          )
        }
      />
      {guestId && (
        <AdminOrderGuestInfoModal
          guestId={guestId}
          openSlide={openSlideGuestInfoModal}
          mainElementRef={mainElementRefGuestInfoModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {(selectedGuestIds.length > 0 || isSelectAll) && (
        <div className="AdminBusinessSelectedGuest">
          <div className="SelectedGuestsContainer">
            <div className="AdminBusinessSelectedGuestCountContainer">
              <IconButton
                onClick={handleCancelMessage}
                icon={<Cancel />}
                className="AdminBusinessGuestsCancelButton"
                type={ICON_BUTTON_ENUMS.Text}
              />
              <h6 className="Body2-Pos Medium">
                {isSelectAll
                  ? t("bulkSms.everyoneSelected")
                  : `${selectedGuestIds.length} ${t("bulkSms.guestSelected")}`}
              </h6>
            </div>
            {/*<Button*/}
            {/*  onClick={() => ""}*/}
            {/*  text={t("bulkSms.giveDiscount")}*/}
            {/*  className="SelectedGuestsDiscountButton"*/}
            {/*/>*/}
            <Button
              onClick={handleOpenBulkMessageModal}
              text={t("bulkSms.sendSms")}
              className="SelectedGuestsSendButton"
              type={ENUMS_TYPE.Primary}
            />
          </div>
        </div>
      )}
      <Modal
        isVisible={bulkSmsModalIsVisible}
        close={bulkSmsModalClose}
        outsideClickCallback={handleCloseBulkMessageModal}
      >
        {BulkSmsModal}
      </Modal>

      <Modal isVisible={confirmModalIsVisible} close={confirmModalClose}>
        <ConfirmModalContent
          title={confirmModalOptions.title}
          description={confirmModalOptions.description}
          type={CONFIRM_MODAL_ENUMS.Info}
          onCancel={confirmModalOptions.onCancel}
          onConfirm={confirmModalOptions.onConfirm}
        />
      </Modal>

      <Modal
        isVisible={notificationModalIsVisible}
        close={notificationModalClose}
      >
        <NotificationModalContent
          title={notificationInfo.title}
          description={notificationInfo.description}
          type={notificationInfo.type}
          onClose={notificationModalClose}
        />
      </Modal>
    </div>
  );
};
export default AdminBusinessGuests;
