import PropTypes from "prop-types";
import useAdminMetaDataUpdater from "utils/hooks/useAdminMetaDataUpdater";
import useInventoryUpdater from "utils/hooks/useInventoryUpdater";
import useTopicUpdater from "utils/hooks/useTopicUpdater";
import useSetUserLanguage from "utils/hooks/useSetUserLanguage";
import useOrderNotificationSound from "utils/hooks/useOrderNotificationSound";

const AdminController = ({ children }) => {
  useAdminMetaDataUpdater();
  useInventoryUpdater();
  useTopicUpdater();
  useSetUserLanguage();
  useOrderNotificationSound();

  return children;
};

AdminController.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminController;
