import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GUEST_FORGET_PASSWORD_STEP_TYPES } from "pages/client/auth/ClientAuthHelper";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import { ROUTE_NAME } from "utils/constants/routes";
import ForgotPasswordMainForm from "pages/client/auth/forget-password/forget-password-main-form/ForgotPasswordMainForm";
import ForgotPasswordVerifyOtp from "pages/client/auth/forget-password/forget-password-verify-otp/ForgotPasswordVerifyOtp";
import ForgotPasswordNewPassword from "pages/client/auth/forget-password/forget-password-new-password/ForgotPasswordNewPassword";
import { SIGN_IN_METHOD as SIGN_UP_METHOD } from "pages/common/login/sign-in/SignIn";
import useCountdown from "utils/hooks/useCountdown";

import "./ForgotPasswordStepper.scss";

const ForgetPasswordStepperProgressBar = ({ activeStep, steps }) => {
  const numberOfSteps = Object.keys(steps).length;
  const activeStepIndex = Object.keys(steps).indexOf(activeStep.key);
  return (
    <>
      <div className="StepperProgressBar">
        {Object.keys(steps).map((stepKey, index) => {
          const stepWidth = `calc((100% - ${
            (numberOfSteps - 1) * 16
          }px) / ${numberOfSteps})`;
          return (
            <div
              key={stepKey}
              className={cx("StepperProgressBarLine", {
                isPassed: index <= activeStepIndex,
              })}
              style={{ width: stepWidth }}
            ></div>
          );
        })}
      </div>
    </>
  );
};

ForgetPasswordStepperProgressBar.propTypes = {
  activeStep: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  steps: PropTypes.object.isRequired,
};

const ForgotPasswordStepper = () => {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(
    GUEST_FORGET_PASSWORD_STEP_TYPES.mainForm
  );

  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    password: "",
    otpCode: "",
    otpToken: "",
    otpSendCount: 3,
  });

  const [steps] = useState(GUEST_FORGET_PASSWORD_STEP_TYPES);
  const [selectedMethod, setSelectedMethod] = useState(
    SIGN_UP_METHOD.phoneNumber
  );
  const activeStepIndex = Object.keys(steps).indexOf(activeStep.key);
  const dynamicWidth = `${Object.keys(steps).length * 100}%`;

  const backStepKey = Object.keys(steps)[activeStepIndex - 1];

  const handleBackStep = () => {
    if (activeStepIndex === 0) {
      return businessId
        ? navigate(
            `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}`
          )
        : navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}`);
    }
    setActiveStep(steps[backStepKey]);
  };
  const [counter, setCounter] = useCountdown(0);

  const GuestAuthForgetPasswordHeader = (
    <div className="GuestAuthForgetPasswordHeader">
      {activeStep.type !== steps.newPassword.type && (
        <BackButton
          onClick={handleBackStep}
          type={BACK_BUTTON_ENUMS.types.TYPE_D}
        />
      )}
      <h2 className="SemiBold">{t("auth.forgotPasswordTitle")}</h2>
    </div>
  );

  return (
    <div className="ForgetPasswordStepper">
      {GuestAuthForgetPasswordHeader}
      <ForgetPasswordStepperProgressBar activeStep={activeStep} steps={steps} />
      <div
        className="ForgetPasswordStepperWrapper"
        style={{
          width: dynamicWidth,
          [document.documentElement.dir === "rtl"
            ? "marginRight"
            : "marginLeft"]: `-${activeStepIndex * 100}%`,
        }}
      >
        <ForgotPasswordMainForm
          formData={formData}
          setFormData={setFormData}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          setCounter={setCounter}
          setActiveStep={setActiveStep}
        />
        <ForgotPasswordVerifyOtp
          formData={formData}
          setFormData={setFormData}
          selectedMethod={selectedMethod}
          counter={counter}
          setCounter={setCounter}
          setActiveStep={setActiveStep}
        />
        <ForgotPasswordNewPassword
          formData={formData}
          setFormData={setFormData}
          selectedMethod={selectedMethod}
        />
      </div>
    </div>
  );
};

export default ForgotPasswordStepper;
