import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { omit, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";

import Modal from "components/modal/Modal";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import { STORE_NAMES } from "utils/constants/redux";
import Switch from "components/forms/switch/Switch";
import useOutsideClick from "utils/hooks/useOutsideClick";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import RadioSelection, {
  ENUMS as RADIO_ENUMS,
} from "components/admin/forms/radio-selection/RadioSelection";
import IconButton from "components/buttons/icon-button/IconButton";
import Language from "components/admin/elements/language/Language";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import { getActiveLanguageValue, getInitialSchedule } from "utils/helpers";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import {
  INTENT,
  usePermission,
} from "utils/context-api/AdminPermissionContext";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import DateAccordion from "components/admin/elements/date-accordion/DateAccordion";

import "./AddCategoryModal.scss";

const AddCategoryModal = ({
  item,
  mainElementRef,
  openSlide,
  setOpenSlide,
  title,
  hasPublishMode = false,
  isMultiLanguage = false,
  setOutsideClickAction,
  isLoading,
  hasSchedule,
  onSave,
  onDelete,
  allCategories,
}) => {
  const { t } = useTranslation();
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const { menuCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const menuLanguage = menu?.language;
  const { isPermitted } = usePermission();
  const isDeleteMenuItemsEnabledForRole = !isPermitted(INTENT.deleteMenuItems);

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const [activeLanguageCode, setActiveLanguageCode] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const initialSchedule = item?.schedule?.from
    ? item?.schedule
    : getInitialSchedule(new Date());

  const initialData = {
    name: isMultiLanguage
      ? [{ value: "", languageCode: menuLanguage.code }]
      : "",
    isPublished: true,
    schedule: initialSchedule,
    type: item?.type ? item.type : menuCategoryType.standard_category,
  };
  const [formData, setFormData] = useState(initialData);
  const [formDataInitial, setFormDataInitial] = useState(initialData);
  const isEditable = !!item;
  const hasPromotionCategory = allCategories.some(
    (category) => category.type === menuCategoryType.promotion
  );
  const showPromotionCategory =
    (hasPromotionCategory &&
      isEditable &&
      item.type === menuCategoryType.promotion) ||
    (!hasPromotionCategory && !isEditable);

  const formDataWithoutType = omit(formData, ["type"]);
  const formDataInitialWithoutType = omit(formDataInitial, ["type"]);

  useFormOutsideClickHandler({
    formData: formDataWithoutType,
    formDataInitial: formDataInitialWithoutType,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const schemas = useValidationSchema(t);
  const { addItemModalSchema, requiredStringSchema } = schemas;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    criteriaMode: "all",
    resolver: zodResolver(addItemModalSchema),
  });

  useEffect(() => {
    reset({
      formData,
    });
  }, [reset, formData]);

  const handleOnInputChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      name: isMultiLanguage
        ? formData.name.map((property) =>
            property.languageCode === activeLanguageCode
              ? { ...property, value }
              : property
          )
        : value,
    });
  };

  const handleOnModalClose = () => {
    if (!isEqual(formDataWithoutType, formDataInitialWithoutType)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
  };

  const handleOnPublishModeChange = () => {
    setFormData({
      ...formData,
      isPublished: !formData.isPublished,
    });
  };

  const handleNameInputWithMultipleLanguage = () => {
    const nameError = formData.name
      .map((name) => {
        try {
          requiredStringSchema.parse(name.value);
          return null;
        } catch (error) {
          setError("name", {
            type: "manual",
            message: t("errorMessages.input.generalRequired"),
          });

          setActiveLanguageCode(name.languageCode);
          return name.languageCode;
        }
      })
      .filter((name) => name !== null);

    return nameError;
  };

  const handleOnSubmit = () => {
    if (isMultiLanguage) {
      const nameError = handleNameInputWithMultipleLanguage();

      if (nameError.length > 0) {
        return;
      }
    }

    const hasNoSchedule = isEqual(formData.schedule, initialSchedule);
    const updatedFormData = { ...formData };
    if (hasNoSchedule) {
      delete updatedFormData.schedule;
    }
    if (isEditable) {
      onSave(
        {
          ...updatedFormData,
          ...(updatedFormData?.schedule && {
            schedule: {
              ...updatedFormData.schedule,
              weekdays: JSON.stringify(updatedFormData.schedule.weekdays),
            },
          }),
        },
        item.id
      );
    } else {
      onSave({
        ...updatedFormData,
        ...(updatedFormData?.schedule && {
          schedule: {
            ...updatedFormData.schedule,
            weekdays: JSON.stringify(updatedFormData.schedule.weekdays),
          },
        }),
      });
    }
  };

  const setFormDataName = (properties) => {
    const newProperties = properties.map((property) => {
      property.filter((item) => !item.value);
      return property.filter((item) => item.value !== null);
    });
    setFormData({ ...formData, name: newProperties[0] });
  };

  const handleOnCancelCloseModal = () => setOpenSlideConfirmCloseModal(false);

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const handleKeyDown = ({ target, key }) => {
    if (key === "Enter") {
      switch (target.name) {
        case "name":
          handleSubmit(handleOnSubmit)();
          break;
        default:
          break;
      }
    }
  };

  const handleChangeCategoryType = (type) => {
    if (!isEditable) {
      setFormData({
        ...formData,
        type,
      });
    }
  };

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        isPublished: item.isPublished,
        schedule: item.schedule?.from ? item.schedule : initialSchedule,
        type: item.type,
      });
      setFormDataInitial({
        name: item.name,
        isPublished: item.isPublished,
        schedule: item.schedule?.from ? item.schedule : initialSchedule,
        type: item.type,
      });
      setActiveLanguageCode(isMultiLanguage ? item.name[0].languageCode : null);
      setIsAccordionOpen(item.schedule?.isActive || false);
    } else {
      setFormData(initialData);
      setFormDataInitial(initialData);
      setActiveLanguageCode(menuLanguage.code);
      setIsAccordionOpen(initialSchedule.isActive);
    }

    reset();
  }, [item, openSlide]);

  const ModalBodyContent = (
    <InputControl
      type="text"
      required
      name="name"
      placeholder={t("common.name")}
      labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
      value={
        isMultiLanguage
          ? getActiveLanguageValue(formData.name, activeLanguageCode)
          : formData.name
      }
      className="AddCategoryModalBodyFormInput"
      hasError={errors.name}
      error={
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <p className="h7 error-message">{message}</p>
          )}
        />
      }
      onKeyDown={handleKeyDown}
      autoFocus={true}
      func={{
        ...register("name", {
          onChange: handleOnInputChange,
        }),
      }}
    />
  );

  const categoryTypes = [
    {
      type: menuCategoryType.standard_category,
      label: "menu.category.standardCategory",
    },
    {
      type: menuCategoryType.promotion,
      label: "menu.category.promotion",
      show: showPromotionCategory,
    },
    {
      type: menuCategoryType.special_category,
      label: "menu.category.specialCategory",
    },
    { type: menuCategoryType.banner, label: "menu.category.banner" },
  ];

  const AddCategoryModalHeader = (
    <div className="AddCategoryModalHeader">
      <h3 className="SemiBold AddCategoryModalHeaderTitle">
        {isEditable
          ? t("buttons.editForModal", { title: title })
          : t("buttons.addForModal", { title: title })}
      </h3>
      {hasPublishMode && (
        <div className="AddCategoryModalHeaderTitlePublishMode">
          <h6 className="h7">
            {formData.isPublished
              ? t("common.published")
              : t("common.unPublished")}
          </h6>
          <Switch
            isChecked={formData.isPublished}
            onChange={handleOnPublishModeChange}
          />
        </div>
      )}
      {isEditable && (
        <DeleteButton
          setOpenSlide={setOpenSlide}
          onClick={() => onDelete(item.id)}
          className={cx("AddCategoryModalDeleteButton", {
            isDisabled: isDeleteMenuItemsEnabledForRole,
          })}
          showAccessDeniedModal={isDeleteMenuItemsEnabledForRole}
        />
      )}
      <IconButton onClick={handleOnModalClose} svgComponent={<IconClose />} />
    </div>
  );

  const AddCategoryModalBody = (
    <div className="AddCategoryModalBody">
      <h5 className="Medium">{t("menu.category.selectionType")}</h5>
      <div className="AddCategoryModalBodyLine"></div>
      <div className="AddCategoryModalBodySelectionTypes">
        {categoryTypes.map(
          ({ type, label, show = true }) =>
            show && (
              <div
                key={type}
                onClick={() => handleChangeCategoryType(type)}
                className={cx("AddCategoryModalBodySelectionType", {
                  hasBorder: formData.type === type,
                  notAllowed: formData.type !== type && isEditable,
                })}
              >
                <div className="SelectionTypeWrapper">
                  <RadioSelection
                    onChange={() => {}}
                    name={type}
                    value={formData.type}
                    type={RADIO_ENUMS.types.TYPE_YELLOW}
                    isChecked={formData.type === type}
                  />
                  <p className="h7 Medium AddCategoryModalBodySelectionTypeTitle">
                    {t(label)}
                  </p>
                </div>
              </div>
            )
        )}
      </div>
      <form
        className={cx("AddCategoryModalBodyForm", {
          maxWidth: showPromotionCategory,
        })}
        onSubmit={(e) => e.preventDefault()}
      >
        {isMultiLanguage ? (
          <Language
            content={ModalBodyContent}
            activeLanguageCode={activeLanguageCode}
            setActiveLanguageCode={setActiveLanguageCode}
            properties={[formData.name]}
            setProperties={setFormDataName}
          ></Language>
        ) : (
          ModalBodyContent
        )}
        {hasSchedule && (
          <div className="AddCategoryModalBodyFormSchedule">
            <DateAccordion
              isAccordionOpen={isAccordionOpen}
              setIsAccordionOpen={setIsAccordionOpen}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        )}
      </form>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const AddCategoryModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={AddCategoryModalHeader}
      body={AddCategoryModalBody}
      footer={AddCategoryModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    ></Modal>
  );
};

AddCategoryModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    isPublished: PropTypes.bool,
    schedule: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
  }),
  mainElementRef: PropTypes.object,
  openSlide: PropTypes.bool,
  setOpenSlide: PropTypes.func,
  title: PropTypes.string.isRequired,
  hasPublishMode: PropTypes.bool,
  isMultiLanguage: PropTypes.bool,
  setOutsideClickAction: PropTypes.func,
  isLoading: PropTypes.bool,
  hasSchedule: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  allCategories: PropTypes.array,
};

export default AddCategoryModal;
