import { useTranslation } from "react-i18next";

const useTags = (menuItem) => {
  const { t } = useTranslation();
  const calories = menuItem?.calories
    ? {
        name: `${menuItem.calories} ${t("common.kcal")}`,
        type: "calorie",
      }
    : null;
  const time = menuItem?.timeToMake
    ? {
        name: `${menuItem.timeToMake} ${t("common.time.min")}`,
        type: "time",
      }
    : null;

  const amount = menuItem?.amount
    ? {
        name: `${menuItem.amount} ${menuItem.unit.name}`,
        type: "amount",
      }
    : null;

  const allTags = [time, calories, amount].filter((tag) => tag !== null);

  return { allTags };
};

export default useTags;
