import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import AdminOrderHeader from "pages/admin/admin-pages/admin-order/admin-order-tables/admin-order-header/AdminOrderHeader";
import { ADMIN_ORDER_VIEW_MODE_WITH_GUESTS } from "pages/admin/admin-pages/admin-order/admin-order-tables/AdminOrderTables";
import AdminMapView from "pages/admin/admin-pages/admin-map/admin-map-view/AdminMapView";
import If from "components/if/If";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import AdminOrderPage from "pages/admin/admin-pages/admin-order/admin-order-page/AdminOrderPage";
import AdminDetailedChat from "pages/admin/admin-pages/admin-order/admin-detailed-chat/AdminDetailedChat";
import AdminOrderGuestInfoModal from "pages/admin/admin-pages/admin-order/admin-order-tables/admin-order-guest-info-modal/AdminOrderGuestInfoModal";
import EmptyState from "components/admin/empty-state/EmptyState";
import { STORE_NAMES } from "utils/constants/redux";
import { ORDER_ACTION_STATE } from "utils/constants/data/menu-model";
import { QUERY_PARAMS } from "utils/constants/routes";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { createOrderAsync } from "redux/actions/orderActions";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { sortActiveGuest } from "utils/helper-functions/dashboard/activeGuestHelper";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import {
  checkDateEqualCurrentDate,
  concatFirstNameWithLastName,
  handleOnAsyncError,
} from "utils/helpers";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import GuestIcon from "assets/icons/profile/Users.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import { ReactComponent as HappyBirthday } from "assets/icons/birthday/happybirthday.svg";

import "./AdminOrderMap.scss";

const AdminOrderMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const { user, userWithPin } = useSelector((state) => state[STORE_NAMES.user]);
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  const businessId = business?.id;
  const [selectedAction, setSelectedAction] = useState([]);
  const [isNewOrderCreated, setIsNewOrderCreated] = useState(false);
  const [openSlideOrder, setOpenSlideOrder] = useOutsideClick();
  const [openSlideChat, setOpenSlideChat] = useOutsideClick();
  const [guestId, setGuestId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTable, setSelectedTable] = useState(null);
  const [activeZone, setActiveZone] = useState(zones[0]);
  const allGuests = orders.flatMap((order) => order.guests);

  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();
  const [
    openSlideGuestInfoModal,
    setOpenSlideGuestInfoModal,
    mainElementRefGuestInfoModal,
  ] = useOutsideClick();

  const handleOnFilterOrdersByAction = (action) => {
    const actionIsSelected = selectedAction.find((z) => z.id === action.id);
    if (actionIsSelected) {
      const updatedActions = selectedAction.filter((z) => z.id !== action.id);
      setSelectedAction(updatedActions);
    } else {
      setSelectedAction((prev) => [...prev, action]);
    }
  };

  const activeZoneTables = zones?.find((zone) => {
    return zone.id === activeZone?.id;
  });

  const activeTables = useMemo(() => {
    return activeZoneTables?.tables
      .filter((table) =>
        Object.values(table.coordinates).every((value) => value !== null)
      )
      .filter((table) => {
        if (selectedAction.length > 0) {
          return orders.some((order) => {
            return selectedAction.some(
              (action) => order.table.id === table.id && order[action.id]
            );
          });
        }
        return true;
      })
      .map((el) => {
        const selectedOrder = orders.find((o) => o.table.id === el.id);
        const currentTopic = chat?.find(
          (topic) => topic.id === selectedOrder?.id
        );
        const lastMessage =
          currentTopic?.messages[currentTopic.messages.length - 1];
        const lastReadTime = currentTopic?.usersLastReadTime.find(
          (item) => item.userId === user.id
        )?.dateTime;

        let hasNewMessage = false;
        if (lastMessage?.author?.id !== user.id) {
          hasNewMessage = lastMessage?.dateTime
            ? !lastReadTime || lastReadTime < lastMessage.dateTime
            : false;
        }

        return {
          ...el,
          askForBill: selectedOrder?.actionBill,
          callWaiter: selectedOrder?.actionWaiter,
          hasNewMessage,
          hasNewOrder: selectedOrder?.hasNewOrder,
          createDate: selectedOrder?.createDate,
        };
      });
  }, [activeZoneTables, selectedAction, orders, chat, user.id]);

  const messageCountOfOrdersByActiveZone = activeTables?.filter(
    (table) => table.hasNewMessage
  ).length;

  const showOrderWithGuests = () => {
    switch (orderViewWithGuests) {
      case ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard:
        setOrderViewWithGuests(ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests);
        break;
      case ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests:
        setOrderViewWithGuests(ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard);
    }
  };
  const [orderViewWithGuests, setOrderViewWithGuests] = useState(
    ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard
  );
  const orderFilterOptions = useMemo(() => {
    return [
      {
        id: ORDER_ACTION_STATE.actionBill,
        name: t("waiter.bill"),
        isEnabled: business?.isAskForBillEnabled,
      },
      {
        id: ORDER_ACTION_STATE.actionWaiter,
        name: t("waiter.call"),
        isEnabled: business?.isCallWaiterEnabled,
      },
    ].filter((orderOption) => orderOption.isEnabled);
  }, [business?.isAskForBillEnabled, business?.isCallWaiterEnabled, t]);
  const handleZoneChange = (zone) => {
    zone && setActiveZone(zone);
  };
  const handleOnClickTable = async (table) => {
    const order = orders.find((order) => order.table.id === table.id);
    if (order) {
      searchParams.set(QUERY_PARAMS.selectedOrder, order.id);
      setSearchParams(searchParams);
    } else {
      setSelectedTable(table);
      setOpenSlideConfirm(true);
    }
  };
  const createNewOrder = async () => {
    const newOrder = {
      tableId: selectedTable.id,
      assigneeId: selectedTable.assignee?.id || userWithPin?.id || user.id,
    };
    const response = await dispatch(
      createOrderAsync({
        businessId,
        order: newOrder,
      })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      setOpenSlideOrder(true);
      setOpenSlideConfirm(false);
      searchParams.set(QUERY_PARAMS.selectedOrder, response.payload.id);
      setSearchParams(searchParams);
      setIsNewOrderCreated(true);
    }
  };
  useEffect(() => {
    setOpenSlideOrder(searchParams.has(QUERY_PARAMS.selectedOrder));
    setOpenSlideChat(searchParams.has(QUERY_PARAMS.showDetailedChat));
  }, [searchParams]);

  const handleOpenModal = (id) => {
    setOpenSlideGuestInfoModal(true);
    setGuestId(id);
  };
  const allGuestsWithDetails = allGuests.map((guest) => {
    const order = orders.find((order) =>
      order.guests.some((g) => g.id === guest.id)
    );
    if (order) {
      const table = order.table;
      const zone = zones.find((zone) =>
        zone.tables.some((t) => t.id === table.id)
      );
      return {
        ...guest,
        tableName: table.name,
        zoneName: zone ? zone.name : "",
      };
    }
    return guest;
  });
  const handleCloseModal = () => {
    setOpenSlideGuestInfoModal(false);
  };
  return (
    <div className="AdminOrderMapContainer">
      <AdminOrderHeader
        business={business}
        fullTablesOfActiveZone={activeTables}
        showOrderWithGuests={showOrderWithGuests}
        messageCountOfOrdersByActiveZone={messageCountOfOrdersByActiveZone}
        handleOnFilterOrdersByAction={handleOnFilterOrdersByAction}
        handleZoneChange={handleZoneChange}
        orderFilterOptions={orderFilterOptions}
        zones={zones}
        activeZone={activeZone}
        selectedAction={selectedAction}
        orderViewWithGuests={orderViewWithGuests}
      />
      <div className="AdminOrderMapSections">
        <AdminMapView
          onClickTable={handleOnClickTable}
          activeTablesId={activeTables?.map((table) => table.id)}
          selectedZone={activeZoneTables}
        />
        <If
          state={
            orderViewWithGuests === ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests
          }
        >
          <div className="AdminOrderTablesSectionGuests">
            <h4 className="SemiBold">{`${t("orders.guests")} (${
              allGuestsWithDetails.length
            })`}</h4>
            {allGuestsWithDetails.length > 0 ? (
              <div className="AdminOrderTablesSectionGuestsContainer">
                {sortActiveGuest(allGuestsWithDetails)?.map((guest, index) => {
                  const isBirthday = checkDateEqualCurrentDate(
                    guest.dateOfBirth
                  );
                  return (
                    <div className="AdminOrderTablesSectionGuest" key={index}>
                      <div
                        key={index}
                        className="AdminOrderTablesSectionGuestInfo"
                        onClick={() => handleOpenModal(guest.person.id)}
                      >
                        <div className="GuestInfoProfilePicContainer">
                          {isBirthday && (
                            <BirthdayHat className="BirthdayHat" />
                          )}
                          <ImageWithPlaceholder
                            imageSource={createDOBucketName(guest?.profilePic)}
                            placeholder={IMG_GUEST}
                            alt="logo"
                          />
                        </div>
                        <div>
                          <h6 className="Medium">
                            {concatFirstNameWithLastName({
                              firstName: guest?.firstName,
                              lastName: guest?.lastName,
                            }) ||
                              `${t("dashboard.guest.guest")} ${
                                guest?.person.id
                              }`}
                          </h6>
                          <span>{`${guest?.tableName} (${guest?.zoneName})`}</span>
                        </div>
                      </div>
                      {isBirthday && (
                        <div className="HappyBirthdayContainer">
                          <HappyBirthday className="HappyBirthday" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <EmptyState
                icon={GuestIcon}
                description={t("emptyStates.noGuests")}
                isAdmin={true}
              />
            )}
          </div>
        </If>
      </div>
      <If state={openSlideOrder}>
        <AdminOrderPage
          setOpenSlide={setOpenSlideOrder}
          orders={orders}
          isNewOrderCreated={isNewOrderCreated}
          setIsNewOrderCreated={setIsNewOrderCreated}
        />
      </If>
      <If state={openSlideChat}>
        <div className="AdminOrderChat">
          <AdminDetailedChat
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </div>
      </If>
      <Confirm
        title={t("modal.warningModalTitleNewOrder")
          .replace("{{table}}", `${selectedTable?.name}`)
          .replace("{{zone}}", `${activeZone?.name}`)}
        type={ENUMS_CONFIRM.types.TYPE_B}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={() => setOpenSlideConfirm(false)}
        onConfirm={createNewOrder}
        description={t("modal.warningModalDescription")}
      />
      {guestId && (
        <AdminOrderGuestInfoModal
          openSlide={openSlideGuestInfoModal}
          mainElementRef={mainElementRefGuestInfoModal}
          handleCloseModal={handleCloseModal}
          guestId={guestId}
        />
      )}
    </div>
  );
};
export default AdminOrderMap;
