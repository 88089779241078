import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Star1 from "assets/icons/emoji/PoutingFace.svg";
import Star2 from "assets/icons/emoji/FrowningFace.svg";
import Star3 from "assets/icons/emoji/NeutralFace.svg";
import Star4 from "assets/icons/emoji/SmilingFace.svg";
import Star5 from "assets/icons/emoji/HeartEyes.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check/selected.svg";

import "./Rating.scss";

export const ENUMS = {
  name: "Rating",
};

const Rating = ({ title, rating, onRatingChange, icon }) => {
  const { t } = useTranslation();

  const RATING_TITLES = [
    t("dashboard.feedbackStatistic.rating.horrible"),
    t("dashboard.feedbackStatistic.rating.bad"),
    t("dashboard.feedbackStatistic.rating.meh"),
    t("dashboard.feedbackStatistic.rating.good"),
    t("dashboard.feedbackStatistic.rating.awesome"),
  ];
  const handleOnRate = (ratingValue) => onRatingChange(ratingValue);

  const emojis = [
    { value: 1, src: Star1 },
    { value: 2, src: Star2 },
    { value: 3, src: Star3 },
    { value: 4, src: Star4 },
    { value: 5, src: Star5 },
  ];

  return (
    <div className="Rating">
      <div className="RatingTitle">
        <h5 className="SemiBold">{title}</h5>
        {icon && icon}
      </div>
      <div className="RatingEmoji">
        {emojis.map((emoji, index) => (
          <div key={emoji.value} className="RatingEmojiContainer">
            <div
              className={`RatingEmojiItem ${
                rating === emoji.value ? "isFilled" : ""
              }`}
              onClick={() => handleOnRate(emoji.value)}
            >
              {rating === emoji.value && (
                <div className="RatingEmojiSelected">
                  <CheckIcon />
                </div>
              )}
              <img src={emoji.src} alt="emoji" />
            </div>
            <h6 className=" h8 Medium RatingName">{RATING_TITLES[index]}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

Rating.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.number,
  onRatingChange: PropTypes.func.isRequired,
  icon: PropTypes.node,
};

export default Rating;
