import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useIsAdminRoute from "utils/hooks/useIsAdminRoute";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTES_OF_ROLES, ROUTE_NAME } from "utils/constants/routes";

const useRouteManager = () => {
  const navigate = useNavigate();
  const isAdminRoute = useIsAdminRoute();
  const location = useLocation();
  const previousLocation = useRef(null);
  const userRole = useSelector((state) => state[STORE_NAMES.user]?.user?.roles);
  const guestId = useSelector((state) => state[STORE_NAMES.guest]?.id);
  const user = useSelector((state) => state[STORE_NAMES.user]);

  useEffect(() => {
    const handleRouteChange = () => {
      const currentPath = location.pathname;
      const role = userRole?.[0]?.name;
      const newPathname = currentPath.replace(/^\/a/, "");

      // Check if the current path is a valid route
      const isCurrentPathValid =
        Object.values(ROUTE_NAME).includes(newPathname);

      if (isAdminRoute) {
        // Invalid route by Admin (from Admin to Admin)
        if (!isCurrentPathValid && user?.user?.id && user?.token) {
          navigate(-1);

          // Invalid route by Guest (from Guest to Admin)
        } else if (!isCurrentPathValid && guestId) {
          navigate(ROUTE_NAME.signIn);
        }

        // Handle restricted routes based on user role
        if (role) {
          const restrictedRoutes = ROUTES_OF_ROLES.find(
            (r) => r?.role === role
          )?.restrictedRoutes;

          if (restrictedRoutes) {
            restrictedRoutes.forEach((route) => {
              if (currentPath.includes(route)) {
                navigate(-1);
                return;
              }
            });
          }
        }
      }

      previousLocation.current = currentPath;
    };

    handleRouteChange();
  }, [location, userRole, navigate]);
};

export default useRouteManager;
