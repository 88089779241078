import React from "react";
import PropTypes from "prop-types";
import styles from "./IconButton.module.scss";

const IconButton = ({ Icon, className, onClick, img, hasNotification }) => {
  return (
    <div className={className} onClick={onClick}>
      {Icon && <Icon />}
      {img && <img src={img} alt={"language"} className={styles.Logo} />}
      {hasNotification && <div className={styles.Notification}></div>}
    </div>
  );
};

IconButton.propTypes = {
  img: PropTypes.string,
  Icon: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hasNotification: PropTypes.bool,
};

export default IconButton;
