import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { GuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";
import { PARAM_NAME, ROUTE_NAME } from "utils/constants/routes";
import ClientPagesController from "pages/client/ClientPagesController";
import Basket from "pages/client/basket/Basket";
import Business from "pages/client/business/Business";
import Dashboard from "pages/client/dashboard/Dashboard";
import useWindowSize from "utils/hooks/useWindowSize";
import GuestChatController from "components/elements/chat/GuestChatController";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "pages/common/error-boundary/ErrorBoundary";
import OfflineMenu from "pages/common/offline-menu/OfflineMenu";
import FunZoneGames from "pages/client/fun-zone/games/FunZoneGames";
import useGuestRouteManager from "utils/hooks/useGuestRouteManager";
import MyReservations from "pages/client/reservation/my-reservations/MyReservations";
import CreateReservation from "pages/client/reservation/create-reservation/CreateReservation";
import WheelOfFortune from "components/wheel-of-fortune/WheelOfFortune";
import Auth from "pages/client/auth/Auth";
import SignUp from "pages/client/auth/sign-up/SignUp";
import SignIn from "pages/client/auth/sign-in/SignIn";
import ForgetPassword from "pages/client/auth/forget-password/ForgotPassword";
import useCreateOrGetGuest from "utils/hooks/useCreateOrGetGuest";
import MyProfile from "pages/client/my-profile/MyProfile";
import MyProfileEdit from "pages/client/my-profile-edit/MyProfileEdit";
import ResetPassword from "pages/client/reset-password/ResetPassword";
import VerifyUser from "pages/client/verify-user/VerifyUser";
import TowerBlock from "pages/client/fun-zone/games/tower-block/TowerBlock";
import MemoryCardGame from "pages/client/fun-zone/games/memory-card-game/MemoryCardGame";
import MagicOrder from "pages/client/magic-order/MagicOrder";
import TicTacToeGame from "pages/client/fun-zone/games/tic-tac-toe/TicTacToeGame";
import MenuV2 from "pages/client/menu-v2/Menu";
import Favorites from "pages/client/favorites/Favorites";
import FilterResultPage from "pages/client/menu-v2/filter-result-page/FilterResultPage";
import SeeAll from "pages/client/menu-v2/see-all/SeeAll";
import MenuItem from "pages/client/menu-v2/menu-item/MenuItem";
import GuestFeedBack from "pages/client/feed-back/GuestFeedBack";
import { ImageVisibilityProvider } from "utils/context-api/ImageVisibilityContext";
import GuestMenuProvider from "pages/client/menu-v2/GuestMenuProvider";
import Mockup from "components/mockup/Mockup";
import If from "components/if/If";
import useArabianLanguageController from "utils/hooks/useArabianLanguageController";
import MenuItemAR from "pages/client/menu-item-ar/MenuItemAR";
import BasketItem from "pages/client/basket/basket-item/BasketItem";
import VR from "pages/client/vr/VR";

const GuestRoutes = () => {
  useGuestRouteManager();
  useArabianLanguageController();

  const { isStoreReset } = useCreateOrGetGuest();

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <GuestWebsocketContextProvider>
        <Routes>
          <Route
            path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}`}
            element={
              <GuestMenuProvider>
                <ClientPagesController isStoreReset={isStoreReset} />
              </GuestMenuProvider>
            }
          >
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}`}
              element={
                <ImageVisibilityProvider>
                  <MenuV2 />
                </ImageVisibilityProvider>
              }
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.myProfile}`}
              element={<MyProfile />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.feedbacks}`}
              element={<GuestFeedBack />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.auth}`}
              element={<Auth />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`}
              element={<SignUp />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`}
              element={<SignIn />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.auth}${ROUTE_NAME.forgotPassword}`}
              element={<ForgetPassword />}
            />

            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`}
              element={<MyProfileEdit />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.myProfile}${ROUTE_NAME.resetPassword}`}
              element={<ResetPassword />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.category}${PARAM_NAME.categoryId}`}
              element={<SeeAll />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.filterResult}`}
              element={<FilterResultPage />}
            />

            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}${PARAM_NAME.menuItemId}`}
              element={<MenuItem />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItemAR}${PARAM_NAME.menuItemId}`}
              element={<MenuItemAR />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.basket}`}
              element={<Basket />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.basket}${ROUTE_NAME.basketItem}${PARAM_NAME.basketItemIndex}`}
              element={<BasketItem />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.info}`}
              element={<Business />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.vr}`}
              element={<VR />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.dashboard}`}
              element={<Dashboard />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.chat}`}
              element={<GuestChatController />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`}
              element={<MyReservations />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.reservation}${ROUTE_NAME.createReservation}`}
              element={<CreateReservation />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`}
              element={<FunZoneGames />}
            ></Route>

            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.memoryCard}`}
              element={<MemoryCardGame />}
            />

            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.ticTacToe}`}
              element={<TicTacToeGame />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.towerBlock}`}
              element={<TowerBlock />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.magicOrder}`}
              element={<MagicOrder />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.favorites}`}
              element={<Favorites />}
            />
          </Route>
          <Route path={ROUTE_NAME.myProfile} element={<MyProfile />} />

          <Route
            path={`${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`}
            element={<MyProfileEdit />}
          />
          <Route
            path={`${ROUTE_NAME.myProfile}${ROUTE_NAME.resetPassword}`}
            element={<ResetPassword />}
          />
          {/*<Route path={ROUTE_NAME.allBusinesses} element={<AllBusinesses />} />*/}
          <Route
            path={ROUTE_NAME.allBusinesses}
            element={<Navigate to={ROUTE_NAME.underConstruction} />}
          />
          <Route
            path={`${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`}
            element={<MyReservations />}
          />

          <Route path={`${ROUTE_NAME.auth}`} element={<Auth />} />
          <Route
            path={`${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`}
            element={<SignUp />}
          />
          <Route
            path={`${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`}
            element={<SignIn />}
          />
          <Route
            path={`${ROUTE_NAME.auth}${ROUTE_NAME.forgotPassword}`}
            element={<ForgetPassword />}
          />
          <Route path={`${ROUTE_NAME.verifyUser}`} element={<VerifyUser />} />
          <Route
            path={`${ROUTE_NAME.reservation}${ROUTE_NAME.createReservation}`}
            element={<CreateReservation />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to={`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`}
              />
            }
          />
          <Route path={ROUTE_NAME.offlineMenu} element={<OfflineMenu />} />
        </Routes>
        <WheelOfFortune />
      </GuestWebsocketContextProvider>
    </ErrorBoundary>
  );
};

const AppRouterGuest = () => {
  const { size } = useWindowSize();

  return (
    <>
      <If state={size.width >= 1000}>
        <Mockup src={window.location.href} />
      </If>
      <If state={size.width < 1000}>
        <GuestRoutes />
      </If>
    </>
  );
};

export default AppRouterGuest;
