import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import "@google/model-viewer";
import { useTranslation } from "react-i18next";

import { getBrandMainLogoDark } from "utils/branding-helper";
import { ReactComponent as ArIcon } from "assets/icons/menuv2/MagicOrder.svg";
import If from "components/if/If";

import styles from "./ModelViewer.module.scss";

const Logo = getBrandMainLogoDark();

const ModelViewer = ({ src, iosSrc }) => {
  const { t } = useTranslation();
  const [isModelVisible, setIsModelVisible] = useState(false);

  useEffect(() => {
    const item = document.querySelector("model-viewer");

    const handleVisibilityChange = () => {
      if (item && item.isConnected) {
        setIsModelVisible(true);
      }
    };

    item.addEventListener("load", handleVisibilityChange);

    return () => item.removeEventListener("load", handleVisibilityChange);
  }, []);

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <model-viewer
          src={src}
          ios-src={iosSrc}
          alt="3D Model"
          ar={true}
          ar-modes="scene-viewer webxr quick-look"
          camera-controls={true}
          shadow-intensity="1"
          className={styles.ModelViewer}
          style={{
            borderRadius: "16px",
            border: "1px solid #DFDEE7",
            backgroundColor: "#fff",
            width: "100%",
            height: "472px",
            "--progress-bar-color": "#FFCA40",
          }}
        >
          <Logo className={styles.Logo} alt="3D Model" />
          <If state={!isModelVisible}>
            <div className={styles.LoadingMessage}>
              {t("emptyStates.loading")}
            </div>
          </If>

          <button slot="ar-button" className={styles.ARButton}>
            <h5 className={cx(styles.Text, "SemiBold")}>
              {t("buttons.revive")}
            </h5>
            <ArIcon />
          </button>
        </model-viewer>
      </div>
    </div>
  );
};

ModelViewer.propTypes = {
  src: PropTypes.string,
  iosSrc: PropTypes.string,
};

export default ModelViewer;
