import React, { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";

import { ROUTE_NAME } from "utils/constants/routes";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";

const SentryContext = createContext();

const SentryProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const businessUser = useSelector((state) => state[STORE_NAMES.user].user);
  useEffect(() => {
    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (!SENTRY_DSN) {
      return;
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0, // Performance monitoring
      replaysSessionSampleRate: 1.0, // Optional: Session replay
      environment: process.env.REACT_APP_DEPLOY_ENV,
    });
    setIsInitialized(true);
  }, []);

  const logError = (error, context = {}) => {
    if (isInitialized) {
      Sentry.captureException(error, { extra: context });
    }
  };

  useEffect(() => {
    if (!isInitialized) return;

    const path = window.location.pathname;
    const isClient = path.startsWith(ROUTE_NAME.client);
    const isAdmin = path.startsWith(ROUTE_NAME.admin);

    const userData = {
      ...(isClient && guest.id && { id: guest.id }),
      ...(isClient && guest.firstName && { username: guest.firstName }),
      ...(isAdmin && businessUser.id && { id: businessUser.id }),
      ...(isAdmin && businessUser.name && { username: businessUser.name }),
    };

    if ((isClient && guest.id) || (isAdmin && businessUser.id)) {
      Sentry.setUser(userData);
    } else {
      Sentry.setUser(null);
    }
  }, [isInitialized, guest, businessUser]);

  return (
    <SentryContext.Provider value={{ logError }}>
      {children}
    </SentryContext.Provider>
  );
};

SentryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSentry = () => {
  return useContext(SentryContext);
};

export default SentryProvider;
