import TermsAndCondition from "utils/constants/i18n/TermsAndCondition";
import PrivacyPolicy from "utils/constants/i18n/PrivacyPolicy";

const TRANSLATION = {
  welcome: "bienvenido (Spain)",
  common: {
    settings: "Configuración",
    about: "Acerca de",
    home: "Inicio",
    logo: "Logo",
    shape: "Forma",
    selected: "Seleccionado",
    create: "Crear",
    notFound: "No encontrado",
    applied: "Aplicado",
    yourLikes: "Tus me gusta",
    details: "Detalles",
    published: "Publicado",
    unPublished: "No publicado",
    general: "General",
    address: "Dirección",
    wifi: "Wi-Fi",
    sort: "Ordenar",
    copied: "Copiado",
    contact: "Contacto",
    pages: "Páginas",
    kcal: "kcal",
    currency: "Moneda",
    connect: "Conectar",
    disconnect: "Desconectar",
    confirmDisconnect: "¿Estás seguro de desconectar?",
    connection: "Conexión",
    qr: "QR",
    telegram: "Telegram",
    enable: "Habilitar",
    disable: "Deshabilitar",
    zoneAndTable: "Zona y Mesa",
    printers: "Impresoras",
    connectToWifi: "Conectado a Wi-Fi",
    connectWifi: "Conectar Wi-Fi",
    disconnectFromWifi: "Desconectado de Wi-Fi",
    name: "Nombre",
    active: "Activo",
    inactive: "Inactivo",
    date: {
      date: "Fecha",
      time: "Hora",
      createdAt: "Creado el",
      dateCreated: "Fecha de creación",
      dateSelection: "Selección de fecha",
      schedule: "Operativo durante períodos programados",
    },
    time: {
      hour: "hora",
      minute: "minuto",
      second: "segundo",
      from: "Desde",
      to: "Hasta",
      since: "Desde el inicio",
      min: "min",
      shortVersion: {
        hour: "h",
        minute: "m",
        second: "s",
      },
    },
    days: {
      days: "Días",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      sunday: "Domingo",
      everyday: "Todos los días",
      everyWeekday: "Cada día laborable",
      weekend: "Fin de semana",
    },
  },
  navbarRoutes: {
    magicOrder: "Pedido Mágico",
    aiSearch: "Búsqueda AI",
    account: "Cuenta",
    order: "Pedido",
    statistics: "Estadísticas",
    dashboard: "Panel",
    salesStatistics: "Estadísticas de Ventas",
    orderStatistics: "Estadísticas de Pedidos",
    allOrders: "Todos los Pedidos",
    menuStatistics: "Estadísticas del Menú",
    allItems: "Todos los Ítems",
    feedbacks: "Comentarios",
    staff: "Personal",
    weeklyStatistics: "Estadísticas Semanales",
    tables: "Mesas",
    menu: "Menú",
    business: "Negocio",
    infra: "Infraestructura",
    users: "Usuarios",
    qrAndTable: "QR y Mesa",
    table: "Mesa",
    qr: "QR",
    reservation: "Reservación",
    devices: "Dispositivos",
    map: "Mapa",
    orders: "Pedidos",
    departments: "Departamentos",
    superAdmin: "Super Administrador",
    inventory: "Inventario",
    funZone: "Zona de Diversión",
    home: "Inicio",
    guests: "Invitados",
    payments: "Pagos",
    profile: "Mi Perfil",
    ipBlacklist: "Lista negra de IP",
    pageTitles: {
      superAdmin: "SUPER ADMINISTRADOR",
      infra: "INFRAESTRUCTURA",
      guests: "INVITADOS",
      weeklyStatistics: "ESTADÍSTICAS SEMANALES",
      salesStatistics: "ESTADÍSTICAS DE VENTAS",
      orderStatistics: "ESTADÍSTICAS DE PEDIDOS",
      menuStatistics: "ESTADÍSTICAS DEL MENÚ",
      allOrders: "TODOS LOS PEDIDOS",
      departments: "Departamentos",
      allItems: "TODOS LOS ÍTEMS",
      feedbacks: "COMENTARIOS",
      category: "CATEGORÍA",
      settings: "CONFIGURACIÓN",
      business: "NEGOCIO",
      users: "USUARIOS",
      zones: "ZONAS",
      tables: "MESAS",
      qrList: "LISTA DE QR",
      integrations: "Integraciones",
      payments: "PAGOS",
      telegramIntegration: "Telegram - Integración",
    },
  },
  emptyTable: {
    emptyTableDescription: "Aún no tienes zonas.",
    emptyZoneDescription: "No hay mesas en esta zona.",
    emptyQR: "Aún no tienes códigos QR.",
    emptyZoneTitle: "No hay mesas en el mapa",
  },
  language: {
    language: "Idioma",
    english: "Inglés",
    russian: "Ruso",
    azerbaijani: "Azerbaiyano",
    german: "Alemán",
  },

  buttons: {
    revive: "Revivir",
    addItem: "Agregar ítem",
    editItem: "Editar ítem",
    addQR: "Agregar QR",
    resetData: "Restablecer",
    back: "Atrás",
    createQR: "Crear QR",
    createZone: "Crear Zona",
    createOrder: "Crear Pedido",
    edit: "Editar",
    delete: "Eliminar",
    confirm: "Confirmar",
    finish: "Finalizar",
    discard: "Descartar",
    change: "Cambiar",
    deny: "Denegar",
    save: "Guardar",
    close: "Cerrar",
    saveChanges: "Guardar cambios",
    all: "Todo",
    selectAll: "Seleccionar todo",
    seeAll: "Ver todo",
    seeLess: "Ver menos",
    add: "Agregar",
    addNew: "Agregar nuevo",
    reset: "Restablecer",
    resend: "Reenviar",
    clearFilters: "Limpiar filtros",
    pay: "Pagar",
    clear: "Limpiar todo",
    apply: "Aplicar",
    defaultLanguage: "Idioma predeterminado",
    defaultCurrency: "Moneda predeterminada",
    availableLanguages: "Idiomas disponibles",
    currency: "Moneda predeterminada",
    askAi: "Preguntar a la IA",
    link: "Enlace",
    links: "Enlaces",
    unlink: "Desvincular",
    addBasket: "Agregar a la cesta",
    addToOrder: "Agregar al pedido",
    clickToAdd: "Haz clic para agregar",
    clickToAddImage: "Haz clic para agregar imagen",
    clickToUpload: "Haz clic para subir",
    logOut: "Salir",
    login: "Iniciar sesión",
    lockScreen: "Bloquear",
    goToDashboard: "Ir al panel",
    rememberMe: "Recuérdame",
    forgotPassword: "¿Olvidaste tu contraseña?",
    payWith: "Pagar con",
    seeMenu: "Ver el menú",
    addOrder: "Nuevo pedido",
    readMore: "Leer más",
    serve: "Servir",
    confirmOrder: "Confirmar pedido",
    backToMenu: "Volver al menú",
    send: "Enviar",
    tryAgain: "Intentar de nuevo",
    update: "Actualizar",
    continue: "Continuar",
    verify: "Verificar",
    applyDiscount: "Aplicar descuento",
    discountProducts: "Productos con descuento",
    print: "Imprimir",
    export: "Exportar",
    closeOrder: "Finalizar pedido",
    printBill: "Imprimir la factura",
    fullTables: "Mesas llenas",
    editForModal: "Editar {{title}}",
    addForModal: "Agregar {{title}}",
    successfullySent: "Enviado con éxito",
    sent: "Enviado",
    reserve: "Reservar",
    skip: "Saltar",
    source: "Fuente",
    target: "Objetivo",
    editZone: "Editar zona",
    contactUs: "Contáctanos",
  },
  inputs: {
    name: "Nombre",
    username: "Nombre de usuario",
    description: "Descripción",
    example: "Ejemplo",
    price: "Precio",
    discount: "Descuento",
    role: "Rol",
    phoneNumber: "Número de teléfono",
    pinCode: "Código PIN",
    pinCodeConfirm: "Confirmar código PIN",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    newPassword: "Nueva contraseña",
    oldPassword: "Contraseña anterior",
    businessName: "Nombre del negocio",
    city: "Ciudad",
    zipCode: "Código postal",
    street: "Calle",
    map: "Enlace de Google Maps",
    serviceFee: "Tarifa de servicio",
    search: "Buscar",
    enterPromoCode: "Ingresar código promocional",
    link: "Agregar enlace",
    instagram: "Enlace de Instagram",
    phone: "Agregar teléfono",
    contactPhone: "Número de teléfono",
    whatsapp: "Número de WhatsApp",
    mail: "Agregar correo electrónico",
    contactMail: "Dirección de correo electrónico",
    tableName: "Nombre de la mesa",
    usernameExample: "@ejemplo",
    chooseCurrent: "Elegir actual",
    seats: "Asientos",
    minDeposit: "Depósito mínimo",
    qrName: "Nombre del QR",
    wifiName: "Nombre de Wi-Fi",
    sell: "Precio de venta",
    cost: "Costo",
    calories: "Calorías",
    time: "Tiempo",
    amount: "Cantidad",
    unit: "Unidad",
    addComment: "Agregar comentario",
    warningAmount: "Cantidad de advertencia",
    number: "Número",
    notFixed: "No fijo",
    fixed: "Fijo",
    maxNumber: "Número máximo",
    deposit: "Depósito",
    yourName: "Tu nombre",
    contact: "Contacto",
    reserveeName: "Nombre del reservante",
    numberOfGuest: "Número de invitados",
    date: "Fecha",
    duration: "Duración",
    comment: "Comentario...",
    commentText: "Comentario",
    fullName: "Nombre completo",
    dateOfBirth: "Fecha de nacimiento",
  },
  image: {
    images: "Imágenes",
    selectCover: "haz clic en la imagen para establecer como portada",
    background: "Imagen de fondo",
    other: "Otras fotos",
    addPhoto: "Agregar foto",
    addImage: "Agregar imagen",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Nombre de la modificación",
    modification: "Modificación",
    modifications: "Modificaciones",
    noModifications: "Aún no se han agregado modificaciones.",
    noModificationOption: "Aún no se han agregado opciones.",
    addModification: "Agregar modificación",
    editModification: "Editar modificación",
    selection: {
      selection: "Selección",
      types: "Tipo de selección",
      singleSelection: "Única",
      singleSelectionDescription: "solo se puede seleccionar una opción",
      multiSelection: "Múltiple",
      multiSelectionDescription: "se pueden seleccionar varias opciones",
      option: {
        options: "Opciones",
        onlyOnce: "Solo una vez",
        manyTimes: "Muchas veces",
        default: "Selección predeterminada",
        addLanguage: "Agregar idioma",
      },
    },
  },

  user: {
    staff: "Personal",
    employee: "Empleado",
    addEmployee: "Agregar empleado",
    editEmployee: "Editar empleado",
    sendAllDetails: "Generar una nueva contraseña y enviarla al usuario",
    userDeleteError:
      "No puedes eliminar la cuenta con la que has iniciado sesión",
    generatePassword: "Generar una nueva contraseña y enviarla al usuario.",
    changeYourPassword: "Cambia tu contraseña",
  },
  guests: {
    guest: "Huésped",
    guests: "Huéspedes",
    registeredGuest: "Registrado",
    unregisteredGuest: "No registrado",
    allBusinesses: "Todos los negocios",
  },
  table: {
    connected: "Conectado con QR",
    tablesLowercase: "mesas",
    tables: "Mesas",
    table: "Mesa",
    addTable: "Agregar mesa",
    editTable: "Editar mesa",
    selectTable: "Seleccionar mesa",
    busy: "mesas ocupadas",
    available: "mesas disponibles",
    full: "lleno",
    noTablesVisible: "No hay mesas visibles en el mapa actualmente.",
    map: "Mapa",
    current: "actual",
  },
  zone: {
    zone: "Zona",
    tables: "Mesas",
    zones: "Zonas",
    name: "Nombre de la zona",
    allZones: "Todas las zonas",
    selectZone: "Seleccionar zona",
  },
  map: {
    removeFromMap: "Eliminar del mapa",
    shapes: {
      rectangle: "Rectángulo",
      circle: "Círculo",
    },
    createTable: "Crear mesa",
    addToMap: "Agregar al mapa",
    windowAndDoor: "Ventana y puerta",
    designElements: "Elementos de diseño",
    decoration: "Decoración",
    reset: "Restablecer",
    width: "Ancho",
    height: "Altura",
  },
  sort: {
    az: "A-Z",
    cheapToExpensive: "De barato a caro",
    expensiveToCheap: "De caro a barato",
    new: "Nuevo",
  },
  qr: {
    qr: "QR",
    qrCode: "Código QR",
    name: "Nombre del QR",
    list: "Lista de QR",
    id: "ID de QR",
    type: "Tipo de código QR",
    status: "Estado",
    unlinked: "No vinculado",
    linked: "Vinculado",
    qrIsNotAdded: "QR no ha sido añadido",
    connected: "Conectado a",
    notConnected: "No conectado",
    scanToConnect: "o escanea para conectar",
    QRLinkCopied: "Enlace QR copiado",
    qrScan: "Escanea este QR",
    scanCount: "Número de escaneos",
    qrScanDescription:
      "* Escanea desde el teléfono móvil del usuario y asegúrate de que la aplicación de Telegram esté instalada",
    types: {
      qrType: "Tipo de QR",
      wifi: "Wi-Fi",
      menu: "Menú",
    },
    downloadAllQr: "Descargar todos los QR",
    downloadQr: "Descargar código QR",
    generateQR: "Generar código QR",
    custom: "Personalizado",
    forEachCategory: "Para cada categoría",
    params: "Parámetros",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "Sí, confirmo mi pedido y me responsabilizo de pagarlo.",
    areYouSureYouWantToPlaceThisOrder:
      "¿Estás seguro de que deseas realizar este pedido?",
    deleteModalTitle: "¿Estás seguro de eliminar?",
    deleteMenuItemTitle: "¿Estás seguro de eliminar estos elementos?",
    deleteModalDescription: "Esta acción no se puede deshacer",
    warningModalTitleFinishOrder:
      "¿Estás seguro de que deseas finalizar este pedido?",
    accessDenied: "Acceso denegado",
    permissionRequired: "Permiso requerido",
    confirmModalTitleLogOut: "¿Estás seguro de cerrar sesión?",
    confirmModalDescriptionLogOut:
      "Puedes volver a iniciar sesión en cualquier momento",
    warningModalTitleChangeZone:
      "¿Estás seguro de que deseas cambiar esta zona?",
    warningModalTitleLogout: "¿Estás seguro de que deseas cerrar sesión?",
    warningModalTitleNewOrder:
      "Crearás un nuevo pedido en {{zone}}/{{table}}. ¿Estás seguro?",
    warningModalDescriptionNewOrder:
      "Por favor, haz clic en el botón 'Sí' para crear un nuevo pedido",
    warningModalTitleUnsavedChanges:
      "¿Estás seguro de que deseas salir sin guardar?",
    warningModalDescription: "Tus ediciones no se guardarán",
    yes: "Sí",
    no: "No",
    close: "Cerrar",
    apply: "Aplicar",
    id: "id",
    preview: "Vista previa",
    cannotBeRemoved: "Los artículos confirmados no se pueden eliminar",
    unConfirmedCannotBeRemoved:
      "Los artículos no confirmados no se pueden pagar",
    payMenuItemTitle: "¿Estás seguro de pagar esta selección?",
    guestProfile: "Perfil del huésped",
    totalVisits: "Visitas totales",
    averageAmount: "Monto promedio",
    topOrder: "Top {{count}} pedido",
    createdBy: "Creado por",
    paymentMethod: "Método de pago",
    orderId: "ID de pedido",
    transactionId: "ID de transacción",
    visitHistory: "Historial de visitas",
    emptyOrderInfo:
      "El pedido no está disponible en este momento. Escanea nuevamente el menú QR o contacta al camarero.",
    closeOrderConfirmation: "¿Cómo le gustaría cerrar el pedido?",
  },
  menu: {
    preview: "Vista previa del menú",
    search: "Busca tu comida favorita...",
    translate: {
      ai: "Traducción mágica",
      translating: "Traduciendo...",
      aiAutoTranslate: "Traducción automática con IA",
      languageSelection: "Selección de idioma",
      successfulTranslate: "Traducción exitosa",
      waitingTranslate:
        "La IA tardará unos minutos en traducir tu menú. Ten en cuenta que los elementos del menú no se pueden editar durante este tiempo.",
      magicTranslate: "Traducir",
      itemErrorMessage:
        "Este artículo no existe en el idioma de origen. Para traducirlo, agrégalo en el idioma de origen.",
      categoryErrorMessage:
        "Esta categoría no existe en el idioma de origen. Para traducirla, agrégala en el idioma de origen.",
    },
    tag: {
      tag: "Etiqueta",
      tags: "Etiquetas",
      name: "Nombre de la etiqueta",
    },
    category: {
      category: "Categoría",
      categories: "Categorías",
      add: "Agregar categoría",
      all: "Todas las categorías",
      name: "Nombre de la categoría",
      mealCategories: "Categorías de comidas",
      emptyGuestMenuTitle: "El menú está vacío",
      emptyGuestMenuDescription:
        "Parece que el negocio aún no ha agregado nada al menú.",
      emptyAdminCategoryDescription: "Aún no tienes categorías.",
    },
    item: {
      items: "Artículos",
      item: "Artículo",
      all: "Todos los artículos",
      itemLowerCase: "artículo",
      itemsLowerCase: "artículos",
      selected: "Artículos seleccionados",
      deleteItem: "Eliminar artículo",
      updateItem: "Actualizar artículo",
      duplicateItem: "Duplicar artículo",
      duplicate: "Duplicar",
      scheduleDiscount: "Programar descuento",
      emptyAdminMenuItemDescription: "Aún no tienes artículos.",
      itemDeleteError:
        "No puedes eliminar este producto porque existe en un pedido activo.",
    },
    view: {
      selection: "Vista predeterminada del menú",
      scroll: "Desplazamiento",
      withImage: "Con imagen",
      withoutImage: "Sin imagen",
      list: "Lista",
      threeD: "3D",
      poker: "Póker",
      grid: "Cuadrícula",
    },
    filter: {
      filters: "Filtros",
      priceRange: "Rango de precios",
      noResult: "No hay resultados coincidentes",
      noResultDesc:
        "Los filtros activos están ocultando todos los artículos del menú.",
    },
    settings: {
      color: "Color",
      radius: "Radio del borde",
      roundness: "Redondez",
      currency: "Moneda",
      colors: {
        primary: "Colores primarios",
        red: "Rojo",
        gold: "Oro",
        purple: "Púrpura",
        blue: "Azul",
      },
      curves: {
        elementCurves: "Curvaturas del elemento",
        none: "Ninguna",
        small: "Pequeña",
        medium: "Mediana",
        large: "Grande",
      },
      changeProperties: "Cambiar propiedades",
      details: "Detalles del menú",
    },
    viewAr: "Vista AR",
  },
  menuV2: {
    selectLanguage: "Seleccionar idioma",
  },
  business: {
    businesses: "Negocios",
    list: "Lista de negocios",
    staffList: "{{count}} empleado",
    selectTitle: "Seleccionar negocio",
    selectSubTitle:
      "Por favor, selecciona el negocio al que deseas ingresar y continúa",
    contact: "Contacto",
    contactWith: "Contactar con",
    workingHours: "Horario de trabajo",
    needToKnow: "Necesitas saber",
    addNew: "Agregar negocio",
    edit: "Editar negocio",
    plan: "Plan",
    selectPlan: "Seleccionar plan",
    creationDate: "Fecha de creación",
    status: "Estado",
    verified: "Verificado",
    unverified: "No verificado",
    businessStatus: "Estado del negocio",
    expirationDate: "Fecha de expiración",
    country: "País",
    printers: "Impresoras",
    wiFi: "Wi-Fi",
    printer: "Impresora",
    active: "Activo",
    inActive: "Inactivo",
    connectedTo: "Conectado a",
    guestBusinessBlockTitle: "Este negocio está inactivo.",
    guestBusinessBlockSubTitle:
      "Lo sentimos, este negocio ya no está disponible.",
    settings: {
      goLive: "Ir en vivo",
      chat: "Chat",
      realTimeOrder: "Tiempo real",
      menuItemCalorie: "Calorías",
      menuItemTime: "Tiempo",
      menuItemAmount: "Cantidad",
      reservation: "Reservación",
      reservationMap: "Mostrar mapa en reservación",
      chatSound: "Chat",
      orderSound: "Pedido",
      askForBillSound: "Pedir la cuenta",
      callWaiterSound: "Llamar al camarero",
      isAskForBillEnabled: "Pedir la cuenta",
      isCallWaiterEnabled: "Llamar al camarero",
      menu: "Menú",
      sound: "Sonido",
      general: "General",
      isOnlinePaymentEnabled: "Pago en línea",
      order: "Pedido",
      action: "Acciones",
      showInBusinessCatalogue: "Mostrar en catálogo de negocios",
      reservationVRLink: "Agregar enlace virtual",
      reservationVREnabled: "Vista virtual",
      reservationMapEnabled: "Vista de mapa",
      permission: "Permisos",
      declineOrderItemsEnabled: "Rechazar artículos del pedido",
      deleteOrderEnabled: "Eliminar pedido",
      deleteMenuItemsEnabled: "Eliminar artículos del menú",
      editMenuEnabled: "Editar menú",
      virtualTour: "Tour virtual",
      vrLink: "Agregar enlace virtual",
      vrLinkEnabled: "Vista virtual",
    },
  },
  orderItem: {
    denied: "Denegado",
    deleted: "Eliminado",
    empty: "Vacío",
  },
  basket: {
    basket: "Cesta",
    favorites: "Favoritos",
    basketAndFavorites: "Cesta y Favoritos",
    addMessage: "Añadir mensaje para el restaurante",
    messagePlaceholder:
      "¿Solicitud especial, alergias, restricciones alimentarias?",
    itemAdded: "{{count}} artículo añadido al carrito",
    emptyFavorite: "Aún no tienes favoritos",
    added: "Añadido",
    order: {
      info: "Información del pedido",
      subtotal: "Subtotal",
      total: "Total",
      tax: "Impuesto",
      promoCode: "Código promocional",
      totalCost: "Costo total",
      orders: "Pedidos",
      myOrders: "Mi Pedido",
      ordersLowerCase: "pedidos",
      orderLowerCase: "pedido",
      order: "Pedido",
      bill: "Factura",
      noOrder: "No hay pedidos disponibles",
      noOrders: "No hay pedidos.",
      deletedOrderTitle: "Tu pedido ha sido eliminado",
      deletedOrderDescription:
        "Lo sentimos, tu pedido ha sido eliminado por nuestro administrador/camarero",
      notifications: "Notificaciones",
      updatesToSave: "Cambios",
      newOrder: "Nuevo Pedido",
      deleteOrder: "Eliminar Pedido",
      finishOrder: "Finalizar Pedido",
      done: "Hecho",
      preparing: "Preparando",
      numberOfGuests: "Número de invitados",
      fullNessOfTable: "Capacidad de la mesa",
      acceptAll: "Aceptar Todo",
    },
    comment: {
      addMessage: "Añadir mensajes para los restaurantes",
      addMessageDesc:
        "¿Solicitud especial, alergias, restricciones alimentarias?",
    },
    emptyBasketTitle: "Tu Cesta está Vacía",
    emptyBasketDescription: "Parece que aún no has añadido nada a tu cesta",
  },
  orders: {
    orders: "Pedidos",
    order: "Pedido",
    guests: "Invitados",
    guest: "Invitado",
    fullTables: "Mesas Ocupadas",
  },
  funZone: {
    selectGame: "Seleccionar Juego",
    playGame: "Jugar",
    who: "¿Quién",
    paysTheBill: "paga la cuenta?",
    mindGame: "Memoria",
    canYouRemember: "¿Puedes recordar?",
    tower: "Torre",
    buildYourHighestTower: "Construye tu torre más alta",
    ticTackToe: {
      title: "Tres en Raya",
      player: "Jugador",
      turnFor: "Turno de {{player}}",
      win: "Ganó",
      outsmartYourOpponent: "¡Supera a tu oponente!",
      draw: "Empate",
      points: "Puntos",
    },
    wheelOfFortune: {
      spinnerGame: "Juego de Ruleta",
      who: "¿Quién?",
      whoWillPayTheBill: "¿Quién pagará la cuenta?",
      whoWillGoToTheMarket: "¿Quién irá al mercado?",
      whoWillBuyCigarettes: "¿Quién comprará cigarrillos?",
      whoWillHaveAnotherShot: "¿Quién tomará otro trago?",
      whoWillGetDessert: "¿Quién pedirá postre?",
      whoWillChooseTheMusic: "¿Quién elegirá la música?",
      whoWillSingKaraoke: "¿Quién cantará?",
      start: "Comenzar",
      spinning: "Girando...",
      editToPlay: "Editar para jugar",
      addNew: "Añadir nuevo",
      saveAndContinue: "Guardar y continuar",
      typeHere: "Escribe aquí",
      result: "Resultado",
      youAreTheChosenOne: "Eres el elegido",
    },
    memoryCardGame: {
      mindGame: "Juego Mental",
      restart: "Reiniciar",
      winMessage: "Ganaste",
      loseMessage: "Perdiste",
      secondsLeft: "Segundos restantes",
      triesLeft: "Intentos restantes",
      startNewGame: "Iniciar un nuevo juego",
    },
    towerGame: {
      blocks: "BLOQUES",
      clickToPlay: "HAZ CLIC PARA JUGAR",
      clickToRestart: "Haz clic para reiniciar",
    },
  },

  dashboard: {
    dashboard: "Tablero",
    startTalk: "Iniciar Conversación",
    sales: "Ventas",
    salesAnalytics: "Ventas Totales / Hora",
    weekStatistics: "Estadísticas Semanales",
    statistics: "Estadísticas",
    totalSales: "Ventas Totales",
    paymentMethodAnalysis: "Análisis de Método de Pago",
    byNumberOfOrders: "Por número de pedidos",
    byTheAmountOfSales: "Por el monto de ventas",
    byTheNumberOfRatings: "Por el número de calificaciones",
    bestselling: "Más Vendido",
    worstSelling: "Menos Vendido",
    processing: "Procesando",
    billReady: "La factura estará lista",
    unSelect: "Deseleccionar Todo",
    selectToPay: "Haz clic para seleccionar",
    denied: "Denegado",
    showMore: "Mostrar Más",
    showLess: "...Mostrar Menos",
    bestSellingCategory: "Categoría más vendida",
    topPerformer: "Mejor Desempeño",
    lowPerformer: "Peor Desempeño",
    noResultForThisPeriodMsg: "No hay resultados para este período",
    all: "Todo",
    yourTable: "Tu Mesa",
    totalGuests: "Total de Invitados",
    totalItems: "Total de Artículos",
    deleted: "Eliminado",
    weeklySaleStatistics: "Estadísticas de Ventas Semanales",
    weeklyOrderStatistics: "Estadísticas de Pedidos Semanales",
    paid: "Pagado",
    inProgress: "En Progreso",
    paymentInProgress: "Pago en proceso",
    legends: {
      sale: "Venta",
      revenue: "Ingresos",
      orders: "Número de pedidos",
      orderItems: "Número de artículos",
      totalSale: "Venta Total",
      starCount: "Cantidad de Estrellas",
    },
    guest: {
      guests: "Invitados",
      guest: "Invitado",
      me: "Yo",
      review: {
        meal: "Comida",
        service: "Servicio",
        review: "Reseña",
        sent: "Enviado",
        feedBack: "Comentarios",
      },
    },
    staffStatistics: {
      sales: "Ventas",
      orders: "Pedidos",
      rating: "Calificación",
    },
    orderStatistics: {
      totalSale: "Venta Total",
      revenue: "Ingresos",
      averageBill: "Factura Promedio",
      orderCount: "Cantidad de Pedidos",
      guestsServed: "Invitados Atendidos",
      averageTime: "Tiempo Promedio",
      from: "desde ayer",
      orders: "Pedidos",
      sale: "Venta",
      in: "En",
      out: "Fuera",
      totalPOSTerminalSales: "Ventas Totales en Terminal POS",
      totalOnlineSales: "Ventas Totales en Línea",
      totalCashSales: "Ventas Totales en Efectivo",
    },
    feedbackStatistic: {
      service: "Comentarios del Servicio",
      meal: "Comentarios de la Comida",
      feedBackTitle: "¿Cómo te sientes?",
      feedBackDesc:
        "Tu opinión es valiosa para ayudarnos a comprender mejor tus necesidades y adaptar nuestro servicio en consecuencia.",
      rating: {
        horrible: "Horrible",
        bad: "Malo",
        meh: "Regular",
        good: "Bueno",
        awesome: "Increíble",
      },
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Personal",
        dates: "Fechas",
        item: "Artículo",
        subTotal: "Subtotal",
        discount: "Descuento",
        total: "Total",
        zone: "Zona",
        table: "Mesa",
        service: "Servicio",
        fee: "Tarifa",
        orderDetails: "Detalles del Pedido",
        receipt: "Recibo",
      },
      menuStatisticsTable: {
        menuItem: "Artículo del Menú",
        category: "Categoría",
        cost: "Costo",
        revenue: "Ingresos",
        total: "Total",
        order: "Pedido",
        count: "Cantidad",
        price: "Precio",
        item: "Artículo",
      },
      businessGuestsTable: {
        name: "Nombre",
        gender: "Género",
        dateOfBirth: "Fecha de Nacimiento",
        numberOfVisit: "Número de Visitas",
        guestCount: "Cantidad de Invitados",
        pageSize: "Tamaño de Página",
      },
    },
    pagination: {
      prev: "Anterior",
      next: "Siguiente",
    },
    prevNext: {
      previous: "Anterior",
      next: "Siguiente",
    },
    placeholder: {
      zone: "Zona",
      orderId: "ID del Pedido",
      staff: "Personal",
      itemName: "Nombre del Artículo",
      actionFilter: "Filtro de acción",
      zoneFilter: "Filtro de zona",
      categories: "Categorías",
    },
  },
  login: {
    or: "o",
    facebook: "Con Facebook",
    google: "Con Google",
    pin: "PIN",
    alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
    signIn: {
      title: "Iniciar Sesión",
      subTitle: "¿No tienes una cuenta?",
      method: "Método de inicio de sesión",
    },
    signUp: {
      termsErrorMessage:
        "*Debes aceptar los términos y condiciones para continuar.",
      title: "Registrarse",
      subTitleForUserExist:
        "Ya tienes una cuenta. Por favor, introduce tu contraseña existente.",
      subTitleForUserNotExist:
        "Por favor, introduce la contraseña enviada a: {{emailOrPhone}}",
      alreadyHaveAccount: "¿Ya tienes una cuenta?",
      passwordSentTo: "La contraseña ha sido enviada a",
      setPinSubTitle:
        "Configura un código PIN para el inicio de sesión más fácil",
      agree: "Acepto lo siguiente:",
      terms: "Términos y condiciones",
      privacy: "Política de Privacidad",
      enterPassword: "Introduce tu contraseña",
      setPinSubtitle:
        "Configura un código PIN para el inicio de sesión más fácil",
    },
    forgotPassword: {
      title: "¿Olvidaste tu contraseña?",
      subTitle:
        "Introduce tu {{method}} y te enviaremos tu contraseña nuevamente.",
      sendPassword: "Enviar Contraseña",
      phoneNumber: "número de teléfono",
      email: "correo electrónico",
    },
    resetPassword: {
      title: "Cambiar Contraseña",
      subTitle: "¡Bienvenido de nuevo, te hemos echado de menos!",
    },
    resendPassword: {
      title: "Reenviar Contraseña",
      subTitleForUserExist: "¿Olvidaste tu contraseña actual?",
      subTitleForUserNotExist: "¿No recibiste la contraseña?",
      buttonTextForUserExist: "Enviar nueva contraseña",
      buttonTextForUserNotExist: "Reenviar",
      resendingIsAvailable: "El reenvío está disponible",
      resendingCodeWillBeAvailableIn:
        "El código de reenvío estará disponible en",
    },
  },
  payment: {
    receipt: "Recibo",
    payedBy: "Pagado por",
    onlinePayment: "Pago en línea",
    paymentMessage: "El pago con la tarjeta {{card}} fue exitoso",
    paymentFailMessage: "El pago con la tarjeta {{card}} falló",
    payment: "Pago",
    method: "Método de pago",
    pageTitle: "¿Cómo desea pagar?",
    pageSubtitle: "Seleccione un método para realizar su pago",
    cash: "Efectivo",
    POSTerminal: "Terminal POS",
    ready: "¡Listo en un minuto!",
    confirmPOSTerminal:
      "Solicitud confirmada. El terminal POS será traído por el camarero pronto",
    confirmCash:
      "Solicitud confirmada. Su cuenta será traída por el camarero pronto",
    totalCostFor: "Costo total para",
    printsAndBill: "Impresiones y factura",
    paymentConfirmedTitle: "Su pedido está terminado. Gracias por elegirnos.",
    paymentConfirmedDescription:
      "¡Fue un placer hospedarlo! ¡Nos vemos la próxima vez 👋 Que tenga un buen día 😍",
    businessPaymentsTitle: {
      orderId: "ID de pedido",
      paidPrice: "Monto pagado",
      currency: "Moneda",
      paymentStatus: "Estado de pago",
      lastFourDigits: "Últimos 4 dígitos",
      cardClassification: "Clasificación de tarjeta",
      cardHolderName: "Titular de la tarjeta",
    },
  },
  waiter: {
    waiter: "Camarero",
    waiterLowercase: "camarero",
    call: "Llamar al camarero",
    bill: "Pedir cuenta",
    quickActions: "Acciones rápidas",
    actions: "Acciones",
  },
  mascot: {
    about: "Acerca de {{businessName}}",
    dashboard: {
      support: "Soporte y accesos directos",
      eatIn: "Comer en el lugar",
      eatInHall: "Comer en el salón",
      reserveTable: "Reservar una mesa",
      letUs: "Déjanos hacer ... por ti",
      learnMore: "Aprende más",
      fixProblemText: "¿Tienes problemas con la aplicación?",
      fixProblemButton: "Haz clic aquí para arreglarlo",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "Elige una mesa para crear un pedido",
    selectWaiterToCreateOrder: "Elige una mesa para crear un pedido",
    lastLanguage: "No puedes eliminar el último idioma",
    orderAssignee: "Debes seleccionar un asignado",
    updateFailed: "Error al actualizar la información del huésped",
    required: {
      password: "Se requiere la contraseña",
      confirmPassword: "Se requiere confirmar la contraseña",
      customMessage: "{{name}} es requerido",
      comment: "*Comentario requerido",
    },
    image: "Falló la carga de la imagen",
    addUnit: "Agregar unidad",
    confirmOrders:
      "Tienes trabajo incompleto. Por favor, guárdalo antes de continuar.",
    input: {
      maxCharacter: "*Por favor, ingrese no más de {{max}} caracteres",
      minCharacter: "*Por favor, ingrese al menos {{min}} caracteres",
      maxNumber: "*Por favor, ingrese un número menor que {{max}}",
      minNumber: "*Por favor, ingrese un número mayor que 0",
      invalid: "*{{name}} inválido",
      required: "*{{name}} es requerido",
      generalRequired: "*Este campo es requerido",
      menu: {
        fixedDiscount:
          "*El descuento no puede ser mayor que el precio de venta",
        unFixedDiscount: "*El descuento no puede ser mayor al 100%",
        requiredUnit: "*Por favor, seleccione una unidad para la cantidad dada",
      },
      menuSettings: {
        requiredDefaultLanguage:
          "*Por favor, seleccione un idioma predeterminado",
        requiredAvailableLanguages: "*Por favor, seleccione al menos un idioma",
      },
      pinCode: {
        maxDigits: "*El código PIN debe tener menos de 6 dígitos",
        alreadyExists: "*Este código PIN ya existe",
        notNumeric: "*El código PIN solo debe contener dígitos",
        skipOrSetNow: "*Configura un código PIN ahora, o salta este paso",
        notMatch: "*Los códigos PIN no coinciden",
        incorrect: "*El código PIN es incorrecto",
        TryAgainLater:
          "Ya has superado el límite, por favor intenta nuevamente más tarde.",
      },
      email: {
        alreadyExists: "*Este correo electrónico ya existe",
        incorrect:
          "*Por favor, ingrese una dirección de correo electrónico válida",
        notExists: "*Este correo electrónico no existe",
        TryAgainLater:
          "*Ya has superado el límite, por favor intenta nuevamente más tarde.",
      },
      password: {
        incorrect: "*Contraseña incorrecta",
        notNumeric: "*La contraseña solo debe contener dígitos",
        incorrectLength: "*La contraseña debe tener 6 dígitos",
      },
      phoneNumber: {
        incorrectLength: "*El número de teléfono debe tener 12 dígitos",
        notNumeric: "*El número de teléfono solo debe contener dígitos",
        alreadyExists: "*Este número de teléfono ya existe",
        notExists: "*Este número de teléfono no existe",
        incorrect: "*Por favor, ingrese un número de teléfono válido",
        TryAgainLater:
          "*Ya has superado el límite, por favor intenta nuevamente más tarde.",
      },
      business: {
        serviceFeeMax: "*La tarifa de servicio debe ser menor al 100%",
        serviceFeeMin: "*La tarifa de servicio debe ser mayor a 0%",
        url: "*Por favor, ingrese una URL válida",
      },
      qrAndTable: {
        maxSeat: "El número de asientos debe ser un número entero",
      },
      reservation: {
        maxGuestCount: "El número de invitados debe ser un número entero",
      },
      contact: {
        instagram: "*Por favor, ingrese una dirección válida de Instagram",
      },
      otpCode: {
        OtpIsWrong: "*El código OTP es incorrecto",
        OtpIsNotVerified: "El código OTP no está verificado",
        sendOtp: "El código ha sido enviado a: ",
      },
      oldPassword: {
        OldPasswordIsWrong: "*La contraseña actual es incorrecta",
      },
    },
  },

  toastMessages: {
    success: {
      login: "¡Inicio de sesión exitoso!",
      updateBusinessProfile: "Perfil de negocio actualizado con éxito",
      updateBusinessSettings:
        "Configuraciones del negocio actualizadas con éxito",
      updateTag: "Etiqueta actualizada con éxito",
      createTag: "Etiqueta creada con éxito",
      deleteTag: "Etiqueta eliminada con éxito",
      createReservation: "Reserva creada con éxito",
      deleteReservation: "Reserva eliminada con éxito",
      updateReservation: "Reserva actualizada con éxito",
      updateSpecialCategory: "Categoría especial actualizada con éxito",
      updateCategory: "Categoría actualizada con éxito",
      createCategory: "Categoría creada con éxito",
      deleteCategory: "Categoría eliminada con éxito",
      updateMenuItem: "Elemento del menú actualizado con éxito",
      updateMenu: "Menú actualizado con éxito",
      createMenuItem: "Elemento del menú creado con éxito",
      deleteMenuItem: "Elemento del menú eliminado con éxito",
      duplicateMenuItem: "Elemento del menú duplicado con éxito",
      updateZone: "Zona actualizada con éxito",
      createZone: "Zona creada con éxito",
      deleteZone: "Zona eliminada con éxito",
      updateTable: "Mesa actualizada con éxito",
      updateMenuSettings: "Configuraciones del menú actualizadas con éxito",
      createTable: "Mesa creada con éxito",
      deleteTable: "Mesa eliminada con éxito",
      updateUser: "Usuario actualizado con éxito",
      createUser: "Usuario creado con éxito",
      deleteUser: "Usuario eliminado con éxito",
      updateQR: "QR actualizado con éxito",
      createQR: "QR creado con éxito",
      deleteQR: "QR eliminado con éxito",
      updateOrder: "Pedido actualizado con éxito",
      createOrder: "Pedido creado con éxito",
      deleteOrder: "Pedido eliminado con éxito",
      finishedOrder: "Pedido finalizado con éxito",
      updateInventoryCategory: "Categoría de inventario actualizada con éxito",
      createInventoryCategory: "Categoría de inventario creada con éxito",
      deleteInventoryCategory: "Categoría de inventario eliminada con éxito",
      updateInventoryItem: "Elemento de inventario actualizado con éxito",
      createInventoryItem: "Elemento de inventario creado con éxito",
      deleteInventoryItem: "Elemento de inventario eliminado con éxito",
      updateBusiness: "Negocio actualizado con éxito",
      createBusiness: "Negocio creado con éxito",
      deleteBusiness: "Negocio eliminado con éxito",
      updateLanguage: "Idioma cambiado con éxito",
      deleteOrderHistory: "Historial de pedidos eliminado con éxito",
      resetPassword: "Contraseña restablecida con éxito",
    },
    error: {
      common: "¡Algo salió mal! Por favor, inténtalo de nuevo",
      zones: "No puedes eliminar esta zona porque tiene una mesa",
      unselectedTable: "Debes seleccionar una mesa",
      unselectedZone: "Debes seleccionar una zona",
      deleteCategory:
        "No puedes eliminar esta categoría porque tiene un artículo",
    },
    warning: {
      socials: "Actualmente en desarrollo, gracias por tu comprensión.",
    },
  },
  reservation: {
    reserve: "Reservar",
    reservationTime: "Hora de reserva",
    reservationInfo: "Información de la reserva",
    contactInfo: "Información de contacto",
    enterName: "Nombre",
    enterPhoneNumber: "Número de teléfono",
    bookingList: "Lista de reservas",
    booked: "reservado",
    pending: "Pendiente",
    createReservation: "Crear reserva",
    editReservation: "Editar reserva",
    waiting: "Esperando",
    today: "Hoy",
    guestNumber: "Número de invitados",
    waitingTime: "Tiempo de espera",
    startDate: "Fecha de inicio",
    ReservationDuration: "Duración de la reserva",
    comment: "Comentario",
    numberOfGuest: "Número de invitados",
    startTime: "Hora de inicio",
    duration: "Duración",
    confirmed: "Confirmado",
    denied: "Denegado",
    delete: "Eliminar",
    update: "Actualizar",
    searchRestaurant: "Buscar restaurante",
    tableNotFound: "No seleccionado",
    showMore: "Mostrar más",
    showLess: "Mostrar menos",
    reservations: "Reservas",
    open: "abierto",
    seeMenu: "Ver menú",
    reservation: "Reserva",
    contact: "Contacto",
    place: "Lugar",
    reserveeName: "Nombre del reservante",
    date: "Fecha",
    time: "Hora",
    reservationStatus: "Estado de la reserva",
    reservationDurationHelpText:
      "Nota: Si no eliges la duración, se establecerá automáticamente en 1 hora.",
    justNow: "Hace un momento",
    oneDay: "1 día",
    day: "día",
    hour: "h",
    minute: "min",
    selectedTable: "Mesa seleccionada",
    confirmation: "Confirmar y continuar",
    chooseRestaurant: "Elige restaurante",
    setDate: "¿Cuándo vas a visitarnos?",
    chooseTable: "¿Tienes una mesa preferida?",
    addContact: "¿Quién está visitando?",
    vrLinkCopied: "Enlace de VR copiado",
    statuses: {
      PENDING: "Pendiente",
      CONFIRMED: "Confirmado",
      DENIED: "Cancelado",
      DELETED: "Eliminado",
    },
    vr: {
      browserDoesNotSupport: "Tu navegador no soporta la etiqueta de video.",
      vrTourReservation: "Reserva de recorrido VR",
      explore: "¡Explora y elige tu lugar!",
      vrView: "Vista VR",
      explorevr: "Explora nuestro lugar",
    },
    map: {
      chooseTable: "Elige mesa",
      mapReservation: "Reserva en el mapa",
      chooseLocation: "¡Elige tu ubicación de mesa!",
      tables: "Mesas",
    },
    sentSuccessfully: "Solicitud enviada con éxito",
    successMessage:
      "Las actualizaciones serán enviadas a tu número y podrás revisar tu estado en la página de reservas",
  },
  socials: {
    instagram: "Instagram",
    email: "Correo electrónico",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  },
  weekdays: {
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
  },
  periods: {
    hourly: "Por hora",
    daily: "Diario",
    weekly: "Semanal",
    monthly: "Mensual",
  },

  chat: {
    typeHere: "Escribe aquí...",
    chat: "Chat",
    allMessages: "Todos los mensajes",
    unreadMessages: "Mensajes no leídos",
    selectTopic: "Por favor, selecciona un tema.",
    noMessages: "No hay mensajes.",
  },
  kitchen: {
    stageNew: "Nuevo",
    stageProgress: "Preparando",
    stageDone: "Hecho",
  },
  inventory: {
    productList: "Lista de productos",
    product: "producto",
    stock: "Stock",
    warningAmountInfo:
      "Selecciona la cantidad mínima para ser notificado cuando esté a punto de agotarse",
    productname: "Nombre del producto",
  },
  emptyStates: {
    loading: "Cargando...",
    noPrinters: "No se han agregado impresoras aún",
    noWiFi: "No se ha agregado wifi aún.",
    noOption: "No hay opción",
    noOrder: "Aún no hay pedido",
    noItem: "Aún no hay artículo",
    noLanguage: "No hay idiomas para agregar",
    noTableData: "No hay datos en esta página",
    noOrderedCategory:
      "No hay resultados de artículos pedidos en esta categoría",
    noChat: "No tienes chats debido a la ausencia de pedidos",
    noUnread: "No hay mensajes no leídos.",
    noSearchResults: "No hay resultados de búsqueda",
    noOrders: "No hay pedidos",
    noGuests: "No hay invitados",
    noPendingReservations: "No hay reservas pendientes.",
    noReservationDescription: "Aún no tienes reservas",
    noReservations: "No hay reservas",
    notOpenForReservations: "Este restaurante no está abierto para reservas.",
    noBusiness: "No hay negocio",
    noBusinessContent: "No hay información adicional sobre el negocio.",
    noBusinessDescription:
      "Desafortunadamente, todas las reservas de restaurantes están cerradas.",
  },
  info: {
    itemAmount:
      "El número se refiere a la cantidad del artículo, especificada en unidades como kilogramos (kg), gramos (g), litros (l) o mililitros (ml).",
    itemModificationOptionMaxNumberInfo:
      "El límite máximo de la opción de modificación se refiere al número máximo de estas opciones que se pueden aplicar.",
    general: "Información general",
    detail: "Información detallada",
  },
  stepper: {
    signupMethod: "Método de registro",
    otpVerification: "Verificación OTP",
    userDetail: "Detalles del usuario",
    passwordVerification: "Verificación de contraseña",
    setPinCode: "Código PIN",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Términos y condiciones",
    privacyPolicy: "Política de privacidad",
    allRightReserved: "Todos los derechos reservados.",
    copyRight: "© {{year}} {{value}}.",
    joinUs: "Únete a nosotros {{emoji}}",
    madeWith: "Hecho con {{emoji}} por",
    madeBy: "Hecho por <strong>iLoyal</strong>",
    scanQR: "Escanear QR para un nuevo pedido",
    name: "¡Deja que tus amigos sepan que eres tú!",
    profilePicture: "Tu foto de perfil",
    comingSoon: "PRÓXIMAMENTE",
    underConstruction: "EN CONSTRUCCIÓN",
  },
  optionTypes: {
    None: "Ninguno",
    S: "S",
    M: "M",
    L: "L",
  },
  businesses: {
    allBusinesses: "Todos los negocios",
    specialOffers: "Ofertas especiales",
    mostPopular: "Más popular",
    seaside: "A la orilla del mar",
    coffeeShop: "Cafeterías",
    nationalCuisine: "Cocina nacional",
    nationalCuisineExtended: "Cocina nacional extendida",
    information: "Información",
    search: "Buscar...",
    seeAll: "Ver todo",
    seeLess: "Ver menos",
  },
  policy: {
    termsDataInfo:
      "Al continuar, aceptas los <strong>Términos de servicio y la Política de privacidad</strong>",
    joinUsTermsData: {
      introduction: {
        title: "1. Introducción",
        content: {
          point1:
            "Al usar nuestros servicios, aceptas estos Términos de servicio y nuestra Política de privacidad. Por favor, léelos cuidadosamente.",
        },
      },
      userConsent: {
        title: "2. Consentimiento del usuario",
        content: {
          point1:
            "Al acceder o usar nuestros servicios, aceptas la recopilación, el uso y la compartición de tu información según se describe en estos Términos de servicio y Política de privacidad. Si no estás de acuerdo con estos términos, por favor no uses nuestros servicios.",
        },
      },
      dataCollection: {
        title: "3. Recopilación de datos",
        content: {
          point1:
            "Recopilamos la información que nos proporcionas directamente, como cuando creas una cuenta, realizas una compra o nos contactas para obtener soporte. También podemos recopilar información automáticamente, como tu dirección IP, tipo de navegador y datos de uso.",
        },
      },
      dataUse: {
        title: "4. Uso de los datos",
        content: {
          point1:
            "Tus datos se usan para proporcionar, mantener y mejorar nuestros servicios. Esto incluye procesar transacciones, enviar comunicaciones y personalizar tu experiencia.",
        },
      },
      dataProtection: {
        title: "5. Protección de datos",
        content: {
          point1:
            "Nos comprometemos a proteger tus datos. Implementamos diversas medidas de seguridad para salvaguardar tu información de accesos no autorizados, alteraciones, divulgación o destrucción.",
        },
      },
      dataSharing: {
        title: "6. Compartición de datos",
        content: {
          point1:
            "No vendemos tus datos personales. Podemos compartir información con terceros de confianza que nos ayuden a operar nuestro sitio web, realizar nuestros negocios o proporcionarte servicios, siempre que esas partes acepten mantener esta información confidencial.",
        },
      },
      userRights: {
        title: "7. Derechos del usuario",
        content: {
          point1:
            "Tienes el derecho de acceder, corregir o eliminar tu información personal. También puedes optar por no participar en ciertos usos y divulgaciones de tus datos.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "Al usar nuestros servicios, reconoces que has leído y comprendido estos términos y aceptas estar sujeto a ellos.",
        },
      },
    },
    signUpTermsData: {
      agreement: {
        title: "1. Términos del acuerdo",
        content: {
          point1:
            "1.1 El licenciante debe proporcionar la licencia del software según los términos de este acuerdo, y el cliente debe pagar por esta licencia.",
          point2:
            "1.2 La tarifa mensual de la licencia (suscripción) se determina en función de la variedad de características proporcionadas por iLoyal según las necesidades del cliente. Los cambios en la tarifa de la licencia (suscripción) solo pueden hacerse con un acuerdo mutuo entre el Cliente y iLoyal. El período mensual de la licencia (suscripción) se considera que comienza desde la fecha en que se firma el acuerdo.",
        },
      },
      license: {
        title: "2. Términos de la licencia",
        content: {
          point1:
            "2.1 El licenciante otorga los siguientes derechos bajo los términos de una licencia simple:",
          point2:
            "2.1.1 Asegurar el uso del software y determinar su funcionamiento para el propósito previsto.",
          point3: "2.2 Se prohíbe lo siguiente para la licencia:",
          point4:
            "2.2.1 Extracción del código del software por parte del usuario.",
          point5:
            "2.2.2 Reproducción por parte del usuario, es decir, hacer una o más copias del software.",
          point6: "2.2.3 Revender el software y sus copias.",
          point7: "2.2.4 Hacer el software públicamente disponible.",
        },
      },
      payment: {
        title: "3. Pago de la licencia",
        content: {
          point1:
            "3.1 El cliente acepta pagar el monto especificado en la factura por la(s) licencia(s) descritas en este acuerdo.",
          point2:
            "3.2 Los precios de las licencias se encuentran listados en el sitio web oficial del licenciante.",
          point3:
            "3.3 En caso de terminación del acuerdo entre las partes, la tarifa de la licencia no es negociable ni reembolsable.",
          point4:
            "3.4 Después del pago de la tarifa de la licencia según la factura emitida por el licenciante, se considera que has aceptado los términos del presente Acuerdo de Licencia, y este acuerdo se considera concluido entre tú y el licenciante.",
          point5:
            "3.5 El licenciante se reserva el derecho de modificar unilateralmente este acuerdo.",
          point6:
            "3.6 Una vez que los montos correspondientes sean depositados en la cuenta bancaria del licenciante, todas las obligaciones de pago del cliente se consideran cumplidas.",
        },
      },
      dataProtection: {
        title: "4. Protección de datos",
        content: {
          point1:
            "4.1 Toda la información del cliente está protegida por el licenciante.",
          point2:
            "4.2 Los datos del cliente almacenados en nuestras cuentas en la nube no se comparten con terceros.",
        },
      },
    },
  },
  departments: {
    department: "Departamento",
    categories: "Categorías",
    printers: "Impresoras",
    activeDepartment: "Activar Departamento",
  },
  auth: {
    createAnAccount: "Crear una cuenta",
    register: "Registrarse",
    verifyOtp: "Verificar OTP",
    profile: "Perfil",
    signUp: "Registrarse",
    otpSentTo: "OTP enviado a",
    otpCode: "Código OTP",
    letKnow: "Déjanos conocerte",
    gender: "Género",
    saveAndFinish: "Guardar y terminar",
    message: {
      first: "¡Diviértete!",
      second: "¡Siéntete cómodo!",
      third: "¡No pierdas ofertas!",
      fourth: "¡Ahorra tiempo!",
    },
    genders: {
      male: "Masculino",
      female: "Femenino",
      other: "Otro",
    },
    info: "Información",
    youAlreadyHaveAccount: "Ya tienes una cuenta registrada con ",
    clickButtonToLogin: "Haz clic en este botón para iniciar sesión",
    loginYourAccount: "Iniciar sesión en tu cuenta",
    forgotPassword: "¿Olvidaste la contraseña?",
    forgotPasswordTitle: "Olvidaste la contraseña",
    forgotPasswordDesc:
      "Ingresa tu {{method}} y te enviaremos un código de verificación OTP",
    setNewPassword: "Establecer nueva contraseña",
    setNewPasswordDesc: "Ingresa tu nueva contraseña",
    signUpWith: "O regístrate con",
    withoutSignUp: "Sin registrarse",
    phoneNumber: "número de teléfono",
    email: "correo electrónico",
    loginAccount: "Iniciar sesión en tu cuenta",
    resendingIsAvailable: "Reenvío disponible",
    resendingCodeWillBeAvailableIn:
      "El reenvío del código estará disponible en ",
    createGuestAccountDesc:
      "¡Al registrarte con iLoyal, obtienes la oportunidad de beneficiarte de descuentos y características adicionales!",
  },
  myProfile: {
    title: "Cuenta",
    editProfile: "Editar perfil",
    orderHistory: "Historial de pedidos",
    favorites: "Favoritos",
    chat: "Chat",
    gameHistory: "Historial de juegos",
    helpCenter: "Centro de ayuda",
    settings: "Configuraciones",
    resetPassword: {
      resetPassword: "Restablecer la contraseña",
      setNewPassword: "Establecer nueva contraseña",
      enterCurrentPassword:
        "Para establecer una nueva contraseña, por favor ingresa tu contraseña actual primero.",
      currentPassword: "Contraseña actual *",
      newPassword: "Nueva contraseña *",
    },
  },
  record: {
    talkToMe: "Habla conmigo",
    magicOrder: "Orden mágica",
    magicSearch: "Búsqueda mágica",
    start: "Empezar",
    stop: "Detener",
    magicOffer: "Oferta mágica",
    results: "Resultados",
    addedToBasket: "Añadido a la cesta",
    youCanUseMicrophone: "Puedes usar el micrófono",
    magicOrderError:
      "Tu solicitud no parece estar relacionada con el menú. Por favor, proporciona una consulta más específica relacionada con los artículos del menú o las categorías de alimentos.",
    introductionLoya: "Hola, soy Loya.",
    askMeAnything: "¡Pregúntame cualquier cosa!",
    askLoya: "Pregúntale a Loya",
    loyaIsTyping: "Loya está escribiendo",
  },
  recommend: {
    fitMyBudget: "Platos dentro del presupuesto.",
    tastiestDishes: "Platos baratos y sabrosos.",
    delicious: "Platos deliciosos.",
    smtQuick: "Algo rápido.",
  },
  termsAndCondition: TermsAndCondition,
  privacyPolicy: PrivacyPolicy,
  devices: {
    addPrinter: "Añadir impresora",
    testPrinter: "Probar impresora",
    deletePrinter: "Eliminar impresora",
    connectedDevices: "Dispositivos conectados",
    availableDevices: "Dispositivos disponibles",
    notFound: "¡No encontrado!",
    printingBill: "El pedido se está imprimiendo, por favor espere.",
  },
  printer: {
    unknownBusiness: "Negocio Desconocido",
    awaitingApproval: "PEDIDO EN ESPERA DE APROBACIÓN",
    orderNumber: "Número de Pedido",
    date: "Fecha",
    table: "Mesa",
    waiter: "Camarero",
    unknown: "Desconocido",
    noWaiterAssigned: "Sin Camarero Asignado",
    orderNote: "Nota del Pedido",
    product: "Producto",
    quantity: "Cantidad",
    price: "Precio",
    total: "Total",
    unknownProduct: "Producto Desconocido",
    unknownOption: "Opción Desconocida",
    unknownChoice: "Elección Desconocida",
    currency: "EUR",
    totalAmount: "Monto Total",
    bonAppetit: "Buen provecho..",
    hopeToSeeYouAgain: "Esperamos verte de nuevo :)",
    companyName: "iLoyal ES",
    advancedReceiptSystems: "Sistemas Avanzados de Recibos",
    printError: "Error al enviar a la impresora",
    printSuccess: "Impresión completada con éxito",
  },
  bulkSms: {
    guestSelected: "Invitado seleccionado",
    everyoneSelected: "Todos están seleccionados",
    giveDiscount: "Dar descuento",
    sendSms: "Enviar SMS",
    composeYourMessage: "Redacta tu mensaje",
    typeHere: "Escribe aquí...",
    closeModal: "¿Estás seguro de que quieres cerrar?",
    yourMessageWillBeDeleted: "Tu mensaje será eliminado",
    sendSmsModal: "¿Estás seguro de que quieres enviar el SMS?",
    willBeSent: "Este mensaje será enviado a {{numberOfPeople}} personas",
    successfullySent: "Enviado con éxito",
    successfullySentToPeople:
      "Mensaje enviado con éxito a {{numberOfPeople}} personas",
    operationFailed: "La operación falló",
    operationFailedPeople:
      "No se pudo enviar el mensaje a {{numberOfPeople}} personas",
    no: "No",
    yes: "Sí",
    close: "Cerrar",
    messageIsRequired: "El mensaje es obligatorio",
    sentToEveryone: "El mensaje se enviará a todos",
    sentToEveryoneSuccess: "El mensaje ha sido enviado a todos",
    sentToEveryoneFail: "El mensaje no pudo enviarse a nadie",
  },
  typingEffect: {
    tableStartTopic: "¡Nuevo tema!",
    talkToWaiter: "Habla con el camarero.",
    shareOpinion: "¡Comparte tu opinión!",
    questionsHere: "¡Haz preguntas aquí!",
    chatWithOtherTables: "Chatea con otras mesas",
  },
  gameMessages: {
    findCards: "¡Encuentra las cartas!",
    tastyVictory: "¡Victoria sabrosa!",
    riseBeforeMeal: "¡Sube antes de comer!",
    playAndGetHungry: "¡Juega y te dará hambre!",
    winBeforeMeal: "¡Gana antes de la comida!",
  },
};

export default TRANSLATION;
