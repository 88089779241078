import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconAdd } from "assets/icons/other/AddPhoto.svg";

import "./AddPhotoButton.scss";

export const ENUMS = {
  name: "AddPhotoButton",
};

const AddPhotoButton = ({ onFileSelect, isMultiple = true }) => {
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      onFileSelect(selectedFiles);
      e.target.value = "";
    }
  };

  return (
    <div className="AddPhotoButton">
      <button
        className="AddPhotoButtonInner"
        onClick={handleButtonClick}
        type="button"
      >
        <IconAdd className="AddPhotoButtonIcon" />
        <h5 className="h8 Medium">{t("image.addImage")}</h5>
        <h6 className="h7 Medium">{t("buttons.clickToAddImage")}</h6>
        <h6 className="h8 AddPhotoButtonImageFormat">{t("image.formats")}</h6>
      </button>
      <input
        type="file"
        accept="image/*"
        className="AddPhotoButtonFileInput"
        ref={fileInputRef}
        onChange={handleFileSelect}
        multiple={isMultiple}
      />
    </div>
  );
};

AddPhotoButton.propTypes = {
  /**
   * The function called when a file is selected
   */
  onFileSelect: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
};

export default AddPhotoButton;
