import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AdminWebsocketContext } from "utils/context-api/AdminWebsocketContext";
import useCheckNetwork from "utils/hooks/useCheckNetwork";
import { resetDataAdmin } from "utils/general";
import Button, {
  ENUMS_TYPE as BUTTON_ENUMS,
} from "components/pos/button/Button";
import { ENUMS as AVATAR_WITH_TEXT_ENUMS } from "components/pos/avatar-with-text/AvatarWithText";
import { ReactComponent as WifiIcon } from "assets/icons/pos/wifi.svg";
import { ReactComponent as Socket } from "assets/icons/pos/switch-horizontal-01.svg";
import { ReactComponent as ResetIcon } from "assets/icons/pos/database-01.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/pos/log-out-02.svg";
import Avatar, { ENUMS as AVATAR_ENUMS } from "components/pos/avatar/Avatar";
import { ROUTE_NAME } from "utils/constants/routes";
import Modal, { useModal } from "components/pos/modal/Modal";
import ConfirmModalContent, {
  ENUMS as CONFIRM_MODAL_ENUMS,
} from "components/pos/confirm-modal-content/ConfirmModalContent";

import styles from "./Footer.module.scss";

const Footer = () => {
  const networkStatus = useCheckNetwork();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isConnected } = useContext(AdminWebsocketContext);

  const {
    isVisible: isVisibleConfirmResetData,
    close: closeConfirmResetData,
    open: openConfirmResetData,
  } = useModal();

  const {
    isVisible: isVisibleConfirmLogout,
    close: closeConfirmLogout,
    open: openConfirmLogout,
  } = useModal();

  const handleOnResetConfirm = () => {
    closeConfirmResetData();
    resetDataAdmin({ dispatch, navigate });
  };

  const handleOnLogOutConfirm = () => {
    closeConfirmLogout();
    navigate(ROUTE_NAME.signIn, { replace: true });
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <div className={styles.NetworkContainer}>
          <Avatar
            icon={<WifiIcon />}
            size={AVATAR_ENUMS.SIZE.types.AVATAR48}
            statusType={
              networkStatus
                ? AVATAR_WITH_TEXT_ENUMS.STATUS.types.SUCCESS
                : AVATAR_WITH_TEXT_ENUMS.STATUS.types.ERROR
            }
          />
          <Avatar
            icon={<Socket />}
            size={AVATAR_ENUMS.SIZE.types.AVATAR48}
            statusType={
              isConnected
                ? AVATAR_WITH_TEXT_ENUMS.STATUS.types.SUCCESS
                : AVATAR_WITH_TEXT_ENUMS.STATUS.types.ERROR
            }
          />
        </div>
        <div className={styles.BtnContainer}>
          <Button
            onClick={openConfirmResetData}
            text={t("buttons.resetData")}
            className={styles.Button}
            rightIcon={<ResetIcon />}
            type={BUTTON_ENUMS.Neutral}
          />
          <Button
            onClick={openConfirmLogout}
            text={t("buttons.logOut")}
            className={styles.Button}
            rightIcon={<LogOutIcon />}
            type={BUTTON_ENUMS.LogOut}
          />
        </div>
      </div>
      <Modal isVisible={isVisibleConfirmLogout} close={closeConfirmLogout}>
        <ConfirmModalContent
          type={CONFIRM_MODAL_ENUMS.Error}
          title={t("modal.warningModalTitleLogout")}
          onCancel={closeConfirmLogout}
          onConfirm={handleOnLogOutConfirm}
        />
      </Modal>
      <Modal
        isVisible={isVisibleConfirmResetData}
        close={closeConfirmResetData}
      >
        <ConfirmModalContent
          type={CONFIRM_MODAL_ENUMS.Error}
          title={t("modal.warningModalTitleLogout")}
          onCancel={closeConfirmResetData}
          onConfirm={handleOnResetConfirm}
        />
      </Modal>
    </div>
  );
};

export default Footer;
