import React, { useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import useLanguage from "utils/hooks/useLanguage";

import styles from "./MenuCategoryChips.module.scss";

const activeCategoryPadding = 8;
const MenuCategoryChips = ({ categories, activeCategoryId, onChipClick }) => {
  const { displayDataByLanguage } = useLanguage();
  const categoryHeaderRef = useRef(null);
  const activeCategoryRef = useRef(null);

  useEffect(() => {
    if (categoryHeaderRef.current && activeCategoryRef.current) {
      const categoryHeaderRefWidth = categoryHeaderRef.current.clientWidth;
      const distanceActiveCategoryFromLeft =
        activeCategoryRef.current.offsetLeft;
      const activeCategoryWidth = activeCategoryRef.current.clientWidth;

      categoryHeaderRef.current.scroll({
        left:
          distanceActiveCategoryFromLeft -
          (categoryHeaderRefWidth -
            (activeCategoryWidth - activeCategoryPadding)) /
            2,
        behavior: "smooth",
      });
    }
  }, [activeCategoryId]);

  return (
    <div className={styles.Container}>
      <div ref={categoryHeaderRef} className={styles.Wrapper}>
        {categories.map((category) => {
          const isActive = category.id === activeCategoryId;
          return (
            <div
              ref={isActive ? activeCategoryRef : null}
              key={category.id}
              className={cx(styles.Item, { [styles.isActive]: isActive })}
              onClick={() => onChipClick(category.id)}
            >
              <h6 className={cx(styles.Name, "SemiBold")}>
                {displayDataByLanguage(category.name)}
              </h6>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MenuCategoryChips.propTypes = {
  categories: PropTypes.array.isRequired,
  activeCategoryId: PropTypes.number,
  onChipClick: PropTypes.func.isRequired,
};

export default MenuCategoryChips;
