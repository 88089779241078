import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "swiper/css";
import "./PromotionAndBanner.scss";

export const ENUMS = {
  name: "PromoOrBanner",
  types: {
    PROMOTION: "Promotion",
    BANNER: "Banner",
  },
};

const PromotionAndBanner = ({
  delay = "",
  category,
  type = ENUMS.types.PROMOTION,
}) => {
  const navigate = useNavigate();
  const { menuItemCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business]?.business
  );

  const menuItems = useMemo(() => category?.menuItems || [], [category]);

  const handleInternalLink = (ctaLink) => {
    navigate(ctaLink);
  };

  const handleExternalLink = (ctaLink) => {
    window.open(ctaLink);
  };

  const handleMenuItemNavigation = (menuItemId) => {
    const url = `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${menuItemId}?from=${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`;
    navigate(url);
  };

  const handleAction = ({ ctaLink, ctaType, menuItemId }) => {
    switch (ctaType) {
      case menuItemCategoryType.internal_link:
        handleInternalLink(ctaLink);
        break;
      case menuItemCategoryType.external_link:
        handleExternalLink(ctaLink);
        break;
      default:
        handleMenuItemNavigation(menuItemId);
        break;
    }
  };

  const slides = useMemo(
    () =>
      menuItems.map(
        ({
          ctaType,
          ctaLink,
          id: menuItemId,
          hasCtaAction,
          extraFields: { bannerImage, displayedName, displayedDescription },
        }) => (
          <SwiperSlide key={menuItemId}>
            <div
              className="GuestPromotionAndBannerSwiper"
              onClick={() => {
                if (hasCtaAction) {
                  handleAction({
                    ctaLink,
                    ctaType,
                    menuItemId,
                  });
                }
              }}
            >
              <ImageWithPlaceholder
                imageSource={bannerImage}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt={type === ENUMS.types.BANNER ? "Banner" : "Promotion"}
              />
              <div className="PromoAndBannerDetailsWrapper">
                <p className="PromoAndBannerTitle">{displayedName}</p>
                <p className="PromoAndBannerDescription">
                  {displayedDescription}
                </p>
              </div>
            </div>
          </SwiperSlide>
        )
      ),
    [menuItems, handleAction]
  );

  return (
    <div
      className={cx(
        `GuestPromotionAndBanner ${
          type === ENUMS.types.BANNER ? "Banner" : "Promotion"
        }`,
        {
          Delay: delay,
        }
      )}
      style={{ animationDelay: `${delay}` }}
    >
      <Swiper
        dir={document.documentElement.dir === "rtl" ? "rtl" : "ltr"}
        effect={type === ENUMS.types.BANNER ? "slide" : "fade"}
        navigation={true}
        spaceBetween={16}
        grabCursor={true}
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: type === ENUMS.types.BANNER ? 1500 : 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          pauseOnTouch: true,
        }}
        modules={[EffectFade, Autoplay]}
      >
        {slides}
      </Swiper>
    </div>
  );
};

PromotionAndBanner.propTypes = {
  category: PropTypes.object,
  delay: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
};

export default PromotionAndBanner;
