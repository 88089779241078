import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import { QUERY_PARAMS } from "utils/constants/routes";

const RouteTopScroller = ({ children }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const targetedScroll = searchParams.get(QUERY_PARAMS.targetedScroll) || 0;

  useEffect(() => {
    const scroll = parseInt(targetedScroll, 10);
    window.scrollTo({
      top: scroll,
      behavior: "instant",
    });
    searchParams.delete(QUERY_PARAMS.targetedScroll);
    setSearchParams(searchParams);
  }, [pathname]);

  return children || null;
};

export default RouteTopScroller;

RouteTopScroller.propTypes = {
  children: PropTypes.node,
};
