import { ELECTRON_CHANNEL } from "utils/constants/electron/electron-constants";

export const sendPrintRequestToElectron = (electronAPI, printOptions) => {
  // Send the print request through Electron IPC with the formatted print data
  electronAPI.ipcRenderer.send(
    ELECTRON_CHANNEL.outbound.printRequest,
    printOptions
  );
};

export const sendGetPrintersRequest = (electronAPI) => {
  electronAPI?.ipcRenderer.send(ELECTRON_CHANNEL.outbound.getPrinters);
};

export const listenGetPrinters = (electronAPI, callback) => {
  electronAPI?.ipcRenderer.on(
    ELECTRON_CHANNEL.inbound.printersList,
    (event, data) => {
      //TODO Efe ERROR HANDLER
      callback(data, null);
    }
  );
};

export const removeListener = (electronAPI) => {
  electronAPI?.ipcRenderer.removeAllListeners(
    ELECTRON_CHANNEL.inbound.printersList
  );
};
