import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from "assets/icons/pos/info-circle.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/pos/check-circle.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/pos/x-circle.svg";
import Button, {
  ENUMS_TYPE as BUTTON_ENUMS_TYPE,
} from "components/pos/button/Button";

import styles from "./NotificationModalContent.module.scss";

export const ENUMS_TYPE = {
  Info: "Info",
  Success: "Success",
  Error: "Error",
};

const ICONS = {
  [ENUMS_TYPE.Info]: <InfoIcon />,
  [ENUMS_TYPE.Success]: <SuccessIcon />,
  [ENUMS_TYPE.Error]: <ErrorIcon />,
};

const BUTTONS_TYPE = {
  [ENUMS_TYPE.Info]: BUTTON_ENUMS_TYPE.Primary,
  [ENUMS_TYPE.Success]: BUTTON_ENUMS_TYPE.Success,
  [ENUMS_TYPE.Error]: BUTTON_ENUMS_TYPE.Error,
};

const NotificationModalContent = ({
  className,
  type = ENUMS_TYPE.Info,
  title,
  description,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.Root, styles[type], className)}>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Outer}>
            <div className={styles.Inner}>{ICONS[type]}</div>
          </div>
        </div>
        <div className={styles.Main}>
          <div className={styles.Info}>
            <h1 className="Subtitle1-Pos Medium">{title}</h1>
            <h4 className="Body1-Pos">{description} </h4>
          </div>
        </div>
        <div className={styles.Footer}>
          <Button
            onClick={onClose}
            type={BUTTONS_TYPE[type]}
            text={t("modal.close")}
          />
        </div>
      </div>
    </div>
  );
};

NotificationModalContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(ENUMS_TYPE)),
  description: PropTypes.string.isRequired,
};

export default NotificationModalContent;
