import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as DownIcon } from "assets/icons/arrows/ArrowBottom.svg";

import "./ScrollBottom.scss";

const ScrollBottom = ({
  containerRef,
  initialPosition = 30,
  scrolledPosition = 95,
  scrollTrigger = 400,
  promptResultsHistory = [],
  isFocused = false,
}) => {
  const [bottomOffset, setBottomOffset] = useState(initialPosition);

  const calculateScrollPosition = () => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;
    const scrollPosition = scrollContainer.scrollTop;
    const maxScroll =
      scrollContainer.scrollHeight - scrollContainer.clientHeight;

    return { scrollPosition, maxScroll };
  };

  const handleScroll = () => {
    const { scrollPosition, maxScroll } = calculateScrollPosition();
    maxScroll - scrollPosition > scrollTrigger
      ? setBottomOffset(isFocused ? 159 : scrolledPosition)
      : setBottomOffset(initialPosition);
  };

  useEffect(() => {
    const scrollContainer = containerRef.current;

    if (scrollContainer) {
      const { scrollPosition, maxScroll } = calculateScrollPosition();
      maxScroll - scrollPosition > scrollTrigger
        ? setBottomOffset(isFocused ? 159 : scrolledPosition)
        : setBottomOffset(initialPosition);
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    } else {
      setBottomOffset(initialPosition);
    }
  }, [containerRef, promptResultsHistory, isFocused]);

  const handleScrollToBottom = () => {
    const scrollContainer = containerRef.current;

    scrollContainer &&
      containerRef.current?.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
  };

  return (
    <div
      className="ScrollBottomContainer"
      style={{ bottom: `${bottomOffset}px` }}
      onClick={handleScrollToBottom}
    >
      <DownIcon />
    </div>
  );
};

ScrollBottom.propTypes = {
  containerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  initialPosition: PropTypes.number,
  scrolledPosition: PropTypes.number,
  scrollTrigger: PropTypes.number,
  promptResultsHistory: PropTypes.array,
  isFocused: PropTypes.bool,
};

export default ScrollBottom;
