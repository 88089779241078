export const MP_EVENTS = {
  error: "Error",
  magicOrderPrompt: "Magic order prompt",
  menu: {
    liked: "Menu item like",
    viewChange: "Menu view change",
  },
  clickHamburger: "Click hamburger",
  openSearchModal: "Open search modal",
  openFilterModal: "Open filter modal",
  basket: {
    checkout: "Basket checkout",
    clickConfirmOrder: "Click confirm order",
    clickConfirmCheckbox: "Click confirm checkbox",
    clickCloseModal: "Click close modal",
    viewFavorite: "View favorite",
    viewBasket: "View Basket",
  },
  guestDashboard: {
    clickChat: "GD Click chat",
    clickMenu: "GD Click menu",
    clickFunZone: "GD Click games",
    clickCallWaiter: "GD Click call waiter",
    clickAskForBill: "GD Click ask bill",
  },
  gameSelected: "Game selected",
  pageView: {
    guest: {
      instagramStory: "View Instagram story",
      hamburgerMenu: "View Hamburger menu",
      menuItem: "View menu item",
    },
    admin: {
      statistics: "",
    },
  },
  reservation: {
    start: "RSV start",
    selectTableViaVR: "RSV select table VR",
    selectTableViaMap: "RSV select table map",
    sendRequest: "RSV request sent",
  },

  auth: {
    closeModal: "Auth closed",
    displayModal: "Auth displayed",
    clickSignIn: "Auth click signin",
    clickSignup: "Auth click signup",
    signUpCompleted: "Auth signup completed",
    signInCompleted: "Auth signin completed",
    forgotPassword: "Auth forgot password",
    resetPassword: "Auth reset password",
  },
};

// TOdo remove
export const MP_SOURCE_NAME = {
  guestDashboard: "Guest Dashboard",
  userProfile: "User Profile",
  allBusinesses: "All Businesses",
  businessInfo: "Business Info",
  basket: "Basket",
  menu: "Menu",
};

export const MP_GAME_NAMES = {
  who: "Who",
  memoryCard: "Memory Card",
  ticTacToe: "Tic Tac Toe",
  towerBlock: "Tower Block",
};

export const MP_PAGE_NAMES = {
  auth: "Page view auth",
  signIn: "Page view signIn",
  signUp: "Page view signUp",
  forgotPassword: "Page view forgot password",
  resetPassword: "Page view reset password",
  chat: "Page view chat",
  businessInfo: "Page view business info",
  menu: "Page view menu",
  basket: "Page view basket",
  funZone: "Page view funZone",
  memoryCard: "Page view memory card",
  ticTacToe: "Page view tic tac toe",
  towerBlock: "Page view tower block",
  magicOrder: "Page view magic order",
  myProfileEdit: "Page view my profile edit",
  myProfile: "Page view my profile",
  createReservation: "Page view create reservation",
  myReservations: "Page view my reservation",
  myOrder: "Page view my order",
  feedback: "Page view feedback",
  favorite: "Page view favorite",
};

export const MP_PROP_NAMES = {
  gameName: "gameName",
  source: "source",
  viewType: "viewType",
  billType: "billType",
  billValue: "billValue",
  waiterValue: "waiterValue",
  itemId: "itemId",
  itemName: "itemName",
  numOfItems: "numOfItems",
  totalPrice: "totalPrice",
  idsOfItems: "idsOfItems",
  currency: "currency",
  businessId: "businessId",
  prompt: "prompt",
};
