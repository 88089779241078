import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as IconGuest } from "assets/icons/pos/users-02.svg";
import { ReactComponent as ChatIcon } from "assets/icons/pos/message-text-square-02.svg";

import If from "components/if/If";
import Dropdown from "components/pos/dropdown/Dropdown";
import IconButton, {
  ENUMS_TYPE as ICON_BUTTON_ENUMS,
} from "components/pos/icon-button/IconButton";
import { ADMIN_ORDER_VIEW_MODE_WITH_GUESTS } from "pages/admin/admin-pages/admin-order/admin-order-tables/AdminOrderTables";
import { QUERY_PARAMS } from "utils/constants/routes";

import "./AdminOrderHeader.scss";

const AdminOrderHeader = ({
  business,
  fullTablesOfActiveZone = [],
  handleZoneChange,
  handleOnFilterOrdersByAction,
  activeZone,
  initialZone,
  selectedAction,
  zones,
  orderFilterOptions,
  messageCountOfOrdersByActiveZone,
  showOrderWithGuests,
  orderViewWithGuests,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="AdminOrderHeader">
      <h1 className="AdminOrderHeaderTitle Bold">{`${t(
        "navbarRoutes.orders"
      )} (${fullTablesOfActiveZone.length})`}</h1>

      <div className="AdminOrderHeaderActionButtons">
        <div>
          <Dropdown
            onChange={handleZoneChange}
            name="zones"
            value={activeZone}
            options={initialZone ? [initialZone, ...zones] : zones}
            placeholder={t("table.selectTable")}
            className="NewDropdown"
            search
          />
          <If
            state={
              business?.isAskForBillEnabled || business?.isCallWaiterEnabled
            }
          >
            <Dropdown
              onChange={handleOnFilterOrdersByAction}
              name="actions"
              placeholder={t("dashboard.placeholder.actionFilter")}
              value={selectedAction}
              isMultiple
              options={orderFilterOptions}
              className="NewDropdown"
            />
          </If>
        </div>
        <div>
          <If state={business?.chat}>
            <div className="AdminOrderHeaderChatButton">
              <IconButton
                onClick={() => {
                  setSearchParams({
                    ...searchParams,
                    [QUERY_PARAMS.showDetailedChat]: true,
                  });
                }}
                className="AdminOrderOption"
                type={ICON_BUTTON_ENUMS.Neutral}
                icon={<ChatIcon />}
              />
              <If state={messageCountOfOrdersByActiveZone > 0}>
                <div className="AdminOrderButtonNotifications">
                  <h5 className="SemiBold">
                    {messageCountOfOrdersByActiveZone}
                  </h5>
                </div>
              </If>
            </div>
          </If>

          <div className="AdminOrderHeaderGuestsButton">
            <IconButton
              icon={<IconGuest />}
              onClick={showOrderWithGuests}
              className={`AdminOrderOption ${
                orderViewWithGuests === ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests
                  ? "isActive"
                  : ""
              }`}
              type={ICON_BUTTON_ENUMS.Neutral}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
AdminOrderHeader.propTypes = {
  business: PropTypes.shape({
    isAskForBillEnabled: PropTypes.bool,
    isCallWaiterEnabled: PropTypes.bool,
    chat: PropTypes.bool,
  }),
  fullTablesOfActiveZone: PropTypes.array.isRequired,
  handleZoneChange: PropTypes.func.isRequired,
  handleOnFilterOrdersByAction: PropTypes.func.isRequired,
  activeZone: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  initialZone: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedAction: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  orderFilterOptions: PropTypes.array.isRequired,
  messageCountOfOrdersByActiveZone: PropTypes.number.isRequired,
  showOrderWithGuests: PropTypes.func.isRequired,
  orderViewWithGuests: PropTypes.string.isRequired,
};

AdminOrderHeader.propTypes = {};

export default AdminOrderHeader;
