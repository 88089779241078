import TermsAndCondition from "utils/constants/i18n/TermsAndCondition";
import PrivacyPolicy from "utils/constants/i18n/PrivacyPolicy";

const TRANSLATION = {
  welcome: "Xush kelibsiz (O'zbekcha)",
  common: {
    settings: "Sozlamalar",
    about: "Haqida",
    home: "Bosh sahifa",
    logo: "Logo",
    shape: "Shakl",
    selected: "Tanlangan",
    create: "Yaratish",
    notFound: "Topilmadi",
    applied: "Qo'llanilgan",
    yourLikes: "Sizning yoqtirganlaringiz",
    details: "Tafsilotlar",
    published: "Nashr qilingan",
    unPublished: "Nashr qilinmagan",
    general: "Umumiy",
    address: "Manzil",
    wifi: "Wi-Fi",
    sort: "Saralash",
    copied: "Nusxalandi",
    contact: "Aloqa",
    pages: "Sahifalar",
    kcal: "kkal",
    currency: "Valyuta",
    connect: "Ulashish",
    disconnect: "Ulanishni uzish",
    confirmDisconnect: "Ulanishni uzish istaysizmi?",
    connection: "Aloqa",
    qr: "QR",
    telegram: "Telegram",
    enable: "Faollashtirish",
    disable: "O'chirish",
    zoneAndTable: "Zona va Stol",
    printers: "Printerlar",
    connectToWifi: "Wi-Fi ga ulandi",
    connectWifi: "Wi-Fi ga ulanish",
    disconnectFromWifi: "Wi-Fi dan uzildi",
    name: "Ism",
    active: "Faol",
    inactive: "Faol emas",
    date: {
      date: "Sana",
      time: "Vaqt",
      createdAt: "Yaratilgan sana",
      dateCreated: "Yaratilgan sana",
      dateSelection: "Sana tanlash",
      schedule: "Rejalashtirilgan davrda ishlash",
    },
    time: {
      hour: "soat",
      minute: "daqiqa",
      second: "soniya",
      from: "dan",
      to: "gacha",
      since: "Boshlanishidan beri",
      min: "d",
      shortVersion: {
        hour: "s",
        minute: "d",
        second: "s",
      },
    },
    days: {
      days: "Kunlar",
      monday: "Dushanba",
      tuesday: "Seshanba",
      wednesday: "Chorshanba",
      thursday: "Payshanba",
      friday: "Juma",
      saturday: "Shanba",
      sunday: "Yakshanba",
      everyday: "Har kuni",
      everyWeekday: "Har hafta kunlari",
      weekend: "Hafta oxiri",
    },
  },
  navbarRoutes: {
    magicOrder: "Sehrli buyurtma",
    aiSearch: "AI qidiruvi",
    account: "Hisob",
    order: "Buyurtma",
    statistics: "Statistika",
    dashboard: "Boshqaruv paneli",
    salesStatistics: "Savdo statistikasi",
    orderStatistics: "Buyurtma statistikasi",
    allOrders: "Barcha buyurtmalar",
    menuStatistics: "Menyu statistikasi",
    allItems: "Barcha mahsulotlar",
    feedbacks: "Fikrlar",
    staff: "Xodimlar",
    weeklyStatistics: "Haftalik statistikalar",
    tables: "Stollar",
    menu: "Menyu",
    business: "Biznes",
    infra: "Infra",
    users: "Foydalanuvchilar",
    qrAndTable: "QR va Stol",
    table: "Stol",
    qr: "QR",
    reservation: "Zahira",
    devices: "Qurilmalar",
    map: "Xarita",
    orders: "Buyurtmalar",
    departments: "Bo'limlar",
    superAdmin: "Super Administrator",
    inventory: "Inventarizatsiya",
    funZone: "Kulgili zona",
    home: "Bosh sahifa",
    guests: "Mehmonlar",
    payments: "To'lovlar",
    profile: "Mening profilim",
    ipBlacklist: "IP qora ro‘yxati",
    pageTitles: {
      superAdmin: "SUPER ADMIN",
      infra: "INFRA",
      guests: "MEHMONLAR",
      weeklyStatistics: "HAFTALIK STATISTIKALAR",
      salesStatistics: "SAVDO STATISTIKASI",
      orderStatistics: "BUYURTMA STATISTIKASI",
      menuStatistics: "MENYU STATISTIKASI",
      allOrders: "BARCA BUYURTMALAR",
      departments: "BO'LIMLAR",
      allItems: "BARCA MAHSULOTLAR",
      feedbacks: "FIKRLAR",
      category: "KATEGORIYA",
      settings: "SOZLAMALAR",
      business: "BIZNES",
      users: "FOYDALANUVCHILAR",
      zones: "ZONALAR",
      tables: "STOLLAR",
      qrList: "QR RO'YXATI",
      integrations: "Integratsiyalar",
      payments: "TO'LOVLAR",
      telegramIntegration: "Telegram - integratsiya",
    },
  },
  emptyTable: {
    emptyTableDescription: "Hali hazırda zonalaringiz yo'q.",
    emptyZoneDescription: "Bu zonada hech qanday stollar yo'q.",
    emptyQR: "Hali QR kodlaringiz yo'q.",
    emptyZoneTitle: "Xaritada hech qanday stol yo'q",
  },
  language: {
    language: "Til",
    english: "Inglizcha",
    russian: "Ruscha",
    azerbaijani: "Ozarbayjoncha",
    german: "Nemischa",
  },

  buttons: {
    revive: "Tiriklantirish",
    addItem: "Mahsulot qo'shish",
    editItem: "Mahsulotni tahrirlash",
    addQR: "QR qo'shish",
    back: "Orqaga",
    createQR: "QR yaratish",
    resetData: "Qayta tiklash",
    createZone: "Zona yaratish",
    createOrder: "Buyurtma yaratish",
    edit: "Tahrirlash",
    delete: "O'chirish",
    confirm: "Tasdiqlash",
    finish: "Tugatish",
    discard: "Bekor qilish",
    change: "O'zgartirish",
    deny: "Rad etish",
    save: "Saqlash",
    close: "Yopish",
    saveChanges: "O'zgarishlarni saqlash",
    all: "Barchasi",
    selectAll: "Hammasini tanlash",
    seeAll: "Hammasini ko'rish",
    seeLess: "Kamroq ko'rish",
    add: "Qo'shish",
    addNew: "Yangi qo'shish",
    reset: "Tozalash",
    resend: "Qayta yuborish",
    clearFilters: "Filtrlarni tozalash",
    pay: "To'lash",
    clear: "Tozalash",
    apply: "Qo'llash",
    defaultLanguage: "Standart til",
    defaultCurrency: "Standart valyuta",
    availableLanguages: "Mavjud tillar",
    currency: "Standart valyuta",
    askAi: "AI ga so'rov yuboring",
    link: "Havola",
    links: "Havolalar",
    unlink: "Havolani olib tashlash",
    addBasket: "Savatchaga qo'shish",
    addToOrder: "Buyurtmaga qo'shish",
    clickToAdd: "Qo'shish uchun bosing",
    clickToAddImage: "Rasm qo'shish uchun bosing",
    clickToUpload: "Yuklash uchun bosing",
    logOut: "Chiqish",
    login: "Kirish",
    lockScreen: "Qulf",
    goToDashboard: "Boshqaruv paneliga o'tish",
    rememberMe: "Meni eslab qolish",
    forgotPassword: "Parolni unutdingizmi?",
    payWith: "Bilan to'lash",
    seeMenu: "Menyu ko'rish",
    addOrder: "Yangi buyurtma",
    readMore: "Ko'proq o'qish",
    serve: "Xizmat qilish",
    confirmOrder: "Buyurtmani tasdiqlash",
    backToMenu: "Menyuga qaytish",
    send: "Yuborish",
    tryAgain: "Qaytadan urinib ko'ring",
    update: "Yangilash",
    continue: "Davom etish",
    verify: "Tasdiqlash",
    applyDiscount: "Chegirma qo'llash",
    discountProducts: "Chegirmali mahsulotlar",
    print: "Chop etish",
    export: "Eksport qilish",
    closeOrder: "Buyurtmani tugatish",
    printBill: "Hisobni chop etish",
    fullTables: "To'liq stollar",
    editForModal: "{{title}} ni tahrirlash",
    addForModal: "{{title}} ni qo'shish",
    successfullySent: "Muvaffaqiyatli yuborildi",
    sent: "Yuborildi",
    reserve: "Zahira qilish",
    skip: "O'tkazib yuborish",
    source: "Manba",
    target: "Maqsad",
    editZone: "Zonani tahrirlash",
    contactUs: "Biz bilan bog'laning",
  },
  inputs: {
    name: "Ism",
    username: "Foydalanuvchi nomi",
    description: "Tavsif",
    example: "Misol",
    price: "Narx",
    discount: "Chegirma",
    role: "Rol",
    phoneNumber: "Telefon raqami",
    pinCode: "PIN kodi",
    pinCodeConfirm: "PIN kodini tasdiqlash",
    firstName: "Ismingiz",
    lastName: "Familyangiz",
    email: "Email",
    password: "Parol",
    confirmPassword: "Parolni tasdiqlash",
    newPassword: "Yangi parol",
    oldPassword: "Eski parol",
    businessName: "Biznes nomi",
    city: "Shahar",
    zipCode: "Pochta kodi",
    street: "Ko'cha",
    map: "Google xarita havolasi",
    serviceFee: "Xizmat haqi",
    search: "Qidirish",
    enterPromoCode: "Promo kodni kiriting",
    link: "Havola qo'shish",
    instagram: "Instagram havolasi",
    phone: "Telefon qo'shish",
    contactPhone: "Aloqa telefon raqami",
    whatsapp: "Whatsapp raqami",
    mail: "Email manzilini qo'shish",
    contactMail: "Aloqa email manzili",
    tableName: "Stol nomi",
    usernameExample: "@misol",
    chooseCurrent: "Hozirgi tanlang",
    seats: "O'rindiqlar",
    minDeposit: "Minimal depozit",
    qrName: "QR nomi",
    wifiName: "Wi-Fi nomi",
    sell: "Sotish narxi",
    cost: "Narxi",
    calories: "Kaloriyalar",
    time: "Vaqt",
    amount: "Miqdor",
    unit: "Birlik",
    addComment: "Izoh qo'shish",
    warningAmount: "Ogohlantirish miqdori",
    number: "Raqam",
    notFixed: "Doimiy emas",
    fixed: "Doimiy",
    maxNumber: "Maksimal raqam",
    deposit: "Depozit",
    yourName: "Ismingiz",
    contact: "Aloqa",
    reserveeName: "Zahiralovchi ismi",
    numberOfGuest: "Mehmonlar soni",
    date: "Sana",
    duration: "Davomiylik",
    comment: "Izoh...",
    commentText: "Izoh",
    fullName: "To'liq ism",
    dateOfBirth: "Tug'ilgan sana",
  },
  image: {
    images: "Rasmlar",
    selectCover: "Rasmni bosib, qoplama rasmi sifatida o'rnating",
    background: "Fon rasmi",
    other: "Boshqa rasmlar",
    addPhoto: "Rasm qo'shish",
    addImage: "Rasm qo'shish",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Modifikatsiya nomi",
    modification: "Modifikatsiya",
    modifications: "Modifikatsiyalar",
    noModifications: "Hali hech qanday modifikatsiya qo'shilmagan.",
    noModificationOption: "Hali hech qanday variant qo'shilmagan.",
    addModification: "Modifikatsiya qo'shish",
    editModification: "Modifikatsiyani tahrirlash",
    selection: {
      selection: "Tanlov",
      types: "Tanlov turi",
      singleSelection: "Yagona",
      singleSelectionDescription: "faqat bitta variant tanlanishi mumkin",
      multiSelection: "Ko'p",
      multiSelectionDescription: "bir nechta variantlar tanlanishi mumkin",
      option: {
        options: "Variantlar",
        onlyOnce: "Faqat bir marta",
        manyTimes: "Ko'p marta",
        default: "Standart tanlov",
        addLanguage: "Til qo'shish",
      },
    },
  },

  user: {
    staff: "Xodimlar",
    employee: "Xodim",
    addEmployee: "Xodim qo'shish",
    editEmployee: "Xodimni tahrirlash",
    sendAllDetails: "Yangi parol yaratib, foydalanuvchiga yuborish",
    userDeleteError: "O'zingiz kirgan hisobni o'chirishingiz mumkin emas",
    generatePassword: "Yangi parol yaratib, foydalanuvchiga yuboring.",
    changeYourPassword: "Parolingizni o'zgartiring",
  },
  guests: {
    guest: "Mehmon",
    guests: "Mehmonlar",
    registeredGuest: "Ro'yxatdan o'tgan",
    unregisteredGuest: "Ro'yxatdan o'tmagan",
    allBusinesses: "Barcha bizneslar",
  },
  table: {
    connected: "QR bilan ulangan",
    tablesLowercase: "stollar",
    tables: "Stollar",
    table: "Stol",
    addTable: "Stol qo'shish",
    editTable: "Stolni tahrirlash",
    selectTable: "Stolni tanlash",
    busy: "stollar band",
    available: "stollar mavjud",
    full: "to'liq",
    noTablesVisible: "Hozirda xarita ustida hech qanday stol ko'rinmayapti.",
    map: "Xarita",
    current: "joriy",
  },
  zone: {
    zone: "Zona",
    tables: "Stollar",
    zones: "Zonalar",
    name: "Zona nomi",
    allZones: "Barcha zonalar",
    selectZone: "Zona tanlash",
  },
  map: {
    removeFromMap: "Xaritadan olib tashlash",
    shapes: {
      rectangle: "To'rtburchak",
      circle: "Doira",
    },
    createTable: "Stol yaratish",
    addToMap: "Xaritaga qo'shish",
    windowAndDoor: "Deraza & Eshik",
    designElements: "Dizayn elementlari",
    decoration: "Dekoratsiya",
    reset: "Qayta tiklash",
    width: "Kenglik",
    height: "Balandlik",
  },
  sort: {
    az: "A-Z",
    cheapToExpensive: "Arzonidan qimmatgacha",
    expensiveToCheap: "Qimmatidan arzonigacha",
    new: "Yangi",
  },
  qr: {
    qr: "QR",
    qrCode: "QR kodi",
    name: "QR nomi",
    list: "QR ro'yxati",
    id: "QR ID",
    type: "QR kodi turi",
    status: "Holat",
    unlinked: "Bog'lanmagan",
    linked: "Bog'langan",
    qrIsNotAdded: "QR qo'shilmagan",
    connected: "Ulangan",
    notConnected: "Ulanmagan",
    scanToConnect: "yoki ulanish uchun skanerlang",
    QRLinkCopied: "QR havolasi nusxalandi",
    qrScan: "Ushbu QR-ni skanerlang",
    scanCount: "Skanerlash soni",
    qrScanDescription:
      "* Foydalanuvchining mobil telefonidan skanerlang va Telegram ilovasi o'rnatilganligiga ishonch hosil qiling",
    types: {
      qrType: "QR turi",
      wifi: "Wi-Fi",
      menu: "Menu",
    },
    downloadAllQr: "Barcha QR-larni yuklab olish",
    downloadQr: "QR kodni yuklab olish",
    generateQR: "QR kod yaratish",
    custom: "Moslashtirilgan",
    forEachCategory: "Har bir kategoriya uchun",
    params: "Parametrlar",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "Ha, men buyurtmamni tasdiqlayman va to'lovni amalga oshirishga mas'uliyatni olaman.",
    areYouSureYouWantToPlaceThisOrder:
      "Siz ushbu buyurtmani joylashtirishni xohlaysizmi?",
    deleteModalTitle: "O'chirishga ishonchingiz komilmi?",
    deleteMenuItemTitle:
      "Ushbu mahsulotlarni o'chirishga ishonchingiz komilmi?",
    deleteModalDescription: "Bu amalni qaytarib bo'lmaydi",
    warningModalTitleFinishOrder: "Siz ushbu buyurtmani tugatmoqchimisiz?",
    accessDenied: "Kirish taqiqlangan",
    permissionRequired: "Ruxsatnoma talab qilinadi",
    confirmModalTitleLogOut: "Chiqishga ishonchingiz komilmi?",
    confirmModalDescriptionLogOut: "Istalgan vaqtda qayta kirishingiz mumkin",
    warningModalTitleChangeZone: "Siz ushbu zonani o'zgartirmoqchimisiz?",
    warningModalTitleLogout: "Siz chiqmoqchimisiz?",
    warningModalTitleNewOrder:
      "{{zone}}/{{table}} ustida yangi buyurtma yaratmoqchimisiz? Haqiqatan ham shu qilmoqchimisiz?",
    warningModalDescriptionNewOrder:
      "Yangi buyurtma yaratish uchun iltimos, ha tugmasini bosing",
    warningModalTitleUnsavedChanges:
      "Saqlanmagan o'zgartirishlarni qoldirmoqchimisiz?",
    warningModalDescription: "Sizning tahrirlaringiz saqlanmaydi",
    yes: "Ha",
    no: "Yo'q",
    close: "Yopish",
    apply: "Tasdiqlash",
    id: "id",
    preview: "Oldindan ko'rish",
    cannotBeRemoved: "Tasdiqlangan mahsulotlar o'chirilishi mumkin emas",
    unConfirmedCannotBeRemoved: "Tasdiqlanmagan mahsulotlar to'lanmaydi",
    payMenuItemTitle: "Ushbu tanlovlarni to'lashga ishonchingiz komilmi?",
    guestProfile: "Mehmon profili",
    totalVisits: "Jami tashriflar",
    averageAmount: "O'rtacha summa",
    topOrder: "Eng yaxshi {{count}} buyurtma",
    createdBy: "Yaratuvchi",
    paymentMethod: "To'lov usuli",
    orderId: "Buyurtma ID",
    transactionId: "Tranzaksiya ID",
    visitHistory: "Tashrif tarixi",
    emptyOrderInfo:
      "Buyurtma berish hozirda mavjud emas. Iltimos, QR menyusini yana bir bor skanerlang yoki ofitsiantga murojaat qiling.",
    closeOrderConfirmation: "Buyurtmani qanday yopmoqchisiz?",
  },
  menu: {
    preview: "Menyuni ko'rib chiqish",
    search: "Sevimli taomingizni qidiring...",
    translate: {
      ai: "Sehrli tarjima",
      translating: "Tarjima qilinmoqda...",
      aiAutoTranslate: "AI Avtomatik Tarjima",
      languageSelection: "Tilni tanlash",
      successfulTranslate: "Muvaffaqiyatli tarjima qilindi",
      waitingTranslate:
        "AI menyuni tarjima qilish uchun bir necha daqiqa talab qiladi. Iltimos, hisobga olingki, menyu elementlarini bu vaqt ichida tahrir qilolmaysiz.",
      magicTranslate: "Tarjima qilish",
      itemErrorMessage:
        "Ushbu mahsulot manba tilida mavjud emas. Tarjima qilish uchun, iltimos, mahsulotni manba tilida qo'shing.",
      categoryErrorMessage:
        "Ushbu kategoriya manba tilida mavjud emas. Tarjima qilish uchun, iltimos, kategoriyani manba tilida qo'shing.",
    },
    tag: {
      tag: "Belgi",
      tags: "Belgilar",
      name: "Belgi nomi",
    },
    category: {
      category: "Kategoriyalar",
      categories: "Kategoriyalar",
      add: "Kategoriya qo'shish",
      all: "Barcha kategoriyalar",
      name: "Kategoriya nomi",
      mealCategories: "Taom kategoriyalari",
      emptyGuestMenuTitle: "Menyu Bo'sh",
      emptyGuestMenuDescription:
        "Ko'rinib turibdiki, biznes hali menyuga hech narsa qo'shmagan",
      emptyAdminCategoryDescription:
        "Sizda hali birorta kategoriya mavjud emas.",
    },
    item: {
      items: "Mahsulotlar",
      item: "Mahsulot",
      all: "Barcha mahsulotlar",
      itemLowerCase: "mahsulot",
      itemsLowerCase: "mahsulotlar",
      selected: "Tanlangan mahsulotlar",
      deleteItem: "Mahsulotni o'chirish",
      updateItem: "Mahsulotni yangilash",
      duplicateItem: "Mahsulotni nusxalash",
      duplicate: "Nusxalash",
      scheduleDiscount: "Chegirma rejalashtirish",
      emptyAdminMenuItemDescription: "Sizda hali birorta mahsulot mavjud emas.",
      itemDeleteError:
        "Siz ushbu mahsulotni o'chira olmaysiz, chunki u faol buyurtmada mavjud.",
    },
    view: {
      selection: "Menyuning standart ko'rinishi",
      scroll: "Sahifa bo'ylab aylantirish",
      withImage: "Rasm bilan",
      withoutImage: "Rasm bilan emas",
      list: "Ro'yxat",
      threeD: "3D",
      poker: "Poker",
      grid: "Panorama",
    },
    filter: {
      filters: "Filtrlar",
      priceRange: "Narx oralig'i",
      noResult: "Mos keladigan natijalar yo'q",
      noResultDesc: "Faol filtrlar barcha menyu elementlarini yashirmoqda.",
    },
    settings: {
      color: "Rang",
      radius: "Chegara radiusi",
      roundness: "Yumroqlik",
      currency: "Valyuta",
      colors: {
        primary: "Asosiy ranglar",
        red: "Qizil",
        gold: "Oltin",
        purple: "Binafsha",
        blue: "Ko'k",
      },
      curves: {
        elementCurves: "Element egri chizig'i",
        none: "Yo'q",
        small: "Kichik",
        medium: "O'rta",
        large: "Katta",
      },
      changeProperties: "Xususiyatlarni o'zgartirish",
      details: "Menyu tafsilotlari",
    },
    viewAr: "AR ko'rinishi",
  },
  menuV2: {
    selectLanguage: "Tilni tanlash",
  },
  business: {
    businesses: "Bizneslar",
    list: "Bizneslar ro'yxati",
    staffList: "{{count}} xodim",
    selectTitle: "Biznesni tanlang",
    selectSubTitle:
      "Iltimos, kirish va davom ettirish uchun tanlagan biznesingizni tanlang",
    contact: "Aloqa",
    contactWith: "Aloqa qilmoq",
    workingHours: "Ish vaqt",
    needToKnow: "Bilishingiz kerak",
    addNew: "Biznes qo'shish",
    edit: "Biznesni tahrirlash",
    plan: "Reja",
    selectPlan: "Rejani tanlash",
    creationDate: "Yaratilish sanasi",
    status: "Holat",
    verified: "Tasdiqlangan",
    unverified: "Tasdiqlanmagan",
    businessStatus: "Biznes holati",
    expirationDate: "Amaldan chiqish sanasi",
    country: "Mamlakat",
    printers: "Printerlar",
    wiFi: "Wi-Fi",
    printer: "Printer",
    active: "Faol",
    inActive: "Faol emas",
    connectedTo: "Ulanilgan",
    guestBusinessBlockTitle: "Ushbu biznes faol emas.",
    guestBusinessBlockSubTitle: "Kechirasiz, ushbu biznes endi mavjud emas.",
    settings: {
      goLive: "Jonli efir",
      chat: "Chat",
      realTimeOrder: "Real-vaqt buyurtmalari",
      menuItemCalorie: "Kaloriya",
      menuItemTime: "Vaqt",
      menuItemAmount: "Miqdor",
      reservation: "Rezervatsiya",
      reservationMap: "Rezervatsiyada xaritani ko'rsatish",
      chatSound: "Chat",
      orderSound: "Buyurtma",
      askForBillSound: "Hisobni so'rash",
      callWaiterSound: "Ofitsiantni chaqirish",
      isAskForBillEnabled: "Hisobni so'rash",
      isCallWaiterEnabled: "Ofitsiantni chaqirish",
      menu: "Menyu",
      sound: "Tovush",
      general: "Umumiy",
      isOnlinePaymentEnabled: "To'lov",
      order: "Buyurtma",
      action: "Harakatlar",
      showInBusinessCatalogue: "Biznes katalogida ko'rsatish",
      reservationVRLink: "Virtual havola qo'shish",
      reservationVREnabled: "Virtual ko'rinish",
      reservationMapEnabled: "Xarita ko'rinishi",
      permission: "Ruxsatnomalar",
      declineOrderItemsEnabled: "Buyurtma elementlarini rad etish",
      deleteOrderEnabled: "Buyurtmani o'chirish",
      deleteMenuItemsEnabled: "Menyu elementlarini o'chirish",
      editMenuEnabled: "Menyu tahririni yoqish",
      virtualTour: "Virtual tur",
      vrLink: "Virtual havola qo'shish",
      vrLinkEnabled: "Virtual ko'rinish",
    },
  },
  orderItem: {
    denied: "Rad etilgan",
    deleted: "O'chirilgan",
    empty: "Bo'sh",
  },
  basket: {
    basket: "Savatcha",
    favorites: "Sevimlilar",
    basketAndFavorites: "Savatcha & Sevimlilar",
    addMessage: "Restorana xabar qo'shish",
    messagePlaceholder: "Maxsus so'rov, allergiya, ovqatlanish cheklovlari?",
    itemAdded: "{{count}} mahsulot savatchaga qo'shildi",
    emptyFavorite: "Hali sevimlilaringiz yo'q",
    added: "Qo'shildi",
    order: {
      info: "Buyurtma haqida ma'lumot",
      subtotal: "O'rtacha summa",
      total: "Jami",
      tax: "Soliq",
      promoCode: "Promo kod",
      totalCost: "Jami narx",
      orders: "Buyurtmalar",
      myOrders: "Mening buyurtmam",
      ordersLowerCase: "buyurtmalar",
      orderLowerCase: "buyurtma",
      order: "Buyurtma",
      bill: "Hisob",
      noOrder: "Mavjud buyurtma yo'q",
      noOrders: "Buyurtmalar yo'q.",
      deletedOrderTitle: "Sizning buyurtmangiz o'chirildi",
      deletedOrderDescription:
        "Kechirasiz, sizning buyurtmangiz bizning admin / ofitsiant tomonidan o'chirildi",
      notifications: "Xabarlar",
      updatesToSave: "O'zgartirishlar",
      newOrder: "Yangi Buyurtma",
      deleteOrder: "Buyurtmani o'chirish",
      finishOrder: "Buyurtmani yakunlash",
      done: "Bajarildi",
      preparing: "Tayyorlanmoqda",
      numberOfGuests: "Mehmonlar soni",
      fullNessOfTable: "Jadvalning to'liqligi",
      acceptAll: "Hammasini qabul qilish",
    },
    comment: {
      addMessage: "Restorana xabar qo'shish",
      addMessageDesc: "Maxsus so'rov, allergiya, ovqatlanish cheklovlari?",
    },
    emptyBasketTitle: "Savatchangiz bo'sh",
    emptyBasketDescription:
      "Ko'rinib turibdiki, siz hali savatchaga hech narsa qo'shmagansiz",
  },
  orders: {
    orders: "Buyurtmalar",
    order: "Buyurtma",
    guests: "Mehmonlar",
    guest: "Mehmon",
    fullTables: "To'liq stollar",
  },
  funZone: {
    selectGame: "O'yinni tanlang",
    playGame: "O'yinni o'ynash",
    who: "Kim",
    paysTheBill: "Hisobni kim to'laydi?",
    mindGame: "Xotira",
    canYouRemember: "Eslay olasizmi?",
    tower: "Minora",
    buildYourHighestTower: "Eng baland minorangizni qurish",
    ticTackToe: {
      title: "Tik Tak Toe",
      player: "O'yinchi",
      turnFor: "{{player}} uchun navbat",
      win: "Yutdi",
      outsmartYourOpponent: "Raqibingizni aldang!",
      draw: "Durang",
      points: "Nuqtalar",
    },
    wheelOfFortune: {
      spinnerGame: "Spinner o'yini",
      who: "Kim?",
      whoWillPayTheBill: "Kim hisobni to'laydi?",
      whoWillGoToTheMarket: "Kim bozorga boradi?",
      whoWillBuyCigarettes: "Kim sigaret sotib oladi?",
      whoWillHaveAnotherShot: "Kim yana bir ichimlik ichadi?",
      whoWillGetDessert: "Kim desert oladi?",
      whoWillChooseTheMusic: "Kim musiqa tanlaydi?",
      whoWillSingKaraoke: "Kim karaoke kuylaydi?",
      start: "Boshlash",
      spinning: "Aylantirilmoqda...",
      editToPlay: "O'ynash uchun tahrirlash",
      addNew: "Yangi qo'shish",
      saveAndContinue: "Saqlash va davom ettirish",
      typeHere: "Bu yerga yozing",
      result: "Natija",
      youAreTheChosenOne: "Siz tanlangan odamsiz",
    },
    memoryCardGame: {
      mindGame: "Aql o'yini",
      restart: "Qayta boshlash",
      winMessage: "Siz yutdingiz",
      loseMessage: "Siz yutqazdingiz",
      secondsLeft: "Qolgan sekundlar",
      triesLeft: "Qolgan urinishlar",
      startNewGame: "Yangi o'yinni boshlash",
    },
    towerGame: {
      blocks: "BLOKLAR",
      clickToPlay: "O'ynash uchun bosing",
      clickToRestart: "Qayta boshlash uchun bosing",
    },
  },

  dashboard: {
    dashboard: "Boshqaruv Paneli",
    startTalk: "Suhbatni boshlash",
    sales: "Savdolar",
    salesAnalytics: "Jami savdolar / Soat",
    weekStatistics: "Haftalik Statistikalar",
    statistics: "Statistikalar",
    totalSales: "Jami savdolar",
    paymentMethodAnalysis: "To'lov usuli tahlili",
    byNumberOfOrders: "Buyurtmalar soni bo'yicha",
    byTheAmountOfSales: "Savdolar miqdori bo'yicha",
    byTheNumberOfRatings: "Baholar soni bo'yicha",
    bestselling: "Eng ko'p sotilgan",
    worstSelling: "Eng kam sotilgan",
    processing: "Jarayonda",
    billReady: "Hisob tayyor bo'ladi",
    unSelect: "Barchasini tanlamaslik",
    selectToPay: "To'lashni tanlash uchun bosing",
    denied: "Rad etildi",
    showMore: "Ko'proq ko'rsatish",
    showLess: "...Kamroq ko'rsatish",
    bestSellingCategory: "Eng ko'p sotilgan kategoriya",
    topPerformer: "Eng yaxshi natija ko'rsatuvchi",
    lowPerformer: "Kamroq natija ko'rsatuvchi",
    noResultForThisPeriodMsg: "Ushbu davr uchun natijalar yo'q",
    all: "Barchasi",
    yourTable: "Sizning stolingiz",
    totalGuests: "Jami mehmonlar",
    totalItems: "Jami elementlar",
    deleted: "O'chirilgan",
    weeklySaleStatistics: "Haftalik savdo statistikasi",
    weeklyOrderStatistics: "Haftalik buyurtmalar statistikasi",
    paid: "To'langan",
    inProgress: "Jarayonda",
    paymentInProgress: "To'lov jarayoni davom etmoqda",
    legends: {
      sale: "Savdo",
      revenue: "Daromad",
      orders: "Buyurtmalar soni",
      orderItems: "Elementlar soni",
      totalSale: "Jami savdo",
      starCount: "Yulduzlar soni",
    },
    guest: {
      guests: "Mehmonlar",
      guest: "Mehmon",
      me: "Men",
      review: {
        meal: "Oziq-ovqat",
        service: "Xizmat",
        review: "Sharh",
        sent: "Yuborilgan",
        feedBack: "Fikr-mulohaza",
      },
    },
    staffStatistics: {
      sales: "Savdolar",
      orders: "Buyurtmalar",
      rating: "Baholar",
    },
    orderStatistics: {
      totalSale: "Jami savdo",
      revenue: "Daromad",
      averageBill: "O'rtacha hisob",
      orderCount: "Buyurtmalar soni",
      guestsServed: "Xizmat ko'rsatilgan mehmonlar",
      averageTime: "O'rtacha vaqt",
      from: "kechagi kundan",
      orders: "Buyurtmalar",
      sale: "Savdo",
      in: "Ichida",
      out: "Tashqarida",
      totalPOSTerminalSales: "Jami POS Terminal Savdolari",
      totalOnlineSales: "Jami Onlayn Savdolar",
      totalCashSales: "Jami Naqd Pul Savdolari",
    },
    feedbackStatistic: {
      service: "Xizmat haqidagi fikrlar",
      meal: "Oziq-ovqat haqidagi fikrlar",
      feedBackTitle: "Qanday his qilmoqdasiz?",
      feedBackDesc:
        "Sizning fikr-mulohazalaringiz bizga yaxshiroq tushunish va xizmatni moslashtirishga yordam beradi.",
      rating: {
        horrible: "Yomon",
        bad: "Yomon",
        meh: "O'rtacha",
        good: "Yaxshi",
        awesome: "Ajoyib",
      },
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Xodim",
        dates: "Sana",
        item: "Element",
        subTotal: "Yig'indisi",
        discount: "Chegirma",
        total: "Jami",
        zone: "Zona",
        table: "Stol",
        service: "Xizmat",
        fee: "Hafta haqi",
        orderDetails: "Buyurtma tafsilotlari",
        receipt: "Chek",
      },
      menuStatisticsTable: {
        menuItem: "Menu elementi",
        category: "Kategoriya",
        cost: "Narx",
        revenue: "Daromad",
        total: "Jami",
        order: "Buyurtma",
        count: "Soni",
        price: "Narx",
        item: "Element",
      },
      businessGuestsTable: {
        name: "Ism",
        gender: "Jins",
        dateOfBirth: "Tug'ilgan sana",
        numberOfVisit: "Tashriflar soni",
        guestCount: "Mehmonlar soni",
        pageSize: "Sahifa hajmi",
      },
    },
    pagination: {
      prev: "Oldingi",
      next: "Keyingi",
    },
    prevNext: {
      previous: "Oldingi",
      next: "Keyingi",
    },
    placeholder: {
      zone: "Zona",
      orderId: "Buyurtma ID",
      staff: "Xodim",
      itemName: "Element nomi",
      actionFilter: "Harakat filtri",
      zoneFilter: "Zona filtri",
      categories: "Kategoriyalar",
    },
  },
  login: {
    or: "yoki",
    facebook: "Facebook bilan",
    google: "Google bilan",
    pin: "PIN",
    alreadyHaveAnAccount: "Allaqachon akkauntingiz bormi?",
    signIn: {
      title: "Kirish",
      subTitle: "Akkauntingiz yo'qmi?",
      method: "Kirish usuli",
    },
    signUp: {
      termsErrorMessage:
        "*Davom etish uchun shartlar va qoidalarni qabul qilishingiz kerak.",
      title: "Ro'yxatdan o'tish",
      subTitleForUserExist:
        "Siz allaqachon akkauntingizga egasiz. Iltimos, mavjud parolingizni kiriting.",
      subTitleForUserNotExist:
        "Iltimos, quyidagi manzilga yuborilgan parolni kiriting: {{emailOrPhone}}",
      alreadyHaveAccount: "Allaqachon akkauntingiz bormi?",
      passwordSentTo: "Parol yuborildi",
      setPinSubTitle: "Eng oson kirish uchun PIN kodni o'rnating",
      agree: "Quyidagilarni qabul qilaman:",
      terms: "Shartlar va qoidalar",
      privacy: "Maxfiylik siyosati",
      enterPassword: "Parolingizni kiriting",
      setPinSubtitle: "Eng oson kirish uchun PIN kodni o'rnating",
    },
    forgotPassword: {
      title: "Parolni unutdingizmi?",
      subTitle:
        "Iltimos, {{method}} manzilingizni kiriting va biz parolingizni yana yuboramiz.",
      sendPassword: "Parolni yuborish",
      phoneNumber: "telefon raqami",
      email: "elektron pochta",
    },
    resetPassword: {
      title: "Parolni o'zgartirish",
      subTitle: "Xush kelibsiz, sizni sog'indik!",
    },
    resendPassword: {
      title: "Parolni qayta yuborish",
      subTitleForUserExist: "Mavjud parolingizni unutdingizmi?",
      subTitleForUserNotExist: "Parolni olmadingizmi?",
      buttonTextForUserExist: "Yangi parolni yuborish",
      buttonTextForUserNotExist: "Qayta yuborish",
      resendingIsAvailable: "Qayta yuborish mavjud",
      resendingCodeWillBeAvailableIn:
        "Qayta yuborish kodi quyidagi vaqtda mavjud bo'ladi",
    },
  },
  payment: {
    receipt: "Chiqim",
    payedBy: "To'lovni amalga oshirgan",
    onlinePayment: "Onlayn to'lov",
    paymentMessage: "To'lov {{card}} bilan muvaffaqiyatli amalga oshirildi",
    paymentFailMessage: "To'lov {{card}} bilan amalga oshirilmadi",
    payment: "To'lov",
    method: "To'lov usuli",
    pageTitle: "Qanday to'lashni xohlaysiz?",
    pageSubtitle: "To'lovni amalga oshirish uchun usulni tanlang",
    cash: "Naqd pul",
    POSTerminal: "POS-terminal",
    ready: "Bir daqiqada tayyor!",
    confirmPOSTerminal:
      "So'rov tasdiqlandi. POS-terminal tez orada ofitsiant tomonidan olib kelinadi",
    confirmCash:
      "So'rov tasdiqlandi. Hisobingiz tez orada ofitsiant tomonidan olib kelinadi",
    totalCostFor: "Jami xarajatlar",
    printsAndBill: "Chiqim va chek",
    paymentConfirmedTitle:
      "Buyurtmangiz yakunlandi. Bizni tanlaganingiz uchun rahmat.",
    paymentConfirmedDescription:
      "Sizni kutib olish biz uchun mamnuniyat edi! Keyingi safar ko'rishguncha 👋 Yaxshi kun tilaymiz 😍",
    businessPaymentsTitle: {
      orderId: "Buyurtma ID",
      paidPrice: "To'langan summa",
      currency: "Valyuta",
      paymentStatus: "To'lov holati",
      lastFourDigits: "So'nggi 4 raqam",
      cardClassification: "Kartani tasnifi",
      cardHolderName: "Karta egasi",
    },
  },
  waiter: {
    waiter: "Ofitsiant",
    waiterLowercase: "ofitsiant",
    call: "Ofitsiantni chaqirish",
    bill: "Hisobni so'rash",
    quickActions: "Tezkor harakatlar",
    actions: "Harakatlar",
  },
  mascot: {
    about: "{{businessName}} haqida",
    dashboard: {
      support: "Qo'llab-quvvatlash va qisqa yo'llar",
      eatIn: "Ichkarida ovqatlanish",
      eatInHall: "Ichkarida ovqatlanish zali",
      reserveTable: "Stolni bron qilish",
      letUs: "Bizni ... qilishga ruxsat bering",
      learnMore: "Batafsil o'qing",
      fixProblemText: "Ilovada muammolarga duch keldingizmi?",
      fixProblemButton: "Muammoni hal qilish uchun bu yerni bosing",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "Buyurtma yaratish uchun stolni tanlang",
    selectWaiterToCreateOrder: "Buyurtma yaratish uchun ofitsiantni tanlang",
    lastLanguage: "Oxirgi tildan voz kechib bo'lmaydi",
    orderAssignee: "Mas'ulni tanlashingiz kerak",
    updateFailed: "Mehmon ma'lumotlarini yangilashda xatolik yuz berdi",
    required: {
      password: "Parol talab etiladi",
      confirmPassword: "Parolni tasdiqlash talab etiladi",
      customMessage: "{{name}} talab etiladi",
      comment: "*Izoh talab etiladi",
    },
    image: "Rasmni yuklashda xatolik yuz berdi",
    addUnit: "Birlik qo'shish",
    confirmOrders:
      "Sizda tugallanmagan ish mavjud. Iltimos, davom etishdan oldin uni saqlang.",
    input: {
      maxCharacter: "*Iltimos, {{max}} dan ko'p belgi kiritmang",
      minCharacter: "*Iltimos, kamida {{min}} belgi kiriting",
      maxNumber: "*Iltimos, {{max}} dan kichik raqam kiriting",
      minNumber: "*Iltimos, 0 dan katta raqam kiriting",
      invalid: "*Noto'g'ri {{name}}",
      required: "*{{name}} talab etiladi",
      generalRequired: "*Bu maydon talab etiladi",
      menu: {
        fixedDiscount: "*Chegirma sotish narxidan katta bo'lmasligi kerak",
        unFixedDiscount: "*Chegirma 100% dan katta bo'lmasligi kerak",
        requiredUnit: "*Berilgan miqdor uchun birlikni tanlang",
      },
      menuSettings: {
        requiredDefaultLanguage: "*Iltimos, birinchi tilni tanlang",
        requiredAvailableLanguages: "*Iltimos, kamida bitta tilni tanlang",
      },
      pinCode: {
        maxDigits: "*PIN kodi 6 raqamdan kichik bo'lishi kerak",
        alreadyExists: "*Ushbu PIN kodi allaqachon mavjud",
        notNumeric: "*PIN kodi faqat raqamlardan iborat bo'lishi kerak",
        skipOrSetNow:
          "*Endi PIN kodi o'rnating yoki ushbu bosqichni o'tkazib yuboring",
        notMatch: "*PIN kodlari mos kelmaydi",
        incorrect: "*PIN kodi noto'g'ri",
        TryAgainLater:
          "Siz allaqachon limitni oshirdingiz, iltimos, keyinroq qayta urinib ko'ring.",
      },
      email: {
        alreadyExists: "*Ushbu elektron pochta manzili allaqachon mavjud",
        incorrect: "*Iltimos, to'g'ri elektron pochta manzilini kiriting",
        notExists: "*Ushbu elektron pochta manzili mavjud emas",
        TryAgainLater:
          "*Siz allaqachon limitni oshirdingiz, iltimos, keyinroq qayta urinib ko'ring.",
      },
      password: {
        incorrect: "*Parol noto'g'ri",
        notNumeric: "*Parol faqat raqamlardan iborat bo'lishi kerak",
        incorrectLength: "*Parol 6 raqamdan iborat bo'lishi kerak",
      },
      phoneNumber: {
        incorrectLength: "*Telefon raqami 12 raqamdan iborat bo'lishi kerak",
        notNumeric: "*Telefon raqami faqat raqamlardan iborat bo'lishi kerak",
        alreadyExists: "*Ushbu telefon raqami allaqachon mavjud",
        notExists: "*Ushbu telefon raqami mavjud emas",
        incorrect: "*Iltimos, to'g'ri telefon raqamini kiriting",
        TryAgainLater:
          "*Siz allaqachon limitni oshirdingiz, iltimos, keyinroq qayta urinib ko'ring.",
      },
      business: {
        serviceFeeMax: "*Xizmat haqi 100% dan kam bo'lishi kerak",
        serviceFeeMin: "*Xizmat haqi 0% dan katta bo'lishi kerak",
        url: "*Iltimos, to'g'ri URL manzilini kiriting",
      },
      qrAndTable: {
        maxSeat: "O'rindiqlar soni butun son bo'lishi kerak",
      },
      reservation: {
        maxGuestCount: "Mehmonlar soni butun son bo'lishi kerak",
      },
      contact: {
        instagram: "*Iltimos, to'g'ri Instagram manzilini kiriting",
      },
      otpCode: {
        OtpIsWrong: "*OTP noto'g'ri",
        OtpIsNotVerified: "OTP tasdiqlanmagan",
        sendOtp: "Kod yuborildi: ",
      },
      oldPassword: {
        OldPasswordIsWrong: "*Joriy parol noto'g'ri",
      },
    },
  },

  toastMessages: {
    success: {
      login: "Kirish muvaffaqiyatli!",
      updateBusinessProfile: "Biznes profili muvaffaqiyatli yangilandi",
      updateBusinessSettings: "Biznes sozlamalari muvaffaqiyatli yangilandi",
      updateTag: "Teg muvaffaqiyatli yangilandi",
      createTag: "Teg muvaffaqiyatli yaratildi",
      deleteTag: "Teg muvaffaqiyatli o'chirildi",
      createReservation: "Rezervatsiya muvaffaqiyatli yaratildi",
      deleteReservation: "Rezervatsiya muvaffaqiyatli o'chirildi",
      updateReservation: "Rezervatsiya muvaffaqiyatli yangilandi",
      updateSpecialCategory: "Maxsus kategoriya muvaffaqiyatli yangilandi",
      updateCategory: "Kategoriya muvaffaqiyatli yangilandi",
      createCategory: "Kategoriya muvaffaqiyatli yaratildi",
      deleteCategory: "Kategoriya muvaffaqiyatli o'chirildi",
      updateMenuItem: "Menyu elementi muvaffaqiyatli yangilandi",
      updateMenu: "Menyu muvaffaqiyatli yangilandi",
      createMenuItem: "Menyu elementi muvaffaqiyatli yaratildi",
      deleteMenuItem: "Menyu elementi muvaffaqiyatli o'chirildi",
      duplicateMenuItem: "Menyu elementi muvaffaqiyatli ko'paytirildi",
      updateZone: "Zona muvaffaqiyatli yangilandi",
      createZone: "Zona muvaffaqiyatli yaratildi",
      deleteZone: "Zona muvaffaqiyatli o'chirildi",
      updateTable: "Stol muvaffaqiyatli yangilandi",
      updateMenuSettings: "Menyu sozlamalari muvaffaqiyatli yangilandi",
      createTable: "Stol muvaffaqiyatli yaratildi",
      deleteTable: "Stol muvaffaqiyatli o'chirildi",
      updateUser: "Foydalanuvchi muvaffaqiyatli yangilandi",
      createUser: "Foydalanuvchi muvaffaqiyatli yaratildi",
      deleteUser: "Foydalanuvchi muvaffaqiyatli o'chirildi",
      updateQR: "QR muvaffaqiyatli yangilandi",
      createQR: "QR muvaffaqiyatli yaratildi",
      deleteQR: "QR muvaffaqiyatli o'chirildi",
      updateOrder: "Buyurtma muvaffaqiyatli yangilandi",
      createOrder: "Buyurtma muvaffaqiyatli yaratildi",
      deleteOrder: "Buyurtma muvaffaqiyatli o'chirildi",
      finishedOrder: "Buyurtma muvaffaqiyatli yakunlandi",
      updateInventoryCategory: "Inventar kategoriya muvaffaqiyatli yangilandi",
      createInventoryCategory: "Inventar kategoriya muvaffaqiyatli yaratildi",
      deleteInventoryCategory: "Inventar kategoriya muvaffaqiyatli o'chirildi",
      updateInventoryItem: "Inventar elementi muvaffaqiyatli yangilandi",
      createInventoryItem: "Inventar elementi muvaffaqiyatli yaratildi",
    },
    error: {
      common: "Biror narsa noto'g'ri ketdi! Iltimos, qaytadan urinib ko'ring",
      zones: "Siz bu zonani o'chira olmaysiz, chunki unda stol bor",
      unselectedTable: "Stolni tanlashingiz kerak",
      unselectedZone: "Zona tanlashingiz kerak",
      deleteCategory:
        "Siz bu kategoriyani o'chira olmaysiz, chunki unda mahsulot bor",
    },
    warning: {
      socials: "Hozirda ishlab chiqilmoqda, tushunishingiz uchun rahmat.",
    },
  },
  reservation: {
    reserve: "Rezervatsiya",
    reservationTime: "Rezervatsiya vaqti",
    reservationInfo: "Rezervatsiya ma'lumoti",
    contactInfo: "Aloqa ma'lumoti",
    enterName: "Ismni kiriting",
    enterPhoneNumber: "Telefon raqamini kiriting",
    bookingList: "Buyurtma ro'yxati",
    booked: "Buyurtma qilingan",
    pending: "Kutilyapti",
    createReservation: "Rezervatsiya yaratish",
    editReservation: "Rezervatsiyani tahrirlash",
    waiting: "Kutish",
    today: "Bugun",
    guestNumber: "Mehmon",
    waitingTime: "Kutish",
    startDate: "Boshlash",
    ReservationDuration: "Rezervatsiya davomiyligi",
    comment: "Izoh",
    numberOfGuest: "Mehmonlar soni",
    startTime: "Boshlanish vaqti",
    duration: "Davomiylik",
    confirmed: "Tasdiqlandi",
    denied: "Rad etildi",
    delete: "O'chirish",
    update: "Yangilash",
    searchRestaurant: "Restoran qidirish",
    tableNotFound: "Stol tanlanmagan",
    showMore: "Ko'proq ko'rsatish",
    showLess: "Kamroq ko'rsatish",
    reservations: "Rezervatsiyalar",
    open: "Ochilgan",
    seeMenu: "Menyuni ko'rish",
    reservation: "Rezervatsiya",
    contact: "Aloqa",
    place: "Joy",
    reserveeName: "Rezervatsiya qilgan shaxsning ismi",
    date: "Sana",
    time: "Vaqt",
    reservationStatus: "Rezervatsiya holati",
    reservationDurationHelpText:
      "Eslatma: Agar davomiylikni tanlamasangiz, u avtomatik ravishda 1 soat qilib belgilanadi.",
    justNow: "Endi",
    oneDay: "1 kun",
    day: "kun",
    hour: "soat",
    minute: "daqiq",
    selectedTable: "Tanlangan stol",
    confirmation: "Tasdiqlash va davom etish",
    chooseRestaurant: "Restoranni tanlang",
    setDate: "Qachon kelmoqchisiz?",
    chooseTable: "Tanlangan stolidan bor?",
    addContact: "Kim tashrif buyuradi?",
    vrLinkCopied: "VR havolasi nusxalandi",
    statuses: {
      PENDING: "Kutilyapti",
      CONFIRMED: "Tasdiqlandi",
      DENIED: "Bekor qilindi",
      DELETED: "O'chirildi",
    },
    vr: {
      browserDoesNotSupport: "Brauzeringiz video tegini qo'llab-quvvatlamaydi.",
      vrTourReservation: "VR tur rezervatsiyasi",
      explore: "Kashf eting va joyingizni tanlang!",
      vrView: "VR ko'rinishi",
      explorevr: "Bizning joyimizni kashf eting",
    },
    map: {
      chooseTable: "Stolni tanlang",
      mapReservation: "Xarita orqali rezervatsiya",
      chooseLocation: "Joylashuvni tanlang!",
      tables: "Stollar",
    },
    sentSuccessfully: "So'rov muvaffaqiyatli yuborildi",
    successMessage:
      "Yangilanishlar sizning raqamingizga yuboriladi, va siz rezervatsiya sahifasida holatingizni tekshirishingiz mumkin",
  },
  socials: {
    instagram: "Instagram",
    email: "Elektron pochta",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "Yanvar",
    february: "Fevral",
    march: "Mart",
    april: "Aprel",
    may: "May",
    june: "Iyun",
    july: "Iyul",
    august: "Avgust",
    september: "Sentabr",
    october: "Oktyabr",
    november: "Noyabr",
    december: "Dekabr",
  },
  weekdays: {
    monday: "Dushanba",
    tuesday: "Seshanba",
    wednesday: "Chorshanba",
    thursday: "Payshanba",
    friday: "Juma",
    saturday: "Shanba",
    sunday: "Yakshanba",
  },
  periods: {
    hourly: "Soatlik",
    daily: "Kunlik",
    weekly: "Haftalik",
    monthly: "Oylik",
  },

  chat: {
    typeHere: "Bu yerga yozing...",
    chat: "Chat",
    allMessages: "Barcha Xabarlar",
    unreadMessages: "O'qilmagan Xabarlar",
    selectTopic: "Iltimos, mavzuni tanlang.",
    you: "Sen",
    noMessages:
      "Hali xabar yo‘q. Suhbatni boshlash uchun birinchi xabarni yuboring!",
  },
  kitchen: {
    stageNew: "Yangi",
    stageProgress: "Tayyorlanmoqda",
    stageDone: "Tayyor",
  },
  inventory: {
    productList: "Mahsulotlar ro'yxati",
    product: "Mahsulot",
    stock: "Zaxira",
    warningAmountInfo:
      "Tugab qolishiga yaqin bo'lsa, ogohlantirish uchun minimal miqdorni tanlang",
    productname: "Mahsulot nomi",
  },
  emptyStates: {
    loading: "Yuklanmoqda...",
    noPrinters: "Hali printerlar qo'shilmagan",
    noWiFi: "Hali Wi-Fi tarmog'i qo'shilmagan.",
    noOption: "Tanlash uchun hech qanday variant yo'q",
    noOrder: "Hali buyurtma yo'q",
    noItem: "Hali mahsulot yo'q",
    noLanguage: "Qo'shish uchun hech qanday til yo'q",
    noTableData: "Bu sahifada ma'lumotlar yo'q",
    noOrderedCategory: "Bu kategoriyada buyurtma qilingan mahsulotlar yo'q",
    noChat: "Buyurtmalar yo'qligi sababli sizda chatlar yo'q",
    noUnread: "O'qilmagan xabarlar yo'q.",
    noSearchResults: "Qidiruv natijalari yo'q",
    noOrders: "Buyurtmalar yo'q",
    noGuests: "Mehmonlar yo'q",
    noPendingReservations: "Kutilayotgan rezervatsiyalar yo'q.",
    noReservationDescription: "Hali sizda rezervatsiyalar yo'q",
    noReservations: "Rezervatsiyalar yo'q",
    notOpenForReservations: "Bu restoran rezervatsiyalar uchun ochiq emas.",
    noBusiness: "Biznes yo'q",
    noBusinessContent: "Biznes haqida qo'shimcha ma'lumot yo'q.",
    noBusinessDescription:
      "Afsuski, barcha restoran rezervatsiyalari yopilgan.",
  },
  info: {
    itemAmount:
      "Raqam mahsulotning miqdorini anglatadi, kilogramm (kg), gramm (g), litr (l) yoki millilitr (ml) kabi birliklarda ko'rsatilgan.",
    itemModificationOptionMaxNumberInfo:
      "Modifikatsiya variantining maksimal cheklovi bu variantlarning qo'llanilishi mumkin bo'lgan maksimal sonini anglatadi.",
    general: "Umumiy ma'lumot",
    detail: "Tafsilotli ma'lumot",
  },
  stepper: {
    signupMethod: "Ro'yxatdan o'tish usuli",
    otpVerification: "OTP tasdiqlash",
    userDetail: "Foydalanuvchi ma'lumotlari",
    passwordVerification: "Parol tasdiqlash",
    setPinCode: "PIN kodi",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Foydalanish shartlari",
    privacyPolicy: "Maxfiylik siyosati",
    allRightReserved: "Barcha huquqlar himoyalangan.",
    copyRight: "© {{year}} {{value}}.",
    joinUs: "Bizga qo'shiling {{emoji}}",
    madeWith: "Yaratildi {{emoji}} bilan",
    madeBy: "Yaratildi <strong>iLoyal</strong> tomonidan",
    scanQR: "Yangi buyurtma uchun QR kodni skanerlash",
    name: "Do'stlaringizga bu siz ekanligingizni bildiring! ",
    profilePicture: "Profil rasmingiz",
    comingSoon: "Yaqinda keladi",
    underConstruction: "Ishlab chiqilmoqda",
  },
  optionTypes: {
    None: "Hech narsa",
    S: "S",
    M: "M",
    L: "L",
  },
  businesses: {
    allBusinesses: "Barcha bizneslar",
    specialOffers: "Maxsus takliflar",
    mostPopular: "Eng mashhur",
    seaside: "Dengiz bo'yida",
    coffeeShop: "Kofe do'konlari",
    nationalCuisine: "Milliy taomlar",
    nationalCuisineExtended: "Milliy taomlar (uzaytirilgan)",
    information: "Ma'lumot",
    search: "Qidirish...",
    seeAll: "Hammasini ko'rish",
    seeLess: "Kamroq ko'rish",
  },
  policy: {
    termsDataInfo:
      "Davom etish orqali siz <strong>Xizmat ko'rsatish shartlari va Maxfiylik siyosati</strong>ga rozilik bildirasiz.",
    joinUsTermsData: {
      introduction: {
        title: "1. Kirish",
        content: {
          point1:
            "Bizning xizmatlarimizdan foydalanish orqali siz ushbu Xizmat ko'rsatish shartlari va Maxfiylik siyosatini o'qib, rozilik bildirgan bo'lasiz. Iltimos, ularni diqqat bilan o'qing.",
        },
      },
      userConsent: {
        title: "2. Foydalanuvchi roziligi",
        content: {
          point1:
            "Bizning xizmatlarimizga kirish yoki foydalanish orqali siz o'z ma'lumotlaringizni ushbu Xizmat ko'rsatish shartlari va Maxfiylik siyosatida belgilangan tarzda yig'ish, ishlatish va ulashishga rozilik bildirgan bo'lasiz. Agar siz ushbu shartlarga rozilik bildirmasangiz, iltimos, xizmatlarimizdan foydalanmang.",
        },
      },
      dataCollection: {
        title: "3. Ma'lumot yig'ish",
        content: {
          point1:
            "Biz siz tomonidan to'g'ridan-to'g'ri taqdim etilgan ma'lumotlarni yig'amiz, masalan, hisob yaratish, xarid qilish yoki biz bilan bog'lanish. Biz shuningdek, avtomatik ravishda, masalan, IP manzilingiz, brauzer turi va foydalanuvchi ma'lumotlarini yig'ishimiz mumkin.",
        },
      },
      dataUse: {
        title: "4. Ma'lumotlarni ishlatish",
        content: {
          point1:
            "Sizning ma'lumotlaringiz xizmatlarimizni taqdim etish, saqlash va yaxshilash uchun ishlatiladi. Bu tranzaksiyalarni qayta ishlash, muloqot yuborish va tajribangizni moslashtirishni o'z ichiga oladi.",
        },
      },
      dataProtection: {
        title: "5. Ma'lumotlarni himoya qilish",
        content: {
          point1:
            "Biz sizning ma'lumotlaringizni himoya qilishga qaror qilamiz. Biz ma'lumotlaringizni ruxsatsiz kirish, o'zgartirish, oshkor qilish yoki yo'q qilishdan himoya qilish uchun turli xavfsizlik choralarini ko'ramiz.",
        },
      },
      dataSharing: {
        title: "6. Ma'lumotlarni ulashish",
        content: {
          point1:
            "Biz sizning shaxsiy ma'lumotlaringizni sotmaymiz. Biz ba'zi ishonchli uchinchi tomonlar bilan ma'lumotlarni ulashishimiz mumkin, ular bizning veb-saytimizni boshqarish, biznesni amalga oshirish yoki sizga xizmatlar ko'rsatishda yordam beradi, shartki, ular ushbu ma'lumotlarni maxfiy saqlashga rozilik berishadi.",
        },
      },
      userRights: {
        title: "7. Foydalanuvchi huquqlari",
        content: {
          point1:
            "Sizning shaxsiy ma'lumotlaringizga kirish, to'g'irlash yoki o'chirish huquqiga egasiz. Shuningdek, ba'zi ma'lumotlarni ishlatish va oshkor qilishni to'xtatishingiz mumkin.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "Bizning xizmatlarimizdan foydalanish orqali siz ushbu shartlarni o'qib chiqqaningizni va ularga rozilik bildirishni tasdiqlaysiz.",
        },
      },
    },
    signUpTermsData: {
      agreement: {
        title: "1. Shartnoma shartlari",
        content: {
          point1:
            "1.1 Litsenziar ushbu shartnoma shartlari asosida dasturiy ta'minot litsenziyasini taqdim etishi kerak va mijoz ushbu litsenziya uchun to'lovni amalga oshirishi kerak.",
          point2:
            "1.2 Oylik litsenziya (obuna) to'lovi iLoyal tomonidan mijozning ehtiyojlariga qarab taqdim etiladigan xususiyatlar xilma-xilligiga asoslanadi. Litsenziya (obuna) to'lovini faqat mijoz va iLoyal o'rtasidagi o'zaro kelishuv asosida o'zgartirish mumkin. Oylik litsenziya (obuna) muddati shartnoma imzolangan sanadan boshlab boshlanadi.",
        },
      },
      license: {
        title: "2. Litsenziya shartlari",
        content: {
          point1: "2.1 Litsenziar quyidagi huquqlarni taqdim etadi:",
          point2:
            "2.1.1 Dasturiy ta'minotdan foydalanishni ta'minlash va uning ishlashini maqsadga muvofiq belgilash.",
          point3: "2.2 Litsenziya uchun quyidagi harakatlar TA'QIQLANADI:",
          point4:
            "2.2.1 Foydalanuvchi tomonidan dasturiy ta'minot kodini chiqarish.",
          point5:
            "2.2.2 Foydalanuvchi tomonidan dasturiy ta'minotni nusxalash.",
          point6: "2.2.3 Dasturiy ta'minotni va uning nusxalarini sotish.",
          point7: "2.2.4 Dasturiy ta'minotni jamoatchilikka taqdim etish.",
        },
      },
      payment: {
        title: "3. Litsenziya to'lovi",
        content: {
          point1:
            "3.1 Mijoz ushbu shartnomada ko'rsatilgan litsenziya(lar) uchun fakturadagi miqdorni to'lashga rozidir.",
          point2:
            "3.2 Litsenziyalar narxlari litsenziarning rasmiy veb-saytida e'lon qilingan.",
          point3:
            "3.3 Tomonlar o'rtasidagi shartnoma tugatilgan taqdirda, litsenziya to'lovi qaytarilmaydi va muzokaralarga yaroqsiz.",
          point4:
            "3.4 Litsenziya to'lovi litsenziar tomonidan chiqarilgan faktura bo'yicha to'langanidan so'ng, siz ushbu Litsenziya shartnomasining shartlariga rozilik bildirgan hisoblanasiz va ushbu shartnoma siz va litsenziar o'rtasida tuzilgan deb hisoblanadi.",
          point5:
            "3.5 Litsenziar ushbu shartnomani bir tomonlama o'zgartirish huquqini o'zida saqlaydi.",
          point6:
            "3.6 Tegishli miqdor litsenziarning bank hisob raqamiga to'lanishi bilan, mijozning barcha to'lov majburiyatlari bajarilgan hisoblanadi.",
        },
      },
      dataProtection: {
        title: "4. Ma'lumotlarni himoya qilish",
        content: {
          point1:
            "4.1 Barcha mijoz ma'lumotlari litsenziar tomonidan himoya qilinadi.",
          point2:
            "4.2 Mijozning ma'lumotlari bizning bulut hisoblarimizda uchinchi shaxslarga ulashilmaydi.",
        },
      },
    },
  },
  departments: {
    department: "Bo'lim",
    categories: "Kategoriyalar",
    printers: "Printerlar",
    activeDepartment: "Bo'limni faollashtirish",
  },
  auth: {
    createAnAccount: "Hisob yaratish",
    register: "Ro'yxatdan o'tish",
    verifyOtp: "OTPni tasdiqlash",
    profile: "Profil",
    signUp: "Ro'yxatdan o'tish",
    otpSentTo: "OTP yuborildi",
    otpCode: "OTP kodi",
    letKnow: "O'zingizni tanishtiring",
    gender: "Jins",
    saveAndFinish: "Saqlash va tugatish",
    message: {
      first: "Qiziqish bilan!",
      second: "Quvnoq bo'ling!",
      third: "Taklifni ushlang!",
      fourth: "Vaqtni tejang!",
    },
    genders: {
      male: "Erkak",
      female: "Ayol",
      other: "Boshqa",
    },
    info: "Ma'lumot",
    youAlreadyHaveAccount: "Sizda allaqachon hisob mavjud ",
    clickButtonToLogin: "Kirish uchun bu tugmani bosing",
    loginYourAccount: "Hisobingizga kirish",
    forgotPassword: "Parolni unutdingizmi?",
    forgotPasswordTitle: "Parolni unutish",
    forgotPasswordDesc:
      "Sizning {{method}} kiriting va biz sizga OTP tasdiqlash kodini yuboramiz",
    setNewPassword: "Yangi parolni o'rnating",
    setNewPasswordDesc: "Yangi parolingizni kiriting",
    signUpWith: "Yoki ro'yxatdan o'tish",
    withoutSignUp: "Ro'yxatdan o'tmasdan",
    phoneNumber: "Telefon raqami",
    email: "Elektron pochta",
    loginAccount: "Hisobingizga kirish",
    resendingIsAvailable: "Qayta yuborish mavjud",
    resendingCodeWillBeAvailableIn:
      "Qayta yuborish kodi shundagina mavjud bo'ladi",
    createGuestAccountDesc:
      "iLoyal bilan ro'yxatdan o'tish orqali chegirmalar va qo'shimcha xususiyatlardan foydalanish imkoniyatiga ega bo'lasiz!",
  },
  myProfile: {
    title: "Hisob",
    editProfile: "Profilni tahrirlash",
    orderHistory: "Buyurtmalar tarixi",
    favorites: "Sevimlilar",
    chat: "So'rov",
    gameHistory: "O'yin tarixi",
    helpCenter: "Yordam markazi",
    settings: "Sozlamalar",
    resetPassword: {
      resetPassword: "Parolni tiklash",
      setNewPassword: "Yangi parolni o'rnating",
      enterCurrentPassword:
        "Yangi parolni o'rnatish uchun, iltimos, avvalgi parolingizni kiriting.",
      currentPassword: "Joriy parol *",
      newPassword: "Yangi parol *",
    },
  },
  record: {
    talkToMe: "Menga gapiring",
    magicOrder: "Sehrli buyurtma",
    magicSearch: "Sehrli qidiruv",
    start: "Boshlash",
    stop: "To'xtatish",
    magicOffer: "Sehrli taklif",
    results: "Natijalar",
    addedToBasket: "Savatchaga qo'shildi",
    youCanUseMicrophone: "Mikrofondan foydalanishingiz mumkin",
    magicOrderError:
      "Sizning so'rovingiz menyu bilan bog'liq emas. Iltimos, ovqat yoki menyu kategoriyalariga oid aniqroq so'rov bering.",
    introductionLoya: "Salom, men Loya.",
    askMeAnything: "Menga istalgan narsani so'rang!",
    askLoya: "Loyadan so'rang",
    loyaIsTyping: "Loya yozmoqda",
  },
  recommend: {
    fitMyBudget: "Byudjetimga mos taomlar.",
    tastiestDishes: "Arzon va mazali taomlar.",
    delicious: "Mazali taomlaringiz.",
    smtQuick: "Tezroq narsa bering.",
  },
  termsAndCondition: TermsAndCondition,
  privacyPolicy: PrivacyPolicy,
  devices: {
    addPrinter: "Printer qo'shish",
    testPrinter: "Printerni sinovdan o'tkazish",
    deletePrinter: "Printerni o'chirish",
    connectedDevices: "Ulangan qurilmalar",
    availableDevices: "Mavjud qurilmalar",
    notFound: "Topilmadi!",
    printingBill: "Buyurtma chop etilmoqda, iltimos kuting..",
  },
  printer: {
    unknownBusiness: "Bilinmeyen Biznes",
    awaitingApproval: "QABUL QILINGAN BUYURTMA",
    orderNumber: "Buyurtma Raqami",
    date: "Sana",
    table: "Stol",
    waiter: "Ofitsiant",
    unknown: "Bilinmadi",
    noWaiterAssigned: "Ofitsiant tayinlanmagan",
    orderNote: "Buyurtma izohi",
    product: "Mahsulot",
    quantity: "Miqdor",
    price: "Narx",
    total: "Jami",
    unknownProduct: "Bilinmaydigan Mahsulot",
    unknownOption: "Bilinmaydigan Variant",
    unknownChoice: "Bilinmaydigan Tanlov",
    currency: "UZS",
    totalAmount: "Jami Miqdor",
    bonAppetit: "Yoqi boling..",
    hopeToSeeYouAgain: "Yana ko'rishishni umid qilamiz :)",
    companyName: "iLoyal UZ",
    advancedReceiptSystems: "Ilg'or Chiqim Tizimlari",
    printError: "Printerga yuborishda xatolik",
    printSuccess: "Chop etish muvaffaqiyatli tugadi",
  },
  bulkSms: {
    guestSelected: "Mehmon tanlandi",
    everyoneSelected: "Hamma tanlandi",
    giveDiscount: "Chegirma berish",
    sendSms: "SMS yuborish",
    composeYourMessage: "Xabarni yozing",
    typeHere: "Bu yerga yozing...",
    closeModal: "Yopmoqchiligingizga ishonchingiz komilmi?",
    yourMessageWillBeDeleted: "Xabaringiz o'chiriladi",
    sendSmsModal: "SMS yuborishni xohlaysizmi?",
    willBeSent: "Bu xabar {{numberOfPeople}} kishiga yuboriladi",
    successfullySent: "Muvaffaqiyatli yuborildi",
    successfullySentToPeople:
      "Xabar {{numberOfPeople}} kishiga muvaffaqiyatli yuborildi",
    operationFailed: "Amaliyot bajarilmadi",
    operationFailedPeople: "Xabar {{numberOfPeople}} kishiga yuborilmadi",
    no: "Yo'q",
    yes: "Ha",
    close: "Yopish",
    messageIsRequired: "Xabar majburiydir",
    sentToEveryone: "Xabar hammaga yuboriladi",
    sentToEveryoneSuccess: "Xabar hammaga yuborildi",
    sentToEveryoneFail: "Xabar hech kimga yuborilmadi",
  },
  typingEffect: {
    tableStartTopic: "Yangi mavzu!",
    talkToWaiter: "Ofitsiant bilan gaplashing.",
    shareOpinion: "Fikringizni ayting!",
    questionsHere: "Savollarni bu yerga yozing!",
    chatWithOtherTables: "Boshqa stollar bilan suhbatlashing",
  },
  gameMessages: {
    findCards: "Kartalarni top!",
    tastyVictory: "Mazali g'alaba!",
    riseBeforeMeal: "Taomdan oldin ko'taril!",
    playAndGetHungry: "O'ynab och qol!",
    winBeforeMeal: "Taomdan oldin g'alaba qozon!",
  },
};

export default TRANSLATION;
