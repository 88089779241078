import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import cx from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { loginGuestAsync } from "redux/actions/guestAction";
import {
  ENUMS as ENUMS_EMAIL_OR_PHONE,
  ENUMS,
} from "components/elements/email-or-phone/EmailOrPhone";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import EmailInput, {
  ENUMS as ENUMS_EMAIL_INPUT,
} from "components/forms/input/input-email/EmailInput";
import PasswordInput, {
  ENUMS as ENUMS_PASSWORD_INPUT,
} from "components/forms/input/input-password/PasswordInput";
import useTransferOrderSourceToTarget from "utils/hooks/useTransferOrderSourceToTarget";
import { replaceGuestId } from "redux/slices/basketStore";
import { MP_EVENTS } from "utils/constants/mixpanel";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import SwitchOptions from "components/elements/switch-options/SwitchOptions";

import "./SignInForm.scss";

const SWITCH_OPTIONS_FOR_SIGN_IN = [
  {
    name: "phoneNumber",
    label: "inputs.phoneNumber",
    value: SIGN_IN_METHOD.phoneNumber,
  },
  {
    name: "email",
    label: "inputs.email",
    value: SIGN_IN_METHOD.email,
  },
];

const SignInForm = ({ formData, setFormData }) => {
  const dispatch = useDispatch();
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [selectedLoginMethod, setSelectedLoginMethod] = useState(
    SIGN_IN_METHOD.phoneNumber
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const schemas = useValidationSchema(t);

  const { guestSignInSchema } = schemas;
  const methods = useForm({
    resolver: zodResolver(guestSignInSchema(selectedLoginMethod)),
    criteriaMode: "all",
    defaultValues: {
      phoneNumber: formData.phoneNumber,
      email: formData.email,
    },
  });
  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    setFocus,
  } = methods;

  const { transferOrderSourceToTarget } = useTransferOrderSourceToTarget();

  useEffect(() => {
    const phoneValue = searchParams.get(QUERY_PARAMS.phoneNumber);
    const emailValue = searchParams.get(QUERY_PARAMS.email);

    const updateFormDataAndLoginMethod = (field, value, method) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
      setValue(field, value);
      setSelectedLoginMethod(method);
      searchParams.delete(field);
      setSearchParams(searchParams);
    };

    if (phoneValue) {
      updateFormDataAndLoginMethod(
        QUERY_PARAMS.phoneNumber,
        phoneValue,
        SIGN_IN_METHOD.phoneNumber
      );
    } else if (emailValue) {
      updateFormDataAndLoginMethod(
        QUERY_PARAMS.email,
        emailValue,
        SIGN_IN_METHOD.email
      );
    }
  }, [searchParams, navigate]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneInputChange = (phone, meta, onChange) => {
    const countryDialCode = meta.country.dialCode;
    if (phone && phone !== ENUMS_EMAIL_OR_PHONE.plusSign + countryDialCode) {
      setFormData((prev) => {
        return {
          ...prev,
          phoneNumber: phone,
        };
      });
    }

    onChange(phone);
  };

  const handleOnSubmit = async () => {
    setIsLoadingLogin(true);
    const anonymousGuestId = guestId;
    const { email, password, phoneNumber } = formData;
    let guestBody;
    if (selectedLoginMethod === SIGN_IN_METHOD.email) {
      guestBody = { email: email, password: password };
    } else if (selectedLoginMethod === SIGN_IN_METHOD.phoneNumber) {
      guestBody = {
        phoneNumber: phoneNumber.replace(/\+/g, ""),
        password: password,
      };
    }
    const response = await dispatch(
      loginGuestAsync({
        guestBody,
      })
    );
    if (response.error) {
      const errorData = handleAPIErrorMessage(response.payload);
      setIsLoadingLogin(false);
      if (errorData) {
        const { field, errorMessage } = errorData;
        return setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    }
    await dispatch(
      replaceGuestId({
        oldGuestId: anonymousGuestId,
        newGuestId: response.payload.guest.id,
      })
    );
    await transferOrderSourceToTarget({
      sourceId: anonymousGuestId,
      targetId: response.payload.guest.id,
    });

    setIsLoadingLogin(false);
    if (response.payload.access_token) {
      navigate(
        businessId
          ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
          : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
      );
      trackMixpanel(`${MP_EVENTS.auth.signInCompleted}`);
    }
  };
  const handleKeyDown = ({ target, key }) => {
    if (key === "Enter") {
      switch (target.name) {
        case "phoneNumber":
        case "email":
          setFocus("password");
          break;
        case "password":
          handleSubmit(handleOnSubmit)();
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className="SignInMainForm">
      <h1 className="SemiBold title">{t("auth.loginYourAccount")}</h1>

      <form
        className="SignInCreateAnAccountForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <SwitchOptions
          className={"SignUpPhoneOrEmailSwitch"}
          options={SWITCH_OPTIONS_FOR_SIGN_IN}
          selectedMethod={selectedLoginMethod}
          setSelectedMethod={setSelectedLoginMethod}
        />
        {selectedLoginMethod === SIGN_IN_METHOD.phoneNumber ? (
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <PhoneInput
                  value={value}
                  onChange={(phone, meta) =>
                    handlePhoneInputChange(phone, meta, onChange)
                  }
                  name="phoneNumber"
                  placeholder={t("inputs.phoneNumber")}
                  defaultCountry={ENUMS.defaultCountry}
                  hideDropdown
                  className={cx("phone-input", {
                    hasError: errors.phoneNumber,
                  })}
                  inputProps={{
                    autoComplete: "username",
                    onKeyDown: handleKeyDown,
                  }}
                />
                {errors.phoneNumber && (
                  <div className="EmailOrPhoneSignInMethodError">
                    {errors.phoneNumber && (
                      <ErrorMessage
                        errors={errors}
                        name="phoneNumber"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          />
        ) : (
          <EmailInput
            className={"InputEmail"}
            containerClassName={"TypeA"}
            placeholder={t("inputs.email")}
            required
            name="email"
            labelType={ENUMS_EMAIL_INPUT.types.TYPE_B}
            func={{
              ...register("email", {
                onChange: handleInputChange,
              }),
            }}
            hasError={errors.email}
            onKeyDown={handleKeyDown}
            error={
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            autoComplete={"username"}
          />
        )}
        <PasswordInput
          name="password"
          containerClassName={"TypeA"}
          placeholder={t("inputs.password")}
          value={formData.password}
          hasError={errors.password}
          required
          func={{
            ...register("password", {
              onChange: handleInputChange,
            }),
          }}
          labelType={ENUMS_PASSWORD_INPUT.types.TYPE_B}
          onKeyDown={handleKeyDown}
          error={
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
          autoComplete={"password"}
        />
        <h6
          onClick={() =>
            businessId
              ? navigate(
                  `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}${ROUTE_NAME.forgotPassword}`
                )
              : navigate(
                  `${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.forgotPassword}`
                )
          }
          className="Medium SignInFormForgotPassword"
        >
          {t("login.forgotPassword.title")}
        </h6>
        <h6 className="Medium SignInSubTitle">
          {t("login.signIn.subTitle")}
          <Link
            to={
              businessId
                ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`
                : `${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`
            }
            className="SignUpLink SemiBold"
          >
            {t("login.signUp.title")}
          </Link>
        </h6>
        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("buttons.continue")}
          isLoading={isLoadingLogin}
        />
      </form>
    </div>
  );
};
SignInForm.propTypes = {
  formData: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string.isRequired,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default SignInForm;
