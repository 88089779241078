import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

import useGetLoggedBusinessUser from "utils/hooks/useGetLoggedBusinessUser";
import { getUserRoutesByRole } from "utils/helpers";
import { adminRoles } from "utils/constants/global";

const AdminAuthContext = createContext();

const AdminAuthProvider = ({ children }) => {
  const user = useGetLoggedBusinessUser();

  const userRole = useMemo(() => user?.roles[0].name, [user]);

  const userRouteInfo = useMemo(
    () => getUserRoutesByRole(userRole),
    [getUserRoutesByRole, userRole]
  );

  const restrictedRoutes = useMemo(
    () => userRouteInfo?.restrictedRoutes || [],
    [userRouteInfo]
  );

  const isAdmin = useMemo(
    () => adminRoles.includes(userRole),
    [adminRoles, userRole]
  );

  return (
    <AdminAuthContext.Provider
      value={{ user, userRole, userRouteInfo, restrictedRoutes, isAdmin }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

AdminAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};

export default AdminAuthProvider;
