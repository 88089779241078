import ICON_HOME from "assets/icons/admin-dashboard/Home.svg";
import ICON_SUPER_ADMIN from "assets/icons/admin-dashboard/SuperAdmin.svg";
import ICON_MENU from "assets/icons/admin-dashboard/Menu.svg";
import ICON_BUSINESS from "assets/icons/admin-dashboard/Business.svg";
import ICON_GUESTS from "assets/icons/admin-dashboard/Guests.svg";
import ICON_QR_CODE from "assets/icons/admin-dashboard/QrCode.svg";
import ICON_ORDER from "assets/icons/admin-dashboard/Order.svg";
import ICON_USERS from "assets/icons/admin-dashboard/Users.svg";
import { BUSINESS_SETTINGS, ROLES } from "./global";

import ICON_RESERVATION from "assets/icons/admin-dashboard/Reservation.svg";
import ICON_DEVICES from "assets/icons/admin-dashboard/Devices.svg";
// import ICON_INVENTORY from "assets/icons/admin-dashboard/Inventory.svg";
// import ICON_THREE_DOTS from "assets/icons/admin-dashboard/ThreeDots.svg";

export const ROUTE_NAME_DASHBOARD = "/st";
export const ROUTE_NAME_ORDER = "/o";
export const ROUTE_NAME_MENU = "/m";
export const ROUTE_NAME_BUSINESS = "/b";
export const ROUTE_NAME_USERS = "/u";
export const ROUTE_NAME_GUESTS = "/g";
export const ROUTE_NAME_RESERVATION = "/r";
export const ROUTE_NAME_QR_TABLE_ZONE_MAP = "/qtz";
export const ROUTE_NAME_QR_TABLE_MAP = "/qtm";
export const ROUTE_NAME_SETTINGS = "/stg";
export const ROUTE_NAME_QR = "/qr";
export const ROUTE_NAME_DEVICE = "/d";
export const ROUTE_NAME_SUPER = "/super";

export const ROUTE_NAME = {
  underConstruction: "/",
  offlineMenu: "/offlineMenu",
  any: "/*",
  signIn: "/signin",
  signUp: "/signup",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword",
  legal: "/legal",
  privacyPolicy: "/privacy",
  termsAndCondition: "/terms",

  // admin routes
  admin: "/a",
  lockScreen: "/lsc",
  adminHome: "/home",
  adminBusinessGeneral: `${ROUTE_NAME_BUSINESS}/g`,
  adminBusinessSettings: `${ROUTE_NAME_BUSINESS}${ROUTE_NAME_SETTINGS}`,
  adminMenuCreate: `${ROUTE_NAME_MENU}/crt`,
  adminMenuSettings: `${ROUTE_NAME_MENU}${ROUTE_NAME_SETTINGS}`,
  adminStatistics: ROUTE_NAME_DASHBOARD,
  adminDashboardSalesStatistics: `${ROUTE_NAME_DASHBOARD}/sls`,
  adminDashboardOrderStatistics: `${ROUTE_NAME_DASHBOARD}/ors`,
  adminDashboardAllOrders: `${ROUTE_NAME_DASHBOARD}/aor`,
  adminDashboardMenuStatistics: `${ROUTE_NAME_DASHBOARD}/mns`,
  adminDashboardAllMenus: `${ROUTE_NAME_DASHBOARD}/amn`,
  adminDashboardFeedback: `${ROUTE_NAME_DASHBOARD}/fdb`,
  adminDashboardPayments: `${ROUTE_NAME_DASHBOARD}/pay`,
  adminDashboardStaffStatistics: `${ROUTE_NAME_DASHBOARD}/stf`,
  adminDashboardWeeklyStatistics: `${ROUTE_NAME_DASHBOARD}/wks`,
  adminUserDashboard: `${ROUTE_NAME_USERS}/d`,
  adminGuestDashboard: `${ROUTE_NAME_GUESTS}/d`,
  adminQrTableMap: ROUTE_NAME_QR_TABLE_MAP,
  adminTable: `${ROUTE_NAME_QR_TABLE_ZONE_MAP}/t`,
  adminQR: `${ROUTE_NAME_QR_TABLE_ZONE_MAP}${ROUTE_NAME_QR}`,
  adminMap: `${ROUTE_NAME_QR_TABLE_ZONE_MAP}/map`,
  adminMapView: "/tqr/mapView",
  adminReservationDashboard: `${ROUTE_NAME_RESERVATION}/d`,

  adminDevices: ROUTE_NAME_DEVICE,
  adminPrinters: `${ROUTE_NAME_DEVICE}/p`,

  adminInventory: "/inv",
  adminOrderDashboard: `${ROUTE_NAME_ORDER}/od`,
  adminOrderMap: `${ROUTE_NAME_ORDER}/map`,
  adminOrderChat: "/services/order/chat",
  adminOrderSettings: "/services/order/settings",
  adminKitchenDashboard: "/services/kitchen/kitchen-dashboard",
  adminKitchenSettings: "/services/kitchen/settings",
  superBusiness: `${ROUTE_NAME_SUPER}/create`,
  superInfra: `${ROUTE_NAME_SUPER}/infra`,
  superGuests: `${ROUTE_NAME_SUPER}/guests`,
  superIpBlacklist: `${ROUTE_NAME_SUPER}/ip-blacklist`,

  // client routes
  client: "/c",
  menu: ROUTE_NAME_MENU,
  menuItem: "/item",
  basketItem: "/basketItem",
  menuItemAR: "/miAR",
  dashboard: ROUTE_NAME_DASHBOARD,
  business: ROUTE_NAME_BUSINESS,
  info: "/i",
  vr: "/vr",
  basket: "/basket",
  qr: ROUTE_NAME_QR,
  reservation: "/r",
  myReservations: "/myR",
  auth: "/auth",
  verifyUser: "verifyUser",
  myProfile: "/myProfile",
  edit: "/edit",
  category: "/cat",

  createReservation: "/crtR",
  allBusinesses: "/allBusinesses",
  chat: "/cht",
  funZone: "/fz",
  games: "/games",
  memoryCard: "/memoryCard",
  towerBlock: "/towerBlock",
  magicOrder: "/mo",
  ticTacToe: "/ticTacToe",
  favorites: "/favorites",
  filterResult: "/filterResult",
  feedbacks: "/feedbacks",
};

export const PARAM_NAME = {
  adminPage: "/:adminPage",
  menuId: "/:menuId",
  menuItemId: "/:menuItemId",
  basketItemIndex: "/:basketItemIndex",
  businessId: "/:businessId",
  qrId: "/:qrId",
  categoryId: "/:categoryId",
};
export const QUERY_PARAMS = {
  tableId: "tableId",
  qrId: "qrId",
  categoryId: "categoryId",
  showHamburgerMenu: "showHM",
  showAllBusinessesHamburgerMenu: "showABHM",
  showWheelOfFortune: "showWOF",
  showConfetti: "showC",
  unsavedChanges: "uch",
  showPopup: "showPopup",
  selectedOrder: "so",
  showDetailedChat: "sDCht",
  selectedChat: "sCht",
  from: "from",
  selectedItem: "sItem",
  phoneNumber: "phoneNumber",
  email: "email",
  token: "token",
  reservation: {
    businessId: "bId",
    zoneId: "zId",
    tableId: "tId",
  },
  popupPages: {
    filter: "filter",
  },
  transactionId: "transactionId",
  memorizedScroll: "ms",
  targetedScroll: "ts",
  language: "lang",
};

export const ROUTES_OF_ROLES = [
  {
    role: ROLES.superAdmin.name,
    firstRouteAfterSignIn: ROUTE_NAME.superBusiness,
    restrictedRoutes: [],
  },
  {
    role: ROLES.admin.name,
    firstRouteAfterSignIn: ROUTE_NAME.superBusiness,
    restrictedRoutes: [
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
    ],
  },
  {
    role: ROLES.admin2.name,
    firstRouteAfterSignIn: ROUTE_NAME.superBusiness,
    restrictedRoutes: [
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
    ],
  },
  {
    role: ROLES.admin3.name,
    firstRouteAfterSignIn: ROUTE_NAME.superBusiness,
    restrictedRoutes: [
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
    ],
  },
  {
    role: ROLES.admin4.name,
    firstRouteAfterSignIn: ROUTE_NAME.superBusiness,
    restrictedRoutes: [
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
    ],
  },
  {
    role: ROLES.owner.name,
    firstRouteAfterSignIn: ROUTE_NAME.adminOrderDashboard,
    restrictedRoutes: [
      ROUTE_NAME.superBusiness,
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
    ],
  },
  {
    role: ROLES.headManager.name,
    firstRouteAfterSignIn: ROUTE_NAME.adminOrderDashboard,
    restrictedRoutes: [
      ROUTE_NAME.superBusiness,
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
      ROUTE_NAME.adminUserDashboard,
    ],
  },
  {
    role: ROLES.branchManager.name,
    firstRouteAfterSignIn: ROUTE_NAME.adminOrderDashboard,
    restrictedRoutes: [
      ROUTE_NAME.superBusiness,
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
      ROUTE_NAME.adminUserDashboard,
    ],
  },
  {
    role: ROLES.manager.name,
    firstRouteAfterSignIn: ROUTE_NAME.adminOrderDashboard,
    restrictedRoutes: [
      ROUTE_NAME.superBusiness,
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
      ROUTE_NAME.adminUserDashboard,
    ],
  },
  {
    role: ROLES.cashier.name,
    firstRouteAfterSignIn: ROUTE_NAME.adminOrderDashboard,
    restrictedRoutes: [
      ROUTE_NAME.superBusiness,
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
      ROUTE_NAME.adminUserDashboard,
      ROUTE_NAME.adminStatistics,
      ROUTE_NAME.adminBusinessGeneral,
      ROUTE_NAME.adminBusinessSettings,
      ROUTE_NAME.adminMenuSettings,
    ],
  },
  {
    role: ROLES.staff.name,
    firstRouteAfterSignIn: ROUTE_NAME.adminOrderDashboard,
    restrictedRoutes: [
      ROUTE_NAME.superBusiness,
      ROUTE_NAME.superInfra,
      ROUTE_NAME.superGuests,
      ROUTE_NAME.superIpBlacklist,
      ROUTE_NAME.adminUserDashboard,
      ROUTE_NAME.adminStatistics,
      ROUTE_NAME.adminBusinessGeneral,
      ROUTE_NAME.adminBusinessSettings,
      ROUTE_NAME.adminMenuSettings,
    ],
  },
  // {
  //   role: "GUEST",
  //   // firstRouteAfterSignIn: "",
  //   restrictedRoutes: [
  //     ROUTE_NAME.admin,
  //   ],
  // },
];

export const ROUTES_OF_BUSINESS_SETTINGS = [
  {
    setting: BUSINESS_SETTINGS.reservation,
    restrictedRoutes: [],
    redirectRoute: `${ROUTE_NAME.client}${ROUTE_NAME.business}/`,
  },
  {
    setting: BUSINESS_SETTINGS.chat,
    restrictedRoutes: [ROUTE_NAME.chat],
    redirectRoute: `${ROUTE_NAME.client}${ROUTE_NAME.business}/`,
  },
];

export const NAVBAR_ROUTES = [
  {
    name: "navbarRoutes.superAdmin",
    icon: ICON_SUPER_ADMIN,
    path: ROUTE_NAME.superBusiness,
    subRoutes: [
      {
        name: "navbarRoutes.business",
        path: ROUTE_NAME.superBusiness,
      },
      {
        name: "navbarRoutes.infra",
        path: ROUTE_NAME.superInfra,
      },
      {
        name: "navbarRoutes.guests",
        path: ROUTE_NAME.superGuests,
      },
    ],
  },
  {
    name: "navbarRoutes.statistics",
    path: ROUTE_NAME.adminStatistics,
    icon: ICON_HOME,
    subRoutes: [
      {
        name: "navbarRoutes.weeklyStatistics",
        path: ROUTE_NAME.adminDashboardWeeklyStatistics,
      },
      {
        name: "navbarRoutes.salesStatistics",
        path: ROUTE_NAME.adminDashboardSalesStatistics,
      },
      {
        name: "navbarRoutes.orderStatistics",
        path: ROUTE_NAME.adminDashboardOrderStatistics,
      },
      {
        name: "navbarRoutes.menuStatistics",
        path: ROUTE_NAME.adminDashboardMenuStatistics,
      },
      {
        name: "navbarRoutes.allOrders",
        path: ROUTE_NAME.adminDashboardAllOrders,
      },
      {
        name: "navbarRoutes.allItems",
        path: ROUTE_NAME.adminDashboardAllMenus,
      },
      {
        name: "navbarRoutes.feedbacks",
        path: ROUTE_NAME.adminDashboardFeedback,
      },
      {
        name: "navbarRoutes.payments",
        path: ROUTE_NAME.adminDashboardPayments,
      },
      // {
      //   name: "navbarRoutes.staff",
      //   path: ROUTE_NAME.adminDashboardStaffStatistics,
      // },
    ],
  },

  {
    name: "navbarRoutes.orders",
    path: ROUTE_NAME.adminOrderDashboard,
    icon: ICON_ORDER,
    // subRoutes: [
    //   {
    //     name: t("navbarRoutes.dashboard"),
    //     path: ROUTE_NAME.adminOrderDashboard,
    //   },
    //   {
    //     name: t("common.settings"),
    //     path: ROUTE_NAME.adminOrderSettings,
    //   },
    // ],
  },
  // {
  //   name: "navbarRoutes.departments",
  //   path: ROUTE_NAME.adminKitchenDashboard,
  //   icon: ICON_KITCHEN,
  //   subRoutes: [
  //     {
  //       name: "navbarRoutes.dashboard",
  //       path: ROUTE_NAME.adminKitchenDashboard,
  //     },
  //     {
  //       name: "common.settings",
  //       path: ROUTE_NAME.adminKitchenSettings,
  //     },
  //   ],
  // },
  {
    name: "navbarRoutes.menu",
    icon: ICON_MENU,
    path: ROUTE_NAME.adminMenuCreate,
    subRoutes: [
      {
        name: "common.create",
        path: ROUTE_NAME.adminMenuCreate,
      },
      {
        name: "common.settings",
        path: ROUTE_NAME.adminMenuSettings,
      },
    ],
  },
  {
    name: "navbarRoutes.business",
    icon: ICON_BUSINESS,
    path: ROUTE_NAME.adminBusinessGeneral,
    subRoutes: [
      {
        name: "common.general",
        path: ROUTE_NAME.adminBusinessGeneral,
      },
      {
        name: "common.settings",
        path: ROUTE_NAME.adminBusinessSettings,
      },
    ],
  },
  {
    name: "navbarRoutes.guests",
    icon: ICON_GUESTS,
    path: ROUTE_NAME.adminGuests,
  },
  {
    name: "navbarRoutes.users",
    path: ROUTE_NAME.adminUserDashboard,
    icon: ICON_USERS,
  },
  {
    name: "navbarRoutes.qrAndTable",
    icon: ICON_QR_CODE,
    path: ROUTE_NAME.adminTable,
    subRoutes: [
      {
        name: "common.zoneAndTable",
        path: ROUTE_NAME.adminTable,
      },
      {
        name: "common.qr",
        path: ROUTE_NAME.adminQR,
      },
      {
        name: "navbarRoutes.map",
        path: ROUTE_NAME.adminMap,
      },
    ],
  },
  {
    name: "navbarRoutes.reservation",
    path: ROUTE_NAME.adminReservationDashboard,
    icon: ICON_RESERVATION,
  },
  {
    name: "navbarRoutes.devices",
    path: ROUTE_NAME.adminDevices,
    icon: ICON_DEVICES,
    subRoutes: [
      {
        name: "common.printers",
        path: ROUTE_NAME.adminPrinters,
      },
    ],
  },
  // {
  //   name: t("navbarRoutes.inventory"),
  //   path: ROUTE_NAME.adminInventory,
  //   icon: ICON_INVENTORY,
  // },
];
