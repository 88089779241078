import { useEffect, useRef, useState } from "react";

const headerOffset = -60;
const scrollActivationOffset = 200;

const useManageChipsWhenScroll = ({ categories }) => {
  const categoryRefs = useRef({});
  const [activeCategoryId, setActiveCategoryId] = useState(null);

  useEffect(() => {
    const onScroll = () => {
      const scrollTop = window.scrollY;
      categories.forEach((category) => {
        const categoryElement = categoryRefs.current[category.id];
        if (categoryElement) {
          const categoryTop = categoryElement.offsetTop;
          const categoryHeight = categoryElement.offsetHeight;
          if (
            scrollTop >= categoryTop - scrollActivationOffset &&
            scrollTop < categoryTop + categoryHeight
          ) {
            setActiveCategoryId(category.id);
          }
        }
      });
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [categories]);

  const handleChipClick = (categoryId) => {
    const targetElement = categoryRefs.current[categoryId];
    if (targetElement) {
      const targetPosition = targetElement.offsetTop + headerOffset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return { handleChipClick, activeCategoryId, categoryRefs };
};

export default useManageChipsWhenScroll;
