import React, { useEffect } from "react";
import PropTypes from "prop-types";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";

import "./Range.scss";

export const ENUMS = {
  name: "Range",
};

const Range = ({ min = 0, max = 100, step = 5, currentValue, onChange }) => {
  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data.currency
  );
  const handleValuesChange = (newValues) => {
    if (newValues[1] <= max) {
      onChange({
        min: newValues[0],
        max: newValues[1],
      });
    }
  };

  useEffect(() => {
    const thumbElements = document.querySelectorAll(".range-slider__thumb");

    thumbElements.forEach((thumbElement) => {
      let tooltip = thumbElement.querySelector(".tooltip");

      if (!tooltip) {
        tooltip = document.createElement("h6");
        tooltip.className = "tooltip Bold";
        thumbElement.appendChild(tooltip);
      }

      tooltip.textContent = thumbElement.getAttribute("aria-valuenow");
    });
  }, [currentValue.min, currentValue.max]);

  return (
    <div className="RangeComponentSlider">
      <div className="RangeComponentSliderWrap">
        <RangeSlider
          value={[currentValue.min, currentValue.max]}
          onInput={handleValuesChange}
          min={min}
          max={max}
          step={step}
          rangeSlideDisabled={true}
        />
      </div>

      <div className="RangeComponentBordersWrapper">
        <div className="h8">
          {currency.code} {currentValue.min}
        </div>
        <div className="h8">
          {currency.code} {currentValue.max}
        </div>
      </div>
    </div>
  );
};

Range.propTypes = {
  /**
   * The minimum value of range
   */
  min: PropTypes.number,

  /**
   * The maximum value of range
   */
  max: PropTypes.number.isRequired,

  /**
   * The step of range
   */
  step: PropTypes.number.isRequired,

  /**
   * The current value of range
   */
  currentValue: PropTypes.object.isRequired,

  /**
   * The change event of range
   */
  onChange: PropTypes.func.isRequired,
};

export default Range;
