import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TextIconButton from "components/buttons/text-icon-button/TextIconButton";
import { ReactComponent as IconMenu } from "assets/icons/admin-dashboard/Menu.svg";
import { ReactComponent as IconChat } from "assets/icons/chat/ChatMessage.svg";
import { ReactComponent as OrderIcon } from "assets/icons/admin-dashboard/Order.svg";

import { MENU_TABS_ENUMS } from "../admin-order-page/AdminOrderPage";
import { STORE_NAMES } from "utils/constants/redux";

import "./AdminOrderMenu.scss";

export const ADMIN_MENU_ITEM_TYPE = "AdminMenuItemType";

const AdminOrderMenu = ({ setActiveTab, activeTab, hasNewMessage }) => {
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const { t } = useTranslation();
  const adminOrderTabs = [
    {
      type: MENU_TABS_ENUMS.tabType.HOME,
      name: "navbarRoutes.home",
      icon: <OrderIcon />,
    },
    {
      type: MENU_TABS_ENUMS.tabType.MENU,
      name: "navbarRoutes.menu",
      icon: <IconMenu />,
    },
    ...(business?.chat
      ? [
          {
            type: MENU_TABS_ENUMS.tabType.CHAT,
            name: "chat.chat",
            icon: <IconChat />,
          },
        ]
      : []),
    // {
    //   type: MENU_TABS_ENUMS.tabType.PRINTS,
    //   name: "payment.printsAndBill",
    //   icon: <IconPrinter />,
    // },
  ];

  return (
    <div className="AdminOrderMenu">
      <div className="AdminOrderMenuTabs">
        {adminOrderTabs.map((tab) => (
          <TextIconButton
            key={tab.type}
            text={t(`${tab.name}`)}
            svgComponent={tab.icon}
            onClick={() => setActiveTab(tab.type)}
            className={
              tab.type === activeTab
                ? "ActiveTab"
                : activeTab !== MENU_TABS_ENUMS.tabType.CHAT &&
                  hasNewMessage &&
                  tab.type === MENU_TABS_ENUMS.tabType.CHAT
                ? "AnimatedTab"
                : undefined
            }
          />
        ))}
      </div>
    </div>
  );
};

AdminOrderMenu.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  hasNewMessage: PropTypes.bool,
};

export default AdminOrderMenu;
