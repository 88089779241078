import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import JSZip from "jszip";

import AddNewButton from "components/admin/buttons/add-new-button/AddNewButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAsync from "utils/hooks/useAsync";
import {
  createQRCode,
  deleteQRCode,
  updateQRCode,
} from "utils/api/services/QR";
import AdminQRModal from "pages/admin/admin-pages/admin-qr-table-map/admin-qr/admin-qr-modal/AdminQRModal";
import { STORE_NAMES } from "utils/constants/redux";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage, QR_TYPES } from "utils/constants/data/base";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { getZonesAsync } from "redux/actions/zoneAction";
import { getAllQrAsync } from "redux/actions/qrAction";
import Table from "components/admin/elements/table/Table";
import Spinner from "components/elements/spinner/Spinner";
import EmptyState from "components/admin/empty-state/EmptyState";
import EmptyQrIcon from "assets/icons/qr/EmptyQR.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import Button from "components/buttons/button/Button";
import If from "components/if/If";
import { ReactComponent as DownloadIcon } from "assets/icons/other/Download.svg";
import Modal, { useModal } from "components/pos/modal/Modal";
import GenerateQrModal from "pages/admin/admin-pages/admin-qr/generate-qr-modal/GenerateQrModal";

import "./AdminQr.scss";

const AdminQr = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isVisible: isVisibleGenerateQrModal,
    open: openGenerateQrModal,
    close: closeGenerateQrModal,
  } = useModal();

  const { name: businessName, id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business]?.business
  );

  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const { qrTypes } = useSelector((state) => state[STORE_NAMES.app].enums);
  const { qrs, isLoading } = useSelector((state) => state[STORE_NAMES.qr]);

  useEffect(() => {
    dispatch(getAllQrAsync(businessId));
  }, []);

  const handleOnAsyncSuccessForZone = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      //TODO remove reFetch after web sockets
      dispatch(getAllQrAsync(businessId));
      dispatch(getZonesAsync({ businessId }));
    });
  };
  const handleOnAsyncErrorForZone = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };
  const { execute: executeCreateQRCode, loading: isLoadingCreateQRCode } =
    useAsync(createQRCode, {
      onError: ({ response }) =>
        handleOnAsyncErrorForZone(response.data.message),
      onSuccess: () => {
        handleOnAsyncSuccessForZone(t("toastMessages.success.createQR"));
      },
    });
  const { execute: executeUpdateQRCode, loading: isLoadingUpdateQRCode } =
    useAsync(updateQRCode, {
      onError: ({ response }) => {
        handleOnAsyncErrorForZone(response.data.message);
      },
      onSuccess: () =>
        handleOnAsyncSuccessForZone(t("toastMessages.success.updateQR")),
    });
  const { execute: executeDeleteQRCode } = useAsync(deleteQRCode, {
    onError: () => handleOnAsyncErrorForZone(),
    onSuccess: () =>
      handleOnAsyncSuccessForZone(t("toastMessages.success.deleteQR")),
  });

  const isQRCodeConnectedToTheTable = (qr) => {
    return zones.some((zone) =>
      zone.tables.some((table) =>
        table.qrLinks.some((qrLink) => qrLink.id === qr.id)
      )
    );
  };

  const [openSlide, setOpenSlide, mainElementRef, , , setOutsideClickAction] =
    useOutsideClick();
  const [selectedQR, setSelectedQR] = useState(null);

  const [formData, setFormData] = useState(null);
  const [formDataInitial, setFormDataInitial] = useState(null);

  const handleOnAddQR = async () => {
    setOpenSlide(true);
    setSelectedQR(null);
  };

  const handleOnEditQR = (id) => {
    setOpenSlide(true);
    setSelectedQR(qrs.find((qrCode) => qrCode.id === id));
  };

  const handleOnSaveQR = async (data, id) => {
    let qrData;
    const qrType = data.type?.value;

    const formDataToSend = new FormData();
    formDataToSend.append("name", data.name);

    if (qrType === QR_TYPES.URL) {
      const domain = `${window.location.origin}${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}?${QUERY_PARAMS.qrId}=`;
      qrData = { url: id ? domain + id : domain };
      formDataToSend.append("tableId", data?.table?.id ? data.table.id : null);
    } else {
      qrData = { ssid: data.wiFiName, password: data.wiFiPassword };
    }

    for (const key in qrData) {
      formDataToSend.append(`qrData[${key}]`, qrData[key]);
    }

    if (id) {
      await executeUpdateQRCode(businessId, formDataToSend, id, qrType);
    } else {
      await executeCreateQRCode(businessId, formDataToSend, qrType);
    }
  };

  const handleOnDeleteQR = async (id) => {
    await executeDeleteQRCode(businessId, id);
  };

  const tableColumns = [
    {
      key: "name",
      name: t("qr.name"),
      isFilterable: false,
    },
    {
      key: "type",
      name: t("qr.type"),
      isFilterable: false,
    },
    {
      key: "status",
      name: t("qr.status"),
      isFilterable: false,
      hasCustomRow: true,
    },
    {
      key: "connectedTo",
      name: t("qr.connected"),
      isFilterable: false,
    },
    {
      key: "scanCount",
      name: t("qr.scanCount"),
      isFilterable: false,
    },
  ];

  const handleDownloadAllQRCodes = async (qrs) => {
    const zip = new JSZip();
    try {
      await Promise.all(
        qrs.map(async (qr) => {
          const associatedZone = zones.find((zone) =>
            zone.tables.some((table) =>
              table.qrLinks.some((qrLink) => qrLink.id === qr.id)
            )
          );
          const response = await fetch(createDOBucketName(qr.image));
          if (!response.ok) {
            throw new Error(`Failed to fetch QR code image: ${qr.id}`);
          }

          const blob = await response.blob();
          const arrayBuffer = await blob.arrayBuffer();

          let folderName = "Uncategorized";
          if (qr.type === qrTypes.wifi) {
            folderName = qrTypes.wifi;
          } else if (associatedZone) {
            folderName = associatedZone.name;
          }

          let fileName = `${qr.name}.png`;

          if (qr.type === qrTypes.url && associatedZone) {
            fileName = `${associatedZone.name} (${qr.table?.name} QR-${qr.id}).png`;
          }
          zip.folder(folderName).file(fileName, arrayBuffer);
        })
      );
      const zipBlob = await zip.generateAsync({ type: "blob" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = `${businessName}.zip`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("An error occurred while creating the zip file:", error);
    }
  };
  const tableItems =
    qrs
      ?.map((QRCode) => ({
        id: QRCode.id,
        name: QRCode.name,
        type: QRCode.type,
        status: isQRCodeConnectedToTheTable(QRCode) ? (
          <div className="AdminQrStatus linked">{t("qr.linked")}</div>
        ) : (
          QRCode.type === "URL" && (
            <div className="AdminQrStatus unlinked">{t("qr.unlinked")}</div>
          )
        ),
        connectedTo: QRCode.table?.name,
        scanCount: QRCode.scanCount,
      }))
      .sort((a, b) => b.scanCount - a.scanCount) || [];

  return (
    <div className="AdminQr">
      <div className="AdminQrCaption">
        <h4 className="AdminQrTitle SemiBold">
          {t("navbarRoutes.pageTitles.qrList")}
          <span className="AdminQrCount">({qrs?.length})</span>
        </h4>
        <div className="AdminQrCaptionButtons">
          <Button
            className={"AllQrDownloadButton"}
            onClick={openGenerateQrModal}
            text={t("qr.generateQR")}
          />
          <Button
            className={"AllQrDownloadButton"}
            onClick={() => handleDownloadAllQRCodes(qrs)}
            IconLeft={<DownloadIcon />}
            text={t("qr.downloadAllQr")}
          />
          <AddNewButton onClick={handleOnAddQR} label={t("buttons.addQR")} />
        </div>
      </div>
      <If state={isLoading}>
        <Spinner />
      </If>
      <If state={!isLoading}>
        <div className="AdminQrContainer">
          {qrs?.length === 0 && (
            <EmptyState
              icon={EmptyQrIcon}
              description={t("emptyTable.emptyQR")}
              isAdmin={true}
            />
          )}
          {qrs?.length > 0 && (
            <Table
              columns={tableColumns}
              items={tableItems}
              hasDeleteButton
              onEdit={(id) => handleOnEditQR(id)}
              onDelete={(id) => handleOnDeleteQR(id)}
              classname="AdminQrContainerTable"
            />
          )}
        </div>
      </If>
      <AdminQRModal
        mainElementRef={mainElementRef}
        openSlide={openSlide}
        setOpenSlide={setOpenSlide}
        formData={formData}
        setFormData={setFormData}
        setFormDataInitial={setFormDataInitial}
        formDataInitial={formDataInitial}
        selectedQR={selectedQR}
        onSave={handleOnSaveQR}
        onDelete={handleOnDeleteQR}
        title={t("qr.qr")}
        setOutsideClickAction={setOutsideClickAction}
        isLoading={isLoadingCreateQRCode || isLoadingUpdateQRCode}
      />
      <Modal isVisible={isVisibleGenerateQrModal} close={closeGenerateQrModal}>
        <GenerateQrModal />
      </Modal>
    </div>
  );
};

export default AdminQr;
