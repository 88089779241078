import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setLanguage } from "redux/slices/userStore";
import { STORE_NAMES } from "utils/constants/redux";
import { LANGUAGES } from "utils/constants/language";
import { QUERY_PARAMS } from "utils/constants/routes";

const useSetUserLanguage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queriedLanguage = searchParams.get(QUERY_PARAMS.language);
  const { data: menuData } = useSelector((state) => state[STORE_NAMES.menu]);
  const { userLanguage } = useSelector((state) => state[STORE_NAMES.user]);

  const availableLanguages = menuData?.availableLanguages;
  const menuDefaultLanguage = menuData?.language;

  const findLanguageByCode = useCallback(
    (code) => availableLanguages?.find((lang) => lang.code === code),
    [availableLanguages]
  );

  useEffect(() => {
    if (!availableLanguages?.length) return;

    const determineLanguage = () => {
      if (queriedLanguage) {
        const queriedLang = findLanguageByCode(queriedLanguage);
        if (queriedLang) return queriedLang;
      }

      if (!userLanguage || !userLanguage.code) {
        return (
          menuDefaultLanguage ||
          findLanguageByCode(LANGUAGES.en.code) ||
          availableLanguages[0]
        );
      }

      return userLanguage;
    };

    const newLanguage = determineLanguage();
    if (newLanguage && newLanguage.code !== userLanguage?.code) {
      dispatch(setLanguage(newLanguage));
    }
  }, [
    availableLanguages,
    queriedLanguage,
    userLanguage,
    findLanguageByCode,
    menuDefaultLanguage,
    dispatch,
  ]);
};

export default useSetUserLanguage;
