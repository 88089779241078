import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS } from "utils/constants/routes";

const useInternalNavigation = () => {
  const routerNavigate = useNavigate();
  const { pathname: currentPath, search } = useLocation();
  const [searchParams] = useSearchParams();

  const navigate = ({
    path,
    preserveExistingQueries = false,
    preserveExistingUrl = false,
    queries = {},
  }) => {
    const queryString = new URLSearchParams(
      preserveExistingQueries ? search : ""
    );

    if (preserveExistingUrl) {
      queryString.set(QUERY_PARAMS.from, currentPath);
    }

    Object.keys(queries).forEach((key) => {
      queryString.set(key, queries[key]);
    });

    const query = queryString.toString();

    routerNavigate({
      pathname: path,
      search: query ? `?${query}` : "",
    });
  };

  const navigateToPreservedBackUrl = ({ fallbackUrl, queries = {} }) => {
    const queryString = new URLSearchParams();
    Object.keys(queries).forEach((key) => {
      queryString.set(key, queries[key]);
    });
    const query = queryString.toString();

    const backPathname = searchParams.get(QUERY_PARAMS.from) || fallbackUrl;

    routerNavigate({
      pathname: backPathname,
      search: query ? `?${query}` : "",
    });
  };

  return { navigate, navigateToPreservedBackUrl };
};
export default useInternalNavigation;
