import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { initialState, setUserWithPin } from "redux/slices/userStore";
import { setIsAdminDashboardPinLocked } from "redux/slices/appStore";
import { ROUTE_NAME } from "utils/constants/routes";
import { useAdminAuth } from "utils/context-api/AdminAuthContext";
import If from "components/if/If";
import Button, {
  ENUMS_TYPE as BUTTON_ENUMS,
} from "components/pos/button/Button";

import Profile, {
  ENUMS as PROFILE_ENUMS,
} from "components/pos/profile/Profile";

import { ReactComponent as LockIcon } from "assets/icons/pos/lock-01-white.svg";
import Dropdown from "components/pos/language-dropdown/LanguageDropdown";
import { generateInitial } from "utils/helpers";

import styles from "./Header.module.scss";

const Header = () => {
  const { t } = useTranslation();
  const { user, isAdmin } = useAdminAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { firstName, lastName } = user;

  const handleOnLockScreen = () => {
    dispatch(setUserWithPin(initialState.userWithPin));
    dispatch(setIsAdminDashboardPinLocked(true));
    navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`, { replace: true });
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <div className={styles.User}>
          <Profile
            title={`${firstName} ${lastName}`}
            initialText={generateInitial([firstName, lastName])}
            size={PROFILE_ENUMS.types.PROFILE52}
            className={styles.Profile}
          />
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.Dropdown}>
            <Dropdown />
          </div>
          <If state={!isAdmin}>
            <div className={styles.PinLockBtnContainer}>
              <Button
                onClick={handleOnLockScreen}
                type={BUTTON_ENUMS.Lock}
                text={t("buttons.lockScreen")}
                rightIcon={<LockIcon />}
              />
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default Header;
