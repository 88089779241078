import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Button, {
  ENUMS_TYPE as BUTTON_ENUMS_TYPE,
} from "components/pos/button/Button";
import TextInput, {
  ENUMS as ENUMS_TEXT_INPUT,
} from "components/forms/input/input-text/TextInput";
import IconButton from "components/pos/icon-button/IconButton";
import { ReactComponent as PlusIcon } from "assets/icons/math-operators/add/Plus.svg";
import { ReactComponent as MinusIcon } from "assets/icons/math-operators/minus/Minus.svg";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import { downloadQRCodesAsZip } from "utils/helper-functions/menu-helper/menu-item-helper";

import styles from "./CreateCategoryQr.module.scss";

const CreateCategoryQr = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({});
  const [paramsCount, setParamsCount] = useState(1);
  const { name: businessName, id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business]?.business
  );
  const categories =
    useSelector((state) => state[STORE_NAMES.menu].data?.categories) || [];

  const updatedCategoryIds = useMemo(() => {
    if (categories.length === 0) return [];
    return categories
      .filter((category) => !category.isArchived)
      .flatMap(({ id }) => id);
  }, [categories]);

  const [isLoadingDownloadCategoryQr, setIsLoadingDownloadCatQr] =
    useState(false);

  const validParams = Object.values(params).filter(
    (param) => param?.key?.trim() && param?.value?.trim()
  );

  const isDisabledButton =
    Object.values(params).filter(
      (param) => param?.key?.trim() || param?.value?.trim()
    ).length > 0 && !validParams.length > 0;

  const queryString = validParams
    .map((param) => `${param.key}=${param.value}`)
    .join("&");
  const formattedParams = queryString ? `?${queryString}` : "";

  const downloadAllCategoryQrs = async () => {
    const data = updatedCategoryIds.map((categoryId) => {
      const url = `${window.location.origin}${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.category}/${categoryId}${formattedParams}`;
      return { id: categoryId, url };
    });
    const typeNumber =
      Math.ceil(
        data.flatMap(({ url }) => url.length).sort((a, b) => b - a)?.[0] / 8
      ) || 4;
    const paramExtension = validParams.flatMap(({ value }) => value).join("_");
    try {
      await downloadQRCodesAsZip({
        businessName,
        data,
        setLoading: setIsLoadingDownloadCatQr,
        setError: (error) => console.error("Error:", error),
        paramExtension,
        typeNumber,
      });
    } catch (error) {
      toast.error(t("toastMessages.error.common"));
    }
  };
  const incrementParams = () => {
    setParamsCount((prev) => prev + 1);
  };
  const decrementParams = (deletedIndex) => {
    setParamsCount((prev) => Math.max(prev - 1, 1));

    setParams((prev) =>
      Object.fromEntries(
        Object.entries(prev)
          .filter(([index]) => Number(index) !== deletedIndex)
          .map(([, param], newIndex) => [newIndex, param])
      )
    );
  };

  const handleChangeUrl = (e, index) => {
    const { name, value } = e.target;

    setParams((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev?.[index],
          [name]: value,
        },
      };
    });
  };

  return (
    <div>
      <div className={styles.Header}>
        <h6 className="SemiBold">{t("qr.params")}</h6>
        <div>
          <Button
            onClick={incrementParams}
            icon={<PlusIcon />}
            className={styles.Plus}
            type={BUTTON_ENUMS_TYPE.Primary}
            text={t("buttons.add")}
          />
        </div>
      </div>

      <div className={styles.Form}>
        {Array.from({ length: paramsCount }, (_, index) => {
          return (
            <div className={styles.Row} key={index}>
              <TextInput
                value={params?.[index]?.key || ""}
                name={"key"}
                onChange={(e) => handleChangeUrl(e, index)}
                placeholder="Key"
                labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
              />
              <TextInput
                value={params?.[index]?.value || ""}
                name={"value"}
                onChange={(e) => handleChangeUrl(e, index)}
                placeholder="Value"
                labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
              />
              <IconButton
                onClick={() => decrementParams(index)}
                icon={<MinusIcon />}
                className={styles.Minus}
              />
            </div>
          );
        })}
      </div>
      <Button
        onClick={downloadAllCategoryQrs}
        className={styles.DownloadButton}
        text={t("qr.downloadAllQr")}
        type={BUTTON_ENUMS_TYPE.Primary}
        isLoading={isLoadingDownloadCategoryQr}
        isDisabled={isDisabledButton}
      />
    </div>
  );
};

export default CreateCategoryQr;
