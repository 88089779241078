/**
 * Predefined beep configurations for printers.
 * Each configuration defines the number of beeps and their duration.
 */
export const BEEP_CONFIGS = {
  SHORT_SINGLE: { times: 1, duration: 3 }, // Single short beep
  LONG_SINGLE: { times: 1, duration: 6 }, // Single long beep
  DOUBLE_BEEP: { times: 2, duration: 4 }, // Two beeps
  TRIPLE_BEEP: { times: 3, duration: 5 }, // Three beeps
};

const PRINTER_ALERTS = {
  BEEP: "beep",
};

/**
 * Class to build print commands for a printer.
 */
export class PrintCommandBuilder {
  constructor() {
    this.commands = [];
  }

  /**
   * Adds a text command to the print commands.
   * @param {string} value - The text to be printed.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addText(value) {
    this.commands.push({ type: "text", value });
    return this;
  }

  /**
   * Adds a draw line command to the print commands.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addDrawLine() {
    this.commands.push({ type: "drawLine" });
    return this;
  }

  /**
   * Adds a table command to the print commands.
   * @param {Array} columns - The columns of the table.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addTable(columns) {
    this.commands.push({ type: "table", columns });
    return this;
  }

  /**
   * Adds an align command to the print commands.
   * @param {string} value - The alignment value (e.g., 'left', 'center', 'right').
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addAlign(value) {
    this.commands.push({ type: "align", value });
    return this;
  }

  /**
   * Adds a bold command to the print commands.
   * @param {boolean} value - Whether to enable or disable bold text.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addBold(value) {
    this.commands.push({ type: "bold", value });
    return this;
  }

  /**
   * Adds a newline command to the print commands.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addNewline() {
    this.commands.push({ type: "newline" });
    return this;
  }

  /**
   * Adds a cut command to the print commands.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addCut() {
    this.commands.push({ type: "cut" });
    return this;
  }

  /**
   * Adds a sound command to the print commands.
   * @param {Object} config - Sound configuration object.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addSound(config) {
    if (!config || config.type !== "sound") {
      throw new Error("Invalid sound configuration");
    }
    this.commands.push(config);
    return this;
  }

  /**
   * Builds and returns the list of print commands.
   * @returns {Array<{
   *   type: ('text'|'drawLine'|'table'|'align'|'bold'|'newline'|'cut'|'beep'),
   *   value?: string|boolean,
   *   columns?: Array<{
   *     text: string,
   *     width: number
   *   }>,
   *   times?: number,
   *   duration?: number
   * }>} Array of print commands where each command is an object with:
   * - type: Command type identifier
   * - value: (optional) Command value for text, align, and bold commands
   * - columns: (optional) Array of column configurations for table command
   * - times: (optional) Number of beeps for beep command
   * - duration: (optional) Duration of beeps for beep command
   * @example
   * // Returns an array like:
   * [
   *   { type: 'align', value: 'center' },
   *   { type: 'bold', value: true },
   *   { type: 'text', value: 'Receipt' },
   *   { type: 'table', columns: [{ text: 'Item', width: 20 }] },
   *   { type: 'drawLine' },
   *   { type: 'beep', times: 3, duration: 6 },
   *   { type: 'cut' }
   * ]
   */
  build() {
    return this.commands;
  }

  /**
   * Adds a beep command to the print commands.
   * @param {number} times - The number of beeps.
   * @param {number} duration - The duration of each beep.
   * @returns {PrintCommandBuilder} The builder instance.
   */
  addBeep({ times, duration }) {
    this.commands.push({ type: PRINTER_ALERTS.BEEP, times, duration });
    return this;
  }
}
