import React, { memo, useCallback, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import PropTypes from "prop-types";

import useToggle from "utils/hooks/useToggle";

import styles from "./Modal.module.scss";

export const PositionType = {
  Center: "Center",
  Right: "Right",
  Left: "Left",
  Top: "Top",
  Bottom: "Bottom",
};

const Modal = ({
  children,
  isVisible,
  close,
  disableOutsideClick = false,
  outsideClickCallback,
  classNames = {},
  position = PositionType.Center,
  animationDuration = 300,
}) => {
  const handleOutsideClick = useCallback(() => {
    if (disableOutsideClick) return;

    if (outsideClickCallback) {
      outsideClickCallback();
      return;
    }

    if (close) {
      close();
    }
  }, [disableOutsideClick, outsideClickCallback, close]);

  const modalStyle = useMemo(
    () => ({
      "--animation-duration": `${animationDuration}ms`,
    }),
    [animationDuration]
  );

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      className={cx(styles.Root, classNames.Root, styles[position], {
        [styles.isVisible]: isVisible,
      })}
      style={modalStyle}
    >
      <div
        className={cx(styles.Background, classNames.Background)}
        onClick={handleOutsideClick}
        aria-hidden={true}
      />
      <div
        className={cx(styles.Wrapper, classNames.Wrapper)}
        role="dialog"
        aria-hidden={!isVisible}
        aria-modal={true}
      >
        {children}
      </div>
    </div>,
    document.getElementById("modal-root") || document.body
  );
};

export const useModal = () => {
  const {
    value: isVisible,
    setOn: open,
    setOff: close,
  } = useToggle({ initialValue: false });

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const modalRoot = document.getElementById("modal-root");
    if (!modalRoot) return;

    const isModalRootEmpty = modalRoot.children.length === 0;
    if (!isModalRootEmpty) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isVisible]);

  return { isVisible, open, close };
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  disableOutsideClick: PropTypes.bool,
  outsideClickCallback: PropTypes.func,
  classNames: PropTypes.object,
  position: PropTypes.oneOf(Object.values(PositionType)),
  animationDuration: PropTypes.number,
};

export default memo(Modal);
