import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import ICON_ARROW from "assets/icons/pos/chevron-down.svg";
import ICON_ERROR from "assets/icons/pos/alert-triangle.svg";
import ICON_WARNING from "assets/icons/pos/alert-circle.svg";

import DropdownOption from "components/pos/dropdown/dropdown-option/DropdownOption";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import Chip, { ENUMS as CHIP_ENUMS } from "components/pos/chip/Chip";
import If from "components/if/If";

import useOutsideClick from "utils/hooks/useOutsideClick";

import styles from "./Dropdown.module.scss";

const Dropdown = ({
  options = [],
  onChange,
  value,
  isGrouped,
  isMultiple,
  isDisabled,
  error,
  hasError,
  hasWarning,
  placeholder,
  search = false,
  showValueWithChips = false,
  className,
  menuMaxHeight,
  text,
  helperText,
  isOptionRequired,
}) => {
  const subValue = isGrouped
    ? options?.find((option) =>
        option.subOptions.some((subOption) => subOption?.id === value?.id)
      )
    : "";

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [openSlide, setOpenSlide, mainElementRef, toggleBtnRef] =
    useOutsideClick();

  const displayedOptions = useMemo(() => {
    if (!options) return [];

    if (searchValue.trim() !== "") {
      return options.filter((option) => {
        return option.name
          .toLowerCase()
          .split(" ")
          .some((word) => word.startsWith(searchValue.toLowerCase()));
      });
    } else {
      return options;
    }
  }, [options, searchValue]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    if (!isMultiple) setOpenSlide(false);
  };
  const displayedValue = useMemo(() => {
    if (!value) return null;
    if (isMultiple && !showValueWithChips && value?.length > 0) {
      return value.flatMap(({ name }) => name).join(", ");
    } else if (!isMultiple && Object.entries(value).length > 0) {
      return value?.name;
    } else {
      return null;
    }
  }, [isMultiple, showValueWithChips, value]);

  return (
    <div
      className={cx(styles.Root, {
        [styles.hasError]: hasError,
        [styles.hasWarning]: hasWarning,
      })}
    >
      <If state={Boolean(text)}>
        <h4 className={cx(styles.Text, "Medium")}>{text}</h4>
      </If>
      <div className={cx(styles.Dropdown, className)}>
        <div
          className={cx(styles.SelectedItem, {
            [styles.isDisabled]: isDisabled,
            [styles.isOpenOptions]: openSlide,
            [styles.hasValue]: displayedValue,
          })}
          onClick={() => setOpenSlide(isDisabled ? false : !openSlide)}
          ref={toggleBtnRef}
        >
          <div className={styles.SelectedItemTitle}>
            <h5
              className={cx(styles.Label, {
                [styles.isCentered]:
                  !value || (Array.isArray(value) && value.length === 0),
                [styles.hasError]: hasError,
                [styles.hasChips]:
                  showValueWithChips &&
                  value &&
                  Object.entries(value).length > 0,
              })}
            >
              {placeholder}
            </h5>
            <If state={!showValueWithChips}>
              <h5>
                {displayedValue}
                {isGrouped && value && subValue && (
                  <span className={styles.SelectedItemSubTitle}>
                    {`(${subValue.name})`}
                  </span>
                )}
              </h5>
            </If>
            <If state={showValueWithChips && isMultiple}>
              <div className={styles.Chips}>
                {Array.isArray(value) &&
                  value.map((option) => (
                    <Chip
                      key={option.id}
                      className={styles.DropdownChip}
                      title={option.name}
                      type={CHIP_ENUMS.TYPE.types.SECONDARY}
                      shapeType={CHIP_ENUMS.SHAPE_TYPE.types.CIRCLE}
                    />
                  ))}
              </div>
            </If>
            <If state={showValueWithChips && !isMultiple}>
              <Chip
                className={styles.DropdownChip}
                title={displayedValue}
                type={CHIP_ENUMS.TYPE.types.SECONDARY}
                shapeType={CHIP_ENUMS.SHAPE_TYPE.types.CIRCLE}
              />
            </If>
          </div>
          <img
            className={cx(styles.Arrow, {
              [styles.isOpen]: openSlide,
              hasError,
              hasWarning,
            })}
            src={hasError ? ICON_ERROR : hasWarning ? ICON_WARNING : ICON_ARROW}
            alt="arrow"
          />
        </div>
        <If state={openSlide}>
          <div
            className={styles.Options}
            ref={mainElementRef}
            style={{
              maxHeight: menuMaxHeight ? `${menuMaxHeight}px` : "240px",
            }}
          >
            <If state={search}>
              <Search
                onChange={(value) => {
                  handleSearchChange(value);
                }}
                value={searchValue}
                type={SEARCH_ENUMS.types.TYPE_F}
              />
            </If>
            <If state={displayedOptions.length === 0}>
              <h6 className={styles.EmptyState}>{t("emptyStates.noOption")}</h6>
            </If>
            <If state={displayedOptions.length > 0}>
              {displayedOptions.map((option, index) =>
                isGrouped ? (
                  <div key={index}>
                    <h6 className={cx(styles.GroupName, "SemiBold")}>
                      {option.name}
                    </h6>
                    {option.subOptions.map((subOption, subIndex) => (
                      <DropdownOption
                        key={subIndex}
                        option={subOption}
                        value={value}
                        handleOptionClick={handleOptionClick}
                        isMultiple={isMultiple}
                        isGrouped={isGrouped}
                        isOptionRequired={isOptionRequired}
                      />
                    ))}
                  </div>
                ) : (
                  <DropdownOption
                    key={index}
                    option={option}
                    value={value}
                    handleOptionClick={handleOptionClick}
                    isMultiple={isMultiple}
                    isOptionRequired={isOptionRequired}
                  />
                )
              )}
            </If>
          </div>
        </If>

        {error}
      </div>
      <h5 className={cx(styles.HelperText, "Medium")}>
        {helperText && !openSlide && helperText}
      </h5>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        subOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            name: PropTypes.string.isRequired,
          })
        ).isRequired,
      }),
    ])
  ),
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isGrouped: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  className: PropTypes.string,
  menuMaxHeight: PropTypes.number,
  showValueWithChips: PropTypes.bool,
  error: PropTypes.node,
  isOptionRequired: PropTypes.bool,
};

export default Dropdown;
