import React from "react";
import PropTypes from "prop-types";

import "./AnimatedWord.scss";

const AnimatedWord = ({ firstText, messages }) => {
  return (
    <div className="AnimatedWordContainer">
      <h1 className="SemiBold">{firstText}</h1>
      <div className="AnimatedWords">
        {messages.map((msg, index) => (
          <span key={index} className="AnimatedWord">
            {msg}
          </span>
        ))}
      </div>
    </div>
  );
};

AnimatedWord.propTypes = {
  messages: PropTypes.array,
  firstText: PropTypes.string,
};

export default AnimatedWord;
