import TermsAndCondition from "utils/constants/i18n/TermsAndCondition";
import PrivacyPolicy from "utils/constants/i18n/PrivacyPolicy";

const TRANSLATION = {
  welcome: "მოგესალმებით (ქართველს)",
  common: {
    settings: "პარამეტრები",
    about: "შესახებ",
    home: "მთავარი",
    logo: "ლოგო",
    shape: "ფორმა",
    selected: "არჩეული",
    create: "შექმნა",
    notFound: "არ მოიძებნა",
    applied: "გამოყენებული",
    yourLikes: "თქვენი მოწონებები",
    details: "დეტალები",
    published: "გამოქვეყნებული",
    unPublished: "გამოუქვეყნებელი",
    general: "ზოგადი",
    address: "მისამართი",
    wifi: "ვაი-ფაი",
    sort: "დალაგება",
    copied: "კოპირებული",
    contact: "კონტაქტი",
    pages: "გვერდები",
    kcal: "კკალ",
    currency: "ვალუტა",
    connect: "დაკავშირება",
    disconnect: "გათიშვა",
    confirmDisconnect: "დარწმუნებული ხართ, რომ გსურთ გათიშვა?",
    connection: "კავშირი",
    qr: "კოდი",
    telegram: "ტელეგრამი",
    enable: "ჩართვა",
    disable: "გამორთვა",
    zoneAndTable: "ზონა და მაგიდა",
    printers: "პრინტერები",
    connectToWifi: "ვაი-ფაი-თან დაკავშირება",
    connectWifi: "ვაი-ფაი-თან დაკავშირება",
    disconnectFromWifi: "ვაი-ფაი-დან გათიშვა",
    name: "სახელი",
    active: "აქტიური",
    inactive: "არააქტიური",
    date: {
      date: "თარიღი",
      time: "დრო",
      createdAt: "შექმნის თარიღი",
      dateCreated: "შექმნის თარიღი",
      dateSelection: "თარიღის არჩევა",
      schedule: "მუშაობს დაგეგმილი პერიოდების განმავლობაში",
    },
    time: {
      hour: "საათი",
      minute: "წუთი",
      second: "წამი",
      from: "დან",
      to: "მდე",
      since: "დაწყებიდან",
      min: "წთ",
      shortVersion: {
        hour: "სთ",
        minute: "წთ",
        second: "წმ",
      },
    },
    days: {
      days: "დღეები",
      monday: "ორშაბათი",
      tuesday: "სამშაბათი",
      wednesday: "ოთხშაბათი",
      thursday: "ხუთშაბათი",
      friday: "პარასკევი",
      saturday: "შაბათი",
      sunday: "კვირა",
      everyday: "ყოველდღე",
      everyWeekday: "ყოველ სამუშაო დღეს",
      weekend: "უიქენდი",
    },
  },
  navbarRoutes: {
    magicOrder: "ჯადოსნური შეკვეთა",
    aiSearch: "ინტელექტური ძებნა",
    account: "ანგარიში",
    order: "შეკვეთა",
    statistics: "სტატისტიკა",
    dashboard: "დაფა",
    salesStatistics: "გაყიდვების სტატისტიკა",
    orderStatistics: "შეკვეთების სტატისტიკა",
    allOrders: "ყველა შეკვეთა",
    menuStatistics: "მენიუს სტატისტიკა",
    allItems: "ყველა პროდუქტი",
    feedbacks: "გამოხმაურებები",
    staff: "პერსონალი",
    weeklyStatistics: "ყოველკვირეული სტატისტიკა",
    tables: "მაგიდები",
    menu: "მენიუ",
    business: "ბიზნესი",
    infra: "ინფრასტრუქტურა",
    users: "მომხმარებლები",
    qrAndTable: "კოდი და მაგიდა",
    table: "მაგიდა",
    qr: "კოდი",
    reservation: "რეზერვაცია",
    devices: "მოწყობილობები",
    map: "რუკა",
    orders: "შეკვეთები",
    departments: "დეპარტამენტები",
    superAdmin: "სუპერ ადმინი",
    inventory: "ინვენტარი",
    funZone: "გასართობი ზონა",
    home: "მთავარი",
    guests: "სტუმრები",
    payments: "გადახდები",
    profile: "ჩემი პროფილი",
    ipBlacklist: "IP შავი სია",
    pageTitles: {
      superAdmin: "სუპერ ადმინი",
      infra: "ინფრასტრუქტურა",
      guests: "სტუმრები",
      weeklyStatistics: "ყოველკვირეული სტატისტიკა",
      salesStatistics: "გაყიდვების სტატისტიკა",
      orderStatistics: "შეკვეთების სტატისტიკა",
      menuStatistics: "მენიუს სტატისტიკა",
      allOrders: "ყველა შეკვეთა",
      departments: "დეპარტამენტები",
      allItems: "ყველა პროდუქტი",
      feedbacks: "გამოხმაურებები",
      category: "კატეგორია",
      settings: "პარამეტრები",
      business: "ბიზნესი",
      users: "მომხმარებლები",
      zones: "ზონები",
      tables: "მაგიდები",
      qrList: "კოდის სია",

      integrations: "ინტეგრაციები",
      payments: "გადახდები",
      telegramIntegration: "ტელეგრამი - ინტეგრაცია",
    },
  },
  emptyTable: {
    emptyTableDescription: "თქვენ ჯერ არ გაქვთ რაიმე ზონა.",
    emptyZoneDescription: "ამ ზონაში არ არსებობს მაგიდები.",
    emptyQR: "თქვენ ჯერ არ გაქვთ კოდები.",
    emptyZoneTitle: "რუკაზე არ არსებობს მაგიდები",
  },
  language: {
    language: "ენა",
    english: "ინგლისური",
    russian: "რუსული",
    azerbaijani: "აზერბაიჯანული",
    german: "გერმანული",
  },

  buttons: {
    revive: "გაცოცხლებული",
    addItem: "დამატება",
    editItem: "ჩასწორება",
    addQR: "კოდის დამატება",
    back: "უკან",
    resetData: "გადატვირთვა",
    createQR: "კოდის შექმნა",
    createZone: "ზონის შექმნა",
    createOrder: "შეკვეთის შექმნა",
    edit: "ჩასწორება",
    delete: "წაშლა",
    confirm: "დადასტურება",
    finish: "დასრულება",
    discard: "უარყოფა",
    change: "შეცვლა",
    deny: "უარყოფა",
    save: "შენახვა",
    close: "დახურვა",
    saveChanges: "ცვლილებების შენახვა",
    all: "ყველა",
    selectAll: "ყველას მონიშვნა",
    seeAll: "ყველას ნახვა",
    seeLess: "ნაკლების ნახვა",
    add: "დამატება",
    addNew: "ახლის დამატება",
    reset: "გაწმენდა",

    resend: "თავიდან გაგზავნა",
    clearFilters: "ფილტრების გასუფთავება",
    pay: "გადახდა",
    clear: "გასუფთავება",
    apply: "გამოყენება",
    defaultLanguage: "ნაგულისხმევი ენა",
    defaultCurrency: "ნაგულისხმევი ვალუტა",
    availableLanguages: "ხელმისაწვდომი ენები",
    currency: "ნაგულისხმევი ვალუტა",
    askAi: "AI-ს კითხვა",
    link: "ბმული",
    links: "ბმულები",
    unlink: "ბმულის წაშლა",
    addBasket: "კალათაში დამატება",
    addToOrder: "შეკვეთაში დამატება",
    clickToAdd: "დააწკაპეთ დასამატებლად",
    clickToAddImage: "დააწკაპეთ სურათის დასამატებლად",
    clickToUpload: "დააწკაპეთ ასატვირთად",
    logOut: "ჩადიხარ",
    login: "შესვლა",
    lockScreen: "ბლოკვა",
    goToDashboard: "დაფაზე გადასვლა",
    rememberMe: "დამახსოვრება",
    forgotPassword: "დაგავიწყდა პაროლი?",
    payWith: "გადახდა",
    seeMenu: "მენიუს ნახვა",
    addOrder: "ახალი შეკვეთა",
    readMore: "მეტის წაკითხვა",
    serve: "მომსახურება",
    confirmOrder: "შეკვეთის დადასტურება",
    backToMenu: "მენიუში დაბრუნება",
    send: "გაგზავნა",
    tryAgain: "თავიდან ცდა",
    update: "განახლება",
    continue: "გაგრძელება",
    verify: "ვერიფიკაცია",
    applyDiscount: "ფასდაკლების გამოყენება",
    discountProducts: "ფასდაკლებული პროდუქტები",
    print: "ბეჭდვა",
    export: "ექსპორტი",
    closeOrder: "შეკვეთის დასრულება",
    printBill: "ინვოისის ბეჭდვა",
    fullTables: "შევსებული მაგიდები",
    editForModal: "{{title}}-ის ჩასწორება",
    addForModal: "{{title}}-ის დამატება",
    successfullySent: "წარმატებით გაიგზავნა",
    sent: "გაგზავნილია",
    reserve: "დაჯავშნა",
    skip: "გამოტოვება",
    source: "წყარო",
    target: "სამიზნე",
    editZone: "ზონის ჩასწორება",
    contactUs: "დაგვიკავშირდით",
  },
  inputs: {
    name: "სახელი",
    username: "მომხმარებლის სახელი",
    description: "აღწერა",
    example: "მაგალითი",
    price: "ფასი",
    discount: "ფასდაკლება",
    role: "როლი",
    phoneNumber: "ტელეფონის ნომერი",
    pinCode: "პინი კოდი",
    pinCodeConfirm: "პინის დადასტურება",
    firstName: "სახელი",
    lastName: "გვარი",
    email: "ელფოსტა",
    password: "პაროლი",
    confirmPassword: "პაროლის დადასტურება",
    newPassword: "ახალი პაროლი",
    oldPassword: "ძველი პაროლი",
    businessName: "ბიზნესის სახელი",
    city: "ქალაქი",
    zipCode: "ინდექსი",
    street: "ქუჩა",
    map: "Google Maps-ის ბმული",
    serviceFee: "მომსახურების საფასური",
    search: "ძებნა",
    enterPromoCode: "პრომო კოდის შეყვანა",
    link: "ბმულის დამატება",
    instagram: "ინსტაგრამი-ის ბმული",
    phone: "ტელეფონის დამატება",
    contactPhone: "ტელეფონის ნომერი",
    whatsapp: "ვაცაფი ნომერი",
    mail: "ელფოსტის დამატება",
    contactMail: "ელფოსტის მისამართი",
    tableName: "მაგიდის სახელი",
    usernameExample: "@example",
    chooseCurrent: "მიმდინარე არჩევა",
    seats: "ადგილი",
    minDeposit: "მინ. დეპოზიტი",
    qrName: "კოდის სახელი",
    wifiName: "ვაი-ფაი სქელი",
    sell: "გაყიდვის ფასი",
    cost: "ფასი",
    calories: "კალორიები",
    time: "დრო",
    amount: "რაოდენობა",
    unit: "ერთეული",
    addComment: "კომენტარის დამატება",
    warningAmount: "გაფრთხილების რაოდენობა",
    number: "რიცხვი",
    notFixed: "არაფიქსირებული",
    fixed: "ფიქსირებული",
    maxNumber: "მაქსიმალური რაოდენობა",
    deposit: "დეპოზიტი",
    yourName: "თქვენი სახელი",
    contact: "კონტაქტი",
    reserveeName: "დამკვეთის სახელი",
    numberOfGuest: "სტუმრების რაოდენობა",
    date: "თარიღი",
    duration: "ხანგრძლივობა",
    comment: "კომენტარი...",
    commentText: "კომენტარი",
    fullName: "სრული სახელი",
    dateOfBirth: "დაბადების თარიღი",
  },
  image: {
    images: "სურათები",
    selectCover: "დააჭირეთ სურათს, რომ დააყენოთ როგორც საფარ-სურათი",
    background: "ფონის სურათი",
    other: "სხვა სურათები",
    addPhoto: "დამატეთ ფოტო",
    addImage: "დამატეთ სურათი",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "შეცვლის სახელი",
    modification: "შეცვლა",
    modifications: "შეცვლები",
    noModifications: "მომდევნო ცვლილებები ჯერ არ არის დამატებული.",
    noModificationOption: "არ არის დამატებული ცვლილების ვარიანტები.",
    addModification: "დამატეთ შეცვლა",
    editModification: "რედაქტირება შეცვლის",
    selection: {
      selection: "მორჩევა",
      types: "მორჩევის ტიპი",
      singleSelection: "მხოლოდ ერთი",
      singleSelectionDescription: "მხოლოდ ერთი ვარიანტი შეიძლება აირჩეს",
      multiSelection: "მრავალი",
      multiSelectionDescription: "მრავალი ვარიანტი შეიძლება აირჩეს",
      option: {
        options: "ვარიანტები",
        onlyOnce: "მხოლოდ ერთხელ",
        manyTimes: "მრავალი ჯერ",
        default: "ნაგულისხმევი არჩევანი",
        addLanguage: "დამატეთ ენა",
      },
    },
  },
  user: {
    staff: "პერსონალი",
    employee: "თანამშრომელი",
    addEmployee: "თანამშრომლის დამატება",
    editEmployee: "თანამშრომლის შეცვლა",
    sendAllDetails: "შექმნათ ახალი პაროლი და გამოაგზავნოთ მომხმარებელთან",
    userDeleteError: "თქვენ ვერ წაიშლით თქვენს პროფილს",
    generatePassword: "შექმენით ახალი პაროლი და გამოაგზავნეთ მომხმარებელს.",
    changeYourPassword: "გახადეთ თქვენი პაროლი",
  },
  guests: {
    guest: "სტუმარი",
    guests: "სტუმრები",
    registeredGuest: "დარეგისტრირებული",
    unregisteredGuest: "არარეგისტრირებული",
    allBusinesses: "ყველა ბიზნესი",
  },
  table: {
    connected: "დაკავშირებულია კოდით",
    tablesLowercase: "მაგიდები",
    tables: "მაგიდები",
    table: "მაგიდა",
    addTable: "მაგიდის დამატება",
    editTable: "მაგიდის შეცვლა",
    selectTable: "მაგიდის არჩევა",
    busy: "მაგიდები დაკავებულია",
    available: "მაგიდები ხელმისაწვდომია",
    full: "სავსე",
    noTablesVisible: "ამჟამად არ არის მაგიდები ნაჩვენები რუკაზე.",
    map: "რუკა",
    current: "მიმდინარე",
  },
  zone: {
    zone: "ზონა",
    tables: "მაგიდები",
    zones: "ზონები",
    name: "ზონის სახელი",
    allZones: "ყველა ზონა",
    selectZone: "ზონის არჩევა",
  },
  map: {
    removeFromMap: "წაშალეთ რუკიდან",
    shapes: {
      rectangle: "კვადრატი",
      circle: "მრგვალი",
    },
    createTable: "მაგიდის შექმნა",
    addToMap: "დამატება რუკაზე",
    windowAndDoor: "ფანჯარა და კარი",
    designElements: "დიზაინის ელემენტები",
    decoration: "მორთვა",
    reset: "გახსნა",
    width: "სიგანე",
    height: "სიმაღლე",
  },
  sort: {
    az: "ა-ჰ",
    cheapToExpensive: "უნიჭიერო დალიდისაკენ",
    expensiveToCheap: "ვართ სიტყვებით მომთხოვნიდან",
    new: "ახალი",
  },
  qr: {
    qr: "კოდი",
    qrCode: "კოდის კოდი",
    name: "კოდის სახელი",
    list: "კოდის სია",
    id: "იდენტიფიკატორი",
    type: "კოდის ტიპი",
    status: "სტატუსი",
    unlinked: "არა დაკავშირებული",
    linked: "დაკავშირებულია",
    qrIsNotAdded: "კოდი არ არის დამატებული",
    connected: "დაკავშირებულია",
    notConnected: "არ არის დაკავშირებული",
    scanToConnect: "ან სკანირეთ დასაკავშირებლად",
    QRLinkCopied: "კოდის ბმული გადაკოპირებულია",
    qrScan: "სკანირება კოდთან",
    scanCount: "სკანირების რაოდენობა",
    qrScanDescription:
      "* დაასკანერეთ კოდი მომხმარებლის ტელეფონით და დარწმუნდით, რომ ტელეგრამი დაყენებულია.",
    types: {
      qrType: "კოდის ტიპი",
      wifi: "ვაი-ფაი",
      menu: "მენიუ",
    },
    downloadAllQr: "ყველა კოდის ჩამოტვირთვა",
    downloadQr: "QR კოდის ჩამოტვირთვა",
    generateQR: "QR კოდის გენერაცია",
    custom: "მორგებული",
    forEachCategory: "თითოეული კატეგორიისთვის",
    params: "პარამეტრები",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "კი, ვადასტურებ ჩემს შეკვეთას და ვიღებ პასუხისმგებლობას გადახდაზე.",
    areYouSureYouWantToPlaceThisOrder:
      "დარწმუნებული ხართ, რომ გსურთ ამ შეკვეთის განთავსება?",
    deleteModalTitle: "დარწმუნებული ხართ, რომ გსურთ წაშლა?",
    deleteMenuItemTitle: "დარწმუნებული ხართ, რომ გსურთ ამ ნივთების წაშლა?",
    deleteModalDescription: "ეს მოქმედება არ არის ასატეხი",
    warningModalTitleFinishOrder:
      "დარწმუნებული ხართ, რომ გსურთ ამ შეკვეთის დასრულება?",
    accessDenied: "საწვდომობა უარყოფილია",
    permissionRequired: "საჭირო არის ნებართვა",
    confirmModalTitleLogOut: "დარწმუნებული ხართ, რომ გსურთ გასვლა?",
    confirmModalDescriptionLogOut: "შეძლებთ ნებისმიერ დროს დაბრუნებას",
    warningModalTitleChangeZone:
      "დარწმუნებული ხართ, რომ გსურთ ამ ზონის შეცვლა?",
    warningModalTitleLogout: "დარწმუნებული ხართ, რომ გსურთ გასვლა?",
    warningModalTitleNewOrder:
      "თქვენ შექმნით ახალ შეკვეთას {{zone}}/{{table}}. დარწმუნებული ხართ?",
    warningModalDescriptionNewOrder:
      "გთხოვთ დააჭირეთ „კი“ ბტონს, რომ შექმნათ ახალი შეკვეთა",
    warningModalTitleUnsavedChanges:
      "დარწმუნებული ხართ, რომ გსურთ შეცვალოთ, ისე რომ ცვლილებები არ იყოს შენახული?",
    warningModalDescription: "თქვენი რედაქტირება არ იქნება შენახული",
    yes: "კი",
    no: "არა",
    close: "დახურვა",
    apply: "გამოიყენე",
    id: "id",
    preview: "წინასწარი ნახვა",
    cannotBeRemoved: "დადასტურებული ნივთები ვერ იქნებიან წაშლილი",
    unConfirmedCannotBeRemoved:
      "დადასტურებული არ ნივთები არ შეიძლება გადახდილი",
    payMenuItemTitle: "დარწმუნებული ხართ, რომ გსურთ ამ არჩევანის გადახდა?",
    guestProfile: "მივესალმო პროფილი",
    totalVisits: "მთლიანი ვიზიტები",
    averageAmount: "საშუალო თანხა",
    topOrder: "უახლესი {{count}} შეკვეთა",
    createdBy: "შექმნილია",
    paymentMethod: "გადახდის მეთოდი",
    orderId: "შეკვეთის ნომერი",
    transactionId: "ტრანზაქციის ნომერი",
    visitHistory: "ვიზიტის ისტორია",
    emptyOrderInfo:
      "შეკვეთა არ არის. დააფიქსირეთ კოდი ან დაუკავშირდით მიმტანს.",
    closeOrderConfirmation: "როგორ გსურთ შეკვეთის დახურვა?",
  },
  menu: {
    preview: "მენიუს წინასწარი ნახვა",
    search: "ეძებეთ თქვენი საყვარელი საჭმელი...",
    translate: {
      ai: "マジック თარგმანი",
      translating: "თარგმნის...",
      aiAutoTranslate: "ინტელექტური თარგმანი",
      languageSelection: "ენა არჩევანი",
      successfulTranslate: "წარმატებით თარგმნილია",
      waitingTranslate:
        "AI-ს უნდა რამდენიმე წუთი თქვენი მენიუს თარგმნისთვის. გთხოვთ გაითვალისწინოთ, რომ მენიუს ელემენტები ვერ იქნებიან რედაქტირებული ამ დროს.",
      magicTranslate: "თარგმნე",
      itemErrorMessage:
        "ეს ნივთი არ არსებობს წყარო ენაში. თარგმნისთვის გთხოვთ დაამატოთ ნივთი წყარო ენაში.",
      categoryErrorMessage:
        "ეს კატეგორია არ არსებობს წყარო ენაში. თარგმნისთვის გთხოვთ დაამატოთ კატეგორია წყარო ენაში.",
    },
    tag: {
      tag: "თაგი",
      tags: "თაგები",
      name: "თაგის სახელი",
    },
    category: {
      category: "კატეგორია",
      categories: "კატეგორიები",
      add: "დამატეთ კატეგორია",
      all: "ყველა კატეგორია",
      name: "კატეგორიის სახელი",
      mealCategories: "საკვების კატეგორიები",
      emptyGuestMenuTitle: "მენიუ ცარიელია",
      emptyGuestMenuDescription:
        "ჩანს რომ ბიზნესმა ჯერ არაფერი დაუმატა მენიუში",
      emptyAdminCategoryDescription: "თქვენ ჯერ არ გაქვთ კატეგორიები.",
    },
    item: {
      items: "ნივთები",
      item: "ნივთი",
      all: "ყველა ნივთი",
      itemLowerCase: "ნივთი",
      itemsLowerCase: "ნივთები",
      selected: "არჩეული ნივთები",
      deleteItem: "წაშალეთ ნივთი",
      updateItem: "განაახლეთ ნივთი",
      duplicateItem: "კოპირება ნივთი",
      duplicate: "კოპირება",
      scheduleDiscount: "დროებით დისკონტი",
      emptyAdminMenuItemDescription: "თქვენ ჯერ არ გაქვთ ნივთები.",
      itemDeleteError:
        "თქვენ არ შეგიძლიათ ამ პროდუქტების წაშლა, რადგან იგი აქტიურ შეკვეთაშია.",
    },
    view: {
      selection: "ნაგულისხმევი მენიუ ნახვა",
      scroll: "გახეხვა",
      withImage: "სურათით",
      withoutImage: "სურათის გარეშე",
      list: "ნუსხა",
      threeD: "3D",
      poker: "პოკერი",
      grid: "გადანართვა",
    },
    filter: {
      filters: "ფილტრები",
      priceRange: "ფასის დიაპაზონი",
      noResult: "შედეგი არ არსებობს",
      noResultDesc: "აქტიური ფილტრები მალავენ ყველა მენიუს ნივთებს.",
    },
    settings: {
      color: "ფერი",
      radius: "პირის რადიუსი",
      roundness: "მრგვალობა",
      currency: "ვალუტა",
      colors: {
        primary: "მთავარი ფერები",
        red: "წითელი",
        gold: "ოქროსფერი",
        purple: "ლურჯი",
        blue: "ლურჯი",
      },
      curves: {
        elementCurves: "ელემენტების მრუდები",
        none: "არაფერი",
        small: "პატარა",
        medium: "საშუალო",
        large: "დიდი",
      },
      changeProperties: "პირობების შეცვლა",
      details: "მენიუს დეტალები",
    },
    viewAr: "AR ნახვა",
  },
  menuV2: {
    selectLanguage: "აირჩიეთ ენა",
  },
  business: {
    businesses: "ბიზნესი",
    list: "ბიზნეის სია",
    staffList: "{{count}} თანამშრომელი",
    selectTitle: "აირჩიეთ ბიზნესი",
    selectSubTitle:
      "გთხოვთ აირჩიოთ ბიზნესი, რომელშიც გსურთ შესვლა და გაგრძელება",
    contact: "კონტაქტი",
    contactWith: "კონტაქტი თან",
    workingHours: "სამუშაო საათები",
    needToKnow: "უნდა იცოდეთ",
    addNew: "ბიზნესი დამატება",
    edit: "ბიზნესი შეცვლა",
    plan: "გეგმა",
    selectPlan: "აირჩიეთ გეგმა",
    creationDate: "შექმნის თარიღი",
    status: "სტატუსი",
    verified: "დადასტურებულია",
    unverified: "არ არის დადასტურებული",
    businessStatus: "ბიზნესი სტატუსი",
    expirationDate: "ვადაგასვლის თარიღი",
    country: "ქვეყანა",
    printers: "პრინტერები",
    wiFi: "ვაი-ფაი",
    printer: "პრინტერი",
    active: "აქტიური",
    inActive: "არააქტიური",
    connectedTo: "დაკავშირებულია",
    guestBusinessBlockTitle: "ეს ბიზნესი არ არის აქტიური.",
    guestBusinessBlockSubTitle: "ვწუხვართ, ეს ბიზნესი აღარ არის ხელმისაწვდომი.",
    settings: {
      goLive: "დასაწყისი",
      chat: "ჩატი",
      realTimeOrder: "რეალური დრო",
      menuItemCalorie: "კალორია",
      menuItemTime: "დრო",
      menuItemAmount: "რაოდენობა",
      reservation: "ჯავშანი",
      reservationMap: "მაჩვენე რუკა ჯავშანში",
      chatSound: "ჩატ ხმა",
      orderSound: "შეკვეთის ხმა",
      askForBillSound: "ანგარიშის მოთხოვნა",
      callWaiterSound: "ემპლუი დარეკვა",
      isAskForBillEnabled: "ანგარიშის მოთხოვნა",
      isCallWaiterEnabled: "ემპლუი დარეკვა",
      menu: "მენიუ",
      sound: "ხმა",
      general: "საერთო",
      isOnlinePaymentEnabled: "გადახდაც",
      order: "შეკვეთა",
      action: "მოქმედება",
      showInBusinessCatalogue: "დამატება ბიზნეს კატალოგში",
      reservationVRLink: "მომხმარებლის virtual (VR) ნახვა",
      reservationVREnabled: "ვირტუალური ნახვა",
      reservationMapEnabled: "რუკა ნახვა",
      permission: "დამოწმებული უფლებები",
      declineOrderItemsEnabled: "დაკავშირება შეღწევა",
      deleteOrderEnabled: "შეკვეთის წაშლა",
      deleteMenuItemsEnabled: "მენიუს ობიექტების წაშლა",
      editMenuEnabled: "მენიუს რედაქტირება",
      virtualTour: "ვირტუალური ტური",
      vrLink: "ვირტუალური ლინკის დამატება",
      vrLinkEnabled: "ვირტუალური ხედი",
    },
  },
  orderItem: {
    denied: "უარყოფილია",
    deleted: "წაშლილია",
    empty: "ცარიელი",
  },
  basket: {
    basket: "კალათა",
    favorites: "სიყვარული",
    basketAndFavorites: "კალათა & არჩევანი",
    addMessage: "შეტყობინების დამატება",
    messagePlaceholder: "მოცემული მოთხოვნები, ალერგია, დიეტური შეზღუდვები?",
    itemAdded: "{{count}} ნივთი დაემატა კალათაში",
    emptyFavorite: "თქვენ არ გაქვთ არჩევანი ჯერ",
    added: "დამატებულია",
    order: {
      info: "შეკვეთის ინფორმაცია",
      subtotal: "სუბტოტალი",
      total: "მთლიანობა",
      tax: "ტაქსი",
      promoCode: "პრომო კოდი",
      totalCost: "სულ თანხა",
      orders: "შეკვეთები",
      myOrders: "ჩემი შეკვეთები",
      ordersLowerCase: "შეკვეთები",
      orderLowerCase: "შეკვეთა",
      order: "შეკვეთა",
      bill: "ანგარიში",
      noOrder: "არ არსებობს ხელმისაწვდომი შეკვეთა",
      noOrders: "არანაირი შეკვეთა",
      deletedOrderTitle: "თქვენი შეკვეთა წაშლილია",
      deletedOrderDescription:
        "ვწუხვართ, თქვენი შეკვეთა წაშლილია ჩვენი ადმინისტრატორის ან ემპლუის მიერ",
      notifications: "შეტყობინებები",
      updatesToSave: "მომდევნო ცვლილებები",
      newOrder: "ახალი შეკვეთა",
      deleteOrder: "შეკვეთის წაშლა",
      finishOrder: "შეკვეთის დასრულება",
      done: "მოგვარებული",
      preparing: "მზადდება",
      numberOfGuests: "სტუმრების რაოდენობა",
      fullNessOfTable: "მაგიდის სავსე",
      acceptAll: "მიღება ყველა",
    },
    comment: {
      addMessage: "შეტყობინების დამატება რესტორანში",
      addMessageDesc: "მოცემული მოთხოვნები, ალერგია, დიეტური შეზღუდვები?",
    },
    emptyBasketTitle: "თქვენი კალათა ცარიელია",
    emptyBasketDescription:
      "მიუხედავად იმისა, რომ არაფერი მოგიწიათ, თქვენ არ გადმოწერეთ კალათაში",
  },
  orders: {
    orders: "შეკვეთები",
    order: "შეკვეთა",
    guests: "მოსამსახურეები",
    guest: "მოსამსახურე",
    fullTables: "სავსე მაგიდები",
  },
  funZone: {
    selectGame: "არჩიე თამაში",
    playGame: "თამაში",
    who: "ვინ",
    paysTheBill: "გადაისახავს ანგარიშს?",
    mindGame: "მეხსიერება",
    canYouRemember: "შეგიძლიათ გაიხსენოთ?",
    tower: "სამაგიდო გრე",
    buildYourHighestTower: "შექმენით თქვენი უმაღლესი გრე",
    ticTackToe: {
      title: "ტიკ-ტაკ-ტოე",
      player: "მოთამაშე",
      turnFor: "მომდევნო გასვლა: {{player}}",
      win: "გაიმარჯვა",
      outsmartYourOpponent: "გატყუე თქვენი მოწინააღმდეგე!",
      draw: "წუწაობა",
      points: "ქულები",
    },
    wheelOfFortune: {
      spinnerGame: "სპინერის თამაში",
      who: "ვინ?",
      whoWillPayTheBill: "ვინ გადაიხდის?",
      whoWillGoToTheMarket: "ვინ წავა ბაზარში?",
      whoWillBuyCigarettes: "ვინ იყიდის სიგარეტს?",
      whoWillHaveAnotherShot: "ვინ მიიღებს კიდევ ერთს?",
      whoWillGetDessert: "ვინ მიიღებს დესერტს?",
      whoWillChooseTheMusic: "ვინ აირჩევს მუსიკას?",
      whoWillSingKaraoke: "ვინ იმღერებს?",
      start: "დაწყება",
      spinning: "ბრუნდება...",
      editToPlay: "შეცვალეთ თამაში",
      addNew: "დამატება ახალი",
      saveAndContinue: "დამახსოვრება და გაგრძელება",
      typeHere: "დაწერეთ აქ",
      result: "რეზულტატი",
      youAreTheChosenOne: "თქვენი ხართ არჩეული",
    },
    memoryCardGame: {
      mindGame: "მეხსიერების თამაში",
      restart: "გახსენება",
      winMessage: "თქვენ გაიმარჯვეთ",
      loseMessage: "თქვენ წააგეთ",
      secondsLeft: "წამი დარჩა",
      triesLeft: "მოწოდები დარჩა",
      startNewGame: "დაწყება ახალი თამაში",
    },
    towerGame: {
      blocks: "ბლოკები",
      clickToPlay: "დააჭირეთ თამაში",
      clickToRestart: "დააჭირეთ განახლება",
    },
  },

  dashboard: {
    dashboard: "დაშბორდი",
    startTalk: "საუბრის დაწყება",
    sales: "გაყიდვები",
    salesAnalytics: "ჯამური გაყიდვები / საათი",
    weekStatistics: "კვირის სტატისტიკა",
    statistics: "სტატისტიკა",
    totalSales: "ჯამური გაყიდვები",
    paymentMethodAnalysis: "გადახდის მეთოდის ანალიზი",
    byNumberOfOrders: "შეკვეთების რაოდენობით",
    byTheAmountOfSales: "გაყიდვების რაოდენობით",
    byTheNumberOfRatings: "რეიტინგების რაოდენობით",
    bestselling: "ყველაზე გაყიდვადი",
    worstSelling: "ყველაზე ნაკლებად გაყიდვადი",
    processing: "პროცესირებულია",
    billReady: "ანგარიში მზად იქნება",
    unSelect: "ყველა გამორთვა",
    selectToPay: "აირჩიეთ გადახდისთვის",
    denied: "უარყოფილია",
    showMore: "მეტის ჩვენება",
    showLess: "...მეტის შემცირება",
    bestSellingCategory: "ყველაზე გაყიდვადი კატეგორია",
    topPerformer: "ტოპ გამოსავალი",
    lowPerformer: "დაბალი გამოსავალი",
    noResultForThisPeriodMsg: "ამ პერიოდში არ არსებობს შედეგები",
    all: "ყველა",
    yourTable: "თქვენი მაგიდა",
    totalGuests: "ჯამური სტუმრები",
    totalItems: "ჯამური ნივთები",
    deleted: "წაშლილი",
    weeklySaleStatistics: "კვირეული გაყიდვების სტატისტიკა",
    weeklyOrderStatistics: "კვირეული შეკვეთების სტატისტიკა",
    paid: "გადახდილია",
    inProgress: "გადიოდა",
    paymentInProgress: "გადახდა მიმდინარეობს",
    legends: {
      sale: "გაყიდვა",
      revenue: "შემოსავალი",
      orders: "შეკვეთების რაოდენობა",
      orderItems: "ნივთების რაოდენობა",
      totalSale: "ჯამური გაყიდვა",
      starCount: "ვარსკვლავების რაოდენობა",
    },
    guest: {
      guests: "სტუმრები",
      guest: "სტუმარი",
      me: "მე",
      review: {
        meal: "მახასიათებელი",
        service: "მომსახურება",
        review: "წესები",
        sent: "გაგზავნილია",
        feedBack: "მოხსენებები",
      },
    },
    staffStatistics: {
      sales: "გაყიდვები",
      orders: "შეკვეთები",
      rating: "რეიტინგი",
    },
    orderStatistics: {
      totalSale: "ჯამური გაყიდვა",
      revenue: "შემოსავალი",
      averageBill: "საშუალო ანგარიში",
      orderCount: "შეკვეთების რაოდენობა",
      guestsServed: "მომსახურებული სტუმრები",
      averageTime: "საშუალო დრო",
      from: "წინა დღიდან",
      orders: "შეკვეთები",
      sale: "გაყიდვა",
      in: "შიგნით",
      out: "გაუშვეს",
      totalPOSTerminalSales: "POS ტერმინალის მთლიანი გაყიდვები",
      totalOnlineSales: "ონლაინ გაყიდვების ჯამი",
      totalCashSales: "ნაღდი ფულის გაყიდვების ჯამი",
    },
    feedbackStatistic: {
      service: "მომსახურების უკუკავშირი",
      meal: "მახასიათებელი უკუკავშირი",
      feedBackTitle: "როგორ გრძნობთ თავს?",
      feedBackDesc:
        "თქვენი გამოხმაურება მნიშვნელოვანია, რათა უკეთ გავიგოთ თქვენი საჭიროებები და შესაბამისად გავზარდოთ ჩვენი მომსახურება.",
      rating: {
        horrible: "საშინელი",
        bad: "ცუდი",
        meh: "ცუდი",
        good: "კარგი",
        awesome: "შესანიშნავი",
      },
    },
    table: {
      adminOrderHistoryTable: {
        staff: "პერსონალი",
        dates: "თარიღები",
        item: "ნივთი",
        subTotal: "სუბტოტალი",
        discount: "დაკლება",
        total: "ჯამი",
        zone: "ზონა",
        table: "მაგიდა",
        service: "მომსახურება",
        fee: "გადასახადი",
        orderDetails: "შეკვეთის დეტალები",
        receipt: "ღირებულება",
      },
      menuStatisticsTable: {
        menuItem: "მენიუს ელემენტი",
        category: "კატეგორია",
        cost: "ღირებულება",
        revenue: "შემოსავალი",
        total: "ჯამი",
        order: "შეკვეთა",
        count: "რაოდენობა",
        price: "ფასი",
        item: "ნივთი",
      },
      businessGuestsTable: {
        name: "სახელი",
        gender: "სქესი",
        dateOfBirth: "დაბადების თარიღი",
        numberOfVisit: "ვიზიტების რაოდენობა",
        guestCount: "სტუმრების რაოდენობა",
        pageSize: "გვერდის ზომა",
      },
    },
    pagination: {
      prev: "წინა",
      next: "შემდეგი",
    },
    prevNext: {
      previous: "წინა",
      next: "შემდეგი",
    },
    placeholder: {
      zone: "ზონა",
      orderId: "შეკვეთის ID",
      staff: "პერსონალი",
      itemName: "ნივთის სახელი",
      actionFilter: "მოქმედების ფილტრი",
      zoneFilter: "ზონის ფილტრი",
      categories: "კატეგორიები",
    },
  },
  login: {
    or: "ან",
    facebook: "Facebook-ით",
    google: "Google-ით",
    pin: "პინ-კოდი",
    alreadyHaveAnAccount: "Already have an account?",
    signIn: {
      title: "შესვლა",
      subTitle: "გაქვთ ანგარიში?",
      method: "შესვლის მეთოდი",
    },
    signUp: {
      termsErrorMessage:
        "*თქვენ უნდა დაეთანხმოთ პირობებსა და წესებს, რომ გაგრძელოთ.",
      title: "რეგისტრაცია",
      subTitleForUserExist:
        "თქვენ უკვე გაქვთ ანგარიში. გთხოვთ შეიყვანოთ არსებული პაროლი.",
      subTitleForUserNotExist:
        "გთხოვთ შეიყვანოთ პაროლი, რომელიც გამოგიგზავნეთ: {{emailOrPhone}}",
      alreadyHaveAccount: "გვაქვს ანგარიში უკვე?",
      passwordSentTo: "პაროლი გამოიგზავნა",
      setPinSubTitle: "დააყენეთ პინი კოდი ყველაზე მარტივი შესვლისთვის",
      agree: "ვთანხმდები შემდეგს:",
      terms: "პირობები",
      privacy: "პირადი მონაცემები",
      enterPassword: "შეიყვანეთ თქვენი პაროლი",
      setPinSubtitle: "დააყენეთ პინი კოდი ყველაზე მარტივი შესვლისთვის",
    },
    forgotPassword: {
      title: "დაგავიწყდათ პაროლი?",
      subTitle:
        "შეიყვანეთ თქვენი {{method}} და ჩვენ გადაგიგზავნით პაროლს ხელახლა.",
      sendPassword: "პაროლის გაგზავნა",
      phoneNumber: "ტელეფონის ნომერი",
      email: "იმეილი",
    },
    resetPassword: {
      title: "პაროლის შეცვლა",
      subTitle: "კარგად დაბრუნდით, ჩვენ დაგვენანეთ!",
    },
    resendPassword: {
      title: "პაროლის ხელახლა გაგზავნა",
      subTitleForUserExist: "დაგავიწყდათ არსებული პაროლი?",
      subTitleForUserNotExist: "არ მიიღეთ პაროლი?",
      buttonTextForUserExist: "გააგზავნეთ ახალი პაროლი",
      buttonTextForUserNotExist: "ხელახლა გაგზავნა",
      resendingIsAvailable: "ხელახლა გაგზავნა ხელმისაწვდომია",
      resendingCodeWillBeAvailableIn:
        "ხელახლა გაგზავნა კოდი იქნება ხელმისაწვდომი",
    },
  },
  payment: {
    receipt: "კვანძი",
    payedBy: "გადახდილია",
    onlinePayment: "ონლაინ გადახდა",
    paymentMessage: "გადახდა {{card}}-ით წარმატებით განხორციელდა",
    paymentFailMessage: "გადახდა {{card}}-ით წარუმატებელი იყო",
    payment: "გადახდა",
    method: "გადახდის მეთოდი",
    pageTitle: "როგორ გსურთ გადაიხადოთ?",
    pageSubtitle: "არჩიეთ მეთოდი, რომ განახორციელოთ თქვენი გადახდა",
    cash: "ნაღდი ფული",
    POSTerminal: "ტერმინალი",
    ready: "მინუტებში მზად!",
    confirmPOSTerminal:
      "დადასტურებული მოთხოვნა. ტერმინალი მალე მოტანდება უარს!",

    confirmCash: "დადასტურებული მოთხოვნა. თქვენი ქვითარი მალე მოტანდება უარს!",
    totalCostFor: "სულ თანხა",
    printsAndBill: "ბეჭდვები და ქვითარი",
    paymentConfirmedTitle:
      "თქვენი შეკვეთა დასრულებულია. მადლობა, რომ გაგვირჩიეთ.",
    paymentConfirmedDescription:
      "ჩვენთვის პატივია თქვენთან სტუმრობა! შევხვდებით მომავალში 👋 გისურვებთ კარგ დღეს 😍",
    businessPaymentsTitle: {
      orderId: "შეკვეთის ID",
      paidPrice: "გადახდილი თანხა",
      currency: "ვალუტა",
      paymentStatus: "გადახდის სტატუსი",
      lastFourDigits: "ბოლო 4 ციფრი",
      cardClassification: "ბარათის კლასიფიკაცია",
      cardHolderName: "ბარათის მფლობელის სახელი",
    },
  },
  waiter: {
    waiter: "მომსახურე",
    waiterLowercase: "მომსახურე",
    call: "დარეკეთ მომსახურე",
    bill: "ბილეთის მოთხოვნა",
    quickActions: "სწრაფი მოქმედებები",
    actions: "მოქმედებები",
  },
  mascot: {
    about: "მშვიდობად {{businessName}}",
    dashboard: {
      support: "მხარდაჭერა და ნახმარი რეზერვები",
      eatIn: "ჭამა ადგილობრივი",
      eatInHall: "ჭამა დარბაზში",
      reserveTable: "მიღება მაგიდა",
      letUs: "დამატება .. ჩვენთვის",
      learnMore: "გაიგეთ მეტი",
      fixProblemText: "გგრძნობთ პრობლემებს აპლიკაციასთან?",
      fixProblemButton: "გადადით აქ გადაჭრისთვის",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "აირჩიეთ მაგიდა შეკვეთის შესაქმნელად",
    selectWaiterToCreateOrder: "აირჩიეთ მომსახურე შეკვეთის შესაქმნელად",
    lastLanguage: "თქვენ არ შეგიძლიათ წაიღოთ უკან ბოლო ენა",
    orderAssignee: "უნდა აირჩიოთ შესატყვისი",
    updateFailed: "მონაცემების განახლება ვერ მოხდა",
    required: {
      password: "პაროლი აუცილებელია",
      confirmPassword: "დაადასტურეთ პაროლი",
      customMessage: "{{name}} აუცილებელია",
      comment: "*კომენტარი აუცილებელია",
    },
    image: "სურათის ატვირთვა ვერ მოხერხდა",
    addUnit: "დამატება ერთეული",
    confirmOrders:
      "თქვენ გაქვთ არასრულყოფილი სამუშაო. გთხოვთ შეინახოთ მას შემდეგ რომ განაგრძოთ.",
    input: {
      maxCharacter: "*გთხოვთ შეიყვანოთ არა მეტი ვიდრე {{max}} სიმბოლო",
      minCharacter: "*გთხოვთ შეიყვანოთ მინიმუმ {{min}} სიმბოლო",
      maxNumber: "*გთხოვთ შეიყვანოთ უფრო ნაკლები რაოდენობა {{max}}",
      minNumber: "*გთხოვთ შეიყვანოთ მეტი 0",
      search: "ძიება",
      invalid: "*არასწორი {{name}}",
      required: "*{{name}} აუცილებელია",
      generalRequired: "*ეს ველი აუცილებელია",
      menu: {
        fixedDiscount:
          "*დაკლება არ შეიძლება იყოს უფრო დიდი ვიდრე გაყიდვის ფასი",
        unFixedDiscount: "*დაკლება არ შეიძლება იყოს 100%-ზე მეტი",
        requiredUnit: "*გთხოვთ აირჩიოთ ერთეული მოცემული ოდენობისთვის",
      },
      menuSettings: {
        requiredDefaultLanguage: "*გთხოვთ აირჩიოთ უკვე არსებულ ენა",
        requiredAvailableLanguages: "*გთხოვთ აირჩიოთ მინიმუმ ერთი ენა",
      },
      pinCode: {
        maxDigits: "*პინის კოდი არ უნდა აღემატებოდეს 6 ციფრს",
        alreadyExists: "*ამ პინის კოდით უკვე არსებობს",
        notNumeric: "*პინის კოდი მხოლოდ ციფრები უნდა იყოს",
        skipOrSetNow: "*წესრიგის კოდით აირჩიეთ, ან გამოტოვეთ ეს ნაბიჯი",
        notMatch: "*პინის კოდები არ ემთხვევა",
        incorrect: "*პინის კოდი არასწორია",
        TryAgainLater:
          "თქვენ უკვე გადააჭარბეთ ლიმიტს, გთხოვთ სცადოთ მოგვიანებით.",
      },
      email: {
        alreadyExists: "*ეს ელ.ფოსტა უკვე არსებობს",
        incorrect: "*გთხოვთ შეიყვანოთ სწორი ელ.ფოსტა",
        notExists: "*ეს ელ.ფოსტა არ არსებობს",
        TryAgainLater:
          "*თქვენ უკვე გადააჭარბეთ ლიმიტს, გთხოვთ სცადოთ მოგვიანებით.",
      },
      password: {
        incorrect: "*პაროლი არასწორია",
        notNumeric: "*პაროლი მხოლოდ ციფრები უნდა შეიცავდეს",
        incorrectLength: "*პაროლი უნდა იყოს 6 ციფრიანი",
      },
      phoneNumber: {
        incorrectLength: "*ტელეფონის ნომერი უნდა იყოს 12 ციფრი",
        notNumeric: "*ტელეფონის ნომერი მხოლოდ ციფრები უნდა იყოს",
        alreadyExists: "*ამ ტელეფონის ნომრით უკვე არსებობს",
        notExists: "*ამ ტელეფონის ნომერი არ არსებობს",
        incorrect: "*გთხოვთ შეიყვანოთ სწორი ტელეფონის ნომერი",
        TryAgainLater:
          "*თქვენ უკვე გადააჭარბეთ ლიმიტს, გთხოვთ სცადოთ მოგვიანებით.",
      },
      business: {
        serviceFeeMax: "*მომსახურების საფასური უნდა იყოს 100%-ზე ნაკლები",
        serviceFeeMin: "*მომსახურების საფასური უნდა იყოს 0%-ზე მეტი",
        url: "*გთხოვთ შეიყვანოთ სწორი URL",
      },
      qrAndTable: {
        maxSeat: "სასადილო ადგილების რაოდენობა უნდა იყოს მთელი რიცხვი",
      },
      reservation: {
        maxGuestCount: "სტუმრების რაოდენობა უნდა იყოს მთელი რიცხვი",
      },
      contact: {
        instagram: "*გთხოვთ შეიყვანოთ სწორი ინსტაგრამი მისამართი",
      },
      otpCode: {
        OtpIsWrong: "*ერთჯერადი პაროლი არასწორია",
        OtpIsNotVerified: "ერთჯერადი პაროლი არ არის დადასტურებული",
        sendOtp: "კოდი გაიგზავნა აქ: ",
      },
      oldPassword: {
        OldPasswordIsWrong: "*მიმდინარე პაროლი არასწორია",
      },
    },
  },

  toastMessages: {
    success: {
      login: "შესვლა წარმატებით შესრულდა!",
      updateBusinessProfile: "ბიზნესის პროფილი წარმატებით განახლდა",
      updateBusinessSettings: "ბიზნესის პარამეტრები წარმატებით განახლდა",
      updateTag: "თეგი წარმატებით განახლდა",
      createTag: "თეგი წარმატებით შეიქმნა",
      deleteTag: "თეგი წარმატებით წაიშალა",
      createReservation: "შეკვეთა წარმატებით შეიქმნა",
      deleteReservation: "შეკვეთა წარმატებით წაიშალა",
      updateReservation: "შეკვეთა წარმატებით განახლდა",
      updateSpecialCategory: "სპეციალური კატეგორია წარმატებით განახლდა",
      updateCategory: "კატეგორია წარმატებით განახლდა",
      createCategory: "კატეგორია წარმატებით შეიქმნა",
      deleteCategory: "კატეგორია წარმატებით წაიშალა",
      updateMenuItem: "მენიუს ელემენტი წარმატებით განახლდა",
      updateMenu: "მენიუ წარმატებით განახლდა",
      createMenuItem: "მენიუს ელემენტი წარმატებით შეიქმნა",
      deleteMenuItem: "მენიუს ელემენტი წარმატებით წაიშალა",
      duplicateMenuItem: "მენიუს ელემენტი წარმატებით დაპლაგინდა",
      updateZone: "ზონა წარმატებით განახლდა",
      createZone: "ზონა წარმატებით შეიქმნა",
      deleteZone: "ზონა წარმატებით წაიშალა",
      updateTable: "მაგიდა განახლდა",
      updateMenuSettings: "მენიუს პარამეტრები წარმატებით განახლდა",
      createTable: "მაგიდა წარმატებით შეიქმნა",
      deleteTable: "მაგიდა წარმატებით წაიშალა",
      updateUser: "მომხმარებელი წარმატებით განახლდა",
      createUser: "მომხმარებელი წარმატებით შეიქმნა",
      deleteUser: "მომხმარებელი წარმატებით წაიშალა",
      updateQR: "კოდი განახლდა",
      createQR: "კოდი შეიქმნა",
      deleteQR: "კოდი წაიშალა",
      updateOrder: "შეკვეთა წარმატებით განახლდა",
      createOrder: "შეკვეთა წარმატებით შეიქმნა",
      deleteOrder: "შეკვეთა წარმატებით წაიშალა",
      finishedOrder: "შეკვეთა წარმატებით დასრულდა",
      updateInventoryCategory: "ინვენტარის კატეგორია წარმატებით განახლდა",
      createInventoryCategory: "ინვენტარის კატეგორია წარმატებით შეიქმნა",
      deleteInventoryCategory: "ინვენტარის კატეგორია წარმატებით წაიშალა",
      updateInventoryItem: "ინვენტარის ელემენტი წარმატებით განახლდა",
      createInventoryItem: "ინვენტარის ელემენტი წარმატებით შეიქმნა",
      deleteInventoryItem: "ინვენტარის ელემენტი წარმატებით წაიშალა",
      updateBusiness: "ბიზნესი წარმატებით განახლდა",
      createBusiness: "ბიზნესი წარმატებით შეიქმნა",
      deleteBusiness: "ბიზნესი წარმატებით წაიშალა",
      updateLanguage: "ენა წარმატებით შეიცვალა",
      deleteOrderHistory: "შეკვეთის ისტორია წარმატებით წაიშალა",
      resetPassword: "პაროლი წარმატებით შეცვლილია",
    },
    error: {
      common: "რამე არასწორად მოხდა! გთხოვთ სცადეთ კვლავ",
      zones: "ამ ზონას ვერ წაშლიდეთ, რადგან მას მაგიდა აქვს",
      unselectedTable: "თქვენ უნდა შეარჩიოთ მაგიდა",
      unselectedZone: "თქვენ უნდა შეარჩიოთ ზონა",
      deleteCategory: "ამ კატეგორიას ვერ წაშლიდეთ, რადგან მას აქვს ელემენტი",
    },
    warning: {
      socials: "ამჟამად განვითარებაშია, მადლობა გაგებისათვის.",
    },
  },
  reservation: {
    reserve: "რეზერვაცია",
    reservationTime: "რეზერვაციის დრო",
    reservationInfo: "რეზერვაციის ინფორმაცია",
    contactInfo: "კონტაქტის ინფორმაცია",
    enterName: "სახელი",
    enterPhoneNumber: "ტელეფონის ნომერი",
    bookingList: "შეკვეთების სია",
    booked: "დაჯავშნა",
    pending: "მოლოდინში",
    createReservation: "შეკვეთის შექმნა",
    editReservation: "შეკვეთის რედაქტირება",
    waiting: "მოლოდინში",
    today: "დღეს",
    guestNumber: "გესტი",
    waitingTime: "ლოდინ",
    startDate: "დაწყება",
    ReservationDuration: "რეზერვაციის ხანგრძლივობა",
    comment: "კომენტარი",
    numberOfGuest: "გესტების რაოდენობა",
    startTime: "დაწყების დრო",
    duration: "ხანგრძლივობა",
    confirmed: "დადასტურებულია",
    denied: "გაუქმებულია",
    delete: "წაშლა",
    update: "განახლება",
    searchRestaurant: "საძებნოთ რესტორანი",
    tableNotFound: "არ არის არჩეული",
    showMore: "მეტის ჩვენება",
    showLess: "ნაკლები ჩვენება",
    reservations: "რეზერვაციები",
    open: "გახსნა",
    seeMenu: "მენიუს ნახვა",
    reservation: "რეზერვაცია",
    contact: "კონტაქტი",
    place: "მდებარეობა",
    reserveeName: "დაჯავშნილის სახელი",
    date: "თარიღი",
    time: "დრო",
    reservationStatus: "რეზერვაციის სტატუსი",
    reservationDurationHelpText:
      "შენიშვნა: თუ არ აირჩევთ ხანგრძლივობას, ის ავტომატურად 1 საათს იქნება მორგებული.",
    justNow: "ახლა",
    oneDay: "1 დღე",
    day: "დღე",
    hour: "საათი",
    minute: "წუთი",
    selectedTable: "არჩეული მაგიდა",
    confirmation: "დაადასტურეთ და გააგრძელეთ",
    chooseRestaurant: "არჩიე რესტორანი",
    setDate: "როდის გსურთ ხვედრა?",
    chooseTable: "მოგწონთ მაგიდა?",
    addContact: "ვინ მოდის?",
    vrLinkCopied: "VR ლინკი ასლია",
    statuses: {
      PENDING: "მოლოდინში",
      CONFIRMED: "დადასტურებულია",
      DENIED: "გაუქმებულია",
      DELETED: "წაიშალა",
    },
    vr: {
      browserDoesNotSupport: "თქვენი ბრაუზერი არ უჭერს მხარს ვიდეო ტეგს.",
      vrTourReservation: "VR ტურის რეზერვაცია",
      explore: "გამოიკვლიეთ და შეარჩიეთ თქვენი ადგილი!",
      vrView: "VR ხედი",
      explorevr: "გახედეთ ჩვენს ადგილს",
    },
    map: {
      chooseTable: "არჩიეთ მაგიდა",
      mapReservation: "მონაცემთა რუკა",
      chooseLocation: "შეარჩიეთ თქვენი მაგიდა!",
      tables: "მაგიდები",
    },
    sentSuccessfully: "მოთხოვნა წარმატებით გაიგზავნა",
    successMessage:
      "განახლება გაგზავნილია თქვენს ნომერზე, და შეგიძლიათ ნახოთ თქვენი სტატუსი რეზერვაციის გვერდზე",
  },
  socials: {
    instagram: "ინსტაგრამი",
    email: "ელ.ფოსტა",
    whatsapp: "ვაცაფი",
  },
  months: {
    january: "იანვარი",
    february: "თებერვალი",
    march: "მარტი",
    april: "აპრილი",
    may: "მაისი",
    june: "ივნისი",
    july: "ივლისი",
    august: "აგვისტო",
    september: "სექტემბერი",
    october: "ოქტომბერი",
    november: "ნოემბერი",
    december: "დეკემბერი",
  },
  weekdays: {
    monday: "ორშაბათი",
    tuesday: "სამშაბათი",
    wednesday: "ოთხშაბათი",
    thursday: "ხუთშაბათი",
    friday: "პარასკევი",
    saturday: "შაბათი",
    sunday: "კვირა",
  },
  periods: {
    hourly: "საათობრივი",
    daily: "დღიური",
    weekly: "კვირეული",
    monthly: "თვიური",
  },

  chat: {
    typeHere: "დააკრიფეთ აქ...",
    chat: "ჩათი",
    allMessages: "ყველა შეტყობინება",
    unreadMessages: "არ წაკითხული შეტყობინებები",
    selectTopic: "გთხოვთ, აირჩიოთ თემის არჩევა.",
    you: "შენ",
    noMessages:
      "ჯერ შეტყობინებები არ არის. დაიწყე საუბარი პირველი შეტყობინების გაგზავნით!",
  },
  kitchen: {
    stageNew: "ახალი",
    stageProgress: "მომზადება",
    stageDone: "დასრულებულია",
  },
  inventory: {
    productList: "პროდუქტების სია",
    product: "პროდუქტი",
    stock: "საწარმო",
    warningAmountInfo:
      "აირჩიეთ მინიმალური რაოდენობა, რათა გაფრთხილდეს, როდესაც ის დასრულდება",
    productname: "პროდუქტის სახელი",
  },
  emptyStates: {
    loading: "მიმდინარეობს ჩატვირთვა...",
    noPrinters: "მოსაწერი არცერთი პრინტერი არ არის დამატებული",
    noWiFi: "მოსაწერი არცერთი WiFi არ არის დამატებული.",
    noOption: "არ არსებობს არჩევანი",
    noOrder: "არცერთი შეკვეთა არ არსებობს",
    noItem: "არცერთი ელემენტი არ არსებობს",
    noLanguage: "არ არსებობს ენა, რომელიც უნდა დაემატოს",
    noTableData: "ამ გვერდზე არ არსებობს მონაცემები",
    noOrderedCategory: "ამ კატეგორიაში არ არსებობს შეკვეთილი ელემენტები",
    noChat: "თქვენ არ გაქვთ ჩათები შეკვეთების არყოფნის გამო",
    noUnread: "არ არის არ წაკითხული შეტყობინებები.",
    noSearchResults: "არ არის ძებნის შედეგები",
    noOrders: "არცერთი შეკვეთა არ არსებობს",
    noGuests: "არ არის სტუმრები",
    noPendingReservations: "არ არის მოლოდინში რეზერვაციები.",
    noReservationDescription: "თქვენ არ გაქვთ არანაირი რეზერვაციები",
    noReservations: "არ არის რეზერვაციები",
    notOpenForReservations: "ეს რესტორანი არ არის გახსნილი რეზერვაციებისათვის.",
    noBusiness: "არ არის ბიზნესი",
    noBusinessContent: "არ არსებობს დამატებითი ინფორმაცია ბიზნესზე.",
    noBusinessDescription:
      "სამწუხაროდ, ყველა რესტორნის რეზერვაციები დაკეტილია.",
  },
  info: {
    itemAmount:
      "რაოდენობა ეხება საქონლის რაოდენობას, რომელიც მითითებულია ერთეულებში, როგორიცაა კილოგრამი (კგ), გრამი (გ), ლიტრი (ლ) ან მილილიტრი (მლ).",
    itemModificationOptionMaxNumberInfo:
      "მოდიფიკაციის ვარიანტების მაქსიმალური რაოდენობა ეხება ამ ვარიანტების მაქსიმალურ რაოდენობას, რომელიც შეიძლება გამოყენებულ იქნას.",
    general: "ზოგადი ინფორმაცია",
    detail: "დეტალური ინფორმაცია",
  },
  stepper: {
    signupMethod: "რეგისტრაციის მეთოდი",
    otpVerification: "ერთჯერადი პაროლის დადასტურება",
    userDetail: "მომხმარებლის დეტალები",
    passwordVerification: "პაროლის დადასტურება",
    setPinCode: "პინი კოდი",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "მომხმარებელთა პირობები",
    privacyPolicy: "პროფილური პოლიტიკა",
    allRightReserved: "ყველა უფლება დაცულია.",
    copyRight: "© {{year}} {{value}}.",
    joinUs: "შეუერთდით ჩვენ {{emoji}}",
    madeWith: "შექმნილია {{emoji}} მიერ",
    madeBy: "შექმნილია <strong>iLoyal</strong>-ის მიერ",
    scanQR: "კოდის სკანირება",
    name: "ცნობილი გახადეთ თქვენს მეგობრებს, რომ ეს თქვენ ხართ!",
    profilePicture: "თქვენი პროფილის სურათი",
    comingSoon: "მინიშნება მალე",
    underConstruction: "კონსტრუქციაშია",
  },
  optionTypes: {
    None: "არაფერი",
    S: "S",
    M: "M",
    L: "L",
  },
  businesses: {
    allBusinesses: "ყველა ბიზნესი",
    specialOffers: "სპეციალური შეთავაზებები",
    mostPopular: "ყველაზე პოპულარული",
    seaside: "კიდევ ზღვაზე",
    coffeeShop: "კაფეები",
    nationalCuisine: "ეროვნული სამზარეულო",
    nationalCuisineExtended: "ეროვნული სამზარეულოოოოოოო",
    information: "ინფორმაცია",
    search: "ძებნა...",
    seeAll: "ყველა ნახვა",
    seeLess: "პაკეტი ნაკლები",
  },
  policy: {
    termsDataInfo:
      "გაგრძელებით, თქვენ ეთანხმებით <strong> მომსახურების პირობებს და კონფიდენციალურობის პოლიტიკას </strong>",
    joinUsTermsData: {
      introduction: {
        title: "1. შესავალი",
        content: {
          point1:
            "ჩვენი მომსახურების გამოყენებით, თქვენ ეთანხმებით ამ მომსახურების პირობებს და ჩვენს კონფიდენციალურობის პოლიტიკას. გთხოვთ, დააზუსტოთ ისინი ყურადღებით.",
        },
      },
      userConsent: {
        title: "2. მომხმარებლის თანხმობა",
        content: {
          point1:
            "მომსახურების გამოყენებისას, თქვენ ეთანხმებით თქვენი მონაცემების შეგროვებას, გამოყენებასა და გაზიარებას ამ მომსახურების პირობების და კონფიდენციალურობის პოლიტიკის მიხედვით. თუ არ ეთანხმებით ამ პირობებს, გთხოვთ, არ გამოიყენოთ ჩვენი მომსახურება.",
        },
      },
      dataCollection: {
        title: "3. მონაცემების შეგროვება",
        content: {
          point1:
            "ჩვენ ვაგროვებთ ინფორმაციას, რომელსაც პირდაპირ გვაწვდით, მაგალითად, როდესაც ქმნით ანგარიშს, ყიდულობთ პროდუქტებს ან ვუკავშირდებით ჩვენ დახმარებისთვის. ასევე შეგვიძლია შეგროვდეს ავტომატურად მიღებული ინფორმაცია, მაგალითად თქვენი IP მისამართი, ბრაუზერის ტიპი და გამოყენების მონაცემები.",
        },
      },
      dataUse: {
        title: "4. მონაცემების გამოყენება",
        content: {
          point1:
            "თქვენი მონაცემები გამოიყენება ჩვენი მომსახურების უზრუნველსაყოფად, 유지ებისა და გაუმჯობესებისთვის. ეს მოიცავს ტრანზაქციების დამუშავებას, კომუნიკაციების გაგზავნას და თქვენი გამოცდილების პერსონალიზაციას.",
        },
      },
      dataProtection: {
        title: "5. მონაცემების დაცვა",
        content: {
          point1:
            "ჩვენ ვათავსებთ თქვენი მონაცემების დაცვას. ჩვენ ვიმყოფებით სხვადასხვა უსაფრთხოების ზომები, რათა დავიცვათ თქვენი ინფორმაცია გაუმართავი წვდომის, ცვლილებების, გამჟღავნების ან განადგურებისგან.",
        },
      },
      dataSharing: {
        title: "6. მონაცემების გაზიარება",
        content: {
          point1:
            "ჩვენ არ ვყიდით თქვენს პირად მონაცემებს. ჩვენ შეიძლება გავიზიაროთ ინფორმაცია საიმედო მესამე მხარეებთან, რომლებიც გვეხმარებიან ჩვენს ვებგვერდის ოპერაციაში, ბიზნესის წარმართვაში ან მომსახურების მიწოდებაში თქვენთვის, თუ ისინი თანახმა არიან შეინახონ ეს ინფორმაცია კონფიდენციალურად.",
        },
      },
      userRights: {
        title: "7. მომხმარებლის უფლებები",
        content: {
          point1:
            "თქვენ გაქვთ უფლება მიიღოთ, დააკორექტიროთ ან წაიშალოთ თქვენი პირადი ინფორმაცია. ასევე შეგიძლიათ აირჩიოთ გარკვეული გამოყენებებისა და გამჟღავნებისგან.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "ჩვენი მომსახურების გამოყენებით, თქვენ ამტკიცებთ, რომ წაიკითხეთ და გაიგეთ ეს პირობები და ეთანხმებით მათ.",
        },
      },
    },
    signUpTermsData: {
      agreement: {
        title: "1. შეთანხმების პირობები",
        content: {
          point1:
            "1.1 ლიცენზიარი უნდა მიაწოდოს პროგრამული უზრუნველყოფის ლიცენზია ამ შეთანხმების პირობების მიხედვით, ხოლო კლიენტი უნდა გადაიხადოს ამ ლიცენზიისთვის.",
          point2:
            "1.2 ყოველთვიური ლიცენზიის (გამოყენების) საფასური განსაზღვრულია იმ მახასიათებლების მიხედვით, რომლებიც iLoyal აწვდის კლიენტის მოთხოვნებიდან გამომდინარე. ცვლილებები ლიცენზიის საფასურში (გამოყენების საფასური) შეიძლება განხორციელდეს მხოლოდ კლიენტის და iLoyal-ს შორის ურთიერთშეთანხმების საფუძველზე. ყოველთვიური ლიცენზიის (გამოყენების) პერიოდი იწყება შეთანხმების ხელმოწერიდან.",
        },
      },
      license: {
        title: "2. ლიცენზიის პირობები",
        content: {
          point1:
            "2.1 ლიცენზიარი მხარს უჭერს შემდეგ უფლებებს მარტივი ლიცენზიის პირობების მიხედვით:",
          point2:
            "2.1.1 პროგრამული უზრუნველყოფის გამოყენება და მისი ოპერაციის მიზნისთვის განსაზღვრა.",
          point3: "2.2 შემდეგი მოქმედებები აკრძალულია ლიცენზიის პირობებით:",
          point4: "2.2.1 პროგრამული კოდის ამოღება მომხმარებლის მიერ.",
          point5: "2.2.2 პროგრამის გაყიდვა მომხმარებლის მიერ, ანუ მისი ასლები.",
          point6: "2.2.3 პროგრამის გადახდა და მისი ასლების ხელმოწერა.",
          point7: "2.2.4 პროგრამის საჯარო ხელმისაწვდომობა.",
        },
      },
      payment: {
        title: "3. ლიცენზიის გადახდა",
        content: {
          point1:
            "3.1 კლიენტი თანხმდება გადახდეს შესაბამისი თანხა, რომელიც მითითებულია ინვოისში, ამ შეთანხმების მიხედვით.",
          point2:
            "3.2 ლიცენზიის ფასები მითითებულია ლიცენზიარის ოფიციალურ ვებგვერდზე.",
          point3:
            "3.3 შეთანხმების შეწყვეტის შემთხვევაში, ლიცენზიის გადასახადი არ არის შეთანხმებული და არ განიხილება.",
          point4:
            "3.4 ლიცენზიის საფასურის გადახდის შემდეგ, რაც მითითებულია ლიცენზიარის მიერ გამოთხოვილ ინვოისში, თქვენ ითვლებით რომ შეთანხმება დასრულებულია.",
          point5:
            "3.5 ლიცენზიარი უფლებას იტოვებს, შეცვალოს ეს შეთანხმება ერთმხრივ.",
          point6:
            "3.6 როგორც კი შესაბამისი თანხები გადარიცხულია ლიცენზიარის ბანკში, კლიენტის ყველა გადახდილი ვალდებულება ჩაითვლება შესრულებულად.",
        },
      },
      dataProtection: {
        title: "4. მონაცემების დაცვა",
        content: {
          point1: "4.1 ყველა კლიენტის ინფორმაცია დაცულია ლიცენზიარის მიერ.",
          point2:
            "4.2 კლიენტის მონაცემები, რომელიც არის ჩვენს ღრუბლოვან ანგარიშებში, არ გაიცემა მესამე მხარეებთან.",
        },
      },
    },
  },
  departments: {
    department: "დეპარტამენტი",
    categories: "კატეგორიები",
    printers: "პრინტერები",
    activeDepartment: "დეპარტამენტის აქტივაცია",
  },
  auth: {
    createAnAccount: "შექმენი ანგარიში",
    register: "რეგისტრაცია",
    verifyOtp: "ერთჯერადი პაროლის ვერიფიკაცია",
    profile: "პროფილი",
    signUp: "რეგისტრაცია",
    otpSentTo: "ერთჯერადი პაროლი გაგზავნილია",
    otpCode: "ერთჯერადი პაროლის კოდი",
    letKnow: "მოგაცნობოთ",
    gender: "სქესი",
    saveAndFinish: "შეინახე და დაასრულე",
    message: {
      first: "გაერთე!",
      second: "გრძნობდი!",
      third: "არ გამოტოვო!",
      fourth: "დარჩი!",
    },
    genders: {
      male: "კაცი",
      female: "ქალი",
      other: "სხვა",
    },
    info: "ინფორმაცია",
    youAlreadyHaveAccount: "თქვენ უკვე გაქვთ რეგისტრირებული ანგარიში ",
    clickButtonToLogin: "დააწკაპუნეთ ამ ღილაკზე შესასვლელად",
    loginYourAccount: "შესვლა თქვენს ანგარიშზე",
    forgotPassword: "დაგავიწყდათ პაროლი?",
    forgotPasswordTitle: "დაგავიწყდათ პაროლი",
    forgotPasswordDesc:
      "შეიყვანეთ თქვენი {{method}} და გამოგიგზავნით ერთჯერადი პაროლის ვერიფიკაციის კოდს",

    setNewPassword: "მოამზადეთ ახალი პაროლი",
    setNewPasswordDesc: "შეიყვანეთ თქვენი ახალი პაროლი",
    signUpWith: "ან რეგისტრაცია ",
    withoutSignUp: "რეგისტრაციის გარეშე",
    phoneNumber: "ტელეფონის ნომერი",
    email: "ელ. ფოსტა",
    loginAccount: "შესვლა თქვენს ანგარიშზე",
    resendingIsAvailable: "გადაგზავნა ხელმისაწვდომია",
    resendingCodeWillBeAvailableIn: "გადაგზავნის კოდი ხელმისაწვდომი იქნება ",
    createGuestAccountDesc:
      "რეგისტრაციით iLoyal-ში თქვენ იღებთ შანსს ისარგებლოთ ფასდაკლებებით და დამატებითი ფუნქციებით!",
  },
  myProfile: {
    title: "ანგარიში",
    editProfile: "რედაქტირება",
    orderHistory: "შეკვეთების ისტორია",
    favorites: "ფავორიტები",
    chat: "ჩათი",
    gameHistory: "თამაშის ისტორია",
    helpCenter: "დახმარების ცენტრი",
    settings: "პარამეტრები",

    resetPassword: {
      resetPassword: "პაროლის გადმოწერა",
      setNewPassword: "მოამზადეთ ახალი პაროლი",
      enterCurrentPassword:
        "ახალი პაროლის დასაყენებლად, გთხოვთ, პირველ რიგში შეიყვანოთ თქვენი მიმდინარე პაროლი.",
      currentPassword: "მიმდინარე პაროლი *",
      newPassword: "ახალი პაროლი *",
    },
  },
  record: {
    talkToMe: "მომალაპარაკე",
    magicOrder: "მაჯიკური შეკვეთა",
    magicSearch: "მაჯიკური ძიება",
    start: "დაწყება",
    stop: "შეჩერება",
    magicOffer: "მაჯიკური შეთავაზება",
    results: "შედეგები",
    addedToBasket: "დაემატა კალათში",
    youCanUseMicrophone: "თქვენ შეგიძლიათ გამოიყენოთ მიკროფონი",
    magicOrderError:
      "თქვენი თხოვნა არ უკავშირდება მენიუს. გთხოვთ, გაამოკლებთ უფრო კონკრეტულ კითხვას, რომელიც ეხება საკვებს ან მენიუს კატეგორიებს.",
    introductionLoya: "გამარჯობა, მე ვარ ლოია.",
    askMeAnything: "დამკითხე ყველაფერი!",
    askLoya: "დამკითხე ლოიას",
    loyaIsTyping: "ლოია წერს",
  },
  recommend: {
    fitMyBudget: "გამომიგზავნე ბიუჯეტური კერძები.",
    tastiestDishes: "არჩიე იაფი და გემრიელი.",
    delicious: "მითხარი ყველაზე გემრიელი.",
    smtQuick: "მომეცი რამე სწრაფი.",
  },
  termsAndCondition: TermsAndCondition,
  privacyPolicy: PrivacyPolicy,
  devices: {
    addPrinter: "პრინტერის დამატება",
    testPrinter: "პრინტერის ტესტირება",
    deletePrinter: "პრინტერის წაშლა",
    connectedDevices: "დაკავშირებული მოწყობილობები",
    availableDevices: "მოცემული მოწყობილობები",
    notFound: "არ არის ნაპოვნი!",
    printingBill: "შეკვეთა იბეჭდება, გთხოვთ დაელოდოთ..",
  },
  printer: {
    unknownBusiness: "უცნობი ბიზნესი",
    awaitingApproval: "მომლოდინეა დამტკიცების დასაჭერა",
    orderNumber: "შეკვეთის ნომერი",
    date: "თარიღი",
    table: "მარტივი",
    waiter: "მომსწრე",
    unknown: "უცნობი",
    noWaiterAssigned: "მომსწრე არ დაინიშნა",
    orderNote: "შეკვეთის შენიშვნა",
    product: "პროდუქტი",
    quantity: "რაოდენობა",
    price: "ფასი",
    total: "ჯამი",
    unknownProduct: "უცნობი პროდუქტი",
    unknownOption: "უცნობი ვარიანტი",
    unknownChoice: "უცნობი არჩევანი",
    currency: "GEL",
    totalAmount: "ჯამი თანხა",
    bonAppetit: "გემრიელად ჭამეთ..",
    hopeToSeeYouAgain: "მომავალში ვიმედოვნებთ კვლავ წარსულში:) ",
    companyName: "iLoyal GE",
    advancedReceiptSystems: "გაიზრდა ფასი სისტემები",
    printError: "შეცდომა პრინტერის",
    printSuccess: "ბეჭდვა წარმატებით დასრულდა",
  },
  bulkSms: {
    guestSelected: "მამკაცი არჩეულია",
    everyoneSelected: "ყველა არჩეულია",
    giveDiscount: "დახურული ფასის შეთავაზება",
    sendSms: "SMS გაგზავნა",
    composeYourMessage: "თქვენი მესიჯის შედგენა",
    typeHere: "მოწერეთ აქ...",
    closeModal: "დარწმუნებული ხართ, რომ გინდათ დახურვა?",
    yourMessageWillBeDeleted: "თქვენი მესიჯი წაიშლება",
    sendSmsModal: "დარწმუნებული ხართ, რომ გინდათ SMS-ის გაგზავნა?",
    willBeSent: "ეს მესიჯი გამოიგზავნება {{numberOfPeople}} ადამიანს",
    successfullySent: "წარმატებით გამოიგზავნა",
    successfullySentToPeople:
      "მესიჯი წარმატებით გამოიგზავნა {{numberOfPeople}} ადამიანს",
    operationFailed: "ოპერაცია ვერ განხორციელდა",
    operationFailedPeople: "მესიჯი ვერ გაიგზავნა {{numberOfPeople}} ადამიანს",
    no: "არა",
    yes: "კი",
    close: "დახურვა",
    messageIsRequired: "მესიჯი აუცილებელია",
    sentToEveryone: "შეტყობინება გაიგზავნება ყველასთვის",
    sentToEveryoneSuccess: "შეტყობინება ყველას გაეგზავნა",
    sentToEveryoneFail: "შეტყობინების გაგზავნა ვერ მოხერხდა",
  },
  typingEffect: {
    tableStartTopic: "ახალი თემა!",
    talkToWaiter: "დამლაგეთთან საუბარი.",
    shareOpinion: "გამოაცხადეთ თქვენი აზრი!",
    questionsHere: "დასვით კითხვები აქ!",
    chatWithOtherTables: "საუბარი სხვა მაგიდებთან",
  },
  gameMessages: {
    findCards: "კარტების პოვნა!",
    tastyVictory: "მომხიბლავი გამარჯვება!",
    riseBeforeMeal: "მატერიის წინ აღზევება!",
    playAndGetHungry: "თამაში და შიმშილის გადადება!",
    winBeforeMeal: "გაუმჯობესება ხანგრძლივი!",
  },
};

export default TRANSLATION;
