import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as IconNewChat } from "assets/icons/pos/message-text-square-02.svg";
import { ReactComponent as IconCallWaiter } from "assets/icons/pos/bell-03.svg";
import { ReactComponent as IconNewOrder } from "assets/icons/pos/file-02.svg";
import { ReactComponent as IconAskBill } from "assets/icons/pos/bank-note-01.svg";

import If from "components/if/If";
import TimerWithColon from "components/admin/elements/timer-with-colon/TimerWithColon";

import "./AdminOrderTable.scss";

const AdminOrderTable = ({
  onClickTable,
  zoneName,
  hasOrder,
  newNotification,
  table,
  business,
}) => {
  return (
    <div
      className={cx("AdminOrderTable", {
        hasOrder,
        newNotification,
      })}
      onClick={onClickTable}
    >
      <h4 className="Medium">{table.name}</h4>
      <h6 className="Medium">{zoneName}</h6>
      <span>
        <TimerWithColon
          startTime={table.createDate}
          twoDigitsEnabled
          disabledSecond
        />
      </span>

      <If state={Boolean(hasOrder)}>
        <div className="AdminOrderTableActionsWrapper">
          <If state={business.isAskForBillEnabled}>
            <div
              className={cx("IconWrapper", {
                isActive: table.askForBill,
              })}
            >
              <IconAskBill className="AdminOrderTableAction" />
            </div>
          </If>

          <div
            className={cx("IconWrapper", {
              isActive: table.hasNewOrder,
            })}
          >
            <IconNewOrder className="AdminOrderTableAction" />
          </div>
          <If state={business.isCallWaiterEnabled}>
            <div
              className={cx("IconWrapper", {
                isActive: table.callWaiter,
              })}
            >
              <IconCallWaiter className="AdminOrderTableAction" />
            </div>
          </If>
          <If state={business?.chat}>
            <div
              className={cx("IconWrapper", {
                isActive: table.hasNewMessage,
              })}
            >
              <IconNewChat className="AdminOrderTableAction" />
            </div>
          </If>
        </div>
      </If>
    </div>
  );
};
AdminOrderTable.propTypes = {
  onClickTable: PropTypes.func.isRequired,
  zoneName: PropTypes.string.isRequired,
  hasOrder: PropTypes.object,
  newNotification: PropTypes.bool,
  table: PropTypes.shape({
    name: PropTypes.string.isRequired,
    askForBill: PropTypes.bool.isRequired,
    hasNewMessage: PropTypes.bool.isRequired,
    callWaiter: PropTypes.bool.isRequired,
    hasNewOrder: PropTypes.bool.isRequired,
    createDate: PropTypes.string.isRequired,
  }).isRequired,
  business: PropTypes.shape({
    isAskForBillEnabled: PropTypes.bool,
    isCallWaiterEnabled: PropTypes.bool,
    chat: PropTypes.bool,
  }).isRequired,
};
AdminOrderTable.propTypes = {};

export default AdminOrderTable;
