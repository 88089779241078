import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import {
  createOrderPrinterCommands,
  createPrinterCommandsFromOrders,
} from "utils/helper-functions/electron-helper/createPrinterCommand";
import { sendPrintRequestToElectron } from "utils/helper-functions/electron-helper/electron-printer-service";
import { BEEP_CONFIGS } from "utils/helper-functions/electron-helper/printCommandBuilder";
import {
  ELECTRON_CONSTANTS,
  TEST_PRINT_DATA,
} from "utils/constants/electron/electron-constants";

let electronAPI = null;

const checkIsElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(ELECTRON_CONSTANTS.userAgent) !== -1;
};

const getElectronAPI = () => {
  if (checkIsElectron() && !electronAPI) {
    electronAPI = window.require("electron");
  }
  return electronAPI;
};

//TODO: use electron provider via hook
const ElectronContext = createContext({});

const ElectronProvider = ({ children }) => {
  const [isElectron, setIsElectron] = useState(false);
  const [electronAPIInstance, setElectronAPIInstance] = useState(null);
  const { t } = useTranslation();
  const { business } = useSelector((state) => state[STORE_NAMES.business]);

  useEffect(() => {
    if (checkIsElectron()) {
      setIsElectron(true);
      setElectronAPIInstance(getElectronAPI());
    }
  }, []);

  // TODO: Add type of print data (Like: Order, Receipt, etc.)
  const handleTestPrinter = async ({ menuData, selectedPrinter }) => {
    const commands = createOrderPrinterCommands({
      order: TEST_PRINT_DATA,
      t,
      options: {
        businessName: business?.name,
        currency: menuData?.currency?.code,
        language: menuData?.language?.code,
        isTotal: false,
        isWaitForAccept: false,
      },
      soundType: BEEP_CONFIGS.TRIPLE_BEEP,
    });

    await sendPrintRequestToElectron(electronAPI, {
      commands,
      printers: [selectedPrinter],
    });
  };

  const handlePrintTheBill = async (order, options) => {
    if (!isElectron) return;

    const commands = createOrderPrinterCommands({
      order,
      t,
      options: {
        businessName: business.name,
        businessImages: business.images,
        isTotal: true,
        ...options,
      },
      soundType: BEEP_CONFIGS.DOUBLE_BEEP,
    });

    sendPrintRequestToElectron(electronAPIInstance, {
      commands,
      printers: business.printers?.map((printer) => printer.name) || [],
    });

    return true;
  };

  /**
   * The printers parameter is required because of React's Context hierarchy limitations.
   * Even though both ElectronProvider and AdminWebsocketContextProvider have access to
   * the business object through Redux, we can't directly access printer information here
   * when the websocket event is triggered because:
   *
   * 1. AdminWebsocketContextProvider manages websocket events
   * 2. ElectronProvider handles print functionality
   * 3. Context values are only accessible to child components
   * 4. These providers operate independently at the same level
   *
   * By passing printers as a parameter, we:
   * - Maintain separation of concerns
   * - Avoid circular dependencies
   * - Keep providers loosely coupled
   * - Make data flow explicit
   *
   */
  const handleWebsocketOrderUpdate = async ({ orders, options, printers }) => {
    if (!isElectron || !Array.isArray(orders) || orders.length === 0) {
      console.log("Electron yoxdur!");
      return false;
    }

    // Get the most recent update time from all orders
    // TODO: Update via timezone update
    const mostRecentUpdate = Math.max(
      ...orders.map((order) => new Date(order.lastUpdateDate).getTime())
    );
    const now = new Date();
    const timeDifference = (now - mostRecentUpdate) / 1000;
    const isWaitForAccept = orders.some((order) => order.hasNewOrder);
    // TODO: Request isOrder from the backend
    if (
      timeDifference <= ELECTRON_CONSTANTS.PRINT_THROTTLE_TIME ||
      isWaitForAccept
    ) {
      try {
        const commands = createPrinterCommandsFromOrders({
          orders,
          t,
          options: {
            isTotal: false,
            isWaitForAccept,
            ...options,
          },
          soundType: BEEP_CONFIGS.SHORT_SINGLE,
        });

        console.log("commands: ", commands);

        await sendPrintRequestToElectron(electronAPI, {
          commands,
          printers,
        });

        return true;
      } catch (error) {
        console.error("Print error:", error);
        return false;
      }
    }

    return false;
  };

  return (
    <ElectronContext.Provider
      value={{
        isElectron,
        electronAPI: electronAPIInstance,
        handleTestPrinter,
        handlePrintTheBill,
        handleWebsocketOrderUpdate,
      }}
    >
      {children}
    </ElectronContext.Provider>
  );
};

ElectronProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ElectronContext, ElectronProvider };
