import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CallButton from "components/buttons/call-button/CallButton";
import { ReactComponent as CallWaiterIcon } from "assets/icons/client-dashboard/Bell.svg";
import { ReactComponent as AskForBillIcon } from "assets/icons/client-dashboard/Receipt.svg";
import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";
import { STORE_NAMES } from "utils/constants/redux";
import { updateOrderActionAsync } from "redux/actions/orderActions";
import { generateInitial, handleOnAsyncError } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import PaymentModal from "components/payment-modal/PaymentModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";
import { ReactComponent as HamburgerMenu } from "assets/icons/menuv2/hamburger.svg";
import { MP_EVENTS, MP_PROP_NAMES } from "utils/constants/mixpanel";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import ImageWithInitials from "pages/client/menu-v2/image-with-placeholder/ImageWithInitials";

import { createDOBucketName } from "utils/DO-Spaces";
import InstagramStories from "components/insta-stories/InstaStories";
import IconButton from "components/icon-button/IconButton";
import { Languages } from "pages/client/menu-v2/top-bar/TopBar";
import { useGuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";

import "./DashboardHeroSection.scss";

const DashboardHeroSection = ({ selectedGuests, menuItemCount }) => {
  const { openHamburger } = useGuestLayout();
  const dispatch = useDispatch();
  const { trackMixpanel } = useMixpanel();
  const [openPayment, setOpenPayment, mainElementRef] = useOutsideClick();
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const { table, businessId } = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  );
  const tableId = table?.id;

  const currentZone = zones?.find((zone) =>
    zone.tables.find((table) => table.id === tableId)
  );

  const order = orders?.find((order) => order.table.id === table.id);
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const guestId = guest.id;
  const currentGuest = order?.guests.find(
    (guest) => guest.person.id === guestId
  );
  const { unreadMessagesCount } = useGuestWebsocketContextProvider();

  const isWaiterCalled = order?.actions?.find(
    (action) => action.name === ORDER_ACTIONS_TYPE.waiter
  ).value;
  const isBillAsked = order?.actions?.find(
    (action) => action.name === ORDER_ACTIONS_TYPE.bill
  ).value;
  const { t } = useTranslation();

  const allOrderers = order
    ? [
        currentGuest,
        ...(order.guests.filter((guest) => guest.person.id !== guestId) || []),
        ...(order.users || []),
      ]
    : [];
  const isAskForBillDisabled =
    allOrderers
      .flatMap((guest) => guest.orderItems)
      .filter((item) => item.isConfirmed !== false).length === 0;
  const isCallWaiterDisabled = !order;
  const handleOnChangeAction = async (actionName) => {
    const findUpdatedAction = order.actions.find(
      (action) => action.name === actionName
    );

    const updatedAction = {
      ...findUpdatedAction,
      value: !findUpdatedAction.value,
    };
    if (actionName === ORDER_ACTIONS_TYPE.bill) {
      if (updatedAction.value) {
        trackMixpanel(`${MP_EVENTS.guestDashboard.clickAskForBill}`, {
          [MP_PROP_NAMES.billType]: updatedAction.type,
        });
      }
    } else if (actionName === ORDER_ACTIONS_TYPE.waiter) {
      if (updatedAction.value) {
        trackMixpanel(`${MP_EVENTS.guestDashboard.clickCallWaiter}`);
      }
    }
    const actionTypeKey = (actionName) => {
      switch (actionName) {
        case ORDER_ACTIONS_TYPE.bill:
          return "actionBill";
        default:
          return "actionWaiter";
      }
    };

    const actionType = actionTypeKey(updatedAction.name);

    const updatedOrder = {
      action: updatedAction,
      [actionType]: updatedAction.value,
    };
    const response = await dispatch(
      updateOrderActionAsync({ businessId, order: updatedOrder, id: order.id })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    }
  };

  const hasNotification = unreadMessagesCount > 0 && business?.chat;

  const [showInstagramStory, setShowInstagramStory] = useState(false);
  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.menu].data?.availableLanguages
  );

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const [openLanguages, setOpenLanguages, mainElementRefLanguages] =
    useOutsideClick();
  const showLanguagesModal = availableLanguages?.length > 1 || false;
  return (
    <div className="DashboardHeroSection">
      <header>
        {showInstagramStory && business?.images?.other?.length > 0 && (
          <InstagramStories
            business={business}
            setShowInstaStory={setShowInstagramStory}
          />
        )}
        <div className="DashboardHeroSectionWrapper">
          <div
            className="DashboardHeroSectionStory"
            onClick={() => setShowInstagramStory(true)}
          >
            {business?.images?.other?.length > 0 && (
              <div className="DashboardHeroSectionStoryCircle" />
            )}
            <ImageWithInitials
              alt="logo"
              className="DashboardHeroSectionLogo"
              imageSource={createDOBucketName(business.images.logo)}
              initialText={generateInitial([business.name])}
            />
          </div>
          {currentZone && (
            <div className="DashboardHeroSectionZoneTableName">
              <h4 className="Medium">{order?.table?.name}</h4>
              <h6 className="h7">{currentZone?.name}</h6>
            </div>
          )}
        </div>
        <div className="DashboardHeroSectionOptions">
          <IconButton
            className="DashboardHeroSectionOptionButton"
            img={selectedLanguage?.imgSrc}
            onClick={() => setOpenLanguages(showLanguagesModal)}
          />
          <IconButton
            className="DashboardHeroSectionOptionButton"
            Icon={HamburgerMenu}
            onClick={openHamburger}
            hasNotification={hasNotification}
          />
        </div>
      </header>
      <div className="ButtonsContainer">
        {business?.isCallWaiterEnabled && (
          <CallButton
            isChecked={isWaiterCalled || false}
            onChange={() => handleOnChangeAction(ORDER_ACTIONS_TYPE.waiter)}
            title={t("waiter.call")}
            isDisabled={isCallWaiterDisabled}
            icon={<CallWaiterIcon />}
          />
        )}
        {business?.isAskForBillEnabled && (
          <CallButton
            isChecked={isBillAsked || false}
            onChange={() =>
              isBillAsked
                ? handleOnChangeAction(ORDER_ACTIONS_TYPE.bill)
                : setOpenPayment(true)
            }
            title={t("waiter.bill")}
            isDisabled={isAskForBillDisabled}
            icon={<AskForBillIcon />}
          />
        )}
      </div>
      <PaymentModal
        mainElementRef={mainElementRef}
        openSlide={openPayment}
        onClose={() => setOpenPayment(false)}
        closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
        selectedGuests={selectedGuests}
        totalPrice={order?.totalPrice}
        menuItemCount={menuItemCount}
        tableName={table.name}
      />
      <Languages
        mainElementRef={mainElementRefLanguages}
        openSlide={openLanguages}
        onClose={() => setOpenLanguages(false)}
      />
    </div>
  );
};

DashboardHeroSection.propTypes = {
  selectedGuests: PropTypes.array,
  menuItemCount: PropTypes.number,
};

export default DashboardHeroSection;
