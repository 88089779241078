import React from "react";
import cx from "classnames";

import If from "components/if/If";
import AdminHomepageSidebar from "pages/admin/admin-pages/admin-home/sidebar/AdminHomepageSidebar";
import AdminRoutesSidebar from "pages/admin/admin-sidebar-controller/admin-sidebar/AdminSidebar";
import { useAdminLayout } from "pages/admin/admin-layout/AdminLayout";
import { useAdminRouteController } from "utils/context-api/AdminRouteControllerContext";

import styles from "./AdminSidebarController.module.scss";

const AdminSidebarController = () => {
  const { isHomePage } = useAdminRouteController();
  const { closeSidebar, isOpenSidebar } = useAdminLayout();

  return (
    <>
      <If state={!isHomePage}>
        <AdminRoutesSidebar />
      </If>
      <If state={isHomePage}>
        <AdminHomepageSidebar />
      </If>
      <div
        className={cx(styles.Background, { [styles.isOpen]: isOpenSidebar })}
        onClick={closeSidebar}
      />
    </>
  );
};

export default AdminSidebarController;
