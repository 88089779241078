import React, { useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import FooterSocial from "components/elements/footer-social/FooterSocial";
import HamburgerActionButton from "pages/client/menu-v2/hamburger/hamburger-action-button/HamburgerActionButton";
import PolicyModal from "components/policy-modal/PolicyModal";
import WifiModal from "components/mascot/wifi-modal/WifiModal";
import { getBrandFooterLogo, getBrandName } from "utils/branding-helper";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import useOutsideClick from "utils/hooks/useOutsideClick";
import ICON_WIFI from "assets/icons/hamburger/wifi.svg";
import InstagramIcon from "assets/icons/sosial-media/FooterInstagram.svg";
import MailIcon from "assets/icons/sosial-media/FooterMail.svg";
import TiktokIcon from "assets/icons/sosial-media/Tiktok.svg";
import { ReactComponent as MessageIcon } from "assets/icons/hamburger/Message.svg";
import { ReactComponent as GameIcon } from "assets/icons/hamburger/game.svg";
import { ReactComponent as ReservationIcon } from "assets/icons/hamburger/MenuBoard.svg";
import { ReactComponent as FeedBackIcon } from "assets/icons/hamburger/FeedBack.svg";
import { useGuestLayout } from "../GuestLayoutContext";
import { TERMS_AND_PRIVACY } from "utils/constants/global";
import { createDOBucketName } from "utils/DO-Spaces";
import ImageWithInitials from "pages/client/menu-v2/image-with-placeholder/ImageWithInitials";
import { generateInitial } from "utils/helpers";
import { useGuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";

import styles from "./Hamburger.module.scss";

const Logo = getBrandFooterLogo();
const brandName = getBrandName();

const SOCIAL_LINKS = [
  {
    platform: "instagram",
    url: "https://www.instagram.com/iloyal.app.az",
    icon: InstagramIcon,
  },
  { platform: "gmail", url: "mailto:admin@onezero.com", icon: MailIcon },
  // { platform: "whatsapp", url: "", icon: WhatsappIcon },
  {
    platform: "tiktok",
    url: "https://www.tiktok.com/@iloyal.app",
    icon: TiktokIcon,
  },
];

const Hamburger = () => {
  const { t } = useTranslation();
  const { isHamburgerVisible, closeHamburger } = useGuestLayout();
  const { businessId } = useParams();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const currentYear = new Date().getFullYear();
  const [termsOrPrivacy, setTermsOrPrivacy] = useState(null);
  const [
    openSlideTermsOrPolicy,
    setOpenSlideTermsOrPolicy,
    mainElementRefTermsOrPolicy,
  ] = useOutsideClick();
  const [openSlideWifi, setOpenSlideWifi, mainElementRefWifi] =
    useOutsideClick();
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);

  const { unreadMessagesCount } = useGuestWebsocketContextProvider();
  const isChatHidden = !order || !business?.chat;
  const isOrderEmpty = !order;
  const HAMBURGER_ROUTES = useMemo(() => {
    return [
      {
        text: t("chat.chat"),
        icon: <MessageIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.chat}`,
        isHidden: isChatHidden,
        notification: unreadMessagesCount,
      },
      {
        text: t("navbarRoutes.funZone"),
        icon: <GameIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
        isHidden: false,
        notification: null,
      },
      {
        text: t("navbarRoutes.reservation"),
        icon: <ReservationIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`,
        isHidden: false,
        notification: null,
      },
      {
        text: t("navbarRoutes.feedbacks"),
        icon: <FeedBackIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.feedbacks}`,
        isHidden: isOrderEmpty,
        notification: null,
      },
      {
        text: t("mascot.about").replace("{{businessName}}", `${business.name}`),
        icon: (
          <ImageWithInitials
            alt="logo"
            className={styles.Logo}
            imageSource={createDOBucketName(business.images.logo)}
            initialText={generateInitial([business.name])}
          />
        ),
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.info}`,
        isHidden: false,
        notification: null,
      },
    ];
  }, [
    t,
    isOrderEmpty,
    isChatHidden,
    unreadMessagesCount,
    business?.images?.logo,
    business?.name,
  ]);

  const businessHasWifi = business?.wifi?.length > 0;

  const handleOpenWifiModal = () => {
    setOpenSlideWifi(true);
    closeHamburger();
  };

  return (
    <>
      <div className={cx(styles.Container)}>
        <div className={styles.Header}>
          <Logo />
          <CloseButton
            onClick={closeHamburger}
            type={ENUMS_CLOSE_BUTTON.types.TYPE_T}
          />
        </div>

        <div className={styles.Body}>
          {businessHasWifi && (
            <button className={styles.Connect} onClick={handleOpenWifiModal}>
              <img src={ICON_WIFI} alt="WiFi" />
              <h5 className="SemiBold">{t("common.connectWifi")}</h5>
            </button>
          )}
          {HAMBURGER_ROUTES.filter((route) => !route.isHidden).map(
            (route, index) => (
              <HamburgerActionButton key={index} route={route} />
            )
          )}
        </div>

        <div className={styles.Footer}>
          <div className={styles.Socials}>
            {SOCIAL_LINKS.map((socialLink) => {
              const { platform, url, icon: SocialIcon } = socialLink;
              return (
                <FooterSocial
                  key={platform}
                  SocialIcon={SocialIcon}
                  onClick={() =>
                    window.open(url, "_blank", "noopener noreferrer")
                  }
                />
              );
            })}
          </div>

          <div className={styles.Conditions}>
            {Object.values(TERMS_AND_PRIVACY).map((element) => {
              const { key, label } = element;
              return (
                <div
                  key={key}
                  onClick={() => {
                    setTermsOrPrivacy(key);
                    setOpenSlideTermsOrPolicy(true);
                    closeHamburger();
                  }}
                >
                  <h6 className="h8">{t(`login.signUp.${label}`)}</h6>
                </div>
              );
            })}
          </div>

          <div className={styles.CopyRight}>
            <div>
              <Logo />
              <h6 className="h7 Medium">
                {t("general.copyRight", {
                  value: brandName,
                  year: currentYear,
                })}
              </h6>
            </div>
            <h6 className={styles.AllRightReserved}>
              {t("general.allRightReserved")}
            </h6>
          </div>
        </div>
      </div>

      <div
        className={cx(styles.Overlay, {
          [styles.isVisible]: isHamburgerVisible,
        })}
        onClick={closeHamburger}
      />
      <div className={styles.HamburgerPrivacy}>
        <PolicyModal
          open={openSlideTermsOrPolicy}
          onClose={() => setOpenSlideTermsOrPolicy(false)}
          mainElementRefPolicy={mainElementRefTermsOrPolicy}
          policyModalTranslateFileKey="signUpTermsData"
          termsOrPrivacy={termsOrPrivacy}
        />
      </div>

      <WifiModal
        mainElementRef={mainElementRefWifi}
        openSlide={openSlideWifi}
        onClose={() => setOpenSlideWifi(false)}
      />
    </>
  );
};

export default Hamburger;
