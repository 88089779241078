import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import If from "components/if/If";

import styles from "./Profile.module.scss";

export const ENUMS = {
  name: "Profile",
  types: {
    PROFILE32: "Profile32",
    PROFILE36: "Profile36",
    PROFILE40: "Profile40",
    PROFILE44: "Profile44",
    PROFILE48: "Profile48",
    PROFILE52: "Profile52",
  },
};

const Profile = ({
  src,
  size = ENUMS.types.PROFILE52,
  title,
  className,
  helperText,
  initialText,
  iconProfile,
  iconRight,
  onClick,
}) => {
  const { t } = useTranslation();
  const [shouldShowInitial, setShouldShowInitial] = useState(
    Boolean(initialText)
  );
  return (
    <div
      className={cx(styles.Root, className, {
        [styles.isClickable]: Boolean(onClick),
      })}
      onClick={onClick}
    >
      <div className={cx(styles.ProfileContainer, styles[size])}>
        <If state={Boolean(src)}>
          <img
            src={src}
            className={styles.ProfileSrc}
            alt={t("general.profilePicture")}
            onLoad={() => setShouldShowInitial(false)}
            style={{ display: shouldShowInitial ? "none" : "inline-block" }}
          />
        </If>
        <If state={shouldShowInitial}>
          <h5 className={cx(styles.Initial, "Medium")}>{initialText}</h5>
        </If>
        <If state={Boolean(iconProfile)}>
          <div>{iconProfile}</div>
        </If>
      </div>
      <div className={cx(styles.TitleAndArrow, styles[size])}>
        <div className={cx(styles.TitleContainer)}>
          <h5 className={cx(styles.Title, "Medium")}>{title}</h5>
          <h5 className={cx(styles.HelperText, "Medium")}>{helperText}</h5>
        </div>
        {iconRight}
      </div>
    </div>
  );
};

Profile.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(Object.values(ENUMS.types)).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  helperText: PropTypes.string,
  initialText: PropTypes.string.isRequired,
  iconProfile: PropTypes.node,
  iconRight: PropTypes.node,
  onClick: PropTypes.func,
};
export default Profile;
