import React, { useEffect, useState } from "react";
import _, { omit } from "lodash";
import cx from "classnames";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import {
  getActiveLanguageValue,
  getBasketModificationsWithDefaultValues,
  getInitialSchedule,
  getTranslationPropertyRequestBody,
} from "utils/helpers";
import {
  createDOBucketName,
  removeImageFromDO,
  uploadARToDO,
  uploadImageToDO,
} from "utils/DO-Spaces";
import Modal from "components/modal/Modal";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import TextInput, {
  ENUMS as ENUMS_TEXT_INPUT,
} from "components/forms/input/input-text/TextInput";
import Photo from "components/admin/cards/photo/Photo";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Spinner from "components/elements/spinner/Spinner";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { filterArchivedCategories } from "utils/helpersMenu";
import AddNewButton, {
  ENUMS as ENUMS_ADD_NEW_BUTTON,
} from "components/admin/buttons/add-new-button/AddNewButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import IconButton from "components/buttons/icon-button/IconButton";
import Language from "components/admin/elements/language/Language";
import { DO_FILE_TYPES, IMAGE_FILE } from "utils/constants/DOSpaces";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import Switch, { ENUMS as ENUMS_SWITCH } from "components/forms/switch/Switch";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import RadioSelection from "components/admin/forms/radio-selection/RadioSelection";
import DateAccordion from "components/admin/elements/date-accordion/DateAccordion";
import AddPhotoButton from "components/admin/buttons/add-photo-button/AddPhotoButton";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import { ENUMS } from "pages/admin/admin-pages/admin-menu/admin-menu-controller/add-menu-item-modal/AddMenuItemModal";
import ModificationSettings from "pages/admin/admin-pages/admin-menu/admin-menu-controller/modification-settings-modal/ModificationSettingsModal";
import ArInput, {
  AR_INPUT_TYPES,
} from "components/admin/forms/ar-input/ArInput";
import useGetLoggedBusinessUser from "utils/hooks/useGetLoggedBusinessUser";
import { adminRoles } from "utils/constants/global";
import If from "components/if/If";

import "./AddPromotionOrBannerModal.scss";

const IMAGE_TYPE = {
  coverImageSrc: "coverImageSrc",
  otherImagesSrc: "otherImagesSrc",
};

const CTA_OPTIONS = [
  { key: "menu_items", label: "menu.category.menuItems" },
  { key: "external_link", label: "menu.category.externalLink" },
  { key: "internal_link", label: "menu.category.internalLink" },
];

const AddPromotionOrBannerModal = ({
  item,
  title,
  openSlide,
  setOpenSlide,
  mainElementRef,
  onSave,
  onDelete,
  selectedCategory,
  selectedCategoryType,
  showDeniedModal,
  isLoading,
  setOutsideClickAction,
}) => {
  const { t } = useTranslation();
  const user = useGetLoggedBusinessUser();
  const userRole = user?.roles[0].name;
  const isAdmin = adminRoles.includes(userRole);

  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const { menuItemCategoryType, menuCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const { units } = useSelector((state) => state[STORE_NAMES.app]);

  const menuLanguage = menu?.language;
  const initialSchedule = item?.schedule?.from
    ? item?.schedule
    : getInitialSchedule(new Date());
  const currencyCode = menuCurrency?.code;
  const initialRateSchedule =
    item?.rate?.schedule?.from ?? getInitialSchedule(new Date());
  const categories =
    filterArchivedCategories(menu?.categories)
      .filter(
        (category) =>
          category.type === menuCategoryType.banner ||
          category.type === menuCategoryType.promotion
      )
      .map((category) => {
        return { id: category.id, name: category.name };
      }) || [];

  const isEditable = !!item;
  const tags = menu?.tags || [];
  const initialData = {
    schedule: initialSchedule,
    name: [{ value: "", languageCode: menuLanguage.code }],
    isPublished: true,
    description: [{ value: "", languageCode: menuLanguage.code }],
    priceSell: "",
    priceCost: "",
    calories: "",
    timeToMake: "",
    amount: "",
    unit: units.find((unit) => unit.name === ENUMS.unit),
    coverImageSrc: "",
    otherImagesSrc: [],
    model3D: {
      ios: null,
      general: null,
    },
    tags: [],
    category: selectedCategory,
    rate: {
      isFixed: false,
      amount: 0,
      schedule: initialRateSchedule,
      isEnabled: false,
    },
    modifications: [],
    ctaType: menuItemCategoryType.menu_items,
    ctaLink: null,
    hasCtaAction: true,
  };
  const [formData, setFormData] = useState(initialData);
  const [coverImageError, setCoverImageError] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedARImages, setUploadedARImages] = useState({
    ios: null,
    general: null,
  });
  const [activeLanguageCode, setActiveLanguageCode] = useState(null);
  const [selectedModification, setSelectedModification] = useState(null);
  const [formDataInitial, setFormDataInitial] = useState(initialData);
  const [coverImageLoading, setCoverImageLoading] = useState(false);
  const [isDiscountScheduleAccordionOpen, setIsDiscountScheduleAccordionOpen] =
    useState(false);
  const [isItemScheduleAccordionOpen, setIsItemScheduleAccordionOpen] =
    useState(false);
  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const [
    openSlideModification,
    setOpenSlideModification,
    mainElementRefModification,
    ,
    ,
    setOutsideClickActionModification,
  ] = useOutsideClick();

  const formDataWithoutType = omit(formData, ["ctaType"]);
  const formDataInitialWithoutType = omit(formDataInitial, ["ctaType"]);

  useFormOutsideClickHandler({
    formData: formDataWithoutType,
    formDataInitial: formDataInitialWithoutType,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const schemas = useValidationSchema(t);
  const addMenuItemSchema = schemas.addMenuItemSchema(formData);
  const addCTALinkSchema = schemas.addCTALinkSchema;
  const { requiredStringSchemaForMenuItem } = schemas;

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    setValue,
  } = useForm({
    resolver:
      formData.ctaType === menuItemCategoryType.menu_items
        ? zodResolver(addMenuItemSchema)
        : zodResolver(addCTALinkSchema),
    criteriaMode: "all",
  });

  const handleOnPublishModeChange = () => {
    setFormData({
      ...formData,
      isPublished: !formData.isPublished,
    });
  };
  const handleChangeCTAType = (ctaType) => {
    if (!isEditable) {
      setFormData({
        ...formData,
        ctaType,
        ctaLink: null,
      });
    }
  };
  const handleChangeCTAAction = () => {
    setFormData({
      ...formData,
      hasCtaAction: !formData.hasCtaAction,
    });
  };
  const handleOnChangeRate = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      rate: {
        ...formData.rate,
        isFixed: value === "true",
      },
    });
  };
  const handleOnRemoveRedundantImagesFromAWSWhenCloseModal = () => {
    uploadedImages.map((image) => {
      if (!item?.otherImagesSrc.includes(image)) {
        removeImageFromDO(image);
      }
    });
    setUploadedImages([]);

    const itemARImages = Object.values(item?.model3D || {}).filter(Boolean);

    Object.values(uploadedARImages).forEach((image) => {
      if (!itemARImages.some((img) => img === image)) {
        removeImageFromDO(image);
      }
    });

    setUploadedARImages({
      ios: null,
      general: null,
    });
  };
  const handleOnChangeRateEnabled = () => {
    setFormData({
      ...formData,
      rate: {
        ...formData.rate,
        isEnabled: !formData.rate.isEnabled,
      },
    });
  };
  const handleOnRateChange = (e) => {
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      rate: {
        ...formData.rate,
        amount: value ? parseFloat(value) : 0,
      },
    };
    setFormData(updatedFormData);
  };
  const handleOnDropdownChange = (option, name) => {
    setFormData({
      ...formData,
      [name]: option,
    });
  };
  const handleOnAddModification = () => {
    setOpenSlideModification(true);
    setSelectedModification(null);
  };
  const handleOnEditModification = (modification) => {
    setOpenSlideModification(true);
    setSelectedModification(modification);
  };
  const handleOnDeleteModification = (id) => {
    setFormData({
      ...formData,
      modifications: formData.modifications.filter(
        (modification) => modification.id !== id
      ),
    });
  };
  const handleOnSaveModifications = (activeModification, id) => {
    let updatedModifications;
    if (id) {
      updatedModifications = formData.modifications.map((modification) => {
        if (id === modification.id) return activeModification;
        return modification;
      });
    } else {
      updatedModifications = [
        { id: new Date().getTime(), ...activeModification },
        ...formData.modifications,
      ];
    }
    setFormData({
      ...formData,
      modifications: updatedModifications,
    });
  };
  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };
  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };
  const handleOnDelete = () => {
    onDelete(item.id);
  };

  const updateImages = (imageType, uploadedImageLocations) => {
    setFormData((prevData) => ({
      ...prevData,
      coverImageSrc:
        imageType === IMAGE_TYPE.coverImageSrc
          ? uploadedImageLocations[0]
          : prevData.coverImageSrc,
      otherImagesSrc:
        imageType === IMAGE_TYPE.coverImageSrc
          ? prevData.otherImagesSrc
          : [...uploadedImageLocations, ...prevData.otherImagesSrc],
    }));
  };

  const handleOnFileSelect = async (selectedPhotos, imageType) => {
    const selectedFilesArray = Array.from(selectedPhotos);
    try {
      setCoverImageLoading(true);
      const uploadedImageLocations = await Promise.all(
        selectedFilesArray.map((photo) => {
          return new Promise((resolve, reject) => {
            uploadImageToDO({
              image: photo,
              fileType: DO_FILE_TYPES.MENU,
              options: IMAGE_FILE.menuItem,
              businessId,
              onSuccess: (location) => {
                resolve(location);
              },
              onError: (error) => {
                setCoverImageError(error);
                toast.error(t("errorMessages.image"));
                setCoverImageLoading(false);
              },
            }).catch((error) => {
              reject(error);
            });
          });
        })
      );

      setUploadedImages([...uploadedImages, ...uploadedImageLocations]);
      updateImages(imageType, uploadedImageLocations);
    } catch (error) {
      setCoverImageError(error);
      toast.error(t("errorMessages.image"));
    }
    setCoverImageLoading(false);
  };

  const handleOnInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatedFormData = { ...formData };

    if (type === "number") {
      updatedFormData[name] = value !== "" ? parseFloat(value) : "";
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
  };
  const handleOnMultiLanguageInputChange = (e, name) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [name]: formData[name].map((property) => {
        if (property.languageCode === activeLanguageCode) {
          return {
            ...property,
            value: value,
          };
        }
        return property;
      }),
    });
  };
  const handleOnTagRemove = (option) => {
    const updatedTags = formData.tags.filter((tag) => tag.id !== option.id);
    setFormData({
      ...formData,
      tags: updatedTags,
    });
  };
  const handleOnTagChange = (option) => {
    const existingTag = formData.tags.find((tag) => tag.id === option.id);

    if (existingTag) {
      handleOnTagRemove(option);
    } else {
      setFormData({
        ...formData,
        tags: [...formData.tags, option],
      });
    }
  };
  const handleCTALinkChange = (e) => {
    if (e.target) {
      const { value, name } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setValue("ctaLink", e);
      setFormData({
        ...formData,
        ctaLink: e,
      });
    }
  };
  const handleNameInputWithMultipleLanguage = () => {
    const nameError = formData.name
      .map((name) => {
        try {
          if (name.value.trim() === "") {
            setError("name", {
              type: "manual",
              message: t("errorMessages.input.generalRequired"),
            });
            setActiveLanguageCode(name.languageCode);
            return name.languageCode;
          }
          requiredStringSchemaForMenuItem.parse(name.value);
          return null;
        } catch (error) {
          setError("name", {
            type: "manual",
            message: t("errorMessages.input.maxCharacter").replace(
              "{{max}}",
              "100"
            ),
          });

          setActiveLanguageCode(name.languageCode);
          return name.languageCode;
        }
      })
      .filter((name) => name !== null);

    return nameError;
  };
  const handleOnSubmit = () => {
    const nameError = handleNameInputWithMultipleLanguage();
    if (nameError.length > 0) {
      return;
    }

    //remove redundant uploaded images from AWS
    uploadedImages.map((image) => {
      if (
        !formData.otherImagesSrc.includes(image) &&
        image !== formData.coverImageSrc
      ) {
        removeImageFromDO(image);
      }
    });
    setUploadedImages([]);
    // Remove old images from AWS
    item?.otherImagesSrc.forEach((oldImage) => {
      if (!formData.otherImagesSrc.includes(oldImage)) {
        removeImageFromDO(oldImage);
      }
    });

    const formDataARImages = Object.values(formData.model3D || {}).filter(
      Boolean
    );
    const itemARImages = Object.values(item?.model3D || {}).filter(Boolean);
    Object.values(uploadedARImages || {}).forEach((arImage) => {
      if (arImage && !formDataARImages.some((img) => img === arImage)) {
        removeImageFromDO(arImage);
      }
    });

    setUploadedARImages({
      ios: null,
      general: null,
    });

    Object.values(itemARImages).forEach((oldArImage) => {
      if (!formDataARImages.some((img) => img === oldArImage)) {
        removeImageFromDO(oldArImage);
      }
    });

    const modifiedModifications =
      formData.modifications.length > 0
        ? formData.modifications.map((modification) => {
            const updatedModificationName = getTranslationPropertyRequestBody(
              modification.name
            );
            if (item?.modifications.some((mod) => mod.id === modification.id)) {
              const modifiedOptions = modification.options.map((option) => {
                const updatedOptionName = getTranslationPropertyRequestBody(
                  option.name
                );
                if (
                  item?.modifications
                    .find((mod) => mod.id === modification.id)
                    .options.some((opt) => opt.id === option.id)
                ) {
                  return { ...option, name: updatedOptionName };
                }

                // eslint-disable-next-line no-unused-vars
                const { id, ...modifiedOption } = option;
                return { ...modifiedOption, name: updatedOptionName };
              });
              return {
                ...modification,
                name: updatedModificationName,
                options: modifiedOptions.map((option) => ({
                  ...option,
                  priceCost: option.priceCost || undefined,
                  priceSell: option.priceSell === "" ? 0 : option.priceSell,
                })),
              };
            }
            // eslint-disable-next-line no-unused-vars
            const { id, ...modifiedModification } = modification;

            const modifiedOptions = modifiedModification.options.map(
              // eslint-disable-next-line no-unused-vars
              ({ id, ...option }) => ({
                ...option,
                priceCost: option.priceCost || undefined,
                priceSell: option.priceSell === "" ? 0 : option.priceSell,
              })
            );

            return {
              ...modifiedModification,
              name: updatedModificationName,
              options: modifiedOptions,
            };
          })
        : [];

    const updatedName = getTranslationPropertyRequestBody(formData.name);
    const updatedDescription = getTranslationPropertyRequestBody(
      formData.description
    );
    const hasNoSchedule = isEqual(formData.schedule, initialSchedule);
    const hasNoRateSchedule = isEqual(
      formData.rate.schedule,
      initialRateSchedule
    );
    let updatedFormData = { ...formData };
    if (hasNoSchedule) {
      delete updatedFormData.schedule;
    }
    if (hasNoRateSchedule) {
      // eslint-disable-next-line no-unused-vars
      const { schedule, ...updatedRate } = updatedFormData.rate;
      updatedFormData = { ...updatedFormData, rate: updatedRate };
    }
    const requestBody = {
      ...updatedFormData,
      name: updatedName,
      description: updatedDescription,
      tags: updatedFormData.tags.map((tag) => tag.id),
      modifications: modifiedModifications,
      category: formData.category.id,
      unit: updatedFormData.unit?.id,
      priceSell:
        updatedFormData.priceSell === ""
          ? 0
          : parseFloat(updatedFormData.priceSell.toFixed(2)),
      priceCost: updatedFormData.priceCost
        ? parseFloat(updatedFormData.priceCost.toFixed(2))
        : undefined,
      amount: updatedFormData.amount ? updatedFormData.amount : undefined,
      timeToMake: updatedFormData.timeToMake
        ? updatedFormData.timeToMake
        : undefined,
      calories: updatedFormData.calories ? updatedFormData.calories : undefined,
      ctaLink: updatedFormData.ctaLink ? updatedFormData.ctaLink.trim() : null,
    };
    const updatedBody = {
      ...requestBody,
      ...(requestBody?.rate && {
        rate: {
          ...requestBody.rate,
          ...(requestBody.rate?.schedule && {
            schedule: {
              ...requestBody.rate.schedule,
              weekdays: JSON.stringify(requestBody.rate.schedule.weekdays),
            },
          }),
        },
      }),
      ...(requestBody?.schedule && {
        schedule: {
          ...requestBody.schedule,
          weekdays: JSON.stringify(requestBody.schedule.weekdays),
        },
      }),
    };
    if (isEditable) {
      onSave(updatedBody, item.id);
    } else {
      onSave(updatedBody);
    }
  };

  const handleOnRemoveImage = (image) => {
    const updatedImages = formData.otherImagesSrc.filter(
      (otherImage) => otherImage !== image
    );

    if (formData.coverImageSrc === image) {
      setFormData({
        ...formData,
        otherImagesSrc: updatedImages,
        coverImageSrc: "",
      });
    } else {
      setFormData({
        ...formData,
        otherImagesSrc: updatedImages,
      });
    }
  };

  const handleAddAR = async (e, type) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);
      try {
        const uploadedImageLocations = await Promise.all(
          selectedFilesArray.map((photo) => {
            return new Promise((resolve, reject) => {
              uploadARToDO({
                image: photo,
                fileType: DO_FILE_TYPES.MENU_AR,
                businessId,
                onSuccess: (location) => {
                  resolve(location);
                },
                onError: () => {
                  toast.error(t("errorMessages.image"));
                },
              }).catch((error) => {
                reject(error);
              });
            });
          })
        );
        setUploadedARImages({
          ...uploadedARImages,
          [type === AR_INPUT_TYPES.ios
            ? AR_INPUT_TYPES.ios
            : AR_INPUT_TYPES.general]: uploadedImageLocations[0],
        });
        setFormData((prevData) => ({
          ...prevData,
          model3D: {
            ...prevData.model3D,
            [type === AR_INPUT_TYPES.ios
              ? AR_INPUT_TYPES.ios
              : AR_INPUT_TYPES.general]: uploadedImageLocations[0],
          },
        }));
      } catch (error) {
        toast.error(t("errorMessages.image"));
      }
    }
  };

  const setRateSchedule = (rate) => {
    setFormData({ ...formData, rate });
  };

  const NameAndDescriptionContent = (
    <>
      <InputControl
        labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
        type="text"
        required
        name="name"
        value={getActiveLanguageValue(formData.name, activeLanguageCode)}
        placeholder={t("inputs.name")}
        className="AddPromoOrBannerModalBodyFormDescriptionInput"
        error={
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <p className="h7 error-message">{message}</p>
            )}
          />
        }
        hasError={errors.name}
        func={{
          ...register("name", {
            onChange: (e) => handleOnMultiLanguageInputChange(e, "name"),
          }),
        }}
      />
      <InputControl
        labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
        textarea
        name="description"
        value={getActiveLanguageValue(formData.description, activeLanguageCode)}
        placeholder={t("inputs.description")}
        className="AddPromoOrBannerModalBodyFormDescriptionInput"
        error={
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => (
              <p className="h7 error-message">{message}</p>
            )}
          />
        }
        hasError={errors.description}
        func={{
          ...register("description", {
            onChange: (e) => handleOnMultiLanguageInputChange(e, "description"),
          }),
        }}
      />
    </>
  );
  const NameAndDescriptionTabs = ["name", "description"];
  const setTabProperties = (properties, tabs) => {
    const newProperties = properties.map((property) => {
      return property.filter((item) => item.value !== null);
    });
    const updatedProperties = _.cloneDeep(formData);
    tabs.forEach((tab, tabIndex) => {
      const keys = tab.split(".");
      let currentLevel = updatedProperties;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          currentLevel[key] = newProperties[tabIndex];
        } else {
          updatedProperties[key] = updatedProperties[key] || {};
          currentLevel = currentLevel[key];
        }
      });
    });
    setFormData(updatedProperties);
  };
  useEffect(() => {
    if (!openSlide) {
      handleOnRemoveRedundantImagesFromAWSWhenCloseModal();
    }
    if (item) {
      setFormData({
        schedule: item.schedule.from ? item.schedule : initialSchedule,
        name: item.name,
        isPublished: item.isPublished,
        description: item.description,
        priceSell: item.priceSell,
        priceCost: item.priceCost ?? "",
        calories: item.calories || "",
        timeToMake: item.timeToMake || "",
        amount: item.amount || "",
        unit: item.unit,
        coverImageSrc: item.coverImageSrc,
        otherImagesSrc: item.otherImagesSrc,
        model3D: {
          ios: item?.model3D?.ios || null,
          general: item?.model3D?.general || null,
        },
        tags: item.tags,
        category: selectedCategory,
        rate: {
          ...item.rate,
          schedule: item.rate.schedule.from
            ? item.rate.schedule
            : initialRateSchedule,
        },
        modifications: item.modifications,
        ctaType: item.ctaType,
        ctaLink: item.ctaLink,
        hasCtaAction: item.hasCtaAction,
      });
      setFormDataInitial({
        schedule: item.schedule.from ? item.schedule : initialSchedule,
        name: item.name,
        isPublished: item.isPublished,
        description: item.description,
        priceSell: item.priceSell,
        priceCost: item.priceCost ?? "",
        calories: item.calories || "",
        timeToMake: item.timeToMake || "",
        amount: item.amount || "",
        unit: item.unit,
        coverImageSrc: item.coverImageSrc,
        otherImagesSrc: item.otherImagesSrc,
        model3D: {
          ios: item?.model3D?.ios || null,
          general: item?.model3D?.general || null,
        },
        tags: item.tags,
        category: selectedCategory,
        rate: {
          ...item.rate,
          schedule: item.rate.schedule.from
            ? item.rate.schedule
            : initialRateSchedule,
        },
        modifications: item.modifications,
        ctaType: item.ctaType,
        ctaLink: item.ctaLink,
        hasCtaAction: item.hasCtaAction,
      });

      setActiveLanguageCode(item.name[0].languageCode);
      setIsDiscountScheduleAccordionOpen(
        item.rate?.schedule?.isActive || false
      );
      setIsItemScheduleAccordionOpen(item.schedule?.isActive || false);
    } else {
      setFormData(initialData);
      setFormDataInitial(initialData);
      setActiveLanguageCode(initialData.name[0].languageCode);
      setIsDiscountScheduleAccordionOpen(initialRateSchedule.isActive);
      setIsItemScheduleAccordionOpen(initialSchedule.isActive);
    }
  }, [item, openSlide]);
  useEffect(() => {
    reset({
      formData,
      ctaLink: formData.ctaLink || "",
    });
    return () => {
      setCoverImageLoading(false);
      setCoverImageError(null);
    };
  }, [reset, formData]);

  const Features = [
    {
      id: 1,
      name: t("menu.category.magicSearch"),
      ctaLink: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.magicOrder}`,
    },
    {
      id: 2,
      name: t("funZone.funZone"),
      ctaLink: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
    },
    // {
    //   id: 3,
    //   name: t("menu.category.360Reservation"),
    //   ctaLink: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.magicOrder}s`,
    // },
  ];

  const AddPromotionOrBannerModalHeader = (
    <div className="AddPromotionOrBannerModalHeader">
      <h3 className="AddPromotionOrBannerModalHeaderTitle SemiBold">
        {isEditable
          ? t("buttons.editForModal", { title: title })
          : t("buttons.addForModal", { title: title })}
      </h3>
      <div className="ModalHeaderTitlePublishMode">
        <h6 className="h7">
          {formData.isPublished
            ? t("common.published")
            : t("common.unPublished")}
        </h6>
        <Switch
          isChecked={formData.isPublished}
          onChange={handleOnPublishModeChange}
        />
      </div>
      {isEditable && (
        <DeleteButton
          onClick={handleOnDelete}
          setOpenSlide={setOpenSlide}
          showAccessDeniedModal={showDeniedModal}
          {...(showDeniedModal && { className: "isDisabled" })}
        />
      )}
      <IconButton
        onClick={() => {
          if (
            coverImageLoading ||
            !isEqual(formDataWithoutType, formDataInitialWithoutType)
          ) {
            return setOpenSlideConfirmCloseModal(true);
          }
          setOpenSlide(false);
        }}
        svgComponent={<IconClose />}
      />
    </div>
  );
  const AddPromotionOrBannerModalBody = (
    <div className="AddPromotionOrBannerModalBody">
      <div className="ThumbnailAndCTATypeContainer">
        <div
          className={cx("ThumbnailContainer", {
            Banner: selectedCategoryType === menuCategoryType.banner,
          })}
        >
          <h5 className="SemiBold ThumbnailTitle">
            {t("menu.category.thumbnail")}
          </h5>
          <If state={formData.ctaType !== menuItemCategoryType.internal_link}>
            {formData.coverImageSrc && !coverImageLoading ? (
              <div className="ThumbnailPhotoContainer">
                <div className="ThumbnailPhoto">
                  <Photo
                    key={formData.coverImageSrc}
                    image={createDOBucketName(formData.coverImageSrc)}
                    onClose={() => handleOnRemoveImage(formData.coverImageSrc)}
                  />
                </div>
              </div>
            ) : (
              <div className="ThumbnailPhotoAddNew">
                <div className="ThumbnailPhotoAddNewComponent">
                  {coverImageLoading && !coverImageError && <Spinner />}
                  {!coverImageLoading && (
                    <AddPhotoButton
                      isMultiple={false}
                      onFileSelect={(selectedPhotos) =>
                        handleOnFileSelect(
                          selectedPhotos,
                          IMAGE_TYPE.coverImageSrc
                        )
                      }
                    />
                  )}
                  {errors.coverImageSrc && (
                    <p className="h7 error-message">
                      {errors.coverImageSrc.message}
                    </p>
                  )}
                </div>
              </div>
            )}
          </If>
        </div>
        <div className="CTAContainer">
          <div className="CTATitleAndIsActiveButton">
            <h5 className="SemiBold CTATitle">{t("menu.category.ctaType")}</h5>
            <Switch
              isChecked={formData.hasCtaAction}
              onChange={handleChangeCTAAction}
            />
          </div>
          <div className="CTATypes">
            {CTA_OPTIONS.map(({ key, label }) => {
              const isActive = formData.ctaType === menuItemCategoryType[key];

              return (
                <div
                  key={key}
                  onClick={() => handleChangeCTAType(menuItemCategoryType[key])}
                  className={cx("CTAType", {
                    isChecked: isActive,
                    notAllowed:
                      formData.ctaType !== menuItemCategoryType[key] &&
                      isEditable,
                  })}
                >
                  <h6 className={cx({ SemiBold: isActive })}>{t(label)}</h6>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="AddPromotionOrBannerFormContainer">
        <ModificationSettings
          item={selectedModification}
          setOpenSlide={setOpenSlideModification}
          mainElementRef={mainElementRefModification}
          openSlide={openSlideModification}
          onSave={handleOnSaveModifications}
          setOutsideClickAction={setOutsideClickActionModification}
        />
        <form
          className="AddMenuItemModalBodyForm"
          onSubmit={(e) => e.preventDefault()}
        >
          {formData.ctaType === menuItemCategoryType.internal_link && (
            <Controller
              control={control}
              name="ctaLink"
              render={({ field: { onChange } }) => (
                <Dropdown
                  onChange={(option) => {
                    handleCTALinkChange(option.ctaLink);
                    onChange(option.ctaLink);
                  }}
                  options={Features}
                  value={
                    formData.ctaLink
                      ? Features.find(
                          (item) => item.ctaLink === formData.ctaLink
                        )
                      : null
                  }
                  className="CTALinkDropdown"
                  placeholder={t("menu.category.features")}
                  hasError={!!errors.ctaLink}
                  isOptionRequired
                  error={
                    <ErrorMessage
                      errors={errors}
                      name="ctaLink"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  }
                />
              )}
            />
          )}
          {formData.ctaType === menuItemCategoryType.external_link && (
            <TextInput
              placeholder={t("menu.category.urlLink")}
              required
              name="ctaLink"
              labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
              func={{
                ...register("ctaLink", {
                  onChange: handleCTALinkChange,
                }),
              }}
              hasError={errors.ctaLink}
              value={formData.ctaLink}
              error={
                <ErrorMessage
                  errors={errors}
                  name="ctaLink"
                  render={({ message }) => {
                    return <p className="h7 error-message">{message}</p>;
                  }}
                />
              }
            />
          )}
          <Language
            content={NameAndDescriptionContent}
            activeLanguageCode={activeLanguageCode}
            setActiveLanguageCode={setActiveLanguageCode}
            properties={[formData.name, formData.description]}
            setProperties={(properties) =>
              setTabProperties(properties, NameAndDescriptionTabs)
            }
          ></Language>
          {formData.ctaType === menuItemCategoryType.menu_items && (
            <>
              <div className="AddMenuItemModalBodyFormBox">
                <div className="AddMenuItemModalBodyFormPriceAndDiscount">
                  <div className="AddMenuItemModalBodyFormPrice">
                    <InputControl
                      labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                      type="number"
                      name="priceSell"
                      required
                      value={formData.priceSell}
                      placeholder={t("inputs.sell")}
                      className="AddMenuItemModalBodyFormInputHalfWidth"
                      hasError={errors.priceSell}
                      func={{
                        ...register("priceSell", {
                          setValueAs: (value) =>
                            value === "" ? undefined : parseFloat(value),
                          onChange: (e) => handleOnInputChange(e),
                        }),
                      }}
                      definitionText={currencyCode}
                      error={
                        <ErrorMessage
                          errors={errors}
                          name="priceSell"
                          render={({ message }) => (
                            <p className="h7 error-message">{message}</p>
                          )}
                        />
                      }
                    />
                    <InputControl
                      labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                      type="number"
                      name="priceCost"
                      value={formData.priceCost}
                      hasError={errors.priceCost}
                      definitionText={currencyCode}
                      func={{
                        ...register("priceCost", {
                          setValueAs: (value) =>
                            value === "" ? undefined : parseFloat(value),
                          onChange: (e) => handleOnInputChange(e),
                        }),
                      }}
                      error={
                        <ErrorMessage
                          errors={errors}
                          name="priceCost"
                          render={({ message }) => (
                            <p className="h7 error-message">{message}</p>
                          )}
                        />
                      }
                      placeholder={t("inputs.cost")}
                      className="AddMenuItemModalBodyFormInputHalfWidth"
                    />
                  </div>
                  <div className="RateExtraComponent">
                    <div className="RateExtraComponentTitle">
                      <Switch
                        onChange={handleOnChangeRateEnabled}
                        isChecked={formData.rate.isEnabled}
                        type={ENUMS_SWITCH.types.TYPE_A}
                      />
                      <h6 className="Medium">{t("buttons.applyDiscount")}</h6>
                    </div>
                    {formData.rate.isEnabled && (
                      <div className="RateExtraComponentBody">
                        <div className="RateExtraComponentBodyIsFixed">
                          <RadioSelection
                            onChange={handleOnChangeRate}
                            name="isFixed"
                            isChecked={!formData.rate.isFixed}
                            value={false}
                            label={t("inputs.notFixed")}
                          />
                          <RadioSelection
                            onChange={handleOnChangeRate}
                            name="isFixed"
                            isChecked={formData.rate.isFixed}
                            value={true}
                            label={t("inputs.fixed")}
                          />
                        </div>
                        <InputControl
                          labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                          type="number"
                          name="rate"
                          value={formData.rate.amount || ""}
                          placeholder={t("inputs.discount")}
                          definitionText={
                            formData.rate.isFixed ? currencyCode : "%"
                          }
                          className="RateExtraComponentItemInput"
                          hasError={errors.rate}
                          func={{
                            ...register("rate", {
                              setValueAs: (value) =>
                                value === "" ? undefined : parseFloat(value),
                              onChange: (e) => handleOnRateChange(e),
                            }),
                          }}
                          error={
                            <ErrorMessage
                              errors={errors}
                              name="rate"
                              render={({ message }) => (
                                <p className="h7 error-message">{message}</p>
                              )}
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                {formData.rate.isEnabled && (
                  <DateAccordion
                    setIsAccordionOpen={setIsDiscountScheduleAccordionOpen}
                    isAccordionOpen={isDiscountScheduleAccordionOpen}
                    setFormData={setRateSchedule}
                    formData={formData.rate}
                    title={t("menu.item.scheduleDiscount")}
                  />
                )}
              </div>
              <div className="AddMenuItemModalBodyFormBox AddMenuItemModalBodyFormBoxDropdown">
                <Dropdown
                  placeholder={t("menu.category.category")}
                  name="category"
                  value={formData.category}
                  isOptionRequired
                  options={categories}
                  isMultiLanguage
                  onChange={(category) => {
                    handleOnDropdownChange(category, "category");
                  }}
                />
                <Dropdown
                  isMultiple
                  placeholder={t("menu.tag.tags")}
                  name="tag"
                  value={formData.tags}
                  options={tags}
                  onChange={handleOnTagChange}
                  isMultiLanguage
                />
              </div>
              <div className="AddMenuItemModalBodyPhotos">
                <h5 className="Medium">{t("image.images")}</h5>
                <div className="AddMenuItemModalBodyPhotoAdd">
                  <div className="AddMenuItemModalBodyPhotoAddNew">
                    <div className="AddMenuItemModalBodyPhotoAddNewComponent">
                      <AddPhotoButton
                        onFileSelect={(selectedPhotos) =>
                          handleOnFileSelect(
                            selectedPhotos,
                            IMAGE_TYPE.otherImagesSrc
                          )
                        }
                      />
                    </div>
                  </div>
                  {(formData.otherImagesSrc.length > 0 ||
                    coverImageLoading) && (
                    <div className="AddMenuItemModalBodyPhotoAddExisting">
                      <div className="AddMenuItemModalBodyPhotoAddExistingPhotos">
                        {coverImageLoading && !coverImageError && <Spinner />}
                        {formData.otherImagesSrc.map((photo) => (
                          <Photo
                            key={photo}
                            image={createDOBucketName(photo)}
                            onClose={() => handleOnRemoveImage(photo)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <If state={isAdmin}>
                  <ArInput
                    onClick={handleAddAR}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </If>
              </div>
              <div className="AddMenuItemModalBodyFormBox">
                <div className="AddMenuItemModalBodyFormBoxRow">
                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    type="number"
                    name="calories"
                    value={formData.calories}
                    func={{
                      ...register("calories", {
                        setValueAs: (value) =>
                          value === "" ? undefined : parseFloat(value),
                        onChange: (e) => handleOnInputChange(e),
                      }),
                    }}
                    hasError={errors.calories}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="calories"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    placeholder={t("inputs.calories")}
                    className="AddMenuItemModalBodyFormInputHalfWidth"
                    definitionText={t("common.kcal")}
                  />

                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    type="number"
                    name="timeToMake"
                    value={formData.timeToMake}
                    hasError={errors.timeToMake}
                    func={{
                      ...register("timeToMake", {
                        setValueAs: (value) =>
                          value === "" ? undefined : parseFloat(value),
                        onChange: (e) => handleOnInputChange(e),
                      }),
                    }}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="timeToMake"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    placeholder={t("inputs.time")}
                    className="AddMenuItemModalBodyFormInputHalfWidth"
                    definitionText={t("common.time.min")}
                  />
                </div>
                <div className="AddMenuItemModalBodyFormBoxRow">
                  <InputControl
                    labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
                    type="number"
                    name="amount"
                    value={formData.amount}
                    placeholder={t("inputs.number")}
                    hasError={errors.amount}
                    className="AddMenuItemModalBodyFormInputHalfWidth"
                    infoText={t("info.itemAmount")}
                    func={{
                      ...register("amount", {
                        setValueAs: (value) =>
                          value === "" ? undefined : parseFloat(value),
                        onChange: (e) => handleOnInputChange(e),
                      }),
                    }}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="amount"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                  />
                  <Dropdown
                    placeholder={t("inputs.unit")}
                    name="unit"
                    value={formData.unit}
                    hasError={errors.unit}
                    options={units}
                    isOptionRequired
                    {...register("unit")}
                    onChange={(unit) => {
                      handleOnDropdownChange(unit, "unit");
                    }}
                    className="AddMenuItemModalBodyFormInputHalfWidth"
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="unit"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className="AddMenuItemModalBodyFormBox">
            <DateAccordion
              setIsAccordionOpen={setIsItemScheduleAccordionOpen}
              isAccordionOpen={isItemScheduleAccordionOpen}
              setFormData={setFormData}
              formData={formData}
            />
          </div>
          {formData.ctaType === menuItemCategoryType.menu_items && (
            <div className="AddMenuItemModalBodyFormBox">
              <div className="AddMenuItemModalBodyModifications">
                <div className="AddMenuItemModalBodyModificationsTitle">
                  <h5 className="Medium">{t("modification.modifications")}</h5>
                  <AddNewButton
                    onClick={handleOnAddModification}
                    type={ENUMS_ADD_NEW_BUTTON.types.TYPE_B}
                  />
                </div>
                {formData.modifications.length > 0 ? (
                  <div className="AddMenuItemModalBodyModificationsList">
                    {formData.modifications.map((modification) => (
                      <ModificationsMenu
                        key={modification.id}
                        data={modification}
                        selectedOptions={getBasketModificationsWithDefaultValues(
                          formData.modifications
                        ).find((options) => options.id === modification.id)}
                        hasControls
                        onEdit={() => {
                          handleOnEditModification(modification);
                        }}
                        onDelete={() => {
                          handleOnDeleteModification(modification.id);
                        }}
                        isReadOnly
                      />
                    ))}
                  </div>
                ) : (
                  <h6 className="Medium AddMenuItemModalBodyNoModifications">
                    {t("modification.noModifications")}
                  </h6>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        description={t("modal.warningModalDescription")}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
      />
    </div>
  );

  const AddPromotionOrBannerModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={AddPromotionOrBannerModalHeader}
      body={AddPromotionOrBannerModalBody}
      footer={AddPromotionOrBannerModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AddPromotionOrBannerModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    schedule: PropTypes.object,
    value: PropTypes.string,
    name: PropTypes.array,
    description: PropTypes.array,
    priceSell: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    priceCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    calories: PropTypes.number,
    timeToMake: PropTypes.number,
    amount: PropTypes.number,
    unit: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.array,
      })
    ),
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array,
    model3D: PropTypes.object,
    modifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    category: PropTypes.object,
    isPublished: PropTypes.bool,
    rate: PropTypes.object.isRequired,
    isFixed: PropTypes.bool,
    ctaType: PropTypes.string,
    ctaLink: PropTypes.string,
    hasCtaAction: PropTypes.bool,
  }),
  title: PropTypes.string.isRequired,
  openSlide: PropTypes.bool,
  setOpenSlide: PropTypes.func,
  mainElementRef: PropTypes.object,
  onDelete: PropTypes.func,
  selectedCategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.array,
  }),
  selectedCategoryType: PropTypes.string,
  showDeniedModal: PropTypes.bool,
  setOutsideClickAction: PropTypes.func,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
};

export default AddPromotionOrBannerModal;
