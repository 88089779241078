import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import {
  findGuest,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import ScrollView from "pages/client/menu-v2/category/scroll-view/ScrollView";
import GridView from "pages/client/menu-v2/category/grid-view/GridView";
import WithoutImageView from "pages/client/menu-v2/category/without-image-view/WithoutImageView";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";

import styles from "./Category.module.scss";

const viewComponents = {
  [MENU_VIEW_ENUMS.SCROLL.key]: ScrollView,
  [MENU_VIEW_ENUMS.GRID.key]: GridView,
  [MENU_VIEW_ENUMS.WITHOUT_IMAGE.key]: WithoutImageView,
};

const Category = ({ category, lazyImage = false }) => {
  const { t } = useTranslation();
  const routerNavigate = useNavigate();
  const { navigate } = useInternalNavigation();
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { showTabBar } = useGuestLayout();
  const { defaultMenuViewType } = useMenuHelper();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const handleAddToBasket = (menuItem) => {
    const filteredModifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    ).filter((modification) => modification.options.length > 0);

    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => ({
            id: modification.id,
            options: modification.options.map((option) => ({
              id: option.id,
              count: option.count ?? 1,
            })),
          })),
        },
        count: 1,
        userId: guestId,
      })
    );
    showTabBar();
  };
  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);
  const favoriteItems = guest?.favoriteItems || [];

  const handleGoSeeAllPage = () => {
    routerNavigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}${ROUTE_NAME.category}/${category?.id}`
    );
  };

  const handleFavItem = (menuItem) => {
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleOnClick = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  const handleOpenAr = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItemAR}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  const SelectedView = viewComponents[defaultMenuViewType];

  return (
    <>
      <div className={styles.Category}>
        <div className={styles.Title}>
          <h2 className={cx(styles.Name, "SemiBold")}>
            {category.extraFields.displayedName}
          </h2>
          {category.menuItems.length > 1 && (
            <h6
              className={cx(styles.All, "SemiBold")}
              onClick={handleGoSeeAllPage}
            >
              {t("buttons.seeAll")}
            </h6>
          )}
        </div>
        <SelectedView
          category={category}
          handleAddToBasket={handleAddToBasket}
          favoriteItems={favoriteItems}
          lazyImage={lazyImage}
          handleOpenAr={handleOpenAr}
          handleFavItem={handleFavItem}
          handleOnClick={handleOnClick}
        />
      </div>
    </>
  );
};
Category.propTypes = {
  category: PropTypes.object,
  lazyImage: PropTypes.bool,
};

export default Category;
