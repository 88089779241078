import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import IconButton, {
  ENUMS as ICON_BUTTON_ENUMS,
} from "../buttons/icon-button/IconButton";
import { ReactComponent as IconClose } from "assets/icons/close/CloseBig.svg";
import Modal from "components/modal/Modal";
import PrivacyPolicy from "pages/common/legal/privacy-policy/PrivacyPolicy";
import TermsAndCondition from "pages/common/legal/terms-and-condition/TermsAndCondition";
import { TERMS_AND_PRIVACY } from "utils/constants/global";

import "./PolicyModal.scss";

const PolicyModal = ({
  open,
  onClose,
  mainElementRefPolicy,
  termsOrPrivacy,
}) => {
  const { t } = useTranslation();

  const handleOnModalClose = () => {
    onClose();
  };

  const PolicyModalHeader = (
    <div className="AddItemModalHeader">
      <h3 className="SemiBold AddItemModalHeaderTitle">
        {termsOrPrivacy === TERMS_AND_PRIVACY.TERMS.key
          ? t("privacyPolicy.termsAndCondition")
          : t("termsAndCondition.privacyPolicy")}
      </h3>
      <IconButton
        onClick={handleOnModalClose}
        svgComponent={<IconClose />}
        className="AddItemModalHeaderCloseButton"
        type={ICON_BUTTON_ENUMS.types.TYPE_MEDIUM}
      />
    </div>
  );

  const PolicyModalBody = (
    <div className="PolicyModalBody">
      {termsOrPrivacy === TERMS_AND_PRIVACY.TERMS.key && <TermsAndCondition />}
      {termsOrPrivacy === TERMS_AND_PRIVACY.PRIVACY.key && <PrivacyPolicy />}
    </div>
  );

  return (
    <Modal
      openSlide={open}
      header={PolicyModalHeader}
      body={PolicyModalBody}
      onClose={handleOnModalClose}
      mainElementRef={mainElementRefPolicy}
    />
  );
};

PolicyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRefPolicy: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  policyModalTranslateFileKey: PropTypes.string.isRequired,
  termsOrPrivacy: PropTypes.string,
};

export default PolicyModal;
