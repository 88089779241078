import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Review from "components/elements/review/Review";
import { useDispatch, useSelector } from "react-redux";
import useAsync from "utils/hooks/useAsync";
import { createGuestRating } from "utils/api/services/order";
import { STORE_NAMES } from "utils/constants/redux";
import { toast } from "react-toastify";
import { setOrReplaceOrder } from "redux/slices/ordersStore";
import { ROUTE_NAME } from "utils/constants/routes";
import { ReactComponent as IconArrow } from "assets/icons/menuv2/ArrowLeft.svg";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";

import "./GuestFeedBack.scss";

const GuestFeedBack = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.feedback });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateToPreservedBackUrl } = useInternalNavigation();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const currentGuest =
    order?.guests.find((guest) => guest.person.id === guestId) || {};

  const businessId = qrScanStore.businessId;
  const [isReviewRequestSuccessful, setIsReviewRequestSuccessful] =
    useState(null);

  const { execute: executeGuestRating, loading: isGuestRatingLoading } =
    useAsync(createGuestRating, {
      onError: (error) => {
        setIsReviewRequestSuccessful(false);
        toast.error(error.response.data.message);
      },
      onSuccess: (response) => {
        setIsReviewRequestSuccessful(true);
        dispatch(setOrReplaceOrder([response.data]));
      },
    });

  const handleOnSubmitReview = async (data) => {
    await executeGuestRating({
      guestRatingData: { ...data, tableId },
      businessId,
      orderId: order.id,
      orderGuestId: currentGuest.id,
    });
  };

  const handleGoBack = () => {
    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
    });
  };

  const GuestFeedBackHeader = (
    <div className="GuestFeedBackHeader">
      <div className="GuestFeedBackBackContainer" onClick={handleGoBack}>
        <IconArrow />
      </div>
      <h4 className="SemiBold">{t("dashboard.guest.review.feedBack")}</h4>
    </div>
  );
  const GuestFeedBackBody = (
    <div className="GuestFeedBackTitle">
      <h3 className="SemiBold">
        {t("dashboard.feedbackStatistic.feedBackTitle")}
      </h3>
      <h6>{t("dashboard.feedbackStatistic.feedBackDesc")}</h6>
    </div>
  );
  return (
    <div className="GuestFeedBack">
      {GuestFeedBackHeader}
      {GuestFeedBackBody}
      <Review
        loading={isGuestRatingLoading}
        guest={currentGuest}
        onSubmit={handleOnSubmitReview}
        isReviewRequestSuccessful={isReviewRequestSuccessful}
        setIsReviewRequestSuccessful={setIsReviewRequestSuccessful}
      />
    </div>
  );
};

export default GuestFeedBack;
