import { PrintCommandBuilder } from "utils/helper-functions/electron-helper/printCommandBuilder";
import {
  addOrderDetails,
  addOrderItems,
  addOrderMessages,
  collectOrderItems,
  buildHeaderSection,
  buildFooterSection,
  buildMessageSection,
  buildTotalSection,
  buildBeep,
} from "utils/helper-functions/electron-helper/print-command-helper";

/**
 * Creates printer commands for multiple orders
 * @param {Object} params - The parameters object
 * @param {Array} params.order - List of orders to be processed
 * @param {Function} params.t - Translation function
 * @param {Object} params.options - Configuration options
 * @param {string} params.options.businessName - Name of the business
 * @param {Array | undefined} params.options.businessImages - Business images
 * @param {boolean} params.options.isTotal - Whether to include totals
 * @param {boolean} params.options.isWaitForAccept - Whether order is pending acceptance
 * @param {string} params.options.language - Language code
 * @param {string} params.options.currency - Currency code
 * Builds and returns the list of print commands.
 * @returns {Array<{
 *   type: ('text'|'drawLine'|'table'|'align'|'bold'|'newline'|'cut'|'beep'),
 *   value?: string|boolean,
 *   columns?: Array<{
 *     text: string,
 *     width: number
 *   }>,
 *   times?: number,
 *   duration?: number
 * }>} Array of print commands where each command is an object with:
 * - type: Command type identifier
 * - value: (optional) Command value for text, align, and bold commands
 * - columns: (optional) Array of column configurations for table command
 * - times: (optional) Number of beeps for beep command
 * - duration: (optional) Duration of beeps for beep command
 * @example
 * // Returns an array like:
 * [
 *   { type: 'align', value: 'center' },
 *   { type: 'bold', value: true },
 *   { type: 'text', value: 'Receipt' },
 *   { type: 'table', columns: [{ text: 'Item', width: 20 }] },
 *   { type: 'drawLine' },
 *   { type: 'beep', times: 3, duration: 6 },
 *   { type: 'cut' }
 * ]
 */

export const createOrderPrinterCommands = ({
  order,
  t,
  options,
  soundType,
}) => {
  // Initialize order data
  const orderData = {
    orderItems: collectOrderItems({ order, options }),
    orderMessages: [],
    printData: { commands: [] },
  };

  // Add messages
  addOrderMessages(order, orderData);

  // Return if no data to print
  if (
    orderData.orderItems.length === 0 &&
    orderData.orderMessages.length === 0
  ) {
    return null;
  }

  // Build print commands
  const builder = new PrintCommandBuilder();

  buildHeaderSection({
    builder,
    businessName: options.businessName,
    t,
    options,
  });
  addOrderDetails({ builder, order, t, options });
  buildMessageSection({ builder, messages: orderData.orderMessages, t });

  const totalPrice = addOrderItems({ builder, orderData, t, options });

  buildTotalSection({ builder, totalPrice, t, options });
  buildFooterSection(builder, t);
  buildBeep(builder, soundType);

  return builder.build();
};

/**
 * Creates printer commands for multiple orders
 * @param {Object} params - The parameters object
 * @param {Array} params.orders - List of orders to be processed
 * @param {Function} params.t - Translation function
 * @param {Object} params.options - Configuration options
 * @param {string} params.options.businessName - Name of the business
 * @param {Array | undefined} params.options.businessImages - Business images
 * @param {boolean} params.options.isTotal - Whether to include totals
 * @param {boolean} params.options.isWaitForAccept - Whether order is pending acceptance
 * @param {string} params.options.language - Language code
 * @param {string} params.options.currency - Currency code
 * Builds and returns the list of print commands.
 * @returns {Array<{
 *   type: ('text'|'drawLine'|'table'|'align'|'bold'|'newline'|'cut'|'beep'),
 *   value?: string|boolean,
 *   columns?: Array<{
 *     text: string,
 *     width: number
 *   }>,
 *   times?: number,
 *   duration?: number
 * }>} Array of print commands where each command is an object with:
 * - type: Command type identifier
 * - value: (optional) Command value for text, align, and bold commands
 * - columns: (optional) Array of column configurations for table command
 * - times: (optional) Number of beeps for beep command
 * - duration: (optional) Duration of beeps for beep command
 * @example
 * // Returns an array like:
 * [
 *   { type: 'align', value: 'center' },
 *   { type: 'bold', value: true },
 *   { type: 'text', value: 'Receipt' },
 *   { type: 'table', columns: [{ text: 'Item', width: 20 }] },
 *   { type: 'drawLine' },
 *   { type: 'beep', times: 3, duration: 6 },
 *   { type: 'cut' }
 * ]
 */
export const createPrinterCommandsFromOrders = ({
  orders,
  t,
  options,
  soundType,
}) => {
  return orders.reduce((allCommands, order) => {
    const orderCommands = createOrderPrinterCommands({
      order,
      t,
      options,
      soundType,
    });
    if (orderCommands) {
      return [...allCommands, ...orderCommands];
    }
    return allCommands;
  }, []);
};
