import { useRef, useEffect } from "react";

const usePrevious = ({ value, dep = [] }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, dep);

  return ref.current;
};

export default usePrevious;
