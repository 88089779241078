import React from "react";
import PropTypes from "prop-types";

import If from "components/if/If";
import { useTranslation } from "react-i18next";

export const AR_INPUT_TYPES = {
  general: "general",
  ios: "ios",
};

const ArInput = ({ onClick, formData, setFormData }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="Medium">
        {t("inputs.forType", { type: t("common.general") })}
      </h5>
      <If state={formData?.model3D?.general}>
        <h6 className="Medium">{formData?.model3D?.general}</h6>
        <button
          onClick={() =>
            setFormData((prevData) => ({
              ...prevData,
              model3D: {
                ...prevData.model3D,
                general: null,
              },
            }))
          }
        >
          {t("buttons.delete")}
        </button>
      </If>
      <If state={!formData?.model3D?.general}>
        <div>
          <input
            type="file"
            onChange={(e) => onClick(e, AR_INPUT_TYPES.general)}
            multiple={false}
          />
        </div>
      </If>
      <h5 className="Medium">{t("inputs.forType", { type: t("Ios") })}</h5>
      <If state={formData?.model3D?.ios}>
        <h6 className="Medium">{formData?.model3D?.ios} </h6>
        <button
          onClick={() =>
            setFormData((prevData) => ({
              ...prevData,
              model3D: {
                ...prevData.model3D,
                ios: null,
              },
            }))
          }
        >
          {t("buttons.delete")}
        </button>
      </If>
      <If state={!formData?.model3D?.ios}>
        <div>
          <input
            type="file"
            onChange={(e) => onClick(e, AR_INPUT_TYPES.ios)}
            multiple={false}
          />
        </div>
      </If>
    </>
  );
};

ArInput.propTypes = {
  onClick: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default ArInput;
