import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import { STORE_NAMES } from "utils/constants/redux";

import "./GuestInformation.scss";

export const ENUMS = {
  name: "GuestInformation",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const GuestInformation = ({
  menuItemCount,
  totalPrice,
  handlePayClick,
  guestCount,
  type,
  isPayButtonDisabled,
  tableName,
}) => {
  const { t } = useTranslation();

  const business = useSelector((state) => state[STORE_NAMES.business].business);
  return (
    <div
      className={cx(
        "GuestInformation",
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        },
        {
          typeC: type === ENUMS.types.TYPE_C,
        }
      )}
    >
      {type !== ENUMS.types.TYPE_C && (
        <div className="GuestInformationCountWrapper">
          <div className="GuestInformationHeader">
            <h6 className="h7 Medium">{t("dashboard.yourTable")}:</h6>
            <h6 className="h7 Medium">{t("dashboard.totalGuests")}:</h6>
            <h6 className="h7 Medium">{t("dashboard.totalItems")}:</h6>
          </div>
          <div className="GuestInformationResult">
            <h5 className="Medium">{tableName} </h5>
            <h5 className="Medium">{guestCount}</h5>
            <h5 className="Medium">{menuItemCount} </h5>
          </div>
        </div>
      )}
      {type === ENUMS.types.TYPE_C && (
        <CTAButton
          onClick={handlePayClick}
          element={
            <div className="GuestPayDetail">
              <div className="OrderItemsToBePaidCount">
                <h6 className="SemiBold">{menuItemCount}</h6>
              </div>
              <h3 className="SemiBold PayButton">
                {business.isAskForBillEnabled
                  ? t("waiter.bill")
                  : t("basket.order.bill")}
              </h3>
            </div>
          }
          type={ENUMS_CTA_BUTTON.types.TYPE_Y}
          price={totalPrice}
          disabled={isPayButtonDisabled}
          className="GuestInformationPayButtonWrapper"
        />
      )}

      {type !== ENUMS.types.TYPE_C && (
        <div className="GuestInformationPriceWrapper">
          {type === ENUMS.types.TYPE_A && (
            <CTAButton
              onClick={handlePayClick}
              element={
                <div className="GuestPayDetail">
                  <h4 className="SemiBold"> {t("buttons.pay")}</h4>
                  <h6 className="Bold">({menuItemCount} items)</h6>
                </div>
              }
              type={ENUMS_CTA_BUTTON.types.TYPE_D}
              price={totalPrice}
              disabled={isPayButtonDisabled}
            />
          )}
        </div>
      )}

      {/*<div className="GuestInformationCountWrapper">*/}
      {/*  <p>{t("menu.item.selected")}</p>*/}
      {/*  <h2 className="SemiBold">{menuItemCount}</h2>*/}
      {/*</div>*/}
      {/*<div className="GuestInformationPriceWrapper">*/}
      {/*  <div>*/}
      {/*    {type === ENUMS.types.TYPE_A && <p>{t("basket.order.totalCost")}</p>}*/}

      {/*    {type === ENUMS.types.TYPE_B && (*/}
      {/*      <p>*/}
      {/*        {t("payment.totalCostFor")} {guestCount}&nbsp;*/}
      {/*        {t("dashboard.guest.guests")}*/}
      {/*      </p>*/}
      {/*    )}*/}

      {/*    <Price value={totalPrice} type={ENUMS_PRICE.types.PRICE_XL} />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*{type === ENUMS.types.TYPE_A && (*/}
      {/*  <CTAButton*/}
      {/*    onClick={handlePayClick}*/}
      {/*    name={t("buttons.pay")}*/}
      {/*    type={ENUMS_CTA_BUTTON.types.TYPE_D}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

GuestInformation.propTypes = {
  /**
   * The number of selected menu items.
   */
  menuItemCount: PropTypes.number.isRequired,
  /**
   * The total cost of the selected menu items.
   */
  totalPrice: PropTypes.number,
  /**
   * Callback function to handle the "Pay" action.
   */
  handlePayClick: PropTypes.func,
  /**
   * The type of the component. Should be one of the values defined in ENUMS.types.
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
  /**
   * The number of guests.
   */
  guestCount: PropTypes.number,
  /**
   * Pay Button is disabled or not
   */
  isPayButtonDisabled: PropTypes.bool,
  tableName: PropTypes.string,
};

export default GuestInformation;
