import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import "./TableV2.scss";

/**
 * @component TableV2
 * @description A responsive table component that adjusts cell display and proportions based on defined breakpoints.
 * Handles loading and empty states with customizable components.
 *
 * @param {Object} meta - Metadata defining table behavior.
 * @param {Array<Object>} meta.breakpoints - Array of breakpoint configurations.
 * @param {Object} meta.breakpoints[].BreakPointsRange - Range object with min and max values for breakpoints.
 * @param {number} meta.breakpoints[].BreakPointsRange.min - Minimum width for the breakpoint.
 * @param {number} meta.breakpoints[].BreakPointsRange.max - Maximum width for the breakpoint (-1 for no upper limit).
 * @param {Array<number|null>} meta.breakpoints[].cellDisplayAndProportions - Array of cell width percentages per breakpoint, or `null` to hide the cell.
 *
 * @param {Array<Object>} headers - Array of header cell configurations.
 * @param {JSX.Element} headers[].element - JSX element to render as header cell content.
 *
 * @param {Array<Array<Object>>} rows - Array of rows, each containing an array of cell configurations.
 * @param {JSX.Element} rows[][].element - JSX element to render as body cell content.
 *
 * @param {boolean} loading - Flag to indicate if the table is in a loading state.
 *
 * @param {JSX.Element} LoadingComponent - Component to render during loading state.
 *
 * @param {JSX.Element} EmptyComponent - Component to render when no rows are available.
 *
 * @param {JSX.Element} [Footer] - (Optional) A JSX component to render as the footer for the entire table.
 * This can be any valid JSX element that you want to display at the bottom of the table, typically used for summaries or additional information.
 *
 * @example
 * <TableV2
 *   meta={{
 *     breakpoints: [
 *       { BreakPointsRange: { min: 0, max: 600 }, cellDisplayAndProportions: [100, null, null] },
 *       { BreakPointsRange: { min: 601, max: 1024 }, cellDisplayAndProportions: [50, 50, null] },
 *       { BreakPointsRange: { min: 1025, max: -1 }, cellDisplayAndProportions: [33, 33, 33] },
 *     ],
 *   }}
 *   headers={[
 *     { element: <div>Column 1</div> },
 *     { element: <div>Column 2</div> },
 *     { element: <div>Column 3</div> },
 *   ]}
 *   rows={[
 *     [
 *       { element: <div>Row 1, Cell 1</div> },
 *       { element: <div>Row 1, Cell 2</div> },
 *       { element: <div>Row 1, Cell 3</div> },
 *     ],
 *     [
 *       { element: <div>Row 2, Cell 1</div> },
 *       { element: <div>Row 2, Cell 2</div> },
 *       { element: <div>Row 2, Cell 3</div> },
 *     ],
 *   ]}
 *   loading={false}
 *   LoadingComponent={<div>Loading...</div>}
 *   EmptyComponent={<div>No Data Available</div>}
 *   Footer={<div>Total: 100</div>}  // A single JSX element as the footer
 * />
 */

export const TableV2 = ({
  meta,
  headers,
  rows = [],
  loading = false,
  LoadingComponent = <div>Loading...</div>,
  EmptyComponent = <div>No data available</div>,
  Footer = null,
}) => {
  const generateCellStyles = (breakpointProportions) => {
    return breakpointProportions
      .map((proportion, index) => {
        const display = proportion === null ? "none" : "block";
        const flexBasis = proportion !== null ? `${proportion}%` : "0%";

        return `
          .table-cell-style-${index} {
            flex-basis: ${flexBasis};
            display: ${display};
          }
        `;
      })
      .join("\n");
  };

  const getCurrentTableBreakpoint = (windowSize) => {
    const CurrentBreakPoint = meta?.breakpoints.find((breakpoint) => {
      const { min, max } = breakpoint.BreakPointsRange;
      return windowSize >= min && (max === -1 || windowSize <= max);
    });

    return CurrentBreakPoint;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const activeBreakPoint = useMemo(
    () => getCurrentTableBreakpoint(window.innerWidth),
    [windowWidth]
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dynamicStyles = useMemo(
    () => generateCellStyles(activeBreakPoint?.cellDisplayAndProportions || []),
    [activeBreakPoint]
  );

  return (
    <div className="TableV2" role="table" aria-label="Responsive Table">
      <style>{dynamicStyles}</style>

      <div className="HeaderRow" role="row">
        {headers.map((headerCell, index) => (
          <div
            key={index}
            className={`HeaderCell table-cell-style-${index}`}
            role="columnheader"
          >
            {headerCell.element}
          </div>
        ))}
      </div>

      <div className="BodyWrapper">
        {loading ? (
          LoadingComponent
        ) : (
          <>
            {!rows || rows?.length === 0
              ? EmptyComponent
              : rows.map((row, rowIndex) => (
                  <div key={rowIndex} className="BodyRow" role="row">
                    {row.map((rowCell, cellIndex) => (
                      <div
                        key={cellIndex}
                        className={`BodyCell table-cell-style-${cellIndex}`}
                        role="cell"
                      >
                        {rowCell.element}
                      </div>
                    ))}
                  </div>
                ))}
          </>
        )}
        {Footer && <div className="Footer">{Footer}</div>}
      </div>
    </div>
  );
};

TableV2.propTypes = {
  meta: PropTypes.shape({
    breakpoints: PropTypes.arrayOf(
      PropTypes.shape({
        BreakPointsRange: PropTypes.shape({
          min: PropTypes.number.isRequired,
          max: PropTypes.number.isRequired,
        }).isRequired,
        cellDisplayAndProportions: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.node.isRequired,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        element: PropTypes.node.isRequired,
      })
    )
  ),
  loading: PropTypes.bool,
  LoadingComponent: PropTypes.node,
  EmptyComponent: PropTypes.node,
  Footer: PropTypes.node,
};
