import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as IconArrow } from "assets/icons/menuv2/ArrowLeft.svg";
import MenuCard, { ENUMS } from "pages/client/menu-v2/MenuCard/MenuCard";
import { STORE_NAMES } from "utils/constants/redux";
import { findGuest } from "utils/helpers";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import If from "components/if/If";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import styles from "./MenuItemList.module.scss";

const DefaultEmptyStateComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.EmptyState}>
      <h5 className="Medium">{t("emptyStates.noItem")}</h5>
    </div>
  );
};

const MenuItemList = ({
  onGoBack,
  title,
  categories,
  onClick,
  onFavorite,
  addToBasket,
  emptyStateComponent = <DefaultEmptyStateComponent />,
  actionButtonComponent = null,
}) => {
  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(userId, basketOrders);
  const favoriteItems = guest?.favoriteItems || [];
  const { businessId } = useParams();
  const { navigate } = useInternalNavigation();

  const { shouldDisplayMenuItemImage } = useMenuHelper();
  const displayedItems = useMemo(() => {
    return categories.flatMap(({ menuItems }) => menuItems);
  }, [categories]);

  const shouldShowEmptyState = displayedItems.length === 0;

  const handleOpenAr = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItemAR}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.BackWrapper} onClick={onGoBack}>
          <IconArrow className={styles.Back} />
        </div>
        <h4 className={cx("SemiBold", styles.Title)}>{title}</h4>
        <If state={Boolean(actionButtonComponent)}>
          <div className={styles.ActionBtn}>{actionButtonComponent}</div>
        </If>
      </div>
      <If state={displayedItems.length > 0}>
        <div className={styles.Items}>
          {displayedItems.map((item) => {
            return (
              <MenuCard
                key={item.id}
                menuItem={item}
                type={
                  shouldDisplayMenuItemImage
                    ? ENUMS.types.LARGE
                    : ENUMS.types.WITHOUT_IMAGE
                }
                isFavorite={favoriteItems.includes(item.id)}
                onClick={() => onClick(item)}
                onFavorite={() => onFavorite(item)}
                onAddToBasket={() => addToBasket(item)}
                handleOpenAr={() => handleOpenAr(item)}
              />
            );
          })}
        </div>
      </If>
      {shouldShowEmptyState && emptyStateComponent}
    </div>
  );
};

MenuItemList.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  emptyStateComponent: PropTypes.node,
  onGoBack: PropTypes.func.isRequired,
  actionButtonComponent: PropTypes.node,
  onFavorite: PropTypes.func.isRequired,
  addToBasket: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

export default MenuItemList;
