import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./Switch.scss";

export const ENUMS = {
  name: "Switch",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const Switch = ({
  type = ENUMS.types.TYPE_A,
  isChecked,
  onChange,
  label,
  isDisabled,
}) => {
  return (
    <div
      className={cx(`SwitchContainer ${isDisabled ? "isDisabled" : ""}`, {
        typeB: type === ENUMS.types.TYPE_B,
      })}
    >
      <div
        className={cx(
          "Switch",
          {
            isChecked: isChecked,
          },
          {
            typeA: type === ENUMS.types.TYPE_A,
          }
        )}
        onClick={onChange}
      >
        <div className="SwitchSlider"></div>
      </div>
      {label && <h6 className="SwitchLabel">{label}</h6>}
    </div>
  );
};

Switch.propTypes = {
  /**
   * The type of the checkbox
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * Whether the switch is checked or not
   */
  isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),

  /**
   * Callback function to handle switch toggling
   */
  onChange: PropTypes.func,

  /**
   * The label text for the switch
   */
  label: PropTypes.string,
  /**
   * Is button disabled or not
   */
  isDisabled: PropTypes.bool,
};

export default Switch;
