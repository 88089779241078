import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import If from "components/if/If";

import "./TextArea.scss";

const TextArea = ({
  value,
  placeholder,
  name,
  onChange,
  maxLength,
  hasError = false,
  errorMessage,
}) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <div className={cx("TextAreaWrapper", { hasError: hasError })}>
      <textarea
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={handleChange}
        maxLength={maxLength}
        className="TextAreaControl"
      />
      <div className="TextAreaCommentLabel">
        <h6 className="h7 Medium">
          <If state={hasError}>{errorMessage}</If>
        </h6>
        <p>
          {document.documentElement.dir === "rtl"
            ? maxLength + "/" + value.length
            : value.length + "/" + maxLength}
        </p>
      </div>
    </div>
  );
};

TextArea.displayName = "TextArea";
TextArea.propTypes = {
  /**
   * The current value of the textarea
   */
  value: PropTypes.string,

  /**
   * Placeholder text for the textarea
   */
  placeholder: PropTypes.string,

  /**
   * The name attribute for the textarea
   */
  name: PropTypes.string,

  /**
   * Callback function to handle textarea change events
   */
  onChange: PropTypes.func,

  maxLength: PropTypes.number,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default TextArea;
