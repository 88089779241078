import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TopBar from "pages/client/menu-v2/top-bar/TopBar";
import Category from "pages/client/menu-v2/category/Category";
import MenuCategoryChips from "pages/client/menu-v2/menu-categories/MenuCategoryChips";
import EmptyState from "components/admin/empty-state/EmptyState";
import If from "components/if/If";
import useManageChipsWhenScroll from "utils/hooks/useManageChipsWhenScroll";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import ScrollTop from "components/buttons/scroll-top/ScrollTop";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import ICON_EMPTY_MENU from "assets/icons/menu/guest-empty-menu.svg";
import PromotionAndBanner, {
  ENUMS as PROMOTION_ENUMS,
} from "./promotion-and-banner/PromotionAndBanner";
import { STORE_NAMES } from "utils/constants/redux";
import { useImageVisibility } from "utils/context-api/ImageVisibilityContext";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";

import styles from "./Menu.module.scss";

const Menu = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.menu });
  const { t } = useTranslation();
  const { isVisibleAllImages } = useImageVisibility();
  const { categoriesWithoutPromotion, promotion } = useMenuHelper();
  const { isHamburgerVisible } = useGuestLayout();
  const { handleChipClick, activeCategoryId, categoryRefs } =
    useManageChipsWhenScroll({ categories: categoriesWithoutPromotion });
  const { menuCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const shouldShowEmptyState =
    categoriesWithoutPromotion.length === 0 && !promotion;
  const menuItemsWrapper = (
    <div className={styles.Wrapper}>
      {categoriesWithoutPromotion.map((category) => {
        const isBanner = category.type === menuCategoryType.banner;
        return (
          <div
            key={category.id}
            ref={(el) => (categoryRefs.current[category.id] = el)}
          >
            <If state={isBanner}>
              <PromotionAndBanner
                category={category}
                type={PROMOTION_ENUMS.types.BANNER}
              />
            </If>
            <If state={!isBanner}>
              <Category category={category} lazyImage={!isVisibleAllImages} />
            </If>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.Menu}>
      <TopBar />
      <div className={styles.Container}>
        <If state={shouldShowEmptyState}>
          <div className="MenuDisplayNoResult">
            <EmptyState
              title={t("menu.category.emptyGuestMenuTitle")}
              description={t("menu.category.emptyGuestMenuDescription")}
              icon={ICON_EMPTY_MENU}
              isAdmin={false}
            />
          </div>
        </If>
        <If state={Boolean(promotion)}>
          <PromotionAndBanner category={promotion} />
        </If>
        <If state={!shouldShowEmptyState}>
          <MenuCategoryChips
            categories={categoriesWithoutPromotion}
            activeCategoryId={activeCategoryId}
            onChipClick={handleChipClick}
          />
          {menuItemsWrapper}
        </If>
      </div>
      <If state={!isHamburgerVisible}>
        <ScrollTop />
      </If>
    </div>
  );
};

export default Menu;
