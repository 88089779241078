import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getUserInfoByToken } from "redux/actions/userAction";

const useAuthCheck = () => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUserInfoByToken())
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false))
      .finally(() => setLoading(false));
  }, [dispatch]);

  return { isAuthenticated, loading };
};

export default useAuthCheck;
