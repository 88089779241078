import { PrivacyPolicyRu } from "utils/constants/i18n/PrivacyPolicy";
import { TermsAndConditionRu } from "utils/constants/i18n/TermsAndCondition";

const TRANSLATION = {
  welcome: "добро пожаловать (Russian)",
  common: {
    settings: "Настройки",
    about: "О нас",
    home: "Главная",
    logo: "Логотип",
    shape: "Форма",
    selected: "Выбрано",
    create: "Создать",
    notFound: "Не найдено",
    applied: "Применено",
    yourLikes: "Ваши лайки",
    details: "Детали",
    published: "Опубликовано",
    unPublished: "Неопубликовано",
    general: "Общее",
    address: "Адрес",
    wifi: "Wi-Fi",
    sort: "Сортировка",
    copied: "Скопировано",
    contact: "Контакт",
    pages: "Страницы",
    kcal: "ккал",
    currency: "Валюта",
    connect: "Подключить",
    disconnect: "Отключить",
    confirmDisconnect: "Вы уверены, что хотите отключить {{name}}",
    connection: "Соединение",
    qr: "QR",
    telegram: "Телеграм",
    enable: "Включить",
    disable: "Отключить",
    zoneAndTable: "Зона и стол",
    connectToWifi: "Подключено к Wi-Fi",
    connectWifi: "Подключить Wi-Fi",

    disconnectFromWifi: "Отключено от Wi-Fi",
    name: "Имя",
    active: "Активный",
    inactive: "Неактивный",
    date: {
      date: "Дата",
      time: "Время",
      createdAt: "Создано в",
      dateCreated: "Дата создания",
      dateSelection: "Выбор даты",
      schedule: "Работает в запланированные периоды",
    },
    time: {
      hour: "час",
      minute: "минута",
      second: "секунда",
      from: "От",
      to: "До",
      since: "С начала",
      min: "мин",
      shortVersion: {
        hour: "ч",
        minute: "мин",
        second: "с",
      },
    },
    days: {
      days: "Дни",
      monday: "Понедельник",
      tuesday: "Вторник",
      wednesday: "Среда",
      thursday: "Четверг",
      friday: "Пятница",
      saturday: "Суббота",
      sunday: "Воскресенье",
      everyday: "Каждый день",
      everyWeekday: "Каждый будний день",
      weekend: "Выходные",
    },
  },
  navbarRoutes: {
    aiSearch: "ИИ Поиск",
    account: "Аккаунт",
    order: "Заказ",
    statistics: "Статистика",
    salesStatistics: "Продаж",
    orderStatistics: "Заказов",
    allOrders: "Все Заказы",
    menuStatistics: "Меню",
    allItems: "Все товары",
    feedbacks: "Отзывы",
    weeklyStatistics: "Еженедельная",
    staff: "Персонал",
    menu: "Меню",
    business: "Бизнес",
    users: "Пользователи",
    qrAndTable: "QR и стол",
    table: "Стол",
    qr: "QR",
    reservation: "Бронирование",
    devices: "Устройства",
    map: "Карта",
    orders: "Заказы",
    departments: "Отделы",
    superAdmin: "Супер админ",
    inventory: "Инвентарь",
    funZone: "Bеселья",
    payments: "Платежи",
    guests: "Гости",
    ipBlacklist: "Чёрный список IP",
    pageTitles: {
      superAdmin: "СУПЕР АДМИН",
      infra: "INFRA",
      guests: "ГОСТИ",
      weeklyStatistics: "ЕЖЕНЕДЕЛЬНАЯ СТАТИСТИКА",
      salesStatistics: "СТАТИСТИКА ПРОДАЖ",
      orderStatistics: "СТАТИСТИКА ЗАКАЗОВ",
      menuStatistics: "СТАТИСТИКА МЕНЮ",
      allOrders: "ВСЕ ЗАКАЗЫ",
      allItems: "ВСЕ товары",
      feedbacks: "ОТЗЫВЫ",
      category: "КАТЕГОРИЯ",
      settings: "НАСТРОЙКИ",
      business: "БИЗНЕС",
      users: "ПОЛЬЗОВАТЕЛИ",
      zones: "ЗОНЫ",
      tables: "СТОЛЫ",
      qrList: "СПИСОК QR",
      integrations: "Интеграции",
      payments: "ПЛАТЕЖИ",
      telegramIntegration: "Telegram - интеграция",
    },
  },
  emptyTable: {
    emptyTableDescription: "У вас пока нет зон.",
    emptyZoneDescription: "В этой зоне нет столов.",
    emptyQR: "У вас пока нет QR-кодов.",
    emptyZoneTitle: "На карте нет столов.",
  },
  language: {
    language: "Язык",
    english: "Английский",
    russian: "Русский",
    azerbaijani: "Азербайджанский",
    german: "Немецкий",
  },
  buttons: {
    revive: "Возродить",
    addItem: "Добавить товар",
    addQR: "Добавить QR",
    resetData: "Сбросить",
    back: "Назад",
    createQR: "Создать QR",
    createZone: "Создать зону",
    createOrder: "Создать заказ",
    edit: "Редактировать",
    delete: "Удалить",
    confirm: "Подтвердить",
    finish: "Завершить",
    discard: "Отменить",
    change: "Изменить",
    deny: "Отказать",
    save: "Сохранить",
    close: "Закрыть",
    saveChanges: "Сохранить изменения",
    all: "Все",
    selectAll: "Выбрать все",
    seeAll: "Показать все",
    seeLess: "Показать меньше",
    add: "Добавить",
    addNew: "Добавить новый",
    reset: "Очистить",
    resend: "Отправить повторно",
    clearFilters: "Очистить фильтры",
    pay: "Оплатить",
    clear: "Очистить все",
    apply: "Применить",
    defaultLanguage: "Язык по умолчанию",
    defaultCurrency: "Валюта по умолчанию",
    availableLanguages: "Доступные языки",
    currency: "Валюта по умолчанию",
    link: "Ссылка",
    links: "Ссылки",
    unlink: "Отвязать",
    addBasket: "Добавить в корзину",
    addToOrder: "Добавить в заказ",
    clickToAdd: "Нажмите для добавления",
    clickToAddImage: "Нажмите для добавления изображения",
    clickToUpload: "Нажмите для загрузки",
    logOut: "Выйти",
    login: "Войти",
    lockScreen: "Замок",
    goToDashboard: "Перейти на панель управления",
    rememberMe: "Запомнить меня",
    forgotPassword: "Забыли пароль?",
    payWith: "Оплатить с помощью",
    seeMenu: "Посмотреть меню",
    addOrder: "Новый заказ",
    readMore: "Читать далее",
    serve: "Обслуживать",
    confirmOrder: "Подтвердить заказ",
    backToMenu: "Вернуться к меню",
    send: "Отправить",
    tryAgain: "Попробовать снова",
    update: "Обновить",
    continue: "Продолжить",
    verify: "Подтвердить",
    applyDiscount: "Применить скидку",
    discountProducts: "Скидочные товары",
    print: "Печать",
    closeOrder: "Закрыть заказ",
    printBill: "Напечатать счет",
    fullTables: "Полные столы",
    editForModal: "Редактировать {{title}}",
    addForModal: "Добавить {{title}}",
    successfullySent: "Успешно Отправлено",
    sent: "Отправлено",
    skip: "Пропустить",
    reserve: "Резервировать",
    source: "Источник",
    target: "Цель",
    editZone: "Редактировать Зону",
    contactUs: "Свяжитесь с нами",
  },
  inputs: {
    name: "Имя",
    username: "Имя пользователя",
    description: "Описание",
    price: "Цена",
    discount: "Скидка",
    role: "Роль",
    example: "Пример",
    phoneNumber: "Номер телефона",
    pinCode: "ПИН-код",
    pinCodeConfirm: "Подтвердите PIN-код",
    firstName: "Имя",
    lastName: "Фамилия",
    email: "Электронная почта",
    password: "Пароль",
    confirmPassword: "Подтвердите пароль",
    newPassword: "Новый пароль",
    oldPassword: "Старый пароль",
    businessName: "Название бизнеса",
    city: "Город",
    zipCode: "Почтовый индекс",
    street: "Улица",
    map: "Ссылка на Google Maps",
    serviceFee: "Сервисный сбор",
    search: "Поиск",
    enterPromoCode: "Введите промокод",
    link: "Добавить ссылку",
    instagram: "Ссылка на Instagram",
    phone: "Добавить телефон",
    contactPhone: "Номер телефона",
    whatsapp: "Номер Whatsapp",
    mail: "Добавить адрес электронной почты",
    contactMail: "Адрес электронной почты",
    tableName: "Название стола",
    usernameExample: "@пример",
    chooseCurrent: "Выбрать текущий",
    seats: "Места",
    minDeposit: "Мин. депозит",
    qrName: "Имя QR",
    wifiName: "Имя Wi-Fi",
    sell: "Цена продажи",
    cost: "Стоимость",
    calories: "Калории",
    time: "Время",
    amount: "Количество",
    unit: "Единица",
    addComment: "Добавить комментарий",
    warningAmount: "Предупреждение о количестве",
    number: "Номер",
    notFixed: "Не фиксировано",
    fixed: "Фиксировано",
    maxNumber: "Макс. число",
    deposit: "Депозит",
    yourName: "Ваше имя",
    contact: "Контакт",
    reserveeName: "Имя бронирующего",
    numberOfGuest: "Количество гостей",
    date: "Дата",
    duration: "Продолжительность",
    comment: "Комментарий...",
    commentText: "Комментарий",
    fullName: "Полное имя",
    dateOfBirth: "Дата рождения",
  },
  image: {
    images: "Изображения",
    selectCover: "нажмите на изображение, чтобы установить как обложку",
    background: "Фоновое изображение",
    other: "Другие фотографии",
    addPhoto: "Добавить фото",
    addImage: "Добавить изображение",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Название модификации",
    modification: "Модификация",
    modifications: "Модификации",
    noModifications: "Пока нет добавленных модификаций.",
    noModificationOption: "Пока нет добавленных опций.",
    addModification: "Добавить модификацию",
    editModification: "Редактировать модификацию",
    selection: {
      selection: "Выбор",
      types: "Тип выбора",
      singleSelection: "Один раз",
      singleSelectionDescription: "можно выбрать только одну опцию",
      multiSelection: "Много раз",
      multiSelectionDescription: "можно выбрать несколько опций",
      option: {
        options: "Опции",
        onlyOnce: "Только один раз",
        manyTimes: "Много раз",
        default: "Выбор по умолчанию",
        addLanguage: "Добавить язык",
      },
    },
  },
  user: {
    staff: "Персонал",
    listOfEmployees: "{{count}} сотрудник",
    employee: "Сотрудник",
    addEmployee: "Добавить сотрудника",
    editEmployee: "Редактировать сотрудника",
    sendAllDetails: "Сгенерировать новый пароль и отправить пользователю",
    userDeleteError: "Вы не можете удалить учетную запись, в которую вы вошли",
    generatePassword: "Сгенерируйте новый пароль и отправьте его пользователю.",
    changeYourPassword: "Измените свой пароль",
  },
  table: {
    connected: "Подключено через QR",
    tablesLowercase: "столы",
    tables: "Столы",
    table: "Стол",
    addTable: "Добавить стол",
    editTable: "Редактировать стол",
    selectTable: "Выбрать стол",
    busy: "занятые столы",
    available: "доступные столы",
    full: "заполнено",
    noTablesVisible: "На карте в данный момент нет столов.",
    map: "Карта",
    current: "текущий",
  },
  zone: {
    zone: "Зона",
    tables: "Столы",
    zones: "Зоны",
    name: "Название зоны",
    allZones: "Все зоны",
  },
  map: {
    removeFromMap: "Удалить с карты",
    shapes: {
      rectangle: "Прямоугольник",
      circle: "Круг",
    },
    createTable: "Создать стол",
    addToMap: "Добавить на карту",
    windowAndDoor: "Окно и Дверь",
    designElements: "Элементы дизайна",
    decoration: "Декорация",
    reset: "Сбросить",
    width: "Ширина",
    height: "Высота",
  },
  sort: {
    az: "А-Я",
    cheapToExpensive: "От Дешевого к Дорогому",
    expensiveToCheap: "От Дорогого к Дешевому",
    new: "Новый",
  },
  qr: {
    qr: "QR",
    qrCode: "QR-код",
    name: "Имя QR",
    list: "Список QR",
    id: "ID QR",
    type: "Тип QR-кода",
    status: "Статус",
    unlinked: "Не связан",
    linked: "Связан",
    qrIsNotAdded: "QR-код не добавлен",
    connected: "Подключено к",
    notConnected: "Не подключено",
    scanToConnect: "или отсканируйте для подключения",
    QRLinkCopied: "QR-ссылка скопирована",
    scanCount: "Количество сканирований",
    qrScan: "Сканируйте этот QR",
    qrScanDescription:
      "* Сканируйте с мобильного телефона пользователя и убедитесь, что приложение Telegram установлено",
    types: {
      qrType: "Тип QR",
      wifi: "Wi-Fi",
      menu: "Меню",
    },
    downloadAllQr: "Скачать все QR",
    downloadQr: "Скачать QR-код",
    generateQR: "Создать QR-код",
    custom: "Настроить",
    forEachCategory: "Для каждой категории",
    params: "Параметры",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "Да, я подтверждаю свой заказ и беру на себя ответственность за оплату.",
    areYouSureYouWantToPlaceThisOrder:
      "Вы уверены, что хотите разместить этот заказ?",
    deleteModalTitle: "Вы уверены, что хотите удалить?",
    deleteMenuItemTitle: "Вы уверены, что хотите удалить эти элементы?",
    deleteModalDescription: "Это действие нельзя отменить.",
    warningModalTitleFinishOrder:
      "Вы уверены, что хотите завершить этот заказ?",
    accessDenied: "Доступ запрещен",
    permissionRequired: "Требуется разрешение",
    cannotBeRemoved: "Подтвержденные элементы не могут быть удалены",
    confirmModalTitleLogOut: "Вы уверены, что хотите выйти?",
    confirmModalDescriptionLogOut: "Вы можете войти снова в любое время",
    warningModalTitleChangeZone: "Вы уверены, что хотите изменить эту зону?",
    warningModalTitleLogout: "Вы уверены, что хотите выйти?",
    warningModalTitleNewOrder:
      "Вы создадите новый заказ в {{zone}}/{{table}}. Вы уверены?",
    warningModalDescriptionNewOrder:
      'Нажмите кнопку "да", чтобы создать новый заказ.',
    warningModalTitleUnsavedChanges:
      "Вы уверены, что хотите уйти без сохранения?",
    warningModalDescription: "Ваши изменения не будут сохранены.",
    yes: "Да",
    no: "Нет",
    close: "Закрыть",
    apply: "Применить",
    id: "ID",
    preview: "Предпросмотр",
    unConfirmedCannotBeRemoved:
      "Неподтвержденные элементы не могут быть оплачены",
    payMenuItemTitle: "Вы уверены, что хотите оплатить этот выбор?",
    guestProfile: "Профиль гостя",
    totalVisits: "Общее количество визитов",
    averageAmount: "Средняя сумма",
    topOrder: "Топ {{count}} заказов",
    createdBy: "Создано",
    paymentMethod: "Метод оплаты",
    orderId: "ID заказа",
    transactionId: "ID транзакции",
    visitHistory: "История визитов",
    emptyOrderInfo:
      "Заказ в настоящее время недоступен. Пожалуйста, отсканируйте QR-меню снова или обратитесь к официанту.",
    closeOrderConfirmation: "Как вы хотите закрыть заказ?",
  },
  menu: {
    preview: "Предпросмотр Меню",
    search: "Найдите вашу любимую еду...",
    translate: {
      ai: "Волшебный перевод",
      translating: "Перевод...",
      aiAutoTranslate: "Автоматический перевод AI",
      languageSelection: "Выбор языка",
      successfulTranslate: "Перевод успешно выполнен",
      waitingTranslate:
        "ИИ займет несколько минут, чтобы перевести ваше меню. Обратите внимание, что в это время элементы меню не могут быть отредактированы.",
      magicTranslate: "Перевести это",
      itemErrorMessage:
        "Этот элемент не существует на исходном языке. Чтобы перевести, пожалуйста, добавьте элемент на исходном языке.",
      categoryErrorMessage:
        "Эта категория не существует на исходном языке. Чтобы перевести, пожалуйста, добавьте категорию на исходном языке.",
    },
    tag: {
      tag: "Тег",
      tags: "Теги",
      name: "Название тега",
    },
    category: {
      category: "Категория",
      categories: "Категории",
      add: "Добавить категорию",
      all: "Все категории",
      name: "Название категории",
      mealCategories: "Категории блюд",
      emptyGuestMenuTitle: "Меню пусто",
      emptyGuestMenuDescription:
        "Похоже, что бизнес еще не добавил ничего в меню",
      emptyAdminCategoryDescription: "У вас пока нет категорий.",
      promotion: "Продвижение",
      banner: "Баннер",
      standardCategory: "Стандартная категория",
      specialCategory: "Специальная категория",
      selectionType: "Тип выбора",
      magicSearch: "Магический поиск",
      "360Reservation": "360 Резервация",
      ctaType: "Тип CTA",
      menuItems: "Элементы меню",
      externalLink: "Внешняя ссылка",
      internalLink: "Внутренняя ссылка",
      features: "Функции",
      urlLink: "URL-ссылка",
      discountProducts: "Скидочные товары",
      mostSoldProducts: "Самые продаваемые товары",
      mostLikedProducts: "Самые популярные товары",
      extraVisibility: "Дополнительная видимость",
      youMayAlsoLike: "Вам это тоже может понравиться",
      thumbnail: "Миниатюра",
    },

    item: {
      items: "Элементы",
      item: "Элемент",
      all: "Все элементы",
      itemLowerCase: "элемент",
      itemsLowerCase: "элементы",
      selected: "Выбранные элементы",
      deleteItem: "Удалить элемент",
      duplicateItem: "Дублировать элемент",
      duplicate: "Дублировать",
      scheduleDiscount: "Скидка по расписанию",
      emptyAdminMenuItemDescription: "У вас пока нет элементов.",
      itemDeleteError:
        "Вы не можете удалить этот продукт, так как он существует в активном заказе.",
      name: "Категория Имя",
    },
    view: {
      selection: "Вид меню по умолчанию",
      scroll: "Прокрутка",
      withImage: "Изображением",
      withoutImage: "Без изображения",
      list: "Список",
      threeD: "3D",
      poker: "Покер",
      grid: "Сетка",
    },
    filter: {
      filters: "Фильтры",
      priceRange: "Диапазон цен",
      noResult: "Нет результатов",
      noResultDesc: "Активные фильтры скрывают все элементы меню.",
    },
    settings: {
      color: "Цвет",
      radius: "Радиус скругления",
      roundness: "Округлость",
      currency: "Валюта",
      colors: {
        primary: "Основной",
        red: "Красный",
        gold: "Золотой",
        purple: "Фиолетовый",
        blue: "Синий",
      },
      curves: {
        elementCurves: "Кривые элемента",
        none: "Нет",
        small: "Маленький",
        medium: "Средний",
        large: "Большой",
      },
      changeProperties: "Изменить свойства",
      details: "Детали меню",
    },
    viewAr: "Вид AR",
  },
  menuV2: {
    selectLanguage: "Выберите язык",
  },
  business: {
    businesses: "Бизнесы",
    list: "Список бизнесов",
    staffList: "{{count}} сотрудник",
    selectTitle: "Выберите бизнес",
    selectSubTitle:
      "Выберите бизнес, в который вы хотите войти, и затем продолжайте",
    contact: "Контакт",
    contactWith: "Связаться с",
    workingHours: "Часы работы",
    needToKnow: "Необходимо знать",
    addNew: "Добавить бизнес",
    edit: "Редактировать бизнес",
    plan: "План",
    selectPlan: "Выберите план",
    creationDate: "Дата создания",
    status: "Статус",
    verified: "Подтверждено",
    unverified: "Неподтверждено",
    businessStatus: "Статус бизнеса",
    expirationDate: "Дата истечения",
    country: "Страна",
    printers: "Принтеры",
    printer: "Принтер",
    guestBusinessBlockTitle: "Этот бизнес неактивен.",
    guestBusinessBlockSubTitle: "Извините, этот бизнес больше недоступен.",
    settings: {
      goLive: "Начать трансляцию",
      chat: "Чат",
      realTimeOrder: "В реальном времени",
      menuItemCalorie: "Калории",
      menuItemTime: "Время",
      menuItemAmount: "Количество",
      reservation: "Бронирование",
      reservationMap: "Показать карту при бронировании",
      chatSound: "Звук чата",
      orderSound: "Звук заказа",
      askForBillSound: "Звук запроса счёта",
      callWaiterSound: "Звук вызова официанта",
      isAskForBillEnabled: "Звук запроса счёта",
      isCallWaiterEnabled: "Звук вызова официанта",
      menu: "Меню",
      sound: "Звук",
      general: "Общее",
      order: "Заказ",
      timeZone: "Часовой пояс",
      action: "Действия",
      reservationVRLink: "Добавить виртуальную ссылку",
      reservationVREnabled: "Виртуальный просмотр",
      reservationMapEnabled: "Просмотр карты",
      isOnlinePaymentEnabled: "Оплата",
      showInBusinessCatalogue: "Показать в Каталоге Бизнеса",
      permission: "Разрешения",
      declineOrderItemsEnabled: "Отклонить элементы заказа",
      deleteOrderEnabled: "Удалить заказ",
      deleteMenuItemsEnabled: "Удалить элементы меню",
      editMenuEnabled: "Редактировать меню",
      virtualTour: "Виртуальный Тур",
      vrLink: "Добавить виртуальную ссылку",
      vrLinkEnabled: "Виртуальный просмотр",
    },
  },
  orderItem: {
    denied: "Отказано",
    deleted: "Удалено",
    empty: "Пусто",
  },
  basket: {
    basket: "Корзина",
    favorites: "Избранное",
    basketAndFavorites: "Корзина и избранное",
    addMessage: "Добавить сообщение для ресторана",
    messagePlaceholder: "Особые пожелания, аллергии, диетические ограничения?",
    itemAdded: "{{count}} товар добавлен в корзину",
    comment: {
      addMessage: "Добавьте сообщения для ресторанов",
      addMessageDesc: "Специальные запросы, аллергии, диетические ограничения?",
    },
    emptyFavorite: "У вас пока нет избранных",
    added: "Добавлено",

    order: {
      info: "Информация о заказе",
      subtotal: "Промежуточный итог",
      total: "Итого",
      tax: "Налог",
      promoCode: "Промокод",
      totalCost: "Общая стоимость",
      orders: "Заказы",
      myOrders: "Мой заказ",
      ordersLowerCase: "заказы",
      orderLowerCase: "заказ",
      order: "Заказ",
      bill: "Счет",
      noOrder: "Нет доступного заказа",
      deletedOrderTitle: "Ваш заказ удален",
      deletedOrderDescription:
        "Извините, ваш заказ был удален нашим администратором/официантом",
      notifications: "Уведомления",
      updatesToSave: "Обновления для сохранения",
      newOrder: "Новый заказ",
      deleteOrder: "Удалить заказ",
      finishOrder: "Завершить заказ",
      done: "Готово",
      preparing: "Готовится",
      numberOfGuests: "Количество гостей",
      fullNessOfTable: "Заполненность стола",
      acceptAll: "Принять все",
    },
    emptyBasketTitle: "Ваша корзина пуста",
    emptyBasketDescription: "Похоже, вы еще ничего не добавили в корзину",
  },
  orders: {
    orders: "Заказы",
    order: "Заказ",
    guests: "Гости",
    guest: "Гость",
  },
  guests: {
    guest: "Гость",
    guests: "Гости",
    registeredGuest: "Зарегистрированные",
    unregisteredGuest: "Незарегистрированные",
    listOfGuests: "{{count}} гостей",
    allBusinesses: "Все Бизнесы",
  },

  funZone: {
    funZone: "Зона Развлечений",
    selectGame: "Выбрать игру",
    playGame: "Играть",
    who: "Кто",
    paysTheBill: "Кто оплатит счёт?",
    mindGame: "Память",
    canYouRemember: "Ты можешь вспомнить?",
    tower: "Башня",
    buildYourHighestTower: "Построй свою самую высокую башню",
    ticTackToe: {
      title: "Крестики-нолики",
      player: "Игрок",
      turnFor: "Ход для {{player}}",
      win: "Победа",
      outsmartYourOpponent: "Перехитри своего противника!",
      draw: "Ничья",
      points: "Очки",
    },
    wheelOfFortune: {
      spinnerGame: "Игра с вращением",
      who: "Кто?",
      whoWillPayTheBill: "Кто оплатит счет?",
      whoWillGoToTheMarket: "Кто пойдет на рынок?",
      whoWillBuyCigarettes: "Кто купит сигареты?",
      whoWillHaveAnotherShot: "Кто выпьет еще один раз?",
      whoWillGetDessert: "Кто получит десерт?",
      whoWillChooseTheMusic: "Кто выберет музыку?",
      whoWillSingKaraoke: "Кто будет петь?",
      start: "Начать",
      spinning: "Крутим...",
      editToPlay: "Редактировать для игры",
      addNew: "Добавить новое",
      saveAndContinue: "Сохранить и продолжить",
      typeHere: "Введите здесь",
      result: "Результат",
      youAreTheChosenOne: "Ты избранный",
    },
    memoryCardGame: {
      mindGame: "Игра разума",
      restart: "Перезапуск",
      winMessage: "Ты выиграл",
      loseMessage: "Ты проиграл",
      secondsLeft: "Осталось секунд",
      triesLeft: "Осталось попыток",
      startNewGame: "Начать новую игру",
    },
    towerGame: {
      blocks: "БЛОКИ",
      clickToPlay: "КЛИКНИТЕ, ЧТОБЫ ИГРАТЬ",
      clickToRestart: "Нажмите, чтобы перезапустить",
    },
  },

  dashboard: {
    dashboard: "Панель управления",
    sales: "Продажи",
    startTalk: "Начать разговор",
    salesAnalytics: "Общие продажи / час",
    weekStatistics: "Статистика недели",
    statistics: "Статистика",
    totalSales: "Общие продажи",
    paymentMethodAnalysis: "Анализ методов оплаты",
    byNumberOfOrders: "По количеству заказов",
    byTheAmountOfSales: "По сумме продаж",
    byTheNumberOfRatings: "По числу оценок",
    bestselling: "Бестселлеры",
    worstSelling: "Наименее продаваемые",
    selectToPay: "Нажмите, чтобы выбрать",
    processing: "Обработка",
    billReady: "Счет будет готов",
    unSelect: "Отменить выбор всех",
    denied: "Отклонено",
    deleted: "Удалено",
    showMore: "Показать больше",
    showLess: "...Показать меньше",
    bestSellingCategory: "Лучшая категория продаж",
    topPerformer: "Лучший исполнитель",
    lowPerformer: "Низкий исполнитель",
    noResultForThisPeriodMsg: "Нет результатов за этот период",
    all: "Все",
    yourTable: "Ваш стол",
    totalGuests: "Всего гостей",
    totalItems: "Всего предметов",
    weeklySaleStatistics: "Статистика недельных продаж",
    weeklyOrderStatistics: "Статистика недельных заказов",
    paid: "Оплачено",
    paymentInProgress: "Платеж в процессе",
    inProgress: "В процессе",

    legends: {
      sale: "Продажа",
      revenue: "Выручка",
      orders: "Количество заказов",
      orderItems: "Количество товаров",
      totalSale: "Общая Продажа",
      starCount: "Количество звезд",
    },
    guest: {
      guests: "Гости",
      guest: "Гость",
      me: "Я",
      review: {
        meal: "Блюдо",
        service: "Обслуживание",
        review: "Отзыв",
        sent: "Отправлено",
        feedBack: "Обратная связь",
      },
    },
    staffStatistics: {
      sales: "Продажи",
      orders: "Заказы",
      rating: "Рейтинг",
    },
    orderStatistics: {
      totalSale: "Общая продажа",
      revenue: "Выручка",
      averageBill: "Средний чек",
      orderCount: "Количество заказов",
      guestsServed: "Обслужено гостей",
      averageTime: "Среднее время",
      from: "с вчера",
      orders: "Заказы",
      sale: "Продажа",
      in: "В",
      out: "Из",
      totalPOSTerminalSales: "Общие продажи через POS-терминал",
      totalOnlineSales: "Общие онлайн-продажи",
      totalCashSales: "Общие наличные продажи",
    },
    feedbackStatistic: {
      service: "Отзывы о сервисе",
      meal: "Отзывы о еде",
      feedBackTitle: "Как вы себя чувствуете?",
      feedBackDesc:
        "Ваше мнение важно для того, чтобы мы могли лучше понять ваши потребности и адаптировать наш сервис соответственно.",
      rating: {
        horrible: "Ужасно",
        bad: "Плохо",
        meh: "Так себе",
        good: "Хорошо",
        awesome: "Потрясающе",
      },
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Персонал",
        dates: "Даты",
        item: "Элемент",
        subTotal: "Подитог",
        discount: "Скидка",
        total: "Итого",
        zone: "Зона",
        table: "Стол",
        service: "Сервисный",
        fee: "Сбор",
        orderDetails: "Детали заказа",
        receipt: "Чек",
      },
      menuStatisticsTable: {
        menuItem: "Элемент меню",
        category: "Категория",
        cost: "Себестоимость",
        revenue: "Выручка",
        total: "Итого",
        order: "Заказ",
        count: "Количество",
        price: "Цена",
        item: "Элемент",
      },
      businessGuestsTable: {
        name: "Имя",
        gender: "Пол",
        dateOfBirth: "Дата рождения",
        numberOfVisit: "Количество визитов",
        guestCount: "Количество гостей",
        pageSize: "Размер страницы",
      },
    },
    pagination: {
      prev: "Назад",
      next: "Далее",
    },
    prevNext: {
      previous: "Предыдущий",
      next: "Следующий",
    },
    placeholder: {
      zone: "Зона",
      orderId: "ID заказа",
      staff: "Персонал",
      itemName: "Название товара",
      actionFilter: "Фильтр действий",
      zoneFilter: "Фильтр зон",
      categories: "Категории",
    },
  },
  login: {
    or: "или",
    facebook: "Войти через Facebook",
    google: "Войти через Google",
    pin: "пин-код",
    alreadyHaveAnAccount: "Уже есть аккаунт?",
    signIn: {
      title: "Вход",
      subTitle: "Нет аккаунта?",
      details: "Данные для входа",
      method: "Способ входа",
    },
    signUp: {
      termsErrorMessage:
        "*Вы должны согласиться с условиями и положениями, чтобы продолжить.",
      title: "Регистрация",
      subTitleForUserExist:
        "У вас уже есть аккаунт. Пожалуйста, введите ваш существующий пароль.",
      subTitleForUserNotExist:
        "Пожалуйста, введите пароль, отправленный на: {{emailOrPhone}}",
      alreadyHaveAccount: "Уже есть аккаунт?",
      passwordSentTo: "Пароль отправлен на",
      setPinSubTitle: "Установите PIN-код для удобного входа",
      agree: "Я принимаю следующее:",
      terms: "Условия",
      privacy: "Политика конфиденциальности",
      enterPassword: "Введите ваш пароль",
      setPinSubtitle: "Установите PIN-код для удобного входа",
    },
    forgotPassword: {
      title: "Забыли пароль?",
      subTitle: "Введите свой {{method}}, и мы отправим вам пароль еще раз.",
      sendPassword: "Отправить пароль",
      email: "электронная почта",
      phoneNumber: "номер телефона",
    },
    resetPassword: {
      title: "Изменить пароль",
      subTitle: "С возвращением! Мы скучали по вам!",
    },
    resendPassword: {
      title: "Отправить пароль повторно",
      subTitleForUserExist: "Забыли ваш существующий пароль?",
      subTitleForUserNotExist: "Не получили пароль?",
      buttonTextForUserExist: "Отправить новый пароль",
      buttonTextForUserNotExist: "Отправить повторно",
    },
  },
  payment: {
    receipt: "Чек",
    payedBy: "Оплачено кем",
    onlinePayment: "Онлайн Платеж",
    paymentMessage: "Оплата с {{card}} картой прошла успешно",
    paymentFailMessage: "Оплата с картой {{card}} не удалась",
    payment: "Оплата",
    method: "Способ оплаты",
    pageTitle: "Как вы хотите оплатить?",
    pageSubtitle: "Выберите способ оплаты",
    cash: "Наличные",
    POSTerminal: "POS-Терминал",
    ready: "Готово через минуту!",
    confirmPOSTerminal:
      "Запрос подтвержден. POS-Терминал будет принесен официантом в ближайшее время",
    confirmCash:
      "Запрос подтвержден. Ваш счет будет принесен официантом в ближайшее время",
    totalCostFor: "Общая стоимость для",
    printsAndBill: "Печать и счет",
    paymentConfirmedTitle: "Ваш заказ завершен. Спасибо, что выбрали нас.",
    paymentConfirmedDescription:
      "Нам было приятно вас приветствовать! До новых встреч! 👋 Приятного дня! 😍",
    businessPaymentsTitle: {
      orderId: "Номер заказа",
      paidPrice: "Сумма платежа",
      currency: "Валюта",
      paymentStatus: "Статус оплаты",
      lastFourDigits: "Последние 4 цифры",
      cardClassification: "Классификация карты",
      cardHolderName: "Владелец карты",
    },
  },
  waiter: {
    waiter: "Официант",
    waiterLowercase: "официант",
    call: "Вызвать официанта",
    bill: "Запросить счет",
    quickActions: "Быстрые действия",
    actions: "Действия",
  },
  mascot: {
    about: "О {{businessName}}",
    dashboard: {
      support: "Поддержка и быстрые клавиши",
      eatIn: "Пообедать в",
      eatInHall: "Зал для обеда",
      reserveTable: "Зарезервировать столик",
      letUs: "Дайте нам...",
      learnMore: "Узнать больше",
      fixProblemText: "Вы столкнулись с проблемами в приложении?",
      fixProblemButton: "Нажмите здесь, чтобы исправить",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "Выберите стол для создания заказа",
    selectWaiterToCreateOrder: "Выберите официанта для создания заказа",
    lastLanguage: "Вы не можете удалить последний язык",
    orderAssignee: "Вы должны выбрать исполнителя",
    required: {
      password: "Требуется пароль",
      confirmPassword: "Требуется подтверждение пароля",
      customMessage: "{{name}} требуется",
      comment: "*Комментарий обязателен",
    },
    image: "Ошибка загрузки изображения",
    addUnit: "Добавить единицу",
    confirmOrders:
      "У вас есть незавершенная работа. Пожалуйста, сохраните ее перед продолжением",
    input: {
      maxCharacter: "*Пожалуйста, введите не более {{max}} символов",
      minCharacter: "*Пожалуйста, введите не менее {{min}} символов",
      maxNumber: "*Пожалуйста, введите число меньше чем {{max}}",
      minNumber: "*Пожалуйста, введите число больше 0",
      invalid: "*Неверный {{name}}",
      required: "*{{name}} требуется",
      generalRequired: "*Это поле обязательно",
      qrAndTable: {
        maxSeat: "Количество мест должно быть целым числом",
      },
      menu: {
        fixedDiscount: "*Скидка не может быть больше цены продажи",
        unFixedDiscount: "*Скидка не может быть больше 100%",
        requiredUnit: "*Пожалуйста, выберите единицу для данного количества",
      },
      menuSettings: {
        requiredDefaultLanguage: "*Пожалуйста, выберите язык по умолчанию",
        requiredAvailableLanguages: "*Пожалуйста, выберите хотя бы один язык",
      },
      pinCode: {
        maxDigits: "*Пин-код должен быть менее 6 цифр",
        alreadyExists: "*Этот пин-код уже существует",
        notNumeric: "*Пин-код должен содержать только цифры",
        skipOrSetNow: "*Установите пин-код сейчас или пропустите этот шаг",
        notMatch: "*Пин-коды не совпадают",
        incorrect: "*Неверный пин-код",
        TryAgainLater: "Вы уже превысили лимит, попробуйте позже снова.",
      },
      email: {
        alreadyExists: "*Этот адрес электронной почты уже существует",
        incorrect:
          "*Пожалуйста, введите действительный адрес электронной почты",
        notExists: "*Этот адрес электронной почты не существует",
        TryAgainLater: "*Вы уже превысили лимит, попробуйте позже снова.",
      },
      password: {
        incorrect: "*Неверный пароль",
        notNumeric: "*Пароль должен содержать только цифры",
        incorrectLength: "*Пароль должен состоять из 6 цифр",
      },
      reservation: {
        maxGuestCount: "Количество гостей должно быть целым числом",
      },

      phoneNumber: {
        incorrectLength: "*Номер телефона должен состоять из 12 цифр",
        notNumeric: "*Номер телефона должен содержать только цифры",
        alreadyExists: "*Этот номер телефона уже существует",
        incorrect: "*Пожалуйста, введите действительный номер телефона",
        notExists: "*Этот номер телефона не существует",
        TryAgainLater: "*Вы уже превысили лимит, попробуйте позже снова.",
      },
      business: {
        serviceFeeMax: "*Сервисный сбор должен быть меньше 100%",
        serviceFeeMin: "*Сервисный сбор должен быть больше 0%",
        url: "*Пожалуйста, введите действительный URL",
      },
      contact: {
        instagram: "*Пожалуйста, введите действительный адрес Instagram",
        // phone: "*Please enter a valid phone number",
        // whatsapp: "*Please enter a valid whatsapp number",
        // gmail: "*Please enter a valid gmail address",
      },
      otpCode: {
        OtpIsWrong: "*Otp неверен",
        OtpIsNotVerified: "Otp не подтвержден",
        sendOtp: "Код отправлен на:",
      },
      oldPassword: {
        OldPasswordIsWrong: "*Текущий пароль неверный",
      },
    },
  },
  toastMessages: {
    success: {
      login: "Вход выполнен успешно!",
      updateBusinessProfile: "Профиль бизнеса успешно обновлен",
      updateTag: "Тег успешно обновлен",
      createTag: "Тег успешно создан",
      deleteTag: "Тег успешно удален",
      createReservation: "Бронь успешно создана",
      deleteReservation: "Бронь успешно удалена",
      updateReservation: "Бронь успешно обновлена",
      updateSpecialCategory: "Специальная категория успешно обновлена",
      updateCategory: "Категория успешно обновлена",
      createCategory: "Категория успешно создана",
      deleteCategory: "Категория успешно удалена",
      updateMenuItem: "Элемент меню успешно обновлен",
      updateMenu: "Меню успешно обновлено",
      createMenuItem: "Элемент меню успешно создан",
      deleteMenuItem: "Элемент меню успешно удален",
      duplicateMenuItem: "Элемент меню успешно скопирован",
      updateZone: "Зона успешно обновлена",
      createZone: "Зона успешно создана",
      deleteZone: "Зона успешно удалена",
      updateTable: "Стол успешно обновлен",
      updateMenuSettings: "Настройки меню успешно обновлены",
      createTable: "Стол успешно создан",
      deleteTable: "Стол успешно удален",
      updateUser: "Пользователь успешно обновлен",
      createUser: "Пользователь успешно создан",
      deleteUser: "Пользователь успешно удален",
      updateQR: "QR-код успешно обновлен",
      createQR: "QR-код успешно создан",
      deleteQR: "QR-код успешно удален",
      updateOrder: "Заказ успешно обновлен",
      createOrder: "Заказ успешно создан",
      deleteOrder: "Заказ успешно удален",
      finishedOrder: "Заказ успешно завершен",
      updateInventoryCategory: "Категория инвентаря успешно обновлена",
      createInventoryCategory: "Категория инвентаря успешно создана",
      deleteInventoryCategory: "Категория инвентаря успешно удалена",
      updateInventoryItem: "Элемент инвентаря успешно обновлен",
      createInventoryItem: "Элемент инвентаря успешно создан",
      deleteInventoryItem: "Элемент инвентаря успешно удален",
      updateBusiness: "Бизнес успешно обновлен",
      createBusiness: "Бизнес успешно создан",
      deleteBusiness: "Бизнес успешно удален",
      updateLanguage: "Язык успешно изменен",
      resetPassword: "Пароль успешно изменен",
    },
    error: {
      common: "Что-то пошло не так! Пожалуйста, попробуйте снова",
      zones: "Вы не можете удалить эту зону, потому что в ней есть столы",
      unselectedTable: "Вы должны выбрать стол",
      unselectedZone: "Вы должны выбрать зону",
      deleteCategory:
        "Вы не можете удалить эту категорию, так как в ней есть элемент.",
    },
    warning: {
      socials: "В настоящее время ведутся работы, спасибо за ваше понимание.",
    },
  },
  reservation: {
    reserve: "Зарезервировать",
    reservationTime: "Время бронирования",
    reservationInfo: "Информация о бронировании",
    contactInfo: "Контактная информация",
    enterName: "Введите имя",
    enterPhoneNumber: "Номер телефона",
    bookingList: "Список бронирований",
    booked: "Забронировано",
    pending: "В ожидании",
    createReservation: "Создать бронирование",
    editReservation: "Редактировать бронирование",
    waiting: "Ожидание",
    today: "Сегодня",
    guestNumber: "Количество гостей",
    waitingTime: "Время запроса",
    startDate: "Дата начала",
    ReservationDuration: "Длительность бронирования",
    comment: "Комментарий",
    numberOfGuest: "Количество гостей",
    startTime: "Время начала",
    duration: "Продолжительность",
    confirmed: "Подтверждено",
    denied: "Отклонено",
    delete: "Удалить",
    update: "Обновить",
    searchRestaurant: "Поиск ресторана",
    tableNotFound: "Не выбрано",
    showMore: "Показать больше",
    showLess: "Показать меньше",
    reservations: "Бронирования",
    open: "Открыто",
    seeMenu: "Посмотреть меню",
    reservation: "Бронирование",
    contact: "Контакт",
    place: "Место",
    reserveeName: "Имя клиента",
    date: "Дата",
    time: "Время",
    reservationStatus: "Статус бронирования",
    reservationDurationHelpText:
      "Примечание: Если вы не выберете продолжительность, она будет автоматически установлена на 1 час.",
    justNow: "Только что",
    oneDay: "1 день",
    day: "день",
    hour: "ч",
    minute: "мин",
    selectedTable: "Выбранный стол",
    confirmation: "Подтвердить и продолжить",
    chooseRestaurant: "Выберите ресторан",
    setDate: "Когда вы планируете посетить?",
    chooseTable: "У вас есть предпочтительный столик?",
    addContact: "Кто посещает?",
    vrLinkCopied: "VR ссылка скопирована",

    statuses: {
      PENDING: "В ожидании",
      CONFIRMED: "Подтверждено",
      DENIED: "Отменено",
      DELETED: "Удалено",
    },
    vr: {
      browserDoesNotSupport: "Ваш браузер не поддерживает видео-тег.",
      vrTourReservation: "Бронирование VR-тура",
      explore: "Изучите и выберите своё место!",
      vrView: "VR просмотр",
      explorevr: "Исследуйте наше место",
    },
    map: {
      chooseTable: "Выберите стол",
      mapReservation: "Зарезервировать на карте",
      chooseLocation: "Выберите местоположение стола!",
      tables: "Столы",
    },
    sentSuccessfully: "Запрос успешно отправлен",
    successMessage:
      "Обновления будут отправлены на ваш номер, и вы можете проверить свой статус на странице бронирования",
  },
  socials: {
    instagram: "Instagram",
    email: "Электронная почта",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "Январь",
    february: "Февраль",
    march: "Март",
    april: "Апрель",
    may: "Май",
    june: "Июнь",
    july: "Июль",
    august: "Август",
    september: "Сентябрь",
    october: "Октябрь",
    november: "Ноябрь",
    december: "Декабрь",
  },
  weekdays: {
    monday: "Понедельник",
    tuesday: "Вторник",
    wednesday: "Среда",
    thursday: "Четверг",
    friday: "Пятница",
    saturday: "Суббота",
    sunday: "Воскресенье",
  },
  periods: {
    hourly: "Почасовая",
    daily: "Ежедневная",
    weekly: "Еженедельная",
    monthly: "Ежемесячная",
  },
  chat: {
    typeHere: "Печатайте здесь...",
    chat: "Чат",
    allMessages: "Все сообщения",
    unreadMessages: "Непрочитанные сообщения",
    selectTopic: "Пожалуйста, выберите тему.",
    you: "Вы",
    noMessages:
      "Сообщений пока нет. Отправьте первое сообщение, чтобы начать беседу!",
  },
  kitchen: {
    stageNew: "Новый",
    stageProgress: "Готовится",
    stageDone: "Готово",
  },
  inventory: {
    productList: "Список продуктов",
    product: "продукт",
    stock: "Склад",
    warningAmountInfo:
      "Выберите минимальное количество для уведомления, когда оно почти закончится",
    productname: "Имя продукта",
  },
  emptyStates: {
    loading: "Загрузка...",

    noPrinters: "Принтеры еще не добавлены",
    noOption: "Нет опции",
    noOrder: "Пока нет заказов",
    noItem: "Пока нет элементов",
    noLanguage: "Нет языков для добавления",
    noTableData: "На этой странице нет данных",
    noOrderedCategory: "В этой категории нет заказанных элементов",
    noChat: "У вас нет чатов из-за отсутствия заказов",
    noUnread: "Нет непрочитанных сообщений.",
    noGuests: "Нет гостей",
    noSearchResults: "Нет результатов поиска",
    noOrders: "Нет заказов",
    noPendingReservations: "Нет ожидающих броней.",
    noReservationDescription: "У вас пока нет никаких бронирований",
    noReservations: "Нет бронирований",
    notOpenForReservations: "Этот ресторан не принимает бронирования.",
    noBusiness: "Нет бизнеса",
    noBusinessContent: "Нет дополнительной информации о бизнесе.",
    noBusinessDescription:
      "К сожалению, все бронирования в ресторанах закрыты.",
  },
  info: {
    itemAmount:
      "Число указывает на количество элемента, выраженное в таких единицах, как килограммы (кг), граммы (г), литры (л) или миллилитры (мл).",
    itemModificationOptionMaxNumberInfo:
      "Максимальное количество вариантов модификации относится к максимальному количеству таких вариантов, которые могут быть применены.",
    general: "Общая информация",
    detail: "Детальная информация",
  },
  stepper: {
    signupMethod: "Метод регистрации",
    otpVerification: "Подтверждение OTP",
    userDetail: "Данные пользователя",
    passwordVerification: "Проверка пароля",
    setPinCode: "PIN-код",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Условия использования",
    privacyPolicy: "Политика конфиденциальности",
    allRightReserved: "Все права защищены.",
    copyRight: "© {{year}} {{value}}.",
    joinUs: "Присоединяйтесь к нам {{emoji}}",
    madeWith: "Сделано с {{emoji}}",
    madeBy: "Создано <strong>iLoyal</strong>",
    scanQR: "Отсканируйте QR для нового заказа",
    name: "Покажите друзьям, что это вы!",
    profilePicture: " Ваша фотография профиля",
  },
  optionTypes: {
    None: "Нет",
  },
  businesses: {
    allBusinesses: "Все предприятия",
    specialOffers: "Специальные предложения",
    mostPopular: "Самые популярные",
    coffeeShop: "Кофейня",
    nationalCuisine: "Национальная кухня",
    nationalCuisineExtended: "Национальная кухняяяяяяяяя",
    seaside: "Побережье",
    information: "Информация",
    search: "Поиск...",
    seeAll: "Посмотреть все",
    seeLess: "Показать меньше",
  },
  policy: {
    TermsOfService: "Условия обслуживания (Лицензионное соглашение)",
    termsDataInfo:
      "Продолжая, вы соглашаетесь с <strong>Условиями и Политикой конфиденциальности.</strong>",

    joinUsTermsData: {
      introduction: {
        title: "1. Введение",
        content: {
          point1:
            "Используя наши услуги, вы соглашаетесь с этими Условиями обслуживания и нашей Политикой конфиденциальности. Пожалуйста, прочитайте их внимательно.",
        },
      },
      userConsent: {
        title: "2. Согласие пользователя",
        content: {
          point1:
            "Получая доступ к нашим услугам или используя их, вы даете согласие на сбор, использование и передачу вашей информации, как описано в этих Условиях обслуживания и Политике конфиденциальности. Если вы не согласны с этими условиями, пожалуйста, не используйте наши услуги.",
        },
      },
      dataCollection: {
        title: "3. Сбор данных",
        content: {
          point1:
            "Мы собираем информацию, которую вы предоставляете непосредственно нам, например, когда вы создаете аккаунт, совершаете покупку или обращаетесь к нам за поддержкой. Мы также можем автоматически собирать информацию, такую как ваш IP-адрес, тип браузера и данные о его использовании.",
        },
      },
      dataUse: {
        title: "4. Использование данных",
        content: {
          point1:
            "Ваши данные используются для предоставления, поддержки и улучшения наших услуг. Это включает в себя обработку транзакций, отправку сообщений и персонализацию вашего опыта.",
        },
      },
      dataProtection: {
        title: "5. Защита данных",
        content: {
          point1:
            "Мы привержены защите ваших данных. Мы применяем различные меры безопасности для защиты вашей информации от несанкционированного доступа, изменения, раскрытия или уничтожения.",
        },
      },
      dataSharing: {
        title: "6. Передача данных",
        content: {
          point1:
            "Мы не продаем ваши личные данные. Мы можем делиться информацией с доверенными третьими сторонами, которые помогают нам в управлении нашим веб-сайтом, ведении бизнеса или предоставлении услуг вам, при условии, что эти стороны согласятся сохранять информацию в тайне.",
        },
      },
      userRights: {
        title: "7. Права пользователя",
        content: {
          point1:
            "Вы имеете право на доступ, исправление или удаление вашей личной информации. Вы также можете отказаться от определенного использования и раскрытия ваших данных.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "Используя наши услуги, вы подтверждаете, что прочитали и поняли эти условия и соглашаетесь с ними.",
        },
      },
    },

    signUpTermsData: {
      agreement: {
        title: "1. Условия соглашения",
        content: {
          point1:
            "1.1 Лицензиар должен предоставить лицензию на программное обеспечение на условиях данного соглашения, и клиент должен оплатить эту лицензию.",
          point2:
            "1.2 Ежемесячная плата за лицензию (подписка) определяется в зависимости от множества функций, предоставляемых iLoyal в соответствии с потребностями клиента. Изменения в плате за лицензию (подписку) могут быть внесены только на основе взаимного соглашения между клиентом и iLoyal. Ежемесячный срок лицензии (подписки) считается начавшимся с даты подписания соглашения.",
        },
      },
      license: {
        title: "2. Условия лицензии",
        content: {
          point1:
            "2.1 Лицензиар предоставляет следующие права на условиях простой лицензии:",
          point2:
            "2.1.1 Обеспечить использование программного обеспечения и определить его работу для предусмотренной цели.",
          point3: "2.2 Следующее ЗАПРЕЩЕНО для лицензии:",
          point4:
            "2.2.1 Извлечение кода программного обеспечения пользователем.",
          point5:
            "2.2.2 Воспроизведение пользователем, т.е. создание одной или нескольких копий программного обеспечения.",
          point6: "2.2.3 Перепродажа программного обеспечения и его копий.",
          point7: "2.2.4 Обнародование программного обеспечения.",
        },
      },
      payment: {
        title: "3. Оплата лицензии",
        content: {
          point1:
            "3.1 Клиент соглашается оплатить сумму, указанную в счете-фактуре, за лицензию(и), указанные в данном соглашении.",
          point2:
            "3.2 Цены на лицензии указаны на официальном сайте лицензиара.",
          point3:
            "3.3 В случае расторжения соглашения между сторонами плата за лицензию не подлежит обсуждению и возврату.",
          point4:
            "3.4 После оплаты лицензионного сбора в соответствии со счетом-фактурой, вы считаетесь согласившимся с условиями текущего Лицензионного соглашения, и это соглашение считается заключенным между вами и лицензиаром.",
          point5:
            "3.5 Лицензиар оставляет за собой право в одностороннем порядке изменить это соглашение.",
          point6:
            "3.6 После поступления соответствующих сумм на банковский счет лицензиара все платежные обязательства клиента считаются выполненными.",
        },
      },
      dataProtection: {
        title: "4. Защита данных",
        content: {
          point1: "4.1 Вся информация о клиенте защищена лицензиаром.",
          point2:
            "4.2 Данные клиента, хранящиеся в наших облачных учетных записях, не передаются третьим лицам.",
        },
      },
    },
  },
  auth: {
    createAnAccount: "Создать аккаунт",
    register: "Зарегистрироваться",

    verifyOtp: "Подтвердить OTP",
    profile: "Профиль",
    signUp: "Зарегистрироваться",
    otpSentTo: "OTP отправлен на",
    otpCode: "Код OTP",
    letKnow: "Давайте узнаем вас",
    gender: "Пол",
    saveAndFinish: "Сохранить и завершить",
    message: {
      first: "Присоединяйся!",
      second: "Комфорт ждет!",
      third: "Лови выгоду!",
      fourth: "Береги время!",
    },
    genders: {
      male: "Мужской",
      female: "Женский",
      other: "Другой",
    },
    loginYourAccount: "Войти в ваш аккаунт",
    forgotPassword: "Забыли пароль?",
    forgotPasswordTitle: "Забыли пароль",
    info: "Информация",
    youAlreadyHaveAccount:
      "У вас уже есть зарегистрированная учетная запись с ",
    forgotPasswordDesc:
      "введите ваш {{method}}, и мы отправим вам код подтверждения OTP",
    setNewPassword: "Установить новый пароль",
    setNewPasswordDesc: "введите ваш новый пароль",
    signUpWith: "или зарегистрируйтесь с",
    withoutSignUp: "Без регистрации",
    clickButtonToLogin: "Нажмите эту кнопку, чтобы войти",
    loginAccount: "Войдите в свой аккаунт",
    email: "электронная почта",
    phoneNumber: "номер телефона",
    resendingIsAvailable: "Повторная отправка доступна",
    resendingCodeWillBeAvailableIn:
      "Код будет доступен для повторной отправки через ",
    createGuestAccountDesc:
      "Регистрируясь в iLoyal, вы получаете возможность пользоваться скидками и дополнительными функциями!",
  },
  myProfile: {
    title: "Аккаунт",
    editProfile: "Редактировать профиль",
    orderHistory: "История заказов",
    favorites: "Избранное",
    chat: "Чат",
    gameHistory: "История игр",
    helpCenter: "Центр помощи",
    settings: "Настройки",
    resetPassword: {
      resetPassword: "Сбросить пароль",
      setNewPassword: "Установить новый пароль",
      enterCurrentPassword:
        "Чтобы установить новый пароль, сначала введите текущий пароль.",
      currentPassword: "Текущий пароль *",
      newPassword: "Новый пароль *",
    },
  },

  record: {
    talkToMe: "Поговори со мной",
    magicOrder: "Магический заказ",
    magicSearch: "Магический поиск",
    start: "Начать",
    stop: "Остановить",
    magicOffer: "Магическое предложение",
    results: "Результаты",
    addedToBasket: "Добавлено в корзину",
    youCanUseMicrophone: "Вы можете использовать микрофон",
    magicOrderError:
      "Ваш запрос не связан с меню. Пожалуйста, предоставьте более конкретный запрос, связанный с блюдами или пунктами меню.",
    introductionLoya: "Привет, я Лоя.",
    askMeAnything: "Спроси меня о чем угодно!",
    askLoya: "Спросите Лоя",
    loyaIsTyping: "Лоя печатает",
  },
  recommend: {
    recommendations: "Рекомендации",
    fitMyBudget: "Блюда по бюджету",
    tastiestDishes: "Дешево и вкусно.",
    delicious: "Самые вкусные блюда.",
    smtQuick: "Что-то быстрое.",
  },
  privacyPolicy: PrivacyPolicyRu,
  termsAndCondition: TermsAndConditionRu,
  devices: {
    addPrinter: "Добавить принтер",
    testPrinter: "Тестировать принтер",
    deletePrinter: "Удалить принтер",
    connectedDevices: "Подключенные устройства",
    availableDevices: "Доступные устройства",
    notFound: "Не найдено!",
    printingBill: "Печать выписки по счету, пожалуйста, подождите..",
  },
  printer: {
    unknownBusiness: "Неизвестный бизнес",
    awaitingApproval: "ОЖИДАЕТСЯ УТВЕРЖДЕНИЕ ЗАКАЗА",
    orderNumber: "Номер заказа",
    date: "Дата",
    table: "Стол",
    waiter: "Официант",
    unknown: "Неизвестно",
    noWaiterAssigned: "Официант не назначен",
    orderNote: "Примечание к заказу",
    product: "Продукт",
    quantity: "Количество",
    price: "Цена",
    total: "Итого",
    unknownProduct: "Неизвестный продукт",
    unknownOption: "Неизвестный вариант",
    unknownChoice: "Неизвестный выбор",
    currency: "RUB",
    totalAmount: "Общая сумма",
    bonAppetit: "Приятного аппетита..",
    hopeToSeeYouAgain: "Надеемся увидеть вас снова :)",
    companyName: "iLoyal RU",
    advancedReceiptSystems: "Продвинутые системы квитанций",
    printError: "Ошибка при отправке на принтер",
    printSuccess: "Печать успешно завершена",
  },
  bulkSms: {
    guestSelected: "Гость выбран",
    everyoneSelected: "Все выбраны",
    giveDiscount: "Предоставить скидку",
    sendSms: "Отправить SMS",
    composeYourMessage: "Составьте ваше сообщение",
    typeHere: "Введите здесь...",
    closeModal: "Вы уверены, что хотите закрыть?",
    yourMessageWillBeDeleted: "Ваше сообщение будет удалено",
    sendSmsModal: "Вы уверены, что хотите отправить SMS?",
    willBeSent: "Это сообщение будет отправлено {{numberOfPeople}} людям",
    successfullySent: "Успешно отправлено",
    successfullySentToPeople:
      "Сообщение успешно отправлено {{numberOfPeople}} людям",
    operationFailed: "Операция не удалась",
    operationFailedPeople:
      "Не удалось отправить сообщение {{numberOfPeople}} людям",
    no: "Нет",
    yes: "Да",
    close: "Закрыть",
    messageIsRequired: "Сообщение обязательно",
    sentToEveryone: "Сообщение будет отправлено всем",
    sentToEveryoneSuccess: "Сообщение отправлено всем",
    sentToEveryoneFail: "Сообщение не удалось отправить никому",
  },
  typingEffect: {
    tableStartTopic: "Новая тема!",
    talkToWaiter: "Поговори с официантом.",
    shareOpinion: "Поделись мнением!",
    questionsHere: "Задай вопросы!",
    chatWithOtherTables: "Собщайся с другими столами",
  },
  gameMessages: {
    findCards: "Найди карты!",
    tastyVictory: "Вкусная победа!",
    riseBeforeMeal: "Поднимись до еды!",
    playAndGetHungry: "Играй и голодай!",
    winBeforeMeal: "Побеждай до еды!",
  },
};

export default TRANSLATION;
