import React, { useContext, useRef } from "react";
import cx from "classnames";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReactComponent as Cancel } from "assets/icons/pos/x-close.svg";
import { initialState, setUserWithPin } from "redux/slices/userStore";
import { setIsAdminDashboardPinLocked } from "redux/slices/appStore";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { useAdminAuth } from "utils/context-api/AdminAuthContext";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { AdminWebsocketContext } from "utils/context-api/AdminWebsocketContext";
import { generateInitial } from "utils/helpers";
import useCheckNetwork from "utils/hooks/useCheckNetwork";
import BackButton from "components/pos/sidebar-components/back-button/BackButton";
import SelectButton from "components/pos/sidebar-components/select-button/SelectButton";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import Profile, {
  ENUMS as ENUMS_PROFILE,
} from "components/pos/profile/Profile";
import Button, {
  ENUMS_TYPE as BUTTON_ENUMS,
} from "components/pos/button/Button";
import Avatar, { ENUMS as AVATAR_ENUMS } from "components/pos/avatar/Avatar";
import AVATAR_PLACEHOLDER from "assets/icons/pos/Ellipse 1.png";
import { ReactComponent as LockIcon } from "assets/icons/pos/lock-01-white.svg";
import { ReactComponent as WifiIcon } from "assets/icons/pos/wifi.svg";
import { ReactComponent as Socket } from "assets/icons/pos/switch-horizontal-01.svg";
import If from "components/if/If";
import { useAdminLayout } from "pages/admin/admin-layout/AdminLayout";
import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import { useAdminRouteController } from "utils/context-api/AdminRouteControllerContext";

import styles from "./AdminSidebar.module.scss";

const AdminSidebar = () => {
  const { isOpenSidebar, closeSidebar } = useAdminLayout();
  const { isScreenSize } = useWindowSize();

  const isMobile = isScreenSize(BREAKPOINT_NAMES.mobile);

  const navigate = useNavigate();
  const { pathname: currentPathname } = useLocation();
  const networkStatus = useCheckNetwork();
  const { isConnected } = useContext(AdminWebsocketContext);
  const { subRoutesByCurrentPath } = useAdminRouteController();
  const { user, isAdmin } = useAdminAuth();
  const { firstName, lastName } = user;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const hasUnSavedChanges = Boolean(
    searchParams.get(QUERY_PARAMS.unsavedChanges)
  );
  const memorizedCallback = useRef(() => {});

  const [
    openSlideConfirmChanges,
    setOpenSlideConfirmChanges,
    mainElementRefConfirmChanges,
  ] = useOutsideClick();

  const checkUnsavedChanges = (callback) => {
    if (hasUnSavedChanges) {
      setOpenSlideConfirmChanges(true);
      memorizedCallback.current = callback;
    } else {
      callback();
    }
  };

  const goHomePageHandler = () => {
    checkUnsavedChanges(() => {
      isMobile && closeSidebar();
      const navigatePath = `${ROUTE_NAME.admin}${ROUTE_NAME.adminHome}`;
      navigate(navigatePath);
    });
  };

  const handleOnLockScreen = () => {
    checkUnsavedChanges(() => {
      isMobile && closeSidebar();
      dispatch(setUserWithPin(initialState.userWithPin));
      dispatch(setIsAdminDashboardPinLocked(true));
      navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`, {
        replace: true,
      });
    });
  };

  const handleOnRouteClick = (path) => {
    const navigatePath = `${ROUTE_NAME.admin}${path}`;
    if (navigatePath === currentPathname) {
      return;
    }
    checkUnsavedChanges(() => {
      isMobile && closeSidebar();
      navigate(navigatePath);
    });
  };

  const handleOnChangesConfirm = () => {
    memorizedCallback.current();
    setOpenSlideConfirmChanges(false);
  };

  const handleOnChangesCancel = () => {
    memorizedCallback.current = () => {};
    setOpenSlideConfirmChanges(false);
  };

  const initialName = generateInitial([firstName, lastName]);

  return (
    <div className={cx(styles.Root, { [styles.isOpen]: isOpenSidebar })}>
      <div className={styles.Container}>
        <div className={styles.Top}>
          <div className={styles.Cancel} onClick={closeSidebar}>
            <h6 className={cx("Subtitle2-Pos", "SemiBold")}>
              {t("navbarRoutes.menu")}
            </h6>
            <Cancel />
          </div>
          <Profile
            src={AVATAR_PLACEHOLDER}
            title={initialName}
            initialText={initialName}
            size={ENUMS_PROFILE.types.PROFILE48}
          />
        </div>
        <div className={cx(styles.Nav, { [styles.hasLock]: isAdmin })}>
          <div className={styles.Back}>
            <BackButton onClick={goHomePageHandler} />
          </div>
          <div className={styles.Routes}>
            {subRoutesByCurrentPath.map((subRoute, index) => {
              const fullPathname = `${ROUTE_NAME.admin}${subRoute.path}`;
              const isActive = currentPathname === fullPathname;
              return (
                <SelectButton
                  key={index}
                  onClick={() => handleOnRouteClick(subRoute.path)}
                  title={subRoute.label}
                  isActive={isActive}
                  icon={subRoute.icon}
                  className={styles.SelectButton}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.Bottom}>
          <If state={!isAdmin}>
            <div className={styles.LockScreenBtnContainer}>
              <Button
                onClick={handleOnLockScreen}
                type={BUTTON_ENUMS.Lock}
                text={t("buttons.lockScreen")}
                rightIcon={<LockIcon />}
              />
            </div>
          </If>

          <div className={styles.NetworkWrapper}>
            <Avatar
              icon={<WifiIcon />}
              size={AVATAR_ENUMS.SIZE.types.AVATAR40}
              statusType={
                networkStatus
                  ? AVATAR_ENUMS.STATUS.types.SUCCESS
                  : AVATAR_ENUMS.STATUS.types.ERROR
              }
            />
            <Avatar
              icon={<Socket />}
              size={AVATAR_ENUMS.SIZE.types.AVATAR40}
              statusType={
                isConnected
                  ? AVATAR_ENUMS.STATUS.types.SUCCESS
                  : AVATAR_ENUMS.STATUS.types.ERROR
              }
            />
          </div>
        </div>
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_B}
        title={t("modal.warningModalTitleUnsavedChanges")}
        description={t("modal.warningModalDescription")}
        mainElementRefConfirm={mainElementRefConfirmChanges}
        openSlide={openSlideConfirmChanges}
        onCancel={handleOnChangesCancel}
        onConfirm={handleOnChangesConfirm}
      />
    </div>
  );
};

export default AdminSidebar;
