import useLanguage from "utils/hooks/useLanguage";
import { SORT_OPTIONS } from "pages/client/menu-v2/menu-filter-modal/MenuFilterModal";
import { menuItemHasDiscountBySchedule } from "./menu-item-helper";
import { calculateItemPriceWithDefaultModificationPrice } from "utils/general";
import {
  sortMenuItemsByCheapToExpensive,
  sortMenuItemsByExpensiveToCheap,
  sortMenuItemsByName,
} from "utils/helper-functions/sort-helper/sort-helper";

export const getAllTagsOfMenu = ({ categories, allTags }) => {
  const uniqueTagIds = new Set(
    categories.flatMap((category) =>
      category.menuItems.flatMap((menuItem) =>
        menuItem.tags.map((tag) => tag.id)
      )
    )
  );

  return allTags.filter((tag) => uniqueTagIds.has(tag.id));
};

export const filterMenuItemsByPrice = (menuItems, minPrice, maxPrice) => {
  return menuItems.filter((item) => {
    const itemPrice =
      calculateItemPriceWithDefaultModificationPrice(item) || item.priceSell;
    return itemPrice >= minPrice && (maxPrice === 0 || itemPrice <= maxPrice);
  });
};

export const filterMenuItemsByTags = (menuItems, selectedTags) => {
  if (!selectedTags || selectedTags.length === 0) return menuItems;

  return menuItems.filter((item) =>
    item.tags.some((tag) => selectedTags.includes(tag.id))
  );
};

export const hasItemDiscount = (item) => {
  if (item.rate.schedule?.isActive !== true && item.rate.isEnabled) {
    return true;
  } else {
    const isValidDateRange = menuItemHasDiscountBySchedule(item);
    if (isValidDateRange && item.rate.isEnabled) {
      return true;
    }
  }

  return false;
};

export const filterMenuByCriteria = ({ categories, menuFilters }) => {
  const { displayDataByLanguage } = useLanguage();
  const {
    selectedCategories,
    selectedTags,
    priceRange: { min: minPrice, max: maxPrice },
    showDiscountedItems,
    sortBy,
  } = menuFilters;

  const filteredCategories = categories
    .filter((category) => {
      if (selectedCategories.length === 0) return true;
      return selectedCategories.includes(category.id);
    })
    .map((category) => {
      let menuItems = category.menuItems.filter(
        (menuItem) => menuItem.ctaType === "menu_items"
      );

      menuItems = filterMenuItemsByPrice(menuItems, minPrice, maxPrice);

      menuItems = filterMenuItemsByTags(menuItems, selectedTags);

      return { ...category, menuItems };
    })
    .filter((category) => category.menuItems.length > 0);

  const allMenuItems = filteredCategories.flatMap(
    (category) => category.menuItems
  );
  const sortMenuItems = (menuItems) => {
    switch (sortBy) {
      case SORT_OPTIONS.az.name:
        return sortMenuItemsByName(menuItems, displayDataByLanguage);
      case SORT_OPTIONS.cheapToExpensive.name:
        return sortMenuItemsByCheapToExpensive(menuItems);
      case SORT_OPTIONS.expensiveToCheap.name:
        return sortMenuItemsByExpensiveToCheap(menuItems);
      default:
        return menuItems;
    }
  };

  const sortedMenuItems = sortMenuItems(allMenuItems);
  const filteredMenuItems = showDiscountedItems
    ? sortedMenuItems.filter(hasItemDiscount)
    : sortedMenuItems;

  const uniqueFilteredMenuItems = Array.from(
    new Map(filteredMenuItems.map((item) => [item.id, item])).values()
  );

  return [
    {
      name: "filteredMenu",
      menuItems: uniqueFilteredMenuItems,
    },
  ];
};
