import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAdminMetaDataAsync } from "redux/actions/metaDataAction";
import { STORE_NAMES } from "utils/constants/redux";
import { getOrdersAsync } from "../../redux/actions/orderActions";
import { browserEvents, BrowserEventsValue } from "../constants/browserEvents";

const useAdminMetaDataUpdater = () => {
  const dispatch = useDispatch();

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === BrowserEventsValue.visible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener(
      browserEvents.visibilitychange,
      handleVisibilityChange
    );
    return () => {
      window.removeEventListener(
        browserEvents.visibilitychange,
        handleVisibilityChange
      );
    };
  }, []);

  useEffect(() => {
    if (businessId) {
      dispatch(getAdminMetaDataAsync({ businessId: businessId }));
    }
  }, [businessId]);

  useEffect(() => {
    if (businessId && isVisible) {
      dispatch(getOrdersAsync({ businessId }));
    }
  }, [businessId, isVisible]);
};

export default useAdminMetaDataUpdater;
