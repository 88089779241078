import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useTimeout from "utils/hooks/useTimeout";
import usePrevious from "utils/hooks/usePrevious";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import { ReactComponent as HomeIcon } from "assets/icons/menuv2/Order.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menuv2/Menu.svg";
import { ReactComponent as BasketIcon } from "assets/icons/menuv2/Basket.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/menuv2/Account.svg";
import { ReactComponent as ActiveHomeIcon } from "assets/icons/menuv2/ActiveOrder.svg";
import { ReactComponent as ActiveMenuIcon } from "assets/icons/menuv2/ActiveMenu.svg";
import { ReactComponent as ActiveBasketIcon } from "assets/icons/menuv2/ActiveBasket.svg";
import { ReactComponent as ActiveProfileIcon } from "assets/icons/menuv2/ActiveAccount.svg";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import MagicOrderIconComponent from "pages/client/menu-v2/tab-bar/magic-order-icon-component/MagicOrderIconComponent";

import styles from "./TabBar.module.scss";

export const generateTabRoutes = (businessId) => {
  const { t } = useTranslation();
  const hasSignedUp = useSelector(
    (state) => state[STORE_NAMES.guest]?.hasSignedUp
  );
  return {
    MENU: {
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      icon: <MenuIcon />,
      title: t("navbarRoutes.menu"),
      activeIcon: <ActiveMenuIcon />,
      showTabBarInThisRoute: true,
    },
    MY_ORDER: {
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}`,
      icon: <HomeIcon />,
      title: t("navbarRoutes.order"),
      activeIcon: <ActiveHomeIcon />,
      showTabBarInThisRoute: true,
    },

    MAGIC_ORDER: {
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.magicOrder}`,
      showTabBarInThisRoute: false,
      title: "",
    },
    BASKET: {
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`,
      icon: <BasketIcon />,
      title: t("basket.basket"),
      activeIcon: <ActiveBasketIcon />,
      showTabBarInThisRoute: true,
    },
    MY_PROFILE: {
      path: hasSignedUp
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.myProfile}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.auth}`,
      icon: <ProfileIcon />,
      title: t("navbarRoutes.account"),
      activeIcon: <ActiveProfileIcon />,
      showTabBarInThisRoute: true,
    },
  };
};

export const TAB_BAR_HEIGHT = 76;

const ANIMATION_DURATION = 400;

const BadgeWithNumber = ({ count }) => {
  const displayCount = count > 9 ? "+9" : count;
  const [isAnimated, setIsAnimated] = useState(false);

  const prevCount = usePrevious({
    value: count,
    dep: [count],
  });

  useEffect(() => {
    if (count > prevCount && prevCount && count > 1) {
      setIsAnimated(true);
    }
  }, [count, prevCount]);

  useTimeout({
    callback: () => setIsAnimated(false),
    delay: isAnimated ? ANIMATION_DURATION : null,
  });

  return (
    <div
      className={cx(styles.BadgeContainer, { [styles.isAnimated]: isAnimated })}
      style={{
        ...(isAnimated
          ? { animationDuration: `${ANIMATION_DURATION / 1000}s` }
          : {}),
      }}
    >
      <p className={cx("Medium", styles.Text)}>{displayCount}</p>
    </div>
  );
};

const BadgeWithoutNumber = () => {
  return <div className={styles.Badge}></div>;
};

const TabBarItem = ({
  className,
  hasBadgeWithNumber = false,
  hasBadgeWithoutNumber = false,
  isDisabled = false,
  route,
  itemsCount,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { path, icon, activeIcon, title } = route;
  const handleNavigate = () => navigate(path);
  const isActive = pathname === path;
  return (
    <div
      className={cx(styles[className], {
        [styles.isDisabled]: isDisabled,
      })}
      onClick={handleNavigate}
    >
      {isActive ? activeIcon : icon}
      {title && (
        <h6
          className={cx(styles.Title, {
            [styles.isActive]: isActive,
          })}
        >
          {title}
        </h6>
      )}
      {hasBadgeWithNumber && itemsCount > 0 && (
        <BadgeWithNumber count={itemsCount} />
      )}
      {hasBadgeWithoutNumber && <BadgeWithoutNumber />}
    </div>
  );
};

const TabBar = () => {
  const { isTabBarHidden } = useGuestLayout();
  const { businessId } = useParams();
  const TAB_ROUTES = generateTabRoutes(businessId);
  const navigate = useNavigate();
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { table } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = table?.id;
  const order = orders?.find((order) => order.table.id === tableId);
  const isOrderEmpty = !order;

  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const guests = basketOrders ? basketOrders.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const basketItems = guest?.orderItems || [];
  const basketItemsCount = basketItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );

  return (
    <div
      className={cx(styles.Container, {
        [styles.isHidden]: isTabBarHidden,
      })}
      style={{ height: TAB_BAR_HEIGHT + "px" }}
    >
      <div className={styles.Part}>
        <TabBarItem className="Icon" route={TAB_ROUTES.MENU} />
        <TabBarItem
          className="Icon"
          isDisabled={isOrderEmpty}
          route={TAB_ROUTES.MY_ORDER}
        />
      </div>
      <MagicOrderIconComponent
        onClick={() => navigate(TAB_ROUTES.MAGIC_ORDER.path)}
      />
      <div className={styles.Part}>
        <TabBarItem
          className="Icon"
          hasBadgeWithNumber
          route={TAB_ROUTES.BASKET}
          itemsCount={basketItemsCount}
        />
        <TabBarItem className="Icon" route={TAB_ROUTES.MY_PROFILE} />
      </div>
    </div>
  );
};

BadgeWithNumber.propTypes = {
  count: PropTypes.number,
};
TabBarItem.propTypes = {
  className: PropTypes.string,
  hasBadgeWithNumber: PropTypes.bool,
  hasBadgeWithoutNumber: PropTypes.bool,
  isDisabled: PropTypes.bool,
  route: PropTypes.shape({
    path: PropTypes.string,
    icon: PropTypes.node,
    activeIcon: PropTypes.node,
    title: PropTypes.string,
  }),
  itemsCount: PropTypes.number,
};

export default TabBar;
