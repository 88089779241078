import React from "react";

import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import PropTypes from "prop-types";
import SuccessSvg from "assets/icons/payment/payment-modal/success.svg";
import { ReactComponent as Card } from "assets/icons/payment/card.svg";
import UserProfilePhoto from "assets/icons/payment/userProfilePhoto.svg";
import FailSvg from "assets/icons/payment/payment-modal/failed.svg";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { concatFirstNameWithLastName } from "utils/helpers";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { createDOBucketName } from "utils/DO-Spaces";
import { useTranslation } from "react-i18next";

export const PAYMENT_RESULT_ENUMS = {
  success: "SUCCESS",
  fail: "FAIL",
};

import "./PaymentResultModal.scss";

const PaymentResultModal = ({
  openSlide,
  mainElementRef,
  result,
  setOpenSlidePaymentResult,
}) => {
  const { t } = useTranslation();

  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );

  const handleOnCloseModal = () => {
    setOpenSlidePaymentResult(false);
  };
  const isSuccess = result?.status === "SUCCESS";
  const PaymentSuccessModalHeader = (
    <div className="PaymentResultModalHeader">
      <div className="PaymentResultModalIcons">
        <img src={SuccessSvg} alt="Succes Img" />
        <CloseButton
          onClick={handleOnCloseModal}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_S}
          className="CloseBTN"
        />
      </div>
      <h1 className="Bold">{t("payment.onlinePayment")}</h1>
    </div>
  );
  const PaymentSuccessModalBody = (
    <div className="PaymentResultModalBody">
      <h4>
        {t("payment.paymentMessage").replace(
          "{{card}}",
          `${result?.cardBrand}`
        )}
      </h4>
    </div>
  );

  const PaymentSuccessModalFooter = (
    <div className="PaymentResultModalFooter">
      <div className="PaymentUserCardDetails">
        <div>
          <Card className="PaymentCardIcon" />
          <h4>{result?.cardBrand}</h4>
        </div>
        <h4>{menuCurrency?.symbol + result?.paidPrice}</h4>
      </div>
      <div className="PaymentUserCardDetails">
        <div>
          {result?.guest.profilePic ? (
            <div className="PaymentUserProfilePicture">
              <img
                src={createDOBucketName(result?.guest?.profilePic)}
                alt="Card img"
              />
            </div>
          ) : (
            <div className="PaymentUserProfile">
              <img
                src={UserProfilePhoto}
                alt="Card img"
                className="UserWithoutProfilePicturePhoto"
              />
            </div>
          )}
          <h4 className="PaymentUserProfileName">
            {concatFirstNameWithLastName({
              firstName: result?.guest.firstName,
              lastName: result?.guest.lastName,
            })}
          </h4>
        </div>
      </div>
    </div>
  );

  const PaymentFailModalHeader = (
    <div className="PaymentResultModalHeader">
      <div className="PaymentResultModalIcons">
        <img src={FailSvg} alt="Succes Img" />
        <CloseButton
          onClick={handleOnCloseModal}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
          className="CloseBTN"
        />
      </div>
      <h1 className="Bold">{t("payment.onlinePayment")}</h1>
    </div>
  );

  const PaymentFailModalBody = (
    <div className="PaymentResultModalBody">
      <h4>
        {t("payment.paymentFailMessage").replace(
          "{{card}}",
          `${result?.cardBrand}`
        )}
      </h4>
    </div>
  );

  const PaymentFailModalFooter = (
    <div className="PaymentResultModalFooter">
      <div className="PaymentUserCardDetails">
        <div>
          <img src={Card} alt="Card img" />
          <h4>{result?.cardBrand}</h4>
        </div>
        <h4>{menuCurrency?.symbol + result?.paidPrice}</h4>
      </div>
      <div className="PaymentUserCardDetails">
        <div>
          {result?.guest.profilePic ? (
            <div className="PaymentUserProfilePicture">
              <img
                src={createDOBucketName(result?.guest?.profilePic)}
                alt="Card img"
              />
            </div>
          ) : (
            <div className="PaymentUserProfile">
              <img
                src={UserProfilePhoto}
                alt="Card img"
                className="UserWithoutProfilePicturePhoto"
              />
            </div>
          )}
          <h4>
            {concatFirstNameWithLastName({
              firstName: result?.guest.firstName,
              lastName: result?.guest.lastName,
            })}
          </h4>
        </div>
      </div>
    </div>
  );

  return (
    <GuestModal
      header={isSuccess ? PaymentSuccessModalHeader : PaymentFailModalHeader}
      body={isSuccess ? PaymentSuccessModalBody : PaymentFailModalBody}
      footer={isSuccess ? PaymentSuccessModalFooter : PaymentFailModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      className={"ModalBackgroundClassName"}
      containerClassname={"ContainerClassname"}
      modalClassname={"ModalClassname"}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
PaymentResultModal.propTypes = {
  openSlide: PropTypes.bool,
  mainElementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  setOpenSlidePaymentResult: PropTypes.func,

  result:
    PropTypes.shape({
      status: PropTypes.oneOf(Object.values(PAYMENT_RESULT_ENUMS)),
      cardBrand: PropTypes.string,
      paidPrice: PropTypes.number,
      guest: PropTypes.shape({
        profilePic: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }) || null,
};

export default PaymentResultModal;
