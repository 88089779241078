import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Modal from "components/modal/Modal";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import IconAskBill from "components/elements/icon-ask-bill/IconAskBill";

import "./PrintLoadingModal.scss";

const PrintLoadingModal = ({ openSlide, mainElementRef, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (openSlide) {
      const timer = setTimeout(() => {
        onClose();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [openSlide, onClose]);

  const PrintLoadingModalBody = (
    <div className="print-loading-modal">
      <div className="print-loading-modal__content">
        <div
          className={cx("print-loading-modal__icon", {
            "animate-pulse": openSlide,
          })}
        >
          <IconAskBill />
          <div className="print-loading-modal__spinner"></div>
        </div>
        <div className="print-loading-modal__text">
          <h4>{t("devices.printingBill")}</h4>
          <div className="print-loading-modal__dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );

  const PrintLoadingModalFooter = (
    <div className="print-loading-modal__footer">
      <PrimaryButton
        type="TYPE_D"
        onClick={onClose}
        text={t("buttons.close")}
        className="print-loading-modal__button"
      />
    </div>
  );

  return (
    <Modal
      footer={PrintLoadingModalFooter}
      body={PrintLoadingModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

PrintLoadingModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
};

export default PrintLoadingModal;
