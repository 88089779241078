import { checkValidDateRange } from "utils/helpers";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import QRCodeStyling from "qr-code-styling";
import LOGO_QR from "assets/images/qr/Logo-qr.png";

export const menuItemHasDiscountBySchedule = (item) => {
  const today = new Date();
  const todayDayOfWeek = today
    .toLocaleDateString("en-US", { weekday: "long" })
    .toLowerCase();

  const from = new Date(item.rate.schedule?.from);
  const to = new Date(item.rate.schedule?.to);
  const isValidDateRange = checkValidDateRange(from, to, today);

  const todaySchedule =
    item.rate.schedule?.weekdays &&
    item.rate.schedule?.weekdays[todayDayOfWeek];

  const isWorkingToday =
    todaySchedule &&
    todaySchedule.isWorking &&
    today >= new Date(today.toDateString() + " " + todaySchedule.from) &&
    today <= new Date(today.toDateString() + " " + todaySchedule.to);

  return isWorkingToday && isValidDateRange;
};

export const downloadMenuItemImagesAsZip = async ({
  businessName = "Business",
  categories = [],
  setLoading = () => {},
  setError = () => {},
}) => {
  setLoading(true);
  const zip = new JSZip();
  const rootFolder = zip.folder(businessName);
  for (const category of categories) {
    const categoryFolder = rootFolder.folder(category.name?.[0]?.value);

    for (const menuItem of category.menuItems) {
      const menuItemFolder = categoryFolder.folder(menuItem.name?.[0]?.value);
      for (let i = 0; i < menuItem.otherImagesSrc.length; i++) {
        const imageUrl =
          "https://iloyal-dev.fra1.digitaloceanspaces.com/" +
          menuItem.otherImagesSrc[i];
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          menuItemFolder.file(`${menuItem.name?.[0]?.value}${i + 1}.png`, blob);
        } catch (error) {
          setError(error);
          console.error(`Error downloading ${imageUrl}:`, error);
        }
      }
    }
  }
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${businessName}.zip`);
  });
  setLoading(false);
};

export const downloadQRCodesAsZip = async ({
  businessName,
  data = [],
  setLoading = () => {},
  setError = () => {},
  paramExtension,
  typeNumber,
}) => {
  setLoading(true);
  const zip = new JSZip();
  const rootFolder = zip.folder(`${businessName}_${paramExtension}`);

  for (const { id, url } of data) {
    try {
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: "png",
        data: url,
        margin: 10,
        qrOptions: {
          typeNumber,
          mode: "Byte",
          errorCorrectionLevel: "Q",
        },
        imageOptions: {
          hideBackgroundDots: false,
          margin: 0,
        },
        dotsOptions: {
          type: "dots",
          color: "#000000",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        image: LOGO_QR,
        cornersSquareOptions: {
          type: "extra-rounded",
          color: "#000000",
        },
        cornersDotOptions: {
          type: "dot",
          color: "#000000",
        },
      });

      const qrBlob = await qrCode.getRawData("png");

      rootFolder.file(`cat_${id}_${paramExtension}.png`, qrBlob);
    } catch (error) {
      setError(error);
      console.error(`Error generating QR for category ${id}:`, error);
    }
  }

  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${businessName}_${paramExtension}.zip`);
  });

  setLoading(false);
};
