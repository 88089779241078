import React from "react";

import Header from "pages/admin/admin-pages/admin-home/header/Header";
import Navigation from "pages/admin/admin-pages/admin-home/navigation/Navigation";
import Footer from "pages/admin/admin-pages/admin-home/footer/Footer";

import styles from "./AdminHome.module.scss";

const AdminHome = () => {
  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <Header />
        <Navigation />
        <Footer />
      </div>
    </div>
  );
};

export default AdminHome;
