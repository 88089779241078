import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { useCallback } from "react";

const useLanguage = () => {
  const menuDefaultLanguageCode = useSelector(
    (state) => state[STORE_NAMES.menu].data?.language?.code
  );
  const userPreferredLanguageCode = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage?.code
  );

  const displayDataByLanguage = useCallback(
    (value) => {
      return (
        value.find((p) => p.languageCode === userPreferredLanguageCode)
          ?.value ||
        value.find((p) => p.languageCode === menuDefaultLanguageCode)?.value ||
        value.find((p) => p.value)?.value ||
        ""
      );
    },
    [menuDefaultLanguageCode, userPreferredLanguageCode]
  );

  return { displayDataByLanguage };
};

export default useLanguage;
