import React, { useCallback } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import useOutsideClick from "utils/hooks/useOutsideClick";
import DropdownOption from "components/pos/language-dropdown/dropdown-option/DropdownOption";
import ICON_ARROW from "assets/icons/pos/chevron-down.svg";
import { STORE_NAMES } from "utils/constants/redux";
import { setLanguage } from "redux/slices/userStore";
import If from "components/if/If";

import styles from "./LanguageDropdown.module.scss";

const Dropdown = ({ className, menuMaxHeight = 272 }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOpen, setIsOpen, mainElementRef, toggleBtnRef] = useOutsideClick();

  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const closeDropdown = () => setIsOpen(false);

  const { allLanguages, selectedLanguage } = useSelector((state) => ({
    allLanguages: state[STORE_NAMES.app].languages,
    selectedLanguage:
      state[STORE_NAMES.user].userLanguage ||
      state[STORE_NAMES.app].languages[0],
  }));

  const handleOptionClick = useCallback(
    (option) => {
      if (option) {
        dispatch(setLanguage(option));
        closeDropdown();
      }
    },
    [dispatch, selectedLanguage]
  );

  return (
    <div className={cx(styles.Root, className)}>
      <div
        className={styles.SelectedItem}
        onClick={toggleDropdown}
        ref={toggleBtnRef}
      >
        <div className={styles.SelectedItemTitle}>
          <img
            className={styles.SelectedItemTitleImage}
            src={selectedLanguage.imgSrc}
            alt={selectedLanguage.name}
          />
          <h4 className={cx(`Medium ${styles.selectedLanguage}`)}>
            {selectedLanguage.code}
          </h4>
        </div>
        <img
          className={cx(styles.Arrow, { [styles.isOpen]: isOpen })}
          src={ICON_ARROW}
          alt="arrow"
        />
      </div>
      <If state={isOpen}>
        <div
          className={styles.Options}
          ref={mainElementRef}
          style={{ maxHeight: `${menuMaxHeight}px` }}
        >
          <If state={allLanguages.length === 0}>
            <h6 className={cx(styles.EmptyDropdown, "Medium")}>
              {t("emptyStates.noOption")}
            </h6>
          </If>
          {allLanguages.map((option, index) => (
            <DropdownOption
              key={index}
              option={option}
              isActive={option.id === selectedLanguage.id}
              handleOptionClick={handleOptionClick}
              className={styles.DropDownOptions}
            />
          ))}
        </div>
      </If>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  menuMaxHeight: PropTypes.number,
};

export default Dropdown;
