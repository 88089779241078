import React from "react";
import PropTypes from "prop-types";

import useTimer from "utils/hooks/useTimer";

export const ENUMS = {
  name: "TimerWithColon",
};

const TimerWithColon = ({
  startTime,
  twoDigitsEnabled = false,
  disabledSecond = false,
}) => {
  const { hours, minutes, seconds } = useTimer(startTime);

  const formatTimeDigits = (time) => {
    if (twoDigitsEnabled) {
      return time.toString().padStart(2, "0");
    }
    return time.toString();
  };
  const formattedTime = disabledSecond
    ? `${formatTimeDigits(hours)}:${formatTimeDigits(minutes)}`
    : `${formatTimeDigits(hours)}:${formatTimeDigits(
        minutes
      )}:${formatTimeDigits(seconds)}`;

  return <>{formattedTime}</>;
};

TimerWithColon.propTypes = {
  /**
   * The start time for the timer
   */
  startTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  /**
   * Flag to enable two digits for each time component
   */
  twoDigitsEnabled: PropTypes.bool,
  disabledSecond: PropTypes.bool,
};

export default TimerWithColon;
