import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";

import { useMixpanel } from "utils/context-api/MixpanelContext";
import {
  MP_EVENTS,
  MP_PROP_NAMES,
  MP_SOURCE_NAME,
} from "utils/constants/mixpanel";
import { BASKET_METHOD } from "pages/client/basket/Basket";

import "./SwitchOptions.scss";

const SwitchOptions = ({
  className,
  options,
  selectedMethod,
  setSelectedMethod,
}) => {
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();

  const onChangeHandler = (event) => {
    const { value } = event.target;
    if (value === BASKET_METHOD.favorite) {
      trackMixpanel(`${MP_EVENTS.basket.viewFavorite}`, {
        [MP_PROP_NAMES.source]: MP_SOURCE_NAME.basket,
      });
    } else if (value === BASKET_METHOD.active) {
      trackMixpanel(`${MP_EVENTS.basket.viewBasket}`, {
        [MP_PROP_NAMES.source]: MP_SOURCE_NAME.basket,
      });
    }
    setSelectedMethod(value);
  };

  const activeIndex = options.findIndex(
    (option) => option.name === selectedMethod
  );

  return (
    <div className={cx("FilterSwitch", className)}>
      <div className="FilterSwitchWrapper">
        {options.map((option) => {
          const { name, value, label } = option;
          return (
            <React.Fragment key={name}>
              <input
                id={name}
                name={name}
                type="radio"
                value={value}
                checked={selectedMethod === name}
                onChange={onChangeHandler}
              />
              <label className="option" htmlFor={name}>
                {t(label)}
              </label>
            </React.Fragment>
          );
        })}
        <div
          className="background"
          style={{
            width: `${100 / options.length}%`,
            [document.documentElement.dir === "rtl" ? "right" : "left"]: `${
              (activeIndex * 100) / options.length
            }%`,
          }}
        />
      </div>
    </div>
  );
};

SwitchOptions.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  selectedMethod: PropTypes.string,
  setSelectedMethod: PropTypes.func,
};
export default SwitchOptions;
