import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./IosSwitch.scss";

const IosSwitch = ({ isChecked, onChange, label, isDisabled, darkMode }) => {
  return (
    <div className={cx("IosSwitchContainer", { isDisabled: isDisabled })}>
      <div
        className={cx("IosSwitch", {
          isChecked: isChecked,
          IsDarkMode: darkMode,
        })}
        onClick={onChange}
      >
        <div className="IosSwitchSlider"></div>
      </div>
      {label && <h6 className="IosSwitchLabel">{label}</h6>}
    </div>
  );
};

IosSwitch.propTypes = {
  /**
   * Whether the switch is checked or not
   */
  isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),

  /**
   * Callback function to handle switch toggling
   */
  onChange: PropTypes.func,

  /**
   * The label text for the switch
   */
  label: PropTypes.string,
  /**
   * Is button disabled or not
   */
  isDisabled: PropTypes.bool,
  darkMode: PropTypes.bool,
};

export default IosSwitch;
