import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS, API_PATH_HELPERS } from "utils/constants/api";

export const getQRCodes = async (businessId) =>
  AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.qr}`
  );

export const createQRCode = async (businessId, qr, type) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.qr}?${API_PATH_HELPERS.type}=${type}`,
    qr
  );

export const updateQRCode = async (businessId, qr, id, type) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.qr}/${id}?${API_PATH_HELPERS.type}=${type}`,
    qr
  );

export const deleteQRCode = async (businessId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.qr}/${id}`
  );

export const getClientMetadata = async (businessId, qrId) => {
  const baseUrl = `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.metadata}`;
  const params = new URLSearchParams();
  if (qrId) {
    params.append(API_PATH_HELPERS.qrId, qrId);
  }
  const url = `${baseUrl}?${params.toString()}`;
  return AXIOS_USER.get(url);
};
