import { useSelector } from "react-redux";
import { STORE_NAMES } from "../constants/redux";
import { useEffect } from "react";
import { LANGUAGES } from "../constants/language";

const useArabianLanguageController = () => {
  const userPreferredLanguageCode = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage?.code
  );

  useEffect(() => {
    if (LANGUAGES.ar.code === userPreferredLanguageCode) {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
  }, [userPreferredLanguageCode]);
};

export default useArabianLanguageController;
