import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { ReactComponent as IconArrow } from "assets/icons/arrows/ArrowLeft.svg";

import "./BackButton.scss";

export const ENUMS = {
  name: "BackButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
    TYPE_F: "TYPE_F",
  },
};

const BackButton = ({ type, onClick }) => {
  return (
    <button
      className={cx("BackButton", {
        typeB: type === ENUMS.types.TYPE_B,
        typeC: type === ENUMS.types.TYPE_C,
        typeD: type === ENUMS.types.TYPE_D,
        typeE: type === ENUMS.types.TYPE_E,
        typeF: type === ENUMS.types.TYPE_F,
      })}
      onClick={onClick}
      type="button"
    >
      <IconArrow />
    </button>
  );
};

BackButton.propTypes = {
  /**
   * The type of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
    ENUMS.types.TYPE_D,
    ENUMS.types.TYPE_E,
    ENUMS.types.TYPE_F,
  ]),

  /**
   * The callback function when the button is clicked
   */
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
