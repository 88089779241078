import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import { ReactComponent as HamburgerMenu } from "assets/icons/menuv2/hamburger.svg";
import IconButton from "components/icon-button/IconButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { Languages } from "pages/client/menu-v2/top-bar/TopBar";
import { useGuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";

import "./BasketHeroSection.scss";

const BasketHeroSection = () => {
  const { openHamburger } = useGuestLayout();
  const { t } = useTranslation();

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.menu].data?.availableLanguages
  );
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const [openLanguages, setOpenLanguages, mainElementRef] = useOutsideClick();
  const showLanguagesModal = availableLanguages?.length > 1 || false;

  const { unreadMessagesCount } = useGuestWebsocketContextProvider();
  const hasNotification = unreadMessagesCount > 0 && business?.chat;

  return (
    <div className="BasketHeroSection">
      <div className="BasketHeader">
        <h2 className="BasketHeaderTitle Medium">{t("basket.basket")}</h2>
        <div className="BasketHeaderActions">
          {availableLanguages.length > 1 && (
            <IconButton
              className="BasketHeaderActionButton"
              img={selectedLanguage?.imgSrc}
              onClick={() => setOpenLanguages(showLanguagesModal)}
            />
          )}
          <IconButton
            className="BasketHeaderActionButton"
            Icon={HamburgerMenu}
            onClick={openHamburger}
            hasNotification={hasNotification}
          />
        </div>
      </div>
      <Languages
        mainElementRef={mainElementRef}
        openSlide={openLanguages}
        onClose={() => setOpenLanguages(false)}
      />
    </div>
  );
};

BasketHeroSection.propTypes = {
  /**
   * An array of favorite items
   */
  favoriteItems: PropTypes.array,

  /**
   * Function to close an item
   */
  onClose: PropTypes.func,

  /**
   * Function to add an item
   */
  onAdd: PropTypes.func,
};
export default BasketHeroSection;
