import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getInventoryCategoriesAsync } from "../actions/inventoryActions";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const inventoryStore = createSlice({
  name: STORE_NAMES.inventory,
  initialState: initialState,
  reducers: {
    resetInventoryStore: () => {
      return { ...initialState };
    },

    addNewCategory: (state, { payload }) => {
      state.data.push({
        id: state.data.length + 1,
        name: payload,
        inventoryItems: [],
      });
    },

    ChangeNameOfCategory: (state, { payload }) => {
      return {
        ...state,
        data: state?.data.map((ctg) =>
          ctg.id === payload.id ? { ...ctg, name: payload.name } : ctg
        ),
      };
    },

    deleteInventoryCategory: (state, { payload }) => {
      return {
        ...state,
        data: state?.data.filter((ctg) => ctg.id !== payload.id),
      };
    },

    addInventoryItemToCategory: (state, { payload }) => {
      return {
        ...state,
        data: state?.data.map((ctg) =>
          ctg.id === payload.categoryId
            ? {
                ...ctg,
                inventoryItems: [
                  ...ctg.inventoryItems,
                  { ...payload.item, id: ctg.inventoryItems.length + 1 },
                ],
              }
            : ctg
        ),
      };
    },

    editInventoryItemToCategory: (state, { payload }) => {
      state.data = state?.data.map((ctg) =>
        ctg.id === payload.categoryId
          ? {
              ...ctg,
              inventoryItems: ctg.inventoryItems.map((item) =>
                item.id === payload.item.id ? payload.item : item
              ),
            }
          : ctg
      );
    },

    deleteCategoryItem: (state, { payload }) => {
      return {
        ...state,
        data: state?.data.map((ctg) =>
          ctg.id === payload.categoryId
            ? {
                ...ctg,
                inventoryItems: ctg.inventoryItems.filter(
                  (item) => item.id !== payload.itemId
                ),
              }
            : ctg
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventoryCategoriesAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(
      getInventoryCategoriesAsync.rejected,
      (state, { payload }) => {
        return { ...state, isLoading: false, error: payload };
      }
    );
    builder.addCase(
      getInventoryCategoriesAsync.fulfilled,
      (state, { payload }) => {
        return { ...state, isLoading: false, data: payload };
      }
    );
  },
});

export const {
  resetInventoryStore,
  addNewCategory,
  ChangeNameOfCategory,
  deleteInventoryCategory,
  addInventoryItemToCategory,
  deleteCategoryItem,
  editInventoryItemToCategory,
} = inventoryStore.actions;

export default inventoryStore.reducer;
