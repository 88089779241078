import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { ReactComponent as BasketIcon } from "assets/icons/menuv2/Basket.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash/refresh.svg";
import { ReactComponent as IconArrow } from "assets/icons/menuv2/BackButton.svg";

import "./MagicOrderHeader.scss";

const MagicOrderHeader = ({ resultsHistory, setResultsHistory }) => {
  const { businessId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guests = basketOrders ? basketOrders.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const orderItems = guest?.orderItems || [];
  const orderItemsCount = orderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );

  const handleGoMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };
  const handleGoBasket = () => {
    navigate({
      pathname: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`,
      search: `?${QUERY_PARAMS.from}=${pathname}`,
    });
  };
  const handleClearAllChat = () => {
    setResultsHistory([]);
    sessionStorage.removeItem(resultsHistory);
  };

  const displayCount = orderItemsCount > 9 ? "+9" : orderItemsCount;

  return (
    <div className="SpeechToTextContainerHeader">
      <div className="BackButtonWrapper">
        <button className="MagicOrderBackButton" onClick={handleGoMenu}>
          <IconArrow />
        </button>
      </div>
      <h4 className="SemiBold SpeechToTextContainerHeaderTitle">
        {t("record.magicSearch")}
      </h4>
      <div className="SpeechToTextContainerHeaderActions">
        {resultsHistory.length > 0 && (
          <div className="TrashContainer" onClick={handleClearAllChat}>
            <TrashIcon />
          </div>
        )}
        <div className="BasketContainer">
          <BasketIcon onClick={handleGoBasket} />
          {orderItemsCount > 0 && (
            <div className="BasketButtonCountWrapper">
              <p className="h7 SemiBold">{displayCount}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MagicOrderHeader.propTypes = {
  resultsHistory: PropTypes.array,
  setResultsHistory: PropTypes.func,
};

export default MagicOrderHeader;
