import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import If from "components/if/If";

import styles from "./AvatarWithText.module.scss";

export const ENUMS = {
  SIZE: {
    name: "AvatarWithTextSize",
    types: {
      AVATAR24: "Avatar24",
      AVATAR28: "Avatar28",
      AVATAR32: "Avatar32",
      AVATAR36: "Avatar36",
      AVATAR40: "Avatar40",
      AVATAR44: "Avatar44",
      AVATAR48: "Avatar48",
    },
  },
  STATUS: {
    name: "AvatarWithTextStatus",
    types: {
      SUCCESS: "Success",
      ERROR: "Error",
      WARNING: "Warning",
    },
  },
};

const AvatarWithText = ({
  src,
  title,
  icon,
  size = ENUMS.SIZE.types.AVATAR48,
  statusType,
  className,
  initialText,
  onClick,
}) => {
  const { t } = useTranslation();
  const [shouldShowInitial, setShouldShowInitial] = useState(
    Boolean(initialText)
  );

  return (
    <div
      className={cx(styles.Root, className, {
        [styles.isClickable]: Boolean(onClick),
      })}
      onClick={onClick}
    >
      <div className={cx(styles.AvatarContainer, styles[size])}>
        <If state={Boolean(src)}>
          <img
            src={src}
            className={styles.Avatar}
            alt={t("general.profilePicture")}
            onLoad={() => setShouldShowInitial(false)}
            style={{ display: shouldShowInitial ? "none" : "inline-block" }}
          />
        </If>
        <If state={Boolean(icon)}>
          <div className={styles.Icon}>{icon}</div>
        </If>
        <If state={shouldShowInitial}>
          <h5 className={cx(styles.Initial, "Medium")}>{initialText}</h5>
        </If>
        <If state={Boolean(statusType)}>
          <div className={cx(styles.StatusType, styles[statusType])}></div>
        </If>
      </div>
      <h5 className={cx(styles.Title, "Medium", styles[size])}>{title}</h5>
    </div>
  );
};

AvatarWithText.propTypes = {
  src: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(ENUMS.SIZE.types)).isRequired,
  statusType: PropTypes.oneOf(Object.values(ENUMS.STATUS.types)),
  className: PropTypes.string,
  initialText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default AvatarWithText;
