import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
import {
  calculateMenuItemFinalPrice,
  calculateMenuItemRegularPrice,
  filterItemsByIsArchivedAndIsPublished,
  findHighestDefaultMenuItemPrice,
  menuItemBannerImageHandler,
  menuItemCoverImageHandler,
  menuItemOtherImagesHandler,
  sortCategoriesWithMenuItemsByPlaceInTheList,
} from "utils/helpers";
import {
  getAllTagsOfMenu,
  hasItemDiscount,
} from "utils/helper-functions/menu-helper/menu-filter-helper";
import useLanguage from "utils/hooks/useLanguage";

const GuestMenuContext = createContext();

const findItemByIdInCategories = ({ id, categories = [] }) => {
  if (!categories || categories?.length === 0 || !id) {
    return null;
  }
  for (const category of categories) {
    for (const menuItem of category.menuItems) {
      if (menuItem.id.toString() === id.toString()) {
        return menuItem;
      }
    }
  }
  return null;
};

const GuestMenuProvider = ({ children }) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const { displayDataByLanguage } = useLanguage();
  const { menuCategoryType, menuItemCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const allTags = menu?.tags || [];
  const defaultMenuViewType = useMemo(() => {
    const menuViewTypes = Object.values(MENU_VIEW_ENUMS);
    if (!menuViewTypes.length) return null;
    const defaultView = menu?.defaultMenuView;
    const selectedMenuView = menuViewTypes.find(
      (type) => type.id === defaultView
    );
    return selectedMenuView?.key || menuViewTypes[0].key;
  }, [menu]);

  const shouldDisplayMenuItemImage =
    defaultMenuViewType !== MENU_VIEW_ENUMS.WITHOUT_IMAGE.key;

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const startChecking = () => {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const milliseconds = now.getMilliseconds();
      const nextTargetMinute = [0, 15, 30, 45].find((m) => m > minutes) ?? 60;

      const minutesToNext =
        (nextTargetMinute - minutes + (nextTargetMinute === 60 ? -60 : 0)) % 60;

      const msToNext =
        minutesToNext * 60 * 1000 - seconds * 1000 - milliseconds;

      return setTimeout(() => {
        checkAndRefresh();
        const interval = setInterval(checkAndRefresh, 60 * 1000 * 15);

        return () => clearInterval(interval);
      }, msToNext);
    };

    const checkAndRefresh = () => {
      const now = new Date();
      const minutes = now.getMinutes();

      if ([0, 15, 30, 45].includes(minutes)) {
        setRefreshKey((prev) => prev + 1);
      }
    };

    const timeoutId = startChecking();

    return () => clearTimeout(timeoutId);
  }, []);

  const updatedCategories = useMemo(() => {
    if (!menu?.categories?.length) return [];
    try {
      return menu.categories.map((category) => {
        return {
          ...category,
          menuItems: category.menuItems.map((menuItem) => {
            return {
              ...menuItem,
              coverImageSrc: menuItemCoverImageHandler({
                menuItem,
              }),
              otherImagesSrc: menuItemOtherImagesHandler(menuItem),
              extraFields: {
                displayedName: displayDataByLanguage(menuItem.name),
                displayedDescription: displayDataByLanguage(
                  menuItem.description
                ),
                price: {
                  regular: calculateMenuItemRegularPrice({ menuItem }),
                  final: calculateMenuItemFinalPrice({ menuItem }),
                },
                bannerImage: menuItemBannerImageHandler({
                  menuItem,
                  menuItemCategoryType,
                  menuCategoryType,
                  categoryType: category.type,
                }),
              },
            };
          }),
          extraFields: {
            displayedName: displayDataByLanguage(category.name),
          },
        };
      });
    } catch (e) {
      return [];
    }
  }, [menu?.categories, refreshKey, displayDataByLanguage]);

  const transformedData = useMemo(() => {
    if (updatedCategories.length === 0) {
      return {
        categoriesWithoutPromotion: [],
        allCategories: [],
        promotion: null,
      };
    }
    try {
      const filteredCategories =
        filterItemsByIsArchivedAndIsPublished(updatedCategories);

      const allMenuItems =
        updatedCategories.flatMap(({ menuItems }) => menuItems) || [];

      const existPromotion = filteredCategories.find(
        ({ type }) => type === menuCategoryType.promotion
      );
      const existPromotionMenuItems = existPromotion
        ? filterItemsByIsArchivedAndIsPublished(existPromotion.menuItems)
        : [];
      const promotion =
        existPromotion && existPromotionMenuItems.length > 0
          ? {
              ...existPromotion,
              menuItems: existPromotionMenuItems.sort(
                (a, b) => a.placeInTheList - b.placeInTheList
              ),
            }
          : null;

      const updatedCategoriesWithoutPromo = filteredCategories
        .filter(({ type }) => type !== menuCategoryType.promotion)
        .map((category) => {
          if (category.type !== menuCategoryType.special_category)
            return category;

          const linkedItems = category.linkedMenuItems
            .map((id) =>
              allMenuItems.find(({ id: menuItemId }) => menuItemId === id)
            )
            .filter(Boolean)
            .map((menuItem) => ({
              ...menuItem,
              isPublished: true,
              schedule: {
                from: null,
                isActive: null,
                to: null,
                weekdays: null,
              },
            }));

          if (category.showDiscountItems) {
            const discountItems = allMenuItems
              .filter(
                (menuItem) =>
                  !category.linkedMenuItems.includes(menuItem.id) &&
                  hasItemDiscount(menuItem)
              )
              .map((menuItem) => ({
                ...menuItem,
                isPublished: true,
                schedule: {
                  from: null,
                  isActive: null,
                  to: null,
                  weekdays: null,
                },
              }));
            linkedItems.push(...discountItems);
          }

          return { ...category, menuItems: linkedItems };
        })
        .map((category) => ({
          ...category,
          menuItems: filterItemsByIsArchivedAndIsPublished(category.menuItems),
        }))
        .filter(({ menuItems }) => menuItems.length > 0);

      const categoriesWithoutPromotion =
        sortCategoriesWithMenuItemsByPlaceInTheList(
          updatedCategoriesWithoutPromo
        );

      const allCategories = promotion
        ? [...categoriesWithoutPromotion, promotion]
        : categoriesWithoutPromotion;

      return { categoriesWithoutPromotion, allCategories, promotion };
    } catch (e) {
      return {
        categoriesWithoutPromotion: [],
        allCategories: [],
        promotion: null,
      };
    }
  }, [updatedCategories, refreshKey]);

  const { categoriesWithoutPromotion, promotion, allCategories } =
    transformedData;

  const existTags = useMemo(() => {
    if (!allCategories?.length || !allTags?.length) return [];
    return getAllTagsOfMenu({ categories: allCategories, allTags });
  }, [allCategories]);

  const highestMenuItemPrice = useMemo(() => {
    if (allCategories.length === 0) return 0;

    return findHighestDefaultMenuItemPrice(allCategories);
  }, [allCategories]);

  const findItemById = useCallback(
    (menuItemId) =>
      findItemByIdInCategories({
        id: menuItemId,
        categories: updatedCategories,
      }),
    [updatedCategories, findItemByIdInCategories]
  );

  const isValidMenuItem = useCallback(
    (menuItemId) =>
      Boolean(
        findItemByIdInCategories({ id: menuItemId, categories: allCategories })
      ),
    [allCategories, findItemByIdInCategories]
  );

  return (
    <GuestMenuContext.Provider
      value={{
        shouldDisplayMenuItemImage,
        categoriesWithoutPromotion,
        promotion,
        allCategories,
        existTags,
        highestMenuItemPrice,
        findItemById,
        isValidMenuItem,
        defaultMenuViewType,
      }}
    >
      {children}
    </GuestMenuContext.Provider>
  );
};

export const useMenuHelper = () => {
  return useContext(GuestMenuContext);
};

GuestMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestMenuProvider;
