import { calculateItemPriceWithDefaultModificationPrice } from "utils/general";

export const sortMenuItemsByName = (items, displayDataByLanguage) => {
  return items.sort((itemA, itemB) => {
    const nameA = displayDataByLanguage(itemA.name);
    const nameB = displayDataByLanguage(itemB.name);

    return nameA.localeCompare(nameB);
  });
};

export const sortMenuItemsByCheapToExpensive = (items) => {
  return items.sort((itemA, itemB) => {
    const priceA = calculateItemPriceWithDefaultModificationPrice(itemA);
    const priceB = calculateItemPriceWithDefaultModificationPrice(itemB);

    return priceA - priceB;
  });
};

export const sortMenuItemsByExpensiveToCheap = (items) => {
  return items.sort((itemA, itemB) => {
    const priceA = calculateItemPriceWithDefaultModificationPrice(itemA);
    const priceB = calculateItemPriceWithDefaultModificationPrice(itemB);

    return priceB - priceA;
  });
};
