import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { STORE_NAMES } from "utils/constants/redux";
import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import Confirm from "components/admin/cards/confirm/Confirm";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import { ReactComponent as ArrowIcon } from "assets/icons/profile/arrowIcon.svg";
import { ReactComponent as OrderIcon } from "assets/icons/profile/reserve.svg";
import { ReactComponent as FavoritesIcon } from "assets/icons/profile/Fav.svg";
import { ReactComponent as ChatIcon } from "assets/icons/profile/Message.svg";
import { ReactComponent as GameIcon } from "assets/icons/profile/Game.svg";

import useGuestLogout from "utils/hooks/useGuestLogout";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import { ROUTE_NAME } from "utils/constants/routes";
import { concatFirstNameWithLastName } from "utils/helpers";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import "./MyProfile.scss";

const MyProfile = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.myProfile });
  const { t } = useTranslation();
  const { logoutGuest } = useGuestLogout();
  const { navigate } = useInternalNavigation();
  const { businessId } = useParams();
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const isExistOrder = orders?.find((order) => order?.table.id === tableId);
  const isChatActive = useSelector(
    (state) => state[STORE_NAMES?.business]?.business?.chat
  );

  const [openConfirm, setOpenConfirm, confirmRef] = useOutsideClick();

  const handleLogOut = () => setOpenConfirm(true);

  const handleConfirmLogOut = async () => {
    navigate({
      path: businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`,
    });
    await logoutGuest();
  };
  const handleGoEditProfile = () => {
    navigate({
      path: businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`,
    });
  };

  const options = useMemo(() => {
    return [
      {
        text: t("myProfile.orderHistory"),
        icon: <OrderIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}`,
        isHidden: !isExistOrder,
      },
      {
        text: t("myProfile.favorites"),
        icon: <FavoritesIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.favorites}`,
        isHidden: false,
      },
      {
        text: t("myProfile.chat"),
        icon: <ChatIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.chat}`,
        isHidden: !isExistOrder || !isChatActive,
      },
      {
        text: t("myProfile.gameHistory"),
        icon: <GameIcon />,
        path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
        isHidden: false,
      },
    ].filter((option) => !option.isHidden);
  }, [isExistOrder, isChatActive]);

  const ProfileSection = ({ option }) => {
    const { icon, text, path } = option;

    const handleNavigate = (path) => {
      navigate({ path, preserveExistingUrl: true });
    };
    return (
      <div
        className="MyProfileContentSection"
        onClick={() => handleNavigate(path)}
      >
        {icon}
        <div>
          <h4 className="Medium">{text}</h4>
          <ArrowIcon className="ArrowIcon" />
        </div>
      </div>
    );
  };

  ProfileSection.propTypes = {
    option: PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  return (
    <div className="MyProfile">
      <div className="MyProfileHeader">
        <h1 className="SemiBold LargeTitle">{t("myProfile.title")}</h1>
      </div>

      <div className="MyProfileInfo" onClick={handleGoEditProfile}>
        <GuestProfileWithIcon
          image={guest.profilePic}
          hasImage={!!guest.profilePic}
          className="MyProfileGuestImageContainer"
        />
        <div className="MyProfileInfoDetails">
          <h4 className="SemiBold">{concatFirstNameWithLastName(guest)}</h4>
          {guest.phoneNumber && (
            <PhoneNumberFormatter
              phoneNumber={guest.phoneNumber}
              className="MyProfileGuestPhoneNumber"
            />
          )}
          {guest.email && <h5>{guest.email}</h5>}
        </div>
        <ArrowIcon className="ArrowIcon" />
      </div>

      <div className="GuestProfileOptions">
        <div className="MyProfileContent">
          {options.filter(Boolean).map((option, index) => (
            <ProfileSection key={index} option={option} />
          ))}
        </div>

        <div className="MyProfileContent">
          <div className="MyProfileContentSection" onClick={handleLogOut}>
            <h4 className="Medium LogOut">{t("buttons.logOut")}</h4>
          </div>
        </div>
      </div>

      <Confirm
        type="TYPE_A"
        title={t("modal.confirmModalTitleLogOut")}
        description={t("modal.confirmModalDescriptionLogOut")}
        mainElementRefConfirm={confirmRef}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={handleConfirmLogOut}
        openSlide={openConfirm}
      />
    </div>
  );
};

export default MyProfile;
