import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Modal from "components/modal/Modal";
import FooterButtons from "components/admin/elements/footer-buttons/FooterButtons";
import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";
import { ReactComponent as CashIcon } from "assets/icons/payment/cash.svg";
import { ReactComponent as CardIcon } from "assets/icons/payment/card.svg";

import "./PaymentSelection.scss";

export const ENUMS = {
  name: "Confirm",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const PaymentSelection = ({
  type = ENUMS.types.TYPE_A,
  title,
  description,
  mainElementRefConfirm,
  openSlide,
  onConfirm,
  onCancel,
  setFormData,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [selectedMethod, setSelectedMethod] = useState(null);

  useEffect(() => {
    if (!openSlide) {
      setStep(1);
      setSelectedMethod(null);
    }
  }, [openSlide]);

  const handlePaymentChoice = (paymentMethod) => {
    setSelectedMethod(paymentMethod);
    setFormData((prevState) => ({
      ...prevState,
      actions: prevState.actions.map((action) =>
        action.name === ORDER_ACTIONS_TYPE.bill
          ? { ...action, type: paymentMethod }
          : action
      ),
    }));
    setStep(2);
  };

  const paymentMethods = [
    {
      id: "CASH",
      icon: <CashIcon />,
      title: t("payment.cash"),
    },
    {
      id: "POS-TERMINAL",
      icon: <CardIcon />,
      title: t("payment.POSTerminal"),
    },
  ];

  const ConfirmBody =
    step === 1 ? (
      <div className="payment-selection">
        <div className="payment-selection__header">
          <h2>{t("modal.closeOrderConfirmation")}</h2>
        </div>
        <div className="payment-selection__methods">
          {paymentMethods.map((method) => (
            <div
              key={method.id}
              className={cx("payment-method", {
                "payment-method--selected": selectedMethod === method.id,
              })}
              onClick={() => handlePaymentChoice(method.id)}
            >
              <div className="payment-method__icon">{method.icon}</div>
              <div className="payment-method__content">
                <h3>{method.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="payment-confirmation">
        <div className="payment-confirmation__content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    );

  const ConfirmFooter =
    step === 1 ? null : (
      <FooterButtons
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelButtonText={t("modal.no")}
        confirmButtonText={t("modal.yes")}
        confirmButtonType={type === ENUMS.types.TYPE_A ? "TYPE_C" : "TYPE_B"}
      />
    );

  return (
    <div className="payment-selection-modal">
      <Modal
        body={ConfirmBody}
        footer={ConfirmFooter}
        mainElementRef={mainElementRefConfirm}
        openSlide={openSlide}
      />
    </div>
  );
};

PaymentSelection.propTypes = {
  /** Defines the type of modal behavior (TYPE_A, TYPE_B, or TYPE_C) */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /** The main title displayed in the confirmation step */
  title: PropTypes.string.isRequired,

  /** Additional description text shown in the confirmation step. Can be boolean false to hide */
  description: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /** Reference to the main modal element for positioning and animations */
  mainElementRefConfirm: PropTypes.object,

  /** Controls the visibility of the modal slide */
  openSlide: PropTypes.bool.isRequired,

  /** Callback function triggered when user confirms the payment */
  onConfirm: PropTypes.func.isRequired,

  /** Callback function triggered when user cancels the payment */
  onCancel: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default PaymentSelection;
