import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./MainPageCard.module.scss";

export const ENUMS = {
  name: "MainPageCard",
  types: {
    PRIMARY: "Primary",
    SECONDARY: "Secondary",
  },
};
const MainPageCard = ({
  onClick,
  icon,
  type = ENUMS.types.SECONDARY,
  label,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={cx(styles.Root, className, styles[type], {
        [styles.isClickable]: Boolean(onClick),
      })}
    >
      <div className={styles.Icon}>{icon}</div>
      <h3 className={cx(styles.Label, "SemiBold")}>{label}</h3>
    </div>
  );
};

MainPageCard.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  type: PropTypes.oneOf([ENUMS.types.PRIMARY, ENUMS.types.SECONDARY]),
  className: PropTypes.string,
};

export default MainPageCard;
