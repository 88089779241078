import React from "react";
import PropTypes from "prop-types";

import "./AccordionContainer.scss";

export const ENUMS = {
  name: "AccordionContainer",
};

const AccordionContainer = ({ name, bodyComponent }) => {
  return (
    <div className="AccordionContainer">
      <h2 className="SemiBold">{name}</h2>
      <div className="AccordionContainerBody isAccordionBodyVisible">
        {bodyComponent}
      </div>
    </div>
  );
};

AccordionContainer.propTypes = {
  /**
   * The name of the accordion section
   */
  name: PropTypes.string,
  /**
   * The component that represents the body content of the accordion section
   */
  bodyComponent: PropTypes.node,
};

export default AccordionContainer;
