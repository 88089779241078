import { useEffect, useState } from "react";

const useCountdown = (initialCount) => {
  const [counter, setCounter] = useState(initialCount);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  return [counter, setCounter];
};

export default useCountdown;
