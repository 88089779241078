import TermsAndCondition from "utils/constants/i18n/TermsAndCondition";
import PrivacyPolicy from "utils/constants/i18n/PrivacyPolicy";

const TRANSLATION = {
  welcome: "مرحبًا (عربي)",
  common: {
    settings: "الإعدادات",
    about: "حول",
    home: "الرئيسية",
    logo: "الشعار",
    shape: "الشكل",
    selected: "محدد",
    create: "إنشاء",
    notFound: "غير موجود",
    applied: "تم التقديم",
    yourLikes: "إعجاباتك",
    details: "التفاصيل",
    published: "تم النشر",
    unPublished: "غير منشور",
    general: "عام",
    address: "العنوان",
    wifi: "واي فاي",
    sort: "الترتيب",
    copied: "تم النسخ",
    contact: "اتصال",
    pages: "الصفحات",
    kcal: "كيلو كالوري",
    currency: "العملة",
    connect: "اتصال",
    disconnect: "فصل الاتصال",
    confirmDisconnect: "هل أنت متأكد أنك تريد فصل الاتصال؟",
    connection: "الاتصال",
    qr: "QR",
    telegram: "تيليجرام",
    enable: "تفعيل",
    disable: "تعطيل",
    zoneAndTable: "المنطقة والطاولة",
    printers: "الطابعات",
    connectToWifi: "متصل بالواي فاي",
    connectWifi: "اتصال بالواي فاي",
    disconnectFromWifi: "فصل الاتصال بالواي فاي",
    name: "الاسم",
    active: "نشط",
    inactive: "غير نشط",
    date: {
      date: "التاريخ",
      time: "الوقت",
      createdAt: "تم الإنشاء في",
      dateCreated: "تاريخ الإنشاء",
      dateSelection: "اختيار التاريخ",
      schedule: "العمل خلال الفترات المجدولة",
    },
    time: {
      hour: "ساعة",
      minute: "دقيقة",
      second: "ثانية",
      from: "من",
      to: "إلى",
      since: "منذ البداية",
      min: "د",
      shortVersion: {
        hour: "س",
        minute: "د",
        second: "ث",
      },
    },
    days: {
      days: "الأيام",
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wednesday: "الأربعاء",
      thursday: "الخميس",
      friday: "الجمعة",
      saturday: "السبت",
      sunday: "الأحد",
      everyday: "كل يوم",
      everyWeekday: "كل يوم عمل",
      weekend: "نهاية الأسبوع",
    },
  },
  navbarRoutes: {
    magicOrder: "الطلب السحري",
    aiSearch: "بحث الذكاء الاصطناعي",
    account: "الحساب",
    order: "الطلب",
    statistics: "الإحصائيات",
    dashboard: "لوحة التحكم",
    salesStatistics: "إحصائيات المبيعات",
    orderStatistics: "إحصائيات الطلبات",
    allOrders: "جميع الطلبات",
    menuStatistics: "إحصائيات القائمة",
    allItems: "جميع العناصر",
    feedbacks: "التعليقات",
    staff: "الطاقم",
    weeklyStatistics: "إحصائيات أسبوعية",
    tables: "الطاولات",
    menu: "القائمة",
    business: "الأعمال",
    infra: "البنية التحتية",
    users: "المستخدمون",
    qrAndTable: "QR والطاولة",
    table: "طاولة",
    qr: "QR",
    reservation: "الحجز",
    devices: "الأجهزة",
    map: "الخريطة",
    orders: "الطلبات",
    departments: "الأقسام",
    superAdmin: "المشرف الأعلى",
    inventory: "المخزون",
    funZone: "منطقة المرح",
    home: "الرئيسية",
    guests: "الضيوف",
    payments: "المدفوعات",
    profile: "ملفي الشخصي",
    ipBlacklist: "القائمة السوداء لعناوين IP",
    pageTitles: {
      superAdmin: "المشرف الأعلى",
      infra: "البنية التحتية",
      guests: "الضيوف",
      weeklyStatistics: "الإحصائيات الأسبوعية",
      salesStatistics: "إحصائيات المبيعات",
      orderStatistics: "إحصائيات الطلبات",
      menuStatistics: "إحصائيات القائمة",
      allOrders: "جميع الطلبات",
      departments: "الأقسام",
      allItems: "جميع العناصر",
      feedbacks: "التعليقات",
      category: "الفئة",
      settings: "الإعدادات",
      business: "الأعمال",
      users: "المستخدمون",
      zones: "المناطق",
      tables: "الطاولات",
      qrList: "قائمة QR",
      integrations: "التكاملات",
      payments: "المدفوعات",
      telegramIntegration: "تكامل تيليجرام",
    },
  },
  emptyTable: {
    emptyTableDescription: "ليس لديك أي مناطق حتى الآن.",
    emptyZoneDescription: "لا توجد طاولات في هذه المنطقة.",
    emptyQR: "ليس لديك أي رموز QR حتى الآن.",
    emptyZoneTitle: "لا توجد طاولات على الخريطة",
  },
  language: {
    language: "اللغة",
    english: "الإنجليزية",
    russian: "الروسية",
    azerbaijani: "الأذربيجانية",
    german: "الألمانية",
  },

  buttons: {
    revive: "إحياء",
    addItem: "إضافة عنصر",
    editItem: "تعديل العنصر",
    addQR: "إضافة QR",
    resetData: "إعادة التعيين",
    back: "عودة",
    createQR: "إنشاء QR",
    createZone: "إنشاء منطقة",
    createOrder: "إنشاء طلب",
    edit: "تعديل",
    delete: "حذف",
    confirm: "تأكيد",
    finish: "إنهاء",
    discard: "التجاهل",
    change: "تغيير",
    deny: "رفض",
    save: "حفظ",
    close: "إغلاق",
    saveChanges: "حفظ التغييرات",
    all: "الكل",
    selectAll: "تحديد الكل",
    seeAll: "رؤية الكل",
    seeLess: "رؤية أقل",
    add: "إضافة",
    addNew: "إضافة جديد",
    reset: "مسح",
    resend: "إعادة إرسال",
    clearFilters: "مسح الفلاتر",
    pay: "دفع",
    clear: "مسح الكل",
    apply: "تطبيق",
    defaultLanguage: "اللغة الافتراضية",
    defaultCurrency: "العملة الافتراضية",
    availableLanguages: "اللغات المتاحة",
    currency: "العملة الافتراضية",
    askAi: "اسأل الذكاء الاصطناعي",
    link: "رابط",
    links: "روابط",
    unlink: "فصل الرابط",
    addBasket: "إضافة إلى السلة",
    addToOrder: "إضافة إلى الطلب",
    clickToAdd: "انقر للإضافة",
    clickToAddImage: "انقر لإضافة صورة",
    clickToUpload: "انقر لتحميل",
    logOut: "تسجيل الخروج",
    login: "تسجيل الدخول",
    lockScreen: "قفل الشاشة",
    goToDashboard: "الذهاب إلى لوحة التحكم",
    rememberMe: "تذكرني",
    forgotPassword: "نسيت كلمة المرور؟",
    payWith: "الدفع باستخدام",
    seeMenu: "رؤية القائمة",
    addOrder: "طلب جديد",
    readMore: "قراءة المزيد",
    serve: "تقديم",
    confirmOrder: "تأكيد الطلب",
    backToMenu: "الرجوع إلى القائمة",
    send: "إرسال",
    tryAgain: "حاول مرة أخرى",
    update: "تحديث",
    continue: "استمرار",
    verify: "تحقق",
    applyDiscount: "تطبيق الخصم",
    discountProducts: "منتجات مخفضة",
    print: "طباعة",
    export: "تصدير",
    closeOrder: "إغلاق الطلب",
    printBill: "طباعة الفاتورة",
    fullTables: "طاولات ممتلئة",
    editForModal: "تعديل {{title}}",
    addForModal: "إضافة {{title}}",
    successfullySent: "تم الإرسال بنجاح",
    sent: "تم الإرسال",
    reserve: "احجز",
    skip: "تخطي",
    source: "المصدر",
    target: "الهدف",
    editZone: "تعديل المنطقة",
    contactUs: "اتصل بنا",
  },
  inputs: {
    name: "الاسم",
    username: "اسم المستخدم",
    description: "الوصف",
    example: "مثال",
    price: "السعر",
    discount: "الخصم",
    role: "الدور",
    phoneNumber: "رقم الهاتف",
    pinCode: "رمز التعريف الشخصي",
    pinCodeConfirm: "تأكيد رمز التعريف الشخصي",
    firstName: "الاسم الأول",
    lastName: "الاسم الأخير",
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    confirmPassword: "تأكيد كلمة المرور",
    newPassword: "كلمة مرور جديدة",
    oldPassword: "كلمة مرور قديمة",
    businessName: "اسم العمل",
    city: "المدينة",
    zipCode: "الرمز البريدي",
    street: "الشارع",
    map: "رابط خرائط جوجل",
    serviceFee: "رسوم الخدمة",
    search: "بحث",
    enterPromoCode: "أدخل رمز الترويج",
    link: "إضافة رابط",
    instagram: "رابط إنستجرام",
    phone: "إضافة هاتف",
    contactPhone: "رقم الهاتف",
    whatsapp: "رقم واتساب",
    mail: "إضافة عنوان بريد إلكتروني",
    contactMail: "عنوان البريد الإلكتروني",
    tableName: "اسم الطاولة",
    usernameExample: "@مثال",
    chooseCurrent: "اختر الحالي",
    seats: "المقاعد",
    minDeposit: "الحد الأدنى للإيداع",
    qrName: "اسم QR",
    wifiName: "اسم الواي فاي",
    sell: "سعر البيع",
    cost: "التكلفة",
    calories: "السعرات الحرارية",
    time: "الوقت",
    amount: "الكمية",
    unit: "الوحدة",
    addComment: "إضافة تعليق",
    warningAmount: "كمية التحذير",
    number: "العدد",
    notFixed: "غير ثابت",
    fixed: "ثابت",
    maxNumber: "العدد الأقصى",
    deposit: "الإيداع",
    yourName: "اسمك",
    contact: "الاتصال",
    reserveeName: "اسم المحجوز",
    numberOfGuest: "عدد الضيوف",
    date: "التاريخ",
    duration: "المدة",
    comment: "تعليق...",
    commentText: "تعليق",
    fullName: "الاسم الكامل",
    dateOfBirth: "تاريخ الميلاد",
  },
  image: {
    images: "الصور",
    selectCover: "انقر على الصورة لتعيينها كصورة غلاف",
    background: "صورة الخلفية",
    other: "صور أخرى",
    addPhoto: "إضافة صورة",
    addImage: "إضافة صورة",
    formats: "SVG، PNG، JPEG",
  },
  modification: {
    modificationName: "اسم التعديل",
    modification: "تعديل",
    modifications: "التعديلات",
    noModifications: "لم تتم إضافة أي تعديلات حتى الآن.",
    noModificationOption: "لم تتم إضافة أي خيارات بعد.",
    addModification: "إضافة تعديل",
    editModification: "تعديل التعديل",
    selection: {
      selection: "الاختيار",
      types: "نوع الاختيار",
      singleSelection: "اختيار واحد",
      singleSelectionDescription: "يمكن اختيار خيار واحد فقط",
      multiSelection: "اختيارات متعددة",
      multiSelectionDescription: "يمكن اختيار عدة خيارات",
      option: {
        options: "الخيارات",
        onlyOnce: "مرة واحدة فقط",
        manyTimes: "عدة مرات",
        default: "الاختيار الافتراضي",
        addLanguage: "إضافة لغة",
      },
    },
  },

  user: {
    staff: "الموظفون",
    employee: "الموظف",
    addEmployee: "إضافة موظف",
    editEmployee: "تعديل الموظف",
    sendAllDetails: "إنشاء كلمة مرور جديدة وإرسالها للمستخدم",
    userDeleteError: "لا يمكنك حذف الحساب الذي قمت بتسجيل الدخول منه",
    generatePassword: "إنشاء كلمة مرور جديدة وإرسالها للمستخدم.",
    changeYourPassword: "قم بتغيير كلمة مرورك",
  },
  guests: {
    guest: "ضيف",
    guests: "ضيوف",
    registeredGuest: "مسجل",
    unregisteredGuest: "غير مسجل",
    allBusinesses: "جميع الأعمال",
  },
  table: {
    connected: "متصل عبر QR",
    tablesLowercase: "طاولات",
    tables: "طاولات",
    table: "طاولة",
    addTable: "إضافة طاولة",
    editTable: "تعديل الطاولة",
    selectTable: "اختيار الطاولة",
    busy: "الطاولات مشغولة",
    available: "الطاولات متاحة",
    full: "ممتلئ",
    noTablesVisible: "لا توجد طاولات مرئية على الخريطة حاليا.",
    map: "خريطة",
    current: "الحالي",
  },
  zone: {
    zone: "منطقة",
    tables: "طاولات",
    zones: "مناطق",
    name: "اسم المنطقة",
    allZones: "جميع المناطق",
    selectZone: "اختيار المنطقة",
  },
  map: {
    removeFromMap: "إزالة من الخريطة",
    shapes: {
      rectangle: "مستطيل",
      circle: "دائرة",
    },
    createTable: "إنشاء طاولة",
    addToMap: "إضافة إلى الخريطة",
    windowAndDoor: "نافذة وباب",
    designElements: "عناصر التصميم",
    decoration: "ديكور",
    reset: "إعادة تعيين",
    width: "العرض",
    height: "الارتفاع",
  },
  sort: {
    az: "من الألف إلى الياء",
    cheapToExpensive: "من الرخيص إلى الغالي",
    expensiveToCheap: "من الغالي إلى الرخيص",
    new: "جديد",
  },
  qr: {
    qr: "QR",
    qrCode: "رمز QR",
    name: "اسم QR",
    list: "قائمة QR",
    id: "معرف QR",
    type: "نوع رمز QR",
    status: "الحالة",
    unlinked: "غير مرتبط",
    linked: "مرتبط",
    qrIsNotAdded: "لم تتم إضافة QR",
    connected: "متصل",
    notConnected: "غير متصل",
    scanToConnect: "أو امسح للاتصال",
    QRLinkCopied: "تم نسخ رابط QR",
    qrScan: "امسح هذا QR",
    scanCount: "عدد المسح",
    qrScanDescription:
      "* امسح من هاتف المستخدم المحمول وتأكد من تثبيت تطبيق Telegram",
    types: {
      qrType: "نوع QR",
      wifi: "واي فاي",
      menu: "قائمة",
    },
    downloadAllQr: "تحميل جميع QR",
    downloadQr: "تحميل رمز QR",
    generateQR: "إنشاء رمز QR",
    custom: "مخصص",
    forEachCategory: "لكل فئة",
    params: "المعلمات",
  },
  modal: {
    yesIConfirmMyOrderAndTakeResponsibilityToPay:
      "نعم، أؤكد طلبي وأتحمل المسؤولية عن الدفع.",
    areYouSureYouWantToPlaceThisOrder: "هل أنت متأكد أنك تريد وضع هذا الطلب؟",
    deleteModalTitle: "هل أنت متأكد من الحذف؟",
    deleteMenuItemTitle: "هل أنت متأكد من حذف هذه المنتجات؟",
    deleteModalDescription: "لا يمكن التراجع عن هذا الإجراء",
    warningModalTitleFinishOrder: "هل تريد إنهاء هذا الطلب؟",
    accessDenied: "الدخول مرفوض",
    permissionRequired: "إذن مطلوب",
    confirmModalTitleLogOut: "هل أنت متأكد من تسجيل الخروج؟",
    confirmModalDescriptionLogOut: "يمكنك تسجيل الدخول في أي وقت",
    warningModalTitleChangeZone: "هل تريد تغيير هذه المنطقة؟",
    warningModalTitleLogout: "هل تريد الخروج؟",
    warningModalTitleNewOrder:
      "هل تريد إنشاء طلب جديد على {{zone}}/{{table}}؟ هل أنت متأكد؟",
    warningModalDescriptionNewOrder: "لإنشاء طلب جديد، يرجى الضغط على نعم",
    warningModalTitleUnsavedChanges: "هل تريد ترك التغييرات غير المحفوظة؟",
    warningModalDescription: "لن يتم حفظ تعديلاتك",
    yes: "نعم",
    no: "لا",
    close: "إغلاق",
    apply: "تطبيق",
    id: "معرف",
    preview: "معاينة",
    cannotBeRemoved: "لا يمكن حذف المنتجات المؤكدة",
    unConfirmedCannotBeRemoved: "لا يمكن دفع المنتجات غير المؤكدة",
    payMenuItemTitle: "هل أنت متأكد من دفع هذه الخيارات؟",
    guestProfile: "ملف الضيف",
    totalVisits: "إجمالي الزيارات",
    averageAmount: "المبلغ المتوسط",
    topOrder: "أفضل {{count}} طلب",
    createdBy: "تم الإنشاء بواسطة",
    paymentMethod: "طريقة الدفع",
    orderId: "معرف الطلب",
    transactionId: "معرف المعاملة",
    visitHistory: "تاريخ الزيارة",
    emptyOrderInfo:
      "لا يوجد طلب حاليًا. يرجى مسح رمز QR مرة أخرى أو الاتصال بالنادل.",
  },
  menu: {
    preview: "معاينة القائمة",
    search: "ابحث عن طعامك المفضل...",
    translate: {
      ai: "الترجمة السحرية",
      translating: "يتم الترجمة...",
      aiAutoTranslate: "الترجمة التلقائية AI",
      languageSelection: "اختيار اللغة",
      successfulTranslate: "تم الترجمة بنجاح",
      waitingTranslate:
        "قد يستغرق الذكاء الاصطناعي بضع دقائق لترجمة القائمة. يرجى ملاحظة أنه لا يمكنك تحرير عناصر القائمة خلال هذه الفترة.",
      magicTranslate: "ترجمة",
      itemErrorMessage:
        "هذه المنتج غير موجود في اللغة الأصلية. لإجراء الترجمة، يرجى إضافة المنتج باللغة الأصلية.",
      categoryErrorMessage:
        "هذه الفئة غير موجودة في اللغة الأصلية. لإجراء الترجمة، يرجى إضافة الفئة باللغة الأصلية.",
    },
    tag: {
      tag: "علامة",
      tags: "علامات",
      name: "اسم العلامة",
    },
    category: {
      category: "الفئات",
      categories: "الفئات",
      add: "إضافة فئة",
      all: "جميع الفئات",
      name: "اسم الفئة",
      mealCategories: "فئات الطعام",
      emptyGuestMenuTitle: "القائمة فارغة",
      emptyGuestMenuDescription:
        "يبدو أن الأعمال لم تضف أي شيء بعد إلى القائمة",
      emptyAdminCategoryDescription: "لا توجد فئات لديك بعد.",
    },
    item: {
      items: "منتجات",
      item: "منتج",
      all: "جميع المنتجات",
      itemLowerCase: "منتج",
      itemsLowerCase: "منتجات",
      selected: "المنتجات المختارة",
      deleteItem: "حذف المنتج",
      updateItem: "تحديث المنتج",
      duplicateItem: "نسخ المنتج",
      duplicate: "نسخ",
      scheduleDiscount: "جدولة الخصم",
      emptyAdminMenuItemDescription: "لا توجد منتجات لديك بعد.",
      itemDeleteError: "لا يمكنك حذف هذا المنتج لأنه موجود في طلب نشط.",
    },
    view: {
      selection: "العرض الافتراضي للقائمة",
      scroll: "تمرير الصفحة",
      withImage: "مع صورة",
      withoutImage: "بدون صورة",
      list: "قائمة",
      threeD: "ثلاثي الأبعاد",
      poker: "بوكر",
      grid: "بانوراما",
    },
    filter: {
      filters: "مرشحات",
      priceRange: "نطاق السعر",
      noResult: "لا توجد نتائج مطابقة",
      noResultDesc: "تقوم المرشحات النشطة بإخفاء جميع عناصر القائمة.",
    },
    settings: {
      color: "اللون",
      radius: "نصف القطر",
      roundness: "التدوير",
      currency: "العملة",
      colors: {
        primary: "الألوان الأساسية",
        red: "أحمر",
        gold: "ذهب",
        purple: "أرجواني",
        blue: "أزرق",
      },
      curves: {
        elementCurves: "منحنيات العناصر",
        none: "لا شيء",
        small: "صغير",
        medium: "متوسط",
        large: "كبير",
      },
      changeProperties: "تغيير الخصائص",
      details: "تفاصيل القائمة",
    },
    viewAr: "عرض الواقع المعزز",
  },
  menuV2: {
    selectLanguage: "اختر اللغة",
  },
  business: {
    businesses: "الأعمال",
    list: "قائمة الأعمال",
    staffList: "{{count}} موظف",
    selectTitle: "اختار العمل",
    selectSubTitle: "يرجى اختيار العمل الذي ترغب في الدخول إليه ومتابعة",
    contact: "التواصل",
    contactWith: "التواصل مع",
    workingHours: "ساعات العمل",
    needToKnow: "يجب أن تعرف",
    addNew: "إضافة عمل",
    edit: "تعديل العمل",
    plan: "الخطة",
    selectPlan: "اختيار الخطة",
    creationDate: "تاريخ الإنشاء",
    status: "الحالة",
    verified: "تم التحقق",
    unverified: "لم يتم التحقق",
    businessStatus: "حالة العمل",
    expirationDate: "تاريخ الانتهاء",
    country: "الدولة",
    printers: "الطابعات",
    wiFi: "الواي فاي",
    printer: "طابعة",
    active: "نشط",
    inActive: "غير نشط",
    connectedTo: "متصل بـ",
    guestBusinessBlockTitle: "هذا العمل غير نشط.",
    guestBusinessBlockSubTitle: "نأسف، هذا العمل لم يعد متاحًا.",
    settings: {
      goLive: "بث مباشر",
      chat: "الدردشة",
      realTimeOrder: "الطلبات في الوقت الفعلي",
      menuItemCalorie: "السعرات الحرارية",
      menuItemTime: "الوقت",
      menuItemAmount: "الكمية",
      reservation: "الحجز",
      reservationMap: "عرض الخريطة في الحجز",
      chatSound: "صوت الدردشة",
      orderSound: "صوت الطلب",
      askForBillSound: "صوت طلب الفاتورة",
      callWaiterSound: "صوت استدعاء النادل",
      isAskForBillEnabled: "تمكين طلب الفاتورة",
      isCallWaiterEnabled: "تمكين استدعاء النادل",
      menu: "القائمة",
      sound: "الصوت",
      general: "عام",
      isOnlinePaymentEnabled: "تمكين الدفع عبر الإنترنت",
      order: "الطلب",
      action: "الإجراءات",
      showInBusinessCatalogue: "عرض في دليل الأعمال",
      reservationVRLink: "إضافة رابط افتراضي",
      reservationVREnabled: "عرض افتراضي",
      reservationMapEnabled: "تمكين عرض الخريطة",
      permission: "الأذونات",
      declineOrderItemsEnabled: "تمكين رفض عناصر الطلب",
      deleteOrderEnabled: "تمكين حذف الطلب",
      deleteMenuItemsEnabled: "تمكين حذف عناصر القائمة",
      editMenuEnabled: "تمكين تعديل القائمة",
      virtualTour: "جولة افتراضية",
      vrLink: "إضافة رابط افتراضي",
      vrLinkEnabled: "عرض افتراضي",
    },
  },
  orderItem: {
    denied: "مرفوض",
    deleted: "تم الحذف",
    empty: "فارغ",
  },
  basket: {
    basket: "السلة",
    favorites: "المفضلة",
    basketAndFavorites: "السلة والمفضلة",
    addMessage: "أضف رسالة للمطعم",
    messagePlaceholder: "طلب خاص، حساسية، قيود غذائية؟",
    itemAdded: "{{count}} عنصر تم إضافته إلى السلة",
    emptyFavorite: "لا توجد لديك مفضلات حتى الآن",
    added: "تمت الإضافة",
    order: {
      info: "معلومات الطلب",
      subtotal: "المجموع الفرعي",
      total: "الإجمالي",
      tax: "الضريبة",
      promoCode: "رمز الترويج",
      totalCost: "إجمالي التكلفة",
      orders: "الطلبات",
      myOrders: "طلباتي",
      ordersLowerCase: "الطلبات",
      orderLowerCase: "طلب",
      order: "طلب",
      bill: "الفاتورة",
      noOrder: "لا يوجد طلب متاح",
      noOrders: "لا توجد طلبات.",
      deletedOrderTitle: "تم حذف طلبك",
      deletedOrderDescription: "نأسف، تم حذف طلبك من قبل المسؤول/النادل",
      notifications: "الإشعارات",
      updatesToSave: "التغييرات",
      newOrder: "طلب جديد",
      deleteOrder: "حذف الطلب",
      finishOrder: "إنهاء الطلب",
      done: "تم",
      preparing: "جار التحضير",
      numberOfGuests: "عدد الضيوف",
      fullNessOfTable: "اكتمال الطاولة",
      acceptAll: "قبول الكل",
    },
    comment: {
      addMessage: "أضف رسائل للمطاعم",
      addMessageDesc: "طلب خاص، حساسية، قيود غذائية؟",
    },
    emptyBasketTitle: "سلتك فارغة",
    emptyBasketDescription: "يبدو أنك لم تضف أي شيء إلى سلتك بعد",
  },
  orders: {
    orders: "الطلبات",
    order: "طلب",
    guests: "الضيوف",
    guest: "ضيف",
    fullTables: "الطاولات الممتلئة",
  },
  funZone: {
    selectGame: "اختار اللعبة",
    playGame: "العب اللعبة",
    who: "من",
    paysTheBill: "يدفع الفاتورة؟",
    mindGame: "الذاكرة",
    canYouRemember: "هل تستطيع التذكر؟",
    tower: "البرج",
    buildYourHighestTower: "ابنِ أعلى برج لديك",
    ticTackToe: {
      title: "تيك تاك تو",
      player: "اللاعب",
      turnFor: "دور لـ {{player}}",
      win: "فاز",
      outsmartYourOpponent: "تفوق على خصمك!",
      draw: "تعادل",
      points: "النقاط",
    },
    wheelOfFortune: {
      spinnerGame: "لعبة العجلة",
      who: "من؟",
      whoWillPayTheBill: "من سيدفع الفاتورة؟",
      whoWillGoToTheMarket: "من سيذهب إلى السوق؟",
      whoWillBuyCigarettes: "من سيشتري السجائر؟",
      whoWillHaveAnotherShot: "من سيأخذ جرعة أخرى؟",
      whoWillGetDessert: "من سيحصل على الحلوى؟",
      whoWillChooseTheMusic: "من سيختار الموسيقى؟",
      whoWillSingKaraoke: "من سيغني الكاريوكي؟",
      start: "ابدأ",
      spinning: "الدوران...",
      editToPlay: "تحرير للعب",
      addNew: "أضف جديد",
      saveAndContinue: "حفظ ومتابعة",
      typeHere: "اكتب هنا",
      result: "النتيجة",
      youAreTheChosenOne: "أنت المختار",
    },
    memoryCardGame: {
      mindGame: "لعبة الذاكرة",
      restart: "إعادة تشغيل",
      winMessage: "فزت",
      loseMessage: "خسرت",
      secondsLeft: "ثوانٍ متبقية",
      triesLeft: "محاولات متبقية",
      startNewGame: "ابدأ لعبة جديدة",
    },
    towerGame: {
      blocks: "الكتل",
      clickToPlay: "انقر للعب",
      clickToRestart: "انقر لإعادة التشغيل",
    },
  },

  dashboard: {
    dashboard: "لوحة التحكم",
    startTalk: "ابدأ الدردشة",
    sales: "المبيعات",
    salesAnalytics: "إجمالي المبيعات / الساعة",
    weekStatistics: "إحصائيات الأسبوع",
    statistics: "الإحصائيات",
    totalSales: "إجمالي المبيعات",
    paymentMethodAnalysis: "تحليل طريقة الدفع",
    byNumberOfOrders: "حسب عدد الطلبات",
    byTheAmountOfSales: "حسب قيمة المبيعات",
    byTheNumberOfRatings: "حسب عدد التقييمات",
    bestselling: "الأكثر مبيعًا",
    worstSelling: "الأقل مبيعًا",
    processing: "جار المعالجة",
    billReady: "الفاتورة جاهزة",
    unSelect: "إلغاء تحديد الكل",
    selectToPay: "انقر للاختيار",
    denied: "مرفوض",
    showMore: "عرض المزيد",
    showLess: "...عرض أقل",
    bestSellingCategory: "أفضل فئة مبيعة",
    topPerformer: "الأداء الأعلى",
    lowPerformer: "الأداء الأدنى",
    noResultForThisPeriodMsg: "لا توجد نتائج لهذه الفترة",
    all: "الكل",
    yourTable: "طاولتك",
    totalGuests: "إجمالي الضيوف",
    totalItems: "إجمالي العناصر",
    deleted: "محذوف",
    weeklySaleStatistics: "إحصائيات مبيعات الأسبوع",
    weeklyOrderStatistics: "إحصائيات طلبات الأسبوع",
    paid: "مدفوع",
    inProgress: "قيد المعالجة",
    paymentInProgress: "الدفع قيد المعالجة",
    legends: {
      sale: "المبيعات",
      revenue: "الإيرادات",
      orders: "عدد الطلبات",
      orderItems: "عدد العناصر",
      totalSale: "إجمالي المبيعات",
      starCount: "عدد النجوم",
    },
    guest: {
      guests: "الضيوف",
      guest: "ضيف",
      me: "أنا",
      review: {
        meal: "الوجبة",
        service: "الخدمة",
        review: "التقييم",
        sent: "تم الإرسال",
        feedBack: "التعليقات",
      },
    },
    staffStatistics: {
      sales: "المبيعات",
      orders: "الطلبات",
      rating: "التقييم",
    },
    orderStatistics: {
      totalSale: "إجمالي المبيعات",
      revenue: "الإيرادات",
      averageBill: "متوسط الفاتورة",
      orderCount: "عدد الطلبات",
      guestsServed: "الضيوف الذين تم خدمتهم",
      averageTime: "الوقت المتوسط",
      from: "منذ الأمس",
      orders: "الطلبات",
      sale: "المبيعات",
      in: "داخلي",
      out: "خارجي",
    },
    feedbackStatistic: {
      service: "تعليقات الخدمة",
      meal: "تعليقات الوجبات",
      feedBackTitle: "كيف تشعر؟",
      feedBackDesc:
        "ملاحظاتك مهمة في مساعدتنا على فهم احتياجاتك بشكل أفضل وتخصيص خدمتنا وفقًا لها.",
      rating: {
        horrible: "رهيب",
        bad: "سيء",
        meh: "متوسط",
        good: "جيد",
        awesome: "رائع",
      },
    },
    table: {
      adminOrderHistoryTable: {
        staff: "الطاقم",
        dates: "التواريخ",
        item: "المنتج",
        subTotal: "الإجمالي الفرعي",
        discount: "الخصم",
        total: "الإجمالي",
        zone: "المنطقة",
        table: "الطاولة",
        service: "الخدمة",
        fee: "الرسوم",
        orderDetails: "تفاصيل الطلب",
        receipt: "الإيصال",
      },
      menuStatisticsTable: {
        menuItem: "عنصر القائمة",
        category: "الفئة",
        cost: "التكلفة",
        revenue: "الإيرادات",
        total: "الإجمالي",
        order: "الطلب",
        count: "العدد",
        price: "السعر",
        item: "المنتج",
      },
      businessGuestsTable: {
        name: "الاسم",
        gender: "الجنس",
        dateOfBirth: "تاريخ الميلاد",
        numberOfVisit: "عدد الزيارات",
        guestCount: "عدد الضيوف",
        pageSize: "حجم الصفحة",
      },
    },
    pagination: {
      prev: "السابق",
      next: "التالي",
    },
    prevNext: {
      previous: "السابق",
      next: "التالي",
    },
    placeholder: {
      zone: "المنطقة",
      orderId: "معرف الطلب",
      staff: "الطاقم",
      itemName: "اسم العنصر",
      categories: "الفئات",
    },
  },
  login: {
    or: "أو",
    facebook: "تسجيل الدخول باستخدام فيسبوك",
    google: "تسجيل الدخول باستخدام جوجل",
    pin: "رقم التعريف الشخصي",
    alreadyHaveAnAccount: "هل لديك حساب بالفعل؟",
    signIn: {
      title: "تسجيل الدخول",
      subTitle: "لا تمتلك حساب؟",
      method: "طريقة تسجيل الدخول",
    },
    signUp: {
      termsErrorMessage: "*للمتابعة، يجب قبول الشروط والأحكام.",
      title: "التسجيل",
      subTitleForUserExist:
        "أنت بالفعل تمتلك حساب. يرجى إدخال كلمة المرور الحالية.",
      subTitleForUserNotExist:
        "يرجى إدخال كلمة المرور المرسلة إلى: {{emailOrPhone}}",
      alreadyHaveAccount: "هل لديك حساب بالفعل؟",
      passwordSentTo: "تم إرسال كلمة المرور",
      setPinSubTitle: "حدد رقم تعريف شخصي للوصول الأسهل",
      agree: "أوافق على ما يلي:",
      terms: "الشروط والأحكام",
      privacy: "سياسة الخصوصية",
      enterPassword: "أدخل كلمة المرور",
      setPinSubtitle: "حدد رقم تعريف شخصي للوصول الأسهل",
    },
    forgotPassword: {
      title: "هل نسيت كلمة المرور؟",
      subTitle:
        "يرجى إدخال {{method}} الخاصة بك وسنرسل لك كلمة المرور مرة أخرى.",
      sendPassword: "إرسال كلمة المرور",
      phoneNumber: "رقم الهاتف",
      email: "البريد الإلكتروني",
    },
    resetPassword: {
      title: "تغيير كلمة المرور",
      subTitle: "مرحبًا بك، لقد افتقدناك!",
    },
    resendPassword: {
      title: "إعادة إرسال كلمة المرور",
      subTitleForUserExist: "هل نسيت كلمة المرور الحالية؟",
      subTitleForUserNotExist: "هل لم تتلق كلمة المرور؟",
      buttonTextForUserExist: "إرسال كلمة مرور جديدة",
      buttonTextForUserNotExist: "إعادة الإرسال",
      resendingIsAvailable: "إعادة الإرسال متاحة",
      resendingCodeWillBeAvailableIn:
        "سيكون رمز الإرسال متاحًا في الوقت التالي",
    },
  },
  payment: {
    receipt: "إيصال",
    payedBy: "تم الدفع بواسطة",
    onlinePayment: "الدفع عبر الإنترنت",
    paymentMessage: "تم الدفع بنجاح باستخدام {{card}}",
    paymentFailMessage: "لم يتم الدفع باستخدام {{card}}",
    payment: "الدفع",
    method: "طريقة الدفع",
    pageTitle: "كيف ترغب في الدفع؟",
    pageSubtitle: "اختر طريقة الدفع لإتمام العملية",
    cash: "نقدًا",
    POSTerminal: "نظام الدفع POS",
    ready: "جاهز في دقيقة!",
    confirmPOSTerminal:
      "تم تأكيد الطلب. سيتم إحضار جهاز POS قريبًا بواسطة النادل.",
    confirmCash: "تم تأكيد الطلب. سيتم إحضار الفاتورة قريبًا بواسطة النادل.",
    totalCostFor: "إجمالي التكلفة",
    printsAndBill: "إيصال وفاتورة",
    paymentConfirmedTitle: "تم إتمام طلبك. شكرًا لاختيارك لنا.",
    paymentConfirmedDescription:
      "كان من دواعي سرورنا استضافتك! نراكم في المرة القادمة 👋 نتمنى لك يومًا سعيدًا 😍",
    businessPaymentsTitle: {
      orderId: "رقم الطلب",
      paidPrice: "المبلغ المدفوع",
      currency: "العملة",
      paymentStatus: "حالة الدفع",
      lastFourDigits: "آخر 4 أرقام",
      cardClassification: "تصنيف البطاقة",
      cardHolderName: "اسم صاحب البطاقة",
    },
  },
  waiter: {
    waiter: "النادل",
    waiterLowercase: "نادل",
    call: "استدعاء النادل",
    bill: "طلب الفاتورة",
    quickActions: "الإجراءات السريعة",
    actions: "الإجراءات",
  },
  mascot: {
    about: "عن {{businessName}}",
    dashboard: {
      support: "الدعم والاختصارات",
      eatIn: "تناول الطعام داخل المكان",
      eatInHall: "قاعة تناول الطعام الداخلية",
      reserveTable: "حجز الطاولة",
      letUs: "دعنا نساعدك في ...",
      learnMore: "اقرأ المزيد",
      fixProblemText: "واجهت مشكلة في التطبيق؟",
      fixProblemButton: "اضغط هنا لحل المشكلة",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "يرجى اختيار طاولة لإنشاء طلب",
    selectWaiterToCreateOrder: "يرجى اختيار نادل لإنشاء طلب",
    lastLanguage: "لا يمكنك إزالة اللغة الأخيرة",
    orderAssignee: "يجب عليك اختيار مكلف بالطلب",
    updateFailed: "فشل في تحديث معلومات الضيف",
    required: {
      password: "كلمة المرور مطلوبة",
      confirmPassword: "تأكيد كلمة المرور مطلوب",
      customMessage: "{{name}} مطلوب",
      comment: "*التعليق مطلوب",
    },
    image: "فشل في تحميل الصورة",
    addUnit: "أضف وحدة",
    confirmOrders: "لديك عمل غير مكتمل. يرجى حفظه قبل المتابعة.",
    input: {
      maxCharacter: "*يرجى إدخال أقل من {{max}} حرفًا",
      minCharacter: "*يرجى إدخال أكثر من {{min}} حرفًا",
      maxNumber: "*يرجى إدخال رقم أقل من {{max}}",
      minNumber: "*يرجى إدخال رقم أكبر من 0",
      invalid: "*{{name}} غير صالح",
      required: "*{{name}} مطلوب",
      generalRequired: "*هذا الحقل مطلوب",
      menu: {
        fixedDiscount: "*لا يمكن أن يكون الخصم أكبر من سعر البيع",
        unFixedDiscount: "*لا يمكن أن يكون الخصم أكبر من 100%",
        requiredUnit: "*يرجى اختيار وحدة للمبلغ المحدد",
      },
      menuSettings: {
        requiredDefaultLanguage: "*يرجى اختيار لغة افتراضية",
        requiredAvailableLanguages: "*يرجى اختيار لغة واحدة على الأقل",
      },
      pinCode: {
        maxDigits: "*يجب أن يكون رمز التعريف الشخصي أقل من 6 أرقام",
        alreadyExists: "*رمز التعريف الشخصي هذا موجود بالفعل",
        notNumeric: "*رمز التعريف الشخصي يجب أن يحتوي فقط على أرقام",
        skipOrSetNow: "*حدد رمز تعريف شخصي الآن، أو تخطى هذه الخطوة",
        notMatch: "*رموز التعريف الشخصي غير متطابقة",
        incorrect: "*رمز التعريف الشخصي غير صحيح",
        TryAgainLater: "لقد تخطيت الحد، يرجى المحاولة لاحقًا.",
      },
      email: {
        alreadyExists: "*البريد الإلكتروني موجود بالفعل",
        incorrect: "*يرجى إدخال عنوان بريد إلكتروني صالح",
        notExists: "*البريد الإلكتروني غير موجود",
        TryAgainLater: "*لقد تخطيت الحد، يرجى المحاولة لاحقًا.",
      },
      password: {
        incorrect: "*كلمة المرور غير صحيحة",
        notNumeric: "*يجب أن تحتوي كلمة المرور فقط على أرقام",
        incorrectLength: "*يجب أن تكون كلمة المرور 6 أرقام",
      },
      phoneNumber: {
        incorrectLength: "*يجب أن يتكون رقم الهاتف من 12 رقمًا",
        notNumeric: "*يجب أن يحتوي رقم الهاتف على أرقام فقط",
        alreadyExists: "*رقم الهاتف هذا موجود بالفعل",
        notExists: "*رقم الهاتف هذا غير موجود",
        incorrect: "*يرجى إدخال رقم هاتف صالح",
        TryAgainLater: "*لقد تخطيت الحد، يرجى المحاولة لاحقًا.",
      },
      business: {
        serviceFeeMax: "*يجب أن تكون الرسوم الخدمية أقل من 100%",
        serviceFeeMin: "*يجب أن تكون الرسوم الخدمية أكبر من 0%",
        url: "*يرجى إدخال عنوان URL صالح",
      },
      qrAndTable: {
        maxSeat: "يجب أن يكون عدد المقاعد عددًا صحيحًا",
      },
      reservation: {
        maxGuestCount: "يجب أن يكون عدد الضيوف عددًا صحيحًا",
      },
      contact: {
        instagram: "*يرجى إدخال عنوان إنستغرام صالح",
      },
      otpCode: {
        OtpIsWrong: "*رمز OTP غير صحيح",
        OtpIsNotVerified: "رمز OTP غير مُفعل",
        sendOtp: "تم إرسال الرمز إلى: ",
      },
      oldPassword: {
        OldPasswordIsWrong: "*كلمة المرور الحالية غير صحيحة",
      },
    },
  },

  toastMessages: {
    success: {
      login: "تم تسجيل الدخول بنجاح!",
      updateBusinessProfile: "تم تحديث ملف الأعمال بنجاح",
      updateBusinessSettings: "تم تحديث إعدادات الأعمال بنجاح",
      updateTag: "تم تحديث الوسم بنجاح",
      createTag: "تم إنشاء الوسم بنجاح",
      deleteTag: "تم حذف الوسم بنجاح",
      createReservation: "تم إنشاء الحجز بنجاح",
      deleteReservation: "تم حذف الحجز بنجاح",
      updateReservation: "تم تحديث الحجز بنجاح",
      updateCategory: "تم تحديث الفئة بنجاح",
      createCategory: "تم إنشاء الفئة بنجاح",
      deleteCategory: "تم حذف الفئة بنجاح",
      updateMenuItem: "تم تحديث العنصر في القائمة بنجاح",
      updateMenu: "تم تحديث القائمة بنجاح",
      createMenuItem: "تم إنشاء العنصر في القائمة بنجاح",
      deleteMenuItem: "تم حذف العنصر في القائمة بنجاح",
      duplicateMenuItem: "تم تكرار العنصر في القائمة بنجاح",
      updateZone: "تم تحديث المنطقة بنجاح",
      createZone: "تم إنشاء المنطقة بنجاح",
      deleteZone: "تم حذف المنطقة بنجاح",
      updateTable: "تم تحديث الطاولة بنجاح",
      updateMenuSettings: "تم تحديث إعدادات القائمة بنجاح",
      createTable: "تم إنشاء الطاولة بنجاح",
      deleteTable: "تم حذف الطاولة بنجاح",
      updateUser: "تم تحديث المستخدم بنجاح",
      createUser: "تم إنشاء المستخدم بنجاح",
      deleteUser: "تم حذف المستخدم بنجاح",
      updateQR: "تم تحديث الرمز الشريطي بنجاح",
      createQR: "تم إنشاء الرمز الشريطي بنجاح",
      deleteQR: "تم حذف الرمز الشريطي بنجاح",
      updateOrder: "تم تحديث الطلب بنجاح",
      createOrder: "تم إنشاء الطلب بنجاح",
      deleteOrder: "تم حذف الطلب بنجاح",
      finishedOrder: "تم إنهاء الطلب بنجاح",
      updateInventoryCategory: "تم تحديث فئة المخزون بنجاح",
      createInventoryCategory: "تم إنشاء فئة المخزون بنجاح",
      deleteInventoryCategory: "تم حذف فئة المخزون بنجاح",
      updateInventoryItem: "تم تحديث عنصر المخزون بنجاح",
      createInventoryItem: "تم إنشاء عنصر المخزون بنجاح",
      deleteInventoryItem: "تم حذف عنصر المخزون بنجاح",
      updateBusiness: "تم تحديث الأعمال بنجاح",
      createBusiness: "تم إنشاء الأعمال بنجاح",
      deleteBusiness: "تم حذف الأعمال بنجاح",
      updateLanguage: "تم تغيير اللغة بنجاح",
      deleteOrderHistory: "تم حذف سجل الطلبات بنجاح",
      resetPassword: "تم إعادة تعيين كلمة المرور بنجاح",
    },
    error: {
      common: "حدث خطأ ما! يرجى المحاولة مرة أخرى",
      zones: "لا يمكنك حذف هذه المنطقة لأنها تحتوي على طاولة",
      unselectedTable: "يجب عليك اختيار طاولة",
      unselectedZone: "يجب عليك اختيار منطقة",
      deleteCategory: "لا يمكنك حذف هذه الفئة لأنها تحتوي على عنصر",
    },
    warning: {
      socials: "قيد التطوير حاليًا، شكرًا لتفهمكم.",
    },
  },
  reservation: {
    reserve: "احجز",
    reservationTime: "وقت الحجز",
    reservationInfo: "معلومات الحجز",
    contactInfo: "معلومات الاتصال",
    enterName: "الاسم",
    enterPhoneNumber: "رقم الهاتف",
    bookingList: "قائمة الحجز",
    booked: "محجوز",
    pending: "معلق",
    createReservation: "إنشاء حجز",
    editReservation: "تحرير الحجز",
    waiting: "انتظار",
    today: "اليوم",
    guestNumber: "عدد الضيوف",
    waitingTime: "وقت الطلب",
    startDate: "تاريخ البدء",
    ReservationDuration: "مدة الحجز",
    comment: "تعليق",
    numberOfGuest: "عدد الضيوف",
    startTime: "وقت البدء",
    duration: "المدة",
    confirmed: "مؤكد",
    denied: "مرفوض",
    delete: "حذف",
    update: "تحديث",
    searchRestaurant: "بحث في المطعم",
    tableNotFound: "لم يتم اختيار طاولة",
    showMore: "عرض المزيد",
    showLess: "عرض أقل",
    reservations: "الحجوزات",
    open: "مفتوح",
    seeMenu: "شاهد القائمة",
    reservation: "حجز",
    contact: "اتصال",
    place: "المكان",
    reserveeName: "اسم الحاجز",
    date: "التاريخ",
    time: "الوقت",
    reservationStatus: "حالة الحجز",
    reservationDurationHelpText:
      "ملاحظة: إذا لم تختار المدة، سيتم تعيينها تلقائيًا لمدة ساعة واحدة.",
    justNow: "الآن",
    oneDay: "يوم واحد",
    day: "يوم",
    hour: "ساعة",
    minute: "دقيقة",
    selectedTable: "الطاولة المحددة",
    confirmation: "تأكيد ومتابعة",
    chooseRestaurant: "اختار المطعم",
    setDate: "متى ستزورنا؟",
    chooseTable: "هل لديك طاولة مفضلة؟",
    addContact: "من سيزور؟",
    vrLinkCopied: "تم نسخ رابط الواقع الافتراضي",

    statuses: {
      PENDING: "معلق",
      CONFIRMED: "مؤكد",
      DENIED: "ملغي",
      DELETED: "تم الحذف",
    },
    vr: {
      browserDoesNotSupport: "متصفحك لا يدعم علامة الفيديو.",
      vrTourReservation: "حجز جولة الواقع الافتراضي",
      explore: "استكشاف واختيار مكانك!",
      vrView: "عرض الواقع الافتراضي",
      explorevr: "استكشف مكاننا",
    },
    map: {
      chooseTable: "اختار الطاولة",
      mapReservation: "حجز على الخريطة",
      chooseLocation: "اختر مكان الطاولة!",
      tables: "الطاولات",
    },
    sentSuccessfully: "تم إرسال الطلب بنجاح",
    successMessage:
      "سيتم إرسال التحديثات إلى رقمك، ويمكنك التحقق من حالتك في صفحة الحجز",
  },
  socials: {
    instagram: "إنستغرام",
    email: "البريد الإلكتروني",
    whatsapp: "واتساب",
  },
  months: {
    january: "يناير",
    february: "فبراير",
    march: "مارس",
    april: "أبريل",
    may: "مايو",
    june: "يونيو",
    july: "يوليو",
    august: "أغسطس",
    september: "سبتمبر",
    october: "أكتوبر",
    november: "نوفمبر",
    december: "ديسمبر",
  },
  weekdays: {
    monday: "الإثنين",
    tuesday: "الثلاثاء",
    wednesday: "الأربعاء",
    thursday: "الخميس",
    friday: "الجمعة",
    saturday: "السبت",
    sunday: "الأحد",
  },
  periods: {
    hourly: "كل ساعة",
    daily: "يوميًا",
    weekly: "أسبوعيًا",
    monthly: "شهريًا",
  },

  chat: {
    typeHere: "اكتب هنا...",
    you: "أنت",
    noMessages: "لا توجد رسائل بعد. أرسل الرسالة الأولى لبدء المحادثة!",
    chat: "الدردشة",
    allMessages: "جميع الرسائل",
    unreadMessages: "رسائل غير مقروءة",
    selectTopic: "يرجى اختيار الموضوع.",
  },
  kitchen: {
    stageNew: "جديد",
    stageProgress: "قيد التحضير",
    stageDone: "تم",
  },
  inventory: {
    productList: "قائمة المنتجات",
    product: "منتج",
    stock: "المخزون",
    warningAmountInfo:
      "اختر الحد الأدنى للكميات لإشعارك عندما يوشك المنتج على الانتهاء",
    productname: "اسم المنتج",
  },
  emptyStates: {
    loading: "جاري التحميل...",
    noPrinters: "لا توجد طابعات مضافة بعد",
    noWiFi: "لا يوجد واي فاي مضاف بعد.",
    noOption: "لا توجد خيارات",
    noOrder: "لا توجد طلبات بعد",
    noItem: "لا توجد عناصر بعد",
    noLanguage: "لا توجد لغات لإضافتها",
    noTableData: "لا توجد بيانات في هذه الصفحة",
    noOrderedCategory: "لا توجد نتائج لطلب عناصر في هذه الفئة",
    noChat: "لا توجد محادثات لديك بسبب عدم وجود طلبات",
    noUnread: "لا توجد رسائل غير مقروءة.",
    noSearchResults: "لا توجد نتائج بحث",
    noOrders: "لا توجد طلبات",
    noGuests: "لا توجد ضيوف",
    noPendingReservations: "لا توجد حجوزات معلقة.",
    noReservationDescription: "لا توجد حجوزات لديك بعد",
    noReservations: "لا توجد حجوزات",
    notOpenForReservations: "هذا المطعم غير مفتوح للحجوزات.",
    noBusiness: "لا يوجد أعمال",
    noBusinessContent: "لا توجد معلومات إضافية حول الأعمال.",
    noBusinessDescription: "للأسف، جميع حجوزات المطاعم مغلقة.",
  },
  info: {
    itemAmount:
      "العدد يشير إلى كمية العنصر، المحددة بوحدات مثل الكيلوغرام (كغم)، والغرام (غ)، واللتر (ل)، أو المليلتر (مل).",
    itemModificationOptionMaxNumberInfo:
      "حد التعديل الأقصى يشير إلى أقصى عدد من الخيارات التي يمكن تطبيقها.",
    general: "المعلومات العامة",
    detail: "المعلومات التفصيلية",
  },
  stepper: {
    signupMethod: "طريقة التسجيل",
    otpVerification: "التحقق من OTP",
    userDetail: "تفاصيل المستخدم",
    passwordVerification: "التحقق من كلمة المرور",
    setPinCode: "رمز التعريف الشخصي",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "الشروط والأحكام",
    privacyPolicy: "سياسة الخصوصية",
    allRightReserved: "جميع الحقوق محفوظة.",
    copyRight: "© {{year}} {{value}}.",
    joinUs: "انضم إلينا {{emoji}}",
    madeWith: "صُنع مع {{emoji}} بواسطة",
    madeBy: "صُنع بواسطة <strong>iLoyal</strong>",
    scanQR: "امسح الرمز الشريطي للطلب الجديد",
    name: "دع أصدقائك يعرفون أنك أنت!",
    profilePicture: "صورة الملف الشخصي",
    comingSoon: "قريبًا",
    underConstruction: "تحت الإنشاء",
  },
  optionTypes: {
    None: "لا شيء",
    S: "ص",
    M: "م",
    L: "ل",
  },
  businesses: {
    allBusinesses: "جميع الأعمال",
    specialOffers: "عروض خاصة",
    mostPopular: "الأكثر شعبية",
    seaside: "على البحر",
    coffeeShop: "مقاهي",
    nationalCuisine: "المأكولات الوطنية",
    nationalCuisineExtended: "المأكولات الوطنية الممتدة",
    information: "معلومات",
    search: "بحث...",
    seeAll: "شاهد الكل",
    seeLess: "شاهد أقل",
  },
  policy: {
    termsDataInfo:
      "بالاستمرار، فإنك توافق على <strong>شروط الخدمة وسياسة الخصوصية</strong>",
    joinUsTermsData: {
      introduction: {
        title: "1. المقدمة",
        content: {
          point1:
            "باستخدام خدماتنا، فإنك توافق على شروط الخدمة الخاصة بنا وسياسة الخصوصية. يرجى قراءتها بعناية.",
        },
      },
      userConsent: {
        title: "2. موافقة المستخدم",
        content: {
          point1:
            "من خلال الوصول إلى خدماتنا أو استخدامها، فإنك توافق على جمع واستخدام ومشاركة معلوماتك كما هو موضح في شروط الخدمة وسياسة الخصوصية. إذا كنت لا توافق على هذه الشروط، يرجى عدم استخدام خدماتنا.",
        },
      },
      dataCollection: {
        title: "3. جمع البيانات",
        content: {
          point1:
            "نحن نجمع المعلومات التي تقدمها مباشرة لنا، مثل عندما تقوم بإنشاء حساب أو إجراء عملية شراء أو الاتصال بنا للحصول على الدعم. قد نجمع أيضًا معلومات تلقائيًا، مثل عنوان الـ IP ونوع المتصفح وبيانات الاستخدام.",
        },
      },
      dataUse: {
        title: "4. استخدام البيانات",
        content: {
          point1:
            "يتم استخدام بياناتك لتوفير وصيانة وتحسين خدماتنا. يشمل ذلك معالجة المعاملات، إرسال الاتصالات، وتخصيص تجربتك.",
        },
      },
      dataProtection: {
        title: "5. حماية البيانات",
        content: {
          point1:
            "نحن ملتزمون بحماية بياناتك. نحن نطبق تدابير أمنية متنوعة لحماية معلوماتك من الوصول غير المصرح به أو التغيير أو الكشف أو التدمير.",
        },
      },
      dataSharing: {
        title: "6. مشاركة البيانات",
        content: {
          point1:
            "نحن لا نبيع بياناتك الشخصية. قد نشارك المعلومات مع أطراف ثالثة موثوقة تساعدنا في تشغيل موقعنا الإلكتروني أو إدارة أعمالنا أو تقديم الخدمات لك، طالما أن تلك الأطراف توافق على الحفاظ على سرية هذه المعلومات.",
        },
      },
      userRights: {
        title: "7. حقوق المستخدم",
        content: {
          point1:
            "لديك الحق في الوصول إلى معلوماتك الشخصية وتصحيحها أو حذفها. يمكنك أيضًا الانسحاب من بعض استخدامات بياناتك وكشفها.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "باستخدام خدماتنا، فإنك تقر بأنك قرأت وفهمت هذه الشروط وتوافق على الالتزام بها.",
        },
      },
    },
    signUpTermsData: {
      agreement: {
        title: "1. شروط الاتفاقية",
        content: {
          point1:
            "1.1 يجب على المرخص له توفير ترخيص البرنامج وفقًا لشروط هذه الاتفاقية، ويجب على العميل دفع ثمن هذا الترخيص.",
          point2:
            "1.2 يتم تحديد رسوم الترخيص الشهري (الاشتراك) بناءً على مجموعة الميزات المقدمة من iLoyal وفقًا لاحتياجات العميل. يمكن فقط تعديل رسوم الترخيص (الاشتراك) بناءً على اتفاق مشترك بين العميل و iLoyal. يتم اعتبار فترة الترخيص الشهري (الاشتراك) بدءًا من تاريخ توقيع الاتفاقية.",
        },
      },
      license: {
        title: "2. شروط الترخيص",
        content: {
          point1:
            "2.1 يمنح المرخص له الحقوق التالية بموجب شروط الترخيص البسيط:",
          point2: "2.1.1 لضمان استخدام البرنامج وتحديد تشغيله للغرض المقصود.",
          point3: "2.2 المحظورات التالية على الترخيص:",
          point4: "2.2.1 استخراج شفرة البرنامج من قبل المستخدم.",
          point5:
            "2.2.2 النسخ من قبل المستخدم، أي إنشاء نسخ واحدة أو أكثر من البرنامج.",
          point6: "2.2.3 إعادة بيع البرنامج ونسخه.",
          point7: "2.2.4 جعل البرنامج متاحًا للجمهور.",
        },
      },
      payment: {
        title: "3. دفع الترخيص",
        content: {
          point1:
            "3.1 يوافق العميل على دفع المبلغ المحدد في الفاتورة مقابل الترخيص (الترخيصات) الواردة في هذه الاتفاقية.",
          point2: "3.2 أسعار التراخيص مدرجة على الموقع الرسمي للمرخص له.",
          point3:
            "3.3 في حال إنهاء الاتفاقية بين الأطراف، فإن رسوم الترخيص غير قابلة للتفاوض أو الاسترداد.",
          point4:
            "3.4 بعد دفع رسوم الترخيص وفقًا للفاتورة الصادرة من المرخص له، يعتبر العميل قد وافق على شروط اتفاقية الترخيص الحالية، وتعتبر هذه الاتفاقية قد تم إبرامها بين العميل والمرخص له.",
          point5:
            "3.5 يحتفظ المرخص له بالحق في تعديل هذه الاتفاقية بشكل أحادي.",
          point6:
            "3.6 بمجرد إيداع المبالغ ذات الصلة في حساب المرخص له البنكي، يتم اعتبار جميع التزامات الدفع للعميل قد تم الوفاء بها.",
        },
      },
      dataProtection: {
        title: "4. حماية البيانات",
        content: {
          point1: "4.1 يتم حماية جميع معلومات العميل من قبل المرخص له.",
          point2:
            "4.2 بيانات العميل المخزنة في حسابات السحابة الخاصة بنا لا يتم مشاركتها مع أطراف ثالثة.",
        },
      },
    },
  },
  departments: {
    department: "القسم",
    categories: "الفئات",
    printers: "الطابعات",
    activeDepartment: "تفعيل القسم",
  },
  auth: {
    createAnAccount: "أنشئ حسابًا",
    register: "تسجيل",
    verifyOtp: "تحقق من OTP",
    profile: "الملف الشخصي",
    signUp: "التسجيل",
    otpSentTo: "تم إرسال OTP إلى",
    otpCode: "رمز OTP",
    letKnow: "دعنا نتعرف عليك",
    gender: "الجنس",
    saveAndFinish: "حفظ وإنهاء",
    message: {
      first: "انضم إلى المتعة!",
      second: "اشعر بالراحة!",
      third: "لا تفوت العروض!",
      fourth: "وفر وقتك!",
    },
    genders: {
      male: "ذكر",
      female: "أنثى",
      other: "آخر",
    },
    info: "معلومات",
    youAlreadyHaveAccount: "لديك حساب مسجل بالفعل مع",
    clickButtonToLogin: "اضغط على هذا الزر لتسجيل الدخول",
    loginYourAccount: "تسجيل الدخول إلى حسابك",
    forgotPassword: "نسيت كلمة المرور؟",
    forgotPasswordTitle: "نسيت كلمة المرور",
    forgotPasswordDesc: "أدخل {{method}} وسنرسل لك رمز تحقق OTP",
    setNewPassword: "تعيين كلمة مرور جديدة",
    setNewPasswordDesc: "أدخل كلمة المرور الجديدة",
    signUpWith: "أو سجل باستخدام",
    withoutSignUp: "بدون تسجيل",
    phoneNumber: "رقم الهاتف",
    email: "البريد الإلكتروني",
    loginAccount: "تسجيل الدخول إلى حسابك",
    resendingIsAvailable: "إعادة الإرسال متاحة",
    resendingCodeWillBeAvailableIn: "إعادة إرسال الرمز ستكون متاحة في",
    createGuestAccountDesc:
      "من خلال التسجيل في iLoyal، تحصل على فرصة للاستفادة من الخصومات والميزات الإضافية!",
  },
  myProfile: {
    title: "الحساب",
    editProfile: "تعديل الملف الشخصي",
    orderHistory: "تاريخ الطلبات",
    favorites: "المفضلة",
    chat: "الدردشة",
    gameHistory: "تاريخ الألعاب",
    helpCenter: "مركز المساعدة",
    settings: "الإعدادات",
    resetPassword: {
      resetPassword: "إعادة تعيين كلمة المرور",
      setNewPassword: "تعيين كلمة مرور جديدة",
      enterCurrentPassword:
        "لتعيين كلمة مرور جديدة، يرجى إدخال كلمة المرور الحالية أولاً.",
      currentPassword: "كلمة المرور الحالية *",
      newPassword: "كلمة المرور الجديدة *",
    },
  },
  record: {
    talkToMe: "تحدث إلي",
    magicOrder: "الطلب السحري",
    magicSearch: "البحث السحري",
    start: "ابدأ",
    stop: "توقف",
    magicOffer: "عرض سحري",
    results: "النتائج",
    addedToBasket: "تم إضافته إلى السلة",
    youCanUseMicrophone: "يمكنك استخدام الميكروفون",
    magicOrderError:
      "لا يبدو أن طلبك مرتبط بالقائمة. يرجى تقديم استفسار أكثر تحديدًا يتعلق بالأطعمة أو فئات القائمة.",
    introductionLoya: "مرحبًا، أنا Loya.",
    askMeAnything: "اسألني أي شيء!",
    askLoya: "اسأل Loya",
    loyaIsTyping: "Loya يكتب",
  },
  recommend: {
    fitMyBudget: "أوصِ بالأطباق التي تناسب ميزانيتي.",
    tastiestDishes: "اختر الأطباق الأرخص والألذ",
    delicious: "قدم أطباقك الأكثر لذة",
    smtQuick: "اعطني شيئًا سريعًا",
  },
  devices: {
    addPrinter: "إضافة طابعة",
    testPrinter: "اختبار الطابعة",
    deletePrinter: "حذف الطابعة",
    connectedDevices: "الأجهزة المتصلة",
    availableDevices: "الأجهزة المتاحة",
    notFound: "غير موجود!",
  },
  bulkSms: {
    guestSelected: "تم اختيار الضيف",
    giveDiscount: "إعطاء خصم",
    sendSms: "إرسال الرسائل القصيرة",
    composeYourMessage: "قم بكتابة رسالتك",
    typeHere: "اكتب هنا...",
    closeModal: "هل أنت متأكد أنك تريد الإغلاق؟",
    yourMessageWillBeDeleted: "سيتم حذف رسالتك",
    sendSmsModal: "هل أنت متأكد أنك تريد إرسال الرسائل القصيرة؟",
    willBeSent: "سيتم إرسال هذه الرسالة إلى {{numberOfPeople}} شخصًا",
    successfullySent: "تم الإرسال بنجاح",
    successfullySentToPeople:
      "تم إرسال الرسالة بنجاح إلى {{numberOfPeople}} شخصًا",
    operationFailed: "فشلت العملية",
    operationFailedPeople: "فشل إرسال الرسالة إلى {{numberOfPeople}} شخصًا",
    no: "لا",
    yes: "نعم",
    close: "إغلاق",
    messageIsRequired: "الرسالة مطلوبة",
    everyoneSelected: "تم اختيار الجميع",
    sentToEveryone: "تم إرسال الرسالة إلى الجميع",
    sentToEveryoneSuccess: "تم إرسال الرسالة إلى الجميع",
    sentToEveryoneFail: "لم يتمكن من إرسال الرسالة إلى أحد",
  },
  termsAndCondition: TermsAndCondition,
  privacyPolicy: PrivacyPolicy,
  typingEffect: {
    tableStartTopic: "موضوع جديد!",
    talkToWaiter: "تحدث مع النادل.",
    shareOpinion: "شارك رأيك!",
    questionsHere: "اكتب أسئلتك هنا!",
    chatWithOtherTables: "دردش مع الطاولات الأخرى",
  },
  gameMessages: {
    findCards: "ابحث عن البطاقات!",
    tastyVictory: "نصر لذيذ!",
    riseBeforeMeal: "اِرتقِ قبل الوجبة!",
    playAndGetHungry: "العب وازدد جوعاً!",
    winBeforeMeal: "افز بالفوز قبل الوجبة!",
  },
};

export default TRANSLATION;
