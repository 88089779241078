import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";
import { RotatingLines } from "react-loader-spinner";

import {
  calculateCategoriesOrder,
  reorderItems,
  sortCategoriesAndMenuItems,
} from "utils/general";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useTranslationInProgress from "utils/hooks/useTranslationInProgress";
import AddMenuItemModal from "pages/admin/admin-pages/admin-menu/admin-menu-controller/add-menu-item-modal/AddMenuItemModal";
import SectionHeader from "components/admin/elements/section-header/SectionHeader";
import ADD_ICON from "assets/icons/math-operators/add/PlusAdmin.svg";
import PREVIEW_ICON from "assets/icons/preview/Preview.svg";
import { ReactComponent as MagicPen } from "assets/icons/chat-gpt/MagicPen.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/DropdownArrowDown.svg";
import DRAG_DROP_ICON from "assets/icons/drag-drop/DragDrop.svg";
import ICON_EMPTY_MENU from "assets/icons/menu/admin-empty-menu.svg";
import { updateMenu } from "utils/api/services/menu";
import ICON_CLOSED_EYE from "assets/icons/eye/EyeCloseBlack.svg";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import DraggableListVertical from "components/drag-drop/DraggableListVertical";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import useLanguage from "utils/hooks/useLanguage";
import { ENUMS as ENUMS_DRAGGABLE_CARD } from "components/drag-drop/DraggableCard";
import EditButton, {
  ENUMS as ENUMS_EDIT,
} from "components/admin/buttons/edit-button/EditButton";
import EmptyState from "components/admin/empty-state/EmptyState";
import AddTagButton, {
  ENUMS as ENUMS_ADD_TAG_BUTTON,
} from "components/admin/buttons/add-tag-button/AddTagButton";
import DeleteButton, {
  ENUMS as ENUMS_DELETE_BUTTON,
} from "components/buttons/delete-button/DeleteButton";
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from "utils/api/services/category";
import Item from "components/admin/cards/item/Item";
import useAsync from "utils/hooks/useAsync";
import {
  createMenuItem,
  deleteLinkedMenuItem,
  deleteMenuItem,
  updateMenuItem,
} from "utils/api/services/menuItem";
import { STORE_NAMES } from "utils/constants/redux";
import {
  getTranslationPropertyRequestBody,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import {
  createUniqueImageName,
  duplicateImageInDO,
  removeImageFromDO,
} from "utils/DO-Spaces";
import { DO_FILE_TYPES } from "utils/constants/DOSpaces";
import {
  filterArchivedCategories,
  filterCategoriesAndItsMenuItemsByIsArchived,
} from "utils/helpersMenu";
import { addPartialMenuUpdate } from "redux/slices/menuStore";
import Spinner from "components/elements/spinner/Spinner";
import { API_ERROR_STATUS_CODES } from "utils/hooks/useAPIErrorStatusCodeHelper";
import AdminAiTranslateModal from "pages/admin/admin-pages/admin-menu/admin-menu-controller/admin-ai-translate-modal/AdminAiTranslateModal";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import TranslateWarningModal from "components/admin/modal/translate-warning-modal/TranslateWarningModal";
import {
  INTENT,
  usePermission,
} from "utils/context-api/AdminPermissionContext";
import AccessDeniedModal from "pages/admin/admin-pages/admin-order/admin-order-actions/access-denied-modal/AccessDeniedModal";
import AddCategoryModal from "components/admin/cards/add-category-modal/AddCategoryModal";
import AddPromotionOrBannerModal from "pages/admin/admin-pages/admin-menu/admin-menu-controller/add-promotion-or-banner-modal/AddPromotionOrBannerModal";
import AddSpecialCategoryItemModal from "pages/admin/admin-pages/admin-menu/admin-menu-controller/add-special-category-item-modal/AddSpecialCategoryItemModal";
import { ReactComponent as ICON_DOWNLOAD } from "assets/icons/other/Download.svg";
import { downloadMenuItemImagesAsZip } from "utils/helper-functions/menu-helper/menu-item-helper";

import "./AdminMenuController.scss";

const CONFIRM_TYPE_ENUMS = {
  CATEGORY: "category",
  ITEM: "item",
};

const AdminMenuController = () => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const initialCategories = {
    name: [
      { value: " Hamısı", languageCode: "AZ" },
      { value: " All", languageCode: "EN" },
      { value: " Alle", languageCode: "DE" },
      { value: " Все", languageCode: "RU" },
      { value: " Hepsi", languageCode: "TR" },
      { value: " ყველა", languageCode: "GE" },
      { value: " Hammasi", languageCode: "UZ" },
      { value: " Todo", languageCode: "ES" },
    ],
    id: 0,
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const { isPermitted } = usePermission();
  const [, setOpenDeleteModal] = useOutsideClick();
  const [
    openSlideDeniedModal,
    setOpenSlideDeniedModal,
    mainElementRefDeniedModal,
  ] = useOutsideClick();
  const [positions, setPositions] = useState([]);
  const [initialPositions, setInitialPositions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const oldCategoryRef = useRef(null);
  const [hasUnsavedPositionChanges, setHasUnsavedPositionChanges] =
    useState(false);
  const [activeCategoryDropdown, setActiveCategoryDropdown] =
    useState(initialCategories);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandItemView, setExpandItemView] = useState(true);
  const [isLoadingDownloadMenuItem, setIsLoadingDownloadMenuItem] =
    useState(false);

  const dispatch = useDispatch();
  const { data: menu, isLoading: isMenuLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );
  const { menuCategoryType } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const businessName = business?.name;
  const businessId = business?.id;
  const menuId = business?.menu?.id;

  const categories = filterArchivedCategories(menu?.categories) || [];
  const sortedCategories = sortCategoriesAndMenuItems(
    categories,
    "placeInTheList"
  );
  const [allCategories, setAllCategories] = useState(sortedCategories);

  useEffect(() => {
    const sortedCategories = sortCategoriesAndMenuItems(
      categories,
      "placeInTheList"
    );

    const categoryPositions = sortedCategories.map((category, index) => {
      if (category.type === menuCategoryType.promotion) {
        return {
          id: category.id,
          position: null,
          menuItemPositions: category.menuItems.map((item, itemIndex) => ({
            id: item.id,
            position: itemIndex + 1,
          })),
        };
      }

      return {
        id: category.id,
        position: index + 1,
        menuItemPositions: category.menuItems.map((item, itemIndex) => ({
          id: item.id,
          position: itemIndex + 1,
        })),
      };
    });

    setInitialPositions(categoryPositions);

    setPositions(categoryPositions);

    // setActiveCategoryDropdown(null);
    setAllCategories(sortedCategories);
    setHasUnsavedPositionChanges(false);
  }, [menu]);

  const handleOnAsyncSuccessForMenuItem = async (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideMenuItem(false);
    });
    const oldCategoryId = oldCategoryRef?.current?.id ?? null;
    const menuItem = response.data;
    const menuCategory = response.data.category;
    if (!menuCategory) {
      return;
    }
    if (!menuCategory.menuItems) {
      menuCategory.menuItems = [];
    }

    menuCategory.menuItems.push(menuItem);
    // if menuItem category change, we send extra 2 params to setOrReplaceMenu func ( oldCategoryId and menuItemId ) for delete menuItem to old category and add it to new category.
    if (oldCategoryId && oldCategoryId !== menuCategory.id) {
      return dispatch(
        addPartialMenuUpdate({
          categories: [menuCategory],
          oldCategoryId,
          menuItemId: menuItem.id,
        })
      );
    }
    await dispatch(addPartialMenuUpdate({ categories: [menuCategory] }));
  };

  const handleOnAsyncSuccessForLinkedMenuItem = async (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideMenuItem(false);
    });
  };

  const handleOnAsyncSuccessForCategory = async (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideCategory(false);
    });
    await dispatch(addPartialMenuUpdate({ categories: [response.data] }));
  };
  const handleOnAsyncErrorForMenu = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const { execute: executeCreateCategory, loading: isLoadingCreateCategory } =
    useAsync(createCategory, {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) =>
        handleOnAsyncSuccessForCategory(
          t("toastMessages.success.createCategory"),
          response
        ),
    });
  const { execute: executeUpdateCategory, loading: isLoadingUpdateCategory } =
    useAsync(updateCategory, {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) =>
        handleOnAsyncSuccessForCategory(
          t("toastMessages.success.updateCategory"),
          response
        ),
    });
  const { execute: executeDeleteCategory } = useAsync(deleteCategory, {
    onError: () =>
      handleOnAsyncErrorForMenu(t("toastMessages.error.deleteCategory")),
    onSuccess: (response) =>
      handleOnAsyncSuccessForCategory(
        t("toastMessages.success.deleteCategory"),
        response
      ),
  });

  const { execute: executeCreateMenuItem, loading: isLoadingCreateMenuItem } =
    useAsync(createMenuItem, {
      onError: (err) =>
        handleOnAsyncErrorForMenu(err.response.data.message.message.join()),
      onSuccess: (response) =>
        handleOnAsyncSuccessForMenuItem(
          t("toastMessages.success.createMenuItem"),
          response
        ),
    });
  const { execute: executeUpdateMenuItem, loading: isLoadingUpdateMenuItem } =
    useAsync(updateMenuItem, {
      onError: (err) =>
        handleOnAsyncErrorForMenu(err.response.data.message.message.join()),
      onSuccess: (response) =>
        handleOnAsyncSuccessForMenuItem(
          t("toastMessages.success.updateMenuItem"),
          response
        ),
    });

  const { execute: executeDeleteMenuItem } = useAsync(deleteMenuItem, {
    onError: (error) => {
      if (
        error.response.data.statusCode === API_ERROR_STATUS_CODES["609"].code
      ) {
        return handleOnAsyncErrorForMenu(t("menu.item.itemDeleteError"));
      }
      handleOnAsyncErrorForMenu(error.response.data.message);
    },
    onSuccess: (response) => {
      const removedItemImages =
        response?.data?.categories[0].menuItems[0].otherImagesSrc || [];
      removedItemImages.map((image) => removeImageFromDO(image));
      return handleOnAsyncSuccessForMenuItem(
        t("toastMessages.success.deleteMenuItem"),
        response
      );
    },
  });
  const { execute: executeDeleteLinkedMenuItem } = useAsync(
    deleteLinkedMenuItem,
    {
      onError: () => {},
      onSuccess: (response) => {
        return handleOnAsyncSuccessForLinkedMenuItem(
          t("toastMessages.success.deleteMenuItem"),
          response
        );
      },
    }
  );

  const { execute: executeDuplicateMenuItem } = useAsync(createMenuItem, {
    onError: (err) =>
      handleOnAsyncErrorForMenu(err.response.data.message.message.join()),
    onSuccess: (response) =>
      handleOnAsyncSuccessForMenuItem(
        t("toastMessages.success.duplicateMenuItem"),
        response
      ),
  });

  useEffect(() => {
    if (hasUnsavedPositionChanges) {
      setSearchParams({
        ...searchParams,
        [QUERY_PARAMS.unsavedChanges]: true,
      });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);
    }
  }, [hasUnsavedPositionChanges]);

  const handleOnAsyncSuccessForMenu = async (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {
      setHasUnsavedPositionChanges(false);
    });
    await dispatch(addPartialMenuUpdate(response.data));
  };

  const { execute: executeUpdateMenu, loading: isLoadingUpdateMenu } = useAsync(
    updateMenu,
    {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) =>
        handleOnAsyncSuccessForMenu(
          t("toastMessages.success.updateMenu"),
          response
        ),
    }
  );

  const { translationInProgress } = useTranslationInProgress();

  const [
    openSlideMenuItem,
    setOpenSlideMenuItem,
    mainElementRefMenuItem,
    ,
    ,
    setOutsideClickAction,
  ] = useOutsideClick();

  const [
    openSlideCategory,
    setOpenSlideCategory,
    mainElementRefCategory,
    ,
    ,
    setOutsideClickActionCategory,
  ] = useOutsideClick();

  const [warningOpenSlide, setWarningOpenSlide, mainElementRefWarning] =
    useOutsideClick();

  const [
    openSlideAITranslate,
    setOpenSlideAITranslate,
    mainElementRefAITranslate,
  ] = useOutsideClick();

  useEffect(() => {
    if (!translationInProgress) {
      setOpenSlideAITranslate(false);
      setWarningOpenSlide(false);
    }
  }, [translationInProgress]);

  const checkTranslationInProgress = (callback) => {
    if (translationInProgress) {
      setWarningOpenSlide(true);
    } else {
      setWarningOpenSlide(false);
      callback();
    }
  };

  const handleAITranslate = () => {
    checkTranslationInProgress(() => setOpenSlideAITranslate(true));
  };
  const isEditMenuEnabledForRole = !isPermitted(INTENT.editMenuEnabled);
  const isDeleteMenuItemsEnabledForRole = !isPermitted(INTENT.deleteMenuItems);

  const handleOnEditCategory = (id) => {
    checkTranslationInProgress(() => {
      const category = allCategories.find((category) => category.id === id);
      setSelectedCategory(category);
      isEditMenuEnabledForRole
        ? setOpenSlideDeniedModal(true)
        : setOpenSlideCategory(true);
    });
  };

  const handleOnEditItem = (item, category) => {
    checkTranslationInProgress(() => {
      setSelectedItem(item);
      setSelectedCategory(category);
      oldCategoryRef.current = category;
      isEditMenuEnabledForRole
        ? setOpenSlideDeniedModal(true)
        : category.type !== menuCategoryType.special_category &&
          setOpenSlideMenuItem(true);
    });
  };

  // Handling

  const [confirmType, setConfirmType] = useState("");
  const handleConfirmChanges = () => {
    if (confirmType === CONFIRM_TYPE_ENUMS.CATEGORY) {
      handleConfirmCategoryUpdateChanges();
    } else if (confirmType === CONFIRM_TYPE_ENUMS.ITEM) {
      handleConfirmItemUpdateChanges();
    }
  };

  // Category handling

  const [
    isUpdateChangesConfirmationOpen,
    setIsUpdateChangesConfirmationOpen,
    updateChangesConfirmRef,
  ] = useOutsideClick();

  const handleConfirmCategoryUpdateChanges = () => {
    setHasUnsavedPositionChanges(false);
    setAllCategories(sortedCategories);
    setPositions(initialPositions);
    setOpenSlideCategory(true);
    setSelectedCategory(null);
    setIsUpdateChangesConfirmationOpen(false);
  };

  const handleCancelUpdateChanges = () =>
    setIsUpdateChangesConfirmationOpen(false);

  const handleOnAddCategory = () => {
    checkTranslationInProgress(() => {
      setConfirmType(CONFIRM_TYPE_ENUMS.CATEGORY);
      if (hasUnsavedPositionChanges) {
        return setIsUpdateChangesConfirmationOpen(true);
      }
      isEditMenuEnabledForRole
        ? setOpenSlideDeniedModal(true)
        : setOpenSlideCategory(true);
      setSelectedCategory(null);
    });
  };
  const [selectedCategoryForItem, setSelectedCategoryForItem] = useState(null);

  const handleConfirmItemUpdateChanges = () => {
    setHasUnsavedPositionChanges(false);
    setAllCategories(sortedCategories);
    setPositions(initialPositions);
    setOpenSlideMenuItem(true);
    setSelectedCategory(selectedCategoryForItem);
    setSelectedItem(null);
    setIsUpdateChangesConfirmationOpen(false);
  };

  const handleOnAddItem = (category) => {
    checkTranslationInProgress(() => {
      setSelectedCategoryForItem(category);
      setConfirmType(CONFIRM_TYPE_ENUMS.ITEM);
      if (hasUnsavedPositionChanges) {
        setIsUpdateChangesConfirmationOpen(true);
      } else {
        isEditMenuEnabledForRole
          ? setOpenSlideDeniedModal(true)
          : setOpenSlideMenuItem(true);
        setSelectedCategory(category);
        setSelectedItem(null);
      }
    });
  };

  const handleOnDuplicate = (item, category) => {
    checkTranslationInProgress(async () => {
      // eslint-disable-next-line no-unused-vars
      const { id, ...duplicatedItem } = item;
      let duplicatedCoverImageSrc;
      let duplicatedOtherImagesSrc = [];

      if (
        category.type !== menuCategoryType.promotion &&
        category.type !== menuCategoryType.banner
      ) {
        duplicatedOtherImagesSrc = await Promise.all(
          duplicatedItem.otherImagesSrc.map((photo) => {
            const uniqueImageName = createUniqueImageName(
              DO_FILE_TYPES.MENU,
              businessId
            );
            return new Promise((resolve) => {
              duplicateImageInDO(photo, uniqueImageName, (location) => {
                if (duplicatedItem.coverImageSrc === photo) {
                  duplicatedCoverImageSrc = location;
                }
                resolve(location);
              });
            });
          })
        );
      }

      const modifiedModifications = duplicatedItem.modifications.map(
        (modification) => {
          const updatedModificationName = getTranslationPropertyRequestBody(
            modification.name
          );
          // eslint-disable-next-line no-unused-vars
          const { id, ...modifiedModification } = modification;
          const modifiedOptions = modifiedModification.options.map(
            // eslint-disable-next-line no-unused-vars
            ({ id, ...option }) => {
              const updatedOptionName = getTranslationPropertyRequestBody(
                option.name
              );
              return {
                ...option,
                name: updatedOptionName,
              };
            }
          );

          return {
            ...modifiedModification,
            name: updatedModificationName,
            options: modifiedOptions,
          };
        }
      );
      const duplicatedItemName = duplicatedItem.name.map((item) => ({
        ...item,
        value: `${item.value} ${t("menu.item.duplicate")}`,
      }));

      const requestBody = {
        ...duplicatedItem,
        name: getTranslationPropertyRequestBody(duplicatedItemName),
        description: getTranslationPropertyRequestBody(
          duplicatedItem.description
        ),
        tags: duplicatedItem.tags.map((tag) => tag.id),
        modifications: modifiedModifications,
        category: category.id,
        unit: duplicatedItem.unit?.id,
        placeInTheList: category.menuItems.length + 1,
        coverImageSrc: duplicatedCoverImageSrc || duplicatedItem.coverImageSrc,
        otherImagesSrc:
          duplicatedOtherImagesSrc.length > 0
            ? duplicatedOtherImagesSrc
            : duplicatedItem.otherImagesSrc,
      };
      delete requestBody.lastUpdateDate;
      delete requestBody.createDate;
      await executeDuplicateMenuItem(businessId, menuId, requestBody);
    });
  };

  const handleOnSaveMenuItem = async (data, id) => {
    const menuItem = {
      ...data,
      placeInTheList: selectedCategory.menuItems.length + 1,
    };
    if (id) {
      await executeUpdateMenuItem(businessId, menuId, data, id);
    } else {
      await executeCreateMenuItem(businessId, menuId, menuItem);
    }
  };

  const handleOnDeleteMenuItem = async (id) => {
    checkTranslationInProgress(async () => {
      await executeDeleteMenuItem(businessId, menuId, id);
    });
  };
  const handleOnDeleteLinkedMenuItem = async (id, categoryId) => {
    checkTranslationInProgress(async () => {
      await executeDeleteLinkedMenuItem(businessId, menuId, categoryId, id);
    });
  };

  const handleOnSaveCategory = async (data, id) => {
    const updatedName = getTranslationPropertyRequestBody(data.name);
    const filteredCategories = categories.filter(
      (category) => category.type !== menuCategoryType.promotion
    );
    const category = {
      ...data,
      name: updatedName,
      placeInTheList:
        data.type === menuCategoryType.promotion
          ? null
          : filteredCategories.length + 1,
    };
    if (id) {
      await executeUpdateCategory(businessId, menuId, category, id);
    } else {
      await executeCreateCategory(businessId, menuId, category);
    }
  };
  const handleOnDeleteCategory = async (id) => {
    await executeDeleteCategory(businessId, menuId, id);
  };

  const showItemsByCategory = (categoryId) => {
    const category = allCategories.find(
      (category) => category.id === categoryId
    );

    let menuItems = [];

    if (category?.menuItems?.length > 0) {
      menuItems = category.menuItems;
    } else if (category?.linkedMenuItems?.length > 0) {
      menuItems = category.linkedMenuItems
        .map((linkedItemId) =>
          allCategories
            .flatMap((cat) => cat.menuItems)
            .find((item) => item.id === linkedItemId)
        )
        .filter(Boolean);
    }
    return (
      <div className="AdminMenuControllerItems">
        <div className="AdminMenuControllerItemsAddContainer">
          <AddTagButton
            onClick={() => {
              handleOnAddItem(category);
            }}
            type={ENUMS_ADD_TAG_BUTTON.types.TYPE_C}
            className={cx("AdminMenuControllerItemsAddButton", {
              isDisabled: isEditMenuEnabledForRole,
            })}
          />
        </div>
        {menuItems.map((item) => {
          return (
            <Item
              key={item.id}
              item={item}
              category={category}
              onClick={() => handleOnEditItem(item, category)}
              onDelete={() =>
                category?.menuItems?.some((menuItem) => menuItem.id === item.id)
                  ? handleOnDeleteMenuItem(item.id)
                  : handleOnDeleteLinkedMenuItem(item.id, category.id)
              }
              onDuplicate={() => handleOnDuplicate(item, category)}
              allowClickDeleteButton={!isDeleteMenuItemsEnabledForRole}
              allowClickDuplicateButton={!isEditMenuEnabledForRole}
            />
          );
        })}
      </div>
    );
  };

  const handleDropdownChange = (value) => {
    if (value) {
      setActiveCategoryDropdown(value);
      const selectedCategory = categories.find(
        (category) => category.id === value.id
      );
      if (selectedCategory) {
        setSelectedCategory(selectedCategory);
        setAllCategories([selectedCategory]);
      } else {
        setSelectedCategory(null);
        setAllCategories(sortedCategories);
      }
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    const updatePositions = (updatedItems, positionKey) => {
      let newPosition;
      let promotionPosition = null;

      const filteredItems = updatedItems.filter(
        (item) => item.type !== menuCategoryType.promotion
      );

      const promotionCategory = updatedItems.find(
        (item) => item.type === menuCategoryType.promotion
      );

      if (positionKey === "menuItemPositions") {
        newPosition = filteredItems.map((category) => ({
          id: category.id,
          position: category.placeInTheList,
          menuItemPositions: category.menuItems.map((menuItem, index) => ({
            id: menuItem.id,
            position: index + 1,
          })),
        }));
      } else {
        newPosition = filteredItems.map((item, index) => ({
          id: item.id,
          position: index + 1,
          menuItemPositions: item.menuItems.map((menuItem, indexItem) => ({
            id: menuItem.id,
            position: indexItem + 1,
          })),
        }));
      }

      if (promotionCategory) {
        promotionPosition = {
          id: promotionCategory.id,
          position: null,
          menuItemPositions: promotionCategory.menuItems.map(
            (menuItem, index) => ({
              id: menuItem.id,
              position: index + 1,
            })
          ),
        };
      }

      setPositions(() => {
        const finalPositions = promotionPosition
          ? [...newPosition, promotionPosition]
          : newPosition;
        setHasUnsavedPositionChanges(
          !isEqual(initialPositions, finalPositions)
        );
        return finalPositions;
      });
    };

    if (type === "droppableItem") {
      const sortedCategories = reorderItems(
        allCategories,
        source.index,
        destination.index
      );
      setAllCategories(sortedCategories);
      updatePositions(sortedCategories, "categoryPositions");
    } else if (type.startsWith("droppableSubItem")) {
      const parentId = parseInt(type.split("-")[1]);
      const sortedCategoriesByListOrder =
        calculateCategoriesOrder(allCategories);

      const category = sortedCategoriesByListOrder.find(
        (cat) => cat.id === parentId
      );

      const sortedItems = reorderItems(
        category.menuItems,
        source.index,
        destination.index
      );
      const updatedCategories = sortedCategoriesByListOrder.map((cat) =>
        cat.id === parentId ? { ...cat, menuItems: sortedItems } : cat
      );

      setAllCategories(updatedCategories);
      updatePositions(updatedCategories, "menuItemPositions");
    }
  };

  const handleOnPreviewMenu = () => {
    window.open(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      "_blank"
    );
  };

  const onErrorDownloadMenuItemImages = () => {
    toast.error(t("toastMessages.error.common"));
  };

  const renderHeader = () => (
    <div className="AdminMenuControllerHeaderContainer">
      <SectionHeader
        className="AdminMenuControllerSectionHeader"
        title={t("navbarRoutes.pageTitles.category")}
      />
      <div className="AdminMenuControllerHeaderActionButtons">
        <button
          type="button"
          className={cx("DownloadMenuImagesButton", {
            isDisabled: categories.length === 0 || isLoadingDownloadMenuItem,
          })}
          onClick={() =>
            downloadMenuItemImagesAsZip({
              businessName,
              setLoading: setIsLoadingDownloadMenuItem,
              setError: onErrorDownloadMenuItemImages,
              categories: filterCategoriesAndItsMenuItemsByIsArchived(
                menu?.categories || []
              ),
            })
          }
        >
          {isLoadingDownloadMenuItem ? <Spinner /> : <ICON_DOWNLOAD />}
        </button>

        <CTAButton
          name={t(
            translationInProgress
              ? "menu.translate.translating"
              : "menu.translate.ai"
          )}
          type={CTA_BUTTON_ENUMS.types.TYPE_F}
          icon={
            translationInProgress ? (
              <div className="LoadingIcon">
                <RotatingLines
                  strokeColor="#9288FF"
                  height={20}
                  width="20"
                  strokeWidth="4"
                />
              </div>
            ) : (
              <MagicPen />
            )
          }
          onClick={handleAITranslate}
          className={cx("AdminMenuControllerCategoriesAITranslateButton", {
            isDisabled: categories.length === 0 || isEditMenuEnabledForRole,
          })}
        />
        <PrimaryButton
          text={t("menu.preview")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
          icon={PREVIEW_ICON}
          className="AdminMenuControllerCategoriesPreviewButton"
          onClick={handleOnPreviewMenu}
          isDisabled={isEditMenuEnabledForRole}
        />
        <PrimaryButton
          text={t("menu.category.add")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
          icon={ADD_ICON}
          className="AdminMenuControllerCategoriesAddButton"
          onClick={handleOnAddCategory}
          isDisabled={isEditMenuEnabledForRole}
        />
        <Dropdown
          className="AdminMenuControllerCategoriesDropdown"
          name="category"
          value={activeCategoryDropdown}
          options={[initialCategories, ...categories]}
          onChange={handleDropdownChange}
          isMultiLanguage
          placeholder={t("menu.category.categories")}
        />
      </div>
    </div>
  );

  const renderCategory = (item, index) => {
    const numberOfItems =
      item?.menuItems.length || item?.linkedMenuItems.length || "";

    return {
      key: item.id,
      id: `category-${item.id}`,
      headerComponent: (
        <>
          <div className="DraggableCardDragAndLabel">
            <h6 className="DraggableCardLabel Medium">{index + 1}</h6>
            <img
              src={DRAG_DROP_ICON}
              alt="Drag and drop icon"
              className="DragDropIcon"
            />
          </div>
          <div className="DraggableCardItemNameContainer">
            <h1 className="Medium DraggableCardItemName">
              {displayDataByLanguage(item.name)}{" "}
              {numberOfItems && `(${numberOfItems})`}
            </h1>
            {!item?.isPublished && (
              <div className="AdminMenuControllerCategoryPublishStatusContainer">
                <img src={ICON_CLOSED_EYE} alt="Unpublished status" />
                <h6 className="AdminMenuControllerCategoryPublishStatusText">
                  {t("common.unPublished")}
                </h6>
              </div>
            )}
          </div>
          <div className="DraggableCardActionButtons">
            <DeleteButton
              setOpenSlide={setOpenDeleteModal}
              onClick={() => handleOnDeleteCategory(item.id)}
              type={ENUMS_DELETE_BUTTON.types.TYPE_A}
              className={cx("AdminMenuControllerDeleteButton", {
                isDisabled: isDeleteMenuItemsEnabledForRole,
              })}
              showAccessDeniedModal={isDeleteMenuItemsEnabledForRole}
            />
            <EditButton
              type={ENUMS_EDIT.types.TYPE_C}
              className={cx("AdminMenuControllerEditButton", {
                isDisabled: isEditMenuEnabledForRole,
              })}
              onClick={() => handleOnEditCategory(item.id)}
              isDisabled={isEditMenuEnabledForRole}
            />
            <CTAButton
              type={CTA_BUTTON_ENUMS.types.TYPE_Z}
              icon={<ArrowIcon />}
              onClick={() => setExpandItemView(!expandItemView)}
              className={cx("AdminMenuControllerExpandButton", {
                isExpanded: expandItemView,
              })}
            />
          </div>
        </>
      ),
      bodyComponent: expandItemView && (
        <>
          <div
            className={cx("DraggableCardLeftContainer", {
              noRightWidth: item.type === menuCategoryType.special_category,
              hasNoItem:
                item.menuItems.length === 0 &&
                (!item.linkedMenuItems || item.linkedMenuItems?.length === 0),
            })}
          >
            {showItemsByCategory(item.id)}
          </div>
          {item.menuItems.length === 0 &&
          (!item.linkedMenuItems || item.linkedMenuItems.length === 0) ? (
            <EmptyState
              description={t("menu.item.emptyAdminMenuItemDescription")}
              icon={ICON_EMPTY_MENU}
              isAdmin
              className="AdminMenuControllerEmptyMenuItemState"
            />
          ) : (
            item.type !== menuCategoryType.special_category && (
              <div className="DraggableCardRightContainer">
                <Droppable
                  droppableId={`category-${item.id.toString()}`}
                  type={`droppableSubItem-${item.id.toString()}`}
                >
                  {(provided) => {
                    let menuItems = [];
                    if (item?.menuItems?.length > 0) {
                      menuItems = item.menuItems;
                    } else if (item?.linkedMenuItems?.length > 0) {
                      menuItems = item.linkedMenuItems
                        .map((linkedItemId) =>
                          allCategories
                            .flatMap((cat) => cat.menuItems)
                            .find((menuItem) => menuItem.id === linkedItemId)
                        )
                        .filter(Boolean);
                    }
                    return (
                      <div ref={provided.innerRef}>
                        <DraggableListVertical
                          items={menuItems
                            .map((menuItem, index) => ({
                              id: `${
                                item.menuItems.includes(menuItem)
                                  ? `menuItem-${menuItem.id}`
                                  : `linkedItem-${menuItem.id}`
                              }`,
                              headerComponent: (
                                <>
                                  <div className="DraggableCardDragAndLabel">
                                    <h6 className="DraggableCardLabel Medium">
                                      {index + 1}
                                    </h6>
                                    <img
                                      src={DRAG_DROP_ICON}
                                      alt="Drag and drop icon"
                                      className="DragDropIcon"
                                    />
                                  </div>
                                  <h6 className="Medium DraggableCardItemName">
                                    {displayDataByLanguage(menuItem.name)}
                                  </h6>
                                </>
                              ),
                            }))
                            .filter(Boolean)}
                          sizeVariant={ENUMS_DRAGGABLE_CARD.types.SIZE_S}
                          canDragWholeCard
                          isLoadingUpdateMenu={
                            isLoadingUpdateMenu || isEditMenuEnabledForRole
                          }
                        />
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            )
          )}
        </>
      ),
    };
  };

  const handleOnPositionsSave = () => {
    const positionsDifference = positions
      .map((positionItem) => {
        const initialPositionItem = initialPositions.find(
          (item) => item.id === positionItem.id
        );

        if (initialPositionItem) {
          const changedMenuItems = positionItem.menuItemPositions.filter(
            (menuItem) => {
              const initialMenuItem =
                initialPositionItem.menuItemPositions.find(
                  (item) => item.id === menuItem.id
                );
              return (
                initialMenuItem &&
                initialMenuItem.position !== menuItem.position
              );
            }
          );
          if (changedMenuItems.length > 0) {
            return {
              id: positionItem.id,
              position: positionItem.position,
              menuItemPositions: changedMenuItems,
            };
          }
        }
        return {
          id: positionItem.id,
          position: positionItem.position,
        };
      })
      .filter((positionItem) => {
        const initialPositionItem = initialPositions.find(
          (item) => item.id === positionItem.id
        );
        return (
          positionItem.position !== initialPositionItem.position ||
          positionItem?.menuItemPositions
        );
      });
    executeUpdateMenu(businessId, menuId, { positions: positionsDifference });
  };

  const [isHeaderHovered, setIsHeaderHovered] = useState(false);

  return (
    <div className="AdminMenuController">
      {isMenuLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="AdminMenuControllerTop">{renderHeader()}</div>
          {!categories || categories.length === 0 ? (
            <EmptyState
              description={t("menu.category.emptyAdminCategoryDescription")}
              icon={ICON_EMPTY_MENU}
              isAdmin
              className="AdminMenuControllerEmptyState"
            />
          ) : (
            <>
              {allCategories.some(
                (category) => category.type === menuCategoryType.promotion
              ) && (
                <div className="NoDraggableCardWrapper">
                  {allCategories
                    .filter(
                      (category) => category.type === menuCategoryType.promotion
                    )
                    .map((category, index) => {
                      const numberOfItems = category?.menuItems.length || "";

                      return (
                        <div
                          key={index}
                          className={cx(
                            "DraggableCardContainer NoDraggableCardContainer",
                            {
                              isHeaderHovered,
                            }
                          )}
                        >
                          <div
                            className="DraggableCardHeader NoDraggableCardHeader sizeL"
                            onMouseEnter={() => setIsHeaderHovered(true)}
                            onMouseLeave={() => setIsHeaderHovered(false)}
                          >
                            <div className="DraggableCardItemNameContainer">
                              <h1 className="Medium DraggableCardItemName">
                                {t("menu.category.promotion")}{" "}
                                {numberOfItems && `(${numberOfItems})`}
                              </h1>
                              {!category?.isPublished && (
                                <div className="AdminMenuControllerCategoryPublishStatusContainer">
                                  <img
                                    src={ICON_CLOSED_EYE}
                                    alt="Unpublished status"
                                  />
                                  <h6 className="AdminMenuControllerCategoryPublishStatusText">
                                    {t("common.unPublished")}
                                  </h6>
                                </div>
                              )}
                            </div>
                            <div className="DraggableCardActionButtons">
                              <DeleteButton
                                setOpenSlide={setOpenDeleteModal}
                                onClick={() =>
                                  handleOnDeleteCategory(category.id)
                                }
                                type={ENUMS_DELETE_BUTTON.types.TYPE_A}
                                className={cx(
                                  "AdminMenuControllerDeleteButton",
                                  {
                                    isDisabled: isDeleteMenuItemsEnabledForRole,
                                  }
                                )}
                                showAccessDeniedModal={
                                  isDeleteMenuItemsEnabledForRole
                                }
                              />
                              <EditButton
                                type={ENUMS_EDIT.types.TYPE_C}
                                className={cx("AdminMenuControllerEditButton", {
                                  isDisabled: isEditMenuEnabledForRole,
                                })}
                                onClick={() =>
                                  handleOnEditCategory(category.id)
                                }
                                isDisabled={isEditMenuEnabledForRole}
                              />
                              <CTAButton
                                type={CTA_BUTTON_ENUMS.types.TYPE_Z}
                                icon={<ArrowIcon />}
                                onClick={() =>
                                  setExpandItemView(!expandItemView)
                                }
                                className={cx(
                                  "AdminMenuControllerExpandButton",
                                  {
                                    isExpanded: expandItemView,
                                  }
                                )}
                              />
                            </div>
                          </div>
                          {expandItemView && (
                            <div className="DraggableCardContent NoDraggableCardContent">
                              <div
                                className={cx("DraggableCardLeftContainer", {
                                  hasNoItem: category.menuItems.length === 0,
                                })}
                              >
                                {showItemsByCategory(category.id)}
                              </div>
                              {category.menuItems.length === 0 ? (
                                <EmptyState
                                  description={t(
                                    "menu.item.emptyAdminMenuItemDescription"
                                  )}
                                  icon={ICON_EMPTY_MENU}
                                  isAdmin
                                  className="AdminMenuControllerEmptyMenuItemState"
                                />
                              ) : (
                                <DragDropContext
                                  onDragEnd={(result) => {
                                    if (isLoadingUpdateMenu) return;
                                    handleDragEnd(result);
                                  }}
                                >
                                  <div className="DraggableCardRightContainer">
                                    <Droppable
                                      droppableId={`category-${category.id.toString()}`}
                                      type={`droppableSubItem-${category.id.toString()}`}
                                    >
                                      {(provided) => {
                                        return (
                                          <div ref={provided.innerRef}>
                                            <DraggableListVertical
                                              items={category.menuItems
                                                .map((menuItem, index) => ({
                                                  id: `menuItem-${menuItem.id}`,
                                                  headerComponent: (
                                                    <>
                                                      <div className="DraggableCardDragAndLabel">
                                                        <h6 className="DraggableCardLabel Medium">
                                                          {index + 1}
                                                        </h6>
                                                        <img
                                                          src={DRAG_DROP_ICON}
                                                          alt="Drag and drop icon"
                                                          className="DragDropIcon"
                                                        />
                                                      </div>
                                                      <h6 className="Medium DraggableCardItemName">
                                                        {displayDataByLanguage(
                                                          menuItem.name
                                                        )}
                                                      </h6>
                                                    </>
                                                  ),
                                                }))
                                                .filter(Boolean)}
                                              sizeVariant={
                                                ENUMS_DRAGGABLE_CARD.types
                                                  .SIZE_S
                                              }
                                              canDragWholeCard
                                              isLoadingUpdateMenu={
                                                isLoadingUpdateMenu ||
                                                isEditMenuEnabledForRole
                                              }
                                            />
                                            {provided.placeholder}
                                          </div>
                                        );
                                      }}
                                    </Droppable>
                                  </div>
                                </DragDropContext>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
              <div>
                <DragDropContext
                  onDragEnd={(result) => {
                    if (isLoadingUpdateMenu) return;
                    handleDragEnd(result);
                  }}
                >
                  <Droppable droppableId="allCategories" type="droppableItem">
                    {(provided) => (
                      <div
                        className="AdminMenuControllerCategories"
                        ref={provided.innerRef}
                      >
                        <DraggableListVertical
                          sizeVariant={ENUMS_DRAGGABLE_CARD.types.SIZE_L}
                          items={allCategories
                            .filter(
                              (category) =>
                                category.type !== menuCategoryType.promotion
                            )
                            .map(renderCategory)}
                          isLoadingUpdateMenu={
                            isLoadingUpdateMenu || isEditMenuEnabledForRole
                          }
                          categories={allCategories.filter(
                            (category) =>
                              category.type !== menuCategoryType.promotion
                          )}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </>
          )}
        </>
      )}
      {hasUnsavedPositionChanges && (
        <div className="AdminMenuControllerBottomContainer">
          <PrimaryButton
            type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
            className="AdminMenuControllerSaveButton"
            onClick={handleOnPositionsSave}
            isLoading={isLoadingUpdateMenu}
          />
        </div>
      )}

      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_B}
        title={t("modal.warningModalTitleUnsavedChanges")}
        description={t("modal.warningModalDescription")}
        mainElementRefConfirm={updateChangesConfirmRef}
        openSlide={isUpdateChangesConfirmationOpen}
        onCancel={handleCancelUpdateChanges}
        onConfirm={handleConfirmChanges}
      />

      <AddCategoryModal
        allCategories={categories}
        title={t("menu.category.category")}
        openSlide={openSlideCategory}
        setOpenSlide={setOpenSlideCategory}
        hasPublishMode
        isMultiLanguage
        mainElementRef={mainElementRefCategory}
        setOutsideClickAction={setOutsideClickActionCategory}
        hasSchedule
        isLoading={isLoadingCreateCategory || isLoadingUpdateCategory}
        item={selectedCategory}
        onSave={handleOnSaveCategory}
        onDelete={handleOnDeleteCategory}
      />
      <AccessDeniedModal
        onClose={() => setOpenSlideDeniedModal(false)}
        openSlide={openSlideDeniedModal}
        mainElementRef={mainElementRefDeniedModal}
      />
      {selectedCategory &&
      selectedCategory.type === menuCategoryType.standard_category ? (
        <AddMenuItemModal
          item={selectedItem}
          mainElementRef={mainElementRefMenuItem}
          title={t("menu.item.item")}
          setOpenSlide={setOpenSlideMenuItem}
          openSlide={openSlideMenuItem}
          onSave={handleOnSaveMenuItem}
          onDelete={handleOnDeleteMenuItem}
          selectedCategory={{
            id: selectedCategory?.id,
            name: selectedCategory?.name,
          }}
          setOutsideClickAction={setOutsideClickAction}
          isLoading={isLoadingCreateMenuItem || isLoadingUpdateMenuItem}
          showDeniedModal={isDeleteMenuItemsEnabledForRole}
        />
      ) : selectedCategory &&
        selectedCategory.type === menuCategoryType.special_category ? (
        <AddSpecialCategoryItemModal
          title={t("menu.category.specialCategory")}
          openSlide={openSlideMenuItem}
          selectedCategory={selectedCategory}
          setOpenSlide={setOpenSlideMenuItem}
          mainElementRef={mainElementRefMenuItem}
          setOutsideClickAction={setOutsideClickAction}
        />
      ) : (
        <AddPromotionOrBannerModal
          item={selectedItem}
          mainElementRef={mainElementRefMenuItem}
          title={
            selectedCategory &&
            selectedCategory.type === menuCategoryType.banner
              ? t("menu.category.banner")
              : t("menu.category.promotion")
          }
          setOpenSlide={setOpenSlideMenuItem}
          openSlide={openSlideMenuItem}
          onSave={handleOnSaveMenuItem}
          onDelete={handleOnDeleteMenuItem}
          selectedCategory={{
            id: selectedCategory?.id,
            name: selectedCategory?.name,
          }}
          selectedCategoryType={selectedCategory?.type}
          setOutsideClickAction={setOutsideClickAction}
          isLoading={isLoadingCreateMenuItem || isLoadingUpdateMenuItem}
          showDeniedModal={isDeleteMenuItemsEnabledForRole}
        />
      )}

      {openSlideAITranslate && (
        <AdminAiTranslateModal
          openSlide={openSlideAITranslate}
          setOpenSlide={setOpenSlideAITranslate}
          mainElementRef={mainElementRefAITranslate}
        />
      )}
      <TranslateWarningModal
        warningModalOpenSlide={warningOpenSlide}
        warningModalRef={mainElementRefWarning}
        warningModalSetOpenSlide={setWarningOpenSlide}
      />
    </div>
  );
};

export default AdminMenuController;
