import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GuestWebsocketContext } from "utils/context-api/GuestWebsocketContext";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import useChatControllerHelper from "utils/hooks/websocket/useChatControllerHelper";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import BackButton, { ENUMS } from "components/buttons/back-button/BackButton";
import NewChat from "components/elements/new-chat/NewChat";
import ImageWithInitials from "pages/client/menu-v2/image-with-placeholder/ImageWithInitials";
import { createDOBucketName } from "utils/DO-Spaces";
import { generateInitial } from "utils/helpers";

const GuestChatController = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.chat });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateToPreservedBackUrl } = useInternalNavigation();
  const { publishTableChatMessage, messages } = useContext(
    GuestWebsocketContext
  );
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const isOrderEmpty = !order;
  const orderId = useSelector((state) => state[STORE_NAMES.orders].orders)[0]
    ?.id;
  const businessId = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  ).businessId;
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const roleEnums = useSelector(
    (state) => state[STORE_NAMES.app].enums
  )?.roleName;

  const author = { id: guest.id, role: { name: roleEnums.guest } };

  const firstNames = order?.guests
    .map((g) =>
      g.person.id === guest.id
        ? t("chat.you")
        : g.firstName || `${t("dashboard.guest.guest")} ${g.person.id}`
    )
    .join(", ");

  const {
    authorLastReadTime,
    handleNewMessage,
    updateUserLastReadTimeWithArgs,
  } = useChatControllerHelper({
    author: author,
    onPublishMessage: publishTableChatMessage,
    businessId: businessId,
    orderId: orderId,
    userId: author.id,
  });
  useEffect(() => {
    if (isOrderEmpty) {
      return navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
      );
    }
  }, [isOrderEmpty]);

  const backButtonClickHandler = () => {
    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}`,
    });
  };

  const ChatHeader = (
    <div className="ChatHeader">
      <BackButton type={ENUMS.types.TYPE_D} onClick={backButtonClickHandler} />
      <div className="ChatHeaderGroup">
        <div className="ChatHeaderProfile">
          <ImageWithInitials
            alt={"logo"}
            className="FooterLogoImage"
            imageSource={createDOBucketName(business.images.logo)}
            initialText={generateInitial([business.name])}
          />
        </div>
        <div className="ChatHeaderTitleGroup">
          <h5 className="SemiBold">{order?.table?.name}</h5>
          <h6 className="h7">{firstNames}</h6>
        </div>
      </div>
    </div>
  );

  return business?.chat ? (
    <NewChat
      author={author}
      messages={messages}
      onNewText={handleNewMessage}
      chatHeader={ChatHeader}
      authorLastReadTime={authorLastReadTime}
      updateUserLastReadTime={updateUserLastReadTimeWithArgs}
      topicId={orderId}
    />
  ) : null;
};

export default GuestChatController;
