import React, { useMemo } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { ReactComponent as IconArrow } from "assets/icons/menuv2/ArrowLeft.svg";
import ModelViewer from "components/model-viewer/ModelViewer";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { createDOBucketName } from "utils/DO-Spaces";
import useInternalNavigation from "utils/hooks/useInternalNavigation";

import styles from "./MenuItemAR.module.scss";

const MenuItemAR = () => {
  const { t } = useTranslation();
  const { businessId, menuItemId } = useParams();
  const [searchParams] = useSearchParams();
  const { navigateToPreservedBackUrl } = useInternalNavigation();
  const { findItemById, isValidMenuItem } = useMenuHelper();

  const selectedMenuItem = useMemo(() => {
    if (!menuItemId) {
      return null;
    }
    return findItemById(menuItemId);
  }, [menuItemId, findItemById]);

  const isActiveArView = Boolean(
    selectedMenuItem &&
      selectedMenuItem.model3D &&
      selectedMenuItem.model3D.ios &&
      selectedMenuItem.model3D.general
  );

  if (!isValidMenuItem(selectedMenuItem?.id) || !isActiveArView) {
    return (
      <Navigate
        to={`${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`}
      />
    );
  }

  const handleGoBack = () => {
    const memorizedScroll = searchParams.get(QUERY_PARAMS.memorizedScroll);

    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      queries: {
        ...(memorizedScroll && {
          [QUERY_PARAMS.targetedScroll]: memorizedScroll,
        }),
      },
    });
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.BackWrapper} onClick={handleGoBack}>
            <IconArrow />
          </div>
          <h4 className={cx("SemiBold", styles.Title)}>{t("menu.viewAr")}</h4>
        </div>
        <ModelViewer
          src={createDOBucketName(selectedMenuItem?.model3D?.general)}
          iosSrc={createDOBucketName(selectedMenuItem?.model3D?.ios)}
        />
      </div>
    </div>
  );
};

export default MenuItemAR;
