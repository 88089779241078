import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import If from "components/if/If";

import styles from "./AdminPageHeader.module.scss";

const AdminPageHeader = ({
  leftIcon,
  leftText,
  centerText,
  rightText,
  rightIconFirst,
  rightIconSecond,
  className,
  onClickLeftIcon,
  onClickRightIconFirst,
  onClickRightIconSecond,
}) => {
  return (
    <div className={cx(styles.Root, className)}>
      <If state={Boolean(leftIcon || leftText)}>
        <div
          className={cx(styles.Left, {
            [styles.isClickable]: Boolean(onClickLeftIcon),
          })}
          onClick={onClickLeftIcon}
        >
          {leftIcon}
          <h5 className="Body2-Pos Medium">{leftText}</h5>
        </div>
      </If>
      <div className={styles.Center}>
        <h5 className="Body2-Pos SemiBold">{centerText}</h5>
      </div>
      <If state={Boolean(rightText || rightIconFirst || rightIconSecond)}>
        <div className={styles.Right}>
          <h5 className="Body2-Pos Medium">{rightText}</h5>
          <div
            className={cx({
              [styles.isClickable]: Boolean(onClickRightIconFirst),
            })}
            onClick={onClickRightIconFirst}
          >
            {rightIconFirst}
          </div>
          <div
            className={cx({
              [styles.isClickable]: Boolean(onClickRightIconSecond),
            })}
            onClick={onClickRightIconSecond}
          >
            {rightIconSecond}
          </div>
        </div>
      </If>
    </div>
  );
};
AdminPageHeader.propTypes = {
  leftIcon: PropTypes.node,
  leftText: PropTypes.string,
  centerText: PropTypes.string,
  rightText: PropTypes.string,
  rightIconFirst: PropTypes.node,
  rightIconSecond: PropTypes.node,
  className: PropTypes.string,
  onClickLeftIcon: PropTypes.func,
  onClickRightIconFirst: PropTypes.func,
  onClickRightIconSecond: PropTypes.func,
};
export default AdminPageHeader;
