import { LANGUAGES } from "utils/constants/language";
import { DEFAULT_FALLBACK_LANGUAGE } from "utils/constants/data/base";
import {
  COMPACT_VIEW_COLUMNS,
  PRINTER_ALIGNMENTS,
} from "utils/constants/electron/electron-constants";

/**
 * Finds the name in the preferred language order.
 * @param {Array<{languageCode: string, value: string}>} names - List of name objects containing language codes and values
 * @param {string[]} preferredLanguages - List of preferred language codes (e.g., ['EN', 'TR'])
 * @param {string[]} fallbackLanguages - List of fallback language codes (e.g., ['EN', 'TR'])
 * @returns {string|null} The name value in the preferred language, fallback language, or null if not found
 * @example
 * const names = [
 *   { languageCode: 'TR', value: 'Kahve' },
 *   { languageCode: 'EN', value: 'Coffee' }
 * ];
 * findNameInPreferredLanguage(names, ['DE', 'TR'], ['EN']); // Returns 'Kahve'
 */
export const findNameInPreferredLanguage = (
  names,
  preferredLanguages,
  fallbackLanguages
) => {
  const getName = (languages) =>
    names.find((name) => languages.includes(name.languageCode))?.value;

  return getName(preferredLanguages) || getName(fallbackLanguages) || "";
};

/**
 * Adds order details to the print command builder.
 * @param {PrintCommandBuilder} builder - The print command builder instance.
 * @param {Object} order - The order object.
 * @param {Function} t - Translation function.
 * @param {Object} options - Configuration options.
 */
export const addOrderDetails = ({ builder, order, t }) => {
  builder
    .addAlign("left")
    .addText(`${t("printer.orderNumber")}: ${order.id}`)
    // TODO Efe/Rasif - use timezone of Business.
    .addText(`${t("printer.date")}: ${new Date().toLocaleString("tr-TR")}`)
    .addText(
      `${t("printer.table")}: ${
        `${order.table.zoneName} / ${order.table?.name}` || t("printer.unknown")
      }`
    )
    .addText(
      `${t("printer.waiter")}: ${
        order.assignee?.name || t("printer.noWaiterAssigned")
      }`
    );
};

export const createProductHeader = ({ t, isTotal }) => {
  const productRows = [
    { text: t("printer.product"), align: PRINTER_ALIGNMENTS.ITEM_NAME },
    { text: t("printer.quantity"), align: PRINTER_ALIGNMENTS.ORDER_COUNT },
    { text: t("printer.price"), align: PRINTER_ALIGNMENTS.PRICE },
    { text: t("printer.total"), align: PRINTER_ALIGNMENTS.TOTAL_PRICE },
  ];

  return isTotal ? productRows : productRows.slice(0, COMPACT_VIEW_COLUMNS);
};

export const getItemModificationsText = ({
  modifications,
  language,
  supportedLanguages,
  t,
}) => {
  if (!modifications?.length) return "";

  return modifications
    .map((mod) => {
      const modName =
        findNameInPreferredLanguage(
          mod.name,
          [language, DEFAULT_FALLBACK_LANGUAGE],
          supportedLanguages
        ) || t("printer.unknownOption");

      const selectedOptions = mod.options
        .map(
          (opt) =>
            findNameInPreferredLanguage(
              opt.name,
              [language, DEFAULT_FALLBACK_LANGUAGE],
              supportedLanguages
            ) || t("printer.unknownChoice")
        )
        .join(", ");
      console.log("modName, selectedOptions: ", { modName, selectedOptions });

      return `-> ${modName}: ${selectedOptions}`;
    })
    .join("\n");
};

export const createItemRow = ({ item, count, currency, isTotal }) => {
  const rowData = [
    { text: item.name, align: PRINTER_ALIGNMENTS.ITEM_NAME },
    { text: `${count}x`, align: PRINTER_ALIGNMENTS.ORDER_COUNT },
    { text: `${item.priceSell} ${currency}`, align: PRINTER_ALIGNMENTS.PRICE },
    {
      text: `${item.totalPrice.toFixed(2)} ${currency}`,
      align: PRINTER_ALIGNMENTS.TOTAL_PRICE,
    },
  ];

  return isTotal ? rowData : rowData.slice(0, COMPACT_VIEW_COLUMNS);
};

/**
 * Adds order items to the print command builder.
 * @param {Object} params - Parameters object
 * @param {PrintCommandBuilder} params.builder - The print command builder instance
 * @param {Object} params.orderData - The order data object containing items
 * @param {Function} params.t - Translation function
 * @param {Object} params.options - Configuration options including language and currency
 * @returns {number} Total price of all items
 */
export const addOrderItems = ({ builder, orderData, t, options }) => {
  const supportedLanguages = Object.values(LANGUAGES)
    .map((language) => language.code)
    .filter(Boolean);
  let totalPrice = 0;

  // Add header
  const productRows = createProductHeader({ t, isTotal: options.isTotal });
  builder.addDrawLine().addTable(productRows).addDrawLine();

  // Process items
  orderData.orderItems.forEach((orderItem) => {
    const itemName =
      findNameInPreferredLanguage(
        orderItem.item.name,
        [options.language, "EN"],
        supportedLanguages
      ) || t("printer.unknownProduct");
    console.log("itemName: ", itemName);

    totalPrice += orderItem.payment.orderItemTotalPrice;

    // Add item row
    const rowData = createItemRow({
      item: {
        name: itemName,
        priceSell: orderItem.item.priceSell,
        totalPrice: orderItem.payment.orderItemTotalPrice,
      },
      count: orderItem.count,
      currency: options.currency,
      isTotal: options.isTotal,
    });
    builder.addTable(rowData);

    // Add modifications if any
    const modificationsText = getItemModificationsText({
      modifications: orderItem.item.modifications,
      language: options.language,
      supportedLanguages,
      t,
    });
    if (modificationsText) {
      builder.addText(modificationsText);
    }
  });

  return totalPrice;
};

/**
 * Adds order messages to the order data.
 * @param {Object} order - The order object.
 * @param {Object} orderData - The order data object.
 */
export const addOrderMessages = (order, orderData) => {
  order.guests?.forEach((guest) => {
    if (guest.orderMessage) {
      guest.orderMessage.map((message) => {
        orderData.orderMessages.push(message);
      });
    }
  });
};

export const isValidOrderItem = (orderItem, isWaitForAccept) => {
  if (orderItem.isArchived) return false;
  if (!isWaitForAccept) return orderItem.isConfirmed === true;
  return true;
};

export const collectOrderItems = ({ order, options }) => {
  const userOrders =
    order.users?.reduce((items, user) => [...items, ...user.orderItems], []) ||
    [];

  const guestOrders =
    order.guests?.reduce(
      (items, guest) => [
        ...items,
        ...guest.orderItems.filter((item) =>
          isValidOrderItem(item, options.isWaitForAccept)
        ),
      ],
      []
    ) || [];

  return [...userOrders, ...guestOrders].filter((item) => !item.isArchived);
};

export const buildHeaderSection = ({ builder, businessName, t, options }) => {
  builder
    .addAlign(PRINTER_ALIGNMENTS.HEADER.toLowerCase())
    .addBold(true)
    .addText(businessName || t("printer.unknownBusiness"))
    .addBold(false)
    .addNewline();

  if (options.isWaitForAccept) {
    builder.addText(t("printer.awaitingApproval"));
  }
};

export const buildMessageSection = ({ builder, messages, t }) => {
  if (messages.length > 0) {
    builder.addDrawLine();
    messages.forEach((message) => {
      builder
        .addAlign(PRINTER_ALIGNMENTS.MESSAGE.toLowerCase())
        .addText(`${t("printer.orderNote")}: ${message}`);
    });
  }
};

export const buildTotalSection = ({ builder, totalPrice, t, options }) => {
  if (options.isTotal) {
    builder
      .addDrawLine()
      .addAlign("center")
      .addBold(true)
      .addText(
        `${t("printer.totalAmount")}: ${totalPrice.toFixed(2)} ${
          options.currency
        }`
      )
      .addBold(false)
      .addDrawLine()
      .addText(t("printer.bonAppetit"))
      .addText(t("printer.hopeToSeeYouAgain"));
  }
};

export const buildFooterSection = (builder, t) => {
  builder
    .addAlign(PRINTER_ALIGNMENTS.FOOTER.toLowerCase())
    .addDrawLine()
    .addText(t("printer.companyName"))
    .addText(t("printer.advancedReceiptSystems"))
    .addCut();
};

export const buildBeep = (builder, type) => {
  builder.addBeep(type);
};
