import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";
import {
  concatFirstNameWithLastName,
  formatTime,
  generateInitial,
} from "utils/helpers";
import NO_MESSAGES from "assets/icons/chat/NewNoMessages.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import { ReactComponent as ChatScrollIcon } from "assets/icons/chat/arrowRead.svg";
import ImageWithInitials from "pages/client/menu-v2/image-with-placeholder/ImageWithInitials";
import If from "components/if/If";
import EmptyState from "components/admin/empty-state/EmptyState";
import SuperSearchInput from "pages/client/magic-order/super-search-input/SuperSearchInput";
import { ReactComponent as StarIcon } from "assets/icons/chat/Star.svg";

import "./NewChat.scss";

const colors = [
  "#FF6B6B",
  "#F9A620",
  "#4D96FF",
  "#6BCB77",
  "#9B5DE5",
  "#FF924C",
  "#FF70A6",
  "#FFB84C",
  "#FFD93D",
  "#38B6FF",
];

const NewChat = ({
  author,
  messages,
  onNewText,
  authorLastReadTime,
  updateUserLastReadTime,
  chatHeader,
  topicId,
}) => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const chatBodyRef = useRef(null);
  const business = useSelector((state) => state[STORE_NAMES.business]).business;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const businessImage = createDOBucketName(business?.images?.logo);
  const roleEnums = useSelector(
    (state) => state[STORE_NAMES.app].enums
  )?.roleName;

  const handleOnSubmit = () => {
    if (newMessage.trim() !== "") {
      onNewText(newMessage.trim());
      setNewMessage("");
    }
  };

  const checkOwnMessage = (authorId) => {
    return authorId === author.id;
  };

  const scrollToBottom = ({ smooth }) => {
    chatBodyRef?.current?.scrollTo({
      top: chatBodyRef.current.scrollHeight,
      behavior: smooth ? "smooth" : "auto",
    });
  };

  useEffect(() => {
    if (
      messages.length > 0 &&
      (!isScrolled || checkOwnMessage(messages[messages.length - 1].author.id))
    ) {
      scrollToBottom({ smooth: false });
      updateUserLastReadTime();
    }
  }, [messages]);

  const unReadMessages = messages.filter(
    (message) =>
      !checkOwnMessage(message.author.id) &&
      authorLastReadTime < message.dateTime
  );

  const onScrollHandler = (e) => {
    if (
      parseInt(e.target.scrollHeight - e.target.clientHeight) -
        parseInt(e.target.scrollTop) <
      50
    ) {
      setIsScrolled(false);
      updateUserLastReadTime();
    } else {
      setIsScrolled(true);
    }
  };

  const getGuestProfileInfo = (authorId) => {
    const order = orders.find((order) => order.id === topicId);

    const guestInOrder = order.guests.find(
      (guest) => guest.person.id === authorId
    );
    return {
      name:
        concatFirstNameWithLastName({
          firstName: guestInOrder?.firstName,
          lastName: guestInOrder?.lastName,
        }) || `${t("dashboard.guest.guest")} ${authorId}`,
      profilePicture: createDOBucketName(guestInOrder?.profilePic),
    };
  };

  const authorColors = useMemo(() => {
    const res = {};
    const allAuthorIds = new Set(
      messages
        .flatMap((message) => message.author.id)
        .filter((id) => id !== author.id)
    );
    [...allAuthorIds].forEach((authorId, index) => {
      res[authorId] = colors[index % colors.length];
    });
    return res;
  }, [messages, author.id]);

  return (
    <div className="NewChat">
      {chatHeader}
      {messages.length > 0 ? (
        <div
          className="NewChatBody"
          ref={chatBodyRef}
          onScroll={onScrollHandler}
        >
          {messages?.map((message, index) => {
            const isSameAuthor =
              index > 0 && message.author.id === messages[index - 1].author.id;
            const isOwnMessage = checkOwnMessage(message.author.id);
            return (
              <div
                key={index}
                className={cx("NewChatBodyMessageContainer", {
                  isOwnMessage: isOwnMessage,
                  isSameAuthor: isSameAuthor,
                })}
              >
                {!isOwnMessage && !isSameAuthor && (
                  <div className="NewChatBodyMessageAuthor">
                    <ImageWithInitials
                      imageSource={
                        message.author.role.name === roleEnums.guest
                          ? getGuestProfileInfo(message.author.id)
                              .profilePicture
                          : businessImage
                      }
                      alt="avatar"
                      className="NewChatHeaderAvatarImage"
                      initialText={
                        message.author.role.name === roleEnums.guest
                          ? generateInitial([
                              getGuestProfileInfo(message.author.id).name,
                            ])
                          : generateInitial([business.name])
                      }
                    />
                  </div>
                )}
                <div className="NewChatBodyMessage">
                  {!isOwnMessage && !isSameAuthor && (
                    <>
                      <h6
                        className="NewChatBodyMessageAuthorName SemiBold"
                        style={{ color: authorColors[message.author.id] }}
                      >
                        {message.author.role.name === roleEnums.guest
                          ? getGuestProfileInfo(message.author.id).name
                          : business.name}
                        <If
                          state={message.author.role.name !== roleEnums.guest}
                        >
                          <StarIcon />
                        </If>
                      </h6>
                    </>
                  )}
                  <h4 className="h4 Medium">{message.text}</h4>
                  <h6 className="h8 NewChatBodyMessageTime">
                    {formatTime(message.dateTime)}
                  </h6>
                </div>
              </div>
            );
          })}
          {isScrolled && (
            <div
              className="NewChatScrollToBottom"
              onClick={() => scrollToBottom({ smooth: true })}
            >
              <ChatScrollIcon />
              <h6 className="NewChatUnReadMessageCount Medium">
                {unReadMessages.length || undefined}
              </h6>
            </div>
          )}
        </div>
      ) : (
        <EmptyState
          isAdmin={false}
          icon={NO_MESSAGES}
          description={t("chat.noMessages")}
        />
      )}

      <div className="NewChatFooter">
        <SuperSearchInput
          handleSaveSpeech={handleOnSubmit}
          resultText={newMessage}
          setTypedText={setNewMessage}
          loadingAiSuperSearchMenu={false}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          placeholder={t("funZone.wheelOfFortune.typeHere")}
        />
      </div>
    </div>
  );
};

NewChat.propTypes = {
  /**
   * An array of messages
   */
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        name: PropTypes.string,
        role: PropTypes.object,
      }).isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  /**
   * A function to handle the submission of new text
   */
  onNewText: PropTypes.func,
  /**
   * A boolean flag to determine if the NewChat has a header
   */
  chatHeader: PropTypes.node,
  author: PropTypes.object,
  authorLastReadTime: PropTypes.string,
  updateUserLastReadTime: PropTypes.func,
  topicId: PropTypes.number,
};

export default NewChat;
