import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { RotatingLines } from "react-loader-spinner";

import If from "components/if/If";

import styles from "./IconButton.module.scss";

export const ENUMS_TYPE = {
  Primary: "Primary",
  Secondary: "Secondary",
  Tertiary: "Tertiary",
  Neutral: "Neutral",
  Text: "Text",
};
export const ENUMS_SHAPE = {
  Square: "Square",
  Circle: "Circle",
};
const getLoadingStrokeColor = (type) => {
  switch (type) {
    case ENUMS_TYPE.Neutral:
      return "#58626C";
    case ENUMS_TYPE.Primary:
      return "#131719";
    default:
      return "#4547EA";
  }
};

const IconButton = ({
  onClick,
  className,
  type = ENUMS_TYPE.Neutral,
  isLoading = false,
  isDisabled = false,
  icon,
  shapeType = ENUMS_SHAPE.Square,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={cx(styles.Root, styles[type], styles[shapeType], className, {
        [styles.isLoading]: isLoading,
        [styles.isDisabled]: isDisabled,
      })}
    >
      <If state={isLoading}>
        <RotatingLines
          strokeColor={getLoadingStrokeColor(type)}
          height="24"
          width="24"
          strokeWidth="4"
        />
      </If>
      <If state={!isLoading}>{icon}</If>
    </button>
  );
};

IconButton.propTypes = {
  type: PropTypes.oneOf(Object.values(ENUMS_TYPE)),
  shapeType: PropTypes.oneOf(Object.values(ENUMS_SHAPE)),
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default IconButton;
