import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { filterMenuItemByIsPublishedAndSchedule } from "utils/general";
import { ADMIN_MENU_ITEM_TYPE } from "pages/admin/admin-pages/admin-order/admin-order-menu/AdminOrderMenu";
import OrderMenuItemWrapper from "components/admin/cards/order-menu-item/order-menu-item-wrapper/OrderMenuItemWrapper";
import useLanguage from "utils/hooks/useLanguage";

import "./AdminOrderMenuSection.scss";

const AdminOrderMenuSection = ({
  category,
  menuViewType,
  setActiveCategory,
  onClick,
  onAdd,
  isAdmin,
  isOrderSection,
  categoriesRef,
  scrollElement,
  categories,
  searchValue,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const categoryRef = useRef(null);

  let menuItems = filterMenuItemByIsPublishedAndSchedule(
    category.menuItems,
    category.id,
    displayDataByLanguage,
    searchValue
  );
  if (menuItems?.length < 1) {
    menuItems = filterMenuItemByIsPublishedAndSchedule(
      category.menuItems,
      category.id,
      displayDataByLanguage,
      ""
    );
  }
  const handleScroll = () => {
    const categoryTopDistance =
      categoryRef.current?.getBoundingClientRect().top;
    if (categoryTopDistance > 0 && categoryTopDistance < 200) {
      setActiveCategory(category.id);
    }
  };

  useEffect(() => {
    if (isOrderSection && categoriesRef) {
      return categoriesRef.current.addEventListener("scroll", handleScroll);
    }
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
  }, [categories]);

  const isCategoryHasItems = menuItems.some(
    (menuItem) => menuItem.categoryId === category.id
  );

  return (
    <>
      {isCategoryHasItems && (
        <div
          id={category.id}
          ref={categoryRef}
          className={cx("AdminOrderMenuSection", {
            isAdmin: isAdmin,
          })}
        >
          <div
            className={
              isOrderSection
                ? "MenuDisplaySectionNameForOrderPage"
                : "MenuDisplaySectionName"
            }
          >
            {isOrderSection ? (
              <h1 className={"SemiBold"}>
                {displayDataByLanguage(category.name)}
              </h1>
            ) : (
              <h1 className={"Medium MenuDisplaySectionNameCtgTitle"}>
                {displayDataByLanguage(category.name)}
              </h1>
            )}
          </div>
          {menuViewType === ADMIN_MENU_ITEM_TYPE && (
            <OrderMenuItemWrapper
              items={menuItems}
              onClick={onClick}
              onAdd={onAdd}
            />
          )}
        </div>
      )}
    </>
  );
};

AdminOrderMenuSection.propTypes = {
  /**
   * The menu category object.
   */
  category: PropTypes.object,
  /**
   * The id of menu view type to display (e.g., SCROLL, GRID, etc.).
   */
  menuViewType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * A function to set the active category.
   */
  setActiveCategory: PropTypes.func,

  /**
   * The onClick function to be called when a specific action is triggered.
   */
  onClick: PropTypes.func,

  /**
   * The onAdd function to be called when adding an item.
   */
  onAdd: PropTypes.func,

  /**
   * A flag indicating whether the user is an admin.
   */
  isAdmin: PropTypes.bool,

  /**
   * Search input value
   */
  searchValue: PropTypes.string,

  isOrderSection: PropTypes.bool,
  categoriesRef: PropTypes.object,

  scrollElement: PropTypes.object,
  categories: PropTypes.array,

  setSearchParams: PropTypes.func,
  setSelectedMenuItemId: PropTypes.func,
};
export default AdminOrderMenuSection;
