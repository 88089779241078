import React from "react";
import PropTypes from "prop-types";

import useIframeListener from "components/mockup/IframeListener";

import styles from "./Mockup.module.scss";

const Mockup = ({ src }) => {
  useIframeListener();
  return (
    <div className={styles.Background}>
      <div className={styles.Root}>
        <div className={styles.TopBar}></div>
        <div className={styles.Sleep}></div>
        <div className={styles.BottomBar}></div>
        <div className={styles.Volume}></div>
        <div className={styles.InnerShadow}></div>
        <div className={styles.Screen}>
          <iframe className={styles.Iframe} src={src} />
        </div>
      </div>
    </div>
  );
};

Mockup.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Mockup;
