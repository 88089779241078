import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import FacebookLogin from "react-facebook-login";
import {
  decrement,
  increment,
  incrementByAmount,
  incrementThunk,
  setDarkMode,
} from "redux/slices/appStore";
import useAsync from "utils/hooks/useAsync";
import { STORE_NAMES } from "utils/constants/redux";
import { getHelloWorldAsync } from "utils/api/services/general";
import { BACKEND_BASE_URL } from "utils/api/axios";
import * as api from "api/QR";
import PayButton from "components/buttons/pay-button/PayButton";

import "./Info.scss";
import useGetLoggedBusinessUser from "utils/hooks/useGetLoggedBusinessUser";
import { ROLES } from "utils/constants/global";
import { Navigate } from "react-router-dom";
import { ROUTE_NAME } from "utils/constants/routes";

const QRData = [
  {
    name: "VCard",
    inputs: ["firstName", "lastName", "phoneNumber", "address"],
  },
  {
    name: "URL",
    inputs: ["url"],
  },
  {
    name: "WiFi",
    inputs: ["ssid", "password"],
  },
  {
    name: "Location",
    inputs: ["latitude", "longitude", "altitude"],
  },
  {
    name: "Mobile",
    inputs: ["phone"],
  },
  {
    name: "Mail",
    inputs: ["sendTo", "subject", "body"],
  },
];

const WINDOW = {
  origin: window.location.origin,
  protocol: window.location.protocol,
  host: window.location.host,
  hostName: window.location.hostName,

  devicePixelRatio: window.devicePixelRatio,
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  outerHeight: window.outerHeight,
  outerWidth: window.outerWidth,
  availHeight: window.screen.availHeight,
  availWidth: window.screen.availWidth,
};

const NAVIGATOR = {
  platform: navigator.platform,
  language: navigator.language,
  geolocation: "Requires HTTPS ??",
  userAgent: navigator.userAgent,
  vendor: navigator.vendor,
  appName: navigator.appName,
  appCodeName: navigator.appCodeName,
  appCodeVersion: navigator.appVersion,
};

const Info = () => {
  const user = useGetLoggedBusinessUser();
  const userRole = useMemo(() => user?.roles[0].name, [user]);

  const isSuperAdmin = userRole === ROLES.superAdmin.name;

  if (!isSuperAdmin) {
    return <Navigate to={ROUTE_NAME.signIn} />;
  }
  const reduxStoreApp = useSelector((state) => state[STORE_NAMES.app]);
  const reduxStoreUser = useSelector((state) => state[STORE_NAMES.user]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [smsMSISDN, setSmsMSISDN] = useState("");
  const [smsText, setSmsText] = useState("");
  const [qrType, setQrType] = useState(QRData[0].name);
  const [qrLogoFile, setQrLogoFile] = useState("");
  const [qrFormData, setQrFormData] = useState({});
  const [qrImage, setQrImage] = useState(null);
  const inputs = QRData.find((qr) => qr.name === qrType).inputs;
  const { result: beHello } = useAsync(getHelloWorldAsync, {
    immediate: true,
  });

  function handleQRLogoFileSelection(event) {
    setQrLogoFile(event.target.files[0]);
    const fileName = event.target.files[0].name;
    const fileNameElement = document.getElementById("FileName");
    const customButton = document.querySelector(".CustomButton");
    fileNameElement.textContent = fileName;
    customButton.textContent = "Change logo";
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    // // Append dynamic inputs to FormData
    for (const key in qrFormData) {
      formDataToSend.append(`qrData[${key}]`, qrFormData[key]);
    }

    // Append image file to FormData
    if (qrLogoFile) {
      formDataToSend.append("image", qrLogoFile);
    }
    const size = e.target.elements.size.value;

    try {
      const response = await api.getQR(formDataToSend, size, qrType);

      const base64Data = btoa(
        new Uint8Array(response).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      setQrImage("data:image/png;base64," + base64Data);
    } catch (error) {
      console.error(error);
    }
  };

  // const responseFacebook = (response) => {
  //   console.log("login with Facebook result", response);
  // };
  //
  // const responseGoogle = (response) => {
  //   const userObject = jwtDecode(response.credential);
  //   console.log("login with Google result", userObject);
  // };

  // const calculateKey = (password, login, msgBody, msisdn, sender) => {
  //   const passwordHash = md5(password).toString();
  //   const concatenatedString = passwordHash + login + msgBody + msisdn + sender;
  //   return md5(concatenatedString).toString();
  // };

  const handleSendOTP = async () => {
    const otp = Math.floor(100000 + Math.random() * 900000);
    const text = `Your OTP is: ${otp}`;
    setSmsText(text);
    await handleSendSMSClick();
  };

  const handleSendSMSClick = async () => {
    // const login = process.env.REACT_APP_SMS_LOGIN;
    // const sender = process.env.REACT_APP_SMS_SENDER;
    // const password = process.env.REACT_APP_SMS_PASSWORD;
    const otp = Math.floor(100000 + Math.random() * 900000);
    const text = `Your OTP is: ${otp}`;
    setSmsText(text);
    // const key = calculateKey(password, login, text, smsMSISDN, sender);

    // const url = `https://apps.lsim.az/quicksms/v1/send?login=${login}&msisdn=${smsMSISDN}&text=${text}&sender=${sender}&key=${key}`;

    try {
      // const res = await axios(url);
      // const res = AXIOS_GUEST_NO_AUTH.get(url);
      // const data = await res;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="InfoController">
      {/* --- Facebook Auth --- */}
      {/*<div className="InfoContainer">*/}
      {/*  <h2>Facebook Auth</h2>*/}
      {/*  <FacebookLogin*/}
      {/*    appId={process.env.REACT_APP_FACEBOOK_AUTH_APP_ID}*/}
      {/*    autoLoad={true}*/}
      {/*    fields="name,email,picture"*/}
      {/*    scope="public_profile,user_friends,user_actions.books"*/}
      {/*    callback={responseFacebook}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*/!* --- Google Auth --- *!/*/}
      {/*<div className="InfoContainer">*/}
      {/*  <h2>Google Auth</h2>*/}
      {/*  <GoogleLogin*/}
      {/*    onSuccess={responseGoogle}*/}
      {/*    onError={() => {*/}
      {/*      console.log("Login Failed");*/}
      {/*    }}*/}
      {/*    useOneTap*/}
      {/*  />*/}
      {/*</div>*/}

      {/* --- Send SMS --- */}
      <div className="InfoContainer">
        <input
          type="text"
          id="msisdn"
          value={smsMSISDN}
          onChange={(event) => {
            setSmsMSISDN(event.target.value);
          }}
          placeholder="phone number"
        />
        <input
          type="text"
          id="text"
          value={smsText}
          onChange={(event) => {
            setSmsText(event.target.value);
          }}
          placeholder="text"
        />
        <div>
          <button onClick={handleSendSMSClick} type="button">
            Send SMS
          </button>
        </div>
        <div>
          <button onClick={handleSendOTP} type="button">
            Send OTP
          </button>
        </div>
      </div>

      {/* --- Google Pay --- */}
      <div className="InfoContainer">
        <h2>Google Pay</h2>
        <PayButton />
      </div>

      {/* --- Redux --- */}
      <div className="InfoContainer">
        <h2>Redux</h2>
        <h6 className={"Row"}>
          AppStore state: <span> {reduxStoreApp.value}</span>
        </h6>
        <button
          onClick={() => {
            dispatch(increment());
          }}
          type="button"
        >
          Increment
        </button>
        <button
          onClick={() => {
            dispatch(decrement());
          }}
          type="button"
        >
          Decrement
        </button>
        <button
          onClick={() => {
            dispatch(incrementByAmount(5));
          }}
          type="button"
        >
          Increment by 5
        </button>
        <button
          onClick={() => {
            dispatch(incrementThunk(5));
          }}
          type="button"
        >
          Increment then Decrement Thunk
        </button>
      </div>

      {/* --- custom --- */}
      <div className={"InfoContainer"}>
        <h2>Custom</h2>
        <h6 className={"Row"}>
          MultiLang: <span>{t("welcome")}</span>
        </h6>
        <h6 className={"Row"}>
          Ligh-Dark Mode:
          <span
            className="LighDarkMode"
            onClick={() => {
              dispatch(setDarkMode(!reduxStoreApp.isDarkMode));
            }}
          >
            {reduxStoreApp.isDarkMode ? "Dark" : "Light"}
          </span>
        </h6>
        <h6 className={"Row"}>
          Backend baseUrl: <span>{BACKEND_BASE_URL}</span>
        </h6>
        <h6 className={"Row"}>
          BE test (../hello): <span>{beHello || "null"}</span>
        </h6>
      </div>

      {/* --- Geo Location --- */}
      <div className={"InfoContainer"}>
        <h2>GeoLocation</h2>
        <h6 className={"Row"}>
          source: <span>https://geolocation-db.com/json/</span>
        </h6>
        {reduxStoreUser.userGeolocation &&
          Object.keys(reduxStoreUser.userGeolocation).map((key) => {
            return (
              <h6 key={key} className={"Row"}>
                {key}: <span>{reduxStoreUser.userGeolocation[key]}</span>
              </h6>
            );
          })}
      </div>

      <div className={"InfoContainer"}>
        <h2>Window</h2>
        {Object.keys(WINDOW).map((key) => {
          return (
            <h6 key={key} className={"Row"}>
              {key}: <span>{WINDOW[key]}</span>
            </h6>
          );
        })}
      </div>

      <div className={"InfoContainer"}>
        <h2>Navigator</h2>
        {Object.keys(NAVIGATOR).map((key) => {
          return (
            <h6 key={key} className={"Row"}>
              {key}: <span>{NAVIGATOR[key]}</span>
            </h6>
          );
        })}
      </div>

      {/* --- QR --- */}
      <div className={"InfoContainer QRContainer"}>
        <h2>QR</h2>
        <div className={"QRImage"}>
          {qrImage ? (
            <img src={qrImage} alt="qr" />
          ) : (
            <span className={"NoQRCode"}>No QR Code</span>
          )}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={"FileInput"}>
            <input
              type="file"
              id="file-input"
              name="image"
              onChange={handleQRLogoFileSelection}
            />
            <label htmlFor="file-input" className={"CustomButton"}>
              Pick a logo for QR
            </label>
            <p className={"SelectedFileName"}>
              Selected file name: <span id={"FileName"}>none</span>
            </p>
          </div>
          <input
            className={"FormField"}
            name="size"
            type="text"
            placeholder="QR size"
          />

          <select
            className={"FormField"}
            onChange={(e) => {
              setQrType(e.target.value);
              setQrFormData({});
            }}
            value={qrType}
          >
            {QRData.map((qr) => (
              <option key={qr.name}>{qr.name}</option>
            ))}
          </select>
          {inputs.map((input) => (
            <div key={input}>
              <input
                className={"FormField"}
                name={input}
                placeholder={input.charAt(0).toUpperCase() + input.slice(1)}
                onChange={(e) => {
                  setQrFormData({
                    ...qrFormData,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          ))}
          <input type="submit" className={"SubmitButton"} />
        </form>
      </div>
    </div>
  );
};

export default Info;
