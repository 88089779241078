import React from "react";
import PropTypes from "prop-types";

import If from "components/if/If";

const ImageWithInitials = ({
  imageSource,
  className,
  alt,
  onClick,
  initialText,
}) => {
  return (
    <>
      <If state={Boolean(!imageSource)}>
        <div className={className}>
          <h2 className="Medium">{initialText}</h2>
        </div>
      </If>
      <If state={Boolean(imageSource)}>
        <img
          src={imageSource}
          className={className}
          alt={alt}
          onClick={onClick}
        />
      </If>
    </>
  );
};

ImageWithInitials.propTypes = {
  imageSource: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  initialText: PropTypes.string,
};

export default ImageWithInitials;
