import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import Spinner from "components/elements/spinner/Spinner";
import { getBrandMainLogoDark } from "utils/branding-helper";

import "./ReservationVR.scss";

const ReservationVR = ({ onClick, vrLink }) => {
  const { t } = useTranslation();
  const [vrLoading, setVrLoading] = useState(true);

  const FooterLogo = getBrandMainLogoDark();

  return (
    <div className="ReservationVR">
      <div className="ReservationVRHeader">
        <FooterLogo />
        <p className="ReservationVRHeaderTitle">{t("reservation.vr.vrView")}</p>
        <CloseButton
          onClick={onClick}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_H}
          className={"ReservationVRHeaderCloseButton"}
        />
      </div>
      <div className="ReservationVRBody">
        {vrLoading && <Spinner />}
        <iframe
          className="ReservationVRBodyIframe"
          width={"100%"}
          height={"100%"}
          src={vrLink}
          sandbox="allow-scripts allow-same-origin allow-popups"
          onLoad={() => {
            setVrLoading(false);
          }}
        />
      </div>
    </div>
  );
};

ReservationVR.propTypes = {
  onClick: PropTypes.func.isRequired,
  vrLink: PropTypes.string,
};

export default ReservationVR;
