import { API_PATH_PARAMS } from "utils/constants/api";
import AXIOS_USER from "utils/api/axios";

export const addIpToBlacklist = async ({ businessId, ip }) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.blacklist}/${ip}`
  );

export const getAllBlackListedIps = (businessId) => {
  return AXIOS_USER.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.blacklist}`
  );
};

export const deleteIpFromBlacklist = async ({ businessId, ip }) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.blacklist}/${ip}`
  );
