import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

import useTimeout from "utils/hooks/useTimeout";

const ImageVisibilityContext = createContext();

export const ImageVisibilityProvider = ({ children }) => {
  const [visibleImages, setVisibleImages] = useState({});
  const [isVisibleAllImages, setIsVisibleAllImages] = useState(false);
  const setImageVisible = (src) => {
    setVisibleImages((prev) => ({ ...prev, [src]: true }));
  };

  useTimeout({ callback: () => setIsVisibleAllImages(true), delay: 5000 });

  return (
    <ImageVisibilityContext.Provider
      value={{ visibleImages, setImageVisible, isVisibleAllImages }}
    >
      {children}
    </ImageVisibilityContext.Provider>
  );
};
ImageVisibilityProvider.propTypes = {
  children: PropTypes.node,
};

export const useImageVisibility = () => {
  return useContext(ImageVisibilityContext);
};
