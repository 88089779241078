import React, { useContext } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as LockIcon } from "assets/icons/pos/lock-01-white.svg";
import { ReactComponent as Cancel } from "assets/icons/pos/x-close.svg";
import { ReactComponent as WifiIcon } from "assets/icons/pos/wifi.svg";
import { ReactComponent as Socket } from "assets/icons/pos/switch-horizontal-01.svg";
import useCheckNetwork from "utils/hooks/useCheckNetwork";
import { AdminWebsocketContext } from "utils/context-api/AdminWebsocketContext";
import { useAdminAuth } from "utils/context-api/AdminAuthContext";
import Button, {
  ENUMS_TYPE as BUTTON_ENUMS,
} from "components/pos/button/Button";
import { ENUMS as AVATAR_WITH_TEXT_ENUMS } from "components/pos/avatar-with-text/AvatarWithText";
import Avatar, { ENUMS as AVATAR_ENUMS } from "components/pos/avatar/Avatar";
import { ReactComponent as LogOutIcon } from "assets/icons/pos/log-out-02.svg";

import If from "components/if/If";
import { useAdminLayout } from "pages/admin/admin-layout/AdminLayout";
import { initialState, setUserWithPin } from "redux/slices/userStore";
import { setIsAdminDashboardPinLocked } from "redux/slices/appStore";
import { ROUTE_NAME } from "utils/constants/routes";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";

import styles from "./AdminHomepageSidebar.module.scss";

const AdminHomepageSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { closeSidebar, isOpenSidebar } = useAdminLayout();

  const networkStatus = useCheckNetwork();
  const { isAdmin } = useAdminAuth();
  const { t } = useTranslation();
  const { isConnected } = useContext(AdminWebsocketContext);

  const [
    openSlideConfirmLogOut,
    setOpenSlideConfirmLogOut,
    mainElementRefConfirmLogOut,
  ] = useOutsideClick();

  const logoutHandler = () => {
    setOpenSlideConfirmLogOut(true);
  };

  const handleOnLogOutCancel = () => {
    setOpenSlideConfirmLogOut(false);
  };
  const handleOnLogOutConfirm = () => {
    setOpenSlideConfirmLogOut(false);
    navigate(ROUTE_NAME.signIn, { replace: true });
  };

  const handleOnLockScreen = () => {
    dispatch(setUserWithPin(initialState.userWithPin));
    dispatch(setIsAdminDashboardPinLocked(true));
    navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`, { replace: true });
  };

  return (
    <>
      <div className={cx(styles.Root, { [styles.isOpen]: isOpenSidebar })}>
        <div className={styles.Top}>
          <div className={styles.Icon} onClick={closeSidebar}>
            <h3 className="SemiBold">{t("navbarRoutes.menu")}</h3>
            <Cancel />
          </div>
          <Button
            text={t("buttons.logOut")}
            type={BUTTON_ENUMS.LogOut}
            onClick={logoutHandler}
            rightIcon={<LogOutIcon />}
          />
        </div>
        <div className={styles.Bottom}>
          <If state={!isAdmin}>
            <div className={styles.LockScreenBtnContainer}>
              <Button
                type={BUTTON_ENUMS.Lock}
                text={t("buttons.lockScreen")}
                rightIcon={<LockIcon />}
                onClick={handleOnLockScreen}
              />
            </div>
          </If>

          <div className={styles.NetworkWrapper}>
            <Avatar
              icon={<WifiIcon />}
              size={AVATAR_ENUMS.SIZE.types.AVATAR40}
              statusType={
                networkStatus
                  ? AVATAR_WITH_TEXT_ENUMS.STATUS.types.SUCCESS
                  : AVATAR_WITH_TEXT_ENUMS.STATUS.types.ERROR
              }
            />
            <Avatar
              icon={<Socket />}
              size={AVATAR_ENUMS.SIZE.types.AVATAR40}
              statusType={
                isConnected
                  ? AVATAR_WITH_TEXT_ENUMS.STATUS.types.SUCCESS
                  : AVATAR_WITH_TEXT_ENUMS.STATUS.types.ERROR
              }
            />
          </div>
        </div>
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_A}
        title={t("modal.warningModalTitleLogout")}
        mainElementRefConfirm={mainElementRefConfirmLogOut}
        openSlide={openSlideConfirmLogOut}
        onCancel={handleOnLogOutCancel}
        onConfirm={handleOnLogOutConfirm}
      />
    </>
  );
};

export default AdminHomepageSidebar;
