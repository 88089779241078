import React, { useState, createContext, useContext } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AdminBodyController from "pages/admin/admin-body/AdminBodyController";
import { STORE_NAMES } from "utils/constants/redux";
import AdminBlock from "components/admin/admin-block/AdminBlock";
import If from "components/if/If";
import AdminSidebarController from "pages/admin/admin-sidebar-controller/AdminSidebarController";
import { ReactComponent as GridIcon } from "assets/icons/pos/grid-01.svg";
import { ROUTE_NAME } from "utils/constants/routes";
import { useAdminAuth } from "utils/context-api/AdminAuthContext";
import { useAdminRouteController } from "utils/context-api/AdminRouteControllerContext";
import AdminPageHeader from "components/pos/admin-page-header/AdminPageHeader";
import { ReactComponent as LeftChevronIcon } from "assets/icons/pos/chevron-left.svg";

import styles from "./AdminLayout.module.scss";

const AdminLayoutContext = createContext();

const AdminLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const closeSidebar = () => setIsOpenSidebar(false);
  const openSidebar = () => setIsOpenSidebar(false);
  const toggleSidebar = () => setIsOpenSidebar(!isOpenSidebar);
  const { findRouteByPath, isHomePage } = useAdminRouteController();
  const { parent: parentRoute } = findRouteByPath();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const { isAdmin } = useAdminAuth();

  if (business?.isArchived && !isAdmin) {
    return <AdminBlock business={business} />;
  }

  return (
    <AdminLayoutContext.Provider
      value={{
        isOpenSidebar,
        closeSidebar,
        openSidebar,
      }}
    >
      <div className={cx(styles.Root)}>
        <AdminSidebarController />
        <div className={cx(styles.Page, { [styles.isHomePage]: isHomePage })}>
          <If state={!isHomePage}>
            <div className={styles.PageHeader}>
              <AdminPageHeader
                leftIcon={<LeftChevronIcon />}
                leftText={t("buttons.back")}
                centerText={parentRoute?.label}
                onClickLeftIcon={() =>
                  navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.adminHome}`)
                }
              />
            </div>
          </If>
          <div className={styles.Content}>
            <AdminBodyController />
          </div>
        </div>
        <button className={styles.SidebarToggleBtn} onClick={toggleSidebar}>
          <GridIcon />
        </button>
      </div>
    </AdminLayoutContext.Provider>
  );
};

export const useAdminLayout = () => {
  return useContext(AdminLayoutContext);
};

export default AdminLayout;
