import React from "react";
import PropTypes from "prop-types";

const If = ({ state, children }) => {
  return state ? <>{children}</> : null;
};

If.propTypes = {
  state: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default If;
