import IMAGE_SCROLL from "assets/images/menu-views/HorizontalScroll.png";
import IMAGE_WITHOUT from "assets/images/menu-views/WithoutView.png";
import IMAGE_GRID_VIEW from "assets/images/menu-views/GridView.png";

// import IMAGE_LIST from "assets/images/menu-views/HorizontalList.png";
import ICON_NONE from "assets/icons/curves/None.svg";
import ICON_SMALL from "assets/icons/curves/Small.svg";
import ICON_MEDIUM from "assets/icons/curves/Medium.svg";
import ICON_LARGE from "assets/icons/curves/Large.svg";
import IMG_CIRCLE from "assets/images/shapeEllipse.png";
import IMG_RECTANGLE from "assets/images/shapeRectangle.png";
import IconGrid from "assets/icons/menu-view-selection/Grid.svg";
// import IconList from "assets/icons/menu-view-selection/List.svg";
import IconScroll from "assets/icons/menu-view-selection/Scroll.svg";
import ICON_GRID_ACTIVE from "assets/icons/menu-view-selection/GridActive.svg";
import ICON_SCROLL_ACTIVE from "assets/icons/menu-view-selection/ScrollActive.svg";
// import ICON_LIST_ACTIVE from "assets/icons/menu-view-selection/ListActive.svg";
import { CURRENCY } from "utils/constants/global";

export const MENU_VIEW_ENUMS = {
  WITHOUT_IMAGE: {
    id: 1,
    key: "WITHOUT_IMAGE",
    name: "menu.view.withoutImage",
    imgSrc: IMAGE_WITHOUT,
    icon: IconGrid,
    activeIcon: ICON_GRID_ACTIVE,
  },
  SCROLL: {
    id: 2,
    key: "SCROLL",
    name: "menu.view.scroll",
    imgSrc: IMAGE_SCROLL,
    icon: IconScroll,
    activeIcon: ICON_SCROLL_ACTIVE,
  },
  GRID: {
    id: 3,
    key: "GRID",
    name: "menu.view.grid",
    imgSrc: IMAGE_GRID_VIEW,
    icon: IconScroll,
    activeIcon: ICON_SCROLL_ACTIVE,
  },
  // LIST: {
  //   id: 4,
  //   name: "menu.view.list",
  //   imgSrc: IMAGE_LIST,
  //   icon: IconList,
  //   activeIcon: ICON_LIST_ACTIVE,
  // },
};

export const SHAPE_ENUMS = {
  RECTANGLE: 1,
  CIRCLE: 2,
};

export const TABLE_SHAPE_ENUMS = {
  RECTANGLE: {
    id: 1,
    name: "map.shapes.rectangle",
    imgSrc: IMG_RECTANGLE,
  },
  CIRCLE: {
    id: 2,
    name: "map.shapes.circle",
    imgSrc: IMG_CIRCLE,
  },
};

export const BORDER_RADIUS_ENUMS = {
  types: {
    NONE: {
      id: 1,
      name: "menu.settings.curves.none",
      radius: 0,
      imgSrc: ICON_NONE,
    },
    SMALL: {
      id: 2,
      name: "menu.settings.curves.small",
      radius: 4,
      imgSrc: ICON_SMALL,
    },
    MEDIUM: {
      id: 3,
      name: "menu.settings.curves.medium",
      radius: 6,
      imgSrc: ICON_MEDIUM,
    },
    LARGE: {
      id: 4,
      name: "menu.settings.curves.large",
      radius: 8,
      imgSrc: ICON_LARGE,
    },
  },
};

export const PRIMARY_COLOR_ENUMS = {
  types: {
    GOLD: {
      id: 1,
      name: "menu.settings.colors.gold",
      color: "#FFCA40",
    },
    PURPLE: {
      id: 2,
      name: "menu.settings.colors.purple",
      color: "#B75DD7",
    },
    BLUE: {
      id: 3,
      name: "menu.settings.colors.blue",
      color: "#58A5FF",
    },
    RED: {
      id: 4,
      name: "menu.settings.colors.red",
      color: "#F34237",
    },
  },
};

export const LANGUAGE_OPTIONS = [
  {
    id: 1,
    name: "English",
    description: "English",
    code: "EN",
  },
  {
    id: 2,
    name: "Azerbaijani",
    description: "Azərbaycanca",
    code: "AZ",
  },
  {
    id: 3,
    name: "Russian",
    description: "Русский",
    code: "RU",
  },
];

export const CURRENCIES = [
  {
    id: 1,
    symbol: CURRENCY.dollar.symbol,
    code: "USD",
    description: "United State Dollars",
  },
  {
    id: 2,
    symbol: CURRENCY.azn.symbol,
    code: "GBP",
    description: "Pound Sterling",
  },
  {
    id: 3,
    symbol: CURRENCY.azn.symbol,
    code: "GBP",
    description: "Pound Sterling",
  },
];

// TODO delete this const. All enums are fetched from backend. Use that instead
export const PAYMENT_METHODS = {
  CASH: "CASH",
  POS_TERMINAL: "POS-TERMINAL",
  ONLINE: "ONLINE",
};

export const MENU_ORDER_MODES = {
  EAT_IN: "EAT_IN",
  RESERVATION: "RESERVATION",
};

export const KITCHEN_STAGE = {
  NEW: "NEW",
  PROGRESS: "PROGRESS",
  DONE: "DONE",
};

export const commonAsyncErrorMessage = "toastMessages.error.common";

export const QR_TYPES = {
  WiFi: "WiFi",
  URL: "URL",
};

export const RESERVATION_STATUS = {
  CONFIRMED: "CONFIRMED",
  PENDING: "PENDING",
  DENIED: "DENIED",
};

export const AVAILABLE_COUNTRIES = ["az", "tr", "ru", "de", "us", "ch"];

export const MONTHS = [
  {
    sequence: 0,
    name: "months.january",
  },
  {
    sequence: 1,
    name: "months.february",
  },
  {
    sequence: 2,
    name: "months.march",
  },
  {
    sequence: 3,
    name: "months.april",
  },
  {
    sequence: 4,
    name: "months.may",
  },
  {
    sequence: 5,
    name: "months.june",
  },
  {
    sequence: 6,
    name: "months.july",
  },
  {
    sequence: 7,
    name: "months.august",
  },
  {
    sequence: 8,
    name: "months.september",
  },
  {
    sequence: 9,
    name: "months.october",
  },
  {
    sequence: 10,
    name: "months.november",
  },
  {
    sequence: 11,
    name: "months.december",
  },
];

export const DEFAULT_FALLBACK_LANGUAGE = "EN";
