import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useIframeListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.url) {
        const newURL = new URL(event.data.url);
        navigate(newURL.pathname + newURL.search);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]);
};

export default useIframeListener;
