import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as IconSuperAdmin } from "assets/icons/pos/users-edit.svg";
import { ReactComponent as IconBusiness } from "assets/icons/pos/briefcase-02.svg";
import { ReactComponent as IconStatistics } from "assets/icons/pos/home-line.svg";
import { ReactComponent as IconOrders } from "assets/icons/pos/file-03.svg";
import { ReactComponent as IconUsers } from "assets/icons/pos/users-01.svg";
import { ReactComponent as IconReservation } from "assets/icons/pos/edit-05.svg";
import { ReactComponent as IconQRTableZone } from "assets/icons/pos/qr-code-02.svg";
import { ReactComponent as IconDevices } from "assets/icons/pos/server-01.svg";
import { ReactComponent as IconMenu } from "assets/icons/pos/book-open-02.svg";
import { ReactComponent as IconWeekly } from "assets/icons/pos/calendar.svg";
import { ReactComponent as IconSale } from "assets/icons/pos/sale-03.svg";
import { ReactComponent as IconOrder } from "assets/icons/pos/file-02.svg";
import { ReactComponent as IconMenuStatistic } from "assets/icons/pos/book-open-02-Gray.svg";
import { ReactComponent as IconAllItems } from "assets/icons/pos/layout-alt-02.svg";
import { ReactComponent as IconFeedBack } from "assets/icons/pos/thumbs-up.svg";
import { ReactComponent as IconPayment } from "assets/icons/pos/credit-card-02.svg";
import { ReactComponent as IconSettings } from "assets/icons/pos/settings-02-gray.svg";
import { ReactComponent as IconGuest } from "assets/icons/pos/users-03.svg";
import { ReactComponent as IconQrTable } from "assets/icons/pos/layout-alt-04.svg";
import { ReactComponent as IconQr } from "assets/icons/pos/qr-code-01.svg";
import { ReactComponent as IconMap } from "assets/icons/pos/map-02.svg";
import { ReactComponent as IconAdd } from "assets/icons/pos/file-plus-02.svg";
import { ReactComponent as IconManage } from "assets/icons/pos/briefcase-01.svg";

import {
  ROUTE_NAME,
  ROUTE_NAME_BUSINESS,
  ROUTE_NAME_DASHBOARD,
  ROUTE_NAME_DEVICE,
  ROUTE_NAME_GUESTS,
  ROUTE_NAME_MENU,
  ROUTE_NAME_ORDER,
  ROUTE_NAME_QR_TABLE_ZONE_MAP,
  ROUTE_NAME_RESERVATION,
  ROUTE_NAME_SUPER,
  ROUTE_NAME_USERS,
} from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import { useAdminAuth } from "utils/context-api/AdminAuthContext";
import { ElectronContext } from "electron/ElectronProvider";

const AdminRouteControllerContext = createContext();

const AdminRouteControllerProvider = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { restrictedRoutes, isAdmin } = useAdminAuth();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const { isElectron } = useContext(ElectronContext);

  const isRouteAccessible = useCallback(
    (routeName) => {
      return !restrictedRoutes.includes(routeName);
    },
    [restrictedRoutes]
  );

  const routes = useMemo(() => {
    const allRoutes = {
      SUPER_ADMIN: {
        key: "SUPER_ADMIN",
        path: ROUTE_NAME_SUPER,
        label: t("navbarRoutes.superAdmin"),
        icon: <IconSuperAdmin />,
        shouldDisplay: () => {
          return isAdmin;
        },
        subRoutes: {
          SUPER_BUSINESS: {
            key: "SUPER_BUSINESS",
            path: ROUTE_NAME.superBusiness,
            label: t("navbarRoutes.business"),
            icon: <IconBusiness />,
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.superBusiness);
            },
          },
          SUPER_INFRA: {
            key: "SUPER_INFRA",
            path: ROUTE_NAME.superInfra,
            label: t("navbarRoutes.infra"),
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.superInfra);
            },
          },
          SUPER_GUESTS: {
            key: "SUPER_GUESTS",
            path: ROUTE_NAME.superGuests,
            label: t("navbarRoutes.guests"),
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.superInfra);
            },
          },
          IP_BLACKLIST: {
            key: "IP_BLACKLIST",
            path: ROUTE_NAME.superIpBlacklist,
            label: t("navbarRoutes.ipBlacklist"),
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.superIpBlacklist);
            },
          },
        },
      },
      ORDERS: {
        key: "ORDERS",
        path: ROUTE_NAME_ORDER,
        label: t("navbarRoutes.orders"),
        icon: <IconOrders />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          ORDER_DASHBOARD: {
            key: "ORDER_DASHBOARD",
            path: ROUTE_NAME.adminOrderDashboard,
            label: t("navbarRoutes.orders"),
            icon: <IconOrders />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminOrderDashboard) && business
              );
            },
          },
          ORDER_MAP: {
            key: "ORDER_MAP",
            path: ROUTE_NAME.adminOrderMap,
            label: t("navbarRoutes.map"),
            icon: <IconMap />,
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.adminOrderMap) && business;
            },
          },
        },
      },
      MENU: {
        key: "MENU",
        path: ROUTE_NAME_MENU,
        label: t("navbarRoutes.menu"),
        icon: <IconMenu />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          MENU_GENERAL: {
            key: "MENU_GENERAL",
            path: ROUTE_NAME.adminMenuCreate,
            label: t("common.create"),
            icon: <IconAdd />,
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.adminMenuCreate) && business;
            },
          },
          MENU_SETTINGS: {
            key: "MENU_SETTINGS",
            path: ROUTE_NAME.adminMenuSettings,
            label: t("common.settings"),
            icon: <IconSettings />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminMenuSettings) && business
              );
            },
          },
        },
      },
      QR_TABLE_ZONE_MAP: {
        key: "QR_TABLE_ZONE_MAP",
        path: ROUTE_NAME_QR_TABLE_ZONE_MAP,
        label: t("navbarRoutes.qrAndTable"),
        icon: <IconQRTableZone />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          QR_TABLE_ZONE_MAP_TABLE: {
            key: "QR_TABLE_ZONE_MAP_TABLE",
            path: ROUTE_NAME.adminTable,
            label: t("navbarRoutes.qrAndTable"),
            icon: <IconQrTable />,
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.adminTable) && business;
            },
          },
          QR_TABLE_ZONE_MAP_QR: {
            key: "QR_TABLE_ZONE_MAP_QR",
            path: ROUTE_NAME.adminQR,
            label: t("common.qr"),
            icon: <IconQr />,

            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.adminQR) && business;
            },
          },
          QR_TABLE_ZONE_MAP_MAP: {
            key: "QR_TABLE_ZONE_MAP_MAP",
            path: ROUTE_NAME.adminMap,
            label: t("navbarRoutes.map"),
            icon: <IconMap />,
            shouldDisplay: () => {
              return isRouteAccessible(ROUTE_NAME.adminMap) && business;
            },
          },
        },
      },
      BUSINESS: {
        key: "BUSINESS",
        path: ROUTE_NAME_BUSINESS,
        label: t("navbarRoutes.business"),
        icon: <IconBusiness />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          BUSINESS_GENERAL: {
            key: "BUSINESS_GENERAL",
            path: ROUTE_NAME.adminBusinessGeneral,
            label: t("common.general"),
            icon: <IconManage />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminBusinessGeneral) && business
              );
            },
          },
          BUSINESS_SETTINGS: {
            key: "BUSINESS_SETTINGS",
            path: ROUTE_NAME.adminBusinessSettings,
            label: t("common.settings"),
            icon: <IconSettings />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminBusinessSettings) && business
              );
            },
          },
        },
      },
      STATISTICS: {
        key: "STATISTICS",
        path: ROUTE_NAME_DASHBOARD,
        label: t("navbarRoutes.statistics"),
        icon: <IconStatistics />,
        shouldDisplay: () => {
          return isRouteAccessible(ROUTE_NAME.adminStatistics) && business;
        },
        subRoutes: {
          STATISTICS_WEEKLY: {
            key: "STATISTICS_WEEKLY",
            path: ROUTE_NAME.adminDashboardWeeklyStatistics,

            label: t("navbarRoutes.weeklyStatistics"),
            icon: <IconWeekly />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardWeeklyStatistics) &&
                business
              );
            },
          },
          STATISTICS_SALES: {
            key: "STATISTICS_SALES",
            path: ROUTE_NAME.adminDashboardSalesStatistics,
            label: t("navbarRoutes.salesStatistics"),
            icon: <IconSale />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardSalesStatistics) &&
                business
              );
            },
          },
          STATISTICS_ORDER: {
            key: "STATISTICS_ORDER",
            path: ROUTE_NAME.adminDashboardOrderStatistics,
            label: t("navbarRoutes.orderStatistics"),
            icon: <IconOrder />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardOrderStatistics) &&
                business
              );
            },
          },
          STATISTICS_MENU: {
            key: "STATISTICS_MENU",
            path: ROUTE_NAME.adminDashboardMenuStatistics,
            label: t("navbarRoutes.menuStatistics"),
            icon: <IconMenuStatistic />,

            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardMenuStatistics) &&
                business
              );
            },
          },
          STATISTICS_ALL_ORDERS: {
            key: "STATISTICS_ALL_ORDERS",
            path: ROUTE_NAME.adminDashboardAllOrders,
            label: t("navbarRoutes.allOrders"),
            icon: <IconOrder />,

            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardAllOrders) &&
                business
              );
            },
          },
          STATISTICS_ALL_ITEMS: {
            key: "STATISTICS_ALL_ITEMS",
            path: ROUTE_NAME.adminDashboardAllMenus,
            label: t("navbarRoutes.allItems"),
            icon: <IconAllItems />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardAllMenus) && business
              );
            },
          },
          STATISTICS_FEEDBACKS: {
            key: "STATISTICS_FEEDBACKS",
            path: ROUTE_NAME.adminDashboardFeedback,
            label: t("navbarRoutes.feedbacks"),
            icon: <IconFeedBack />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardFeedback) && business
              );
            },
          },
          STATISTICS_PAYMENTS: {
            key: "STATISTICS_PAYMENTS",
            path: ROUTE_NAME.adminDashboardPayments,
            label: t("navbarRoutes.payments"),
            icon: <IconPayment />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminDashboardPayments) && business
              );
            },
          },
        },
      },
      USERS: {
        key: "USERS",
        path: ROUTE_NAME_USERS,
        label: t("navbarRoutes.users"),
        icon: <IconUsers />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          USERS_DASHBOARD: {
            key: "USERS_DASHBOARD",
            path: ROUTE_NAME.adminUserDashboard,
            label: t("navbarRoutes.users"),
            icon: <IconGuest />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminUserDashboard) && business
              );
            },
          },
        },
      },
      GUESTS: {
        key: "GUESTS",
        path: ROUTE_NAME_GUESTS,
        label: t("navbarRoutes.guests"),
        icon: <IconUsers />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          GUESTS_DASHBOARD: {
            key: "GUESTS_DASHBOARD",
            path: ROUTE_NAME.adminGuestDashboard,
            label: t("navbarRoutes.guests"),
            icon: <IconUsers />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminGuestDashboard) && business
              );
            },
          },
        },
      },
      RESERVATION: {
        key: "RESERVATION",
        path: ROUTE_NAME_RESERVATION,
        label: t("navbarRoutes.reservation"),
        icon: <IconReservation />,
        shouldDisplay: () => {
          return business;
        },
        subRoutes: {
          RESERVATION_DASHBOARD: {
            key: "RESERVATION_DASHBOARD",
            path: ROUTE_NAME.adminReservationDashboard,
            label: t("navbarRoutes.reservation"),
            icon: <IconReservation />,
            shouldDisplay: () => {
              return (
                isRouteAccessible(ROUTE_NAME.adminReservationDashboard) &&
                business
              );
            },
          },
        },
      },
      DEVICES: {
        key: "DEVICES",
        path: ROUTE_NAME_DEVICE,
        label: t("navbarRoutes.devices"),
        icon: <IconDevices />,
        shouldDisplay: () => {
          return isElectron && business;
        },
        subRoutes: {
          DEVICES_PRINTERS: {
            key: "DEVICES_PRINTERS",
            path: ROUTE_NAME.adminPrinters,
            label: t("navbarRoutes.devices"),
            shouldDisplay: () => {
              return isElectron && business;
            },
          },
        },
      },
    };

    return Object.values(allRoutes)
      .filter((route) => {
        return route.shouldDisplay();
      })
      .map((route) => {
        return {
          ...route,
          subRoutes: Object.values(route.subRoutes).filter((subRoute) =>
            subRoute.shouldDisplay()
          ),
        };
      })
      .filter((route) => route.subRoutes.length > 0);
  }, [t, isAdmin, isRouteAccessible, isElectron, business]);

  const subRoutesByCurrentPath = useMemo(() => {
    const existRoute = routes.find((route) =>
      pathname.startsWith(`${ROUTE_NAME.admin}${route.path}`)
    );
    if (!existRoute) return [];
    return existRoute.subRoutes;
  }, [pathname, routes]);

  const findRouteByPath = useCallback(
    (path = pathname) => {
      const existParentRoute = routes?.find((route) =>
        path.startsWith(`${ROUTE_NAME.admin}${route.path}`)
      );
      const existCurrentRoute = existParentRoute?.subRoutes.find(
        (route) => path === `${ROUTE_NAME.admin}${route.path}`
      );
      return {
        current: existCurrentRoute,
        parent: existParentRoute,
      };
    },
    [routes, pathname]
  );

  const isHomePage = useMemo(
    () => pathname === `${ROUTE_NAME.admin}${ROUTE_NAME.adminHome}`,
    [pathname]
  );

  return (
    <AdminRouteControllerContext.Provider
      value={{ routes, subRoutesByCurrentPath, findRouteByPath, isHomePage }}
    >
      {children}
    </AdminRouteControllerContext.Provider>
  );
};

AdminRouteControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdminRouteController = () => {
  return useContext(AdminRouteControllerContext);
};

export default AdminRouteControllerProvider;
