import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import IconButton, {
  ENUMS_TYPE as ICON_BUTTON_ENUMS_TYPE,
} from "components/pos/icon-button/IconButton";
import Button, { ENUMS_TYPE } from "components/pos/button/Button";
import If from "components/if/If";
import { ReactComponent as InfoIcon } from "assets/icons/pos/info-circle.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/pos/check-circle.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/pos/x-circle.svg";
import { ReactComponent as CloseIcon } from "assets/icons/pos/x.svg";

import styles from "./ConfirmModalContent.module.scss";

export const ENUMS = {
  Info: "Info",
  Success: "Success",
  Error: "Error",
  Preview: "Preview",
};

const ICONS = {
  [ENUMS.Info]: <InfoIcon />,
  [ENUMS.Success]: <SuccessIcon />,
  [ENUMS.Error]: <ErrorIcon />,
  [ENUMS.Preview]: <InfoIcon />,
};

const BUTTONS_TYPE = {
  [ENUMS.Info]: {
    no: ENUMS_TYPE.Neutral,
    yes: ENUMS_TYPE.Primary,
  },
  [ENUMS.Success]: {
    no: ENUMS_TYPE.Neutral,
    yes: ENUMS_TYPE.Success,
  },
  [ENUMS.Error]: {
    no: ENUMS_TYPE.Neutral,
    yes: ENUMS_TYPE.Error,
  },
  [ENUMS.Preview]: {
    no: ENUMS_TYPE.Tertiary,
    yes: ENUMS_TYPE.Primary,
  },
};
const ConfirmModalContent = ({
  className,
  type = ENUMS.Info,
  title,
  description,
  onPreview,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const buttonTexts = {
    [ENUMS.Success]: { yes: t("modal.yes"), no: t("modal.no") },
    [ENUMS.Error]: { yes: t("modal.yes"), no: t("modal.no") },
    [ENUMS.Info]: { yes: t("modal.yes"), no: t("modal.no") },
    [ENUMS.Preview]: { yes: t("modal.yes"), no: t("modal.preview") },
  };

  return (
    <div className={cx(styles.Root, styles[type], className)}>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <If state={type === ENUMS.Preview}>
            <IconButton
              className={styles.CloseBtn}
              onClick={onCancel}
              type={ICON_BUTTON_ENUMS_TYPE.Neutral}
              icon={<CloseIcon />}
            />
          </If>
          <div className={styles.Outer}>
            <div className={styles.Inner}>{ICONS[type]}</div>
          </div>
        </div>
        <div className={styles.Main}>
          <div className={styles.Info}>
            <h1 className="Subtitle1-Pos Medium">{title}</h1>
            <h4 className="Body1-Pos">{description} </h4>
          </div>
        </div>
        <div className={styles.Footer}>
          <Button
            className={styles.Button}
            onClick={type === ENUMS.Preview ? onPreview : onCancel}
            type={BUTTONS_TYPE[type].no}
            text={buttonTexts[type].no}
          />
          <Button
            className={styles.Button}
            onClick={onConfirm}
            type={BUTTONS_TYPE[type].yes}
            text={buttonTexts[type].yes}
          />
        </div>
      </div>
    </div>
  );
};

ConfirmModalContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onPreview: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(ENUMS)),
  description: PropTypes.string,
};

export default ConfirmModalContent;
