import React from "react";
import PropTypes from "prop-types";

import SuperSearchInput from "pages/client/magic-order/super-search-input/SuperSearchInput";

import "./MagicOrderFooter.scss";

const MagicOrderFooter = ({
  handleSaveSpeech,
  loadingAiSuperSearchMenu,
  typedText,
  setTypedText,
  isFocused,
  setIsFocused,
}) => {
  return (
    <>
      <div className="SpeechToTextContainerFooter">
        <SuperSearchInput
          handleSaveSpeech={handleSaveSpeech}
          resultText={typedText}
          setTypedText={setTypedText}
          loadingAiSuperSearchMenu={loadingAiSuperSearchMenu}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
        />
      </div>
    </>
  );
};

MagicOrderFooter.propTypes = {
  handleSaveSpeech: PropTypes.func,
  loadingAiSuperSearchMenu: PropTypes.bool,
  typedText: PropTypes.string,
  setTypedText: PropTypes.func,
  isFocused: PropTypes.bool,
  setIsFocused: PropTypes.func,
};

export default MagicOrderFooter;
