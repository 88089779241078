import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Checkbox from "components/pos/checkbox/Checkbox";
import If from "components/if/If";

import styles from "./DropdownOption.module.scss";

const DropdownOption = ({
  option,
  value,
  handleOptionClick,
  isMultiple = false,
  isGrouped,
  isOptionRequired,
}) => {
  const { t } = useTranslation();
  const isActive = isMultiple
    ? Array.isArray(value) &&
      value.some((selectedOption) => selectedOption.id === option.id)
    : value?.id === option.id;

  const optionName = option.name;

  const handleOnClickOption = () => {
    if (value?.id === option.id && !isOptionRequired) {
      handleOptionClick(null);
    } else {
      handleOptionClick(option);
    }
  };
  const hasCurrentDropdownOption = optionName?.includes(t("table.current"));
  return (
    <div
      className={cx(styles.Option, {
        [styles.isActive]: isActive,
        [styles.OptionGrouped]: isGrouped,
        [styles.isDisabled]: option.isDisabled,
        [styles.hasCurrentDropdownOption]: hasCurrentDropdownOption,
      })}
      onClick={handleOnClickOption}
    >
      <If state={isMultiple}>
        <Checkbox isChecked={isActive} label={optionName} />
      </If>
      <If state={!isMultiple}>
        <h5 className="Medium">{optionName}</h5>
      </If>
    </div>
  );
};

DropdownOption.propTypes = {
  /**
   * The option object for this dropdown item
   */
  option: PropTypes.object.isRequired,

  /**
   * The currently selected value in the dropdown
   */
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),

  /**
   * Callback function to handle the click event for this option
   */
  handleOptionClick: PropTypes.func.isRequired,
  isOptionRequired: PropTypes.bool,

  /**
   * Determines if multiple selections are allowed in the dropdown
   */
  isMultiple: PropTypes.bool,

  /**
   * Determines if the options in the dropdown are grouped
   */
  isGrouped: PropTypes.bool,
};

export default DropdownOption;
