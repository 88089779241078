import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactConfetti from "react-confetti";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import useWindowSize from "utils/hooks/useWindowSize";
import { STORE_NAMES } from "utils/constants/redux";
import DashboardHeroSection from "pages/client/dashboard/dashboard-hero-section/DashboardHeroSection";
import GuestInformation, {
  ENUMS,
} from "components/elements/guest-information/GuestInformation";
import GuestProfiles from "components/elements/guest-profiles/GuestProfiles";
import DashboardOrders from "components/elements/dashboard-orders/DashboardOrders";
import PaymentModal from "components/payment-modal/PaymentModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import { createDOBucketName } from "utils/DO-Spaces";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useTimeout from "utils/hooks/useTimeout";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";
import {
  concatFirstNameWithLastName,
  generateGuestIdSuffix,
} from "utils/helpers";
import { ReactComponent as Joist } from "assets/icons/client-dashboard/FunZone.svg";
import { ReactComponent as ChatIcon } from "assets/icons/client-dashboard/Chat.svg";
import {
  calculateTotalPriceOrderItems,
  isDeletedOrderItem,
  isDeniedOrderItem,
  isWaitingOrderItem,
  calculateOrderAmountsToBePaid,
  calculateTotalServiceFeeOrderItems,
  calculateTotalSubtotalOrderItems,
} from "utils/helper-functions/orderHelper";
import { makeOrderPaymentByOdero } from "utils/api/services/payment";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import useAsync from "utils/hooks/useAsync";
import ReceiptModal from "pages/client/dashboard/receipt-modal/ReceiptModal";
import PaymentResultModal, {
  PAYMENT_RESULT_ENUMS,
} from "pages/client/dashboard/payment-result-modal/PaymentResultModal";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { MP_EVENTS, MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { useGuestLayout } from "pages/client/menu-v2/GuestLayoutContext";
import { TAB_BAR_HEIGHT } from "pages/client/menu-v2/tab-bar/TabBar";
import WheelOfFortuneBanner from "components/wheel-of-fortune/WheelOfFortuneBanner";
import If from "components/if/If";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import TypingEffect from "components/typing-effect/TypingEffect";

import "./Dashboard.scss";

const DashboardBanner = ({
  onClick,
  title,
  icon,
  className,
  texts,
  typingSpeed,
  deletingSpeed,
}) => {
  return (
    <div
      className={`DashBoardInformationSectionBanner ${className}`}
      onClick={onClick}
    >
      <div className="DashBoardInformationSectionBannerContent">
        <p className="DashBoardInformationSectionBannerContentTitle">{title}</p>
        <TypingEffect
          texts={texts}
          typingSpeed={typingSpeed}
          deletingSpeed={deletingSpeed}
          textContainer={(displayText) => {
            return <p className="TypingText">{displayText}</p>;
          }}
        />
      </div>
      {icon}
    </div>
  );
};

DashboardBanner.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.string),
  typingSpeed: PropTypes.number,
  deletingSpeed: PropTypes.number,
};

const Dashboard = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.myOrder });
  const { isTabBarHidden } = useGuestLayout();
  const { trackMixpanel } = useMixpanel();
  const routerNavigate = useNavigate();
  const { navigate } = useInternalNavigation();
  const { t } = useTranslation();

  const { width, height } = useWindowSize().size;
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isReviewRequestSuccessful, setIsReviewRequestSuccessful] =
    useState(null);
  const { PaymentStatus } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const isOrderEmpty = !order;
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const {
    images: businesImages,
    name: businessName,
    id: businessId,
    isAskForBillEnabled,
  } = business;
  const businessImage = createDOBucketName(businesImages.logo);
  const [openPayment, setOpenPayment, mainElementRef] = useOutsideClick();
  const [
    openSlidePaymentResult,
    setOpenSlidePaymentResult,
    mainElementRefPaymentResult,
  ] = useOutsideClick();
  const [showConfettiOrder, setShowConfettiOrder] = useState(
    Boolean(searchParams.get(QUERY_PARAMS.showConfetti))
  );
  const [showConfettiPayment, setShowConfettiPayment] = useState(false);
  const [paymentResult, setPaymentResult] = useState(null);
  const showConfettiWhenConfirmOrder = () => {
    setShowConfettiOrder(false);
    searchParams.delete(QUERY_PARAMS.showConfetti);
    setSearchParams(searchParams);
  };
  const showConfettiWhenPaymentIsSucceed = () => {
    setShowConfettiOrder(false);
    searchParams.delete(QUERY_PARAMS.transactionId);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    isOrderEmpty &&
      routerNavigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`
      );
  }, [isOrderEmpty]);

  useEffect(() => {
    const transactionId = searchParams.get(QUERY_PARAMS.transactionId);
    if (transactionId) {
      const existTransaction = order.orderTransactions.find(
        (orderTransaction) =>
          orderTransaction.transactionId === parseInt(transactionId)
      );
      if (existTransaction) {
        const {
          paidPrice,
          cardBrand,
          guest: { lastName, firstName, profilePic },
        } = existTransaction;
        if (existTransaction.paymentStatus === PaymentStatus.success) {
          setShowConfettiPayment(true);
          setPaymentResult({
            status: PAYMENT_RESULT_ENUMS.success,
            paidPrice,
            cardBrand,
            guest: { lastName, firstName, profilePic },
          });
          setOpenSlidePaymentResult(true);
        } else {
          setPaymentResult({
            status: PAYMENT_RESULT_ENUMS.fail,
            paidPrice,
            cardBrand,
            guest: { lastName, firstName, profilePic },
          });
          setOpenSlidePaymentResult(true);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (typeof isReviewRequestSuccessful === "boolean") {
      const timeoutId = setTimeout(() => {
        setIsReviewRequestSuccessful(null);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [isReviewRequestSuccessful]);

  useTimeout({
    callback: showConfettiWhenConfirmOrder,
    delay: showConfettiOrder ? 5000 : null,
  });

  useTimeout({
    callback: showConfettiWhenPaymentIsSucceed,
    delay: showConfettiPayment ? 5000 : null,
  });

  // const paidOrderItems = useMemo(() => {
  //   if (!order) return [];
  //   if (!order.orderTransactions) return [];
  //   return order.orderTransactions
  //     .filter(
  //       (transaction) => transaction.paymentStatus === PaymentStatus.success
  //     )
  //     .flatMap(({ transactionItems }) => transactionItems)
  //     .flatMap(({ externalId }) => externalId);
  // }, [order]);

  const { paidOrderItems, inProgressOrderItems } = useMemo(() => {
    const result = {
      paidOrderItems: [],
      inProgressOrderItems: [],
    };
    if (!order || !order.orderTransactions) return result;

    order.orderTransactions.forEach((transaction) => {
      if (transaction.paymentStatus === PaymentStatus.success) {
        result.paidOrderItems.push(
          ...transaction.transactionItems.map(({ externalId }) => externalId)
        );
      }
      if (transaction.paymentStatus === PaymentStatus.in_progress) {
        result.inProgressOrderItems.push(
          ...transaction.transactionItems.map(({ externalId }) => externalId)
        );
      }
    });
    return result;
  }, [order]);

  const allParticipants = useMemo(() => {
    if (!order) return [];
    const sortedGuests = [
      ...order.guests.filter((guest) => guest?.person.id === guestId),
      ...order.guests.filter((guest) => guest?.person.id !== guestId),
    ];
    const allGuests = sortedGuests?.map((guest) => {
      const {
        profilePic,
        person,
        ratingService,
        ratingProduct,
        ratingMessage,
        phoneNumber,
        orderMessage,
        orderItems,
      } = guest;
      return {
        id: generateGuestIdSuffix(person.id),
        originalId: person.id,
        ordererId: guest.id,
        isGuest: true,
        isMe: person.id === guestId,
        profilePic: createDOBucketName(profilePic) || IMG_GUEST,
        ratingService,
        ratingProduct,
        ratingMessage,
        phoneNumber,
        orderMessage,
        orderItems,
        name: concatFirstNameWithLastName({
          firstName: guest?.firstName,
          lastName: guest?.lastName,
        }),
      };
    });
    const allUsers = order.users.map((user) => {
      const { orderItems, person } = user;
      return {
        id: person.id,
        isGuest: false,
        profilePic: businessImage || IMG_GUEST,
        orderItems,
        name: businessName,
      };
    });

    const getPayer = (orderItemId) => {
      const transactionId = order?.orderTransactions
        .filter((orderTransaction) => orderTransaction.transactionId)
        .flatMap((orderTransaction) =>
          orderTransaction.transactionItems.map((item) => ({
            ...item,
            transactionId: orderTransaction.transactionId,
          }))
        )
        .find(
          (transactionItem) => transactionItem.externalId === orderItemId
        )?.transactionId;

      const payer = order?.orderTransactions.find(
        (orderTransaction) => orderTransaction.transactionId === transactionId
      ).guest;

      return {
        id: payer.id,
        profilePic: createDOBucketName(payer.profilePic) || IMG_GUEST,
        name: payer.name,
      };
    };

    const updateOrderItems = (orderItems) => {
      return orderItems.map((orderItem) => {
        const { id, item, count, payment, isConfirmed } = orderItem;
        const isDeleted = isDeletedOrderItem(orderItem);
        const isDenied = isDeniedOrderItem(orderItem);
        const isWaiting = isWaitingOrderItem(orderItem);
        const isInProgress = inProgressOrderItems.includes(orderItem.id);
        const isPaid = paidOrderItems.includes(orderItem.id);
        const isPaidBy = isPaid ? getPayer(id) : null;
        return {
          id,
          count,
          item,
          payment,
          isDeleted,
          isDenied,
          isWaiting,
          isConfirmed,
          isPaid,
          isPaidBy,
          isInProgress,
        };
      });
    };

    const updatedAllParticipants = [...allGuests, ...allUsers].map(
      (participiant) => {
        return {
          ...participiant,
          orderAmountsToBePaid: calculateOrderAmountsToBePaid({
            orderItems: participiant.orderItems,
            paidOrderItems,
          }),
          orderItems: updateOrderItems(participiant.orderItems),
        };
      }
    );

    return updatedAllParticipants;
  }, [order]);

  const handleSelectGuest = (guestId) => {
    const isSelected = selectedGuests.includes(guestId);

    const guestOrderItems = allParticipants.find(
      (participant) => participant.id === guestId
    ).orderItems;

    const selectableOrderItems = guestOrderItems
      .filter((orderItem) => {
        return !orderItem.isDeleted && !orderItem.isDenied && !orderItem.isPaid;
      })
      .flatMap((orderItem) => orderItem.id);

    if (selectableOrderItems.length === 0) {
      return;
    }

    if (isSelected) {
      setSelectedGuests((prev) =>
        prev.filter((selectedGuestId) => selectedGuestId !== guestId)
      );
      setSelectedOrders((prev) =>
        prev.filter(
          (selectedOrderItemId) =>
            !selectableOrderItems.includes(selectedOrderItemId)
        )
      );
    } else {
      setSelectedGuests((prev) => [...prev, guestId]);
      setSelectedOrders((prev) => [...prev, ...selectableOrderItems]);
    }
  };
  const handlePayClick = () => {
    setOpenPayment(true);
  };

  const texts = useMemo(
    () => [
      t("funZone.wheelOfFortune.whoWillPayTheBill"),
      t("funZone.wheelOfFortune.whoWillHaveAnotherShot"),
      t("funZone.wheelOfFortune.whoWillGetDessert"),
      t("funZone.wheelOfFortune.whoWillChooseTheMusic"),
      t("funZone.wheelOfFortune.whoWillSingKaraoke"),
    ],
    [t]
  );
  const textsForChat = useMemo(
    () => [
      t("typingEffect.tableStartTopic"),
      t("typingEffect.talkToWaiter"),
      t("typingEffect.shareOpinion"),
      t("typingEffect.questionsHere"),
      t("typingEffect.chatWithOtherTables"),
    ],
    [t]
  );
  const textsForGame = useMemo(
    () => [
      t("gameMessages.findCards"),
      t("gameMessages.tastyVictory"),
      t("gameMessages.riseBeforeMeal"),
      t("gameMessages.playAndGetHungry"),
      t("gameMessages.winBeforeMeal"),
    ],

    [t]
  );

  const receipt = useMemo(() => {
    const getSelectedOrderItems = (orderItems, selectedOrders) => {
      return orderItems.filter((orderItem) =>
        selectedOrders.includes(orderItem.id)
      );
    };
    const allOrderItems = allParticipants.flatMap(
      ({ orderItems }) => orderItems
    );

    const selectedOrAllOrderItems =
      selectedOrders.length > 0
        ? getSelectedOrderItems(allOrderItems, selectedOrders)
        : allOrderItems;

    const orderItemsToBePaid = selectedOrAllOrderItems.filter((orderItem) => {
      return !orderItem.isDeleted && !orderItem.isDenied && !orderItem.isPaid;
    });

    const totalAmountToBePaid =
      calculateTotalPriceOrderItems(orderItemsToBePaid);

    const totalServiceFeeToBePaid =
      calculateTotalServiceFeeOrderItems(orderItemsToBePaid);

    const totalSubtotalToBePaid =
      calculateTotalSubtotalOrderItems(orderItemsToBePaid);

    return {
      totalAmountToBePaid,
      totalServiceFeeToBePaid,
      totalSubtotalToBePaid,
      orderItemsToBePaid: orderItemsToBePaid.flatMap(
        (orderItem) => orderItem.id
      ),
    };
  }, [allParticipants, selectedOrders]);

  const { execute: executeMakeOrderPayment, loading: isOnlinePaymentLoading } =
    useAsync(makeOrderPaymentByOdero, {
      onSuccess: ({ data }) => {
        window.location.href = data.data.pageUrl;
      },
      onError: ({ response }) => toast.error(response.data.message),
    });

  const showReceipt = () => {
    setOpenSlideReceipt(true);
  };

  const goOnlinePayment = async () => {
    const orderItemIds = allParticipants
      .flatMap(({ orderItems }) => orderItems)
      .filter((orderItem) => {
        if (selectedOrders.length > 0) {
          return (
            selectedOrders.includes(orderItem.id) &&
            orderItem.isConfirmed &&
            !orderItem.isPaid
          );
        } else {
          return orderItem.isConfirmed && !orderItem.isPaid;
        }
      })
      .flatMap(({ id }) => id);

    const paymentBody = {
      orderItemIds,
      tableId,
    };

    await executeMakeOrderPayment({
      businessId,
      orderId: order.id,
      paymentBody,
    });
  };

  const disablePayButton = receipt.orderItemsToBePaid.length === 0;
  const disableBillButton =
    receipt.orderItemsToBePaid.length === 0 || !isAskForBillEnabled;

  const [openSlideReceipt, setOpenSlideReceipt, mainElementRefReceipt] =
    useOutsideClick();

  const allOrderItems = allParticipants.flatMap(({ orderItems }) => orderItems);

  const selectedOrderObjects =
    selectedOrders.length > 0
      ? allOrderItems.filter((orderItem) =>
          selectedOrders.includes(orderItem.id)
        )
      : allOrderItems;

  const handleGoFunZone = () => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
    });
    trackMixpanel(`${MP_EVENTS.guestDashboard.clickFunZone}`);
  };

  const handleGoChat = () => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.chat}`,
    });
    trackMixpanel(`${MP_EVENTS.guestDashboard.clickChat}`);
  };
  const isChatEnabled = business?.chat;
  return (
    <div className="MyOrders">
      {(showConfettiOrder || showConfettiPayment) && (
        <ReactConfetti
          width={window.innerWidth <= 1000 ? width : 500}
          height={window.innerHeight <= 1000 ? height : 700}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <DashboardHeroSection
        menuItemCount={receipt.orderItemsToBePaid.length}
        selectedGuests={
          selectedGuests.length > 0
            ? allParticipants.filter((guest) =>
                selectedGuests.includes(guest.id)
              )
            : allParticipants
        }
      />{" "}
      {order && (
        <>
          <div className="DashBoardInformationSection">
            <div className="DashBoardInformationSectionGameArea">
              <WheelOfFortuneBanner texts={texts} />
              <If state={isChatEnabled}>
                <DashboardBanner
                  onClick={handleGoChat}
                  icon={<ChatIcon />}
                  title={t("chat.chat")}
                  className="DashboardChatBanner"
                  texts={textsForChat}
                  typingSpeed={50}
                  deletingSpeed={20}
                />
              </If>
              <If state={!isChatEnabled}>
                <DashboardBanner
                  onClick={handleGoFunZone}
                  icon={<Joist />}
                  title={t("navbarRoutes.funZone")}
                  className="DashboardFunZoneBanner"
                  texts={textsForGame}
                  typingSpeed={50}
                  deletingSpeed={20}
                />
              </If>
            </div>
            <div className="DashBoardGuestCount">
              {order.guests.length > 0 && (
                <h6 className="Medium">
                  {order.guests.length}{" "}
                  {order.guests.length === 1
                    ? t("dashboard.guest.guest").toLowerCase()
                    : t("dashboard.guest.guests").toLowerCase()}
                </h6>
              )}
            </div>
            <div className="ProfilesAndOrders">
              <GuestProfiles
                allParticipants={allParticipants}
                handleSelectGuest={handleSelectGuest}
                selectedGuests={selectedGuests}
              />
              <DashboardOrders
                allParticipants={allParticipants}
                setSelectedGuests={setSelectedGuests}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                selectedGuests={selectedGuests}
              />
            </div>
          </div>

          <div
            className="DashBoardGuestInformation"
            style={{ bottom: isTabBarHidden ? 30 : 10 + TAB_BAR_HEIGHT }}
          >
            {business.isOnlinePaymentEnabled ? (
              <CTAButton
                onClick={showReceipt}
                element={
                  <div className="GuestPayDetail">
                    <div className="OrderItemsToBePaidCount">
                      <h6 className="SemiBold">
                        {receipt.orderItemsToBePaid.length}
                      </h6>
                    </div>
                    <h3 className="SemiBold PayButton">{t("buttons.pay")}</h3>
                  </div>
                }
                type={ENUMS_CTA_BUTTON.types.TYPE_Y}
                price={receipt.totalAmountToBePaid}
                disabled={disablePayButton}
                className="GuestInformationPayButtonWrapper"
              />
            ) : (
              <GuestInformation
                totalPrice={receipt.totalAmountToBePaid}
                menuItemCount={receipt.orderItemsToBePaid.length}
                handlePayClick={handlePayClick}
                type={ENUMS.types.TYPE_C}
                isPayButtonDisabled={disableBillButton}
                guestCount={order.guests.length}
                tableName={order.table?.name}
              />
            )}
          </div>
          <PaymentModal
            mainElementRef={mainElementRef}
            openSlide={openPayment}
            onClose={() => setOpenPayment(false)}
            totalPrice={receipt.totalAmountToBePaid}
            menuItemCount={receipt.orderItemsToBePaid.length}
            tableName={order.table?.name}
            closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
            selectedGuests={
              selectedGuests.length > 0
                ? allParticipants.filter((guest) =>
                    selectedGuests.includes(guest.id)
                  )
                : allParticipants
            }
            selectedOrders={selectedOrders}
          />
          <ReceiptModal
            mainElementRef={mainElementRefReceipt}
            orderItems={selectedOrderObjects}
            openSlide={openSlideReceipt}
            onConfirm={goOnlinePayment}
            onCancel={() => setOpenSlideReceipt(false)}
            selectedOrderObjects={selectedOrderObjects}
            isLoading={isOnlinePaymentLoading}
          />

          <PaymentResultModal
            mainElementRef={mainElementRefPaymentResult}
            openSlide={openSlidePaymentResult}
            setOpenSlidePaymentResult={setOpenSlidePaymentResult}
            result={paymentResult}
          />
        </>
      )}
    </div>
  );
};

export default Dashboard;
