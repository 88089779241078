import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import {
  getBrandLoadingLogo,
  getBrandLoadingText,
} from "utils/branding-helper";
import useTimeout from "utils/hooks/useTimeout";

import styles from "./WelcomeClient.module.scss";

const BrandLogo = getBrandLoadingLogo();
const BrandText = getBrandLoadingText();

const VANISH_PERIODS = {
  INIT: "INIT",
  STARTED: "STARTED",
  ENDED: "ENDED",
};

const WelcomeClient = ({ shouldDisplay }) => {
  const [isVanish, setIsVanish] = useState(VANISH_PERIODS.INIT);
  const shouldDisplayIntro = shouldDisplay || isVanish !== VANISH_PERIODS.ENDED;

  useEffect(() => {
    if (!shouldDisplay) {
      setIsVanish(VANISH_PERIODS.STARTED);
    }
  }, [shouldDisplay]);

  useTimeout({
    callback: () => setIsVanish(VANISH_PERIODS.ENDED),
    delay: isVanish === VANISH_PERIODS.STARTED ? 400 : null,
  });

  if (!shouldDisplayIntro) {
    return null;
  }

  return (
    <div
      className={cx(styles.Root, {
        [styles.isVanish]: isVanish === VANISH_PERIODS.STARTED,
      })}
    >
      <div className={styles.Container}>
        <div className={styles.LogoContainer}>
          <BrandLogo className={styles.Logo} />
        </div>
        <div className={styles.TextContainer}>
          <BrandText className={styles.Text} />
        </div>
      </div>
    </div>
  );
};

WelcomeClient.propTypes = {
  shouldDisplay: PropTypes.bool,
};

export default React.memo(WelcomeClient);
