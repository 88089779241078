import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const getBusiness = async (id) =>
  AXIOS_USER.get(`/${API_PATH_PARAMS.business}/${id}`);
export const createBusiness = async (business) =>
  AXIOS_USER.post(`/${API_PATH_PARAMS.business}`, business);

export const updateBusiness = async (business, id) =>
  AXIOS_USER.patch(`/${API_PATH_PARAMS.business}/${id}`, business);

export const updateBusinessSettings = async (business, id) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${id}/${API_PATH_PARAMS.settings}`,
    business
  );

export const deleteBusiness = async (id) =>
  AXIOS_USER.delete(`/${API_PATH_PARAMS.business}/${id}`);

export const getAllBusinesses = () => {
  return AXIOS_USER.get(`/${API_PATH_PARAMS.business}`);
};

export const createPrinter = async (businessId, printer) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.printers}`,
    printer
  );

export const deletePrinter = async (businessId, printerId) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.printers}/${printerId}`
  );
