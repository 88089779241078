import React, { useContext } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { AdminWebsocketContextProvider } from "utils/context-api/AdminWebsocketContext";

import AdminLayout from "pages/admin/admin-layout/AdminLayout";
import Info from "pages/common/info/Info";
import useCssRootVars from "utils/hooks/useCssRootVars";
import useProtection from "utils/hooks/useProtection";
import useGeneralMetaDataUpdater from "utils/hooks/useGeneralMetaDataUpdater";
import { ROUTE_NAME } from "utils/constants/routes";
import useReduxVersioning from "utils/hooks/useReduxVersioning";
import AppRouterGuest from "pages/AppRouterGuest";
import SignIn from "pages/common/login/sign-in/SignIn";
import LockScreen from "pages/admin/admin-pages/lock-screen/LockScreen";
import ForgotPassword from "pages/common/login/forgot-password/ForgotPassword";
import ResetPassword from "pages/common/login/reset-password/ResetPassword";
import RouteTopScroller from "components/route-top-scroller/RouteTopScroller";
import useLanguageI18n from "../utils/hooks/useLanguageI18n";
import useRouterManager from "utils/hooks/useRouteManager";
import PrivacyPolicy from "pages/common/legal/privacy-policy/PrivacyPolicy";
import TermsAndCondition from "pages/common/legal/terms-and-condition/TermsAndCondition";
import LandingPage from "pages/common/main-page/LandingPage";
import MixpanelProvider from "utils/context-api/MixpanelContext";
import { ElectronContext } from "electron/ElectronProvider";
import AdminPermissionContextProvider from "utils/context-api/AdminPermissionContext";
import AdminProtectedRoute from "pages/admin/ProtectedRoute";
import AdminAuthProvider from "utils/context-api/AdminAuthContext";
import AdminController from "pages/admin/AdminController";
import AdminRouteControllerProvider from "utils/context-api/AdminRouteControllerContext";

import "./AppRouter.scss";

const AppRouterAdmin = () => {
  useRouterManager();

  return (
    <AdminProtectedRoute>
      <AdminAuthProvider>
        <AdminWebsocketContextProvider>
          <AdminPermissionContextProvider>
            <AdminController>
              <AdminRouteControllerProvider>
                <Routes>
                  <Route path={ROUTE_NAME.any} element={<AdminLayout />} />
                  <Route
                    path={ROUTE_NAME.lockScreen}
                    element={<LockScreen />}
                  />
                </Routes>
              </AdminRouteControllerProvider>
            </AdminController>
          </AdminPermissionContextProvider>
        </AdminWebsocketContextProvider>
      </AdminAuthProvider>
    </AdminProtectedRoute>
  );
};

const AppRouter = () => {
  // useGeoLocation();
  useCssRootVars();
  useProtection();
  useReduxVersioning();
  useGeneralMetaDataUpdater();
  useLanguageI18n();
  const { isElectron } = useContext(ElectronContext);

  const history = (children) =>
    isElectron ? (
      <HashRouter>{children}</HashRouter>
    ) : (
      <BrowserRouter>{children}</BrowserRouter>
    );

  return (
    <div className="AppRouter">
      <MixpanelProvider>
        {history(
          <RouteTopScroller>
            <Routes>
              <Route
                path={`${ROUTE_NAME.admin}/*`}
                element={<AppRouterAdmin />}
              />
              <Route
                path={`${ROUTE_NAME.client}/*`}
                element={<AppRouterGuest />}
              />
              <Route path={ROUTE_NAME.signIn} element={<SignIn />} />
              <Route
                path={ROUTE_NAME.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={ROUTE_NAME.resetPassword}
                element={<ResetPassword />}
              />

              <Route
                path={ROUTE_NAME.info}
                element={
                  <AdminProtectedRoute>
                    <Info />
                  </AdminProtectedRoute>
                }
              />
              {/*<Route*/}
              {/*  path={ROUTE_NAME.underConstruction}*/}
              {/*  element={<UnderConstruction />}*/}
              {/*/>*/}
              {/*<Route path="*" element={<UnderConstruction />} />*/}
              <Route
                path={ROUTE_NAME.underConstruction}
                element={<LandingPage />}
              />
              <Route
                path={`${ROUTE_NAME.legal}${ROUTE_NAME.privacyPolicy}`}
                element={<PrivacyPolicy />}
              />
              <Route
                path={`${ROUTE_NAME.legal}${ROUTE_NAME.termsAndCondition}`}
                element={<TermsAndCondition />}
              />
              <Route path="*" element={<LandingPage />} />
            </Routes>
          </RouteTopScroller>
        )}
      </MixpanelProvider>
    </div>
  );
};

export default AppRouter;
