import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";

import useAsync from "utils/hooks/useAsync";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import { resetGuestPassword } from "utils/api/services/guest";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import PasswordInput from "components/forms/input/input-password/PasswordInput";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { MP_EVENTS, MP_PAGE_NAMES } from "utils/constants/mixpanel";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./ResetPassword.scss";

const ResetPassword = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.resetPassword });
  const { businessId } = useParams();
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const { resetPasswordSchema } = schemas;
  const navigate = useNavigate();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const { oldPassword, newPassword } = formData;

  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const methods = useForm({
    resolver: zodResolver(resetPasswordSchema),
    criteriaMode: "all",
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
    setFocus,
  } = methods;

  const {
    execute: executeResetGuestPassword,
    loading: isResetPasswordLoading,
  } = useAsync(resetGuestPassword, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: () => {
      navigate(
        businessId
          ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`
          : `${ROUTE_NAME.client}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`
      );
      trackMixpanel(`${MP_EVENTS.auth.resetPassword}`);
    },
  });

  const handleOnSubmit = () => {
    executeResetGuestPassword({
      guestBody: {
        oldPassword,
        newPassword,
      },
      guestId,
    });
  };
  const handleKeyDown = ({ target, key }) => {
    if (key === "Enter") {
      switch (target.name) {
        case "oldPassword":
          setFocus("newPassword");
          break;
        case "newPassword":
          handleSubmit(handleOnSubmit)();
          break;
        default:
          break;
      }
    }
  };

  const handleGoBack = () => {
    navigate(
      businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`
    );
  };

  return (
    <div className="MyProfileResetPassword">
      <div className="MyProfileResetPasswordHeader">
        <BackButton
          onClick={handleGoBack}
          type={BACK_BUTTON_ENUMS.types.TYPE_D}
        />
        <h2 className="SemiBold">
          {t("myProfile.resetPassword.resetPassword")}
        </h2>
      </div>
      <div className="MyProfileResetPasswordBody">
        <div className="MyProfileResetPasswordBodyTitleWrapper">
          <p className="SemiBold MyProfileResetPasswordBodyTitle">
            {t("myProfile.resetPassword.setNewPassword")}
          </p>
          <h6 className="Medium">
            {t("myProfile.resetPassword.enterCurrentPassword")}
          </h6>
        </div>
        <form
          className="MyProfileResetPasswordForm"
          onSubmit={(e) => e.preventDefault()}
        >
          <PasswordInput
            name="oldPassword"
            value={formData.oldPassword}
            hasError={errors.oldPassword}
            func={{
              ...register("oldPassword", {
                onChange: handleInputChange,
              }),
            }}
            onKeyDown={handleKeyDown}
            placeholder={t("myProfile.resetPassword.currentPassword")}
            containerClassName="TypeA"
            error={
              <ErrorMessage
                errors={errors}
                name="oldPassword"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <PasswordInput
            name="newPassword"
            value={formData.newPassword}
            hasError={errors.newPassword}
            func={{
              ...register("newPassword", {
                onChange: handleInputChange,
              }),
            }}
            onKeyDown={handleKeyDown}
            placeholder={t("myProfile.resetPassword.newPassword")}
            containerClassName="TypeA"
            error={
              <ErrorMessage
                errors={errors}
                name="newPassword"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
        </form>
        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("myProfile.resetPassword.setNewPassword")}
          isLoading={isResetPasswordLoading}
        />
      </div>
    </div>
  );
};
export default ResetPassword;
