import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import If from "components/if/If";

import styles from "./Chip.module.scss";

export const ENUMS = {
  TYPE: {
    name: "Chip",
    types: {
      PRIMARY: "Primary",
      SECONDARY: "Secondary",
      SUCCESS: "Success",
      ERROR: "Error",
      WARNING: "Warning",
    },
  },
  SHAPE_TYPE: {
    name: "ChipShape",
    types: {
      SQUARE: "Square",
      CIRCLE: "Circle",
    },
  },
  SIZE_TYPE: {
    name: "SIZE",
    types: {
      SMALL: "Small",
      MEDIUM: "Medium",
      LARGE: "Large",
    },
  },
};

const Chip = ({
  className,
  type,
  leftIcon,
  rightIcon,
  title,
  shapeType = ENUMS.SHAPE_TYPE.types.SQUARE,
  sizeType = ENUMS.SIZE_TYPE.types.MEDIUM,
}) => {
  return (
    <div
      className={cx(
        styles.Chip,
        styles[type],
        styles[shapeType],
        styles[sizeType],
        className
      )}
    >
      <If state={Boolean(leftIcon)}>
        <div className={styles.Icon}>{leftIcon}</div>
      </If>
      <h5 className={cx(styles.Title, "Medium")}>{title}</h5>
      <If state={Boolean(rightIcon)}>
        <div className={styles.Icon}>{rightIcon}</div>
      </If>
    </div>
  );
};

Chip.propTypes = {
  type: PropTypes.oneOf(Object.values(ENUMS.TYPE.types)).isRequired,
  shapeType: PropTypes.oneOf(Object.values(ENUMS.SHAPE_TYPE.types)),
  sizeType: PropTypes.oneOf(Object.values(ENUMS.SIZE_TYPE.types)),
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Chip;
