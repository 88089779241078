import { AXIOS_USER } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createMenuItem = async (businessId, menuId, menuItem) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuItems}`,
    menuItem
  );

export const updateMenuItem = async (businessId, menuId, menuItem, id) =>
  AXIOS_USER.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuItems}/${id}`,
    menuItem
  );

export const deleteMenuItem = async (businessId, menuId, id) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuItems}/${id}`
  );

export const createLinkItemsToCategory = async (
  businessId,
  menuId,
  menuCategoryId,
  body
) =>
  AXIOS_USER.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}/${menuCategoryId}/${API_PATH_PARAMS.linkItems}`,
    body
  );

export const deleteLinkedMenuItem = async (
  businessId,
  menuId,
  categoryId,
  id
) =>
  AXIOS_USER.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}/${categoryId}/${API_PATH_PARAMS.linkItems}/${id}`
  );
