import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import MenuItemHeader, {
  ENUMS as Header_ENUMS,
} from "components/elements/menu-item-header/MenuItemHeader";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useInternalNavigation from "utils/hooks/useInternalNavigation";
import {
  updateFavorites,
  updateOrderItemByIndex,
} from "redux/slices/basketStore";
import { MP_EVENTS, MP_PROP_NAMES } from "utils/constants/mixpanel";
import { deleteItemModificationFromBasket, findGuest } from "utils/helpers";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import { STORE_NAMES } from "utils/constants/redux";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import If from "components/if/If";
import Cube from "components/cube/Cube";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import MenuItemDescription, {
  ENUMS as DESCRIPTION_ENUMS,
} from "components/elements/menu-item-description/MenuItemDescription";
import Tag, { ENUMS as TAG_ENUMS } from "components/elements/tag/Tag";
import TagWithIcon, {
  ENUMS as TAG_ENUMS_WICON,
} from "components/elements/tag-with-icon/TagWithIcon";
import MenuItemPrice from "components/elements/menu-item-price/MenuItemPrice";
import useTags from "utils/hooks/useTags";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";
import useMouseDragHorizontal from "utils/hooks/useMouseDragHorizontal";
import {
  calculateMenuItemPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import useLanguage from "utils/hooks/useLanguage";
import ModificationsMenu from "components/elements/modifications-menu/ModificationsMenu";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";

import styles from "./BasketItem.module.scss";

const BasketItem = () => {
  const { trackMixpanel } = useMixpanel();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businessId, basketItemIndex } = useParams();
  let [searchParams] = useSearchParams();
  const { shouldDisplayMenuItemImage, findItemById, isValidMenuItem } =
    useMenuHelper();
  const { navigate, navigateToPreservedBackUrl } = useInternalNavigation();
  const { displayDataByLanguage } = useLanguage();

  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guest = findGuest(guestId, basketOrders);
  const { favoriteItems = [], orderItems = [] } = guest || [];

  const selectedMenuItem = useMemo(() => {
    if (!basketItemIndex || !orderItems[basketItemIndex]?.item || !guestId)
      return null;

    const basketItem = orderItems[basketItemIndex];
    const existMenuItem = findItemById(basketItem.item.id);

    if (!existMenuItem) return null;

    const modifications = basketItem.item.modifications
      .map((modificationInBasket) => {
        const matchingMod = existMenuItem.modifications.find(
          (modification) => modification.id === modificationInBasket.id
        );
        if (!matchingMod || !modificationInBasket.options.length) return null;

        return {
          ...matchingMod,
          options: modificationInBasket.options
            .map((optionInBasket) => {
              const matchingOption = matchingMod.options.find(
                (option) => option.id === optionInBasket.id
              );
              if (!matchingOption) {
                deleteItemModificationFromBasket(
                  dispatch,
                  guestId,
                  basketItemIndex,
                  modificationInBasket.id
                );
                return null;
              }
              return { ...matchingOption, count: optionInBasket.count };
            })
            .filter(Boolean),
        };
      })
      .filter(Boolean);

    return {
      ...existMenuItem,
      modifications,
      allModifications: existMenuItem.modifications,
    };
  }, [basketItemIndex, orderItems, findItemById, guestId, dispatch]);

  const {
    sliderRef: sliderRefTags,
    handleMouseDownHandler: handleMouseDownHandlerTags,
  } = useMouseDragHorizontal();

  const allTags = useTags(selectedMenuItem, true)?.allTags || [];
  const allTagsWithIcon =
    useTagsWIcon(selectedMenuItem, displayDataByLanguage, true)
      ?.allTagsWithIcon || [];

  const [count, setCount] = useState(orderItems?.[basketItemIndex]?.count || 1);
  const [selectedModifications, setSelectedModifications] = useState([]);
  const [focusedModificationOption, setFocusedModificationOption] =
    useState(null);

  const discountPrice = calculateMenuItemPriceBySchedule(selectedMenuItem);
  const menuItemPrice = selectedMenuItem?.priceSell || 0;
  const finalPrice = useMemo(
    () =>
      selectedMenuItem
        ? calculateMenuItemPrice({
            ...selectedMenuItem,
            priceSell: menuItemPrice + discountPrice,
            modifications: selectedModifications,
          })
        : 0,
    [selectedMenuItem, selectedModifications, menuItemPrice, discountPrice]
  );

  useEffect(() => {
    if (!selectedMenuItem) {
      return [];
    }

    setSelectedModifications(selectedMenuItem.modifications);
  }, [selectedMenuItem]);

  const handleOnFavorite = useCallback(() => {
    if (!selectedMenuItem) return;

    dispatch(
      updateFavorites({ userId: guestId, menuItemID: selectedMenuItem.id })
    );

    trackMixpanel(`${MP_EVENTS.menu.liked}`, {
      [MP_PROP_NAMES.itemId]: basketItemIndex,
      [MP_PROP_NAMES.itemName]: selectedMenuItem.name[0].value,
    });
  }, [dispatch, guestId, selectedMenuItem, trackMixpanel, basketItemIndex]);

  const handleGoBack = useCallback(() => {
    const memorizedScroll = searchParams.get(QUERY_PARAMS.memorizedScroll);

    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      queries: {
        ...(memorizedScroll && {
          [QUERY_PARAMS.targetedScroll]: memorizedScroll,
        }),
      },
    });
  }, [navigateToPreservedBackUrl, businessId, searchParams]);

  const handleCountChange = useCallback((increment) => {
    setCount((prev) => Math.max(1, prev + increment));
  }, []);

  const handleSaveChanges = () => {
    const memorizedScroll = searchParams.get(QUERY_PARAMS.memorizedScroll);

    dispatch(
      updateOrderItemByIndex({
        menuItem: {
          ...selectedMenuItem,
          modifications: selectedModifications,
        },
        count,
        userId: guestId,
        basketIndex: parseInt(basketItemIndex),
      })
    );

    navigateToPreservedBackUrl({
      fallbackUrl: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`,
      queries: {
        ...(memorizedScroll && {
          [QUERY_PARAMS.targetedScroll]: memorizedScroll,
        }),
      },
    });
  };

  const handleOpenAr = (item) => {
    navigate({
      path: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItemAR}/${item.id}`,
      preserveExistingUrl: true,
      queries: {
        [QUERY_PARAMS.memorizedScroll]: window.scrollY,
      },
    });
  };

  if (!isValidMenuItem(selectedMenuItem?.id)) {
    return (
      <Navigate
        to={`${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`}
      />
    );
  }

  const shouldShowSlider =
    selectedMenuItem.otherImagesSrc?.length > 1 && shouldDisplayMenuItemImage;

  const isActiveArView = Boolean(
    selectedMenuItem?.model3D?.ios && selectedMenuItem.model3D?.general
  );

  return (
    <div className={styles.Root}>
      <MenuItemHeader
        onFavorite={handleOnFavorite}
        itemId={selectedMenuItem?.id}
        onGoBack={handleGoBack}
        favoriteItems={favoriteItems}
        type={Header_ENUMS.types.TYPE_C}
      />
      <If state={shouldDisplayMenuItemImage}>
        <div className={styles.ImagesWrapper}>
          <If state={isActiveArView}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleOpenAr(selectedMenuItem);
              }}
              className={styles.ArBtnWrapper}
            >
              <Cube />
              <p className="h7 SemiBold">AR</p>
            </div>
          </If>

          <If state={shouldShowSlider}>
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              effect={"fade"}
              modules={[EffectFade, Autoplay]}
              className={styles.MenuItemSlider}
            >
              {selectedMenuItem.otherImagesSrc.map((imageSrc, index) => (
                <SwiperSlide className={styles.SwiperSlide} key={index}>
                  <img
                    className={styles.CarouselImage}
                    src={imageSrc}
                    alt={selectedMenuItem.extraFields.displayedName}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </If>
          <If state={!shouldShowSlider && shouldDisplayMenuItemImage}>
            <ImageWithPlaceholder
              imageSource={selectedMenuItem.coverImageSrc}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt={String(selectedMenuItem.id)}
              className={styles.CoverImage}
            />
          </If>
        </div>
      </If>
      <div
        className={cx(styles.InfoContainer, {
          [styles.WithPhoto]: shouldDisplayMenuItemImage,
          [styles.WithoutPhoto]: !shouldDisplayMenuItemImage,
        })}
      >
        <h2 className="SemiBold">
          {selectedMenuItem.extraFields.displayedName}
        </h2>
        <div className={styles.InfoWrapper}>
          <div className={styles.DescriptionContainer}>
            <MenuItemDescription
              description={selectedMenuItem.extraFields.displayedDescription}
              type={DESCRIPTION_ENUMS.types.TYPE_A}
            />
          </div>
          <If state={allTags.length > 0}>
            <Tag items={allTags} type={TAG_ENUMS.types.TYPE_F} showTime />
          </If>
          <If state={allTagsWithIcon.length > 0}>
            <div
              className={styles.TagsContainer}
              ref={sliderRefTags}
              onMouseDown={handleMouseDownHandlerTags}
            >
              <TagWithIcon
                items={allTagsWithIcon}
                type={TAG_ENUMS_WICON.types.TYPE_B}
              />
            </div>
          </If>
          <div className={styles.InfoPrice}>
            <MenuItemPrice
              menuItemPrice={menuItemPrice}
              discountPrice={discountPrice}
            />
          </div>
        </div>
      </div>

      <If state={selectedMenuItem?.allModifications.length > 0}>
        <div className={styles.Modifications}>
          {selectedMenuItem.allModifications.map((modification) => (
            <ModificationsMenu
              key={modification.id}
              data={modification}
              selectedOptions={selectedModifications?.find(
                (option) => option.id === modification.id
              )}
              setSelectedModifications={setSelectedModifications}
              focusedModificationOption={focusedModificationOption}
              setFocusedModificationOption={setFocusedModificationOption}
            />
          ))}
        </div>
      </If>

      <div className={styles.Footer}>
        <AnimatedPlusMinusButton
          onMinusClick={() => handleCountChange(-1)}
          onPlusClick={() => handleCountChange(1)}
          hasBorder
          count={count}
          doAnimate
          disableMinusButtonAtOne
        />
        <CTAButton
          className={styles.SaveChanges}
          onClick={handleSaveChanges}
          name={t("buttons.saveChanges")}
          type={CTA_ENUMS.types.TYPE_N}
          price={count * finalPrice}
        />
      </div>
    </div>
  );
};
export default BasketItem;
