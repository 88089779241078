import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useGuestLayout } from "../GuestLayoutContext";
import { createDOBucketName } from "utils/DO-Spaces";
import ImageWithInitials from "pages/client/menu-v2/image-with-placeholder/ImageWithInitials";

import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as HamburgerMenu } from "assets/icons/menuv2/hamburger.svg";
import { ReactComponent as Search } from "assets/icons/filter/SearchIcon.svg";
import { ReactComponent as Filter } from "assets/icons/form/Filter-2.svg";
import IconButton from "components/icon-button/IconButton";
import MenuSearchModal from "../menu-search-modal/MenuSearchModal";
import InstagramStories from "components/insta-stories/InstaStories";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { setLanguage } from "redux/slices/userStore";
import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import CloseButton, {
  ENUMS,
} from "components/buttons/close-button/CloseButton";
import RadioSelection, {
  ENUMS as ENUMS_RADIO_SELECTION,
} from "components/admin/forms/radio-selection/RadioSelection";
import { QUERY_PARAMS } from "utils/constants/routes";
import { useGuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { MP_EVENTS } from "utils/constants/mixpanel";
import { generateInitial } from "utils/helpers";

import styles from "./TopBar.module.scss";

export const Languages = ({ mainElementRef, openSlide, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.menu].data?.availableLanguages
  );
  const handleSelect = async (language) => {
    dispatch(setLanguage(language));
    onClose();
  };

  const LanguagesHeader = (
    <div className={styles.Header}>
      <h4 className={cx("SemiBold", styles.Title)}>
        {t("menuV2.selectLanguage")}
      </h4>
      <CloseButton
        className={styles.Close}
        onClick={onClose}
        type={ENUMS.types.TYPE_S}
      />
    </div>
  );

  const LanguagesBody = (
    <ul className={styles.Options}>
      {availableLanguages?.map((language) => {
        return (
          <li
            className={styles.Option}
            onClick={() => handleSelect(language)}
            key={language.id}
          >
            <div className={styles.Details}>
              <img alt="language" src={language.imgSrc} />
              <h6 className={cx("SemiBold", styles.Name)}>
                {t(language.name)}
              </h6>
            </div>
            <RadioSelection
              name="Languages"
              type={ENUMS_RADIO_SELECTION.types.TYPE_YELLOW}
              onChange={() => handleSelect(language)}
              isChecked={selectedLanguage?.id === language.id}
              value={selectedLanguage?.id}
            />
          </li>
        );
      })}
    </ul>
  );

  return (
    <GuestModal
      modalClassname={styles.LanguagesModal}
      containerClassname={styles.LanguagesContainer}
      body={LanguagesBody}
      header={LanguagesHeader}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};

Languages.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
};

const TopBar = () => {
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();
  const { openHamburger } = useGuestLayout();
  const [searchParams, setSearchParams] = useSearchParams();

  const business = useSelector((state) => state[STORE_NAMES.business].business);
  // const { serviceFee } = useSelector(
  //   (state) => state[STORE_NAMES.business].business
  // );
  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.menu].data?.availableLanguages
  );

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const [openLanguages, setOpenLanguages, mainElementRef] = useOutsideClick();
  const [openSlideGuestSearchModal, setOpenSlideGuestSearchModal] =
    useOutsideClick();
  const showLanguagesModal = availableLanguages?.length > 1 || false;

  const [showInstagramStory, setShowInstagramStory] = useState(false);
  const { unreadMessagesCount } = useGuestWebsocketContextProvider();
  const hasNotification = unreadMessagesCount > 0 && business?.chat;

  const handleOnCancel = () => {
    setOpenSlideGuestSearchModal(false);
  };

  const handleClickSearch = () => {
    trackMixpanel(`${MP_EVENTS.openSearchModal}`);
    setOpenSlideGuestSearchModal(true);
  };

  const handleClickFilter = () => {
    trackMixpanel(`${MP_EVENTS.openFilterModal}`);
    searchParams.set(QUERY_PARAMS.showPopup, QUERY_PARAMS.popupPages.filter);
    setSearchParams(searchParams);
  };

  return (
    <>
      {showInstagramStory && business?.images?.other?.length > 0 && (
        <InstagramStories
          business={business}
          setShowInstaStory={setShowInstagramStory}
        />
      )}
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Info}>
            <div
              className={styles.LogoWrapper}
              onClick={() => setShowInstagramStory(true)}
            >
              {business?.images?.other?.length > 0 && (
                <div className={styles.Circle} />
              )}

              <ImageWithInitials
                alt="logo"
                className={styles.Logo}
                imageSource={createDOBucketName(business.images.logo)}
                initialText={generateInitial([business.name])}
              />
            </div>
            <div className={styles.Details}>
              <h4 className={cx("Medium", styles.Name)}>{business?.name}</h4>
              {/*{serviceFee > 0 && (*/}
              {/*  <p className={cx("h7 Regular", styles.Fee)}>*/}
              {/*    {t("inputs.serviceFee")}*/}
              {/*    {`: ${serviceFee}%`}*/}
              {/*  </p>*/}
              {/*)}*/}
            </div>
          </div>
          <div className={styles.Options}>
            {availableLanguages.length > 1 && (
              <IconButton
                className={styles.Language}
                img={selectedLanguage?.imgSrc}
                onClick={() => setOpenLanguages(showLanguagesModal)}
              />
            )}

            <IconButton
              className={styles.Hamburger}
              Icon={HamburgerMenu}
              onClick={openHamburger}
              hasNotification={hasNotification}
            />
          </div>
        </div>
        <div className={styles.Footer}>
          <div className={styles.SearchWrapper} onClick={handleClickSearch}>
            <Search className={styles.Search} />
            <h6 className={cx("Regular", styles.Text)}>{t("menu.search")}</h6>
          </div>
          <IconButton
            Icon={Filter}
            className={styles.Filter}
            onClick={handleClickFilter}
          />
        </div>
      </div>
      <Languages
        mainElementRef={mainElementRef}
        openSlide={openLanguages}
        onClose={() => setOpenLanguages(false)}
      />

      <MenuSearchModal
        openSlide={openSlideGuestSearchModal}
        onCancel={handleOnCancel}
      />
    </>
  );
};

export default TopBar;
