import { useEffect, useState, useRef } from "react";

const useManageTabBarAndScrollTopWhenScrolling = ({
  scrollThresholdForTabBar = 5,
}) => {
  const [isTabBarHidden, setIsTabBarHidden] = useState(false);
  const [isScrollTopHidden, setIsScrollTopHidden] = useState(true);

  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isAtTop = currentScrollY === 0;
      const isBeyondThreshold =
        Math.abs(currentScrollY - lastScrollY.current) >=
        scrollThresholdForTabBar;

      setIsScrollTopHidden(currentScrollY <= 1000);

      if (isAtTop) {
        setIsTabBarHidden(false);
      } else {
        if (!isBeyondThreshold) {
          return;
        } else {
          setIsTabBarHidden(currentScrollY > lastScrollY.current);
        }
      }

      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollThresholdForTabBar]);

  const showTabBar = () => {
    setIsTabBarHidden(false);
  };

  return { isTabBarHidden, showTabBar, isScrollTopHidden };
};

export default useManageTabBarAndScrollTopWhenScrolling;
