import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ReactComponent as MessageIcon } from "assets/icons/basket/messages.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/leftArrow.svg";
import { STORE_NAMES } from "utils/constants/redux";

import "./BasketMessageSection.scss";

const BasketMessageSection = ({ openModal }) => {
  const { t } = useTranslation();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrder = useSelector((state) => state[STORE_NAMES.basket].order);
  const guests = basketOrder ? basketOrder.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);

  return (
    <div className="BasketMessageSection" onClick={openModal}>
      <div className="BasketMessageSectionContainer">
        <MessageIcon />
        <div className="BasketMessageSectionTitle">
          <h6 className="Medium">{t("basket.comment.addMessage")}</h6>
          <p>{guest?.orderMessage || t("basket.comment.addMessageDesc")}</p>
        </div>
      </div>
      <ArrowIcon />
    </div>
  );
};
BasketMessageSection.propTypes = {
  openModal: PropTypes.func,
};

export default BasketMessageSection;
