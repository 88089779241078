import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { STORE_NAMES } from "utils/constants/redux";
import useGuestWebsocket from "utils/hooks/websocket/useGuestWebsocket";
import { getRedisWebsocket } from "utils/api/redis";
import useGuestWebsocketContextChatHelper from "utils/hooks/websocket/useGuestWebsocketContextChatHelper";
import { browserEvents, BrowserEventsValue } from "../constants/browserEvents";

const GuestWebsocketContext = createContext({});

const GuestWebsocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table?.id;
  const order = orders?.find((order) => order?.table.id === tableId);

  const { id: guestId } = useSelector((state) => state[STORE_NAMES.guest]);
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);

  const unreadMessagesCount = useMemo(() => {
    if (!order || !guestId || !chat || chat.length === 0) return 0;
    const currentTopic = chat.find((topic) => topic.id === order.id);
    const messages = currentTopic?.messages || [];
    const authorLastReadTime = currentTopic?.usersLastReadTime.find(
      (item) => item.userId === guestId
    )?.dateTime;

    return messages.filter(
      (message) =>
        message.author.id !== guestId &&
        (authorLastReadTime ? authorLastReadTime < message.dateTime : true)
    ).length;
  }, [chat, order?.id, guestId]);

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === BrowserEventsValue.visible) {
        setSocket(getRedisWebsocket());
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener(
      browserEvents.visibilitychange,
      handleVisibilityChange
    );
    return () => {
      window.removeEventListener(
        browserEvents.visibilitychange,
        handleVisibilityChange
      );
    };
  }, []);

  useEffect(() => {
    if (!isVisible && socket) {
      socket.removeAllListeners();
      socket.disconnect();
    }
  }, [isVisible, socket]);

  useGuestWebsocket(socket);
  const { messages, publishTableChatMessage } =
    useGuestWebsocketContextChatHelper({ socket, businessId });

  useEffect(() => {
    if (order?.id) {
      setSocket(getRedisWebsocket());
    }
  }, [order?.id]);

  return (
    <GuestWebsocketContext.Provider
      value={{ publishTableChatMessage, messages, unreadMessagesCount }}
    >
      {/*<AudioPlayer audioSrc={ChatAudio} isPlaying={hasNewMessage} />*/}
      {children}
    </GuestWebsocketContext.Provider>
  );
};

GuestWebsocketContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  socket: PropTypes.any,
};

const useGuestWebsocketContextProvider = () => {
  return useContext(GuestWebsocketContext);
};

export {
  GuestWebsocketContextProvider,
  GuestWebsocketContext,
  useGuestWebsocketContextProvider,
};
