import React from "react";
import PropTypes from "prop-types";
import { RotatingLines } from "react-loader-spinner";
import cx from "classnames";

import If from "components/if/If";

import styles from "./Button.module.scss";

export const ENUMS_TYPE = {
  Primary: "Primary",
  Secondary: "Secondary",
  Tertiary: "Tertiary",
  Neutral: "Neutral",
  Text: "Text",
  Split: "Split",
  Lock: "Lock",
  Success: "Success",
  Error: "Error",
  LogOut: "LogOut",
};
export const ENUMS_SIZE = {
  Small: "Small",
  Medium: "Medium",
};

const getLoadingStrokeColor = (type) => {
  switch (type) {
    case ENUMS_TYPE.Lock:
    case ENUMS_TYPE.Success:
    case ENUMS_TYPE.Error:
    case ENUMS_TYPE.LogOut:
      return "#FFFFFF";
    case ENUMS_TYPE.Neutral:
      return "#58626C";
    case ENUMS_TYPE.Primary:
      return "#131719";
    case ENUMS_TYPE.Split:
      return "#13171A";
    default:
      return "#4547EA";
  }
};

const Button = ({
  onClick,
  className,
  size = ENUMS_SIZE.Small,
  type = ENUMS_TYPE.Neutral,
  isLoading = false,
  isDisabled = false,
  leftIcon,
  rightIcon,
  text,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={cx(styles.Root, styles[type], styles[size], className, {
        [styles.isLoading]: isLoading,
        [styles.isDisabled]: isDisabled,
      })}
    >
      <If state={isLoading}>
        <RotatingLines
          strokeColor={getLoadingStrokeColor(type)}
          height="24"
          width="24"
          strokeWidth="4"
        />
      </If>
      <If state={!isLoading}>
        <If state={Boolean(leftIcon)}>
          <div className={styles.Icon}>{leftIcon}</div>
        </If>
        <h5 className={cx("Medium Body2-Pos", styles.Label)}>{text}</h5>
        <If state={Boolean(rightIcon)}>
          <div className={styles.Icon}>{rightIcon}</div>
        </If>
      </If>
    </button>
  );
};

Button.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS_TYPE)).isRequired,
  size: PropTypes.oneOf(Object.values(ENUMS_SIZE)),
  /**
   * The content of button
   */
  text: PropTypes.string,

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The icon to display next to the button text
   */
  leftIcon: PropTypes.node,
  /**
   * The icon to display next to the button text
   */
  rightIcon: PropTypes.node,
  /**
   * Disable state for the button
   */
  isDisabled: PropTypes.bool,

  /**
   * Custom className for additional styling
   */
  className: PropTypes.string,

  /**
   * Loading state for the button
   */
  isLoading: PropTypes.bool,
};

export default Button;
