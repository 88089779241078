import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import AnimatedAddButton, {
  ENUMS as ENUMS_ANIMATION,
} from "components/buttons/animated-add-button/AnimatedAddButton";
import useLanguage from "utils/hooks/useLanguage";
import { useMenuHelper } from "pages/client/menu-v2/GuestMenuProvider";
import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import useTags from "utils/hooks/useTags";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";

import styles from "./MagicCard.module.scss";

const MagicCard = ({ menuItem, onAddToBasket, recommendations = false }) => {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id, name } = menuItem;
  const { shouldDisplayMenuItemImage } = useMenuHelper();
  const { allTags } = useTags(menuItem);
  const { displayDataByLanguage } = useLanguage();
  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);

  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const handleDivClick = () => {
    if (recommendations) {
      navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${menuItem.id}?${QUERY_PARAMS.from}=${pathname}`
      );
    } else {
      onAddToBasket(menuItem);
      setTriggerAnimation(true);
      setTimeout(() => setTriggerAnimation(false), 1400);
    }
  };

  return (
    <div onClick={handleDivClick} className={cx(styles.MenuCard)}>
      <div className={styles.ImageContainer}>
        <ImageWithPlaceholder
          imageSource={shouldDisplayMenuItemImage && menuItem.coverImageSrc}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={displayDataByLanguage(name)}
          className={styles.Image}
        />
      </div>
      <div className={styles.DetailContainer}>
        <h4 className={cx(styles.Title, "SemiBold")}>
          {displayDataByLanguage(name)}
        </h4>

        {allTags.length > 0 && (
          <div className={styles.Tags}>
            <Tag items={allTags} type={ENUMS_TAG.types.TYPE_F} />
          </div>
        )}
        <div className={styles.Footer}>
          <div className={styles.PriceColumn}>
            <Price
              type={ENUMS_PRICE.types.PRICE_S}
              value={
                discountPrice !== null
                  ? menuItemPrice + discountPrice
                  : menuItemPrice
              }
            />
            {discountPrice !== null && (
              <Price
                type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                value={menuItemPrice}
              />
            )}
          </div>
          <AnimatedAddButton
            id={id}
            value={0}
            size={ENUMS_ANIMATION.types.SMALL}
            onClick={() => onAddToBasket(menuItem)}
            isTriggered={triggerAnimation}
          />
        </div>
      </div>
    </div>
  );
};

MagicCard.propTypes = {
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    coverImageSrc: PropTypes.string,
    priceSell: PropTypes.number.isRequired,
    priceCost: PropTypes.number,
    calories: PropTypes.number,
    timeToMake: PropTypes.number,
    discountPrice: PropTypes.number,
    amount: PropTypes.number,
    unit: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  recommendations: PropTypes.bool,
  onAddToBasket: PropTypes.func.isRequired,
};

export default MagicCard;
