import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getZonesAsync, updateZoneAsync } from "redux/actions/zoneAction";

import IconButton from "components/buttons/icon-button/IconButton";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import { STORE_NAMES } from "utils/constants/redux";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { zodResolver } from "@hookform/resolvers/zod";

const EditZoneModal = ({
  mainElementRef,
  setOpenSlide,
  title,
  selectedZone,
  openSlide,
  setOutsideClickAction,
  setSelectedZone,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const [formData, setFormData] = useState(null);
  const [formDataInitial, setFormDataInitial] = useState(null);

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const editZoneForMapSchema = useValidationSchema(t).editZoneForMapSchema;
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    criteriaMode: "all",
    resolver: zodResolver(editZoneForMapSchema),
  });

  useEffect(() => {
    if (openSlide && selectedZone) {
      const INITIAL_FORM_DATA = {
        name: selectedZone?.name || "",
        width: selectedZone?.width || "",
        height: selectedZone?.height || "",
      };
      setFormData(INITIAL_FORM_DATA);
      setFormDataInitial(INITIAL_FORM_DATA);
      reset(INITIAL_FORM_DATA);
    }
  }, [selectedZone, openSlide, reset]);

  const handleOnInputChange = (e) => {
    const { value, name, type } = e.target;
    if (type === "number") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleOnModalClose = () => {
    if (!isEqual(formData, formDataInitial)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
  };

  const handleOnAsyncErrorForZone = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };
  const handleOnAsyncSuccessForZone = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      dispatch(getZonesAsync({ businessId }));
    });
  };

  const handleOnSubmit = async () => {
    const updatedData = {
      name: formData.name,
      width: Number(formData.width),
      height: Number(formData.height),
    };
    const response = await dispatch(
      updateZoneAsync({
        businessId,
        zone: updatedData,
        id: selectedZone.id,
      })
    );

    if (response.error) {
      handleOnAsyncErrorForZone();
    } else {
      handleOnAsyncSuccessForZone(t("toastMessages.success.updateZone"));
      setSelectedZone((prev) =>
        prev.id === selectedZone.id
          ? {
              ...prev,
              name: formData.name,
              width: Number(formData.width),
              height: Number(formData.height),
            }
          : prev
      );
    }
  };
  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const ModalHeader = (
    <div className="AddItemModalHeader">
      <h3 className="SemiBold AddItemModalHeaderTitle">
        {t("buttons.editForModal", { title: title })}
      </h3>
      <IconButton onClick={handleOnModalClose} svgComponent={<IconClose />} />
    </div>
  );
  const ModalBodyContent = (
    <div className="AddItemModalBody">
      <form
        className="AddItemModalBodyForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="AddItemModalBodyFormInputs">
          <InputControl
            type="text"
            required
            name="name"
            placeholder={`${title} `}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            value={formData?.name || ""}
            className="AddItemModalBodyFormInput"
            hasError={errors.name}
            error={
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            func={{
              ...register("name", {
                onChange: handleOnInputChange,
              }),
            }}
          />
          <InputControl
            type="number"
            placeholder={t("map.width")}
            name="width"
            hasError={errors.width}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            definitionText="m"
            value={formData?.width}
            error={
              <ErrorMessage
                errors={errors}
                name="width"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            func={{
              ...register("width", {
                onChange: handleOnInputChange,
                setValueAs: (value) => (value ? parseFloat(value) : undefined),
              }),
            }}
          />
          <InputControl
            type="number"
            placeholder={t("map.height")}
            name="height"
            hasError={errors.height}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            definitionText="m"
            value={formData?.height}
            error={
              <ErrorMessage
                errors={errors}
                name="height"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            func={{
              ...register("height", {
                onChange: handleOnInputChange,
                setValueAs: (value) => (value ? parseFloat(value) : undefined),
              }),
            }}
          />
        </div>
      </form>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const ModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      // isLoading={isLoading}
    />
  );

  return (
    <Modal
      header={ModalHeader}
      body={ModalBodyContent}
      footer={ModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    ></Modal>
  );
};
EditZoneModal.propTypes = {
  mainElementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  setOpenSlide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedZone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  openSlide: PropTypes.bool.isRequired,
  setOutsideClickAction: PropTypes.func.isRequired,
  setSelectedZone: PropTypes.func.isRequired,
};

export default EditZoneModal;
